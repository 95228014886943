<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.9273 3.9843C14.2119 2.75869 13.2415 1.78829 12.0159 1.07292C10.79 0.357592 9.4518 0 8.00024 0C6.54882 0 5.21021 0.357592 3.98456 1.07292C2.75883 1.78818 1.78844 2.75858 1.07303 3.9843C0.357592 5.2101 0 6.54871 0 8.00002C0 9.4514 0.357702 10.7898 1.07292 12.0157C1.78829 13.2413 2.75869 14.2117 3.98441 14.9271C5.21021 15.6424 6.54871 16 8.00009 16C9.45148 16 10.7901 15.6424 12.0158 14.9271C13.2415 14.2119 14.2119 13.2413 14.9272 12.0157C15.6424 10.7899 16 9.45133 16 8.00002C16 6.54856 15.6424 5.20992 14.9273 3.9843ZM6.66685 1.99987C6.66685 1.9026 6.69804 1.8228 6.76061 1.76034C6.82308 1.69792 6.90302 1.66657 7.00021 1.66657H9.00034C9.09746 1.66657 9.1774 1.69792 9.23971 1.76034C9.30214 1.8228 9.33359 1.9026 9.33359 1.99987V3.66659C9.33359 3.76379 9.30214 3.84369 9.23971 3.90608C9.1774 3.96854 9.0975 3.99977 9.00034 3.99977H7.00021C6.90302 3.99977 6.82311 3.96854 6.76061 3.90608C6.69804 3.84372 6.66685 3.76379 6.66685 3.66659V1.99987ZM10.6668 13C10.6668 13.0972 10.6353 13.1771 10.573 13.2394C10.5109 13.3019 10.4309 13.3332 10.3336 13.3332H5.66678C5.56948 13.3332 5.48968 13.3019 5.42719 13.2394C5.36472 13.1771 5.33349 13.0972 5.33349 12.9999V11.3332C5.33349 11.2358 5.36472 11.1561 5.42719 11.0936C5.48968 11.0313 5.56959 10.9998 5.66678 10.9998H6.66685V7.66662H5.66678C5.56948 7.66662 5.48972 7.63542 5.42719 7.57292C5.36472 7.5105 5.33349 7.43059 5.33349 7.33332V5.66671C5.33349 5.56944 5.36472 5.48965 5.42719 5.42711C5.48976 5.36461 5.56966 5.33342 5.66678 5.33342H9.00034C9.09746 5.33342 9.1774 5.36461 9.23971 5.42711C9.30214 5.48965 9.33359 5.56944 9.33359 5.66671V11.0001H10.3333C10.4307 11.0001 10.5106 11.0313 10.573 11.0937C10.6352 11.1562 10.6667 11.236 10.6667 11.3333V13.0001H10.6668V13Z"
      fill="#F2F2F2"
    />
  </svg>
</template>
<script>
export default {

}
</script>