<template>
  <div class="home">
    <template>
      <b-container class="container-rda sidebar" v-if="selected">
        <b-row class="height">
          <b-col md="2" class="menu-sidebar">
            <div class="menu">
              <ul>
                <li class="side-item active">
                  <span>{{ selected.competence.Subject.designation }}</span>
                </li>
              </ul>
            </div>
          </b-col>
          <b-col class="content">
            <b-row>
              <b-col>
                <b-breadcrumb :items="breadcrumb"></b-breadcrumb>
              </b-col>
            </b-row>
            <b-row class="mb-5">
              <b-col md="7">
                <h2 class="purple bold">Editar Competencia</h2>
              </b-col>
            </b-row>
            <b-row class="register-type">
              <b-col md="4">
                <b-form-group class="group-radio">
                  <label for="label" class="mb-4"> Tipo de registro </label>
                  <div class="radio-inline">
                    <b-form-radio
                      v-model="competence.type"
                      name="options"
                      value="competence"
                    >
                      Competência
                    </b-form-radio>
                  </div>
                </b-form-group>
              </b-col>
              <b-col class="status">
                <b-form-group label="Ativo">
                  <b-form-checkbox
                    v-model="selected.competence.is_active"
                    switch
                  ></b-form-checkbox>
                </b-form-group>
              </b-col>
              <div class="w-100"></div>
              <b-col>
                <b-form-group class="top-border">
                  <label for="label">Descritivo</label>
                  <b-form-textarea
                    v-model="selected.competence.description"
                    placeholder="Texto aqui"
                  ></b-form-textarea>
                </b-form-group>
              </b-col>
              <div class="w-100"></div>
              <b-col md="3">
                <b-button
                  class="large blue outline"
                  :to="{
                    name: 'listar-conteudos',
                    params: {
                      subject: selected.competence.Subject.id,
                    },
                  }"
                  >Voltar</b-button
                >
              </b-col>
              <b-col md="3">
                <b-button @click="updateCompetence" class="large blue"
                  >Salvar</b-button
                >
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import DataServiceCompetences from "@/services/competences.service";

export default {
  name: 'competencia-editar',
  components: {

  },
  data() {
    return {
      status: true,
      selected: null,
      competence: {
        type: 'competence',
      },
      breadcrumb : [
        {
          text: 'Coordenação',
          href: '/coordenacao',
        },
        {
          text: 'Currículo',
          href: '/coordenacao/conteudos',
        },
        {
          text: 'Registrar Roteiro',
          href: '/coordenacao/conteudos/registrar',
          active: true
        }
      ],
    }
  },

  computed: {
    ...mapGetters({
      subjects: 'shared/subjects'
    })
  },

  methods: {
    updateCompetence() {
      DataServiceCompetences.update(this.selected.competence.id, this.selected.competence)
        .then(response => {
          this.$router.push({
            name: "listar-conteudos",
            params: {
              subject: this.selected.competence.Subject.id
            }
          })
          const Toast = this.$swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
            didOpen: (toast) => {
            toast.addEventListener('mouseenter', this.$swal.stopTimer)
            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
          }})
          Toast.fire({
            icon: 'success',
            title: 'Conteúdo atualizado com sucesso!'
          })

          return response.data
        }).catch(error => {
          this.$swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Não foi possivel atualizar!',
            footer: 'Por favor, verifique se os campos estão preenchidos.'
          })

          return error.response
        })
    },
    getSelected(id) {
      DataServiceCompetences.get(id)
        .then(response => {
          this.selected = response.data;
          return response.data
        })
        .catch(error => {
          return error
        });
    },
  },
  created() {
    this.getSelected(this.$route.params.id);
    this.subject(this.$route.params.subject);
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/variables.scss";

.home {
  height: 100%;
}

.container-rda {
  padding-bottom: 0;
  .form-group {
    padding-bottom: 25px;
  }
}

.tags {
  background-color: $blue;
  border-radius: 5px;
  color: $white;
  font-weight: $bold;
  line-height: 28px;
  margin: 3px 12px 3px 0;
  max-width: 58px;
  padding: 0 3px;
  text-align: center;
  vertical-align: middle;
  width: 100%;
}

.register-type {
  padding-bottom: 30px;
  .top-border {
    border-top: 2px solid $grey7;
    padding-top: 35px;
    margin-top: 10px;
  }
}

ul, li {
  padding: 0;
}

.side-item {
  &.hide {
    display: none !important;
  }
}

textarea.form-control {
  line-height: 24px;
}
</style>