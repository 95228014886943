<template>
  <div class="loadingView" v-if="loadingForm">
    <LoadingList v-model="loadingForm" text="Carregando dados." />
  </div>
  <div v-else>
    <b-button
      v-if="!isReenrollment"
      style="font-size: 16px"
      class="btn large blue"
      @click="getAllEnrollmentsByResponsible"
      >Importar detalhes dos contratantes de outra matrícula
    </b-button>
    <div v-for="(item, index) in value" :key="`Contractor-${index}`">
      <b-card-header header-tag="header" role="tab" class="title-collapse">
        <h5 v-b-toggle="`accordion-${index + 1}`">
          Contratante {{ positionAlphabet(index) }}
        </h5>
      </b-card-header>
      <b-collapse
        :id="`accordion-${index + 1}`"
        visible
        accordion="my-accordion"
        role="tabpanel"
      >
        <!-- Group -->
        <b-row>
          <b-col md="2">
            <ProfilePhoto
              :modalId="`contractor + ${index}`"
              :photo="
                typeof item.photo !== 'string'
                  ? photoContractor[index]
                  : item.photo
              "
              :callback="(photo) => setNewPhoto(photo, index)"
            />
          </b-col>
          <b-col md="10">
            <b-row>
              <b-col md="4">
                <b-form-group class="required">
                  <label for="label">Relação com o aluno</label>
                  <multiselect
                    open-direction="bottom"
                    v-model="item.relationship"
                    :options="options.relationships"
                    :multiple="false"
                    placeholder="Selecione"
                    selectLabel=""
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group class="required">
                  <label for="label">Detentor da guarda do aluno</label>
                  <multiselect
                    open-direction="bottom"
                    v-model="item.is_guardian"
                    :options="options.boolean"
                    :multiple="false"
                    placeholder="Selecione"
                    selectLabel=""
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group class="required">
                  <label for="label"
                    >Contato prioritário assuntos pedagógicos</label
                  >
                  <multiselect
                    open-direction="bottom"
                    v-model="value[index].is_pedagogical_contact"
                    :options="options.boolean"
                    :multiple="false"
                    placeholder="Selecione"
                    selectLabel=""
                    :disabled="
                      item.is_pedagogical_contact == 'Não' ? true : false
                    "
                    @input="prioritaryContact('is_pedagogical_contact', index)"
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group class="required">
                  <label for="label"
                    >Contato prioritário assuntos financeiros</label
                  >
                  <multiselect
                    open-direction="bottom"
                    v-model="value[index].is_financial_contact"
                    :options="options.boolean"
                    :multiple="false"
                    placeholder="Selecione"
                    selectLabel=""
                    :disabled="
                      item.is_financial_contact == 'Não' ? true : false
                    "
                    @input="prioritaryContact('is_financial_contact', index)"
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <StandardInput
                  v-model="item.name"
                  class="required"
                  type="text"
                  name="student-custody"
                  label="Nome Completo"
                  :disabled="index == 0 ? true : false"
                  validateFullName
                />
              </b-col>
              <b-col md="4">
                <StandardInput
                  v-model="item.birth_date"
                  class="required"
                  type="text"
                  name="birth_date"
                  label="Data de nascimento"
                  v-mask="'##/##/####'"
                  placeholder
                />
              </b-col>
              <b-col md="4">
                <StandardInput
                  v-model="item.occupation"
                  class="required"
                  type="text"
                  name="occupation"
                  label="Ocupação"
                />
              </b-col>
              <b-col md="4">
                <StandardInput
                  v-model="item.workplace"
                  class="required"
                  type="text"
                  name="student-custody"
                  label="Local de trabalho"
                />
              </b-col>
              <b-col md="4">
                <StandardInput
                  v-model="item.nationality"
                  class="required"
                  type="text"
                  name="country"
                  label="Nacionalidade"
                />
              </b-col>
              <b-col md="4">
                <StandardInput
                  v-model="item.country_origin"
                  class="required"
                  type="text"
                  name="country_origin"
                  label="Naturalidade"
                />
              </b-col>
              <b-col md="8">
                <StandardInput
                  v-model="item.talents"
                  type="text"
                  name="activities"
                  label="Habilidades/Talentos que possam contribuir com a Escola da Serra"
                  :required="false"
                />
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <!-- Group -->

        <!-- Group -->
        <b-row class="group">
          <b-col>
            <h5>Dados de endereço</h5>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="3">
            <b-form-group class="required">
              <label for="">Endereço igual ao do aluno</label>
              <multiselect
                open-direction="bottom"
                v-model="sameAddress[index]"
                :options="options.boolean"
                :multiple="false"
                placeholder="Selecione"
                selectLabel=""
                @input="resetAddressId(index)"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <template v-if="sameAddress[index] == 'Não'">
          <div v-if="!loadingCep">
            <b-row class="">
              <b-col>
                <StandardInput
                  v-model="item.Address.cep"
                  class="required required-cme"
                  type="text"
                  name="cep"
                  label="CEP"
                  required
                  v-mask="'#####-###'"
                  @onchange="getAddress(item.Address.cep, index)"
                />
              </b-col>
              <b-col>
                <b-form-group class="required required-cme">
                  <label for="label">Estado</label>
                  <multiselect
                    open-direction="bottom"
                    v-model="item.Address.state"
                    :options="states"
                    :multiple="false"
                    placeholder="Selecione um estado"
                    selectLabel=""
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <StandardInput
                  v-model="item.Address.city"
                  class="required required-cme"
                  type="text"
                  name="city"
                  label="Cidade"
                  required
                />
              </b-col>
              <b-col>
                <StandardInput
                  v-model="item.Address.neighborhood"
                  class="required required-cme"
                  type="text"
                  name="neighborhood"
                  required
                  label="Bairro"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <StandardInput
                  v-model="item.Address.street"
                  class="required required-cme"
                  type="text"
                  name="address"
                  required
                  label="Endereço"
                />
              </b-col>
              <b-col>
                <StandardInput
                  v-model="item.Address.number"
                  class="required-cme"
                  type="text"
                  required
                  name="number"
                  label="Número"
                />
              </b-col>
              <b-col>
                <StandardInput
                  v-model="item.Address.complement"
                  type="text"
                  name="complement"
                  label="Complemento"
                  :required="false"
                />
              </b-col>
              <b-col> </b-col>
            </b-row>
          </div>
          <div v-else>
            <b-row align="center">
              <b-col class="mt-5">
                <b-spinner
                  variant="success"
                  label="Pesquisando o CEP..."
                ></b-spinner>
              </b-col>
            </b-row>
          </div>
        </template>
        <!-- Group -->

        <!-- Group -->
        <b-row class="group">
          <b-col>
            <h5>Contatos</h5>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <StandardInput
              v-model="item.phone1"
              class="required"
              type="text"
              name="phone-1"
              label="Telefone 1"
              v-mask="'(##) #####-####'"
            />
          </b-col>
          <b-col>
            <StandardInput
              v-model="item.phone2"
              :required="false"
              type="text"
              name="phone-2"
              label="Telefone 2"
              v-mask="'(##) #####-####'"
            />
          </b-col>
          <b-col>
            <StandardInput
              v-model="item.phone_commercial"
              :required="false"
              type="text"
              name="commercial-phone"
              label="Telefone Comercial"
              v-mask="'(##) #####-####'"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col md="4">
            <StandardInput
              v-model="item.email"
              class="required"
              type="email"
              name="email"
              label="Seu e-mail"
              :disabled="index == 0 ? true : false"
            />
          </b-col>
        </b-row>
        <b-row class="mb-5">
          <b-col>
            <b-form-checkbox v-model="item.allow_email">
              Autoriza informar o e-mail para a Associação de Responsáveis da
              Escola da Serra
            </b-form-checkbox>
          </b-col>
        </b-row>
        <!-- Group -->

        <!-- Group -->
        <b-row class="group">
          <b-col>
            <h5>Documentos</h5>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <fieldset class="form-group required-cme required">
              <legend></legend>
              <label for="hasSiblings">CPF</label>
              <b-form-input
                v-model="item.cpf"
                type="text"
                name="cpf"
                label="CPF"
                v-mask="'###.###.###-##'"
                placeholder=""
                :disabled="index == 0 ? true : false"
              ></b-form-input>
            </fieldset>
          </b-col>
          <b-col>
            <StandardInput
              v-model="item.rg"
              class="required"
              type="text"
              name="rg"
              label="RG"
              placeholder=""
            />
          </b-col>
          <b-col></b-col>
        </b-row>
        <!-- Group -->
      </b-collapse>
    </div>

    <b-row class="wrap-add" v-if="this.value.length < 2">
      <b-col>
        <b-button class="btn large blue mb-5 add" @click="addContractor()"
          ><b-icon-plus-lg />Adicionar Contratante</b-button
        >
      </b-col>
    </b-row>

    <b-modal
      id="modal-responsibles"
      hide-footer
      title="Matrículas disponívels para trazer os dados dos contratantes"
      style="display: flex; justify-content: center; align-items: center"
    >
      <div>
        <b-container fluid>
          <b-row
            align-v="center"
            class="list-teacher"
            v-for="(enrollment, index) in otherEnrollmentResponsibles"
            :key="index"
          >
            <b-col :md="2" class="name">
              <p class>{{ enrollment.id }}</p>
            </b-col>
            <b-col :md="8" class="name">
              <p class>{{ enrollment.studentName }}</p>
            </b-col>
            <b-col md="2">
              <a
                @click="
                  () => selectResponsibles(enrollment.EnrollmentResponsibles)
                "
              >
                <ExportIcon />
              </a>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import utils from "@/utils";
import ExportIcon from "@/components/svg/ExportIcon.vue";
import ProfilePhoto from "@/components/ProfilePhoto.vue";
import StandardInput from "@/components/inputs/StandardInput.vue";
import getAddressByCep from "../../../services/getAddressByCep";
import getStateNameByAcronym from "../../../utils/getStateNameByAcronym";
import httpRda from "../../../http-rda";
import moment from "moment";
import LoadingList from "@/components/LoadingList.vue";

export default {
  name: "Contractor",
  components: {
    ProfilePhoto,
    LoadingList,
    StandardInput,
    ExportIcon,
  },

  props: ["value", "nameStudantValidate"],

  data() {
    return {
      loadingForm: true,
      otherEnrollmentResponsibles: null,
      isReenrollment: this.$route.name.includes("rematricula") ? true : false,
      loadingCep: false,
      sameAddress: [],
      studentAddressId: null,
      alphabet: ["A", "B"],
      photoContractor: [],
      formInputs: {
        relationship: "",
        is_guardian: "",
        is_pedagogical_contact: "",
        is_financial_contact: "",
        is_address_same_student: false,
        photo: "",
        name: "",
        occupation: "",
        workplace: "",
        nationality: "",
        country_origin: "",
        talents: "",
        phone1: "",
        phone2: "",
        phone_commercial: "",
        email: "",
        allow_email: false,
        cpf: "",
        rg: "",
        Address: {
          id: null,
          cep: null,
          number: null,
          complement: null,
          street: null,
          neighborhood: null,
          city: null,
          state: null,
          country: null,
        },
      },

      options: {
        relationships: ["Pai", "Mãe", "Avô", "Avó", "Tio", "Tia", "Outra"],
        boolean: ["Sim", "Não"],
        countries: ["Brasil", "Alemanha"],
      },
    };
  },

  methods: {
    ...mapActions({
      getUser: "shared/getUser",
      savePhoto: "enrollment/savePhotoGeneric",
      create: "enrollment/createContractorFiliation",
      saveAddress: "enrollment/createContractorFiliationAddress",
      getStates: "shared/getStates",
    }),

    openModal() {
      this.$bvModal.show("modal-responsibles");
    },

    selectResponsibles(responsibles) {
      const maxLength = Math.min(responsibles.length, this.value.length);

      for (let i = 0; i < maxLength; i++) {
        const otherEnrollmentResponsible = responsibles[i];
        const currentEnrollmentResponsible = this.value[i];

        for (const key in otherEnrollmentResponsible) {
          if (key !== "Address" && key !== "id") {
            const value = otherEnrollmentResponsible[key];
            if (typeof value === "boolean") {
              currentEnrollmentResponsible[key] = value ? "Sim" : "Não";
            } else if (key === "birth_date") {
              currentEnrollmentResponsible[key] =
                moment(value).format("DD/MM/YYYY");
            } else {
              currentEnrollmentResponsible[key] = value;
            }
          }
        }
      }

      this.$bvModal.hide("modal-responsibles");
    },
    async getStudentAddressId(enrollmentId) {
      const resp = await httpRda
        .get(`enrollments/${enrollmentId}`)
        .then((resp) => resp.data);
      this.studentAddressId = resp.enrollment.EnrollmentStudent.address_id;
    },

    prioritaryContact(propertyname, index) {
      let contractorResult = this.value[index][propertyname];

      this.value[index === 0 ? 1 : 0][propertyname] =
        contractorResult === "Sim" ? "Não" : "Sim";
    },

    resetAddressId(contractorIndex) {
      if (
        this.sameAddress[contractorIndex] == "Não" &&
        this.value[contractorIndex].is_address_same_student == "Sim"
      ) {
        this.value[contractorIndex].Address.id = undefined;
      }
    },

    async getAddress(cep, contractorIndex) {
      this.loadingCep = true;

      await getAddressByCep(cep)
        .then((resp) => {
          this.value[contractorIndex].Address.state = getStateNameByAcronym(
            resp.uf
          );
          this.value[contractorIndex].Address.city = resp.localidade;
          this.value[contractorIndex].Address.neighborhood = resp.bairro;
          this.value[contractorIndex].Address.street = resp.logradouro;
        })
        .catch((err) => {
          const Toast = this.$swal.mixin({
            toast: true,
            position: "bottom-end",
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer);
              toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            },
          });
          Toast.fire({
            icon: "success",
            title: err.message,
          });
        });

      this.loadingCep = false;
    },

    async getAllEnrollmentsByResponsible() {
      if (
        !this.otherEnrollmentResponsibles ||
        this.otherEnrollmentResponsibles.length == 0
      ) {
        await httpRda
          .get(
            `/responsibles/${this.currentUser.responsible_id}/enrollments-copy`
          )
          .then((response) => {
            this.otherEnrollmentResponsibles = response.data.enrollments
              .map((enrollment) => {
                return {
                  id: enrollment.enrollment_id,
                  studentName: enrollment.student,
                  EnrollmentResponsibles: enrollment.responsibles,
                };
              })
              .filter((item) => item.id != this.$route.params.id);
          });
      }
      this.openModal();
    },

    async getResponsibles() {
      await this.getStudentAddressId(this.$route.params.id);

      this.addContractor(this.value.length);

      await this.setUser();

      for (const contractor of this.value) {
        for (const key in contractor) {
          if (contractor[key] === true) {
            contractor[key] = "Sim";
          } else if (contractor[key] === false) {
            contractor[key] = "Não";
          }
        }
      }

      this.loadingForm = false;
    },

    addContractor(size) {
      switch (size) {
        case 0:
          this.value.push({ ...this.formInputs, type: "contractor a" });
          this.value.push({ ...this.formInputs, type: "contractor b" });
          break;

        case 1:
          this.value.push({ ...this.formInputs, type: "contractor b" });
          break;

        case 2:
          this.value.map((contractor, index) => {
            if (contractor.Address == null) {
              contractor.Address = {
                id: null,
                cep: null,
                number: null,
                complement: null,
                street: null,
                neighborhood: null,
                city: null,
                state: null,
                country: null,
              };
            } else {
              if (
                this.studentAddressId != contractor.address_id &&
                contractor.address_id !== null
              ) {
                this.sameAddress[index] = "Não";
                this.value[index].is_address_same_student = "Não";
              } else {
                this.sameAddress[index] = "Sim";
                this.value[index].is_address_same_student = "Sim";
              }
            }
          });
          break;
      }
    },

    positionAlphabet(index) {
      return this.alphabet[index];
    },

    removeItem(index) {
      this.value.splice(index, 1);
    },

    // Form
    setNewPhoto(photo, index) {
      const bodyFormData = new FormData();
      bodyFormData.append("file", photo);
      this.value[index].photo = bodyFormData;

      this.photoContractor[index] = photo;
    },

    async setUser() {
      if (
        !this.value[0].cpf &&
        !this.value[0].email &&
        !this.value[0].name &&
        !this.value[0].phone1 &&
        !this.value[0].phone2
      ) {
        await this.getUser(this.currentUser.id)
          .then((response) => {
            this.value[0].cpf = response.data.user.cpf;
            this.value[0].rg = response.data.user.rg;
            this.value[0].email = response.data.user.email;
            this.value[0].name = response.data.user.name;
            this.value[0].phone1 = response.data.user.Phones[0].number;
            this.value[0].phone2 = response.data.user.Phones[1].number;
          })
          .catch((error) => {
            return error.data;
          });
      }
    },
  },

  computed: {
    ...mapGetters({
      currentUser: "auth/getAuthData",
      statesList: "shared/states",
    }),

    propModel: {
      get() {
        return this.prop;
      },
      set(value) {
        this.$emit("update:prop", value);
      },
    },

    verificarCpf() {
      if (this.value.cpf != undefined) {
        const validarCPF = utils.validateCPF(this.value.cpf);
        return validarCPF ? true : false;
      } else {
        return false;
      }
    },

    enrollment_id() {
      return this.$route.params.id;
    },

    states() {
      return [...this.statesList.map((c) => c.name), "Não se aplica"];
    },
  },

  watch: {
    sameAddress: function (newValue) {
      newValue.map((value, index) => {
        this.value[index].is_address_same_student = value;
      });
    },
  },

  mounted() {
    this.getResponsibles();
    this.getStates();
  },
};
</script>

<style lang="scss" scoped>
.wrap-add {
  padding-top: 40px;

  .add {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;

    svg {
      font-size: 16px !important;
      position: relative;
      top: -1px;
    }
  }
}

.title-collapse {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;

  h5 {
    flex: 1;
  }

  .btn {
    background: transparent;
    border: 0;

    svg {
      font-size: 16px;
      fill: red;
    }
  }
}
.group {
  border-top: 1px solid #b3b3b3;
  padding-top: 30px;
  margin-top: 30px;
}
</style>
