<template>
  <b-container class="container-rda">
    <b-row>
      <b-col>
        <b-breadcrumb :items="breadcrumb"></b-breadcrumb>
      </b-col>
    </b-row>
    <b-row class="mb-5">
      <b-col>
        <h2 class="purple bold">Relatório de Frequência</h2>
      </b-col>
      <b-col class="text-right">
        <b-button
          v-if="rdaUnit == 'BELO_HORIZONTE'"
          class="btn large blue"
          @click="openModalNotifications"
        >
          Configuração de notificações
        </b-button>
      </b-col>
    </b-row>
    <b-row class="mb-5">
      <b-col>
        <div class="boxes-attendances">
          <p>Frequência</p>
          <h1 class="gradient1">{{ attendances.frequency }}%</h1>
        </div>
      </b-col>
      <b-col>
        <div class="boxes-attendances">
          <p>Total de horas ausentes</p>
          <h1 class="gradient1">{{ attendances.time_absences }}</h1>
        </div>
      </b-col>
      <b-col>
        <div class="boxes-attendances">
          <p>Total de dias ausentes</p>
          <h1 class="gradient1">{{ attendances.absences }}</h1>
        </div>
      </b-col>
      <b-col></b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-container fluid>
          <b-row class="header-list-teacher">
            <b-col md="2">
              <p class="bold">Turma</p>
            </b-col>
            <b-col md="2">
              <p class="bold">Evento</p>
            </b-col>
            <b-col md="2">
              <p class="bold">Tipo</p>
            </b-col>
            <b-col md="3">
              <p class="bold">Data e Hora</p>
            </b-col>
            <b-col md="3">
              <p class="bold">Token Gerado Por</p>
            </b-col>
          </b-row>
        </b-container>
        <b-container fluid>
          <b-row
            align-v="center"
            class="list-teacher"
            v-for="attendance in attendancesList"
            v-bind:key="attendance.id"
          >
            <b-col md="2">
              <p>{{ attendance.classroom }}</p>
            </b-col>
            <b-col md="2">
              <p>
                <span
                  class="attendance"
                  :class="{
                    absence: attendance.type === 'absence' ? 'absence' : '',
                    presence: attendance.type === 'presence' ? 'presence' : '',
                    late: attendance.type === 'late' ? 'late' : '',
                    antecipated:
                      attendance.type === 'antecipated' ? 'antecipated' : '',
                    regular_leave:
                      attendance.type === 'regular_leave'
                        ? 'regular_leave'
                        : '',
                  }"
                >
                  {{
                    attendance.type === "absence"
                      ? "Falta"
                      : "" || attendance.type === "presence"
                      ? "Presença"
                      : "" || attendance.type === "late"
                      ? "Chegada Tardia"
                      : "" || attendance.type === "antecipated"
                      ? "Saída Antecipada"
                      : "" || attendance.type === "regular_leave"
                      ? "Saída Regular"
                      : ""
                  }}
                </span>
              </p>
            </b-col>
            <b-col md="2">
              <p>
                {{
                  attendance.period === "regular"
                    ? "Horário regular"
                    : "Cotraturno"
                }}
              </p>
            </b-col>
            <b-col md="3">
              <p>{{ attendance.date }}</p>
            </b-col>
            <b-col md="3" v-if="attendance.origin !== 'job'">
              <p>
                {{
                  attendance.token_user
                    ? attendance.token_user.name
                    : "Usuário inválido"
                }}
              </p>
            </b-col>
            <b-col md="3" v-else>
              <p>Automático do sistema</p>
            </b-col>
          </b-row>
          <b-row class="align-center" v-if="count > perPage">
            <b-pagination
              v-model="page"
              :per-page="perPage"
              :total-rows="count"
              @change="changePage"
              aria-controls="teachersList"
              last-text
              first-text
            >
              <template #prev-text>
                <LeftArrowIcon class="left" />
              </template>
              <template #next-text>
                <LeftArrowIcon />
              </template>
            </b-pagination>
          </b-row>
        </b-container>
      </b-col>
    </b-row>

    <b-modal
      id="modal-notifications"
      v-if="rdaUnit == 'BELO_HORIZONTE'"
      hide-footer
      title="Configuração de notificações"
      style="display: flex; justify-content: center; align-items: center"
    >
      <div>
        <b-container>
          <b-row align-v="center">
            <b-col md="12">
              <b-form-group label="Como serei notificado?">
                <multiselect
                  :value="selectedNotification"
                  :options="notificationOptions"
                  :multiple="false"
                  :close-on-select="true"
                  :clear-on-select="false"
                  :preserve-search="true"
                  placeholder="Selecione uma opção"
                  label="designation"
                  track-by="id"
                  selectLabel=""
                  selectedLabel=""
                  deselectLabel=""
                  @select="callbackSelectedNotification"
                >
                  <span slot="noResult">Nada encontrado</span>
                </multiselect>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-button class="btn large blue" @click="saveNotifications">
                Salvar
              </b-button>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </b-modal>
  </b-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import httpRda from "../../http-rda";

export default {
  name: "faltas-e-presencas",
  components: {},
  data() {
    return {
      rdaUnit: process.env.VUE_APP_RDA_UNIT,
      page: this.$route.params.page || 1,
      count: 0,
      dateFilter: null,
      perPage: 20,
      attendancesList: [],
      breadcrumb: [
        {
          text: "Início",
          href: "/",
        },
        {
          text: "Faltas e Presenças",
          href: "/faltas-e-presencas",
          active: true,
        },
      ],
      selectedNotification: null,
      selectedNotificationId: null,
      notificationOptions: [
        { designation: "Ser notificado a cada falta", id: "each_attendance" },
        {
          designation: "Ser notificado a cada três faltas",
          id: "interval_attendance",
        },
        { designation: "Não ser notificado", id: "none" },
      ],
    };
  },

  filters: {
    date(date) {
      try {
        var d = new Date(date.split());
        var year = d.getFullYear();
        var month = (d.getMonth() + 1).toString().padStart(2, "0");
        var day = d.getUTCDate().toString().padStart(2, "0");

        return day + "/" + month + "/" + year;
      } catch (error) {
        return date;
      }
    },
  },

  computed: {
    ...mapGetters({
      attendances: "shared/studentsAttendances",
      user: "auth/getAuthData",
    }),
  },

  methods: {
    ...mapActions({
      getStudentAttendances: "shared/getStudentAttendances",
    }),

    changePage(page) {
      this.page = page;
      this.getAttendances();
      window.scrollTo({ top: 0, behavior: "smooth" });
    },

    openModalNotifications() {
      this.$bvModal.show("modal-notifications");
    },

    callbackSelectedNotification(selected) {
      this.selectedNotification = selected;
    },

    getRequestParams(student_id, page, perPage) {
      let params = {};

      if (student_id) {
        params["student_id"] = student_id;
      }

      if (page) {
        params["page"] = page;
      }

      if (perPage) {
        params["limit"] = perPage;
      }

      return params;
    },

    async getNotifications() {
      await httpRda
        .get(
          `/attendance-notifications/responsible/${this.user.responsible_id}/student/${this.$route.params.student_id}`
        )
        .then((response) => {
          const result = response.data;

          this.selectedNotificationId = result.id;
          this.selectedNotification = this.notificationOptions.find(
            (notification) => notification.id === result.option
          );
        });
    },

    async saveNotifications() {
      try {
        const response = await httpRda.post("/attendance-notifications", {
          id: this.selectedNotificationId,
          student_id: this.$route.params.student_id,
          responsible_id: this.user.responsible_id,
          option: this.selectedNotification.id,
        });

        if (response.status === 201) {
          this.$bvModal.hide("modal-notifications");
          this.$bvToast.toast("Notificação salva com sucesso", {
            title: "Sucesso",
            variant: "success",
            solid: true,
          });

          this.getNotifications();
        }
      } catch (error) {
        alert(error);
      }
    },

    async getAttendances() {
      const params = this.getRequestParams(
        this.$route.params.student_id,
        this.page,
        this.perPage
      );

      try {
        const response = await httpRda.get("/attendances", {
          params,
        });
        this.attendancesList = response.data.attendances;
        this.count = response.data.count;
      } catch (error) {
        console.log(error);
      }
    },

    setUser() {
      if (this.$route.params.student_id) {
        this.getStudentAttendances(this.$route.params.student_id);
      } else {
        this.getStudentAttendances(this.currentUser.student_id);
      }
    },
  },

  created() {
    this.getNotifications();
    this.setUser();
    this.getAttendances();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/variables.scss";

.home {
  height: 100%;
}

.boxes-attendances {
  background-color: $white;
  border: 1px solid #f2f2f2;
  border-radius: 10px;
  min-height: 120px;
  padding: 16px;
  text-align: center;
  p {
    margin-bottom: 10px;
  }
  h1 {
    margin: 0;
  }
}

.attendance {
  border-radius: 5px;
  display: block;
  color: $white;
  font-weight: 700;
  line-height: 28px;
  max-width: 200px;
  text-align: center;
  &.late {
    background-color: $orange;
  }
  &.absence {
    background-color: $red;
  }
  &.presence {
    background-color: $green2;
  }
  &.antecipated {
    background-color: $purple;
  }
  &.regular_leave {
    background-color: $yellow;
  }
}
</style>
