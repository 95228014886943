<template>
  <div class="home">
    <template>
      <b-container class="container-rda">
        <b-row>
          <b-col>
            <b-breadcrumb :items="breadcrumb"></b-breadcrumb>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <h2 class="purple bold">Autorizações</h2>
          </b-col>
          <b-col class="text-right">
            <b-button class="btn large blue" href="#" v-if="false">Exportar CSV</b-button>
          </b-col>
        </b-row>
        <b-row class="mt-50">
          <b-col md="3">
            <SelectCycles v-model="cycle" :callback="callbackCycles"></SelectCycles>
            <span class="clear-field" v-if="this.cycle != null" @click="clearCycle">Limpar filtro</span>
          </b-col>
          <b-col>
            <b-form-group label="Buscar Aluno">
              <b-input-group-prepend>
                <b-form-input
                  type="search"
                  placeholder="Digite aqui"
                  v-model="searchTitle"
                  v-on:keyup.enter="
                    page = 1;
                    getList();
                  "
                ></b-form-input>
                <b-input-group-text
                  class="clear-fields"
                  v-if="searchTitle != ''"
                  v-b-tooltip.hover
                  title="Limpar Pesquisa"
                >
                  <b-icon icon="x" @click="clearFields" />
                </b-input-group-text>
              </b-input-group-prepend>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <div class="order-grid-box">
              <p class="order-list" :class="{ desc: descOrder }" v-on:click="orderName">
                Ordenar
                <OrderNameIcon />
              </p>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-container fluid>
              <b-row class="column-list" v-if="enrollments.length > 0 && isRequestSend">
                <b-col md="2">
                  <p class="bold">Aluno</p>
                </b-col>
                <b-col>
                  <p class="bold">Ciclo</p>
                </b-col>
                <b-col>
                  <p class="bold">Ducha</p>
                </b-col>
                <b-col>
                  <p class="bold">Excursões</p>
                </b-col>
                <b-col>
                  <p class="bold">Recreio</p>
                </b-col>
                <b-col>
                  <p class="bold">Antecipada</p>
                </b-col>
                <b-col>
                  <p class="bold">Sozinho</p>
                </b-col>
              </b-row>
            </b-container>
            <b-container fluid>
              <b-row
                align-v="center"
                class="list-orders"
                v-for="enrollment in orderbyName"
                :key="enrollment.id"
              >
                <b-col md="2">
                  <p v-if="enrollment.EnrollmentStudent">{{ enrollment.EnrollmentStudent.name }}</p>
                </b-col>
                <b-col
                  v-if="enrollment.EnrollmentStudent"
                >{{ getCycleName(enrollment.EnrollmentStudent.cycle_id) }}</b-col>
                <b-col>
                  <PermissionIcon
                    v-if="enrollment.EnrollmentAuthorization"
                    :value="enrollment.EnrollmentAuthorization.shower"
                  />
                  <p class="grey4" v-else>Cadastro incompleto</p>
                </b-col>
                <b-col>
                  <PermissionIcon
                    v-if="enrollment.EnrollmentAuthorization"
                    :value="enrollment.EnrollmentAuthorization.excursion"
                  />
                  <p class="grey4" v-else>Cadastro incompleto</p>
                </b-col>
                <b-col>
                  <PermissionIcon
                    v-if="enrollment.EnrollmentAuthorization"
                    :value="
                      enrollment.EnrollmentAuthorization.leave_school_break
                    "
                  />
                  <p class="grey4" v-else>Cadastro incompleto</p>
                </b-col>
                <b-col>
                  <PermissionIcon
                    v-if="enrollment.EnrollmentAuthorization"
                    :value="
                      enrollment.EnrollmentAuthorization
                        .leave_school_antecipated
                    "
                  />
                  <p class="grey4" v-else>Cadastro incompleto</p>
                </b-col>
                <b-col>
                  <PermissionIcon
                    v-if="enrollment.EnrollmentAuthorization"
                    :value="
                      enrollment.EnrollmentAuthorization
                        .leave_school_antecipated
                    "
                  />
                  <p class="grey4" v-else>Cadastro incompleto</p>
                </b-col>
              </b-row>
              <b-row
                align-v="center"
                class="list-teacher"
                v-if="enrollments.length <= 0 && isRequestSend"
              >
                <b-col>Nenhum resultado encontrado</b-col>
              </b-row>
              <b-row align-v="center" class="list-teacher" v-if="!isRequestSend">
                <b-col>Carregando</b-col>
              </b-row>
              <b-row class="align-center" v-else-if="count > perPage && isRequestSend">
                <b-pagination
                  v-model="page"
                  :per-page="perPage"
                  :total-rows="count"
                  @change="changePage"
                  aria-controls="teachersList"
                  last-text
                  first-text
                >
                  <template #prev-text>
                    <LeftArrowIcon class="left" />
                  </template>
                  <template #next-text>
                    <LeftArrowIcon />
                  </template>
                </b-pagination>
              </b-row>
            </b-container>
          </b-col>
        </b-row>
      </b-container>
    </template>
  </div>
</template>

<script>
import DataService from "@/services/enrollments.service";
import DataServiceCycles from "@/services/cycles.service";

import LeftArrowIcon from "@/components/svg/LeftArrowIcon.vue";
import OrderNameIcon from "@/components/svg/OrderNameIcon.vue";
import SelectCycles from "@/components/inputs/SelectCycles.vue";
import PermissionIcon from "@/components/svg/PermissionIcon.vue";

export default {
  name: "requerentes",
  components: {
    LeftArrowIcon,
    OrderNameIcon,
    SelectCycles,
    PermissionIcon,
  },
  data() {
    return {
      enrollments: [],
      descOrder: false,
      isRequestSend: false,

      cycle: null,
      searchTitle: "",

      page: this.$route.params.page || 1,
      count: 0,
      perPage: 20,

      breadcrumb: [
        {
          text: "Secretaria",
          href: "/secretaria",
        },
        {
          text: "Autorizações",
          href: "/autorizacoes",
          active: true,
        },
      ],
    };
  },

  filters: {
    date(date) {
      try {
        var d = new Date(date.split());
        var year = d.getFullYear().toString();
        var month = (d.getMonth() + 1).toString().padStart(2, "0");
        var day = d.getUTCDate().toString().padStart(2, "0");

        return day + "/" + month + "/" + year;
      } catch (error) {
        return date;
      }
    },
  },

  computed: {
    orderbyName() {
      return [...this.enrollments].sort((a, b) => {
        const order = this.descOrder ? [1, -1] : [-1, 1];

        if (a.EnrollmentStudent.name < b.EnrollmentStudent.name)
          return order[0];
        else if (a.EnrollmentStudent.name > b.EnrollmentStudent.name)
          return order[1];

        return 0;
      });
    },
  },

  methods: {
    getRequestParams(searchTitle, page, perPage, cycle) {
      let params = {};

      if (searchTitle) {
        params["search"] = searchTitle;
      }

      if (page) {
        params["page"] = page;
      }

      if (perPage) {
        params["limit"] = perPage;
      }

      if (cycle) {
        params["cycle_id"] = cycle;
      }

      return params;
    },

    getList() {
      const params = this.getRequestParams(
        this.searchTitle,
        this.page,
        this.perPage,
        this.cycle
      );

      DataService.getAll(params)
        .then((response) => {
          this.enrollments = response.data.enrollments;
          this.count = response.data.count;
          this.isRequestSend = true;
        })
        .catch((error) => {
          return error.data;
        });
    },

    getParams(params) {
      DataServiceCycles.getAll(params)
        .then((response) => {
          this.cycles = response.data.cycles;
        })
        .catch((error) => {
          return error.data;
        });
    },

    orderName() {
      this.descOrder = !this.descOrder;
    },

    changePage(page) {
      this.page = page;
      this.getList();
      window.scrollTo({ top: 0, behavior: "smooth" });
    },

    callbackCycles({ id }) {
      this.cycle = id !== -1 ? id : null;
      this.currentPage = 1;
      this.getList();
    },

    clearFields() {
      this.searchTitle = "";
      this.currentPage = 1;
      this.getList();
    },

    getCycleName(id) {
      if (this.cycles) {
        const cycles = this.cycles.find((c) => c.id === id);
        return cycles ? cycles.name : "-";
      }
    },

    getCalendarName(id) {
      if (this.calendars) {
        const calendars = this.calendars.find((c) => c.id === id);
        return calendars ? calendars.designation : "-";
      }
    },
  },

  created() {
    this.getList();
    this.getParams();
  },
};
</script>

<style lang="scss" scoped>

@import "@/assets/css/variables.scss";

.list-orders {
  background-color: $white;
  border: 1px solid $grey5;
  border-radius: 10px;
  margin-bottom: 15px;
  min-height: 80px;
  margin-right: -12px;
  &:last-child {
    margin-bottom: 0px;
  }
  &:hover {
    border-color: transparent;
    background-image: linear-gradient($white, $white), radial-gradient(circle at top left, $orange, $purple);
    background-origin: border-box;
    background-clip: content-box, border-box;
  }
  p {
    margin: 0;
  }
  .tags {
    background-color: $blue;
    border-radius: 5px;
    color: $white;
    font-weight: $bold;
    line-height: 28px;
    max-width: 70px;
    text-align: center;
  }
}

.column-list {
  color: $grey1;
  p {
    font-size: 16px;
  }
}

.flex-right {
  align-items: center;
  display: flex;
  justify-content: flex-end;
}

.qty-order {
  background-color: $purple;
  border-radius: 5px;
  color: $white;
  font-weight: $bold;
  line-height: 28px;
  max-width: 100px;
  margin: 0;
  text-align: center;
  width: 100%;
}

</style>