<template>
  <b-modal id="tutoria" hide-header hide-footer centered @hide="changeModalState">
    <h4 class="purple mb-3">Atribuir alunos</h4>
    <p>Salve uma nova versão do roteiro apenas para alunos específicos.</p>

    <b-row class="mt-4">
      <b-col>
        <StandardSelect
          :callbackSelect="selectStudent"
          :searchChange="setSearch"
          :options="studentsNamed"
          :isLoading="loadingList"
          label="Buscar Aluno"
          placeholder="Digite aqui"
        />
      </b-col>
    </b-row>

    <b-row align-v="end">
      <b-col class="mt-4">
        <b-form-group class="calendar">
          <label>Nova data de finalização</label>
          <Datepicker
            @selected="setDate"
            placeholder="Selecione"
            format="dd/MM/yyyy"
            :use-utc="true"
            :disabled-dates="disabledDates"
            :language="languages[language]"
          />
        </b-form-group>
      </b-col>

      <b-col class="mt-4">
        <b-button @click="assignStudent" class="full mb-3">Atribuir</b-button>
      </b-col>
    </b-row>

    <table class="table-students mt-5" aria-describedby="Alunos">
      <tr v-if="studentsAssigneds.length != 0">
        <th colspan="2" id="alunos">
          <h4 class="purple">Alunos</h4>
        </th>
        <th id="data">
          <h4 class="purple">Data</h4>
        </th>
        <th scope />
      </tr>

      <tr v-for="({ student, date }, index) in studentsAssigneds" :key="student.name">
        <td colspan="2">{{ student.name }}</td>
        <td>{{ formatDate(date) }}</td>
        <td>
          <div class="actions">
            <span class="deleteStudent" @click="() => deleteStudent(index)">
              <DeleteIcon />
            </span>
          </div>
        </td>
      </tr>
    </table>

    <b-row>
      <b-col md="6" class="ml-auto mt-5">
        <b-button @click="closeModal" class="large blue outline">Voltar</b-button>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Datepicker from "vuejs-datepicker";
import * as lang from "vuejs-datepicker/src/locale";

import StandardSelect from "@/components/inputs/StandardSelect.vue";
import DeleteIcon from "@/components/svg/DeleteIcon";

export default {
  name: "",
  components: {
    StandardSelect,
    DeleteIcon,
    Datepicker,
  },

  props: {
    callbackAssign: {
      type: Function,
      required: true,
    },

    studentsToEdit: {
      type: Array,
      default: () => [],
    },

    cycleToFilter: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      date: "",
      language: "ptBR",
      languages: lang,
      loadingList: false,
      searchStudent: "",
      studentToAssign: null,
      studentsAssigneds: [],
      disabledDates: {
        to: new Date(),
      },
      userStopedTyping: () => {
        "";
      },
    };
  },

  computed: {
    ...mapGetters({
      students: "community/students",
    }),

    studentsNamed() {
      return this.students
        .filter((s) =>
          this.cycleToFilter ? s.cycle_id === this.cycleToFilter : true
        )
        .filter(
          (s) => !this.studentsAssigneds.some((sa) => sa.student.id === s.id)
        )
        .map((s) => ({ ...s, name: s.User.name }));
    },
  },

  methods: {
    ...mapActions({
      getStudents: "community/listStudents",
    }),

    assignStudent() {
      const unformattedDate = this.date;
      const student = this.studentToAssign;

      if (!unformattedDate || !student || !student.id) return;

      const date = this.formatDateToPost(unformattedDate);

      this.studentsAssigneds.push({
        student,
        date,
      });
    },

    setSearch(string) {
      this.searchStudent = string;
    },

    selectStudent(student) {
      this.studentToAssign = student;
    },

    setDate(date) {
      this.date = date;
    },

    formatDate(date) {
      return new Intl.DateTimeFormat("pt-BR", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      }).format(new Date(date.replaceAll("-", "/")));
    },

    deleteStudent(index) {
      const newStudentList = [...this.studentsAssigneds];

      newStudentList.splice(index, 1);

      this.studentsAssigneds = newStudentList;
    },

    changeModalState() {
      const simplifiedStudentsAssigneds = this.studentsAssigneds.map(
        ({ date, student }) => {
          const newDate = this.formatDateToPost(date);

          return { date: newDate, student: student.id };
        }
      );

      this.callbackAssign(simplifiedStudentsAssigneds);
    },

    closeModal() {
      this.$root.$emit("bv::hide::modal", "tutoria");
    },

    formatDateToPost(date) {
      const correctDate = typeof date === "object" ? date : date.split("-");
      return new Intl.DateTimeFormat("ko-KR", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      })
        .format(new Date(correctDate))
        .replaceAll(". ", "-")
        .replace(".", "");
    },
  },

  watch: {
    searchStudent() {
      const search = this.searchStudent;
      this.loadingList = true;

      clearTimeout(this.userStopedTyping);

      this.userStopedTyping = setTimeout(() => {
        this.loadingList = false;
        if (search.length > 2) this.getStudents({ search });
      }, 2000);
    },

    studentsToEdit() {
      this.studentsAssigneds = JSON.parse(JSON.stringify(this.studentsToEdit));
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/css/variables.scss";

div#tutoria {
  .modal-dialog {
    max-width: 820px;

    .modal-content {
      background-color: $grey2;
      border: 0;
      border-radius: 0;

      .modal-body {
        padding: 60px;
      }
    }
  }

  .table-students {
    width: 100%;

    td,th {
      font-size: 18px;
    }

    th {
      padding-bottom: 20px;
    }

    td {
      padding: 20px 0;
    }

    tr td:last-of-type {
      text-align: right;

      .actions {
        span {
          cursor: pointer;
        }

        .deleteStudent {
          margin-left: 30px;
        }
      }
    }

    tr + tr {
      border-bottom: 1px solid $grey5;
    }
  }

  .vdp-datepicker input {
    position: relative;
    background: $white;
    color: $grey4;
    font-size: 16px;
    line-height: 34px;
    height: auto;
    border: 1px solid $grey10;
    border-radius: 0.25rem;
    width: 100%;
    padding: 0.375rem 0.75rem;
  }
}
</style>