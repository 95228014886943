<template>
  <div class="home">
    <template>
      <b-container class="container-rda">
        <b-row>
          <b-col>
            <b-breadcrumb :items="breadcrumb"></b-breadcrumb>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <h2 class="purple bold">
              Contratos inadimplentes
              <span class="qty"
                >({{ count }} {{ count > 1 ? "listados" : "listado" }})</span
              >
            </h2>
          </b-col>
          <b-col class="text-right"></b-col>
          <b-col class="text-right">
            <b-button class="large blue outline" @click="openModal"
              >Adicionar aluno</b-button
            >
          </b-col>
        </b-row>
        <b-row class="mt-50">
          <b-col md="2">
            <SelectCalendar
              label="Ano Letivo"
              placeholder="Selecione"
              v-model="calendar"
              :callback="callbackCalendars"
            ></SelectCalendar>
            <span
              class="clear-field"
              v-if="this.calendar != null"
              @click="clearCalendar"
              >Limpar filtro</span
            >
          </b-col>
          <b-col md="2">
            <SelectCycles
              label="Turma"
              placeholder="Selecione"
              v-model="cycle"
              :callback="callbackcycle"
            ></SelectCycles>
            <span
              class="clear-field"
              v-if="this.cycle != null"
              @click="clearCycle"
              >Limpar filtro</span
            >
          </b-col>
          <b-col>
            <SelectStudentsStatus
              v-model="is_active"
              :callback="callbackStudentsStatus"
            ></SelectStudentsStatus>
            <span
              class="clear-field"
              v-if="this.is_active != null"
              @click="clearStudentsStatus"
              >Limpar filtro</span
            >
          </b-col>
          <b-col>
            <b-form-group label="Buscar Aluno">
              <b-input-group-prepend>
                <b-form-input
                  type="search"
                  placeholder="Digite aqui"
                  v-model="searchTitle"
                  v-on:keyup.enter="
                    page = 1;
                    getList();
                  "
                ></b-form-input>
                <b-input-group-text
                  class="clear-fields"
                  v-if="searchTitle != ''"
                  v-b-tooltip.hover
                  title="Limpar Pesquisa"
                >
                  <b-icon icon="x" @click="clearFields" />
                </b-input-group-text>
              </b-input-group-prepend>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row align="center" v-if="loading">
          <b-col class="mt-5">
            <b-spinner variant="success" label="Carregando..."></b-spinner>
          </b-col>
        </b-row>

        <b-row v-else>
          <b-col>
            <b-container fluid>
              <b-row
                style="padding-top: 15px"
                class="column-list"
                v-if="count > 0 && isRequestSend"
              >
                <b-col md="3">
                  <p class="bold">Aluno</p>
                </b-col>
                <b-col>
                  <p class="bold">Matrícula</p>
                </b-col>
                <b-col>
                  <p class="bold">Status</p>
                </b-col>
                <b-col>
                  <p class="bold">Ciclo</p>
                </b-col>
                <b-col>
                  <p class="bold">Ano letivo</p>
                </b-col>
                <b-col md="2">
                  <p class="bold">Vezes inadimplente</p>
                </b-col>
                <b-col>
                  <p class="bold">Inadimplente</p>
                </b-col>
              </b-row>
            </b-container>
            <b-container fluid>
              <b-row
                align-v="center"
                class="list-contracts"
                v-for="enrollment in enrollments"
                :key="enrollment.id"
              >
                <b-col md="3">
                  <p v-if="enrollment.name">
                    {{ enrollment.name.substring(0, 40) }}
                  </p>
                </b-col>
                <b-col>
                  <p>{{ enrollment.enrollment_id }}</p>
                </b-col>
                <b-col>
                  <p>{{ enrollment.is_active ? "Ativo(a)" : "Inativo(a)" }}</p>
                </b-col>
                <b-col>
                  <p v-if="enrollment.cycle">
                    {{ enrollment.cycle }}
                  </p>
                </b-col>

                <b-col>
                  <p style="padding-left: 15%" v-if="enrollment.year">
                    {{ enrollment.year }}
                  </p>
                  <p style="padding-left: 15%" v-else>-</p>
                </b-col>
                <b-col md="2">
                  <p style="text-align: center; padding-right: 20%">
                    {{ enrollment.number_defaulters }}
                  </p>
                </b-col>
                <b-col class="status">
                  <b-form-group>
                    <b-form-checkbox
                      v-model="enrollment.is_defaulter"
                      switch
                      @input="removeFromDefaulters(enrollment.enrollment_id)"
                    ></b-form-checkbox>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row
                align-v="center"
                class="list-teacher"
                v-if="enrollments.length <= 0 && isRequestSend"
              >
                <b-col>Nenhum resultado encontrado</b-col>
              </b-row>
              <b-row
                align-v="center"
                class="list-teacher"
                v-if="!isRequestSend"
              >
                <b-col>Carregando</b-col>
              </b-row>
              <b-row
                class="align-center"
                v-else-if="count > perPage && isRequestSend"
              >
                <b-pagination
                  v-model="page"
                  :per-page="perPage"
                  :total-rows="count"
                  @change="changePage"
                  aria-controls="teachersList"
                  last-text
                  first-text
                >
                  <template #prev-text>
                    <LeftArrowIcon class="left" />
                  </template>
                  <template #next-text>
                    <LeftArrowIcon />
                  </template>
                </b-pagination>
              </b-row>
            </b-container>
          </b-col>
        </b-row>

        <b-row class="align-center" v-if="!loading && enrollments.length > 0">
          <b-pagination
            v-model="currentPage"
            :per-page="20"
            :total-rows="count"
            last-text
            first-text
          >
            <template #prev-text>
              <LeftArrowIcon class="left" />
            </template>
            <template #next-text>
              <LeftArrowIcon />
            </template>
          </b-pagination>
        </b-row>
      </b-container>

      <b-modal
        id="modal-add-defaulter"
        hide-footer
        style="display: flex; justify-content: center; align-items: center"
        title="Adicionar aluno inadimplente"
        size="xl"
        @hide="clearStudentField"
      >
        <b-form-group>
          <StandardSelect
            :callbackSelect="selectStudent"
            :searchChange="setSearch"
            :options="students"
            :isLoading="loadingList"
            label="Buscar Aluno"
            placeholder="Digite aqui"
          />

          <a
            class="large"
            @click="clearStudentField"
            style="
              cursor: pointer;
              color: #b23066;
              font-weight: bold;
              padding-top: 10px;
            "
            ><u>Limpar seleção</u></a
          >
        </b-form-group>
        <b-col>
          <b-form-group class="calendar">
            <div
              style="padding-top: 15px"
              v-if="this.student && this.student.id && !alreadyPresented"
            >
              <label style="font-weight: bold">Aluno</label>
              <p class="grey4">
                {{ student.userName }}
              </p>
              <label style="font-weight: bold">Ciclo</label>
              <p>{{ student.cycle }}</p>
            </div>
            <p
              style="padding-top: 15px"
              v-else-if="!this.student && alreadyPresented"
              class="grey4"
            >
              Nenhum aluno foi selecionado
            </p>
          </b-form-group>
        </b-col>
        <b-col
          class="text-right"
          v-if="this.student && this.student.id && !alreadyPresented"
        >
          <b-button class="large blue outline" @click="addDefaulterStudent"
            >Adicionar</b-button
          >
        </b-col>
        <p
          class="purple bold"
          v-else-if="this.student && this.student.id && alreadyPresented"
          style="padding-top: 15px"
        >
          O aluno(a) {{ this.student.userName }} já está na lista de alunos
          inadimplentes
        </p>
      </b-modal>
    </template>
  </div>
</template>

<script>
import LeftArrowIcon from "@/components/svg/LeftArrowIcon.vue";
import SelectCalendar from "@/components/inputs/SelectCalendar.vue";
import SelectCycles from "@/components/inputs/SelectCycles.vue";
import StandardSelect from "@/components/inputs/StandardSelect.vue";
import SelectStudentsStatus from "@/components/inputs/SelectStudentsStatus.vue";
import httpRda from "../../http-rda";

export default {
  name: "lista-contratos",
  components: {
    SelectCalendar,
    SelectCycles,
    StandardSelect,
    SelectStudentsStatus,
    LeftArrowIcon,
  },
  data() {
    return {
      enrollments: [],
      isRequestSend: false,
      student: null,
      alreadyPresented: true,

      calendar: null,
      cycle: null,
      searchTitle: "",
      is_active: null,

      students: [],
      loadingList: false,

      page: this.$route.params.page || 1,
      count: 0,
      perPage: 20,

      contract: "actives",

      loading: false,

      currentPage: 1,

      breadcrumb: [
        {
          text: "Secretaria",
          href: "/secretaria",
        },
        {
          text: "Contratos inadimplentes",
          href: "/contratos-inadimplentes",
          active: true,
        },
      ],
    };
  },

  computed: {},

  methods: {
    async addDefaulterStudent() {
      this.loading = true;
      await httpRda
        .post("/enrollments/defaulters", {
          id: this.student.id,
        })
        .then(() => {
          this.$bvModal.hide("modal-add-defaulter");
          this.loading = false;

          const Toast = this.$swal.mixin({
            toast: true,
            position: "bottom-end",
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer);
              toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "success",
            title: "Aluno adicionado com sucesso!",
          });

          this.getList();
        })
        .catch((error) => {
          this.loading = false;
          this.$swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Não foi possivel adicionar o aluno!",
            footer: "Por favor, verifique se o aluno já está na lista.",
          });
          return error;
        });

      this.getList();
    },

    openModal() {
      this.getStudents();
      this.$bvModal.show("modal-add-defaulter");
    },

    clearStudentField() {
      this.student = null;
    },

    callbackStudentsStatus({ id }) {
      this.is_active = id ? "true" : "false";
      this.page = 1;
      this.getList();
    },

    async getStudents() {
      if (this.students.length === 0) {
        this.students = await httpRda
          .get("/students?include=cycles")
          .then((resp) => {
            return resp.data.students.map((student) => {
              return {
                id: student.id,
                name:
                  "Nome: " +
                  student.User.name +
                  "; Ciclo: " +
                  (student.Cycle ? student.Cycle.designation : "Sem ciclo"),
                userName: student.User.name,
                cycle: student.Cycle ? student.Cycle.designation : "Sem ciclo",
              };
            });
          });
      }
    },

    setSearch(string) {
      this.searchStudent = string;
    },

    async selectStudent(student) {
      this.student = "";

      const isDefaulter = await httpRda
        .get(`/enrollments/${student.id}/defaulters`)
        .then((resp) => resp.data.enrollmentDefaulter.is_defaulter);

      this.alreadyPresented = isDefaulter;

      this.student = student;
    },

    getRequestParams(
      searchTitle,
      page,
      perPage = 20,
      calendar,
      cycle,
      is_active
    ) {
      let params = {};

      if (searchTitle) {
        params["search"] = searchTitle;
      }

      if (page) {
        params["page"] = page;
      }

      if (perPage) {
        params["limit"] = perPage;
      }

      if (calendar) {
        params["calendar_id"] = calendar;
      }

      if (is_active) {
        params["is_active"] = is_active;
      }

      if (cycle && cycle != -1) {
        params["cycle_id"] = cycle;
      }

      return params;
    },

    getList(page = 1) {
      this.page = page;
      this.loading = true;
      const params = this.getRequestParams(
        this.searchTitle,
        this.page,
        this.perPage,
        this.calendar,
        this.cycle,
        this.is_active
      );

      this.isRequestSend = false;
      httpRda.get("/enrollments/defaulters", { params }).then((resp) => {
        this.enrollments = resp.data.enrollmentDefaulters.results;
        this.count = resp.data.enrollmentDefaulters.count;
        this.isRequestSend = true;
      });
      this.loading = false;
    },

    changePage(page) {
      this.page = page;
      this.getList();
      window.scrollTo({ top: 0, behavior: "smooth" });
    },

    callbackCalendars({ id }) {
      this.calendar = id;
      this.page = 1;
      this.getList();
    },

    callbackcycle({ id }) {
      this.cycle = id;
      this.page = 1;
      this.getList();
    },

    selectStatus({ value }) {
      this.printed = value;
      this.status = value;
      this.page = 1;
      this.getList();
    },

    getInactives(printed) {
      this.printed = printed;
      this.contract = "inactives";
      this.page = 1;
      this.getList();
    },

    clearFields() {
      this.searchTitle = "";
      this.page = 1;
      this.getList();
    },

    clearStudentsStatus() {
      this.is_active = null;
      this.page = 1;
      this.getList();
    },

    clearPrinted() {
      this.printed = "true";
      this.page = 1;
      this.getList();
    },

    clearCalendar() {
      this.calendar = null;
      this.page = 1;
      this.getList();
    },

    clearCycle() {
      this.cycle = null;
      this.page = 1;
      this.getList();
    },

    isAllowed() {
      return (
        this.currentUser.role.includes("admin") ||
        this.currentUser.role.includes("secretary")
      );
    },

    async removeFromDefaulters(enrollmentId) {
      this.$swal
        .fire({
          title: `Deseja remover a matrícula ${enrollmentId} da lista de alunos inadimplentes?`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#68c5b7",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim",
          cancelButtonText: "Cancelar",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            this.loading = true;
            await httpRda
              .patch(`/enrollments/defaulters/${enrollmentId}`, {
                is_defaulter: false,
              })
              .then(() => {
                this.getList();
                this.loading = false;
              })
              .catch(() => {
                this.loading = false;
                this.$swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Não foi possivel atualizar!",
                  footer: "Por favor, tente novamente mais tarde.",
                });
              });
            this.loading = false;
          } else {
            this.getList();
          }
        })
        .catch(() => {
          this.$swal.fire({
            icon: "error",
            title: "ok",
            text: "Ação não realizada!",
            footer: "Por favor, tente novamente mais tarde.",
          });
        });
      this.loading = false;
    },
  },

  watch: {
    currentPage() {
      this.getList(this.currentPage);
    },
  },

  created() {
    this.getList();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/variables.scss";

.list-contracts {
  background-color: $white;
  border: 1px solid $grey5;
  border-radius: 10px;
  margin-bottom: 15px;
  min-height: 80px;
  margin-right: -12px;
  &:last-child {
    margin-bottom: 0px;
  }
  &:hover {
    cursor: pointer;
    border-color: transparent;
    background-image: linear-gradient($white, $white),
      radial-gradient(circle at top left, $orange, $purple);
    background-origin: border-box;
    background-clip: content-box, border-box;
  }
  p {
    margin: 0;
  }
}

.header-list {
  color: $grey1;
  p {
    margin: 0;
  }
  .tags {
    background-color: $blue;
    border-radius: 5px;
    color: $white;
    line-height: 28px;
    max-width: 58px;
    text-align: center;
  }
}

.column-list {
  color: $grey1;
}

.status-box {
  margin: 0 0 60px;
}
</style>
