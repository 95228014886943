import axios from "axios";

const getAddressByCep = async (cep) => {
  const response = await axios
    .get(`https://viacep.com.br/ws/${cep}/json`)
    .then((res) => {
      if (res.data.erro) {
        throw new Error("CEP não encontrado");
      }
      return res.data;
    });
  return response;
};

export default getAddressByCep;
