<template>
    <b-container class="container-rda">
        <b-row>
            <b-col>
                <b-breadcrumb :items="breadcrumb"></b-breadcrumb>
            </b-col>
        </b-row>

        <b-row>
            <b-col>
                <h2 class="purple bold title">Alertas do Informe Periódico</h2>
            </b-col>
        </b-row>

        <b-row>
            <b-col>
                <b-container fluid>
                    <b-row align-v="center" class="list-teacher" v-for="notification in bimontlyReportNotifications"
                        :key="notification.id" @click="redirect(notification)">
                        <b-col md="11">
                            <p>
                                <strong id="notificatoin-body-text">A {{
                                    notification.context.user_coordinator_name
                                }}
                                </strong>
                                solicitou o
                                preenchimento
                                {{ notification.type === 'period_tutor' ? ' do informe periódico' : ' das avaliações' }}
                                do(a) aluno(a)
                                <strong>
                                    {{
                                        notification.context.student_name
                                    }}
                                </strong>
                                {{ notification.created_at }}
                            </p>
                        </b-col>
                        <b-col v-show="!notification.readed_at"
                            style="display: flex; align-items: center; justify-content: center;">
                            <div class="notification-circle"></div>
                        </b-col>
                    </b-row>

                    <b-row align-v="center" class="list-teacher"
                        v-if="wasRequestMade && !bimontlyReportNotifications.length">
                        <b-col>Nenhum resultado encontrado</b-col>
                    </b-row>
                    <b-row align-v="center" class="list-teacher" v-if="!wasRequestMade">
                        <b-col>Carregando</b-col>
                    </b-row>
                    <b-row class="align-center" v-if="bimontlyReportNotifications && wasRequestMade">
                        <b-pagination v-model="currentPage" :per-page="perPage" :total-rows="count"
                            aria-controls="teachersList" last-text first-text>
                            <template #prev-text>
                                <LeftArrowIcon class="left" />
                            </template>
                            <template #next-text>
                                <LeftArrowIcon />
                            </template>
                        </b-pagination>
                    </b-row>
                </b-container>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import { mapGetters } from "vuex";
import LeftArrowIcon from "@/components/svg/LeftArrowIcon.vue";
import httpRda from "../../http-rda";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faArrowAltCircleRight } from "@fortawesome/free-regular-svg-icons";
import moment from 'moment-timezone';

library.add(faArrowAltCircleRight);

export default {
    name: "alertas",
    components: {
        LeftArrowIcon,
    },

    data() {
        return {
            bimontlyReportNotifications: [],
            currentPage: 1,
            count: 0,
            perPage: 10,
            wasRequestMade: true,
            breadcrumb: [
                {
                    text: "Professor",
                    href: "/professor",
                },
                {
                    text: "Alertas",
                    href: "/alertas",
                    active: true,
                },
            ],
        };
    },

    computed: {
        ...mapGetters({
            user: "auth/getAuthData",
        }),
    },

    methods: {

        async markBimontlyReportAsRead(notification) {
            if (notification.readed_at) {
                return;
            }

            httpRda.patch(`/bimontly-report/${this.user.id}/notifications`, {
                ids: [notification.id]
            });
        },

        async redirect(notification) {
            if (notification.type === 'period_tutor') {
                await this.markBimontlyReportAsRead(notification);
                this.$router.push({
                    name: 'tutoria-aluno',
                    params: { id: notification.context.student_user_id }
                });
            } else {
                await this.markBimontlyReportAsRead(notification);
                this.$router.push({
                    name: 'registrar-aprendizagens',
                    params: {
                        id: notification.context.student_id,
                        subject_id: notification.context.subject_id
                    }
                });
            }
        },

        formatNotificationDate(date) {
            const now = moment.tz("America/Sao_Paulo");
            const created_at = moment.tz(date, "America/Sao_Paulo").startOf("day");
            const daysAgo = now.startOf("day").diff(created_at, "days");
            let result;

            if (daysAgo === 1) {
                result =
                    " ontem às " + moment.tz(date, "America/Sao_Paulo").format("HH:mm");
            } else if (daysAgo === 0) {
                result =
                    " hoje às " + moment.tz(date, "America/Sao_Paulo").format("HH:mm");
            } else {
                result =
                    daysAgo +
                    " dias atrás às " +
                    moment.tz(date, "America/Sao_Paulo").format("HH:mm");
            }

            return result;
        },
        async getBimontlyReportNotifications() {
            this.wasRequestMade = true;
            if (!this.user.id) {
                return;
            }

            await httpRda.get(`/bimontly-report/${this.user.id}/notifications`, {
                params: { page: this.currentPage, perPage: this.perPage },
            }).then((response) => {
                this.bimontlyReportNotifications = response.data.result.data;
                this.count = response.data.result.total;
            });

            this.bimontlyReportNotifications.map((notification) => {
                notification.created_at = this.formatNotificationDate(
                    notification.created_at
                );
                return notification;
            });

            this.wasRequestMade = true;
        },
    },

    watch: {
        currentPage() {
            this.getBimontlyReportNotifications();
            window.scrollTo({ top: 0, behavior: "smooth" });
        },
    },

    async created() {
        this.getBimontlyReportNotifications();
    },
};
</script>

<style lang="scss">
@import "@/assets/css/variables.scss";

.title {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .tags_count {
        background-color: #68c5b7;
        font-size: 18px;
        border-radius: 3px;
        color: #fff;
        margin-left: 10px;
        padding: 3px 10px;
        height: max-content;
    }
}

.title-col {
    margin-top: 15px;
}

.list-top {
    margin-top: 30px;
    margin-bottom: 30px;
}

.not-bold {
    font-weight: normal;
}

.list-teacher {
    cursor: pointer;
    background-color: $white;
    border: 1px solid $grey5;
    border-radius: 10px;
    margin-bottom: 15px;
    min-height: 80px;

    &:hover {
        border-color: transparent;
        background-image: linear-gradient($white, $white),
            radial-gradient(circle at top left, $orange, $purple);
        background-origin: border-box;
        background-clip: content-box, border-box;
    }

    p {
        margin: 0;
    }

    .marked {
        border: 2px #b23066 solid;
        border-radius: 10px;
    }

    .disciplines {
        .name {
            display: inline-block;

            .comma {
                margin-right: 4px;
            }

            &:last-child {
                .comma {
                    display: none;
                }
            }
        }
    }

    .cycles {
        display: flex;
        flex-wrap: wrap;

        .tags {
            background-color: $blue;
            border-radius: 5px;
            color: $white;
            font-weight: $bold;
            line-height: 28px;
            margin: 3px 12px 3px 0;
            max-width: 58px;
            padding: 0 3px;
            text-align: center;
            vertical-align: middle;
        }
    }
}

.outline {
    color: #68c5b7 !important;
    background: transparent;
    max-width: 220px !important;
    margin-right: 20px;

    &:hover {
        color: #fff !important;
    }
}

.changes {
    padding: 16px;
    margin: 20px 20px 0;
    border-top: 1px solid #ccc;
    box-sizing: border-box;

    .title {
        font-size: 16px;
        margin-bottom: 10px;
        font-weight: bold;
    }
}

.notification-circle {
    width: 12px;
    height: 12px;
    border-radius: 10px;
    background-color: rgb(233, 69, 19);
}
</style>
