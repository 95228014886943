import api from "../../http-rda";

const createEnrollment = async (_, { ...enrollment }) => {
  return api.post("/enrollments", enrollment);
};

const resetToReenrollment = async (_, { enrollment_id, ...params }) => {
  return api.patch(`/enrollments/${enrollment_id}`, params);
};

const resetToReenrollmentStudent = async (_, { enrollment_id, ...params }) => {
  return api.post(`/enrollments/${enrollment_id}/student`, params);
};

const listEnrollments = async ({ commit }, responsible_id) => {
  const res = await api.get(`/responsibles/${responsible_id}/enrollments`);
  commit("SET_ENROLLMENTS", res.data);

  return res;
};

const getEnrollment = async ({ commit }, id) => {
  const res = await api.get(`/enrollments/${id}`);
  const resCycles = await api.get(
    `/cycles/${res.data.enrollment.EnrollmentStudent.cycle_id}`
  );
  const resBrothers = await api.get(`/enrollments/${id}/student/brothers/`);

  commit("SET_ENROLLMENT", res.data);
  commit("SET_STUDENT_BROTHERS", resBrothers.data);
  commit("SET_CYCLES", resCycles.data);

  return res;
};

// -------------------- Students
const saveAddress = async (_, { address, enrollment_id }) => {
  return api.post(`/enrollments/${enrollment_id}/student/address`, address);
};

const addSibling = async (_, { sibling, id }) => {
  return api.post(`/enrollments/${id}/student/brothers/`, sibling);
};

const removeSibling = async (_, { sibling_id, id }) => {
  return api.delete(`/enrollments/${id}/student/brothers/${sibling_id}`);
};

const updateSibling = async (_, { sibling, id }) => {
  return api.patch(
    `/enrollments/${id}/student/brothers/${sibling.id}`,
    sibling
  );
};

const saveStudent = async (_, { student, enrollment_id }) => {
  return api.post(`/enrollments/${enrollment_id}/student`, student);
};

const saveStudentPhoto = async (_, { photo, id }) => {
  if (!photo || typeof photo === "string") return;

  return api.post(`enrollments/${id}/student/`, photo, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

// -------------------

// -------------------- Contractor and Filiation
const createContractorFiliation = async (
  _,
  { id, data, type = "contractor", option = "a" }
) => {
  return api.post(`/enrollments/${id}/${type}/${option}`, data);
};

const createContractorFiliationAddress = async (
  _,
  { id, data, type = "contractor", option = "a" }
) => {
  return api.post(`/enrollments/${id}/${type}/${option}/address`, data);
};

const updateResponsibleType = async (_, { id, id_responsible, type }) => {
  return api.post(`/enrollments/${id}/responsibles/${id_responsible}/types`, {
    type,
  });
};

const deleteResponsibleType = async (_, { id, id_responsible, type }) => {
  return api.delete(
    `/enrollments/${id}/responsibles/${id_responsible}/types?type=${type}`
  );
};

const deleteResponsible = async (_, { id, option = "a" }) => {
  return api.delete(`/enrollments/${id}/filiation/${option}`);
};
// -------------------

// -------------------- Complementares
const updateComplement = async (_, { complement, id }) => {
  return api.post(`/enrollments/${id}/complement/`, complement);
};
const createContact = async (_, { contact, id }) => {
  return api.post(`/enrollments/${id}/contacts/`, contact);
};

const updateContact = async (_, { contact, id }) => {
  return api.patch(`/enrollments/${id}/contacts/${contact.id}`, contact);
};

const deleteContact = async (_, { contact_id, id }) => {
  return api.delete(`/enrollments/${id}/contacts/${contact_id}`);
};

const savePhotoContact = async (_, { id, photo, contact_id }) => {
  if (!photo || typeof photo === "string") return;
  const bodyFormData = new FormData();
  bodyFormData.append("file", photo);

  return api.patch(`enrollments/${id}/contacts/${contact_id}`, bodyFormData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};
// --------------------
// -------------------- Authorization
const updateAuthorization = async (_, { authorization, id }) => {
  return api.post(`/enrollments/${id}/authorization/`, authorization);
};
// --------------------
// -------------------- Financial
const updateFinance = async (_, { finance, id }) => {
  return api.post(`/enrollments/${id}/finance/`, finance);
};
// --------------------
// -------------------- Print
const downloadContract = async (_, { id }) => {
  return api.get(`/enrollments/${id}/contract/download`, {
    responseType: "blob",
  });
};
// --------------------
// -------------------- Submit
const submitDocument = async (_, { data, id }) => {
  return api.post(`/enrollments/${id}/files`, data, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

const finishEnrollment = async (_, { id }) => {
  return api.post(`/enrollments/${id}/finish`);
};

const requestContractOnClickSign = async (_, { id }) => {
  return api.get(`/enrollments/${id}/contract/download`);
};

// --------------------
// -------------------- Geral
const savePhotoGeneric = async (_, { id, photo, url }) => {
  if (typeof photo === "string") return;
  const bodyFormData = new FormData();
  bodyFormData.append("file", photo);

  return api.post(`enrollments/${id}/${url}`, bodyFormData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

const createResponsible = async (_, data) => {
  return api.post("/responsibles", data);
};

const createParent = async (_, data) => {
  return api.post("/parents", data);
};

export default {
  createEnrollment,
  resetToReenrollment,
  resetToReenrollmentStudent,
  listEnrollments,
  getEnrollment,
  saveStudent,
  saveAddress,
  addSibling,
  removeSibling,
  updateSibling,
  saveStudentPhoto,

  createContractorFiliation,
  createContractorFiliationAddress,
  updateResponsibleType,
  deleteResponsibleType,
  deleteResponsible,

  updateComplement,
  createContact,
  updateContact,
  deleteContact,
  savePhotoContact,

  updateAuthorization,
  updateFinance,
  downloadContract,
  submitDocument,
  finishEnrollment,
  requestContractOnClickSign,

  savePhotoGeneric,
  createResponsible,
  createParent,
};
