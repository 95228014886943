<template>
  <b-container class="container-rda">
    <b-row class="pt-5 mt-4 mb-5" v-if="this.hasStudents.Students.length > 0">
      <b-col>
        <h2 class="purple bold">Bem-vindo(a)!</h2>
      </b-col>
      <b-col md="12">
        <h3>Selecione o perfil desejado:</h3>
      </b-col>
    </b-row>
    <b-row class="pt-5 mt-4 mb-5" v-if="this.responsiblesStudent.Students.length == 0">
      <b-col>
        <h2 class="purple bold">Não há Alunos matriculados.</h2>
        <p>
          Por favor, aguarde. Vocês será redirecionado para realizar a
          Matricula.
        </p>
      </b-col>
    </b-row>
    <b-row v-for="responsible in responsibles" :key="responsible.id">
      <b-col md="3" v-for="student in responsible.Students" :key="student.id">
        <div class="dash-profile" :class="{ active: selectedIndex == student.id }">
          <router-link :to="{
            name: 'aluno-perfil',
            params: { id: student.User.id },
          }">
            <EditIcon2 />
          </router-link>
          <img v-if="student.User.photo == null || ''" src="@/assets/images/default-photo.png" height="64" width="64"
            alt />
          <img v-else width="64" height="64" :src="`${student.User.photo}`" alt />
          <h5>{{ student.User.name }}</h5>
          <p v-if="student && student.Classroom && student.Classroom.year_id">
            {{ student.Classroom.year_id }}º ano do Ciclo:
            {{ getCycleName(student.cycle_id) }}
          </p>

          <p v-else>
            {{ getCycleName(student.cycle_id) }}
          </p>
          <b-form-group class="group-radio">
            <div>
              <b-form-radio name="profile" value="profile-1" @change="setSelected(student, student.id)">Selecionar
                perfil</b-form-radio>
            </div>
          </b-form-group>
        </div>
      </b-col>
    </b-row>
    <b-row class="mt-5 pt-5" v-if="selected">
      <b-col v-if="availableReportData">
        <router-link class="dash-box inform-periodic" :to="{
          name: 'informe-periodico-responsavel',
          params: { id: selected.id },
        }">
          <p>Informe</p>
          <h1>Periódico</h1>
        </router-link>
      </b-col>
      <b-col>
        <router-link class="dash-box contents" :to="{
          name: 'registrar-aprendizagem',
          params: { id: selected.id },
        }">
          <p>Conteúdos</p>
          <h1>{{ topics.count }}</h1>
        </router-link>
      </b-col>
      <b-col v-if="false">
        <router-link class="dash-box scripts" :to="{
          name: 'roteiros-aluno',
          params: { id: selected.id, student_id: selected.id },
        }">
          <p>Roteiros</p>
          <h1>{{ countSyllabuses }}</h1>
        </router-link>
      </b-col>
      <b-col>
        <router-link class="dash-box blue" :to="{
          name: 'faltas-e-presencas',
          params: { student_id: selected.id },
        }">
          <p>Faltas</p>
          <h1>{{ attendances.absences }}</h1>
        </router-link>
      </b-col>
      <b-col>
        <router-link class="dash-box comments" :to="{
          name: 'faltas-e-presencas',
          params: { student_id: selected.id },
        }">
          <p>Frequência</p>
          <h1>{{ attendances.frequency }}%</h1>
        </router-link>
      </b-col>
      <b-col v-if="
        selected.Classroom.cycle_id != 8 && selected.Classroom.cycle_id != 6
      ">
        <router-link class="dash-box tutoring" :to="{
          name: 'dashboard-tutoria-aluno',
          params: { id: selectedIndex },
        }">
          <p>Ver</p>
          <h1>Tutoria</h1>
        </router-link>
      </b-col>
      <b-col v-if="
        selected.Classroom.cycle_id == 8 || selected.Classroom.cycle_id == 6
      ">
        <router-link class="dash-box tutoring" :to="{
          name: 'dashboard-timeline-aluno-parent',
          params: { id: selected.id },
        }">
          <p>Ver</p>
          <h1>Ed. Infantil</h1>
        </router-link>
      </b-col>
    </b-row>
    <b-row class="pt-5 mt-5 mb-4" v-if="selected">
      <b-col>
        <h3>Tarefas da semana</h3>
      </b-col>
    </b-row>
    <b-row v-if="selected">
      <b-col>
        <h5 class="mb-3 purple captalize">{{ month }}</h5>
      </b-col>
      <div class="w-100"></div>

      <b-col v-for="(day, index) in week" :key="day.label">
        <div @click="() => selectDay(day.fullDate)" class="dash-tasks"
          :class="{ active: index == selectedDate.getDay() }">
          <p>{{ day.label }}</p>
          <h1>{{ day.day }}</h1>
        </div>
      </b-col>
    </b-row>
    <b-row class="mt-5">
      <b-col>
        <carousel :perPageCustom="[
          [360, 3],
          [1366, 3],
        ]">
          <slide v-for="syllabus in syllabusesThisWeek" :key="syllabus.id">
            <div class="task-list" :class="{ date: syllabus.isToday }">
              <h5>Entrega {{ formatSyllabusDeadline(syllabus) }}</h5>
              <p>{{ syllabus.name }}</p>
              <p class="disciplines">{{ syllabus.subject.join(", ") }}</p>
            </div>
          </slide>
        </carousel>
      </b-col>
    </b-row>
    <b-row v-if="selected" class="pt-5 mt-5 mb-4">
      <b-col md="12">
        <h3>Conteúdos em estudo</h3>
      </b-col>
    </b-row>
    <b-row v-if="selected">
      <b-col v-if="!topicsFormated.length > 0">
        <p>Não há conteúdos em estudo</p>
      </b-col>
      <b-col v-else>
        <carousel :perPageCustom="[
          [360, 3],
          [1366, 6],
        ]" paginationColor="$grey5">
          <slide v-for="subject in topicsFormated" v-bind:key="subject.id">
            <div class="box-scripts">
              <b-row>
                <b-col><a @click="showModal(subject)" style="cursor: pointer">
                    <SubjectsIcon :value="subject.designation" />
                    <h5>{{ subject.designation }}</h5>
                  </a>
                </b-col>
              </b-row>
              <b-row align-v="center" no-gutters>
                <b-col>
                  <span v-if="subject.topics && subject.topics.length > 1">Há
                    <strong>{{ subject.topics.length }}</strong>
                    tópicos com status "EE"</span>
                  <span v-if="subject.topics && subject.topics.length == 1">Há
                    <strong>{{ subject.topics.length }}</strong>
                    tópicos com status "EE"</span>

                  <span v-if="subject.topics && subject.topics.length == 0">Não há tópicos</span>
                </b-col>

                <div class="w-100"></div>
              </b-row>
            </div>
          </slide>
        </carousel>
      </b-col>
    </b-row>
    <div v-if="selected">
      <SubjectsProgressBars :percentages="percentages" :subjects="subjects" :desiredPercentage="desiredPercentage" />
    </div>
    <b-modal id="modal-script" hide-footer style="display: flex; justify-content: center; align-items: center"
      v-if="subjectSelected" :title="subjectSelected.designation">
      <b-row class="rda-viewlist" v-for="(competence, index) in subjectSelected.competences"
        :key="`competence-${index}`">
        <b-col cols="12">
          <h5 class="title">{{ competence.description }}</h5>
        </b-col>
        <b-col cols="12" v-for="(topic, indexS) in competence.topics" :key="`student-${indexS}`">
          <div class="list-teacher item-rda-viewlist">
            <div class="cols text-col">
              <p>{{ topic }}</p>
            </div>

            <div class="cols">
              <p class="tags warning">EE</p>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-modal>
  </b-container>
</template>

<script>
import { mapGetters } from "vuex";
import DataService from "@/services/responsibles.service";
import DataServiceCycles from "@/services/cycles.service";
import DataServiceStudents from "@/services/students.service";
import SubjectsProgressBars from "@/components/SubjectsProgressBars.vue";
import { Carousel, Slide } from "vue-carousel";
import SubjectsIcon from "@/components/svg/SubjectsIcon.vue";
import EditIcon2 from "@/components/svg/EditIcon2.vue";
import httpRda from "../../http-rda";

export default {
  name: "Início",
  components: {
    Carousel,
    Slide,
    SubjectsIcon,
    EditIcon2,
    SubjectsProgressBars,
  },
  data() {
    return {
      availableReportData: false,
      subjectScript: null,
      topicsFormated: [],
      desiredPercentage: 10,
      subjectSelected: null,
      percentagesDefault: [
        { number: 10, class: false },
        { number: 20, class: false },
        { number: 30, class: false },
        { number: 40, class: false },
        { number: 50, class: false },
        { number: 60, class: false },
        { number: 70, class: false },
        { number: 80, class: false },
        { number: 90, class: false },
        { number: 100, class: false },
      ],
      percentages: [
        { number: 10, class: false },
        { number: 20, class: false },
        { number: 30, class: false },
        { number: 40, class: false },
        { number: 50, class: false },
        { number: 60, class: false },
        { number: 70, class: false },
        { number: 80, class: false },
        { number: 90, class: false },
        { number: 100, class: false },
      ],
      attendances: [],
      responsibles: [],
      subjects: [],
      syllabuses: [],
      topics: [],
      hasStudents: {
        Students: "",
      },
      responsiblesStudent: {
        Students: "",
      },

      countSyllabuses: "",
      selectedDate: new Date(),

      selected: null,
      selectedIndex: -1,

      comments: "actives",
    };
  },

  computed: {
    ...mapGetters({
      currentUser: "auth/getAuthData",
      gettersAuthData: "auth/getAuthData",
    }),

    month() {
      return this.formatDay(new Date());
    },

    getDay() {
      return new Date().getDay();
    },

    week() {
      return [
        {
          label: "dom",
          fullDate: this.getCorrectDate(0),
          day: this.formatDay(this.getCorrectDate(0), { day: "2-digit" }),
        },
        {
          label: "seg",
          fullDate: this.getCorrectDate(1),
          day: this.formatDay(this.getCorrectDate(1), { day: "2-digit" }),
        },
        {
          label: "ter",
          fullDate: this.getCorrectDate(2),
          day: this.formatDay(this.getCorrectDate(2), { day: "2-digit" }),
        },
        {
          label: "qua",
          fullDate: this.getCorrectDate(3),
          day: this.formatDay(this.getCorrectDate(3), { day: "2-digit" }),
        },
        {
          label: "qui",
          fullDate: this.getCorrectDate(4),
          day: this.formatDay(this.getCorrectDate(4), { day: "2-digit" }),
        },
        {
          label: "sex",
          fullDate: this.getCorrectDate(5),
          day: this.formatDay(this.getCorrectDate(5), { day: "2-digit" }),
        },
        {
          label: "sab",
          fullDate: this.getCorrectDate(6),
          day: this.formatDay(this.getCorrectDate(6), { day: "2-digit" }),
        },
      ];
    },

    syllabusesThisWeek() {
      return this.syllabuses
        .filter((sy) => this.checkDateOnRange(sy.SyllabusStudent.deadline))
        .map((sy) => {
          const deadlineDate = new Date(
            sy.SyllabusStudent.deadline.replaceAll("-", "/")
          ).getDate();
          const todayDate = this.selectedDate.getDate();

          return { ...sy, isToday: deadlineDate === todayDate };
        })
        .sort((a, b) => {
          const dateA = new Date(
            a.SyllabusStudent.deadline.replaceAll("-", "/")
          ).getDate();
          const dateB = new Date(
            b.SyllabusStudent.deadline.replaceAll("-", "/")
          ).getDate();

          return dateA < dateB ? -1 : 1;
        });
    },
  },

  methods: {
    getCorrectDate(weekDay) {
      const days = weekDay - this.getDay;
      const result = new Date();
      result.setDate(result.getDate() + days);
      return result;
    },

    formatDay(day, options = { month: "long" }) {
      const date = new Date(day);
      return new Intl.DateTimeFormat("pt-BR", options).format(date);
    },

    checkDateOnRange(deadline) {
      const dateCheck = this.formatDay(deadline.replaceAll("-", "/"), {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });

      const dateFrom = this.formatDay(this.week[0].fullDate, {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
      const dateTo = this.formatDay(this.week[6].fullDate, {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });

      const d1 = dateFrom.split("/");
      const d2 = dateTo.split("/");
      const c = dateCheck.split("/");

      const from = new Date(d1[2], parseInt(d1[1]) - 1, d1[0]);
      const to = new Date(d2[2], parseInt(d2[1]) - 1, d2[0]);
      const check = new Date(c[2], parseInt(c[1]) - 1, c[0]);

      return check >= from && check <= to;
    },

    formatSyllabusDeadline(syllabus) {
      return this.formatDay(
        syllabus.SyllabusStudent.deadline.replaceAll("-", "/"),
        { year: "numeric", month: "2-digit", day: "2-digit" }
      );
    },

    selectDay(day) {
      this.selectedDate = day;
    },

    async getSubjectScript(student_id) {
      const subjects = await httpRda
        .get(`students/${student_id}/study_script`)
        .then((res) => res.data.subjectScript)
        .catch((err) => err);

      this.subjectScript = subjects;

      const topics = await subjects.reduce((acc, cur) => {
        const designation = cur.designation;
        const competences = cur.competences;

        let topics = cur.competences.reduce((acc, cur) => {
          const topics = cur.topics;
          acc.push(topics);
          return acc;
        }, []);

        topics = topics.flat();

        acc.push({ designation, competences, topics });

        return acc;
      }, []);

      this.topicsFormated = topics;
    },

    showModal(subject) {
      this.$bvModal.show("modal-script");
      this.subjectSelected = subject;
    },

    getSelected(id) {
      DataService.getResponsible(id)
        .then((response) => {
          this.responsibles = response.data;
          this.responsiblesStudent = response.data.responsible;

          this.hasStudents = response.data.responsible;
          if (this.hasStudents.Students.length == 0) {
            this.$router.push({ name: "matriculas" });
          }
        })
        .catch((error) => {
          return error;
        });
    },

    getParams(params) {
      DataServiceCycles.getAll(params)
        .then((response) => {
          this.cycles = response.data.cycles;
        })
        .catch((error) => {
          return error;
        });
    },

    getCycleName(id) {
      if (this.cycles) {
        const cycles = this.cycles.find((c) => c.id === id);
        return cycles ? cycles.name : "-";
      }
    },

    async setSelected(student) {
      this.selected = student;

      await httpRda.get(`bimontly-report/${student.id}/available-reports`).then((res) => {
        this.availableReportData = res.data.result;
      });

      this.selectedIndex = student.id;

      DataServiceStudents.getStudentAttendances(student.id)
        .then((response) => {
          this.attendances = response.data;
        })
        .catch((error) => {
          return error;
        });

      await DataServiceStudents.getStudentSubjects(student.id)
        .then((response) => {
          this.subjects = response.data.subjects;
          if (response.data && response.data.desiredPercentage) {
            this.percentages = [];
            this.percentages = [...this.percentagesDefault];

            this.desiredPercentage = Number(
              response.data.desiredPercentage
            );

            this.percentages.sort((a, b) => a.number - b.number);
          }
        })
        .catch((error) => {
          return error;
        });

      await DataServiceStudents.getStudentTopics(student.id)
        .then((response) => {
          this.topics = response.data;
        })
        .catch((error) => {
          return error;
        });

      await this.getSubjectScript(student.id);
    },
  },

  async created() {
    await this.currentUser.id;
    if (this.currentUser.id) {
      this.getSelected(this.currentUser.responsible_id);
      this.getParams();
    }
  },
};
</script>

<style lang="scss">
@import "@/assets/css/variables.scss";

.block {
  display: block;
}

.rda-viewlist {
  margin-bottom: 30px;

  .title {
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: #36b67d;
    margin-bottom: 12px;
  }

  .item-rda-viewlist {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;

    .cols {
      .tags {
        min-width: 40px;
      }
    }

    .text-col {
      flex: 1;
    }
  }
}

.dash-profile {
  border-radius: 10px;
  text-align: center;
  padding: 40px 15px;

  &.active {
    background-color: $grey6;
  }

  img {
    margin-bottom: 25px;
    border-radius: 50%;
  }

  h5 {
    margin-bottom: 12px;
  }

  .form-group {
    margin: 30px 0 0;
  }

  .group-radio {
    cursor: pointer;

    label {
      cursor: pointer;
    }
  }

  a {
    display: block;
    max-width: 128px;
    text-align: right;
    margin: 0 auto;
  }
}

.dash-box {
  border-radius: 19px;
  color: $white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 120px;
  padding: 15px 10px;
  text-align: center;

  p {
    margin-bottom: 10px;
  }

  h1 {
    margin: 0;
  }

  &.inform-periodic {
    background: -webkit-linear-gradient(left, #36b67d, #6883c5);

  }

  &.contents {
    background-color: $purple;
  }

  &.scripts {
    background-color: $green1;
  }

  &.comments {
    background-color: $orange;
  }

  &.tutoring {
    background-color: #4d94bd;
  }

  &.blue {
    background-color: $blue;
  }
}

.dash-parents {
  border-radius: 10px;
  color: $white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 120px;
  padding: 15px 10px;
  text-align: center;

  p {
    color: $white;
    margin-bottom: 10px;
  }

  h1 {
    color: $white;
    margin: 0;
  }

  &.orange {
    background-color: $orange;
  }

  &.blue {
    background-color: $blue;
  }

  &.grey {
    background-color: $grey5;
  }

  &.green1 {
    background-color: $green1;
  }

  &.green2 {
    background-color: $green2;
  }

  &.purple {
    background-color: $purple;
  }
}

.dash-tasks {
  background-color: $white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 120px;
  padding: 15px 10px;
  text-align: center;

  p {
    margin-bottom: 10px;
  }

  h1 {
    margin: 0;
    color: $grey4;
  }

  &:hover {
    background: $gradient1;
    color: $white;
    cursor: pointer;

    h1 {
      color: $white;
    }
  }

  &.active {
    background: $gradient1;
    color: $white;

    h1 {
      color: $white;
    }
  }
}

.task-list {
  background-color: $white;
  border-radius: 10px;
  padding: 20px;
  position: relative;

  h5 {
    color: $grey4;
    margin: 0 0 4px 0;
  }

  .disciplines {
    color: $grey4;
    font-size: 16px;
    margin: 0;
  }

  &.date {
    h5 {
      background: -webkit-linear-gradient(left, $orange, $purple);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    &:after {
      content: "";
      background: $gradient1;
      border-radius: 60px;
      display: block;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      width: 4px;
    }
  }
}

.owl-dots {
  .owl-dot {
    span {
      background-color: $grey5 !important;
      min-height: 22px;
      min-width: 22px;
      margin: 12px !important;
    }

    &.active {
      span {
        background: $gradient1 !important;
      }
    }
  }
}

.box-scripts {
  background-color: $white;
  border-radius: 19px;
  min-height: 169px;
  padding: 20px 25px 15px;
  text-align: center;
  justify-content: space-between;
  display: flex;
  flex-direction: column;

  svg {
    margin-bottom: 10px;
    height: 23px;
  }

  h5 {
    color: $grey4;
  }

  span {
    color: $grey3;
    display: block;
    font-size: 16px;
    text-align: left;
  }

  .progress {
    border-radius: 30px;
    height: 7px;

    .progress-bar {
      background-color: $purple;
    }

    &.orange {
      .progress-bar {
        background-color: $orange2;
      }
    }

    &.yellow {
      .progress-bar {
        background-color: $yellow;
      }
    }

    &.red {
      .progress-bar {
        background-color: $red;
      }
    }

    &.green {
      .progress-bar {
        background-color: $green2;
      }
    }
  }

  p {
    color: $grey4;
    font-size: 12px;
    margin: 0;
    text-align: left;
  }
}

.legend {
  color: $grey4;
  margin-bottom: 90px;

  svg {
    margin-right: 20px;
  }
}

.meetings {
  .tags {
    background-color: $blue;
    border-radius: 5px;
    color: $white;
    font-weight: $bold;
    line-height: 28px;
    max-width: 82px;
    text-align: center;
    width: 100%;
  }
}
</style>
