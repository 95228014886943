<template>
  <svg width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 1L10 10L1 19" stroke="#666666" stroke-width="2.5"/>
  </svg>
</template>

<script>
export default {
  
}
</script>

<style lang="sass">

</style>