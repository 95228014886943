<template>
  <b-form-group :label="label">
    <multiselect
      :value="computedValue"
      :options="myOptions"
      :multiple="false"
      :close-on-select="true"
      :clear-on-select="false"
      :preserve-search="true"
      :placeholder="placeholder"
      label="designation"
      track-by="id"
      selectLabel=""
      selectedLabel=""
      deselectLabel=""
      @select="callback"
    >
      <span slot="noResult">Nada encontrado</span>
    </multiselect>
  </b-form-group>
</template>

<script>
import httpRda from "../../http-rda";

export default {
  props: {
    placeholder: {
      default: "Todos",
      type: String,
    },
    label: {
      type: String,
      default: "Turmas",
    },
    value: {},
    callback: {},
    cycle: null,
  },
  data() {
    return {
      options: "",
      classrooms: [],
    };
  },
  watch: {
    cycle(newValue) {
      this.getClassrooms(newValue);
    },
  },
  created() {
    this.getClassrooms();
  },
  methods: {
    getClassrooms() {
      const params = {
        cycle: this.cycle,
      };

      if (params.cycle === -1) {
        delete params.cycle;
      }
      httpRda
        .get(`/classrooms?is_editable=true`, { params })
        .then((response) => {
          this.classrooms = response.data.classrooms;
        })
        .catch((error) => {
          return error;
        });
    },
    clearAll() {
      this.classrooms = [];
    },
  },
  computed: {
    myOptions() {
      if (!this.classrooms.length) return [];

      return [...this.classrooms, { designation: "Todos", id: -1 }];
    },
    computedValue() {
      if (typeof this.value === "number") {
        return this.classrooms.find((c) => c.id == this.value);
      } else {
        return this.value;
      }
    },
  },
};
</script>
