const SYLLABUSES_UPDATE = (state, { syllabuses }) => {
  state.syllabuses = syllabuses;
};

const SET_SYLLABUS = (state, { syllabus }) => {
  state.syllabus = syllabus;
};

const SET_SYLLABUS_TOPICS = (state, { topics }) => {
  state.syllabusTopics = topics;
};

const SET_SYLLABUS_STUDENTS = (state, { syllabus_students }) => {
  state.syllabusStudents = syllabus_students.map(({ Student, deadline }) => ({ student: { ...Student, name: Student.User.name }, date: deadline }));
};

const CLEAR_SYLLABUS_TOPICS = (state) => {
  state.syllabusTopics = []
  state.syllabus = []
  state.syllabus_students = []
}

const CLEAR_SYLLABUS_STUDENTS = (state) => {
  state.syllabusesAndStudentsArray = []
}

const SET_STUDENTS_COMPETENCES = (state, competence) => {
  state.studentCompetences = competence
}

const SET_STUDENTS_SYLLABUSES = (state, { syllabuses }) => {
  state.studentSyllabuses = syllabuses
}

const SET_STUDENTS_AND_SYLLABUSES_ARRAY = (state, syllabus_students) => {
  state.syllabusesAndStudentsArray = syllabus_students
}

const SET_STUDENT_SUBJECTS = (state, { subjects }) => {
  state.studentSubjects = subjects
}

const SET_STUDENT = (state, { student }) => {
  state.student = student
}

const SET_SUBJECT_CONTENT = (state, { subjects }) => {
  state.subjectContent = subjects
}

const SET_SYLLABUS_CYCLE = (state, { subject }) => {
  state.syllabusCycle = subject.cycle_id
}

const SET_STUDENTS_BY_SYLLABUS = (state, { syllabus_students }) => {
  state.studentSyllabusList = syllabus_students
}

const SET_STUDENTS_BY_SUBJECT = (state, { students }) => {
  state.studentsBySubject = students
}

const RESET_STUDENTS_BY_SUBJECT = (state) => {
  state.studentsBySubject = []
}

export default {
  SYLLABUSES_UPDATE,
  SET_SYLLABUS,
  SET_SYLLABUS_TOPICS,
  SET_SYLLABUS_STUDENTS,
  CLEAR_SYLLABUS_TOPICS,
  CLEAR_SYLLABUS_STUDENTS,
  SET_SYLLABUS_CYCLE,

  SET_STUDENT,
  SET_STUDENTS_COMPETENCES,
  SET_STUDENTS_SYLLABUSES,
  SET_STUDENTS_AND_SYLLABUSES_ARRAY,
  SET_STUDENT_SUBJECTS,

  SET_SUBJECT_CONTENT,
  SET_STUDENTS_BY_SYLLABUS,
  SET_STUDENTS_BY_SUBJECT,
  RESET_STUDENTS_BY_SUBJECT
}