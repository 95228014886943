<template>
  <b-container class="container-rda">
    <b-row>
      <b-col>
        <b-breadcrumb :items="_"></b-breadcrumb>
      </b-col>
    </b-row>
    <b-col>
      <b-tabs class="tab-content" content-class="mt-5">
        <b-tab title="Meus dados">
          <b-row>
            <b-col>
              <h2 class="purple bold">Meus dados</h2>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-row class="profile-row">
                <b-col>
                  <img
                    v-if="!photo"
                    width="64"
                    src="@/assets/images/default-photo.png"
                    alt
                  />
                  <img v-else width="64" height="64" :src="photo" alt />

                  <div class="content-profile">
                    <h5>{{ currentUser.name }}</h5>
                    <p v-if="students.Cycle">
                      Ciclo: {{ students.Cycle.name }}
                    </p>
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row v-if="canEdit">
            <b-col>
              <StandardInput
                type="text"
                name="name"
                label="Nome"
                :value="`${users.name}`"
                disabled
              ></StandardInput>
            </b-col>
            <b-col>
              <StandardInput
                type="email"
                name="email"
                label="E-mail"
                :value="`${users.email}`"
              ></StandardInput>
            </b-col>
            <b-col>
              <StandardInput
                type="text"
                name="phone"
                label="Celular"
                :value="`${phone}`"
                disabled
              ></StandardInput>
            </b-col>
            <b-col md="3">
              <StandardInput
                type="text"
                name="birthday"
                label="Data de nascimento"
                :value="`${birthday}` | date"
                disabled
              ></StandardInput>
            </b-col>
            <div class="w-100"></div>
            <b-col class="title">
              <h5>Documentos</h5>
            </b-col>
            <div class="w-100"></div>
            <b-col md="3">
              <StandardInput
                type="text"
                name="cpf"
                label="CPF"
                v-mask="['###.###.###-##']"
                :value="`${users.cpf}`"
                :disabled="true"
              ></StandardInput>
            </b-col>
            <b-col md="3">
              <StandardInput
                type="text"
                name="rg"
                label="RG"
                :value="`${users.rg}`"
                disabled
              ></StandardInput>
            </b-col>
            <div class="w-100"></div>
            <b-col class="title">
              <h5>Dados de endereço</h5>
            </b-col>
            <div class="w-100"></div>
            <b-col>
              <StandardInput
                type="text"
                name="cep"
                label="CEP"
                :value="`${addressCep}`"
                disabled
              ></StandardInput>
            </b-col>
            <b-col>
              <StandardInput
                type="text"
                name="city"
                label="Cidade"
                :value="`${addressCity}`"
                disabled
              ></StandardInput>
            </b-col>
            <b-col>
              <StandardInput
                type="text"
                name="neighborhood"
                label="Bairro"
                :value="`${addressNeighborhood}`"
                disabled
              ></StandardInput>
            </b-col>
            <b-col>
              <StandardInput
                type="text"
                name="address"
                label="Rua"
                :value="`${addressStreet}`"
                disabled
              ></StandardInput>
            </b-col>
            <div class="w-100"></div>
            <b-col md="2">
              <StandardInput
                type="text"
                name="number"
                label="Número"
                :value="`${addressNumber}`"
                disabled
              ></StandardInput>
            </b-col>
            <b-col md="2">
              <StandardInput
                type="text"
                name="complement"
                label="Complemento"
                :value="`${addressComplement}`"
                disabled
              ></StandardInput>
            </b-col>
          </b-row>

          <b-row v-else>
            <b-col>
              <StandardInput
                type="text"
                name="name"
                label="Nome"
                v-model="selected.user.name"
              ></StandardInput>
            </b-col>
            <b-col>
              <StandardInput
                type="email"
                name="email"
                label="E-mail"
                v-model="selected.user.email"
              ></StandardInput>
            </b-col>
            <b-col v-if="selected.user.Phones[0]">
              <StandardInput
                type="text"
                name="phone"
                label="Telefone"
                v-mask="['(##) #####-####']"
                v-model="selected.user.Phones[0].number"
              ></StandardInput>
            </b-col>
            <b-col>
              <StandardInput
                v-model="selected.user.birth_date"
                type="text"
                name="birth_date"
                label="Data de nascimento"
                placeholder
              ></StandardInput>
            </b-col>
            <div class="w-100"></div>
            <b-col class="title">
              <h5>Documentos</h5>
            </b-col>
            <div class="w-100"></div>
            <b-col md="3">
              <StandardInput
                type="text"
                name="cpf"
                label="CPF"
                v-mask="['###.###.###-##']"
                v-model="selected.user.cpf"
                disabled
              ></StandardInput>
            </b-col>
            <b-col md="3">
              <StandardInput
                type="text"
                name="rg"
                label="RG"
                v-model="selected.user.rg"
                disabled
              ></StandardInput>
            </b-col>
            <div class="w-100"></div>
            <b-col v-if="selected.user.Address" class="title">
              <h5>Dados de endereço</h5>
            </b-col>
            <div class="w-100"></div>
            <b-col v-if="selected.user.Address">
              <StandardInput
                type="text"
                name="cep"
                label="CEP"
                v-mask="['#####-###']"
                v-model="selected.user.Address.cep"
              ></StandardInput>
            </b-col>
            <b-col v-if="selected.user.Address">
              <StandardInput
                type="text"
                name="city"
                label="Cidade"
                v-model="selected.user.Address.city"
              ></StandardInput>
            </b-col>
            <b-col v-if="selected.user.Address">
              <StandardInput
                type="text"
                name="neighborhood"
                label="Bairro"
                v-model="selected.user.Address.neighborhood"
              ></StandardInput>
            </b-col>
            <b-col v-if="selected.user.Address">
              <StandardInput
                type="text"
                name="address"
                label="Rua"
                v-model="selected.user.Address.street"
              ></StandardInput>
            </b-col>
            <div class="w-100"></div>
            <b-col md="2" v-if="selected.user.Address">
              <StandardInput
                type="text"
                name="number"
                label="Número"
                v-model="selected.user.Address.number"
              ></StandardInput>
            </b-col>
            <b-col md="2" v-if="selected.user.Address">
              <StandardInput
                type="text"
                name="complement"
                label="Complemento"
                v-model="selected.user.Address.complement"
              ></StandardInput>
            </b-col>
          </b-row>
          <b-row class="row-buttons">
            <b-col>
              <b-button
                @click="update"
                class="btn large outline"
                v-if="!canEdit"
                :disabled="!isValid('User')"
                >Atualizar dados</b-button
              >
            </b-col>
            <b-col></b-col>
            <b-col class="text-right">
              <b-button class="btn large outline" v-b-modal.edit-photo-1
                >Editar foto de perfil</b-button
              >
            </b-col>
            <b-col class="text-right">
              <b-button class="btn large blue" v-b-modal.edit-password
                >Alterar senha</b-button
              >
            </b-col>
          </b-row>

          <UserEditPassword />

          <EditPhotoProfile :callback="selectPhoto" :photo="photo" />
        </b-tab>

        <b-tab
          v-if="
            !canEdit &&
            !this.currentUser.role.includes('coordinator') &&
            !this.currentUser.role.includes('secretary')
          "
          title="Dados dos contratantes"
        >
          <b-col v-if="enrollmentsResponsibles !== []">
            <b-col
              v-for="(enrollment, index) in enrollmentsResponsibles"
              :key="index"
            >
              <b-row>
                <h2 class="purple bold">
                  {{ enrollment.EnrollmentResponsibles[0].name }}
                </h2>
              </b-row>

              <b-row>
                <b-row>
                  <b-col>
                    <ProfilePhoto
                      :modalId="`contractor + ${index}`"
                      :photo="
                        typeof enrollment.EnrollmentResponsibles[0].photo !==
                        'string'
                          ? photoContractor[index]
                          : enrollment.EnrollmentResponsibles[0].photo
                      "
                      :callback="(photo) => setNewPhotoContractor(photo, index)"
                    />
                  </b-col>
                  <b-col>
                    <StandardInput
                      type="text"
                      name="name"
                      label="Nome"
                      v-model="enrollment.EnrollmentResponsibles[0].name"
                      disabled
                    ></StandardInput>
                    <StandardInput
                      type="email"
                      name="email"
                      label="E-mail"
                      v-model="enrollment.EnrollmentResponsibles[0].email"
                    ></StandardInput>
                    <h5>Documentos</h5>
                    <StandardInput
                      type="text"
                      name="cpf"
                      label="CPF"
                      v-mask="['###.###.###-##']"
                      v-model="enrollment.EnrollmentResponsibles[0].cpf"
                      disabled
                    ></StandardInput>
                  </b-col>
                  <b-col
                    v-if="enrollment.EnrollmentResponsibles[0].Responsible.User"
                  >
                    <StandardInput
                      type="text"
                      name="phone"
                      label="Telefone"
                      v-mask="['(##) #####-####']"
                      v-model="
                        enrollment.EnrollmentResponsibles[0].Responsible.User
                          .Phones[0].number
                      "
                    ></StandardInput>

                    <StandardInput
                      v-model="enrollment.EnrollmentResponsibles[0].birth_date"
                      type="text"
                      name="birth_date"
                      label="Data de nascimento"
                      v-mask="'##/##/####'"
                      placeholder
                    />
                    <h5>&nbsp;</h5>
                    <StandardInput
                      type="text"
                      name="rg"
                      label="RG"
                      v-model="enrollment.EnrollmentResponsibles[0].rg"
                      disabled
                    ></StandardInput>
                  </b-col>
                  <div class="w-100"></div>
                  <b-col class="title"> </b-col>
                  <div class="w-100"></div>
                  <b-col md="3"> </b-col>
                  <div class="w-100"></div>
                  <b-col
                    v-if="
                      enrollment.EnrollmentResponsibles[0].Responsible.User
                        .Address
                    "
                    class="title"
                  >
                    <h5>Dados de endereço</h5>
                  </b-col>
                  <div class="w-100"></div>
                  <b-col
                    v-if="
                      enrollment.EnrollmentResponsibles[0].Responsible.User
                        .Address
                    "
                  >
                    <StandardInput
                      type="text"
                      name="cep"
                      label="CEP"
                      v-mask="['#####-###']"
                      v-model="
                        enrollment.EnrollmentResponsibles[0].Responsible.User
                          .Address.cep
                      "
                    ></StandardInput>
                  </b-col>
                  <b-col
                    v-if="
                      enrollment.EnrollmentResponsibles[0].Responsible.User
                        .Address
                    "
                  >
                    <StandardInput
                      type="text"
                      name="city"
                      label="Cidade"
                      v-model="
                        enrollment.EnrollmentResponsibles[0].Responsible.User
                          .Address.city
                      "
                    ></StandardInput>
                  </b-col>
                  <b-col
                    v-if="
                      enrollment.EnrollmentResponsibles[0].Responsible.User
                        .Address
                    "
                  >
                    <StandardInput
                      type="text"
                      name="neighborhood"
                      label="Bairro"
                      v-model="
                        enrollment.EnrollmentResponsibles[0].Responsible.User
                          .Address.neighborhood
                      "
                    ></StandardInput>
                  </b-col>
                  <b-col
                    v-if="
                      enrollment.EnrollmentResponsibles[0].Responsible.User
                        .Address
                    "
                  >
                    <StandardInput
                      type="text"
                      name="address"
                      label="Rua"
                      v-model="
                        enrollment.EnrollmentResponsibles[0].Responsible.User
                          .Address.street
                      "
                    ></StandardInput>
                  </b-col>
                  <div class="w-100"></div>
                  <b-col
                    md="2"
                    v-if="
                      enrollment.EnrollmentResponsibles[0].Responsible.User
                        .Address
                    "
                  >
                    <StandardInput
                      type="text"
                      name="number"
                      label="Número"
                      v-model="
                        enrollment.EnrollmentResponsibles[0].Responsible.User
                          .Address.number
                      "
                    ></StandardInput>
                  </b-col>
                  <b-col
                    md="2"
                    v-if="
                      enrollment.EnrollmentResponsibles[0].Responsible.User
                        .Address
                    "
                  >
                    <StandardInput
                      type="text"
                      name="complement"
                      label="Complemento"
                      v-model="
                        enrollment.EnrollmentResponsibles[0].Responsible.User
                          .Address.complement
                      "
                    ></StandardInput>
                  </b-col>
                </b-row>
              </b-row>
              <b-col class="divider" style="padding-top: 40px"></b-col>
            </b-col>

            <b-row class="row-buttons">
              <b-col>
                <b-button
                  @click="updateContractors"
                  class="btn large outline"
                  v-if="!canEdit"
                  :disabled="!isValid('Contractor')"
                  >Atualizar dados</b-button
                >
              </b-col>
            </b-row>
          </b-col>
        </b-tab>

        <b-tab
          v-if="
            !canEdit &&
            !this.currentUser.role.includes('coordinator') &&
            !this.currentUser.role.includes('secretary')
          "
          title="Dados dos alunos"
        >
          <b-col v-if="enrollments !== []">
            <b-col v-for="(enrollment, index) in enrollments" :key="index">
              <b-row>
                <h2 class="purple bold">
                  {{ enrollment.EnrollmentStudent.name }}
                </h2>
              </b-row>
              <b-row>
                <p class="purple bold">
                  Referente à matrícula {{ enrollment.id }}
                </p>
              </b-row>
              <b-row>
                <b-row>
                  <b-col>
                    <ProfilePhoto
                      :modalId="`student + ${index}`"
                      :photo="
                        typeof enrollment.EnrollmentStudent.photo !== 'string'
                          ? photoStudent[index]
                          : enrollment.EnrollmentStudent.photo
                      "
                      :callback="(photo) => setNewPhotoStudent(photo, index)"
                    />
                  </b-col>
                  <b-col>
                    <StandardInput
                      type="text"
                      name="name"
                      label="Nome"
                      v-model="enrollment.EnrollmentStudent.name"
                      disabled
                    ></StandardInput>

                    <StandardInput
                      type="email"
                      name="email"
                      label="E-mail"
                      v-model="enrollment.EnrollmentStudent.email"
                    ></StandardInput>
                    <h5>Documentos</h5>

                    <StandardInput
                      type="text"
                      name="cpf"
                      label="CPF"
                      v-mask="['###.###.###-##']"
                      v-model="enrollment.EnrollmentStudent.cpf"
                      disabled
                    ></StandardInput>
                  </b-col>
                  <b-col>
                    <StandardInput
                      v-if="enrollment.EnrollmentStudent.Student.User.Phones"
                      type="text"
                      name="phone"
                      label="Telefone"
                      v-mask="['(##) #####-####']"
                      v-model="
                        enrollment.EnrollmentStudent.Student.User.Phones[0]
                          .number
                      "
                    ></StandardInput>

                    <StandardInput
                      v-model="enrollment.EnrollmentStudent.birth_date"
                      type="text"
                      name="birth_date"
                      label="Data de nascimento"
                      v-mask="'##/##/####'"
                      placeholder
                    />
                    <h5>&nbsp;</h5>
                    <StandardInput
                      type="text"
                      name="rg"
                      label="RG"
                      v-model="enrollment.EnrollmentStudent.rg"
                      disabled
                    ></StandardInput>
                  </b-col>
                  <div class="w-100"></div>
                  <b-col class="title"> </b-col>
                  <div class="w-100"></div>
                  <b-col md="3"> </b-col>

                  <div class="w-100"></div>
                  <b-col
                    v-if="enrollment.EnrollmentStudent.Address"
                    class="title"
                  >
                    <h5>Dados de endereço</h5>
                  </b-col>
                  <div class="w-100"></div>
                  <b-col v-if="enrollment.EnrollmentStudent.Address">
                    <StandardInput
                      type="text"
                      name="cep"
                      label="CEP"
                      v-mask="['#####-###']"
                      v-model="enrollment.EnrollmentStudent.Address.cep"
                    ></StandardInput>
                  </b-col>
                  <b-col v-if="enrollment.EnrollmentStudent.Address">
                    <StandardInput
                      type="text"
                      name="city"
                      label="Cidade"
                      v-model="enrollment.EnrollmentStudent.Address.city"
                    ></StandardInput>
                  </b-col>
                  <b-col v-if="enrollment.EnrollmentStudent.Address">
                    <StandardInput
                      type="text"
                      name="neighborhood"
                      label="Bairro"
                      v-model="
                        enrollment.EnrollmentStudent.Address.neighborhood
                      "
                    ></StandardInput>
                  </b-col>
                  <b-col v-if="enrollment.EnrollmentStudent.Address">
                    <StandardInput
                      type="text"
                      name="address"
                      label="Rua"
                      v-model="enrollment.EnrollmentStudent.Address.street"
                    ></StandardInput>
                  </b-col>
                  <div class="w-100"></div>
                  <b-col md="2" v-if="enrollment.EnrollmentStudent.Address">
                    <StandardInput
                      type="text"
                      name="number"
                      label="Número"
                      v-model="enrollment.EnrollmentStudent.Address.number"
                    ></StandardInput>
                  </b-col>
                  <b-col md="2" v-if="enrollment.EnrollmentStudent.Address">
                    <StandardInput
                      type="text"
                      name="complement"
                      label="Complemento"
                      v-model="enrollment.EnrollmentStudent.Address.complement"
                    ></StandardInput>
                  </b-col>
                </b-row>
              </b-row>
              <b-col class="divider" style="padding-top: 40px"></b-col>
            </b-col>

            <b-row class="row-buttons">
              <b-col>
                <b-button
                  @click="updateStudents"
                  class="btn large outline"
                  v-if="!canEdit"
                  :disabled="!isValid('Student')"
                  >Atualizar dados</b-button
                >
              </b-col>
            </b-row>
          </b-col>
        </b-tab>

        <b-tab
          v-if="
            !canEdit &&
            !this.currentUser.role.includes('coordinator') &&
            !this.currentUser.role.includes('secretary')
          "
          title="Autorizações da matrícula"
        >
          <div
            v-for="(authorization, index) in authorizations"
            :key="index"
            style="padding-bottom: 20px"
          >
            <h3 class="purple bold">
              Referente à matrícula {{ authorization.enrollment_id }} do(a)
              aluno(a)
              {{ studentNameByEnrollmentId(authorization.enrollment_id) }}
            </h3>

            <Authorizations v-model="authorizations[index]" />
            <b-col class="divider" style="padding-top: 40px"></b-col>
          </div>
          <b-row class="row-buttons">
            <b-col>
              <b-button
                @click="createUpdateAuthorizations('update')"
                class="btn large outline"
                v-if="!canEdit"
                >Atualizar dados</b-button
              >
            </b-col>
          </b-row>
        </b-tab>

        <b-tab
          v-if="
            !canEdit &&
            !this.currentUser.role.includes('coordinator') &&
            !this.currentUser.role.includes('secretary')
          "
          title="Complementos da matrícula"
        >
          <div
            v-for="(complement, index) in complements"
            :key="index"
            style="padding-bottom: 20px"
          >
            <p class="purple bold">
              Referente à matrícula {{ complement.enrollment_id }} do(a)
              aluno(a)
              {{ studentNameByEnrollmentId(complement.enrollment_id) }}
            </p>
            <div class="form-content">
              <Complementary v-model="complements[index]" />
            </div>
            <b-col class="divider" style="padding-top: 40px"></b-col>
          </div>
          <b-row class="row-buttons">
            <b-col>
              <b-button
                @click="createUpdateComplement('update')"
                class="btn large outline"
                v-if="!canEdit"
                :disabled="!isValid('Complementary')"
                >Atualizar dados</b-button
              >
            </b-col>
          </b-row>
        </b-tab>

        <b-tab
          v-if="
            !canEdit &&
            !this.currentUser.role.includes('coordinator') &&
            !this.currentUser.role.includes('secretary')
          "
          title="Anexos da matrícula"
        >
          <div
            v-for="(submit, index) in submits"
            :key="index"
            style="padding-bottom: 20px"
          >
            <p class="purple bold">
              Referente à matrícula {{ submit.enrollmentId }} do(a) aluno(a)
              {{ studentNameByEnrollmentId(submit.enrollmentId) }}
            </p>
            <div class="form-content">
              <Submit
                v-model="submits[index].structure"
                :enrollmentId="submits[index].enrollmentId"
                :editedUserId="submits[index].editedUserId"
                :id="submit.enrollmentId"
              />
            </div>
            <b-col class="divider" style="padding-top: 40px"></b-col>
          </div>
        </b-tab>
      </b-tabs>
    </b-col>
  </b-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import DataService from "@/services/user.service";
import * as lang from "vuejs-datepicker/src/locale";
import store from "../../store";
import StandardInput from "@/components/inputs/StandardInput.vue";
import UserEditPassword from "@/components/modals/UserEditPassword";
import EditPhotoProfile from "../../components/modals/EditPhotoProfile.vue";
import httpRda from "../../http-rda";
import Complementary from "../enroll/form/Complementary.vue";
import Authorizations from "../enroll/form/Authorizations.vue";
import Submit from "../enroll/form/Submit.vue";
import ProfilePhoto from "@/components/ProfilePhoto.vue";

import {
  FromBRDateToIsoString,
  FromIsoStringToBRDate,
} from "../../utils/fomatDate";

export default {
  name: "Perfil",
  components: {
    StandardInput,
    UserEditPassword,
    EditPhotoProfile,
    Complementary,
    Authorizations,
    Submit,
    ProfilePhoto,
  },

  data() {
    return {
      selected: {
        user: {
          name: "",
          Phones: {},
        },
      },

      updateUserPhotoForm: null,

      photoContractor: [],

      photoStudent: [],

      enrollments: [],

      enrollmentsResponsibles: [],

      enrollmentsIds: [],

      newPhoto: null,
      blob: null,

      language: "ptBR",
      languages: lang,

      authorizations: [],
      complements: [],
      SubmitStructure: [
        {
          title: "Carteira de identidade do aluno (Frente e Verso)",
          isRequired: true,

          files: [
            {
              file: null,
              progress: null,
              status: false,
              fileName: "identity",
              url: "",
            },
            {
              file: null,
              progress: null,
              status: false,
              fileName: "identity_back",
              url: "",
            },
          ],
        },
        {
          title: "CPF do aluno",
          isRequired: true,

          files: [
            {
              file: null,
              progress: null,
              status: false,
              fileName: "cpf",
              url: "",
            },
          ],
        },
        {
          title: "Cartão de vacinas do aluno",
          isRequired: true,

          files: [
            {
              file: null,
              progress: null,
              status: false,
              fileName: "vaccination_record",
              url: "",
            },
          ],
        },
        {
          title: "Declaração de quitação emitida pela escola anterior",
          isRequired: true,

          files: [
            {
              file: null,
              progress: null,
              status: false,
              fileName: "discharge",
              url: "",
            },
          ],
        },
        {
          title: "Histórico escolar",
          isRequired: false,

          files: [
            {
              file: null,
              progress: null,
              status: false,
              fileName: "school_record",
              url: "",
            },
          ],
        },
        {
          title: "Carteira de identidade do contratante A (Frente e Verso)",
          isRequired: true,

          files: [
            {
              file: null,
              progress: null,
              status: false,
              fileName: "identity_contractor_a",
              url: "",
            },
            {
              file: null,
              progress: null,
              status: false,
              fileName: "identity_contractor_a_back",
              url: "",
            },
          ],
        },
        {
          title: "Carteira de identidade do contratante B (Frente e Verso)",
          isRequired: true,

          files: [
            {
              file: null,
              progress: null,
              status: false,
              fileName: "identity_contractor_b",
              url: "",
            },
            {
              file: null,
              progress: null,
              status: false,
              fileName: "identity_contractor_b_back",
              url: "",
            },
          ],
        },
        {
          title: "CPF dos contratantes",
          isRequired: true,

          files: [
            {
              file: null,
              progress: null,
              status: false,
              fileName: "cpf_contractor_a",
              url: "",
            },
            {
              file: null,
              progress: null,
              status: false,
              fileName: "cpf_contractor_b",
              url: "",
            },
          ],
        },
      ],

      submits: [],
    };
  },

  filters: {
    date(date) {
      try {
        var d = new Date(date.split());
        var year = d.getFullYear();
        var month = (d.getMonth() + 1).toString().padStart(2, "0");
        var day = d.getUTCDate().toString().padStart(2, "0");

        return day + "/" + month + "/" + year;
      } catch (error) {
        return date;
      }
    },
  },

  computed: {
    ...mapGetters({
      currentUser: "auth/getAuthData",
      users: "shared/users",
      students: "shared/studentsCycle",
    }),

    canEdit() {
      if (!this.currentUser.role) return false;
      return !["coordinator", "teacher", "parent", "secretary"].includes(
        this.currentUser.role[0]
      );
    },

    photo() {
      if (this.newPhoto) return this.newPhoto;
      else if (this.users.photo) return this.users.photo;
      else return null;
    },

    phone() {
      if (this.users.Phones[0]) return this.users.Phones[0].number;
      else return "Sem registro";
    },

    birthday() {
      if (this.users.birth_date) return this.users.birth_date;
      else return "Sem registro";
    },

    addressCep() {
      if (this.users.Address) return this.users.Address.cep;
      else return "Sem registro";
    },

    addressCity() {
      if (this.users.Address) return this.users.Address.city;
      else return "Sem registro";
    },

    addressNeighborhood() {
      if (this.users.Address) return this.users.Address.neighborhood;
      else return "Sem registro";
    },

    addressStreet() {
      if (this.users.Address) return this.users.Address.street;
      else return "Sem registro";
    },

    addressNumber() {
      if (this.users.Address) return this.users.Address.number;
      else return "Sem registro";
    },

    addressComplement() {
      if (this.users.Address) return this.users.Address.complement;
      else return "Sem registro";
    },
  },

  methods: {
    ...mapActions({
      getUser: "shared/getUser",
      getStudentCycle: "shared/getStudentCycle",
      updatePhoto: "shared/sabeStudentPhoto",
    }),

    isCompoundName(name) {
      name = name.trim();
      const nameArr = name.split(" ");

      if (nameArr.length >= 2) {
        return true;
      } else {
        return false;
      }
    },

    isValid(type) {
      function checkAddressFormFields(item) {
        return (item.Address.cep &&
          item.Address.cep !== null &&
          item.Address.number &&
          item.Address.number !== null &&
          item.Address.street &&
          item.Address.street !== null &&
          item.Address.neighborhood &&
          item.Address.neighborhood !== null &&
          item.Address.city &&
          item.Address.city !== null) === true
          ? true
          : false;
      }

      const formUser =
        !this.selected.user.name ||
        !this.selected.user.email ||
        !this.selected.user.birth_date ||
        !(
          this.selected.user.Phones &&
          this.selected.user.Phones.length > 0 &&
          this.selected.user.Phones[0].number
        ) ||
        !this.selected.user.Address.cep ||
        !this.selected.user.Address.city ||
        !this.selected.user.Address.neighborhood ||
        !this.selected.user.Address.street ||
        !this.selected.user.Address.number;

      const formStudentData = this.enrollments.map(
        (enrollment) => enrollment.EnrollmentStudent
      );

      const formContractor = this.enrollmentsResponsibles.map(
        (enrollment) => enrollment.EnrollmentResponsibles[0]
      );

      const dataValidateContractor = formContractor.map((contractor) => {
        const allData =
          !contractor.photo ||
          !(contractor.name && this.isCompoundName(contractor.name)) ||
          !contractor.birth_date ||
          !(
            contractor.Responsible.User.Phones &&
            contractor.Responsible.User.Phones.length > 0 &&
            contractor.Responsible.User.Phones[0].number
          ) ||
          !contractor.email ||
          !contractor.cpf ||
          !contractor.rg ||
          !checkAddressFormFields(contractor.Responsible.User);

        return allData;
      });

      let dataValidateStudentData = [];
      dataValidateStudentData = formStudentData.map((student) => {
        const allData =
          !student.photo ||
          !(student.name && this.isCompoundName(student.name)) ||
          !student.birth_date ||
          !student.Address.cep ||
          !student.Address.city ||
          !student.Address.neighborhood ||
          !student.Address.street ||
          !student.Address.number ||
          !(
            student.Student.User.Phones &&
            student.Student.User.Phones.length > 0 &&
            student.Student.User.Phones[0].number
          ) ||
          !student.email ||
          !student.cpf;

        return allData;
      });

      const complementsResult = [];
      this.complements.map((complement) => {
        const formComplementary = complement;

        let validateContacts =
          formComplementary.return_to_home === "Acompanhado por"
            ? formComplementary.EnrollmentComplementContacts?.map((item) => {
                const validate =
                  !item.name ||
                  !item.number1 ||
                  !item.relationship ||
                  !item.photo;
                return validate ? validate : null;
              })
            : [null];
        validateContacts = validateContacts?.every((e) => e === null);

        const dataValidateComplementaryAux =
          !formComplementary.contact1 ||
          !formComplementary.contact1_phone ||
          !formComplementary.contact2 ||
          !formComplementary.contact2_phone ||
          formComplementary.food_restriction
            ? !formComplementary.food_restriction_description
            : null || formComplementary.health_problem
            ? !formComplementary.health_problem_description
            : null || formComplementary.healthcare
            ? !formComplementary.healthcare_description
            : null || formComplementary.individual_accompaniment
            ? !formComplementary.individual_accompaniment_description
            : null || formComplementary.limitation
            ? !formComplementary.limitation_description
            : null || formComplementary.medication
            ? !formComplementary.medication_description
            : null || formComplementary.special_accompaniment
            ? !formComplementary.special_accompaniment_description
            : null || formComplementary.special_care
            ? !formComplementary.special_care_description
            : null;

        let dataValidateComplementary = null;

        if (validateContacts) {
          dataValidateComplementary = !dataValidateComplementaryAux;
        } else {
          dataValidateComplementary = false;
        }

        complementsResult.push(dataValidateComplementary);
      });

      switch (type) {
        case "User":
          return formUser ? false : true;
        case "Student":
          return dataValidateStudentData.every((cur) => cur !== true);
        case "Contractor":
          return dataValidateContractor.every((cur) => cur !== true);
        case "Complementary":
          return complementsResult.every((cur) => cur === true);
        default:
          return null;
      }
    },

    setNewPhotoContractor(photo, index) {
      const bodyFormData = new FormData();
      bodyFormData.append("file", photo);
      bodyFormData.append("notification", true);
      this.enrollmentsResponsibles[index].EnrollmentResponsibles[0].photo =
        bodyFormData;

      this.photoContractor[index] = photo;
    },

    setNewPhotoStudent(photo, index) {
      const bodyFormData = new FormData();
      bodyFormData.append("file", photo);
      bodyFormData.append("notification", true);
      this.enrollments[index].EnrollmentStudent.photo = bodyFormData;

      this.photoStudent[index] = photo;
    },

    async getAllUserEnrollments() {
      this.enrollments = await httpRda
        .get(`/users/${this.user_id}/enrollments`)
        .then((response) => response.data.enrollments);

      this.enrollments.map((enrollment) => {
        enrollment.EnrollmentResponsibles[0].birth_date = FromIsoStringToBRDate(
          enrollment.EnrollmentResponsibles[0].birth_date
        );

        enrollment.EnrollmentStudent.birth_date = FromIsoStringToBRDate(
          enrollment.EnrollmentStudent.birth_date
        );

        this.submits.push({
          structure: JSON.parse(JSON.stringify(this.SubmitStructure)),
          enrollmentId: enrollment.id,
          editedUserId: enrollment.EnrollmentStudent.Student.User.id,
        });
      });

      const responsiblesId = [];

      this.enrollmentsResponsibles = this.enrollments
        .map((e) => {
          if (
            !responsiblesId.includes(
              e.EnrollmentResponsibles[0].Responsible.User.id
            )
          ) {
            responsiblesId.push(
              e.EnrollmentResponsibles[0].Responsible.User.id
            );
            return e;
          }
        })
        .filter((e) => e !== undefined);

      for (const enrollment of this.enrollments) {
        this.enrollmentsIds.push(enrollment.id);
      }
      await this.createUpdateAuthorizations("get");
      await this.createUpdateComplement("get");
    },

    studentNameByEnrollmentId(enrollment_id) {
      const enrollment = this.enrollments.filter(
        (enrollment) => enrollment.id === enrollment_id
      );
      return enrollment[0].EnrollmentStudent.name;
    },

    setUser() {
      const user = store.getters["auth/getAuthData"];
      this.user_id = user.id;
      this.getUser(user.id);
    },

    async selectPhoto(photo) {
      const urlCreator = window.URL || window.webkitURL;
      const imageUrl = urlCreator.createObjectURL(photo);
      const bodyFormData = new FormData();

      bodyFormData.append("file", photo);
      bodyFormData.append("notification", true);

      if (this.currentUser.student_id) {
        this.updatePhoto({
          roles: "students",
          id: this.currentUser.student_id,
          file: bodyFormData,
        });
      } else if (this.currentUser.teacher_id) {
        this.updatePhoto({
          roles: "teachers",
          id: this.currentUser.teacher_id,
          file: bodyFormData,
        });
      } else if (this.currentUser.responsible_id) {
        this.updateUserPhotoForm = bodyFormData;

        this.updatePhoto({
          roles: "responsibles",
          id: this.currentUser.responsible_id,
          file: bodyFormData,
        }).then(async () => {
          await httpRda.patch(
            `/users/${this.selected.user.id}`,
            this.updateUserPhotoForm,
            {
              headers: { "Content-Type": "multipart/form-data" },
            }
          );
        });
      } else {
        this.updatePhoto({
          roles: "users",
          id: this.currentUser.id,
          file: bodyFormData,
        });
      }

      this.newPhoto = imageUrl;
      this.blob = photo;
    },

    async createNotification(userEditorId, userEditedId, details) {
      await httpRda.post("/notifications", {
        user_editor_id: userEditorId,
        user_edited_id: userEditedId,
        details,
      });
    },

    update() {
      try {
        var address = {
          cep: this.selected.user.Address
            ? this.selected.user.Address.cep.replace("-", "")
            : "",
          number: this.selected.user.Address
            ? this.selected.user.Address.number
            : "",
          complement: this.selected.user.Address
            ? this.selected.user.Address.complement
            : "",
          street: this.selected.user.Address
            ? this.selected.user.Address.street
            : "",
          neighborhood: this.selected.user.Address
            ? this.selected.user.Address.neighborhood
            : "",
          city: this.selected.user.Address
            ? this.selected.user.Address.city
            : "",
          state: this.selected.user.Address
            ? this.selected.user.Address.state
            : "",
          country: this.selected.user.Address
            ? this.selected.user.Address.country
            : "",
        };

        DataService.updateAddress(this.selected.user.id, address);

        DataService.update(this.selected.user.id, {
          ...this.selected.user,
          birth_date: FromBRDateToIsoString(this.selected.user.birth_date),
          notification: true,
          name: this.selected.user.name,
          email: this.selected.user.email,
        })
          .then((response) => {
            this.setUser();
            const Toast = this.$swal.mixin({
              toast: true,
              position: "bottom-end",
              showConfirmButton: false,
              timer: 5000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", this.$swal.stopTimer);
                toast.addEventListener("mouseleave", this.$swal.resumeTimer);
              },
            });
            Toast.fire({
              icon: "success",
              title: "Perfil atualizado com sucesso!",
            });
            this.setUser();

            return response.data;
          })
          .catch((error) => {
            this.$swal.fire({
              icon: "error",
              title: "Oops...",
              text: error.message,
              footer: "Por favor, tente novamente mais tarde.",
            });

            return error.response;
          });
      } catch (error) {
        this.$swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Não foi possivel atualizar!",
          footer: "Por favor, tente novamente mais tarde.",
        });

        return error.response;
      }
    },

    async updateContractors() {
      this.enrollmentsResponsibles.map((enrollment) => {
        try {
          var address = {
            cep: enrollment.EnrollmentResponsibles[0].Responsible.User.Address
              ? enrollment.EnrollmentResponsibles[0].Responsible.User.Address.cep.replace(
                  "-",
                  ""
                )
              : "",
            number: enrollment.EnrollmentResponsibles[0].Responsible.User
              .Address
              ? enrollment.EnrollmentResponsibles[0].Responsible.User.Address
                  .number
              : "",
            complement: enrollment.EnrollmentResponsibles[0].Responsible.User
              .Address
              ? enrollment.EnrollmentResponsibles[0].Responsible.User.Address
                  .complement
              : "",
            street: enrollment.EnrollmentResponsibles[0].Responsible.User
              .Address
              ? enrollment.EnrollmentResponsibles[0].Responsible.User.Address
                  .street
              : "",
            neighborhood: enrollment.EnrollmentResponsibles[0].Responsible.User
              .Address
              ? enrollment.EnrollmentResponsibles[0].Responsible.User.Address
                  .neighborhood
              : "",
            city: enrollment.EnrollmentResponsibles[0].Responsible.User.Address
              ? enrollment.EnrollmentResponsibles[0].Responsible.User.Address
                  .city
              : "",
            state: enrollment.EnrollmentResponsibles[0].Responsible.User.Address
              ? enrollment.EnrollmentResponsibles[0].Responsible.User.Address
                  .state
              : "",
            country: enrollment.EnrollmentResponsibles[0].Responsible.User
              .Address
              ? enrollment.EnrollmentResponsibles[0].Responsible.User.Address
                  .country
              : "",
          };

          DataService.updateAddress(
            enrollment.EnrollmentResponsibles[0].Responsible.User.id,
            address
          );

          const payload = {
            birth_date: FromBRDateToIsoString(
              enrollment.EnrollmentResponsibles[0].birth_date
            ),
            Phones:
              enrollment.EnrollmentResponsibles[0].Responsible.User.Phones,
            email: enrollment.EnrollmentResponsibles[0].email,
            Address:
              enrollment.EnrollmentResponsibles[0].Responsible.User.Address,
          };

          DataService.update(
            enrollment.EnrollmentResponsibles[0].Responsible.User.id,
            {
              ...payload,
              notification: true,
            }
          )
            .then(async () => {
              if (
                typeof enrollment.EnrollmentResponsibles[0].photo === "object"
              ) {
                httpRda.patch(
                  `/users/${enrollment.EnrollmentResponsibles[0].Responsible.User.id}`,
                  enrollment.EnrollmentResponsibles[0].photo,
                  {
                    headers: { "Content-Type": "multipart/form-data" },
                  }
                );
              }
            })
            .catch((error) => {
              this.$swal.fire({
                icon: "error",
                title: "Oops...",
                text: error.message,
                footer: "Por favor, tente novamente mais tarde.",
              });

              return error.response;
            });
        } catch (error) {
          this.$swal.fire({
            icon: "error",
            title: "Oops...",
            text: error.message,
            footer: "Por favor, tente novamente mais tarde.",
          });

          return error.response;
        }

        const Toast = this.$swal.mixin({
          toast: true,
          position: "bottom-end",
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", this.$swal.stopTimer);
            toast.addEventListener("mouseleave", this.$swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: "success",
          title: "Perfil atualizado com sucesso!",
        });
      });
    },

    async updateStudents() {
      for (const enrollment of this.enrollments) {
        try {
          var address = {
            cep: enrollment.EnrollmentStudent.Address
              ? enrollment.EnrollmentStudent.Address.cep.replace("-", "")
              : "",
            number: enrollment.EnrollmentStudent.Address
              ? enrollment.EnrollmentStudent.Address.number
              : "",
            complement: enrollment.EnrollmentStudent.Address
              ? enrollment.EnrollmentStudent.Address.complement
              : "",
            street: enrollment.EnrollmentStudent.Address
              ? enrollment.EnrollmentStudent.Address.street
              : "",
            neighborhood: enrollment.EnrollmentStudent.Address
              ? enrollment.EnrollmentStudent.Address.neighborhood
              : "",
            city: enrollment.EnrollmentStudent.Address
              ? enrollment.EnrollmentStudent.Address.city
              : "",
            state: enrollment.EnrollmentStudent.Address
              ? enrollment.EnrollmentStudent.Address.state
              : "",
            country: enrollment.EnrollmentStudent.Address
              ? enrollment.EnrollmentStudent.Address.country
              : "",
          };

          DataService.updateAddress(
            enrollment.EnrollmentStudent.Student.User.id,
            address
          );

          const payload = {
            birth_date: FromBRDateToIsoString(
              enrollment.EnrollmentStudent.birth_date
            ),
            Phones: enrollment.EnrollmentStudent.Student.User.Phones,
            email: enrollment.EnrollmentStudent.email,
            Address: enrollment.EnrollmentStudent.Address,
          };

          await httpRda
            .patch(
              `/users/${enrollment.EnrollmentStudent.Student.User.id}/student`,
              { ...payload, notification: true }
            )
            .then(async () => {
              if (typeof enrollment.EnrollmentStudent.photo === "object") {
                await httpRda.patch(
                  `/users/${enrollment.EnrollmentStudent.Student.User.id}/student`,
                  enrollment.EnrollmentStudent.photo,
                  {
                    headers: { "Content-Type": "multipart/form-data" },
                  }
                );
              }
            })
            .catch((error) => {
              this.$swal.fire({
                icon: "error",
                title: "Oops...",
                text: error.message,
                footer: "Por favor, tente novamente mais tarde.",
              });

              return error.response;
            });
        } catch (error) {
          this.$swal.fire({
            icon: "error",
            title: "Oops...",
            text: error.message,
            footer: "Por favor, tente novamente mais tarde.",
          });

          return error.response;
        }

        const Toast = this.$swal.mixin({
          toast: true,
          position: "bottom-end",
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", this.$swal.stopTimer);
            toast.addEventListener("mouseleave", this.$swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: "success",
          title: "Perfil atualizado com sucesso!",
        });
      }
    },

    getSelected(id) {
      DataService.get(id)
        .then((response) => {
          this.selected = response.data;
          this.selected.user.birth_date = FromIsoStringToBRDate(
            this.selected.user.birth_date
          );
          return response.data;
        })
        .catch((error) => {
          return error.response;
        });
    },

    getCurrentEnrollmentById(enrollmentId) {
      const currentEnrollment = this.enrollments.filter(
        (enrollment) => enrollment.id === enrollmentId
      );

      return currentEnrollment[0];
    },

    async createUpdateAuthorizations(type) {
      switch (type) {
        case "update":
        case "post":
          for (const authorization of this.authorizations) {
            const currentEnrollment = this.getCurrentEnrollmentById(
              authorization.enrollment_id
            );

            await httpRda
              .post(
                `/enrollments/${authorization.enrollment_id}/authorization`,
                {
                  ...authorization,
                  userId: currentEnrollment.EnrollmentStudent.Student.User.id,
                  notification: true,
                }
              )
              .then(() => {
                const Toast = this.$swal.mixin({
                  toast: true,
                  position: "bottom-end",
                  showConfirmButton: false,
                  timer: 5000,
                  timerProgressBar: true,
                  didOpen: (toast) => {
                    toast.addEventListener("mouseenter", this.$swal.stopTimer);
                    toast.addEventListener(
                      "mouseleave",
                      this.$swal.resumeTimer
                    );
                  },
                });
                Toast.fire({
                  icon: "success",
                  title: "Autorizações atualizadas com sucesso!",
                });
              })
              .catch((error) => {
                this.$swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: error.message,
                  footer: "Por favor, tente novamente mais tarde.",
                });

                return error.response;
              });
          }
          break;
        default:
          for (const enrollmentId of this.enrollmentsIds) {
            await httpRda
              .get(`/enrollments/${enrollmentId}/authorization`)
              .then((response) => {
                this.authorizations.push(response.data.authorization);
              });
          }
          break;
      }
    },

    async createUpdateComplement(type) {
      switch (type) {
        case "update":
        case "post":
          for (const complement of this.complements) {
            const currentEnrollment = this.getCurrentEnrollmentById(
              complement.enrollment_id
            );

            await httpRda
              .patch(`/enrollments/${complement.enrollment_id}/complement`, {
                complement: {
                  ...complement,
                  userId: currentEnrollment.EnrollmentStudent.Student.User.id,
                  notification: true,
                },
                id: complement.enrollment_id,
              })
              .then(async () => {
                await this.createUpdateContacts(complement.enrollment_id, {
                  ...complement,
                  userId: currentEnrollment.EnrollmentStudent.Student.User.id,
                  notification: true,
                }).then(() => {
                  const Toast = this.$swal.mixin({
                    toast: true,
                    position: "bottom-end",
                    showConfirmButton: false,
                    timer: 5000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                      toast.addEventListener(
                        "mouseenter",
                        this.$swal.stopTimer
                      );
                      toast.addEventListener(
                        "mouseleave",
                        this.$swal.resumeTimer
                      );
                    },
                  });
                  Toast.fire({
                    icon: "success",
                    title: "Autorizações atualizadas com sucesso!",
                  });
                });
              })
              .catch((error) => {
                this.$swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: error.message,
                  footer: "Por favor, tente novamente mais tarde.",
                });

                return error.response;
              });
          }
          break;

        default:
          for (const enrollmentId of this.enrollmentsIds) {
            await httpRda
              .get(`/enrollments/${enrollmentId}/complement`)
              .then((response) => {
                this.complements.push(response.data.enrollment_complement);
              });
          }

          break;
      }
    },

    async createUpdateContacts(id, complementary) {
      if (complementary.return_to_home !== "Sozinho") {
        const toUpdate = complementary.EnrollmentComplementContacts.filter(
          (contact) => contact.id !== undefined
        );

        const toCreate = complementary.EnrollmentComplementContacts.filter(
          (contact) => contact.id === undefined
        );

        if (toUpdate.length) {
          toUpdate.map(async ({ photo, ...contact }) => {
            const bodyFormData = new FormData();
            bodyFormData.append("file", photo);

            await httpRda.patch(`/enrollments/${id}/contacts/${contact.id}`, {
              ...contact,
              notification: complementary.notification,
              userId: complementary.userId,
            });

            await httpRda.patch(
              `enrollments/${id}/contacts/${contact.id}`,
              bodyFormData,
              {
                headers: { "Content-Type": "multipart/form-data" },
              }
            );
          });
        }

        if (toCreate.length) {
          let newId;
          toCreate.map(async ({ photo, ...contact }) => {
            const bodyFormData = new FormData();
            bodyFormData.append("file", photo);
            await httpRda
              .post(`/enrollments/${id}/contacts`, {
                ...contact,
                notification: complementary.notification,
                userId: complementary.userId,
              })
              .then((response) => {
                newId = response.data.complement[0].id;
              });
            await httpRda.patch(
              `enrollments/${id}/contacts/${newId}`,
              bodyFormData,
              {
                headers: { "Content-Type": "multipart/form-data" },
              }
            );
          });
        }
      }
    },
  },

  created() {
    if (this.currentUser.role.includes("qrcode")) {
      this.$router.push("/qrcode");
    }
    this.setUser();
    this.getAllUserEnrollments();
  },

  mounted() {
    this.getSelected(this.user_id);
  },

  watch: {
    currentUser() {
      this.setUser();
    },

    users: {
      handler() {
        this.isValid("User");
      },
      deep: true,
    },

    enrollmentsResponsibles: {
      handler() {
        this.isValid("Contractor");
      },
      deep: true,
    },

    enrollments: {
      handler() {
        this.isValid("Student");
      },
      deep: true,
    },

    complements: {
      handler() {
        this.isValid("Complementary");
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/variables.scss";

[id*="accordion-"] {
  border-bottom: 1px solid #b3b3b3;
  padding-bottom: 30px;
}
.tab-content {
  .nav-tabs {
    border-bottom: 2px solid #e6e6e6;
    gap: 24px;

    li {
      &:first-child {
        a {
          text-align: left;
          padding: 0;
        }
      }
    }

    .nav-link {
      text-align: center;
      font-size: 18px;
      color: #1a1a1a;
      height: 52px;
      border: 0;
      padding: 0 18px;
      position: relative;

      &.active {
        font-weight: bold;
        background: transparent;

        &:after {
          width: 100%;
        }
      }

      &:after {
        background: $gradient1;
        content: "";
        width: 0;
        height: 4px;
        display: block;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 50px;
        transition: 0.2s all ease-in-out;
      }
    }
  }
}

.divider {
  p {
    min-height: 70px;
  }
  &:after {
    background-color: $grey7;
    content: "";
    display: block;
    height: 1px;
    left: 0;
    top: 50%;
    position: absolute;
    width: 100%;
  }
}

.profile-row {
  background: $gradient3;
  border-radius: 10px;
  color: $white;
  margin: 30px 0 40px;
  padding: 28px 10px;
  position: relative;
  &:after {
    content: url("~@/assets/images/watermark-profile.png");
    right: 0;
    pointer-events: none;
    position: absolute;
    top: -5px;
  }
  .col {
    display: flex;
    align-items: center;
    img {
      margin-right: 40px;
    }
    .content-profile {
      h5 {
        margin-bottom: 14px;
      }
      p {
        margin: 0;
      }
    }
  }
}

.form-group {
  margin-bottom: 40px;
}
.title {
  padding: 20px 15px 40px;
  h5 {
    margin: 0;
  }
}
.row-buttons {
  padding-top: 10px;
}

#StandardInput_birth_date {
  height: 48.67px;
}
</style>
