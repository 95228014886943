<template>
  <div class="file-icon">
    <span class="extenssion">{{ extenssion }}</span>
    <svg
      :height="height"
      :viewBox="`0 0 ${height} ${height}`"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 8C8 5.79086 9.79086 4 12 4H21.1716C21.702 4 22.2107 4.21071 22.5858 4.58579L31.4142 13.4142C31.7893 13.7893 32 14.298 32 14.8284V32C32 34.2091 30.2091 36 28 36H12C9.79086 36 8 34.2091 8 32V8Z"
        :fill="color"
      />
      <path
        d="M22.7071 6.70711C22.0771 6.07714 21 6.52331 21 7.41422L21 13C21 14.1046 21.8954 15 23 15L28.5858 15C29.4767 15 29.9229 13.9229 29.2929 13.2929L22.7071 6.70711Z"
        fill="white"
      />
    </svg>
  </div>
</template>
<script>
export default {
  name: "FileIcon",

  props: {
    height: {
      type: Number,
      default: 40,
    },
    color: {
      type: String,
      default: "#F2F2F2",
    },
    extenssion: {
      type: String,
      default: "PDF",
    },
  },
};
</script>
<style lang="scss">
.file-icon {
  position: relative;

  .extenssion {
    text-transform: uppercase;
    font-size: 8px;
    font-weight: bold;
    display: inline-block;
    position: absolute;
    bottom: 6px;
    left: 50%;
    transform: translateX(-50%);
    color: #fff;
  }
}
</style>
