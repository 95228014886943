<template>
  <b-form-group :label="this.label">
    <multiselect
      :value="computedValue"
      :options="subjects"
      :multiple="false"
      :close-on-select="true"
      :clear-on-select="false"
      :preserve-search="true"
      :custom-label="customLabel"
      open-direction="bottom"
      label="labelSelected"
      placeholder="Selecione"
      track-by="id"
      selectLabel=""
      selectedLabel=""
      deselectLabel=""
      @select="callback"
      :required="required"
    >
      <template slot="option" slot-scope="props">
        <div>
          <span>{{ props.option.label }}</span>
        </div>
      </template>
      <span slot="noResult">Nada encontrado</span>
    </multiselect>
  </b-form-group>
</template>

<script>
export default {
  props: ["value", "callback", "options", "label", "required"],
  data() {
    return {
      labelSelected: null,
      subjects: [],
    };
  },
  created() {
    this.getCycles();
  },
  methods: {
    getCycles() {
      this.subjects = this.options;
      this.labelSelected = "reste";
    },
    customLabel({ label }) {
      return `${label}`;
    },
  },
  computed: {
    computedValue() {
      return this.value;
    },
  },
};
</script>

<style lang="scss" scoped>
.form-group {
  margin-bottom: 0 !important;
}
</style>
