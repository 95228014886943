<template>
  <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.2266 9.30469L16.4844 8.52344C16.2891 8.32812 15.9766 8.32812 15.8203 8.52344L9.76562 14.5781V1.21875C9.76562 0.984375 9.53125 0.75 9.29688 0.75H8.20312C7.92969 0.75 7.73438 0.984375 7.73438 1.21875V14.5781L1.64062 8.52344C1.48438 8.32812 1.17188 8.32812 0.976562 8.52344L0.234375 9.30469C0.0390625 9.46094 0.0390625 9.77344 0.234375 9.96875L8.39844 18.1328C8.59375 18.3281 8.86719 18.3281 9.0625 18.1328L17.2266 9.96875C17.4219 9.77344 17.4219 9.46094 17.2266 9.30469Z" fill="url(#paint0_linear)"/>
    <defs>
    <linearGradient id="paint0_linear" x1="-5.69336" y1="8.88119" x2="28.9335" y2="8.88119" gradientUnits="userSpaceOnUse">
    <stop stop-color="#EF6549"/>
    <stop offset="1" stop-color="#B23066"/>
    </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  
}
</script>

<style lang="sass">

</style>