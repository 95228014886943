<template>
  <svg
    v-if="value == true"
    width="16"
    height="13"
    viewBox="0 0 16 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.7846 3.2567C15.7846 3.00558 15.6842 2.75446 15.5033 2.57366L14.1373 1.20759C13.9565 1.02679 13.7054 0.926339 13.4542 0.926339C13.2031 0.926339 12.952 1.02679 12.7712 1.20759L6.18192 7.80692L3.22879 4.84375C3.04799 4.66295 2.79688 4.5625 2.54576 4.5625C2.29464 4.5625 2.04353 4.66295 1.86272 4.84375L0.496652 6.20982C0.315848 6.39062 0.215402 6.64174 0.215402 6.89286C0.215402 7.14397 0.315848 7.39509 0.496652 7.57589L4.13281 11.2121L5.49888 12.5781C5.67969 12.7589 5.9308 12.8594 6.18192 12.8594C6.43304 12.8594 6.68415 12.7589 6.86496 12.5781L8.23103 11.2121L15.5033 3.93973C15.6842 3.75893 15.7846 3.50781 15.7846 3.2567Z"
      fill="#1A1A1A"
    />
  </svg>
  <svg
    v-else-if="value == false"
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.1786 8.54129C13.1786 11.5748 10.7277 14.0357 7.71429 14.0357C6.61942 14.0357 5.59487 13.7042 4.73103 13.1417L12.3047 5.57812C12.8571 6.43192 13.1786 7.44643 13.1786 8.54129ZM3.14397 11.5446C2.58147 10.6808 2.25 9.65625 2.25 8.54129C2.25 5.51786 4.70089 3.05692 7.71429 3.05692C8.82924 3.05692 9.86384 3.38839 10.7277 3.97098L3.14397 11.5446ZM15.4286 8.54129C15.4286 4.27232 11.9732 0.806919 7.71429 0.806919C3.45536 0.806919 0 4.27232 0 8.54129C0 12.8203 3.45536 16.2857 7.71429 16.2857C11.9732 16.2857 15.4286 12.8203 15.4286 8.54129Z"
      fill="#1A1A1A"
    />
  </svg>
</template>

<script>
export default {
  props: {
    value: {
      default: false,
      type: Boolean
    }
  },
}
</script>

<style lang="sass">
</style>