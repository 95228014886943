<template>
  <svg width="16" height="19" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.28571 8.71428V6.14286C4.28571 4.25446 5.82589 2.71428 7.71429 2.71428C9.60268 2.71428 11.1429 4.25446 11.1429 6.14286V8.71428H4.28571ZM15.4286 10C15.4286 9.29018 14.8527 8.71428 14.1429 8.71428H13.7143V6.14286C13.7143 2.84821 11.0089 0.142856 7.71429 0.142856C4.41964 0.142856 1.71429 2.84821 1.71429 6.14286V8.71428H1.28571C0.575893 8.71428 0 9.29018 0 10V17.7143C0 18.4241 0.575893 19 1.28571 19H14.1429C14.8527 19 15.4286 18.4241 15.4286 17.7143V10Z" fill="url(#paint0_linear)"/>
    <defs>
    <linearGradient id="paint0_linear" x1="-4.88" y1="9.88119" x2="24.8" y2="9.88119" gradientUnits="userSpaceOnUse">
    <stop stop-color="#EF6549"/>
    <stop offset="1" stop-color="#B23066"/>
    </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  
}
</script>

<style lang="sass">

</style>