const SET_ENROLLMENTS = (state, { enrollments }) => {
  state.enrollments = enrollments;
};

const SET_CYCLES = (state, { cycle }) => {
  state.cycle = cycle;
};

const SET_ENROLLMENT = (state, { enrollment }) => {
  state.enrollment = enrollment;
  state.student = enrollment.EnrollmentStudent;

  state.contractorA = enrollment.EnrollmentResponsibles.find(res => res.ResponsibleTypes.some(({ type }) => type === 'contractor a'))
  state.contractorB = enrollment.EnrollmentResponsibles.find(res => res.ResponsibleTypes.some(({ type }) => type === 'contractor b'))
  state.filiationA = enrollment.EnrollmentResponsibles.find(res => res.ResponsibleTypes.some(({ type }) => type === 'filiation a'))
  state.filiationB = enrollment.EnrollmentResponsibles.find(res => res.ResponsibleTypes.some(({ type }) => type === 'filiation b'))

  state.complement = enrollment.EnrollmentComplement
  state.authorization = enrollment.EnrollmentAuthorization
  state.finance = enrollment.EnrollmentFinance
  state.files = enrollment.EnrollmentFile

  state.reEnrollContractorA = (state.contractorA && state.contractorA.ResponsibleTypes) ? state.contractorA.ResponsibleTypes.find(({ type }) => type === 'contractor a').EnrollmentResponsibleType.is_re_enroll : false;
  state.reEnrollContractorB = (state.contractorB && state.contractorB.ResponsibleTypes) ? state.contractorB.ResponsibleTypes.find(({ type }) => type === 'contractor b').EnrollmentResponsibleType.is_re_enroll : false;
  state.reEnrollFiliationA = (state.filiationA && state.filiationA.ResponsibleTypes) ? state.filiationA.ResponsibleTypes.find(({ type }) => type === 'filiation a').EnrollmentResponsibleType.is_re_enroll : false;
  state.reEnrollFiliationB = (state.filiationB && state.filiationB.ResponsibleTypes) ? state.filiationB.ResponsibleTypes.find(({ type }) => type === 'filiation b').EnrollmentResponsibleType.is_re_enroll : false;
};

const SET_STUDENT_BROTHERS = (state, { brothers }) => {
  state.enrollment.EnrollmentStudent.EnrollmentStudentBrothers = brothers;
  state.student.EnrollmentStudentBrothers = brothers;
};

export default {
  SET_ENROLLMENTS,
  SET_ENROLLMENT,
  SET_CYCLES,
  SET_STUDENT_BROTHERS
}
