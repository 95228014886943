<template>
  <svg
    width="27"
    height="24"
    viewBox="0 0 27 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.8438 16.1719C18.75 16.2656 18.75 16.3594 18.75 16.4531V21.75H2.25V5.25H13.5469C13.6406 5.25 13.7344 5.25 13.8281 5.15625L15.3281 3.65625C15.5625 3.42188 15.375 3 15.0469 3H2.25C0.984375 3 0 4.03125 0 5.25V21.75C0 23.0156 0.984375 24 2.25 24H18.75C19.9688 24 21 23.0156 21 21.75V14.9531C21 14.625 20.5781 14.4375 20.3438 14.6719L18.8438 16.1719ZM26.1562 6.75C27.2344 5.67188 27.2344 3.9375 26.1562 2.85938L24.1406 0.84375C23.0625 -0.234375 21.3281 -0.234375 20.25 0.84375L7.96875 13.125L7.5 17.3906C7.35938 18.6094 8.39062 19.6406 9.60938 19.5L13.875 19.0312L26.1562 6.75ZM21.5625 8.15625L12.8438 16.875L9.75 17.25L10.125 14.1562L18.8438 5.4375L21.5625 8.15625ZM24.5625 4.45312C24.7969 4.64062 24.7969 4.92188 24.6094 5.15625L23.1562 6.60938L20.4375 3.84375L21.8438 2.4375C22.0312 2.20312 22.3594 2.20312 22.5469 2.4375L24.5625 4.45312Z"
      fill="url(#paint0_linear)"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="-8.235"
        y1="11.8812"
        x2="41.85"
        y2="11.8812"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#EF6549" />
        <stop offset="1" stop-color="#B23066" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {

}
</script>

<style lang="sass">
</style>