<template>
  <div class="home">
    <template>
      <b-container class="container-rda">
        <b-col>
          <b-row>
            <b-col>
              <b-breadcrumb :items="breadcrumb"></b-breadcrumb>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <h2 class="purple bold">Resetar Senhas</h2>
            </b-col>
            <b-col class="text-right"></b-col>
          </b-row>
          <b-row class="mt-5 justify-content-center">
            <b-col md="6">
              <b-form-group label="Buscar pelo email">
                <b-input-group-prepend>
                  <b-form-input
                    type="search"
                    placeholder="Digite o email"
                    v-model="searchTitle"
                    v-on:keyup.enter="
                      page = 1;
                      getList();
                    "
                  >
                  </b-form-input>
                  <b-input-group-text
                    class="clear-fields"
                    v-if="searchTitle != ''"
                    v-b-tooltip.hover
                    title="Limpar Pesquisa"
                  >
                    <b-icon icon="x" @click="clearFields" />
                  </b-input-group-text>
                </b-input-group-prepend>
              </b-form-group>
            </b-col>
            <div class="w-100"></div>
            <b-col md="6">
              <b-container fluid>
                <b-row
                  class="header-list-teacher"
                  v-if="users.length > 0 && isRequestSend"
                >
                  <b-col>
                    <p class="bold">Nome</p>
                  </b-col>
                  <b-col md="5">
                    <p class="bold">Email</p>
                  </b-col>
                  <b-col md="3">
                    <p class="bold">Senha</p>
                  </b-col>
                </b-row>
              </b-container>
              <b-container fluid>
                <b-row
                  align-v="center"
                  class="list-teacher"
                  v-for="user in users"
                  v-bind:key="user.id"
                >
                  <b-col>
                    <p>{{ user.name }}</p>
                  </b-col>
                  <b-col md="5">
                    <p>{{ user.email }}</p>
                  </b-col>
                  <b-col md="3">
                    <a href="#" @click="resetPassword(user.id)" class="btn-link"
                      >Resetar</a
                    >
                  </b-col>
                </b-row>
                <b-row
                  align-v="center"
                  class="list-teacher"
                  v-if="count <= 0 && isRequestSend"
                >
                  <b-col> Email de cadastro não encontrado </b-col>
                </b-row>
              </b-container>
            </b-col>
          </b-row>
        </b-col>
      </b-container>
    </template>
  </div>
</template>

<script>
import DataService from "@/services/user.service";

import Moment from "moment";

export default {
  components: {

  },
  data() {
    return {
      users: [],
      count: 0,
      isRequestSend: false,
      searchTitle: '',

      breadcrumb : [
        {
          text: 'Resetar Senhas',
          active: true
        }
      ],
      selected: null
    }
  },

  filters: {
    formatDate(value) {
      return Moment(value).format("DD/MM/YYYY");
    },
  },

  methods: {
    getRequestParams(searchTitle) {
      let params = {}

      if (searchTitle) {
        params["email"] = searchTitle
      }

      return params
    },

    getList() {
      const params = this.getRequestParams(
        this.searchTitle
      )

      DataService.getAll(params)
        .then(response => {
          this.users = response.data.users
          this.count = response.data.count
          this.isRequestSend = true

        }).catch(error => {
          return error
        });
    },

    clearFields() {
      this.searchTitle = ''
      this.isRequestSend = false
      this.users = []
    },

    resetPassword(id) {
      DataService.get(id)
      .then((response) => {
        response = response.data.user
        this.name = response.name
        this.email = response.email
        this.cpf = response.cpf

        this.resetPass = this.cpf.slice(0, 6)

        this.submitResetPassword(id)
      }).catch((error) => {
        this.loading = false
        this.message =
          (error.response && error.response.data) ||
          error.message ||
          error.toString()
        this.$swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Por favor, tente novamente mais tarde.",
          footer: `${this.message.error}`,
        })
      })
    },

    submitResetPassword(id){
      this.$swal.fire({
        title: 'Deseja resetar a senha desse usuário?',
        text: `A senha de ${this.name} será resetada.`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#68c5b7',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (result.isConfirmed) {
          var data = {
            email: this.email,
            new_password: this.resetPass,
            confirm_password: this.resetPass
          }

          DataService.createNewPassword(id, data)
          .then((response) => {
            const Toast = this.$swal.mixin({
              toast: true,
              position: "bottom-end",
              showConfirmButton: false,
              timer: 5000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", this.$swal.stopTimer);
                toast.addEventListener("mouseleave", this.$swal.resumeTimer);
              },
            })

            Toast.fire({
              icon: "success",
              title: "Senha resetada com sucesso!",
            })

            return response.data
          })
          .catch((error) => {
            this.loading = false
            this.message =
              (error.response && error.response.data) ||
              error.message ||
              error.toString()
            this.$swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Não foi possivel resetar!",
              footer: `${this.message.error}`,
            })
          })
        }
      }).catch((error) => {
        this.loading = false;
        this.message =
          (error.response && error.response.data) ||
          error.message ||
          error.toString()
        this.$swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Por favor, tente novamente mais tarde.",
          footer: `${this.message.error}`,
        })
      })
    }
  },
}
</script>

<style lang="scss" scoped>

.home {
  height: 100%;
}

.container-rda {
  padding-bottom: 0;
}

</style>
