<template>
  <div class="loadingView" v-if="loadingForm">
    <LoadingList v-model="loadingForm" text="Carregando dados." />
  </div>
  <div v-else>
    <div>
      <b-row class="information">
        <b-col>
          <p v-if="showContract">Insira abaixo os documentos solicitados.</p>
        </b-col>

        <div class="w-100"></div>
        <b-col class>
          <div class="upload-files" v-if="showContract && showForThisEnrollment()">
            <b-row align-v="center">
              <b-col>
                <h5><span class="required">*</span>Contrato Assinado</h5>
                <p class="mb-4" v-if="enrollment.EnrollmentFile">
                  Enviado para o seu e-mail para assinatura digital.
                  <br />
                  <span v-if="
                    enrollment.EnrollmentFile.sign_contract_a_url &&
                    !enrollment.signed_at
                  ">
                    <strong>Contratante A</strong> -
                    <a :href="enrollment.EnrollmentFile.sign_contract_a_url" target="_blank"
                      class="purple underline">Clique aqui para assinar o seu Contrato</a>
                  </span>
                  <br />
                  <span v-if="
                    enrollment.EnrollmentFile.sign_contract_b_url &&
                    !enrollment.signed_at
                  ">
                    <strong>Contratante B</strong> -
                    <a :href="enrollment.EnrollmentFile.sign_contract_b_url" target="_blank"
                      class="purple underline">Clique aqui para assinar o seu Contrato</a>
                  </span>
                </p>
              </b-col>

              <b-col class="text-right" v-if="!enrollment.signed_at">
                <span target="_blank" class="upload holding btn">Aguardando assinatura</span>
              </b-col>

              <b-col class="text-right" v-else>
                <a target="_blank" class="upload done btn contract">Assinado!</a>
              </b-col>
            </b-row>
          </div>
          <div v-if="showContract">
            <div class="upload-files" v-for="(document, index) in value" :key="index">
              <h5 class="mb-4">
                <span v-if="document.isRequired" :class="isRequired">*</span>
                {{ document.title }}
              </h5>

              <b-row class="m-b" align-v="center" v-for="file in document.files" :key="file.fileName">
                <b-col class="mr-auto">
                  <b-form-file accept=".pdf" :value="file.file" @input="(f) => setFile(file, f)" plain>
                  </b-form-file>
                </b-col>

                <b-col class="text-right">
                  <b-form-checkbox v-if="not_applicable_files.includes(file.fileName)" name="nao-se-aplica"
                    :value="true" v-model="file.notApplicable" @change="changeNotApplicable(file)">
                    Não se aplica
                  </b-form-checkbox>
                </b-col>

                <!-- Botão Enviar -->
                <b-col v-if="shouldShowSendButton(file)" class="text-right">
                  <b-button class="upload" @click="submitFile(file)">Enviar</b-button>
                </b-col>

                <!-- Loading -->
                <Loading v-if="!file.status && file.progress" :progress="file.progress" />

                <!-- Botão Inválido -->
                <b-col class="text-right" v-if="file.isInvalid">
                  <span target="_blank" :href="file.url" class="upload invalid btn">Inválido</span>
                </b-col>

                <!-- Botão Enviado -->
                <b-col class="text-right" v-else-if="file.status">
                  <a v-if="file.url !== 'notApplicable'" target="_blank" :href="file.url" class="upload done btn">
                    Enviado!
                  </a>
                  <span v-else class="upload done btn">
                    Enviado!
                  </span>
                </b-col>
              </b-row>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import Loading from "@/components/Loading.vue";
import LoadingList from "@/components/LoadingList.vue";
import DataService from "@/services/enrollments.service";
import httpRda from "../../../http-rda";

export default {
  name: "Submit",
  components: {
    Loading,
    LoadingList,
  },
  props: {
    value: [],
    enrollmentId: null,
    editedUserId: null,
  },

  data() {
    return {
      enrollmentYear: null,
      loadingForm: true,
      rdaUnit: process.env.VUE_APP_RDA_UNIT,
      showDocs: null,
      showContract: true,
      enrollment: {},
      is_re_enroll: false,
      is_re_enroll_updated: false,
      not_applicable_files:
        process.env.VUE_APP_RDA_UNIT === "BELO_HORIZONTE"
          ? [
            "identity_back",
            "cpf",
            "transference_declaration",
            "discharge",
            "school_record",
            "identity_contractor_a_back",
            "identity_contractor_b_back",
            "cpf_contractor_a",
            "cpf_contractor_b",
          ]
          : [],
    };
  },

  methods: {
    ...mapActions({
      submitDocument: "enrollment/submitDocument",
      finishEnrollment: "enrollment/finishEnrollment",
      getFilesEnrollment: "enrollment/enrollment",
    }),

    async getEnrollment() {
      this.enrollment = await httpRda
        .get(`enrollments/${this.enrollmentId}`)
        .then((response) => response.data.enrollment);
      await this.fillDocument();
    },

    async submitFile(file) {
      const bodyFormData = new FormData();
      bodyFormData.append("fileName", file.fileName);

      if (this.editedUserId) {
        bodyFormData.append("notification", true);
        bodyFormData.append("edited_user_id", this.editedUserId);
      }

      if (file.file) {
        bodyFormData.append("file", file.file);
      } else if (!file.notApplicable && !file.file) {
        alert("Selecione um arquivo para enviar.");
        return;
      }

      file.status = 0;
      file.progress = 1;

      try {
        await httpRda
          .post(`/enrollments/${this.enrollmentId}/files`, bodyFormData, {
            headers: { "Content-Type": "multipart/form-data" },
          })
          .then((response) => (file.url = response.data.url));

        setTimeout(() => (file.progress = 100), 300);
        setTimeout(() => (file.status = true), 1000);

        return this.$store.getters.enrollment;
      } catch (error) {
        file.status = false;
        file.progress = null;

        alert(error);
      }

    },

    setFile(file, f) {
      if (f) {
        file.progress = null;
        file.status = false;
        file.file = f;
        file.notApplicable = false;
        file.url = '';
      }
    },

    async showDocumentsAndContracts() {
      this.enrollmentYear = await DataService.get(this.enrollmentId).then(
        (resp) => resp.data.enrollment.Calendar.designation
      );

      (this.enrollmentYear == "2022" || this.enrollmentYear == "2023") &&
        this.rdaUnit == "POUSO_ALEGRE"
        ? (this.showContract = false)
        : (this.showContract = true);

      this.loadingForm = false;
    },

    showForThisEnrollment() {
      return this.enrollment.EnrollmentStudent.cycle_id != 8;
    },

    async fillDocument() {
      if (
        this.enrollment !== undefined &&
        this.enrollment.EnrollmentFile !== undefined &&
        this.enrollment.EnrollmentFile.id !== undefined
      ) {
        for (const key in this.enrollment.EnrollmentFile) {
          this.value.forEach((keyValue) => {
            for (const data of keyValue.files) {
              if (key === data.fileName) {
                data.url = this.enrollment.EnrollmentFile[key];

                data.status = data.url !== null ? true : false;
                data.notApplicable = data.url === "notApplicable";
              }
            }
          });
          this.value[0].signed_at = this.enrollment.signed_at;
        }
      }
    },

    async setIsReEnroll() {
      await httpRda.patch(
        `/enrollments/${this.enrollmentId}/files/isreenroll`,
        {
          is_re_enroll: this.is_re_enroll,
        }
      );
    },

    isRequired() {
      if (this.rdaUnit === "BELO_HORIZONTE") {
        return "required";
      }
      return "";
    },

    onFileSelected(file) {
      if (file.file) {
        file.notApplicable = false;
        file.url = '';
        file.status = false;
      }
    },

    changeNotApplicable(file) {
      file.status = false;
      if (!file.notApplicable) {
        file.url = '';
        file.progress = null;
        file.file = null;
        file.status = false;
      } else {
        file.url = '';
        file.progress = null;
        file.file = null;
      }
    },


    shouldShowSendButton(file) {
      return (!file.status && !file.progress) ||
        (file.notApplicable && file.url !== 'notApplicable') ||
        (!file.notApplicable && file.url === 'notApplicable');
    }
  },

  computed: {
    ...mapGetters({
      files: "enrollment/getFiles",
    }),

    allFiles() {
      if (!this.showDocs) {
        return true;
      }
      if (this.files == null) return false;

      const hasSubmitAllFiles = [
        this.files.identity,
        this.files.identity_back,
        this.files.cpf,
        this.files.vaccination_record,
        this.files.discharge,
        this.files.identity_contractor_a,
        this.files.identity_contractor_a_back,
        this.files.identity_contractor_b,
        this.files.identity_contractor_b_back,
        this.files.cpf_contractor_a,
        this.files.cpf_contractor_b,
      ];

      return hasSubmitAllFiles.every((a) => a !== null);
    },
  },

  watch: {
    file() {
      if (this.value)
        this.value.map((doc) => {
          doc.files.map((file) => {
            const f = this.files[file.fileName];
            if (!f) return;

            file.url = f;
            file.status = true;
            file.progress = 100;
            file.isInvalid = this.files["is_valid_" + file.fileName] === false;
          });
        });
    },
  },

  created() {
    if (this.$route.name.includes("rematricula")) {
      this.is_re_enroll = true;
      this.setIsReEnroll();
    }

    this.getEnrollment();
    this.showDocumentsAndContracts();
  },
};
</script>

<style lang="scss" scoped>
.information {
  .form-group {
    margin-bottom: 40px;
  }
}

.m-b {
  margin-bottom: 20px;
}
</style>
