<template>
  <b-modal ref="edit-dates" id="edit-dates" hide-header hide-footer centered no-close-on-backdrop>
    <h4 class="purple mb-4">Editar data</h4>
    <p>
      Escolha a nova data de finalização para:
      <strong>{{ studentName }}</strong>
    </p>

    <b-row>
      <b-col md="6" class="mt-3">
        <b-form-group class="calendar">
          <label>Nova data de finalização</label>
          <Datepicker
            v-model="studentDeadline"
            @selected="setDate"
            placeholder="Selecione"
            format="dd/MM/yyyy"
            :use-utc="true"
            :disabled-dates="disabledDates"
            :language="languages[language]"
          />
        </b-form-group>
      </b-col>
    </b-row>

    <b-row class="mt-5">
      <b-col>
        <b-button class="btn large outline" @click="hideModal()">Voltar</b-button>
      </b-col>
      <b-col class="text-right">
        <b-button class="btn large blue" @click="saveDate">Salvar</b-button>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import * as lang from "vuejs-datepicker/src/locale";

export default {
  name: "EditSyllabusDate",
  components: {
    Datepicker,
  },

  props: {
    student: {
      type: Object,
      default: () => {
        "";
      },
    },

    callback: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      date: "",
      language: "ptBR",
      languages: lang,
      disabledDates: {
        to: new Date(),
        from: new Date(2022, 0, 1),
      },
    };
  },

  computed: {
    studentName() {
      if (this.student) {
        return this.student.Student.User.name;
      }
      return "";
    },
    studentDeadline() {
      if (this.student) {
        return new Date(this.student.deadline.split("-"));
      }
      return new Date("");
    },
  },

  methods: {
    hideModal() {
      this.$refs["edit-dates"].hide();
    },

    saveDate() {
      this.callback(this.date);
      this.hideModal();
    },

    setDate(date) {
      this.date = date;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/css/variables.scss";

div#edit-dates {
  .modal-dialog {
    max-width: 820px;

    .modal-content {
      background-color: $grey2;
      border: 0;
      border-radius: 0;

      .modal-body {
        padding: 60px;
      }
    }
  }

  .students {
    list-style: none;
    padding: 0;
    margin: 0;

    .student {
      align-items: center;
      display: flex;
      justify-content: space-between;
      line-height: 60px;
      border-bottom: 1px solid $grey5;

      &:last-child {
        border-bottom: 0;
      }

      .name {
        margin: 0;
      }

      .actions {
        a {
          margin: 0 15px;
        }
      }
    }
  }

  .vdp-datepicker input {
    position: relative;
    background: $white;
    color: $grey4;
    font-size: 16px;
    line-height: 34px;
    height: auto;
    border: 1px solid $grey10;
    border-radius: 0.25rem;
    width: 100%;
    padding: 0.375rem 0.75rem;
  }
}
</style>