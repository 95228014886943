<template>
  <div class="container">
    <a v-if="qrCodeToken" class="qrcode">
      <div id="qrcode">
        <qrcode-vue :value="qrCodeToken" size="390" level="H" />
      </div>
      <span
        :style="{
          width: timePercentage + '%',
          maxWidth: '100%',
        }"
        class="progress"
      ></span>

      <div v-if="timeLeftShow <= 0" class="spinnerLoading">
        <b-spinner label="Loading..."></b-spinner>
      </div>
    </a>
    <p v-if="timeLeftShow > 0" class="time-left">
      Tempo restante: {{ timeLeftShow }} segundos
    </p>

    <p v-if="timeLeftShow <= 0" class="time-left">Trocando...</p>
  </div>
</template>

<script>
import httpRda from "../../http-rda";
import moment from "moment-timezone";
import { mapGetters } from "vuex";
import QrcodeVue from "qrcode.vue";

export default {
  name: "qrCode",
  components: {
    QrcodeVue,
  },
  data() {
    return {
      qrCodeToken: null,
      requestingToken: false,
      tokenBeginTime: null,
      tokenEndTime: null,
      isValidToken: false,
      timeLeft: 0,
      timePercentage: 0,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/getAuthData",
    }),
    qrCodeUrl() {
      return `https://chart.googleapis.com/chart?cht=qr&chl=${
        this.qrCodeToken || ""
      }&chs=547x547&choe=UTF-8&chld=L|2`;
    },
    timeLeftShow() {
      let number = this.timeLeft / 1000;
      number = number.toFixed(0);
      if (number < 10) {
        return number < 10 ? "0" + number : number;
      }
      return number;
    },
  },
  methods: {
    decodeBase64(base64Value) {
      return atob(base64Value);
    },

    async getToken() {
      if (this.requestingToken) {
        return;
      }

      try {
        this.requestingToken = true;

        const response = await httpRda.get("/attendances/token");

        const token =
          response.data.token && this.currentUser.role.includes("qrcode")
            ? response.data.token + "@" + this.currentUser.id
            : null;

        this.qrCodeToken = token;

        this.decodeToken(token.split(":")[0]);
      } catch (error) {
        console.error("Erro ao obter o token:", error);
      } finally {
        this.requestingToken = false;
      }
    },

    decodeToken(token) {
      const decodedToken = this.decodeBase64(token);

      const [beginExpTime, endExpTime] = decodedToken.split("-");

      this.tokenBeginTime = +beginExpTime;
      this.tokenEndTime = +endExpTime;

      this.checkTokenValidity();
    },

    checkTokenValidity() {
      const currentTime = moment().utc().valueOf();

      this.isValidToken =
        currentTime >= this.tokenBeginTime && currentTime <= this.tokenEndTime;

      if (!this.isValidToken) {
        this.getToken();
      }
    },

    startTokenValidityCheck() {
      setInterval(() => {
        this.checkTokenValidity();
        this.timeLeft = this.tokenEndTime - moment().utc().valueOf();

        //tim percentage increase by 1% to 100%
        this.timePercentage =
          100 -
          Math.floor(
            ((this.tokenEndTime - moment().utc().valueOf()) /
              (this.tokenEndTime - this.tokenBeginTime)) *
              100
          );
      }, 1000);
    },
  },
  created() {
    this.getToken();
    this.startTokenValidityCheck();
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/css/variables.scss";
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.logo {
  margin-bottom: 2rem;
}

.qrcode {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 420px;
  height: 420px;
  overflow: hidden;
  position: relative;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 8px;
  background-color: #e9e8e8;
  #qrcode {
    position: relative;
    z-index: 1;
    border-radius: 8px;
  }

  .progress {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 100%;
    background: $gradient1;
    z-index: 0;
    border-radius: 0 !important;
    transition: all 0.1s ease-out;
  }
}

.time-left {
  margin-top: 1rem;
  margin-bottom: 0px;
  font-size: 12px;
  color: #666;
}

.spinnerLoading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba($color: #fff, $alpha: 0.8);
}
</style>
