<template>
  <div class="loadingView" v-if="loadingForm">
    <LoadingList v-model="loadingForm" text="Carregando dados." />
  </div>
  <div v-else>
    <b-row>
      <b-col md="12" class="mt-4">
        <b-form-group class="group-radio">
          <label for="label">
            <strong>1</strong> - Fica o aluno antecipadamente autorizado,
            independentemente de permissão específica, a participar de excursões
            — parte integrante do projeto pedagógico desta escola — desde que
            realizadas dentro do horário normal de aulas e dentro dos limites do
            município. <span class="required">*</span>
          </label>
          <div class="radio-inline">
            <b-form-radio-group
              required
              v-model="value.excursion"
              :options="options.yesNo"
              :name="'excursion' + value.enrollment_id"
              :id="'excursion' + value.enrollment_id"
            ></b-form-radio-group>
          </div>
        </b-form-group>
      </b-col>
      <b-col md="12">
        <b-form-group class="group-radio">
          <label for="label">
            <strong>2</strong> - Fica o aluno autorizado a tomar banho de
            ducha/mangueira independentemente de autorização específica.
            <span class="required">*</span>
          </label>
          <div class="radio-inline">
            <b-form-radio-group
              required
              v-model="value.shower"
              :options="options.yesNo"
              :name="'shower' + value.enrollment_id"
              :id="'shower' + value.enrollment_id"
            ></b-form-radio-group>
          </div>
        </b-form-group>
      </b-col>
      <b-col md="12" v-if="rdaUnit != 'POUSO_ALEGRE'">
        <b-form-group class="group-radio">
          <label for="label">
            <strong>3</strong> - Fica o aluno, matriculado no Ensino Médio,
            autorizado a sair desacompanhado das dependências da Escola da Serra
            durante o horário de recreio. <span class="required">*</span>
          </label>

          <div class="radio-inline">
            <b-form-radio-group
              required
              v-model="value.leave_school_break"
              :options="options.yesNo"
              :name="'leave_school_break' + value.enrollment_id"
              :id="'leave_school_break' + value.enrollment_id"
            ></b-form-radio-group>
          </div>
        </b-form-group>
      </b-col>
      <b-col md="12" v-if="rdaUnit != 'POUSO_ALEGRE'">
        <b-form-group class="group-radio">
          <label for="label">
            <strong>4</strong> - Fica o aluno, matriculado no Ensino Médio,
            autorizado a sair desacompanhado da Escola da Serra antes do término
            do horário normal das aulas caso o encerramento das atividades
            escolares seja, eventualmente, antecipado.
            <span class="required">*</span>
          </label>
          <div class="radio-inline">
            <b-form-radio-group
              required
              v-model="value.leave_school_antecipated"
              :options="options.yesNo"
              :name="'leave_school_antecipated' + value.enrollment_id"
              :id="'leave_school_antecipated' + value.enrollment_id"
            ></b-form-radio-group>
          </div>
        </b-form-group>
      </b-col>
      <b-col md="12" v-if="rdaUnit != 'POUSO_ALEGRE'">
        <b-form-group class="group-radio">
          <label for="label">
            <strong>5</strong> - Fica o aluno, matriculado no 3° Ciclo ou Ensino
            Médio, autorizado a sair desacompanhado das dependências da Escola
            da Serra durante o horário de almoço.
            <span class="required">*</span>
          </label>
          <div class="radio-inline">
            <b-form-radio-group
              required
              v-model="value.leave_school_lunch"
              :options="options.yesNo"
              :name="'leave_school_lunch' + value.enrollment_id"
              :id="'leave_school_lunch' + value.enrollment_id"
            ></b-form-radio-group>
          </div>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import LoadingList from "@/components/LoadingList.vue";
export default {
  name: "Authorizations",
  components: {
    LoadingList,
  },

  props: ["value"],

  data() {
    return {
      rdaUnit: process.env.VUE_APP_RDA_UNIT,
      loadingForm: true,
      options: {
        yesNo: [
          { text: "Sim", value: true },
          { text: "Não", value: false },
        ],
      },
    };
  },

  methods: {
    defineToFalseFields() {
      if (this.rdaUnit === "POUSO_ALEGRE") {
        (this.value.leave_school_break = false),
          (this.value.leave_school_antecipated = false);
      }
      this.loadingForm = false;
    },
  },

  computed: {
    //
  },

  watch: {
    //
  },

  mounted() {
    this.defineToFalseFields();
  },

  created() {
    //
  },
};
</script>

<style lang="scss" scoped>
//
</style>
