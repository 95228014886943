<template>
  <b-container class="container-rda">
    <b-row>
      <b-col>
        <b-breadcrumb :items="breadcrumb"></b-breadcrumb>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <h2 class="purple bold">Registro de professor</h2>
      </b-col>
    </b-row>

    <b-row class="teacher-profile mt-50">
      <b-col md="3">
        <ProfilePhoto
          :isEditing="!!id"
          :photo="photo"
          :callback="setNewPhoto"
        />
      </b-col>

      <b-col>
        <b-row>
          <b-col md="1" class="status">
            <b-form-group label="Ativo">
              <b-form-checkbox
                switch
                v-model="teacher.is_active"
              ></b-form-checkbox>
            </b-form-group>
          </b-col>

          <b-col>
            <b-form-group class="required" description>
              <label for="label">Nome</label>
              <b-form-input
                v-model="teacher.User.name"
                type="text"
                placeholder
                required
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col>
            <b-form-group class="required" description>
              <label for="label">E-mail</label>
              <b-form-input
                v-model="teacher.User.email"
                type="text"
                placeholder
                required
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-form-group description>
              <label for="label">Telefone</label>
              <b-form-input
                v-model="teacher.User.phone"
                type="text"
                v-mask="['(##) #####-####']"
                placeholder="(__) _____-____"
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col>
            <b-form-group class="required" description>
              <label for="label">CPF</label>
              <b-form-input
                v-model="teacher.User.cpf"
                :state="verificarCpf"
                type="text"
                v-mask="['###.###.###-##']"
                placeholder="___.___.___-__"
                required
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col>
            <b-form-group description>
              <label for="label">RG</label>
              <b-form-input
                v-model="teacher.User.rg"
                type="text"
                v-mask="['###########']"
                placeholder="Somente números"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-form-group description>
              <label for="label">País</label>
              <multiselect
                v-model="country"
                :options="countriesList"
                :close-on-select="true"
                :clear-on-select="false"
                label="name"
                track-by="name"
                placeholder="Selecione um país"
                selectLabel
                selectedLabel
                deselectLabel
              />
            </b-form-group>
          </b-col>

          <b-col>
            <b-form-group description>
              <label for="label">Estado</label>
              <multiselect
                v-model="state"
                :options="statesList"
                :close-on-select="true"
                :clear-on-select="false"
                label="name"
                track-by="name"
                placeholder="Selecione um estado"
                selectLabel
                selectedLabel
                deselectLabel
              />
            </b-form-group>
          </b-col>

          <b-col style="z-index: 10">
            <b-form-group class="required" description>
              <label for="label">Ciclos</label>
              <select-multiple
                :callback="selectCycle"
                :options="cycles"
                :hasSelectAll="false"
                :initial="cyclesSelected"
                label="Ciclos"
                placeholder="Selecione um Ciclo"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row class>
          <b-col>
            <b-form-group
              class="accordion-materias"
              label="Disciplinas"
              style="z-index: 1"
            >
              <div class="accordion" role="tablist">
                <b-card no-body v-for="cycle in cyclesFilleds" :key="cycle.id">
                  <b-card-header header-tag="header" role="tab">
                    <p block v-b-toggle="'accordion-' + cycle.id">
                      {{ cycle.name }} - {{ cycle.designation }}
                    </p>
                  </b-card-header>

                  <b-collapse
                    :id="'accordion-' + cycle.id"
                    visible
                    accordion="my-accordion"
                    role="tabpanel"
                  >
                    <b-card-body>
                      <b-form-checkbox-group
                        :id="'checkbox-group-' + cycle.id"
                        v-model="subjectsSelected"
                        name="flavour-2"
                      >
                        <b-form-checkbox
                          v-for="subject in cycle.Subjects"
                          :key="subject.id"
                          :value="subject"
                          >{{ subject.designation }}</b-form-checkbox
                        >
                        <p v-if="cycle.Subjects == 0">
                          Não há matérias associadas a este Ciclo
                        </p>
                      </b-form-checkbox-group>
                    </b-card-body>
                  </b-collapse>
                </b-card>
              </div>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row class="mt-3 login-fields" v-if="!id">
          <b-col md-3>
            <b-form-group class="required" description>
              <label for="label">Senha</label>
              <b-form-input
                v-model="teacher.User.password"
                :class="{ isInvalid: !teacher.User.password.length >= 6 }"
                type="password"
                placeholder
                required
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col md-3>
            <b-form-group class="required" description>
              <label for="label">Confirme a senha</label>
              <b-form-input
                v-model="teacher.User.confirmPassword"
                :class="{ isInvalid: !confirmPasswordValidation }"
                type="password"
                placeholder
                required
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-row align-v="center" class="mt-5">
      <b-col></b-col>

      <b-col></b-col>

      <b-col class="text-right" v-if="subjectsSelected.length">
        <a class="btn-link" v-b-modal.articulador>Nomear articulador</a>
      </b-col>

      <b-col class="text-right">
        <b-button class="btn large outline" v-b-modal.tutoria
          >Atribuir Tutorados</b-button
        >
      </b-col>
    </b-row>

    <b-row align-v="center" class="mt-5">
      <b-col></b-col>

      <b-col class="text-right">
        <b-button
          class="large blue outline"
          :to="{
            name: 'professores',
            params: {
              cycle: this.$route.params.cycle,
              subject: this.$route.params.subject,
              page: this.$route.params.page,
            },
          }"
          >Voltar</b-button
        >
      </b-col>

      <b-col></b-col>

      <b-col class="text-right">
        <b-button @click="submit" class="large blue">Salvar</b-button>
      </b-col>
    </b-row>

    <TutoriaModal
      :studentsToEdit="teacher.tutor"
      :callback="setStudentTutors"
      :teacherId="this.id"
    />

    <ArticuladorModal
      :subjectsTeaching="subjectsSelected"
      :subjectsToArticulate="subjectsToArticulate"
      :callback="setArticulador"
    />
  </b-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import utils from "@/utils";

import ProfilePhoto from "@/components/ProfilePhoto";
import TutoriaModal from "@/components/modals/TutoriaModal";
import ArticuladorModal from "@/components/modals/ArticuladorModal";
import SelectMultiple from "@/components/inputs/SelectMultiple.vue";
import httpRda from "../../../http-rda";

export default {
  name: "perfil-professor",
  components: {
    ProfilePhoto,
    TutoriaModal,
    SelectMultiple,
    ArticuladorModal,
  },

  data() {
    return {
      photo: null,
      cyclesSelected: [],
      childhoodCycles: [],
      subjectsSelected: [],
      cyclesFilleds: [],
      studentsToTutor: [],
      subjectsToArticulate: [],

      country: null,
      state: null,

      teacher: {
        is_active: true,
        User: {
          name: "",
          email: "",
          password: "",
          confirmPassword: "",
          birth_date: null,
          cpf: "",
          rg: "",
          phone: "",
          gender: "",
          state_origin: "",
          country_origin: "",
        },
      },

      breadcrumb: [
        {
          text: "Comunidade",
          href: "/comunidade",
        },
        {
          text: "Professores",
          href: "/professores",
        },
        {
          text: "Registro de professor",
          href: "/perfil",
          active: true,
        },
      ],
    };
  },

  computed: {
    ...mapGetters({
      cycles: "shared/cycles",
      subjectsByCycles: "community/subjectsByCycles",
      countriesList: "shared/countries",
      statesList: "shared/states",
      teacherToUpdate: "community/teacher",
    }),

    confirmPasswordValidation() {
      return this.teacher.User.password === this.teacher.User.confirmPassword;
    },

    teacherToSave() {
      const Subjects = this.subjectsSelected.map((s) => s.id);
      const data = {
        Subjects,
        is_active: this.teacher.is_active,
        User: {
          name: this.teacher.User.name,
          email: this.teacher.User.email,
          cpf: this.teacher.User.cpf,
          rg: this.teacher.User.rg,
          Phones: this.teacher.User.phone ? [this.teacher.User.phone] : [],
          gender: "undefined",
          state_origin: this.state ? this.state.name : "",
          country_origin: this.country ? this.country.name : "",
        },
      };
      return data;
    },

    teacherToSaveCreate() {
      const Subjects = this.subjectsSelected.map((s) => s.id);
      const data = {
        Subjects,
        is_active: this.teacher.is_active,
        User: {
          name: this.teacher.User.name,
          email: this.teacher.User.email,
          password: this.teacher.User.password,
          cpf: this.teacher.User.cpf,
          rg: this.teacher.User.rg,
          Phones: this.teacher.User.phone ? [this.teacher.User.phone] : [],
          gender: "undefined",
          state_origin: this.state ? this.state.name : "",
          country_origin: this.country ? this.country.name : "",
        },
      };
      return data;
    },

    id() {
      return this.$route.params.id;
    },

    verificarCpf() {
      if (this.teacher.User.cpf != undefined) {
        const validarCPF = utils.validateCPF(this.teacher.User.cpf);
        return validarCPF ? true : false;
      } else {
        return false;
      }
    },
  },

  methods: {
    ...mapActions({
      getSubjects: "community/setSubjectsByCycles",
      setStudentTutor: "community/setStudentTutor",
      setSubjectArticulator: "community/setSubjectArticulator",
      getCycles: "shared/listCycles",
      getCountries: "shared/getCountries",
      getStates: "shared/getStates",
      getTeacher: "community/getTeacher",
      createTeacher: "community/createTeacher",
      updateTeacher: "community/updateTeacher",
      updateTeachersSubjects: "community/updateTeachersSubjects",
      savePhoto: "community/saveTeacherPhoto",
      savePhone: "community/saveTeacherPhone",
      updateTeacherPhone: "community/updateTeacherPhone",
      getLoginStatus: "community/getLoginStatus",
    }),

    ...mapMutations({
      resetState: "shared/RESET_STATE",
      resetStateCommunity: "community/RESET_STATE_COMMUNITY",
    }),

    resetStates() {
      this.resetState();
      this.resetStateCommunity();
    },

    async create() {
      try {
        let students_id;
        students_id = this.studentsToTutor.map((item) => {
          return item.student_id;
        });
        const data = this.teacherToSaveCreate;

        const response = await this.createTeacher(data);
        const id = response.data.teacher.id;

        this.updatePhoto(id);

        if (students_id.length > 0) {
          await httpRda.post(`tutoring`, {
            students_id: students_id,
            teacher_id: id,
          });
        }

        await this.setSubjectArticulator({
          subjects: this.subjectsToArticulate,
          articulator_id: id,
        });

        await httpRda.post(`/teachers/${id}/childhood-cycle`, {
          cycles: this.childhoodCycles,
        });

        this.successReq();
      } catch (error) {
        this.errorReq(error);
      }
    },

    async update() {
      try {
        let students_id;

        students_id = this.studentsToTutor.map((item) => {
          return item.student_id;
        });

        const data = this.teacherToSave;

        await this.updateTeacher({
          ...data,
          ...data.User,
          id: this.id,
        });
        const id = this.id;

        this.updatePhoto(id);

        if (students_id.length > 0) {
          await httpRda.post(`tutoring`, {
            students_id: students_id,
            teacher_id: id,
          });
        }

        const subjects = this.subjectsSelected.map((s) => s.id);

        if (subjects.length) {
          this.updateTeachersSubjects({ teacher_id: id, subjects });
        }

        this.updateSubjectsArticulating().then(() => {
          httpRda.post(`/teachers/${id}/childhood-cycle`, {
            cycles: this.childhoodCycles,
          });
        });

        this.$swal.fire({
          title: "Sucesso",
          text: "Atualizado com sucesso",
          icon: "success",
          showConfirmButton: false,
          timer: 1500,
        });

        let self = this;
        setTimeout(() => {
          self.redirectTo();
        }, 200);
      } catch (error) {
        this.errorReq(error);
      }
    },

    async updateSubjectsArticulating() {
      const id = this.id;

      await this.setSubjectArticulator({
        subjects: this.subjectsToArticulate,
        articulator_id: id,
      });

      const removedSubjectsToArticulate =
        this.teacherToUpdate.articulate.filter(
          (st) => !this.subjectsToArticulate.some((tu) => tu.id === st.id)
        );

      if (removedSubjectsToArticulate.length)
        await this.setSubjectArticulator({
          subjects: removedSubjectsToArticulate,
          articulator_id: null,
        });
    },

    async setTeacher() {
      await this.getTeacher(this.id);
      this.teacher = this.teacherToUpdate;
      this.country = this.countriesList.find(
        (c) => c.name === this.teacher.User.country_origin
      );
      this.state = this.statesList.find(
        (s) => s.name === this.teacher.User.state_origin
      );

      if (this.teacher.cycles) this.cyclesSelected = this.teacher.cycles;

      if (this.teacher.subjects) this.subjectsSelected = this.teacher.subjects;

      if (this.teacher.User.photo) this.photo = this.teacher.User.photo;

      if (
        this.teacher &&
        Array.isArray(this.teacher.articulate) &&
        this.teacher.articulate.length
      )
        this.subjectsToArticulate = this.teacher.articulate;

      if (
        this.teacher &&
        this.teacher.User &&
        Array.isArray(this.teacher.User.Phones) &&
        this.teacher.User.Phones.length
      )
        this.teacher.User.phone = this.teacher.User.Phones[0].number;
    },

    updatePhoto(id) {
      const bodyFormData = new FormData();

      bodyFormData.append("file", this.photo);

      this.savePhoto({ id, file: bodyFormData });
    },

    selectCycle(params) {
      this.cyclesSelected = params;

      this.childhoodCycles = this.cyclesSelected
        .filter((cy) => cy.id === 6 || cy.id === 8)
        .map((cy) => cy.id);

      this.subjectsToArticulate = this.subjectsToArticulate.filter((sa) =>
        this.cyclesSelected.some((cy) => cy.id === sa.cycle_id)
      );

      const newCycles = this.cyclesSelected.filter(
        (cycle) =>
          !Object.keys(this.subjectsByCycles).some((key) => key == cycle.id)
      );

      if (newCycles.length)
        newCycles.map(async (cycle) => {
          await this.getSubjects(cycle.id);

          this.fillCycles();
        });
      else this.fillCycles();
    },

    fillCycles() {
      this.cyclesFilleds = this.cyclesSelected.map(
        (cycle) => this.subjectsByCycles[cycle.id] || {}
      );

      this.subjectsSelected = this.subjectsSelected.filter((sub) =>
        this.cyclesSelected.some((cy) => cy.id == sub.cycle_id)
      );
    },

    submit() {
      if (
        this.teacher.User.name == "" ||
        this.teacher.User.email == "" ||
        this.teacher.User.password == "" ||
        this.teacher.User.cpf == ""
      ) {
        this.$swal.fire({
          title: "Alerta",
          text: "Preencha todos os campos!",
          icon: "error",
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        if (this.$route.params.id) {
          this.update();
        } else {
          this.create();
        }
      }
    },

    setNewPhoto(photo) {
      this.photo = photo;
    },

    selectCountry(country) {
      this.country = country;
    },

    selectState(state) {
      this.state = state;
    },

    setStudentTutors(students) {
      this.studentsToTutor = students;
    },

    setArticulador(subjectsToArticulate) {
      this.subjectsToArticulate = subjectsToArticulate;
    },

    successReq() {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "bottom-end",
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });

      Toast.fire({
        icon: "success",
        title: "Professor cadastrado com sucesso!",
      });

      let self = this;
      setTimeout(function () {
        self.redirectTo();
      }, 200);
    },

    redirectTo() {
      this.$router.push({
        name: "professores",
        params: {
          cycle: this.$route.params.cycle,
          subject: this.$route.params.subject,
          page: this.$route.params.page,
        },
      });
    },

    errorReq(error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.details &&
        error.response.data.details.length > 0
      ) {
        let errorHtml = "";
        error.response.data.details.forEach((err) => {
          errorHtml += `${err}<br>`;
        });

        this.$swal.fire({
          icon: "error",
          title: "Oops...",
          html: errorHtml,
          footer: "Por favor, verifique se todos os campos estão preenchidos.",
        });
      } else {
        this.$swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Ocorreu um erro ao cadastrar o professor.",
        });
      }
    },
  },

  watch: {
    subjectsSelected() {
      this.subjectsToArticulate = this.subjectsToArticulate.filter((sa) =>
        this.subjectsSelected.some((ss) => ss.id === sa.id)
      );
    },
  },

  created() {
    if (this.id) this.setTeacher();
    this.getCycles();
    this.getCountries();
    this.getStates();
  },

  destroyed() {
    this.resetStates();
  },
};
</script>

<style lang="scss">
.teacher-profile {
  .form-group {
    &.required {
      &:after {
        right: 0;
      }
    }
  }
}

.container-rda .multiselect__tag-icon:after {
  color: white;
}

input.isInvalid {
  border-color: red;
}
</style>
