import { render, staticRenderFns } from "./FormDataDeletion.vue?vue&type=template&id=dea7372a&scoped=true&"
import script from "./FormDataDeletion.vue?vue&type=script&lang=js&"
export * from "./FormDataDeletion.vue?vue&type=script&lang=js&"
import style0 from "./FormDataDeletion.vue?vue&type=style&index=0&id=dea7372a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dea7372a",
  null
  
)

export default component.exports