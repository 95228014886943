const LIST_TEACHERS = (state, { teachers }) => {
  state.teachers = teachers;
};

const LIST_STUDENTS = (state, { students }) => {
  state.students = students;
};

const LIST_CLASSROOMS = (state, { classrooms }) => {
  state.classrooms = classrooms;
};

const SET_SUBJECTS_BY_CYCLES = (state, { cycle }) => {
  state.subjectsByCycles[cycle.id] = cycle
};

const SET_TEACHER = (state, { teacher }) => {
  state.teacher = teacher
};

const RESET_STATE_COMMUNITY = (state) => {
  state.subjectsByCycles = {}
};

export default {
  LIST_TEACHERS,
  LIST_STUDENTS,
  LIST_CLASSROOMS,
  SET_SUBJECTS_BY_CYCLES,
  SET_TEACHER,
  RESET_STATE_COMMUNITY
}