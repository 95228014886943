<template>
  <div class="photo-picture text-center">
    <img
      v-if="typeof photo === 'string'"
      :src="photo"
      class="photoImg"
      alt="Foto"
    />

    <img
      v-else-if="!!photo && typeof photo === 'object'"
      src=""
      ref="photoImg"
      class="photoImg"
      alt="Foto"
    />

    <img
      v-else
      src="@/assets/images/user_placeholder.png"
      class="photoImg"
      alt="Foto"
    />

    <b-button
      v-if="!isEditing"
      class="xlarge mt-5"
      v-b-modal="`edit-photo-${modalId}`"
    >
      <UploadIcon />
    </b-button>

    <b-button v-else class="xlarge outline" v-b-modal="`edit-photo-${modalId}`"
      >Editar foto</b-button
    >

    <EditPhotoProfile :modalId="modalId" v-bind="$attrs" />
  </div>
</template>
<script>
import UploadIcon from "@/components/svg/UploadIcon.vue";
import EditPhotoProfile from "@/components/modals/EditPhotoProfile.vue";

export default {
  name: "ProfilePhoto",
  props: {
    isEditing: {
      type: Boolean,
      default: false,
    },
    modalId: {
      type: String,
      default: "1",
    },
  },

  components: {
    UploadIcon,
    EditPhotoProfile,
  },

  computed: {
    photo() {
      return this.$attrs.photo;
    },
  },

  watch: {
    "$attrs.photo"() {
      const photo = this.$attrs.photo;
      if (photo && typeof photo === "object") {
        var urlCreator = window.URL || window.webkitURL;
        var imageUrl = urlCreator.createObjectURL(photo);

        setTimeout(() => (this.$refs.photoImg.src = imageUrl), 200);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/variables.scss";

.photoImg {
  width: 100%;
}

.photo-picture {
  background-color: $grey6;
  max-width: 305px;
  padding: 40px 20px;

  .btn {
    svg {
      fill: $white;
    }
    &:hover {
      svg {
        fill: $grey4;
      }
    }
    &.outline {
      margin-top: 24px;
    }
  }
  .btn-rotate {
    margin-bottom: 26px;
    svg {
      margin: 20px 10px 0;
      &:last-child {
        transform: rotateY(180deg);
      }
    }
  }
}
</style>
