<template>
  <div class="home">
    <b-container class="container-rda sidebar">
      <b-row class="height">
        <sidebar-coordination />
        <b-col class="content">
          <b-row>
            <b-col>
              <b-breadcrumb :items="breadcrumb"></b-breadcrumb>
            </b-col>
          </b-row>
          <b-row class="mb-5">
            <b-col>
              <h2 class="purple bold">Mensalidade escolar</h2>
            </b-col>
            <b-col class="text-right">
              <b-button
                class="btn large blue"
                :to="{
                  name: 'mensalidade-escolar-registrar',
                  query: { calendar: selectedCalendar.id },
                }"
                >Registrar novos preços</b-button
              >
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <SelectSingle
                label="Selecionar calendário"
                :options="calendars"
                :callback="handleCalendar"
                :initial="selectedCalendar"
              />
              <b-row class="mt-5">
                <b-col>
                  <b-container fluid>
                    <b-row
                      class="header-list-teacher"
                      v-if="prices.length > 0 && isRequestSend"
                    >
                      <b-col md="3">
                        <p class="bold">Nome do ciclo</p>
                      </b-col>
                      <b-col md="3">
                        <p class="bold">Descrição do ciclo</p>
                      </b-col>
                      <b-col md="3">
                        <p class="bold">Valor da mensalidade</p>
                      </b-col>
                      <b-col md="2">
                        <p class="bold">Valor da anuidade</p>
                      </b-col>
                      <b-col md="1">
                        <p class="bold">Editar</p>
                      </b-col>
                    </b-row>
                    <b-row
                      align-v="center"
                      class="list-teacher"
                      v-if="!isRequestSend"
                    >
                      <b-col>Carregando</b-col>
                    </b-row>
                  </b-container>
                  <b-container v-if="prices.length > 0" fluid>
                    <b-row
                      align-v="center"
                      class="list-teacher"
                      v-for="price in prices"
                      v-bind:key="price.id"
                      :class="{ active: selectedIndex == price.id }"
                    >
                      <b-col md="3" class="year">
                        <p>{{ price.cycle_name }}</p>
                      </b-col>
                      <b-col md="3">
                        <p>{{ price.cycle_description }}</p>
                      </b-col>
                      <b-col md="3">
                        <p>{{ price.instalment_price }}</p> </b-col
                      ><b-col md="2">
                        <p>{{ price.annuity_price }}</p>
                      </b-col>
                      <b-col md="1">
                        <a
                          @click="() => setDate(price, price.id)"
                          v-b-modal.update-price
                        >
                          <EditIcon />
                        </a>
                      </b-col>
                    </b-row>
                  </b-container>
                  <b-row
                    align-v="center"
                    class="list-teacher"
                    v-if="prices.length <= 0"
                  >
                    <b-col
                      >Não há preços cadastrados para
                      {{ selectedCalendar.name }}</b-col
                    >
                  </b-row>
                </b-col>
                <div class="w-100 mb-5"></div>
              </b-row>
            </b-col>
          </b-row>
          <b-row class="payments">
            <b-col class="content">
              <h2 style="margin-bottom: 20px; color: #b23066;">Visão dos responsáveis</h2>
              <div class="box-payment">
                <div class="title">
                  <h2>
                    Valores referentes a 200 dias letivos para o ano de
                    {{ selectedCalendar.name ? selectedCalendar.name : null }}
                  </h2>
                </div>
                <div class="content-payment" style="align-items: center">
                  <b-row
                    v-if="prices.length > 0"
                    class="justify-content-md-center"
                  >
                    <b-col style="max-width: 100%;" md="2" :key="price.id" v-for="price in prices">
                      <h5 class="gradient1">{{ price.cycle_name }}</h5>
                      <span class="gradient1">{{
                        price.cycle_description
                      }}</span>
                      <p class="year">Anuidade</p>
                      <p>{{ price.annuity_price }}</p>
                      <hr />
                      <p class="installments">12 parcelas</p>
                      <p>{{ price.instalment_price }}</p>
                    </b-col>
                  </b-row>
                  <b-row v-else>
                    <p class="bold" style="text-align: center">
                      Valores não disponíveis no momento
                    </p>
                  </b-row>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
    <UpdatePrice :calendar="selectedDate" />
  </div>
</template>

<script>
import Moment from "moment";
import EditIcon from "@/components/svg/EditIcon.vue";
import UpdatePrice from "@/components/modals/UpdatePrice.vue";
import SidebarCoordination from "@/components/SidebarCoordination.vue";
import httpRda from "../../../http-rda";
import SelectSingle from "../../../components/inputs/SelectSingle.vue";

require("moment/locale/pt-br");
Moment.locale("pt-br");

export default {
  name: "mensalidade-escolar",
  components: {
    EditIcon,
    UpdatePrice,
    SidebarCoordination,
    SelectSingle,
  },
  data() {
    return {
      selectedDate: {},
      prices: [],
      descOrder: false,
      calendars: [],
      selectedCalendar: null,
      isRequestSend: false,
      selected: null,
      selectedIndex: -1,

      breadcrumb: [
        {
          text: "Secretaria",
          href: "/secretaria",
        },
        {
          text: "Alterações internas",
          active: true,
        },
        {
          text: "Mensalidade escolar",
          href: "/mensalidade-escolar",
          active: true,
        },
      ],
    };
  },
  methods: {
    setDate(date) {
      this.selectedDate = date;
    },

    async handleCalendar(calendar) {
      this.selectedCalendar = calendar;
      await this.getListPrices(this.selectedCalendar.id);
    },

    async getCalendars() {
      this.selectedCalendar = await httpRda
        .get("/config/calendars")
        .then((resp) => {
          return {
            id: resp.data.calendar.id,
            name: resp.data.calendar.designation,
          };
        });

      this.calendars = await httpRda
        .get("/calendars")
        .then((resp) => resp.data.calendars);

      this.calendars = this.calendars.map((calendar) => {
        return {
          id: calendar.id,
          name: calendar.designation,
        };
      });

      if (!this.selectedCalendar) {
        const currentYear = new Date().getFullYear();
        this.selectedCalendar = this.calendars.find((calendar) => {
          if (calendar.designation == currentYear) {
            return {
              id: calendar.id,
              name: calendar.designation,
            };
          }
        });
      }

      await this.getListPrices(this.selectedCalendar.id);
    },

    async getListPrices(calendarId) {
      const params = {};

      params.calendar_id = calendarId ? calendarId : null;

      await httpRda
        .get("/prices", { params })
        .then((response) => {
          this.prices = response.data.prices.values;
          this.isRequestSend = true;
        })
        .catch((error) => {
          return error;
        });
    },
  },

  created() {
    this.getCalendars();
  },

  computed: {},

  filters: {
    formatDate(value) {
      return Moment(value).format("DD/MM/YYYY");
    },
    formatDateYear(value) {
      return Moment(value).format("YYYY");
    },
    formatLanguage(value) {
      return Moment(value).format("dddd");
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/css/variables.scss";

.home {
  height: 100%;
}

.payments {
  margin-bottom: 45px;
  .box-payment {
    border-radius: 10px;
    background-image: linear-gradient(to right, $green1, $blue),
      radial-gradient(circle at top left, $green1, $blue);
    background-origin: border-box;
    background-clip: content-box, border-box;
    border: 1px solid transparent;
    margin-bottom: 45px;
    .title {
      color: $white;
      padding: 21px 15px;
      text-align: center;
      h2 {
        margin: 0;
      }
    }
    .content-payment {
      background: $grey6;
      border-radius: 0 0 9px 9px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      min-height: 300px;
      h5 {
        margin-bottom: 0;
      }
      span {
        display: block;
        font-size: 14px;
        margin-bottom: 15px;
      }
      .year,
      .installments {
        color: $blue;
        font-size: 14px;
        font-weight: $bold;
        margin: 0;
      }
      p {
        color: $grey4;
      }
      hr {
        border-color: $grey5;
      }
    }
  }
  .box-methods {
    border-radius: 10px;
    background-image: linear-gradient($white, $white),
      radial-gradient(circle at top left, $green1, $blue);
    background-origin: border-box;
    background-clip: content-box, border-box;
    border: 1px solid transparent;
    margin-bottom: 45px;
    .title {
      padding: 21px 15px;
      h2 {
        margin: 0;
      }
    }
    .content-payment {
      background: $gradient2;
      border-radius: 0 0 7px 7px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-height: 220px;
      h3 {
        color: $white;
        margin: 0;
        padding: 11px 0;
      }
    }
  }
}

.container-rda {
  padding-bottom: 0;

}

.list-teacher {
  &.active {
    border-color: transparent;
    background-image: linear-gradient($white, $white),
      radial-gradient(circle at top left, $orange, $purple);
    background-origin: border-box;
    background-clip: content-box, border-box;
    .year {
      font-weight: $bold;
    }
  }
}

.school-days {
  background-color: $purple;
  border-radius: 5px;
  color: $white;
  display: inline-block;
  line-height: 28px;
  margin: 0;
  max-width: 175px;
  text-align: center;
  width: 100%;
}

.holidays {
  p {
    margin: 0;
    line-height: 34px;
  }
}

.calendar {
  input,
  select {
    border-radius: 5px;
    border: 1px solid $grey5;
    padding: 12px 16px;
    width: 100%;
  }
  .vdp-datepicker__calendar {
    background-color: $grey6;
    border: 1px solid $grey5;
    border-radius: 5px;
    margin-top: 2px;
    width: 100%;
    .day__month_btn {
      font-size: 18px;
      font-weight: 600;
    }
    .cell {
      &.day-header {
        color: $grey3;
        font-size: 18px;
        font-weight: 300;
        margin-bottom: 10px;
      }
      &.day {
        color: $grey3;
        font-size: 18px;
        position: relative;
        &:hover {
          border: 1px solid $grey6 !important;
        }
        &.selected {
          background: transparent;
          color: $white;
          &:after {
            content: "";
            background: $gradient1;
            border-radius: 30px;
            display: block;
            font-weight: 700;
            height: 32px;
            left: 50%;
            margin-left: -16px;
            margin-top: -16px;
            position: absolute;
            top: 50%;
            width: 32px;
            z-index: -1;
          }
        }
      }
      &.month {
        border: 1px solid $grey6 !important;
        border-radius: 5px;
        &:hover {
          background: $gradient1;
          border-radius: 5px;
          color: $white;
          font-weight: 700;
        }
      }
      &.year {
        border: 1px solid $grey6 !important;
        border-radius: 5px;
        &:hover {
          background: $gradient1;
          border-radius: 5px;
          color: $white;
          font-weight: 700;
        }
      }
    }
  }
}
</style>
