<template>
  <b-form-group>
    <label for="label">{{ label }}</label>
    <multiselect
      v-model="option"
      :options="options"
      :multiple="false"
      :close-on-select="true"
      :clear-on-select="false"
      :preserve-search="true"
      :placeholder="placeholder"
      label="name"
      track-by="name"
      selectLabel=""
      selectedLabel=""
      deselectLabel=""
      @select="callback"
    >
      <template slot="tag" slot-scope="{ option }">
        <span class="custom__tag">
          <span>{{ option.name }}</span>
        </span>
      </template>
      <template slot="clear" slot-scope="props">
        <div
          class="multiselect__clear"
          @mousedown.prevent.stop="clearAll(props.search)"
        ></div>
      </template>
      <span slot="noResult">Nada encontrado</span>
    </multiselect>
  </b-form-group>
</template>

<script>
export default {
  data() {
    return {
      option: { value: 'true', name: 'Assinado' },
      options: [
        { value: 'true', name: 'Assinado' },
        { value: 'false', name: 'Não assinado' },
      ],
    }
  },
  props: [
    'name',
    'label',
    'placeholder',
    'callback',
  ]
}
</script>
