import axios from "axios";
import authHeader from "./auth-header";
import http from "../http-rda";

const API_URL = process.env.VUE_APP_URL;

class UserService {
  getPublicContent() {
    return axios.get(API_URL + "all");
  }

  getAll(params) {
    return http.get("/users/", { params });
  }

  get(id) {
    return http.get(`/users/${id}`);
  }

  createNewPassword(id, data) {
    return http.post(`/users/${id}/password`, data);
  }

  deleteuser(id) {
    return http.delete(`/users/${id}`);
  }

  update(id, data) {
    return http.patch(`/users/${id}`, data);
  }

  updateAddress(id, data) {
    return http.patch(`/users/${id}/addresses`, data);
  }

  getUserBoard() {
    return axios.get(API_URL + "users", { headers: authHeader() });
  }

  getModeratorBoard() {
    return axios.get(API_URL + "mod", { headers: authHeader() });
  }

  getAdminBoard() {
    return axios.get(API_URL + "admin", { headers: authHeader() });
  }
}

export default new UserService();
