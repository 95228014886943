<template>
  <div class="home">
    <b-container class="container-rda">
      <b-row>
        <b-col>
          <b-breadcrumb :items="breadcrumb"></b-breadcrumb>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <h2 class="purple bold">Roteiro</h2>
        </b-col>

        <b-col class="text-right" v-if="canEdit">
          <router-link
            class="large blue"
            :to="{
              name: 'editar-roteiro',
              params: {
                subject: this.$route.params.subject,
                id: id,
              },
            }"
            tag="b-button"
            >Editar roteiro</router-link
          >
        </b-col>
      </b-row>

      <b-row class="profile-row" v-if="!canEdit && !student_id">
        <b-col>
          <img
            v-if="!syllabus.Teacher"
            width="64"
            src="@/assets/images/default-photo.png"
            alt=""
          />
          <img
            v-else
            width="64"
            height="64"
            :src="syllabus.Teacher.User.photo"
            alt=""
          />
          <div class="content-meeting">
            <div class="column">
              <h5 v-if="syllabus.Teacher">
                Professor: {{ syllabus.Teacher.User.name }}
              </h5>
              <h5 v-else>Roteiro sem professor</h5>
              <p v-if="syllabus.subject">
                <strong>Disciplina:</strong> {{ syllabus.subject.join(", ") }}
              </p>
            </div>
            <div class="column two">
              <p>
                <strong>Tempo para conclusão:</strong> {{ syllabus.hours }}h
              </p>
              <p><strong>Data de entrega: </strong> {{ deadline }}</p>
            </div>
            <div class="column">
              <p>
                <strong>Status:</strong>
                {{ syllabus.SyllabusStudent.percentage }}%
              </p>
              <p
                class="pointer is_next"
                :class="{ active: syllabus.SyllabusStudent.is_next }"
                @click="updateSylalbusNext"
              >
                <span
                  ><img
                    src="@/assets/images/svg/check-select.svg"
                    alt="" /></span
                >Próximo roteiro para estudar
              </p>
            </div>
          </div>
        </b-col>
      </b-row>

      <b-row class="profile-row" v-else>
        <b-col>
          <img
            v-if="student.User.photo == null || student.User.photo == ''"
            src="@/assets/images/default-photo.png"
            height="64"
            width="64"
            alt=""
          />
          <img v-else width="64" height="64" :src="`${student.User.photo}`" alt="" />
          <div class="content-meeting">
            <div class="column">
              <h5>{{ student.User.name }}</h5>
              <p v-if="syllabus.subject">
                <strong>Disciplina:</strong> {{ syllabus.subject.join(", ") }}
              </p>
            </div>

            <div class="column two">
              <p>
                <strong>Competência: </strong>
                {{ competences }}
              </p>
              <p>
                <strong>Conteúdo:</strong>
                {{ topics }}
              </p>
            </div>

            <div class="column">
              <p>
                <strong>Tempo para conclusão:</strong> {{ syllabus.hours }}h
              </p>
              <p v-if="deadline">
                <strong>Data de entrega: </strong> {{ deadline }}
              </p>
            </div>
          </div>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <div class="meeting-box">
            <div class="content-meeting">
              <h5 class="purple">Competência:</h5>
              <p>{{ competences }}</p>

              <h5 class="purple mt-4">Conteúdo:</h5>
              <p>{{ topics }}</p>

              <h5 class="purple mt-4">Palavras-Chave</h5>
              <p>{{ tags }}</p>

              <h5 class="purple mt-4">Tema:</h5>
              <p>{{ syllabus.theme }}</p>

              <h5 class="purple mt-4">Atividade:</h5>
              <p>{{ syllabus.name }}</p>

              <h5 class="purple mt-4">Introdução:</h5>
              <p>{{ syllabus.description }}</p>
            </div>
          </div>
        </b-col>
      </b-row>

      <b-row align-v="center" class="mt-4" v-if="syllabus.file">
        <b-col md="3" class="text-right">
          <b-button
            :href="syllabus.file"
            download
            target="_blank"
            class="large blue"
            >Baixar roteiro</b-button
          >
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  name: "StudentScript",
  data() {
    return {
      breadcrumb: [
        {
          text: "Aprendizagem",
          href: "/aprendizagem",
          active: true,
        },
        {
          text: "Roteiros",
          href: `/aprendizagem/roteiros-aluno/${this.$route.params.student_id}`,
        },
        {
          text: "Roteiro",
          active: true,
        },
      ],
    };
  },

  computed: {
    ...mapGetters({
      syllabus: "learning/syllabus",
      syllabusTopics: "learning/syllabusTopics",
      user: "auth/getAuthData",
      student: "learning/student",
    }),

    competences() {
      if (!this.syllabusTopics.length) return "-";
      const allCompetences = this.syllabusTopics
        .map((t) => t.Competence.description)
        .join(", ");
      return this.cutText(allCompetences);
    },

    topics() {
      if (!this.syllabusTopics.length) return "-";
      const allTopics = this.syllabusTopics
        .map((t) => t.description)
        .join(", ");
      return this.cutText(allTopics);
    },

    tags() {
      if (!this.syllabus.Tags || !this.syllabus.Tags.length) return "-";
      return this.syllabus.Tags.join(", ");
    },

    id() {
      return this.$route.params.id;
    },

    student_id() {
      return this.$route.params.student_id;
    },

    canEdit() {
      if (!this.user.role) return false;
      return ["admin", "coordinator", "teacher"].includes(this.user.role[0]);
    },

    deadline() {
      const deadline = this.syllabus.SyllabusStudent.deadline;
      return new Intl.DateTimeFormat("pt-BR", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      }).format(new Date(deadline.replaceAll("-", "/")));
    },
  },

  methods: {
    ...mapActions({
      loadSyllabusDetail: "learning/loadSyllabus",
      getSyllabus: "learning/getStudentSyllabusById",
      getStudent: "learning/getStudent",
      updateSyllabus: "learning/updateSyllabusStudent",
    }),

    ...mapMutations({
      clearState: "learning/CLEAR_SYLLABUS_TOPICS",
    }),

    async updateSylalbusNext() {
      const is_next = !this.syllabus.SyllabusStudent.is_next;
      await this.updateSyllabus({
        student_id: 524,
        syllabus_id: this.id,
        is_next,
      });
      await this.getSyllabus({ student_id: 524, syllabus_id: this.id });
    },

    cutText(text) {
      const textArr = text.trim().split(" ");
      if (textArr.length < 6) return textArr.join(" ");
      return textArr.slice(0, 6).join(" ") + '...'
    },
  },

  async created() {
    if (this.student_id) this.getStudent(this.student_id);

    await this.loadSyllabusDetail(this.id);
    await this.getSyllabus({ student_id: this.student.id, syllabus_id: this.id });
  },

  destroyed() {
    this.clearState();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/variables.scss";

.profile-row {
  background: $gradient1;
  border-radius: 10px;
  color: $white;
  margin: 30px 0 60px;
  padding: 28px 10px;
  position: relative;
  &:after {
    content: url("~@/assets/images/watermark-profile.png");
    right: 0;
    pointer-events: none;
    position: absolute;
    top: -5px;
  }
  .col {
    display: flex;
    align-items: center;
    img {
      margin-right: 40px;
    }
    .content-meeting {
      display: flex;
      width: 100%;
      .column {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 60px;
        min-width: 200px;
        margin-right: 40px;
      }
      h5 {
        margin: 0;
      }
      p {
        margin: 0;
      }
    }
  }
}

.meeting-box {
  background-color: rgba($blue, 0.2);
  border-radius: 10px;
  margin-bottom: 40px;
  margin-top: 30px;
  padding: 60px 30px 40px;

  .content-meeting {
    max-width: 1180px;
    margin: 0 auto;
    h3 {
      margin: 0 0 10px;
    }
    .discipline {
      color: $grey4;
      margin: 0;
    }
    .date-time {
      color: $grey5;
      display: block;
      font-size: 16px;
      margin: 0 0 20px;
    }
    .text {
      max-width: 1025px;
      margin: 0 0 15px;
    }
    .reply {
      color: $purple;
      font-size: 16px;
    }
  }
}

.is_next {
  &.active {
    span {
      background-color: white;
    }
    img {
      opacity: 1;
      margin: 0;
      display: inline;
    }
  }

  img {
    opacity: 0;
  }

  span {
    display: inline-block;
    margin-right: 10px;
    width: 22px;
    height: 22px;
    border: 1px solid white;
    border-radius: 5px;
    margin-bottom: -4px;
    text-align: center;
  }
}
</style>