<template>
  <div class="home">
    <b-container class="container-rda sidebar">
      <b-row class="height">
        <sidebar-coordination />
        <b-col class="content">
          <b-row>
            <b-col>
              <b-breadcrumb :items="breadcrumb"></b-breadcrumb>
            </b-col>
          </b-row>
          <b-row class="mb-5">
            <b-col>
              <h2 class="purple bold">Calendário escolar</h2>
            </b-col>
            <b-col class="text-right">
              <p v-if="selected">
                <span class="school-days">{{ countDays() }} dias letivos</span>
              </p>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-row>
                <b-col>
                  <b-form-group class="calendar">
                    <label>Data de início</label>
                    <Datepicker
                      v-model="calendars.start_date"
                      placeholder="Selecione"
                      format="dd/MM/yyyy"
                      :use-utc="true"
                      :language="languages[language]"
                    ></Datepicker>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group class="calendar">
                    <label>Data de término</label>
                    <Datepicker
                      v-model="calendars.end_date"
                      placeholder="Selecione"
                      format="dd/MM/yyyy"
                      :use-utc="true"
                      :language="languages[language]"
                    ></Datepicker>
                  </b-form-group>
                </b-col>
                <div class="w-100"></div>
                <b-col class="text-right">
                  <b-button class="medium" @click="create"
                    >Adicionar Ano Letivo</b-button
                  >
                </b-col>
              </b-row>
              <b-row class="mt-5">
                <b-col>
                  <b-container fluid>
                    <b-row>
                      <b-col>
                        <div class="order-grid-box">
                          <p
                            class="order-list"
                            :class="{ desc: descOrder }"
                            v-on:click="orderName"
                          >
                            Ordenar
                            <OrderNameIcon />
                          </p>
                        </div>
                      </b-col>
                    </b-row>
                    <b-row
                      class="header-list-teacher"
                      v-if="calendars.length > 0 && isRequestSend"
                    >
                      <b-col md="2">
                        <p class="bold">Ano letivo</p>
                      </b-col>
                      <b-col>
                        <p class="bold">Data de início</p>
                      </b-col>
                      <b-col>
                        <p class="bold">Data de término</p>
                      </b-col>
                      <b-col md="2">
                        <p class="bold">Editar</p>
                      </b-col>
                      <b-col md="2">
                        <p class="bold">Apagar</p>
                      </b-col>
                      <b-col md="2">
                        <p class="bold">Ativo</p>
                      </b-col>
                    </b-row>
                    <b-row
                      align-v="center"
                      class="list-teacher"
                      v-if="!isRequestSend"
                    >
                      <b-col>Carregando</b-col>
                    </b-row>
                  </b-container>
                  <b-container fluid>
                    <b-row
                      align-v="center"
                      class="list-teacher"
                      v-for="calendar in orderbyName"
                      v-bind:key="calendar.id"
                      @click="setSelected(calendar, calendar.id)"
                      :class="{ active: selectedIndex == calendar.id }"
                      style="cursor: pointer"
                    >
                      <b-col md="2" class="year">
                        <p>{{ calendar.designation | formatDateYear }}</p>
                      </b-col>
                      <b-col>
                        <p>{{ calendar.start_date | formatDate }}</p>
                      </b-col>
                      <b-col>
                        <p>{{ calendar.end_date | formatDate }}</p>
                      </b-col>
                      <b-col md="2">
                        <a
                          @click="() => setDate(calendar, calendar.id)"
                          v-b-modal.update-calendar
                        >
                          <EditIcon />
                        </a>
                      </b-col>
                      <b-col md="2">
                        <a
                          v-if="!calendar.current"
                          v-on:click.prevent="exclude(calendar.id)"
                        >
                          <DeleteIcon />
                        </a>
                      </b-col>
                      <b-col md="2">
                        <p>{{ calendar.current ? "✅" : "❌" }}</p>
                      </b-col>
                    </b-row>
                  </b-container>
                </b-col>
                <div class="w-100 mb-5"></div>
              </b-row>
            </b-col>
            <b-col md="4">
              <div class="holidays mb-4" v-if="selected">
                <h5 class="mb-4">
                  {{ selected.designation | formatDateYear }} - Feriados
                  nacionais
                </h5>
                <p
                  v-for="holiday in calendars.find((c) => c.id == selected.id)
                    .Holidays"
                  v-bind:key="holiday.id"
                >
                  <span>
                    <strong>{{ holiday.date | formatDate }}</strong>
                    ({{ holiday.date | formatLanguage }}): {{ holiday.name }}
                  </span>
                </p>
                <p
                  v-if="
                    calendars.find((c) => c.id == selected.id).Holidays
                      .length == 0
                  "
                >
                  Não há feriados cadastrados
                </p>
              </div>
              <b-button class="full" v-b-modal.holidays
                >Adicionar novo feriado</b-button
              >
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
    <HolidaysModal />
    <UpdateCalendar :calendar="selectedDate" />
  </div>
</template>

<script>
import DataService from "@/services/calendars.service";

import Moment from "moment";
import business from "moment-business";
import Datepicker from "vuejs-datepicker";
import * as lang from "vuejs-datepicker/src/locale";
import DeleteIcon from "@/components/svg/DeleteIcon.vue";
import EditIcon from "@/components/svg/EditIcon.vue";
import OrderNameIcon from "@/components/svg/OrderNameIcon.vue";
import HolidaysModal from "@/components/modals/HolidaysModal.vue";
import UpdateCalendar from "@/components/modals/UpdateCalendar.vue";
import SidebarCoordination from "@/components/SidebarCoordination.vue";
import httpRda from "../../../http-rda";

require("moment/locale/pt-br");
Moment.locale("pt-br");

export default {
  name: "calendario-escolar",
  components: {
    Datepicker,
    DeleteIcon,
    EditIcon,
    OrderNameIcon,
    HolidaysModal,
    UpdateCalendar,
    SidebarCoordination,
  },
  data() {
    return {
      selectedDate: {},
      calendars: [],
      descOrder: false,
      currentCalendar: null,
      isRequestSend: false,
      selected: null,
      selectedIndex: -1,

      breadcrumb: [
        {
          text: "Secretaria",
          href: "/secretaria",
        },
        {
          text: "Alterações internas",
          active: true,
        },
        {
          text: "Calendário",
          href: "/calendario",
          active: true,
        },
      ],
      language: "ptBR",
      languages: lang,
    };
  },
  methods: {
    setDate(date) {
      this.selectedDate = date;
    },
    async getListCalendar() {
      this.currentCalendar = await httpRda
        .get("/config/calendars")
        .then((resp) => resp.data.calendar);
      DataService.getAll()
        .then((response) => {
          this.calendars = response.data.calendars.map((calendar) => {
            return {
              ...calendar,
              current:
                calendar.designation == this.currentCalendar.designation
                  ? true
                  : false,
            };
          });
          this.isRequestSend = true;

          const getCalendar = this.calendars.find(
            (c) => c.designation == this.currentCalendar.designation
          );
          this.setSelected(getCalendar);
        })
        .catch((error) => {
          return error;
        });
    },
    create() {
      var data = {
        designation: Moment(this.calendars.start_date).format("YYYY"),
        start_date: this.calendars.start_date,
        end_date: this.calendars.end_date,
      };
      DataService.create(data)
        .then((response) => {
          this.calendars.id = response.data.id;
          this.getListCalendar();
          const Toast = this.$swal.mixin({
            toast: true,
            position: "bottom-end",
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer);
              toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            },
          });
          Toast.fire({
            icon: "success",
            title: "Ano letivo cadastrado com sucesso!",
          });
        })
        .catch((error) => {
          this.$swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Não foi possivel cadastrar!",
            footer:
              "Por favor, verifique se todos os campos estão preenchidos.",
          });

          return error.response;
        });
    },

    exclude(id) {
      this.$swal
        .fire({
          title: "Tem certeza?",
          text: "Você não poderá reverter isso!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#68c5b7",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim, excluir!",
          cancelButtonText: "Cancelar",
        })
        .then((result) => {
          if (result.isConfirmed) {
            DataService.delete(id);
            this.$swal
              .fire("Excluído!", "Ano Letivo foi excluído.", "success")
              .then((response) => {
                this.getListCalendar();

                return response.data;
              });
          }
        })
        .catch((error) => {
          this.$swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Não foi possivel excluir!",
            footer: "Por favor, tente novamente mais tarde.",
          });

          return error.response;
        });
    },

    setSelected(calendar) {
      this.selected = calendar;
      this.selectedIndex = calendar.id;
    },

    countDays() {
      var startDate = Moment(this.selected.start_date, "YYYY/MM/DD");
      var endDate = Moment(this.selected.end_date, "YYYY/MM/DD");
      var onlyWorkingDays = business.weekDays(startDate, endDate);
      var hasHolidaysInWeekends = this.selected.Holidays.filter(
        (h) => h.day != "Sunday" && h.day != "Saturday"
      ).length;

      return onlyWorkingDays - hasHolidaysInWeekends;
    },

    orderName() {
      this.descOrder = !this.descOrder;
    },
  },

  created() {
    this.getListCalendar();
  },

  computed: {
    orderbyName() {
      return [...this.calendars].sort((a, b) => {
        const order = this.descOrder ? [1, -1] : [-1, 1];

        if (a.designation < b.designation) return order[0];
        else if (a.designation > b.designation) return order[1];

        return 0;
      });
    },
  },

  filters: {
    formatDate(value) {
      return Moment(value).format("DD/MM/YYYY");
    },
    formatDateYear(value) {
      return Moment(value).format("YYYY");
    },
    formatLanguage(value) {
      return Moment(value).format("dddd");
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/css/variables.scss";

.home {
  height: 100%;
}

.container-rda {
  padding-bottom: 0;
}

.list-teacher {
  &.active {
    border-color: transparent;
    background-image: linear-gradient($white, $white),
      radial-gradient(circle at top left, $orange, $purple);
    background-origin: border-box;
    background-clip: content-box, border-box;
    .year {
      font-weight: $bold;
    }
  }
}

.school-days {
  background-color: $purple;
  border-radius: 5px;
  color: $white;
  display: inline-block;
  line-height: 28px;
  margin: 0;
  max-width: 175px;
  text-align: center;
  width: 100%;
}

.holidays {
  p {
    margin: 0;
    line-height: 34px;
  }
}

.calendar {
  input,
  select {
    border-radius: 5px;
    border: 1px solid $grey5;
    padding: 12px 16px;
    width: 100%;
  }
  .vdp-datepicker__calendar {
    background-color: $grey6;
    border: 1px solid $grey5;
    border-radius: 5px;
    margin-top: 2px;
    width: 100%;
    .day__month_btn {
      font-size: 18px;
      font-weight: 600;
    }
    .cell {
      &.day-header {
        color: $grey3;
        font-size: 18px;
        font-weight: 300;
        margin-bottom: 10px;
      }
      &.day {
        color: $grey3;
        font-size: 18px;
        position: relative;
        &:hover {
          border: 1px solid $grey6 !important;
        }
        &.selected {
          background: transparent;
          color: $white;
          &:after {
            content: "";
            background: $gradient1;
            border-radius: 30px;
            display: block;
            font-weight: 700;
            height: 32px;
            left: 50%;
            margin-left: -16px;
            margin-top: -16px;
            position: absolute;
            top: 50%;
            width: 32px;
            z-index: -1;
          }
        }
      }
      &.month {
        border: 1px solid $grey6 !important;
        border-radius: 5px;
        &:hover {
          background: $gradient1;
          border-radius: 5px;
          color: $white;
          font-weight: 700;
        }
      }
      &.year {
        border: 1px solid $grey6 !important;
        border-radius: 5px;
        &:hover {
          background: $gradient1;
          border-radius: 5px;
          color: $white;
          font-weight: 700;
        }
      }
    }
  }
}
</style>
