<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.45312 8.16016C7.66406 8.16016 7.875 7.94922 7.875 7.73828V6.05078C7.875 5.80469 7.66406 5.62891 7.45312 5.62891H4.00781C5.13281 4.08203 6.92578 3.0625 9 3.0625C12.4102 3.0625 15.1875 5.83984 15.1875 9.25C15.1875 12.6953 12.4102 15.4375 9 15.4375C7.41797 15.4375 5.97656 14.875 4.88672 13.8906C4.71094 13.75 4.46484 13.75 4.32422 13.9258L3.12891 15.1211C2.95312 15.2969 2.95312 15.5781 3.12891 15.7188C4.67578 17.125 6.75 17.9688 9 17.9688C13.8164 17.9688 17.7188 14.1016 17.7188 9.28516C17.7188 4.46875 13.8867 0.601562 9.07031 0.566406C6.46875 0.53125 4.11328 1.65625 2.53125 3.44922V0.671875C2.53125 0.460938 2.32031 0.25 2.10938 0.25H0.421875C0.175781 0.25 0 0.460938 0 0.671875V7.73828C0 7.94922 0.175781 8.16016 0.421875 8.16016H7.45312Z" fill="url(#paint0_linear)"/>
    <defs>
    <linearGradient id="paint0_linear" x1="-5.49" y1="8.88119" x2="27.9" y2="8.88119" gradientUnits="userSpaceOnUse">
    <stop stop-color="#EF6549"/>
    <stop offset="1" stop-color="#B23066"/>
    </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  
}
</script>

<style lang="sass">

</style>