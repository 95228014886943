<template>
  <svg
    width="23"
    height="23"
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.59986 6.90005C5.44653 6.90005 6.16917 6.60068 6.76799 6.00169C7.36702 5.40274 7.66656 4.67989 7.66656 3.83339C7.66656 2.98698 7.36702 2.26413 6.76799 1.6651C6.16917 1.06615 5.44649 0.766609 4.59986 0.766609C3.75328 0.766609 3.0306 1.06615 2.43165 1.6651C1.83274 2.26413 1.5332 2.98681 1.5332 3.83339C1.5332 4.68006 1.83274 5.40274 2.43165 6.00169C3.0306 6.60051 3.75328 6.90005 4.59986 6.90005Z"
      fill="url(#paint0_linear)"
    />
    <path
      d="M8.2471 11.6861C9.14554 12.5842 10.2295 13.0335 11.4995 13.0335C12.7695 13.0335 13.8535 12.5842 14.7519 11.6861C15.6501 10.7873 16.0994 9.70321 16.0994 8.43348C16.0994 7.16367 15.6501 6.0796 14.7519 5.18112C13.8535 4.28263 12.7695 3.83339 11.4995 3.83339C10.2295 3.83339 9.14546 4.28272 8.2471 5.18112C7.34874 6.07948 6.89941 7.16354 6.89941 8.43348C6.89941 9.70342 7.34874 10.7874 8.2471 11.6861Z"
      fill="url(#paint1_linear)"
    />
    <path
      d="M19.0667 7.13344C19.913 7.13344 20.6358 6.83407 21.2347 6.23508C21.8337 5.63613 22.133 4.91328 22.133 4.06678C22.133 3.22037 21.8337 2.49752 21.2347 1.89849C20.6359 1.29954 19.913 1 19.0667 1C18.2199 1 17.4972 1.29954 16.8983 1.89849C16.2995 2.49752 16 3.2202 16 4.06678C16 4.91345 16.2995 5.63613 16.8983 6.23508C17.4972 6.83391 18.2199 7.13344 19.0667 7.13344Z"
      fill="url(#paint2_linear)"
    />
    <path
      d="M21.5148 6.90022C21.4671 6.90022 21.2934 6.98409 20.9939 7.15179C20.6946 7.31958 20.3051 7.4893 19.8261 7.66104C19.3471 7.8326 18.8716 7.91849 18.4007 7.91849C17.8654 7.91849 17.3347 7.82673 16.8074 7.64295C16.847 7.9383 16.8671 8.20192 16.8671 8.43353C16.8671 9.54361 16.5436 10.5658 15.8965 11.5002C17.1905 11.5403 18.2485 12.0513 19.0714 13.0338H20.6765C21.3312 13.0338 21.8823 12.8717 22.3295 12.5484C22.7766 12.2252 23.0003 11.7518 23.0003 11.1289C23.0005 8.30962 22.5055 6.90005 21.5148 6.90022Z"
      fill="url(#paint3_linear)"
    />
    <path
      d="M19.7239 16.5853C19.6401 16.118 19.5341 15.6847 19.4063 15.2855C19.2785 14.886 19.1068 14.4969 18.8914 14.1176C18.6757 13.7385 18.4281 13.4148 18.1485 13.1472C17.8688 12.8795 17.5274 12.6662 17.1242 12.5062C16.721 12.3466 16.2756 12.2668 15.7885 12.2668C15.7086 12.2668 15.537 12.3526 15.2734 12.5244C15.0098 12.6959 14.7185 12.8879 14.3989 13.0993C14.0794 13.3106 13.6525 13.5025 13.1171 13.6742C12.5817 13.8459 12.0427 13.9318 11.4999 13.9318C10.9569 13.9318 10.4178 13.8459 9.88267 13.6742C9.34746 13.5025 8.92032 13.3106 8.60086 13.0993C8.28131 12.8879 7.98991 12.6959 7.72641 12.5244C7.46279 12.3526 7.29119 12.2668 7.21121 12.2668C6.72412 12.2668 6.27891 12.3466 5.87557 12.5062C5.47223 12.6662 5.13086 12.8795 4.85138 13.1472C4.57164 13.4146 4.3243 13.7385 4.10851 14.1176C3.89298 14.4969 3.72128 14.886 3.59348 15.2855C3.46572 15.6847 3.36003 16.118 3.27603 16.5853C3.19211 17.0525 3.13627 17.4876 3.10832 17.891C3.08034 18.2944 3.06641 18.7078 3.06641 19.1311C3.06641 20.0892 3.3578 20.846 3.94077 21.4009C4.52394 21.9557 5.29865 22.2334 6.26477 22.2334H16.7347C17.7009 22.2334 18.4756 21.9557 19.0589 21.4009C19.6418 20.846 19.9333 20.0892 19.9333 19.1311C19.9333 18.7078 19.9192 18.2945 19.8917 17.891C19.8633 17.4876 19.8075 17.0525 19.7239 16.5853Z"
      fill="url(#paint4_linear)"
    />
    <path
      d="M7.10359 11.5001C6.45669 10.5657 6.13332 9.54356 6.13344 8.43348C6.13344 8.20188 6.15346 7.93842 6.19332 7.64291C5.66616 7.82656 5.1351 7.91845 4.60001 7.91845C4.12882 7.91845 3.65377 7.83261 3.17453 7.661C2.6955 7.48927 2.30605 7.31955 2.00668 7.15176C1.70718 6.98405 1.53335 6.90018 1.48548 6.90018C0.495145 6.90022 0 8.30979 0 11.1289C0 11.7517 0.223635 12.2251 0.67078 12.5483C1.11805 12.8717 1.66913 13.0335 2.32379 13.0335H3.9291C4.75177 12.0512 5.80978 11.5402 7.10359 11.5001Z"
      fill="url(#paint5_linear)"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="-0.337472"
        y1="3.80297"
        x2="11.0399"
        y2="3.80297"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#EF6549" />
        <stop offset="1" stop-color="#B23066" />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="4.09341"
        y1="8.38789"
        x2="21.1594"
        y2="8.38789"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#EF6549" />
        <stop offset="1" stop-color="#B23066" />
      </linearGradient>
      <linearGradient
        id="paint2_linear"
        x1="14.1294"
        y1="4.03636"
        x2="25.5062"
        y2="4.03636"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#EF6549" />
        <stop offset="1" stop-color="#B23066" />
      </linearGradient>
      <linearGradient
        id="paint3_linear"
        x1="13.7298"
        y1="9.93666"
        x2="26.9074"
        y2="9.93666"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#EF6549" />
        <stop offset="1" stop-color="#B23066" />
      </linearGradient>
      <linearGradient
        id="paint4_linear"
        x1="-2.078"
        y1="17.2007"
        x2="29.2101"
        y2="17.2008"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#EF6549" />
        <stop offset="1" stop-color="#B23066" />
      </linearGradient>
      <linearGradient
        id="paint5_linear"
        x1="-2.1666"
        y1="9.93647"
        x2="11.0106"
        y2="9.93647"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#EF6549" />
        <stop offset="1" stop-color="#B23066" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {

}
</script>

<style lang="sass">
</style>