<template>
  <div class="home">
    <template>
      <b-container class="container-rda sidebar">
        <b-row class="height">
          <sidebar-coordination />
          <b-col class="content">
            <b-row>
              <b-col>
                <b-breadcrumb :items="breadcrumb"></b-breadcrumb>
              </b-col>
            </b-row>
            <b-row class="mb-5">
              <b-col>
                <h2 class="purple bold">Gerenciamento de ciclos</h2>
              </b-col>
              <b-col class="text-right" v-if="false">
                <b-button class="btn large blue" :to="{ name: 'novo-ciclo' }"
                  >Registrar novo ciclo</b-button
                >
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group label="Buscar">
                  <b-input-group-prepend>
                    <b-form-input
                      type="search"
                      placeholder="Digite aqui"
                      v-model="searchTitle"
                      v-on:keyup.enter="getList()"
                    >
                    </b-form-input>
                    <b-input-group-text
                      class="clear-fields"
                      v-if="searchTitle != ''"
                      v-b-tooltip.hover
                      title="Limpar Pesquisa"
                    >
                      <b-icon icon="x" @click="clearFields" />
                    </b-input-group-text>
                  </b-input-group-prepend>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <div class="order-grid-box">
                  <p
                    class="order-list"
                    :class="{ desc: descOrder }"
                    v-on:click="orderName"
                  >
                    Ordenar
                    <OrderNameIcon />
                  </p>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-container fluid>
                  <b-row
                    class="header-list-teacher"
                    v-if="cycles.length > 0 && isRequestSend"
                  >
                    <b-col md="8">
                      <p class="bold">Ciclos</p>
                    </b-col>
                    <b-col>
                      <p class="bold">Ativo</p>
                    </b-col>
                    <b-col>
                      <p class="bold">Editar</p>
                    </b-col>
                    <b-col v-if="false">
                      <p class="bold">Apagar</p>
                    </b-col>
                  </b-row>
                </b-container>
                <b-container fluid>
                  <b-row
                    align-v="center"
                    class="list-teacher"
                    v-for="cycle in orderbyName"
                    v-bind:key="cycle.id"
                  >
                    <b-col md="8">
                      <p>{{ cycle.designation }}</p>
                    </b-col>
                    <b-col class="status">
                      <b-form-checkbox
                        switch
                        v-if="cycle.is_editable"
                        v-model="cycle.is_editable"
                        @input="updateStatus(cycle.id, false)"
                      ></b-form-checkbox>
                      <b-form-checkbox
                        switch
                        v-else
                        v-model="cycle.is_editable"
                        @input="updateStatus(cycle.id, true)"
                      ></b-form-checkbox>
                    </b-col>
                    <b-col>
                      <router-link
                        :to="{ name: 'perfil-ciclo', params: { id: cycle.id } }"
                      >
                        <EditIcon />
                      </router-link>
                    </b-col>
                    <b-col v-if="false">
                      <a v-on:click.prevent="exclude(cycle.id)">
                        <DeleteIcon />
                      </a>
                    </b-col>
                  </b-row>
                  <b-row
                    align-v="center"
                    class="list-teacher"
                    v-if="cycles.length <= 0 && isRequestSend"
                  >
                    <b-col> Nenhum resultado encontrado </b-col>
                  </b-row>
                  <b-row
                    align-v="center"
                    class="list-teacher"
                    v-if="!isRequestSend"
                  >
                    <b-col> Carregando </b-col>
                  </b-row>
                </b-container>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </template>
  </div>
</template>

<script>
import DataService from "@/services/cycles.service";

import OrderNameIcon from "@/components/svg/OrderNameIcon.vue";
import EditIcon from "@/components/svg/EditIcon.vue";
import DeleteIcon from "@/components/svg/DeleteIcon.vue";
import SidebarCoordination from "@/components/SidebarCoordination.vue";

export default {
  name: "perfil-ciclo",
  components: {
    OrderNameIcon,
    EditIcon,
    DeleteIcon,
    SidebarCoordination,
  },
  data() {
    return {
      cycles: [],
      descOrder: false,
      isRequestSend: false,

      searchTitle: "",

      breadcrumb: [
        {
          text: "Secretaria",
          href: "/secretaria",
        },
        {
          text: "Alterações internas",
          active: true,
        },
        {
          text: "Ciclos",
          active: true,
        },
      ],
      selected: null,
    };
  },
  methods: {
    getRequestParams(searchTitle) {
      let params = {};

      if (searchTitle) {
        params["search"] = searchTitle;
      }

      return params;
    },

    getList() {
      const params = this.getRequestParams(this.searchTitle);

      DataService.getAll(params)
        .then((response) => {
          this.cycles = response.data.cycles
          this.isRequestSend = true
        }).catch((error) => {
          return error
        })
    },

    get(id) {
      DataService.get(id)
        .then((response) => {
          this.cycles = response.data
        }).catch((error) => {
          return error
        })
    },

    exclude(id) {
      this.$swal
        .fire({
          title: "Tem certeza?",
          text: "Você não poderá reverter isso!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#68c5b7",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim, excluir!",
          cancelButtonText: "Cancelar",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            try {
              await DataService.delete(id);
              this.$swal
                .fire("Excluído!", "Ciclo foi excluído.", "success")
                .then((response) => {
                  this.getList()
                  return response.data
                });
            } catch (error) {
              this.$swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Não foi possivel excluir!",
                footer: "Por favor, tente novamente mais tarde.",
              })

              return error.response
            }
          }
        }).catch((error) => {
          this.$swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Não foi possivel excluir!",
            footer: "Por favor, tente novamente mais tarde.",
          })

          return error.response
        });
    },

    orderName() {
      this.descOrder = !this.descOrder;
    },

    clearFields(searchTitle) {
      this.searchTitle = "";
      this.getList({ search: searchTitle });
    },

    updateStatus(id, status) {
      var data = {
        id: id,
        is_editable: status,
      };
      DataService.update(id, data)
        .then((response) => {
          const Toast = this.$swal.mixin({
            toast: true,
            position: "bottom-end",
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer);
              toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            },
          })
          Toast.fire({
            icon: "success",
            title: `Status atualizado com sucesso!`,
          })

          return response.data
        }).catch((error) => {
          this.$swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Não foi possivel atualizar!",
            footer: "Por favor, tente novamente mais tarde.",
          })

          return error.response
        })
    },
  },
  mounted() {
    this.getList();
  },
  computed: {
    orderbyName() {
      return [...this.cycles].sort((a, b) => {
        const order = this.descOrder ? [1, -1] : [-1, 1];

        if (a.designation < b.designation) return order[0];
        else if (a.designation > b.designation) return order[1];

        return 0;
      })
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/variables.scss";

.home {
  height: 100%;
}

.container-rda {
  padding-bottom: 0;
}

.tags {
  background-color: $blue;
  border-radius: 5px;
  color: $white;
  font-weight: $bold;
  line-height: 28px;
  margin: 3px 12px 3px 0;
  max-width: 58px;
  padding: 0 3px;
  text-align: center;
  vertical-align: middle;
}
</style>
