<template>
  <div class="home">
    <template>
      <b-container class="container-rda sidebar">
        <b-row class="height">
          <sidebar-coordination />
          <b-col class="content">
            <b-row>
              <b-col>
                <b-breadcrumb :items="breadcrumb"></b-breadcrumb>
              </b-col>
            </b-row>
            <b-row class="mb-5">
              <b-col>
                <h2 class="purple bold">Nova Disciplina</h2>
              </b-col>
              <b-col class="text-right"> </b-col>
            </b-row>
            <b-row class="mb-5 pb-5">
              <b-col md="1" class="status">
                <b-form-group label="Ativo">
                  <b-form-checkbox
                    v-model="subjects.is_active"
                    switch
                  ></b-form-checkbox>
                </b-form-group>
              </b-col>
              <b-col md="1" class="status" v-if="false">
                <b-form-group label="Editável">
                  <b-form-checkbox
                    v-model="subjects.is_editable"
                    switch
                  ></b-form-checkbox>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group label="Disciplina">
                  <multiselect
                    v-model="subjects.designation"
                    :options="options"
                    :multiple="false"
                    :close-on-select="true"
                    :clear-on-select="false"
                    :preserve-search="true"
                    placeholder="Selecione"
                    selectLabel=""
                    selectedLabel=""
                    deselectLabel=""
                  >
                    <span slot="noResult">Nada encontrado</span>
                  </multiselect>
                </b-form-group>
              </b-col>
              <b-col md="3" class="hide-last">
                <SelectCycles
                  v-model="subjects.cycle_id"
                  :callback="callbackCycles"
                  placeholder="Selecione"
                >
                </SelectCycles>
              </b-col>
              <div class="w-100 mt-5"></div>
              <b-col md="3">
                <b-button
                  class="large blue outline"
                  :to="{
                    name: 'materias',
                    params: {
                      cycle: this.$route.params.cycle,
                      page: this.$route.params.page,
                    },
                  }"
                  >Voltar</b-button
                >
              </b-col>
              <b-col md="3">
                <b-button @click="create" class="large blue">Salvar</b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </template>
  </div>
</template>

<script>
import DataService from "@/services/subjects.service";

import SelectCycles from '@/components/inputs/SelectCycles.vue'
import SidebarCoordination from '@/components/SidebarCoordination.vue';

export default {
  props: [
    "selected"
  ],
  name: 'nova-materia',
  components: {
    SelectCycles,
    SidebarCoordination
  },
  data() {
    return {
      isActive: false,
      lists: [],

      subjects: {
        cycle_id: null,
        is_active: true,
        is_editable: true,
      },

      options: [
        'Artes', 'Ciências da Natureza', 'Ciências Humanas e Sociais', 'Corpo e Mente', 'Língua Estrangeira', 'Língua Portuguesa','Matemática'
      ],

      breadcrumb : [
        {
          text: 'Coordenação',
          href: '/coordenacao',
        },
        {
          text: 'Alterações internas',
          active: true
        },
        {
          text: 'Disciplinas',
          href: '/materias',
          active: true
        }
      ],
    }
  },
  methods: {
    create() {
      var data = {
        is_active: this.subjects.is_active,
        is_editable: this.subjects.is_editable,
        designation: this.subjects.designation,
        cycle_id: this.subjects.cycle_id,
      };
      DataService.create(data)
        .then(response => {
          this.subjects.id = response.data.id
          this.$router.push({ name: "materias" });
          const Toast = this.$swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
            didOpen: (toast) => {
            toast.addEventListener('mouseenter', this.$swal.stopTimer)
            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
          }
          });
          Toast.fire({
            icon: 'success',
            title: 'Disciplina cadastrada com sucesso!'
          });
        })
        .catch(error => {
          this.$swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Não foi possivel cadastrar!',
            footer: 'Por favor, verifique se todos os campos estão preenchidos.'
          })

          return error.response
        })
    },

    callbackCycles(args) {
      this.subjects.cycle_id = args.id
    }
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/variables.scss";

.home {
  height: 100%;
}

.container-rda {
  padding-bottom: 0;
}

.tags {
  background-color: $blue;
  border-radius: 5px;
  color: $white;
  font-weight: $bold;
  line-height: 28px;
  margin: 3px 12px 3px 0;
  max-width: 58px;
  padding: 0 3px;
  text-align: center;
  vertical-align: middle;
  width: 100%;
}

</style>