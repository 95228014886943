<template>
  <div class="home">
    <template>
      <b-container class="container-rda">
        <b-row>
          <b-col>
            <b-breadcrumb :items="breadcrumb"></b-breadcrumb>
          </b-col>
        </b-row>
        <b-row class="mb-4">
          <b-col>
            <h2 class="purple bold">Histórico de acesso ao sistema</h2>
          </b-col>
          <b-col class="text-right"> </b-col>
        </b-row>
        <b-row class="mb-5">
          <b-col>
            <a href="#" class="boxes-history">
              <p>Usuários online</p>
              <h1>20</h1>
            </a>
          </b-col>
          <b-col v-if="false">
            <a href="/coordenacao/historico/" class="boxes-history active">
              <p>Acessos/mês</p>
              <h1>4.500</h1>
            </a>
          </b-col>
          <b-col>
            <a href="#" class="boxes-history">
              <p>Alunos registrados</p>
              <h1>400</h1>
            </a>
          </b-col>
          <b-col>
            <a
              href="/coordenacao/historico/acessos-falhados"
              class="boxes-history"
            >
              <p>Acessos falhados/dia</p>
              <h1>4</h1>
            </a>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <StandardInput
              type="text"
              name="text"
              label="Data/Mês"
              placeholder=""
            >
            </StandardInput>
          </b-col>
          <b-col>
            <StandardInput
              type="text"
              name="text"
              label="Tipo de Usuário"
              placeholder=""
            >
            </StandardInput>
          </b-col>
          <b-col> </b-col>
        </b-row>
        <b-row>
          <b-col>
            <div class="order-grid-box">
              <p
                class="order-list"
                :class="{ desc: isActive }"
                v-on:click="orderName"
              >
                Ordenar
                <OrderNameIcon />
              </p>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-container fluid>
              <b-row class="header-list-teacher">
                <b-col md="5">
                  <p class="bold">Data</p>
                </b-col>
                <b-col>
                  <p class="bold">Tipo de usuário</p>
                </b-col>
                <b-col md="5">
                  <p class="bold">Quantidade de acessos</p>
                </b-col>
              </b-row>
            </b-container>
            <b-container fluid>
              <b-row align-v="center" class="list-teacher">
                <b-col md="5">
                  <p>07/12/2020</p>
                </b-col>
                <b-col>
                  <p>Pai</p>
                </b-col>
                <b-col md="5">
                  <p>6</p>
                </b-col>
              </b-row>
              <b-row class="align-center">
                <b-pagination
                  aria-controls="teachersList"
                  last-text=""
                  first-text=""
                >
                  <template #prev-text><LeftArrowIcon class="left" /></template>
                  <template #next-text><LeftArrowIcon /></template>
                </b-pagination>
              </b-row>
            </b-container>
          </b-col>
        </b-row>
      </b-container>
    </template>
  </div>
</template>

<script>
import StandardInput from '@/components/inputs/StandardInput.vue'
import OrderNameIcon from '@/components/svg/OrderNameIcon.vue'
import LeftArrowIcon from '@/components/svg/LeftArrowIcon.vue'

export default {
  name: 'historico',
  components: {
    StandardInput,
    OrderNameIcon,
    LeftArrowIcon,
  },
  data() {
    return {
      isActive: false,
      status: true,
      breadcrumb : [
        {
          text: 'Coordenação',
          href: '/coordenacao',
        },
        {
          text: 'Histórico',
          href: '/historico',
          active: true
        },
      ],
      selected: null,
    }
  },
  methods: {
    orderName() {
      this.isActive = !this.isActive;
    },
  }
}
</script>

<style lang="scss" scoped>

.home {
  height: 100%;
}

.container-rda {
  padding-bottom: 0;
}

</style>