<template>
  <div class="home">
    <template>
      <b-container class="container-rda sidebar">
        <b-row class="height">
          <sidebar-coordination />
          <b-col class="content">
            <b-row>
              <b-col>
                <b-breadcrumb :items="breadcrumb"></b-breadcrumb>
              </b-col>
            </b-row>
            <b-row class="mb-4">
              <b-col>
                <h2 class="purple bold">Novo Ciclo</h2>
              </b-col>
              <b-col class="text-right"> </b-col>
            </b-row>
            <b-row class="mb-5 pb-5">
              <b-col md="1" class="status">
                <b-form-group label="Ativo">
                  <b-form-checkbox
                    v-model="cycles.is_editable"
                    switch
                    checked
                  ></b-form-checkbox>
                </b-form-group>
              </b-col>
              <b-col>
                <StandardInput
                  v-model="cycles.designation"
                  type="text"
                  name="designation"
                  label="Nome do Ciclo"
                >
                </StandardInput>
              </b-col>
              <b-col>
                <StandardInput
                  v-model="cycles.name"
                  type="text"
                  name="name"
                  label="Ciclo"
                >
                </StandardInput>
              </b-col>
              <div class="w-100 mt-5"></div>
              <b-col md="3">
                <b-button class="large blue outline" :to="{ name: 'ciclos' }"
                  >Voltar</b-button
                >
              </b-col>
              <b-col md="3">
                <b-button @click="create" class="large blue">Salvar</b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </template>
  </div>
</template>

<script>
import DataService from "@/services/cycles.service";

import StandardInput from '@/components/inputs/StandardInput.vue'
import SidebarCoordination from '@/components/SidebarCoordination.vue';

export default {
  name: 'novo-ciclo',
  components: {
    StandardInput,
    SidebarCoordination
  },
  data() {
    return {
      isActive: false,
      cycles: {
        is_editable: true,
      },
      lists: [],
      breadcrumb : [
        {
          text: 'Coordenação',
          href: '/coordenacao',
        },
        {
          text: 'Alterações internas',
          active: true
        },
        {
          text: 'Ciclos',
          active: true
        }
      ],
    }
  },
  methods: {
    create() {
      var data = {
        is_editable: this.cycles.is_editable,
        designation: this.cycles.designation,
        name: this.cycles.name,
      };
      DataService.create(data)
        .then(response => {
          this.cycles.id = response.data.id
          this.$router.push({ name: "ciclos" })
          const Toast = this.$swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
            didOpen: (toast) => {
            toast.addEventListener('mouseenter', this.$swal.stopTimer)
            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
          }})

          Toast.fire({
            icon: 'success',
            title: 'Ciclo cadastrado com sucesso!'
          })

          return response.data
        }).catch(error => {
          this.$swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Não foi possivel cadastrar!',
            footer: 'Por favor, verifique se todos os campos estão preenchidos.'
          })

          return error.response
        })
    },
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/variables.scss";

.home {
  height: 100%;
}

.container-rda {
  padding-bottom: 0;
}

.tags {
  background-color: $blue;
  border-radius: 5px;
  color: $white;
  font-weight: $bold;
  line-height: 28px;
  margin: 3px 12px 3px 0;
  max-width: 58px;
  padding: 0 3px;
  text-align: center;
  vertical-align: middle;
}

</style>