const enrollments = state => state.enrollments;
const enrollment = state => state.enrollment;
const getStudent = state => state.student
const getCycle = state => state.cycle
const getContractorA = state => state.contractorA
const getContractorB = state => state.contractorB
const getFiliationA = state => state.filiationA
const getFiliationB = state => state.filiationB

const reEnrollContractorA = state => state.reEnrollContractorA
const reEnrollContractorB = state => state.reEnrollContractorB
const reEnrollFiliationA = state => state.reEnrollFiliationA
const reEnrollFiliationB = state => state.reEnrollFiliationB

const getComplement = state => state.complement
const getAuthorization = state => state.authorization
const getFinance = state => state.finance
const getFiles = state => state.files


export default {
  enrollments,
  enrollment,
  getStudent,
  getCycle,
  getContractorA,  
  getContractorB,
  getFiliationA,
  getFiliationB,

  reEnrollContractorA,
  reEnrollContractorB,
  reEnrollFiliationA,
  reEnrollFiliationB,

  getComplement,
  getAuthorization,
  getFinance,
  getFiles
}
