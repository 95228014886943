<template>
  <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.125 12.875H0.625C0.273438 12.875 0 13.1875 0 13.5V16C0 16.3516 0.273438 16.625 0.625 16.625H3.125C3.4375 16.625 3.75 16.3516 3.75 16V13.5C3.75 13.1875 3.4375 12.875 3.125 12.875ZM3.125 0.375H0.625C0.273438 0.375 0 0.6875 0 1V3.5C0 3.85156 0.273438 4.125 0.625 4.125H3.125C3.4375 4.125 3.75 3.85156 3.75 3.5V1C3.75 0.6875 3.4375 0.375 3.125 0.375ZM3.125 6.625H0.625C0.273438 6.625 0 6.9375 0 7.25V9.75C0 10.1016 0.273438 10.375 0.625 10.375H3.125C3.4375 10.375 3.75 10.1016 3.75 9.75V7.25C3.75 6.9375 3.4375 6.625 3.125 6.625ZM19.375 13.5H6.875C6.52344 13.5 6.25 13.8125 6.25 14.125V15.375C6.25 15.7266 6.52344 16 6.875 16H19.375C19.6875 16 20 15.7266 20 15.375V14.125C20 13.8125 19.6875 13.5 19.375 13.5ZM19.375 1H6.875C6.52344 1 6.25 1.3125 6.25 1.625V2.875C6.25 3.22656 6.52344 3.5 6.875 3.5H19.375C19.6875 3.5 20 3.22656 20 2.875V1.625C20 1.3125 19.6875 1 19.375 1ZM19.375 7.25H6.875C6.52344 7.25 6.25 7.5625 6.25 7.875V9.125C6.25 9.47656 6.52344 9.75 6.875 9.75H19.375C19.6875 9.75 20 9.47656 20 9.125V7.875C20 7.5625 19.6875 7.25 19.375 7.25Z" fill="url(#paint0_linear)"/>
    <defs>
    <linearGradient id="paint0_linear" x1="-7.32" y1="14.3168" x2="37.2" y2="14.3168" gradientUnits="userSpaceOnUse">
    <stop stop-color="#EF6549"/>
    <stop offset="1" stop-color="#B23066"/>
    </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  
}
</script>

<style lang="sass">

</style>