<template>
  <b-col :md="md" class="menu-sidebar">
    <div class="menu">
      <router-link :to="{ name: 'turmas' }"><span>Turmas</span></router-link>
      <router-link :to="{ name: 'ciclos' }"><span>Ciclos</span></router-link>
      <router-link :to="{ name: 'materias' }"
        ><span>Disciplinas</span></router-link
      >
      <router-link :to="{ name: 'calendario-escolar' }"
        ><span>Calendário</span></router-link
      >
      <router-link :to="{ name: 'mensalidade-escolar' }"
        ><span>Mensalidade escolar</span></router-link
      >
    </div>
  </b-col>
</template>

<script>

export default {
  name: 'SidebarCoordination',
  data() {
    return {

    }
  },

  props: {
    md: {
      default: 2,
      type: Number
    },
  },
}
</script>

<style lang="scss" scoped>
  ul, li {
    padding: 0;
  }
</style>
