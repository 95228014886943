<template>
  <div id="app">
    <div id="loading">
      <b-spinner
        style="width: 3rem; height: 3rem"
        label="Carregando..."
      ></b-spinner>
    </div>
    <Header v-if="!$route.meta.hideNavigation" />
    <!-- Router -->
    <router-view />

    <button
      :class="`upToTop ${scrollpx > 350 ? 'active' : ''}`"
      @click="upPage"
    >
      <b-icon-arrow-up-circle-fill />
    </button>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

import Header from "@/components/Header.vue";

export default {
  name: "App",
  components: {
    Header,
  },

  data() {
    return {
      scrollpx: 0,
    };
  },

  computed: {
    ...mapGetters({
      currentUser: "auth/getAuthData",
      getLoginStatus: "auth/getLoginStatus",
    }),

    showAdminBoard() {
      if (this.currentUser && this.currentUser.roles) {
        return this.currentUser.roles.includes("ROLE_ADMIN");
      }

      return false;
    },
    showModeratorBoard() {
      if (this.currentUser && this.currentUser.roles) {
        return this.currentUser.roles.includes("ROLE_MODERATOR");
      }

      return false;
    },
  },

  methods: {
    ...mapMutations({
      setUserByToken: "auth/setUserByToken",
    }),
    upPage() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    handleScroll() {
      this.scrollpx = window.scrollY;
    },
  },

  mounted() {
    if (
      process.env.NODE_ENV === "staging" ||
      process.env.NODE_ENV === "development"
    ) {
      console.log("Você está em um Ambiente de Testes");
    }

    let accessToken = "";
    const localUser = localStorage.getItem("user");

    if (localUser) accessToken = JSON.parse(localUser).access_token;

    if (accessToken) this.setUserByToken(accessToken);
  },

  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss">
#app {
  height: 100%;
}

.upToTop {
  background: #fff;
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  border-radius: 50px;
  padding: 0;
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  z-index: -1;
  visibility: hidden;
  opacity: 0;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: 0.2s all ease-in-out;

  &.active {
    z-index: 200;
    visibility: visible;
    opacity: 1;
    transition: 0.2s all ease-in-out;
  }

  svg {
    font-size: 38px;
    color: #b23066;
  }
}
</style>
