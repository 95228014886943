import Vue from 'vue'
import Vuex from 'vuex'

import { auth } from './auth.module'
import learning from './learning'
import shared from './shared'
import community from './community'
import enrollment from './enrollment'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  getters: {},
  actions: {},
  mutations: {},
  modules: {
    auth,
    learning,
    shared,
    community,
    enrollment
  }
})
