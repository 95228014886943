import Vue from "vue";
import VueTheMask from "vue-the-mask";
import Multiselect from "vue-multiselect";
import VueSimpleAlert from "vue-simple-alert";
import VueSweetalert2 from "vue-sweetalert2";
import vueDebounce from "vue-debounce";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import VeeValidate from "vee-validate";
import store from "./store";
import ToastPlugin from "vue-toast-notification";
import "vue-toast-notification/dist/theme-sugar.css";

import App from "./App.vue";
import router from "./router";

Vue.config.productionTip = false;

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VeeValidate);
Vue.use(ToastPlugin);
Vue.use(VueTheMask);
Vue.component("multiselect", Multiselect);
Vue.use(VueSimpleAlert);
Vue.use(VueSweetalert2);
Vue.use(vueDebounce);

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "./assets/css/global.scss";
import "sweetalert2/dist/sweetalert2.min.css";

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

Vue.mixin({
  methods: {
    clearCycle(cycle, page) {
      this.cycle = null;
      this.page = 1;
      this.getList({ cycle_id: cycle, page: page });
    },
    clearSubject(subject, page) {
      this.subject = null;
      this.page = 1;
      this.getList({ cycle_id: subject, page: page });
    },
  },
});
