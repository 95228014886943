import httpRda from "../http-rda";

export default async function enrollmentAuthorizationCheck(enrollmentId) {
  const result = httpRda
    .post(`/enrollmentAuthorizationCheck`, {
      enrollmentId: enrollmentId,
    })
    .then((res) => res.data.status);
  return result;
}
