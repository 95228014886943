<template>
  <b-modal
    ref="update-price"
    id="update-price"
    hide-header
    hide-footer
    centered
    no-close-on-backdrop
  >
    <h4 class="purple mb-4">
      Atualizar mensalidade do {{ selectedDate.cycle_name }}
    </h4>
    <b-row>
      <b-col class="mt-4">
        <b-form-group class="calendar">
          <StandardInput
            v-model="selectedDate.cycle_name"
            type="text"
            name="Nome"
            label="Nome"
            placeholder
            disabled
          />
        </b-form-group>
      </b-col>
      <b-col class="mt-4">
        <b-form-group class="calendar">
          <StandardInput
            v-model="selectedDate.cycle_description"
            type="text"
            name="Descrição"
            label="Descrição"
            placeholder
            disabled
          />
        </b-form-group>
      </b-col> </b-row
    ><b-row>
      <b-col class="mt-4">
        <b-form-group class="calendar">
          <StandardInput
            v-model="selectedDate.instalment_price"
            type="number"
            name="Mensalidade"
            label="Mensalidade (R$)"
            placeholder
            :change="calculateAnnuity(selectedDate.instalment_price)"
          />
        </b-form-group>
      </b-col>
      <b-col class="mt-4">
        <b-form-group class="calendar">
          <StandardInput
            v-model="selectedDate.annuity_price"
            type="number"
            name="Anuidade"
            label="Anuidade (R$)"
            disabled
            placeholder
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="mt-5">
      <b-col>
        <b-button class="btn large outline" @click="hideModal()"
          >Voltar</b-button
        >
      </b-col>
      <b-col>
        <b-button
          id="del-btn"
          class="btn large blue"
          @click="deletePrice(selectedDate.id)"
          >Deletar</b-button
        >
      </b-col>
      <b-col class="text-right">
        <b-button class="btn large blue" @click="update(selectedDate.id)"
          >Salvar</b-button
        >
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import StandardInput from "@/components/inputs/StandardInput.vue";
import httpRda from "../../http-rda";

export default {
  name: "",
  components: { StandardInput },
  props: {
    calendar: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectedDate: {},
      calendars: [],
    };
  },

  watch: {
    calendar() {
      if (this.calendar)
        this.selectedDate = JSON.parse(JSON.stringify(this.calendar));
    },

    selectedDate() {
      if (this.selectedDate) {
        this.selectedDate.instalment_price = this.cleanString(
          this.selectedDate.instalment_price
        );
        this.selectedDate.annuity_price = this.cleanString(
          this.selectedDate.annuity_price
        );
      }
    },
  },

  methods: {
    async deletePrice(priceId) {
      if (!priceId) {
        alert("Não foi possivel deletar o preço");
      }
      if (confirm("Tem certeza que deseja deletar o preço?")) {
        await httpRda.delete(`/prices/${priceId}`);
        this.hideModal();
        window.location.reload();
      }
    },

    cleanString(inputString) {
      if (inputString) {
        const stringWithoutLetters = inputString.replace(/[^\d.,\s]/g, "");

        const stringWithoutPoint = stringWithoutLetters.replace(/\./g, "");

        const strintFinal = stringWithoutPoint.replace(/,/g, ".");

        return strintFinal.trim();
      }
    },

    calculateAnnuity(instalment_price) {
      const numericInstalment = +instalment_price;

      if (!isNaN(numericInstalment)) {
        const annuityValue = +(numericInstalment * 12).toFixed(2);

        this.selectedDate.annuity_price = annuityValue;
        this.selectedDate.instalment_price = numericInstalment;
      }
    },

    formatNumberToCurrency() {
      this.selectedDate.annuity_price =
        "R$ " +
        this.selectedDate.annuity_price.toLocaleString("pt-BR", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });

      this.selectedDate.instalment_price =
        "R$ " +
        this.selectedDate.instalment_price.toLocaleString("pt-BR", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
    },

    update(id) {
      this.formatNumberToCurrency();
      const data = {
        id: this.selectedDate.id,
        instalment_price: this.selectedDate.instalment_price,
        annuity_price: this.selectedDate.annuity_price,
      };

      httpRda
        .patch(`/prices/${id}`, data)
        .then(() => {
          window.location.reload();
          const Toast = this.$swal.mixin({
            toast: true,
            position: "bottom-end",
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer);
              toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            },
          });
          Toast.fire({
            icon: "success",
            title: "Preço atualizado com sucesso!",
          });
          this.hideModal();
        })
        .catch((error) => {
          this.message =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
          this.$swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Não foi possivel atualizar!",
            footer: `${this.message.details}`,
          });
        });
    },
    hideModal() {
      this.$refs["update-price"].hide();
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/css/variables.scss";

div#update-price {
  .modal-dialog {
    max-width: 820px;
    .modal-content {
      background-color: $grey2;
      border: 0;
      border-radius: 0;
      .modal-body {
        padding: 60px;
      }
    }
  }

  #del-btn {
    background-color: #cb465a;
    color: white;
  }
  #del-btn:hover {
    background-color: rgb(233, 117, 117);
  }
  .students {
    list-style: none;
    padding: 0;
    margin: 0;
    .student {
      align-items: center;
      display: flex;
      justify-content: space-between;
      line-height: 60px;
      border-bottom: 1px solid $grey5;
      &:last-child {
        border-bottom: 0;
      }
      .name {
        margin: 0;
      }
      .actions {
        a {
          margin: 0 15px;
        }
      }
    }
  }
  .vdp-datepicker__calendar:nth-child(3) {
    header {
      .month__year_btn.up {
        pointer-events: none;
        width: 100%;
      }
      .prev,
      .next {
        display: none;
      }
    }
  }
}
</style>
