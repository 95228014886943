<template>
  <b-form-group>
    <label v-if="label">{{ label }}</label>
    <multiselect
      v-model="value"
      :options="myOptions"
      :clear-on-select="false"
      :close-on-select="true"
      :preserve-search="true"
      placeholder="Selecione"
      label="name"
      track-by="id"
      selectLabel=""
      selectedLabel=""
      deselectLabel=""
      :multiple="false"
    >
      <span slot="noResult">Nada encontrado</span>
    </multiselect>
  </b-form-group>
</template>

<script>
export default {
  props: ["callback", "options", "hasSelectAll", "initial", "label"],

  data() {
    return {
      value: null,
    };
  },

  created() {
    this.value =
      this.initial || (this.myOptions.length > 0 ? [this.myOptions[0]] : []);
  },

  watch: {
    value() {
      this.callback(this.value);
    },

    initial() {
      this.value = this.initial;
      this.callback(this.value);
    },
  },

  computed: {
    myOptions() {
      if (!this.hasSelectAll) return this.options;

      if (!this.options.length) return [];

      return [...this.options];
    },
  },
};
</script>
