<template>
  <div class="home">
    <template>
      <b-container class="container-rda sidebar">
        <b-row class="height">
          <b-col class="content">
            <b-row>
              <b-col>
                <b-breadcrumb :items="breadcrumb"></b-breadcrumb>
              </b-col>
            </b-row>

            <b-row class="mb-5">
              <b-col md="7">
                <h2 class="purple bold">Registrar roteiro</h2>
              </b-col>
            </b-row>

            <b-row class="register-type">
              <b-col class="status" md="12">
                <b-form-group>
                  <label for="label" class="mb-3">Ativo</label>
                  <b-form-checkbox v-model="syllabus.is_editable" switch />
                </b-form-group>
              </b-col>

              <b-col md="12">
                <b-row v-for="(comp, index) in competencesInputs" :key="index">
                  <b-col :md="index > 0 ? '11' : '12'">
                    <StandardSelect
                      :callbackSelect="(c) => selectCompetence(c, index)"
                      :options="competencesNamed"
                      :init="competencesInputs[index]"
                      :allowEmpty="false"
                      label="Competência"
                      placeholder="Selecione uma competência"
                    />
                  </b-col>

                  <b-col class="align-self-center" v-if="index > 0" md="1">
                    <span @click="() => deleteCompetence(index)">
                      <DeleteIcon class="deleteIcone" />
                    </span>
                  </b-col>
                </b-row>

                <b-button @click="addCompetenceInput" class="normal mb-5"
                  ><strong>+</strong> Nova competência</b-button
                >
              </b-col>

              <b-col md="12">
                <b-row v-for="(top, index) in topicsInputs" :key="index">
                  <b-col :md="index > 0 ? '11' : '12'">
                    <StandardSelect
                      :callbackSelect="(t) => selectTopics(t, index)"
                      :options="topicsNamed"
                      :init="topicsInputs[index]"
                      :allowEmpty="false"
                      label="Conteúdo"
                      placeholder="Selecione uma conteúdo"
                    />
                  </b-col>

                  <b-col class="align-self-center" v-if="index > 0" md="1">
                    <span @click="() => deleteTopics(index)">
                      <DeleteIcon class="deleteIcone" />
                    </span>
                  </b-col>
                </b-row>

                <b-button @click="addTopicInput" class="normal mb-5"
                  ><strong>+</strong> Novo conteúdo</b-button
                >
              </b-col>

              <b-col md="6">
                <StandardInput
                  v-model="syllabus.name"
                  type="text"
                  name="nomeRoteiro"
                  label="Nome do roteiro"
                  placeholder=""
                />
              </b-col>

              <b-col md="3">
                <StandardInput
                  v-model="syllabus.theme"
                  type="text"
                  name="theme"
                  label="Tema"
                  placeholder=""
                />
              </b-col>
              <b-col md="3">
                <StandardInput
                  v-model="syllabus.hours"
                  type="text"
                  name="hours"
                  label="Prazo médio para conclusão"
                  placeholder=""
                />
              </b-col>

              <b-col md="12">
                <b-form-group>
                  <label for="label">Descritivo</label>
                  <b-form-textarea
                    v-model="syllabus.description"
                    placeholder=""
                  />
                </b-form-group>
              </b-col>

              <b-col>
                <StandardInput
                  v-model="syllabus.link"
                  type="text"
                  name="link"
                  label="Link Web complementar"
                  placeholder="www.google.com.br"
                />
              </b-col>

              <b-col md="5">
                <StandardInput
                  type="text"
                  name="tags"
                  label="Palavras-Chave"
                  placeholder=""
                />
              </b-col>

              <div class="w-100"></div>

              <b-col md="5">
                <b-row align-v="center">
                  <b-col>
                    <b-button class="btn large outline" v-b-modal.tutoria
                      >Atribuir aluno</b-button
                    >
                  </b-col>

                  <b-col>
                    <label class="nomargin block">
                      <b-button tag="span" class="btn large blue"
                        >Carregar roteiro</b-button
                      >

                      <input
                        hidden
                        @change="uploadFile"
                        type="file"
                        accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pdf"
                        name="addFileInput"
                        id="addFileInput"
                        ref="addFileInput"
                      />
                    </label>
                  </b-col>
                </b-row>
              </b-col>

              <b-col class="mt-5 mb-4" md="12">
                <div class="top-border"></div>
              </b-col>

              <b-col md="3">
                <b-button
                  :to="{
                    name: 'roteiros',
                    params: {
                      subject: this.$route.params.subject,
                    },
                  }"
                  class="large blue outline"
                  >Voltar</b-button
                >
              </b-col>

              <b-col md="3">
                <b-button @click="submitForm" class="large blue"
                  >Salvar</b-button
                >
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>

      <AssignStudent
        :cycle-to-filter="syllabusCycle"
        :students-to-edit="syllabusStudents"
        :callback-assign="assignStudents"
      />
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import StandardInput from "@/components/inputs/StandardInput.vue";
import StandardSelect from "@/components/inputs/StandardSelect.vue";
import AssignStudent from "@/components/modals/AssignStudent.vue";
import DeleteIcon from "@/components/svg/DeleteIcon.vue";

export default {
  name: "SyllabusCreateEdit",
  components: {
    StandardInput,
    StandardSelect,
    AssignStudent,
    DeleteIcon,
  },

  data() {
    return {
      status: true,
      competencesInputs: [{}],
      topicsInputs: [{}],
      topics: [],
      studentsToAssign: [],
      syllabus: {
        // Tags: [],
        is_editable: true,
        name: "",
        theme: "",
        hours: "",
        description: "",
        link: "",
      },
      breadcrumb: [
        {
          text: "Aprendizagem",
          href: "/aprendizagem",
        },
        {
          text: "Roteiros",
          href: "/aprendizagem/roteiros-historia",
        },
        {
          text: "Registrar",
          href: "/aprendizagem/registrar-roteiro",
          active: true,
        },
      ],
    };
  },

  computed: {
    ...mapGetters({
      competences: "shared/competences",
      syllabusEditing: "learning/syllabus",
      syllabusTopics: "learning/syllabusTopics",
      syllabusStudents: "learning/syllabusStudents",
      syllabusCycle: "learning/syllabusCycle"
    }),

    competencesNamed() {
      return this.competences.map((c) => {
        const isAlreadySelected = this.competencesInputs.some(
          (ci) => ci.id === c.id
        );
        return { ...c, name: c.description, $isDisabled: isAlreadySelected };
      });
    },

    topicsNamed() {
      return this.topics.map((t) => {
        const isAlreadySelected = this.topicsInputs.some(
          (ti) => ti.id === t.id
        );
        return { ...t, name: t.description, $isDisabled: isAlreadySelected };
      });
    },

    id() {
      return this.$route.params.id;
    },

    subject_id() {
      return this.$route.params.syllabus_id;
    },

    studentToEdit() {
      const assigneds = this.syllabusStudents;
      const toAssign = this.studentsToAssign;

      return toAssign.filter((ta) =>
        assigneds.some(
          (as) => as.student.id === ta.student && as.date !== ta.date
        )
      );
    },
  },

  methods: {
    ...mapActions({
      getCompetences: "shared/getCompetencesBySubject",
      getTopics: "shared/getTopicsByCompetence",
      saveSyllabus: "learning/saveSyllabus",
      updateSyllabus: "learning/updateSyllabus",
      loadSyllabusDetail: "learning/loadSyllabus",
      postStudentsToAssign: "learning/batchSaveStudentsSyllabuses",
      deleteStudents: "learning/batchDeleteStudentsSyllabuses",
      updateStudents: "learning/batchUpdateStudentsSyllabuses",
      saveFile: "learning/saveFile",
      getCycle: "learning/getSubjectCycle",
    }),

    async save() {
      try {
        const Topics = this.topicsInputs.map((t) => t.id);
        const resp = await this.saveSyllabus({ ...this.syllabus, Topics });
        const syllabus_id = resp.id;
        const studentsArray = this.studentsToAssign;

        this.updatePhoto(syllabus_id);

        this.postStudentsToAssign({ syllabus_id, studentsArray });

        this.$router.push({
          name: "roteiros",
          params: {
            subject: this.subject_id
          }
        });
        const Toast = this.$swal.mixin({
          toast: true,
          position: 'bottom-end',
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
          didOpen: (toast) => {
          toast.addEventListener('mouseenter', this.$swal.stopTimer)
          toast.addEventListener('mouseleave', this.$swal.resumeTimer)
        }
        });
        Toast.fire({
          icon: 'success',
          title: 'Roteiro criado com sucesso!'
        });
      } catch (error) {
        this.$swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Não foi possivel atualizar!",
          footer: "Por favor, tente novamente mais tarde.",
        });
        return error.response
      }
    },

    async update() {
      try {
        const Topics = this.topicsInputs.map((t) => t.id);
        await this.updateSyllabus({ syllabus: this.syllabus, Topics });
        const syllabus_id = this.id;

        this.updatePhoto(syllabus_id);

        this.updateStudentsAssigneds(syllabus_id);

        this.$router.push({
          name: "roteiros",
          params: {
            subject: this.$route.params.subject
          }
        });
        const Toast = this.$swal.mixin({
          toast: true,
          position: 'bottom-end',
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
          didOpen: (toast) => {
          toast.addEventListener('mouseenter', this.$swal.stopTimer)
          toast.addEventListener('mouseleave', this.$swal.resumeTimer)
        }
        });
        Toast.fire({
          icon: 'success',
          title: 'Roteiro atualizado com sucesso!'
        });
      } catch (error) {
        this.$swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Não foi possivel atualizar!",
          footer: "Por favor, tente novamente mais tarde.",
        })

        return error.response
      }
    },

    updateStudentsAssigneds(syllabus_id) {
      const assigneds = this.syllabusStudents; // initials - {date: string, student: {id: number, ...}}
      const toAssign = this.studentsToAssign; // assigneds - {date: string, student: number}

      const studentToEdit = toAssign.filter((assign) =>
        assigneds.some(
          (as) => as.student.id === assign.student && as.date !== assign.date
        )
      );

      const newStudents = toAssign.filter(
        (curr) => !assigneds.some((as) => as.student.id === curr.student)
      );

      const studentToDelete = assigneds.filter(
        (as) => !toAssign.some((ta) => ta.student === as.student.id)
      );

      this.postStudentsToAssign({ studentsArray: newStudents, syllabus_id });
      this.deleteStudents({ studentsArray: studentToDelete, syllabus_id });
      this.updateStudents({ studentsArray: studentToEdit, syllabus_id });
    },

    uploadFile() {
      const [file] = this.$refs.addFileInput.files;

      this.file = file;
    },

    selectCompetence(comp, index) {
      const newCompetences = [...this.competencesInputs];
      newCompetences[index] = comp;

      this.competencesInputs = newCompetences;
      this.getTopics(comp.id);
    },

    selectTopics(top, index) {
      const newTopics = [...this.topicsInputs];
      newTopics[index] = top;

      this.topicsInputs = newTopics;
    },

    addCompetenceInput() {
      this.competencesInputs.push({});
    },

    addTopicInput() {
      this.topicsInputs.push({});
    },

    deleteCompetence(index) {
      const comps = [...this.competencesInputs];
      comps.splice(index, 1);
      this.competencesInputs = comps;
      this.topics = this.topics.filter((t) =>
        this.competencesInputs.some((ci) => ci.id === t.competence_id)
      );
    },

    deleteTopics(index) {
      const tops = [...this.topicsInputs];
      tops.splice(index, 1);
      this.topicsInputs = tops;
    },

    assignStudents(students) {
      this.studentsToAssign = students;
    },

    updatePhoto(syllabus_id) {
      if (!this.file) return;

      const bodyFormData = new FormData();
      bodyFormData.append("file", this.file);

      this.saveFile({ id: syllabus_id, file: bodyFormData });
    },

    loadFields() {
      this.syllabus = this.syllabusEditing;
      this.topicsInputs = this.syllabusTopics.map((t) => ({
        ...t,
        name: t.description,
      }));
      this.studentsToAssign = this.syllabusStudents.map((sa) => ({
        date: sa.date,
        student: sa.student.id,
      }));
      this.competencesInputs = this.syllabusTopics.reduce(
        (init, { Competence }) => {
          if (init.some((c) => c.id === Competence.id)) return init;
          init.push({ ...Competence, name: Competence.description });

          return init;
        },
        []
      );
    },

    selectCycle(params) {
      this.cycle_id = params.id;
    },

    submitForm() {
      this.id ? this.update() : this.save();
    },
  },

  watch: {
    competences() {
      if(this.competences.length)
        this.getCycle(this.competences[0].subject_id)
    },
    syllabusTopics() {
      if(this.syllabusTopics.length && !this.syllabusCycle)
        this.getCycle(this.syllabusTopics[0].Competence.subject_id)
    }
  },

  async created() {
    if (this.subject_id)
      await this.getCompetences(this.subject_id);
    else if (this.id) {
      await this.loadSyllabusDetail(this.id);
      this.loadFields();
    } else {
      return this.$router.push("/aprendizagem/roteiros-historia");
    }

    this.$store.subscribe(({ type }, state) => {
      if (type === "shared/LIST_TOPICS_BY_COMPETENCE") {
        this.topics = this.competencesInputs.reduce((init, actual) => {
          const topicsArray = state.shared.topicsByCompetences[actual.id];

          if (!topicsArray) return init;

          const newTopics = topicsArray.map((t) => ({
            ...t,
            name: t.description,
          }));
          init = [...init, ...newTopics];

          return init;
        }, []);
      }
    });
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/variables.scss";

.home {
  height: 100%;
}

.container-rda {
  padding-bottom: 0;
  .form-group {
    padding-bottom: 25px;
  }
}

.tags {
  background-color: $blue;
  border-radius: 5px;
  color: $white;
  font-weight: $bold;
  line-height: 28px;
  margin: 3px 12px 3px 0;
  max-width: 52px;
  padding: 0 3px;
  text-align: center;
  vertical-align: middle;
  width: 100%;
}

.register-type {
  padding-bottom: 30px;
  .top-border {
    border-top: 2px solid $grey7;
    padding-top: 35px;
    margin-top: 10px;
  }
}

.deleteIcone {
  cursor: pointer;
}

label.block {
  display: block;
}
</style>