<template>
  <div class="home">
    <template>
      <b-container class="container-rda">
        <b-row>
          <b-col>
            <b-breadcrumb :items="breadcrumb"></b-breadcrumb>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <h2 class="purple bold">Pedidos</h2>
          </b-col>
          <b-col class="flex-right">
            <p class="qty-order">{{ count }}</p>
          </b-col>
        </b-row>
        <b-row class="mt-50">
          <b-col>
            <p class="purple">Selecione o ciclo desejado</p>
          </b-col>
          <div class="w-100"></div>
          <b-col md="3">
            <SelectCalendar
              label="Ano"
              placeholder="Selecione"
              v-model="calendar"
              :callback="callbackCalendars"
            ></SelectCalendar>
            <span
              class="clear-field"
              v-if="this.calendar != null"
              @click="clearCalendar"
              >Limpar filtro</span
            >
          </b-col>
          <b-col md="3">
            <SelectCycles
              v-model="cycle"
              :callback="callbackCycles"
            ></SelectCycles>
            <span
              class="clear-field"
              v-if="this.cycle != null"
              @click="clearCycle"
              >Limpar filtro</span
            >
          </b-col>
          <b-col md="3">
            <SelectStudentsValidationStatus
              v-model="validationStatus"
              :callback="callbackValidation"
            ></SelectStudentsValidationStatus>
            <span
              class="clear-field"
              v-if="this.validationStatus != null"
              @click="clearValidation"
              >Limpar filtro</span
            >
          </b-col>
          <b-col>
            <b-form-group label="Buscar">
              <b-input-group-prepend>
                <b-form-input
                  type="search"
                  placeholder="Digite aqui"
                  v-model="searchTitle"
                  v-on:keyup.enter="
                    page = 1;
                    getList();
                  "
                ></b-form-input>
                <b-input-group-text
                  class="clear-fields"
                  v-if="searchTitle != ''"
                  v-b-tooltip.hover
                  title="Limpar Pesquisa"
                >
                  <b-icon icon="x" @click="clearFields" />
                </b-input-group-text>
              </b-input-group-prepend>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <div class="order-grid-box">
              <p
                class="order-list"
                :class="{ desc: descOrder }"
                v-on:click="orderName"
              >
                Ordenar
                <OrderNameIcon />
              </p>
            </div>
          </b-col>
        </b-row>
        <b-row style="margin-right: 0">
          <b-col class="box">
            <b-container fluid>
              <b-row class="column-list" v-if="count >= 0">
                <b-col md="1">
                  <p class="bold">Matrícula</p>
                </b-col>
                <b-col md="2">
                  <p class="bold">Aluno</p>
                </b-col>
                <b-col md="1">
                  <p class="bold">Ciclo</p>
                </b-col>
                <b-col>
                  <p class="bold">Ano letivo</p>
                </b-col>
                <b-col>
                  <p class="bold">Data criação</p>
                </b-col>
                <b-col>
                  <p class="bold">Início de frequência</p>
                </b-col>
                <b-col>
                  <p class="bold">Imp. Contrato</p>
                </b-col>
                <b-col>
                  <p class="bold">Validação</p>
                </b-col>
                <b-col>
                  <p class="bold">Preenchimento</p>
                </b-col>
                <b-col md="1">
                  <p class="bold">Validado</p>
                </b-col>
              </b-row>
            </b-container>
            <b-container fluid>
              <b-row
                align-v="center"
                class="list-orders"
                v-for="enrollment in orderbyName"
                :key="enrollment.id"
              >
                <b-col md="1">
                  <p class>{{ enrollment.id }}</p>
                </b-col>
                <b-col md="2" class="name">
                  <p class>{{ enrollment.EnrollmentStudent.name }}</p>
                </b-col>
                <b-col md="1">
                  <p class="tags">
                    {{ getCycleName(enrollment.EnrollmentStudent.cycle_id) }}
                  </p>
                </b-col>
                <b-col>
                  <p>{{ getCalendarName(enrollment.calendar_id) }}</p>
                </b-col>
                <b-col>
                  <p>{{ enrollment.created_at | date }}</p>
                </b-col>
                <b-col>
                  <p>
                    {{ enrollment.EnrollmentStudent.starting_frequency }}
                  </p>
                </b-col>
                <b-col>
                  <p>{{ enrollment.signed_at | date }}</p>
                </b-col>
                <b-col>
                  <router-link
                    :to="{
                      name: 'validar-matricula',
                      params: {
                        id: enrollment.id,
                      },
                    }"
                    class="btn-link"
                    >Validar</router-link
                  >
                </b-col>
                <b-col>
                  <p class="tags">{{ enrollment.percentage }}%</p>
                </b-col>
                <b-col md="1">
                  <p>
                    {{ getValidationStatus(enrollment) }}
                  </p>
                </b-col>
              </b-row>
              <b-row align-v="center" class="list-teacher" v-if="count <= 0">
                <b-col>Nenhum resultado encontrado</b-col>
              </b-row>
              <b-row
                align-v="center"
                class="list-teacher"
                v-if="!isRequestSend"
              >
                <b-col>
                  Carregando
                  <span class="spinner-border spinner-border-sm"></span>
                </b-col>
              </b-row>
              <b-row
                class="align-center"
                v-else-if="count > perPage && isRequestSend"
              >
                <b-pagination
                  v-model="page"
                  :per-page="perPage"
                  :total-rows="count"
                  @change="changePage"
                  aria-controls="teachersList"
                  last-text
                  first-text
                >
                  <template #prev-text>
                    <LeftArrowIcon class="left" />
                  </template>
                  <template #next-text>
                    <LeftArrowIcon />
                  </template>
                </b-pagination>
              </b-row>
            </b-container>
          </b-col>
        </b-row>
      </b-container>
    </template>
  </div>
</template>

<script>
import DataService from "@/services/enrollments.service";
import DataServiceCycles from "@/services/cycles.service";
import DataServiceCalendars from "@/services/calendars.service";
import LeftArrowIcon from "@/components/svg/LeftArrowIcon.vue";
import SelectCycles from "@/components/inputs/SelectCycles.vue";
import SelectStudentsValidationStatus from "@/components/inputs/SelectStudentsValidationStatus.vue";
import OrderNameIcon from "@/components/svg/OrderNameIcon.vue";
import SelectCalendar from "@/components/inputs/SelectCalendar.vue";

export default {
  name: "Pedidos",
  components: {
    LeftArrowIcon,
    SelectCycles,
    SelectStudentsValidationStatus,
    OrderNameIcon,
    SelectCalendar,
  },
  data() {
    return {
      enrollments: [],
      descOrder: false,
      isRequestSend: false,

      calendar: null,
      cycle: null,
      validationStatus: null,
      searchTitle: "",

      page: this.$route.params.page || 1,
      count: 0,
      perPage: 20,

      rdaUnit: process.env.VUE_APP_RDA_UNIT,

      breadcrumb: [
        {
          text: "Secretaria",
          href: "/secretaria/contratos/",
        },
        {
          text: "Pedidos",
          href: "/pedidos",
          active: true,
        },
      ],
    };
  },

  filters: {
    date(date) {
      try {
        var d = new Date(date.split());
        var year = d.getFullYear().toString();
        var month = (d.getMonth() + 1).toString().padStart(2, "0");
        var day = d.getUTCDate().toString().padStart(2, "0");

        return day + "/" + month + "/" + year;
      } catch (error) {
        return date;
      }
    },
  },

  computed: {
    orderbyName() {
      let newList = [...this.enrollments];

      for (var i = 0; i < newList.length; i++) {
        if (newList[i].EnrollmentStudent === null) {
          newList.splice(i, 1);
          i--;
        }
      }

      newList.sort((a, b) => {
        const order = this.descOrder ? [1, -1] : [-1, 1];

        if (a.EnrollmentStudent.name < b.EnrollmentStudent.name)
          return order[0];
        else if (a.EnrollmentStudent.name > b.EnrollmentStudent.name)
          return order[1];

        return 0;
      });

      return newList;
    },
  },

  methods: {
    getValidationStatus(enrollment) {
      if (
        enrollment.EnrollmentFile?.is_valid_contract == true &&
        enrollment.EnrollmentFile?.is_valid_identity == true &&
        enrollment.EnrollmentFile?.is_valid_identity_back == true &&
        enrollment.EnrollmentFile?.is_valid_cpf == true &&
        enrollment.EnrollmentFile?.is_valid_vaccination_record == true &&
        enrollment.EnrollmentFile?.is_valid_transference_declaration == true &&
        enrollment.EnrollmentFile?.is_valid_discharge == true &&
        enrollment.EnrollmentFile?.is_valid_school_record == true &&
        enrollment.EnrollmentFile?.is_valid_identity_contractor_a == true &&
        enrollment.EnrollmentFile?.is_valid_identity_contractor_a_back ==
          true &&
        enrollment.EnrollmentFile?.is_valid_identity_contractor_b == true &&
        enrollment.EnrollmentFile?.is_valid_identity_contractor_b_back ==
          true &&
        enrollment.EnrollmentFile?.is_valid_cpf_contractor_a == true &&
        enrollment.EnrollmentFile?.is_valid_cpf_contractor_b == true
      ) {
        return "Sim";
      } else {
        return "Não";
      }
    },

    getRequestParams(
      searchTitle,
      page,
      perPage,
      cycle,
      calendar,
      validationStatus
    ) {
      let params = {};

      if (searchTitle) {
        params["search"] = searchTitle;
      }

      if (page) {
        params["page"] = page;
      }

      if (perPage) {
        params["limit"] = perPage;
      }

      if (cycle) {
        params["cycle_id"] = cycle;
      }

      if (calendar) {
        params["calendar_id"] = calendar;
      }
      if (validationStatus) {
        params["validation_status"] = validationStatus;
      }

      return params;
    },

    getList() {
      this.isRequestSend = false;
      const params = this.getRequestParams(
        this.searchTitle,
        this.page,
        this.perPage,
        this.cycle,
        this.calendar,
        this.validationStatus
      );

      DataService.getAll(params)
        .then((response) => {
          this.enrollments = response.data.enrollments;

          this.count = response.data.count;
        })
        .catch((error) => {
          return error.data;
        });

      this.isRequestSend = true;
    },

    getParams(params) {
      DataServiceCycles.getAll(params)
        .then((response) => {
          this.cycles = response.data.cycles;
        })
        .catch((error) => {
          return error.data;
        });

      DataServiceCalendars.getAll(params)
        .then((response) => {
          this.calendars = response.data.calendars;
        })
        .catch((error) => {
          return error.data;
        });

      DataService.getEnrollmentsActives()
        .then((response) => {
          this.count = response.data.count_active;
        })
        .catch((error) => {
          return error.data;
        });
    },

    orderName() {
      this.descOrder = !this.descOrder;
    },

    changePage(page) {
      this.page = page;
      this.getList();
      window.scrollTo({ top: 0, behavior: "smooth" });
    },

    callbackCalendars({ id }) {
      this.calendar = id;
      this.currentPage = 1;
      this.isRequestSend = false;
      this.enrollments = [];
      this.getList();
    },

    callbackCycles({ id }) {
      this.cycle = id !== -1 ? id : null;
      this.currentPage = 1;
      this.isRequestSend = false;
      this.enrollments = [];
      this.getList();
    },

    callbackValidation({ id }) {
      this.validationStatus = id !== -1 ? id : null;
      this.currentPage = 1;
      this.isRequestSend = false;
      this.enrollments = [];
      this.getList();
    },

    clearFields() {
      this.searchTitle = "";
      this.currentPage = 1;
      this.isRequestSend = false;
      this.enrollments = [];
      this.getList();
    },

    clearCycle() {
      this.cycle = null;
      this.currentPage = 1;
      this.getList();
    },

    clearValidation() {
      this.validationStatus = null;
      this.currentPage = 1;
      this.getList();
    },

    clearCalendar() {
      this.calendar = null;
      this.currentPage = 1;
      this.getList();
    },

    getCycleName(id) {
      if (this.cycles) {
        const cycles = this.cycles.find((c) => c.id === id);
        return cycles ? cycles.name : "-";
      }
    },

    getCalendarName(id) {
      if (this.calendars) {
        const calendars = this.calendars.find((c) => c.id === id);
        return calendars ? calendars.designation : "-";
      }
    },
  },

  created() {
    this.getList();
    this.getParams();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/variables.scss";

.list-orders {
  background-color: $white;
  border: 1px solid $grey5;
  border-radius: 10px;
  margin-bottom: 15px;
  min-height: 80px;
  &:last-child {
    margin-bottom: 0px;
  }
  &:hover {
    border-color: transparent;
    background-image: linear-gradient($white, $white),
      radial-gradient(circle at top left, $orange, $purple);
    background-origin: border-box;
    background-clip: content-box, border-box;
  }
  p {
    margin: 0;
  }
  .tags {
    background-color: $blue;
    border-radius: 5px;
    color: $white;
    font-weight: $bold;
    line-height: 28px;
    max-width: 70px;
    text-align: center;
  }
}

.column-list {
  color: $grey1;
}

.flex-right {
  align-items: center;
  display: flex;
  justify-content: flex-end;
}

.qty-order {
  background-color: $purple;
  border-radius: 5px;
  color: $white;
  font-weight: $bold;
  line-height: 28px;
  max-width: 100px;
  margin: 0;
  text-align: center;
  width: 100%;
}

.box-scroll {
  overflow-x: scroll;
  padding-bottom: 15px;
  &::-webkit-scrollbar {
    height: 6px;
    border-radius: 5px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 5px;
    margin-left: 15px;
  }
  &::-webkit-scrollbar-thumb {
    background: $grey5;
    border-radius: 5px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: $purple;
  }
  .container-fluid {
    width: 1500px;
  }
}
</style>
