<template>
  <div class="accordion">
    <div
      :class="`title-accordion ${this.open ? 'open' : null}`"
      @click="this.toggleAccordeon"
    >
      <div class="index-accordion" v-show="index">{{ this.index }}</div>
      <div class="text"><slot name="title"></slot></div>
      <DownArrowIcon class="down" />
    </div>
    <div :class="`content-accordion ${this.open ? 'open' : null}`">
      <div class="content"><slot name="content"></slot></div>
    </div>
  </div>
</template>

<script>
import DownArrowIcon from "@/components/svg/ArrowDown";

export default {
  name: "Accordion",
  components: {
    DownArrowIcon,
  },
  data() {
    return {
      open: false,
    };
  },

  props: {
    title: null,
    index: null,
  },

  methods: {
    toggleAccordeon() {
      this.open = !this.open;
    },
  },

  computed: {
    //
  },

  mounted() {
    //
  },
};
</script>

<style lang="scss" scoped>
.accordion {
  background-color: #f2f2f2;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  border-radius: 8px;
  position: relative;

  .title-accordion {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    padding: 24px;
    border-radius: 8px 8px 0 0;
    overflow: hidden;
    cursor: pointer;
    box-sizing: border-box;

    &.open {
      background-color: #b23066;
      color: #fff;
      height: "max-content";

      .text {
        color: #fff;
      }

      .down {
        filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(1%)
          hue-rotate(302deg) brightness(105%) contrast(101%);
        transform: rotate(-180deg);
      }
    }

    .text {
      flex: 1;
      font-weight: 400;
      font-size: 18px;
      line-height: 20px;
      color: #000;
    }
    .index-accordion {
      font-size: 24px;
      font-weight: bold;
      width: 32px;
    }
    .down {
      width: 32px;
      height: 8px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
  .content-accordion {
    width: 100%;
    max-height: 0;
    box-sizing: border-box;
    transition: all 0.2s ease-in-out;
    overflow: hidden;
    position: relative;

    .content {
      width: 100%;
      padding: 24px;
      box-sizing: border-box;

      > div {
        width: 100%;
      }

      ul {
        margin: 0;
        padding: 0;

        li {
          list-style: none;
        }
      }
    }

    .content-accordion {
      width: 100%;
      box-sizing: border-box;
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: -1;
    }

    &.open {
      max-height: max-content;
      position: relative;
      transition: all 0.2s ease-in-out;

      .content-accordion {
        position: relative;
        bottom: 0;
        left: 0;
        z-index: 0;
      }
    }
  }
}
</style>
