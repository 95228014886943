<template>
  <b-container class="container-rda login">
    <b-row class="form-login">
      <b-col>
        <div v-if="!successRequest" class="box-login">
          <form name="form" @submit.prevent="submitNewPassword">
            <b-form-group>
              <label for="label">Digite a sua nova senha</label>
              <b-form-input
                v-model="password"
                v-validate="{ required: true, min: 8 }"
                type="password"
                name="password"
                placeholder
                class="form-control"
              ></b-form-input>
              <div
                v-if="errors.has('password')"
                class="alert alert-danger"
                role="alert"
              >
                A senha deve ter no mínimo 8 caracteres.
              </div>
            </b-form-group>
            <div class="mt-3 d-block">
              <ul class="text-white">
                <li>mínimo 8 caracteres.</li>
                <li>recomendamos o uso de números e caracteres especiais</li>
              </ul>
            </div>
            <button :disabled="loading" class="mt-2 btn full btn-login">
              <span
                v-show="loading"
                class="spinner-border spinner-border-sm"
              ></span>
              <span>Salvar</span>
            </button>
            <div v-if="message" role="alert">
              <p class="mt-4 text-center message-error">
                <InfoIcon />Sessão expirada ou inválida, acesse
                <router-link :to="{ name: 'forgot-password' }"
                  >esqueceu sua senha?</router-link
                >
              </p>
            </div>
          </form>
        </div>

        <div v-if="successRequest" class="box-login text-center">
          <h2 class="mb-3">A sua nova senha foi salva!</h2>
          <router-link
            :to="{ name: 'login' }"
            class="btn-link white text-uppercase"
            >Voltar para tela de acesso</router-link
          >
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapActions } from "vuex";

import InfoIcon from "@/components/svg/InfoIcon.vue";

export default {
  name: "login",
  components: {
    InfoIcon,
  },

  data() {
    return {
      loading: false,
      message: "",
      successRequest: false,

      token: this.$route.query.t,
      password: "",
    };
  },

  methods: {
    ...mapActions({
      resetPassword: "shared/resetPassword",
      newPassword: "shared/newPassword",
    }),

    async submitNewPassword() {
      const data = {
        token: this.token,
        new_password: this.password,
      };

      this.$validator.validateAll().then((isValid) => {
        if (!isValid) {
          this.loading = false;
          return;
        }

        this.loading = true;

        this.newPassword(data)
          .then((response) => {
            this.loading = false;
            this.successRequest = true;

            return response;
          })
          .catch((error) => {
            this.loading = false;
            this.message =
              (error.response && error.response.data) ||
              error.message ||
              error.toString();
          });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/variables.scss";

.container-rda {
  display: flex;
  justify-content: center;
}

.login {
  background: url("~@/assets/images/bg-login.jpg");
  background-position: center;
  background-size: cover;
  height: 100%;
  max-width: 100%;
  padding: 0;
  .form-login {
    align-items: center;
    height: 100%;
    width: 100%;
    .box-login {
      max-width: 575px;
      margin: 0 auto;
      h2,
      p,
      label {
        color: $white;
      }
      .btn-login {
        text-transform: uppercase;
      }
      a {
        color: $white;
        font-size: 18px;
        line-height: 32px;
      }
    }
  }

  .spinner-border-sm {
    vertical-align: text-top;
    margin-right: 10px;
  }

  .message-error {
    background-color: $orange;
    border-radius: 5px;
    border: 1px solid $white;
    font-size: 16px;
    font-weight: 700;
    line-height: 16px;
    padding: 12px 20px;
    svg {
      vertical-align: text-top;
      margin-right: 8px;
    }
  }
}
</style>
