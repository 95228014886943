<template>
  <svg width="21" height="24" viewBox="0 0 21 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.5625 19.5H13.6875C13.9688 19.5 14.25 19.2656 14.25 18.9375V8.8125C14.25 8.53125 13.9688 8.25 13.6875 8.25H12.5625C12.2344 8.25 12 8.53125 12 8.8125V18.9375C12 19.2656 12.2344 19.5 12.5625 19.5ZM20.25 3.75H16.3594L14.7656 1.125C14.3906 0.515625 13.5469 0 12.8438 0H8.10938C7.40625 0 6.5625 0.515625 6.1875 1.125L4.59375 3.75H0.75C0.328125 3.75 0 4.125 0 4.5V5.25C0 5.67188 0.328125 6 0.75 6H1.5V21.75C1.5 23.0156 2.48438 24 3.75 24H17.25C18.4688 24 19.5 23.0156 19.5 21.75V6H20.25C20.625 6 21 5.67188 21 5.25V4.5C21 4.125 20.625 3.75 20.25 3.75ZM8.01562 2.39062C8.0625 2.34375 8.20312 2.25 8.25 2.25H8.29688H12.7031C12.75 2.25 12.8906 2.34375 12.9375 2.39062L13.7344 3.75H7.21875L8.01562 2.39062ZM17.25 21.75H3.75V6H17.25V21.75ZM7.3125 19.5H8.4375C8.71875 19.5 9 19.2656 9 18.9375V8.8125C9 8.53125 8.71875 8.25 8.4375 8.25H7.3125C6.98438 8.25 6.75 8.53125 6.75 8.8125V18.9375C6.75 19.2656 6.98438 19.5 7.3125 19.5Z" fill="url(#paint0_linear)"/>
    <defs>
    <linearGradient id="paint0_linear" x1="-6.405" y1="11.8812" x2="32.55" y2="11.8812" gradientUnits="userSpaceOnUse">
    <stop stop-color="#EF6549"/>
    <stop offset="1" stop-color="#B23066"/>
    </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  
}
</script>

<style lang="sass">

</style>