<template>
  <b-container class="container-rda">
    <b-row>
      <b-col>
        <b-breadcrumb :items="breadcrumb"></b-breadcrumb>
      </b-col>
    </b-row>

    <b-row class="mb-5">
      <b-col md="4">
        <h2 class="purple bold">Tutoria</h2>
      </b-col>

      <b-col
        class="text-right"
        style="gap: 20px"
        v-if="
          user.role.includes('coordinator') ||
          user.role.includes('admin') ||
          user.role.includes('secretary')
        "
      >
        <b-button @click="openModalAttClass" class="purple primary"
          >Remover tutorandos</b-button
        >

        <!-- <b-button id="remove-all-students" @click="removeAllStudents"
          >Remover todos os tutorandos</b-button
        > -->
      </b-col>
    </b-row>

    <b-row class="mb-5">
      <b-col>
        <b-form-group label="Buscar aluno">
          <b-form-input
            type="search"
            size="sm"
            placeholder="Digite aqui"
            v-model="search"
            v-debounce:1s="searched"
          >
          </b-form-input>
          <b-input-group-text
            class="clear-fields"
            v-if="search != ''"
            v-b-tooltip.hover
            title="Limpar Pesquisa"
          >
            <b-icon icon="x" @click="clearSearch" />
          </b-input-group-text>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row align="center" v-if="loading">
      <b-col class="mt-5">
        <b-spinner variant="success" label="Carregando..."></b-spinner>
      </b-col>
    </b-row>

    <div>
      <h2 class="purple bold">Meus alunos tutorandos</h2>
      <b-row align="center" v-if="students.length > 0">
        <b-col class="mb-1" v-for="(item, index) in students" :key="index">
          <div class="student-link">
            <router-link
              :to="{
                name: 'tutoria-aluno',
                params: {
                  id: item.Student && item.Student.User && item.Student.User.id,
                },
              }"
            >
              <b-avatar
                class="mb-4"
                :src="
                  item.Student && item.Student.User && item.Student.User.photo
                "
                size="7rem"
              ></b-avatar>
              <p class="name-student">
                {{
                  item.Student && item.Student.User && item.Student.User.name
                }}
              </p>
            </router-link>
          </div>
        </b-col>
      </b-row>

      <b-row v-else-if="!loading && students.length === 0">
        <b-col class="box-align-message">
          <div class="message-not-students">
            <p>Nenhum aluno tutorando encontrado</p>
          </div>
        </b-col>
      </b-row>

      <b-col class="divider" style="padding-bottom: 20px"></b-col>
    </div>
    <h2 class="purple bold" style="padding-top: 10px; padding-bottom: 10px">
      Outros alunos tutorandos
    </h2>
    <b-row align="center" v-if="otherStudents.length > 0">
      <b-col class="mb-1" v-for="(item, index) in otherStudents" :key="index">
        <div class="student-link">
          <router-link
            :to="{
              name: 'tutoria-aluno',
              params: {
                id: item.Student && item.Student.User && item.Student.User.id,
              },
            }"
          >
            <b-avatar
              class="mb-4"
              :src="
                item.Student && item.Student.User && item.Student.User.photo
              "
              size="7rem"
            ></b-avatar>
            <p class="name-student">
              {{ item.Student && item.Student.User && item.Student.User.name }}
            </p>
          </router-link>
        </div>
      </b-col>
    </b-row>

    <b-row v-else-if="!loading && otherStudents.length === 0">
      <b-col class="box-align-message">
        <div class="message-not-students">
          <p>Nenhum aluno tutorando encontrado</p>
        </div>
      </b-col>
    </b-row>

    <b-modal
      id="modal-remove-tutoring"
      title="Remover tutorandos"
      size="xl"
      hide-footer
      @hidden="clearModalData"
    >
      <b-row>
        <b-col md="4" style="padding-bottom: 15px">
          <b-form-group label="Tutores">
            <multiselect
              v-model="selectedTeacher"
              :options="teachers"
              :multiple="false"
              placeholder="Selecione"
              label="name"
              track-by="teacher_id"
              selectLabel=""
              @select="handleTeachersModal"
            >
              <span slot="noResult">Nada encontrado</span>
            </multiselect>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-container fluid>
            <b-row class="header-list-teacher">
              <b-col md="6">
                <p class="bold">Nome</p>
              </b-col>
              <b-col>
                <p class="bold">Turma</p>
              </b-col>
              <b-col md="2">
                <p class="bold"></p>
              </b-col>
            </b-row>
          </b-container>
          <b-container fluid>
            <b-row
              align-v="center"
              class="list-teacher"
              v-for="(student, index) in studentsTutored"
              v-bind:key="index"
            >
              <b-col md="6">
                <p>
                  {{
                    student ? student.Student.User.name : "Nome não cadastrado"
                  }}
                </p>
              </b-col>
              <b-col>
                <p>{{ student.Student.classroom }}</p>
              </b-col>
              <b-col md="2">
                <b-checkbox-group
                  name="confirm-checkbox"
                  :id="'confirm-checkbox' + index"
                  @change="handleCheckStudent(student)"
                >
                  <b-form-checkbox
                    v-model="student.checked"
                    :value="true"
                    :unchecked-value="false"
                    :id="'confirm-true' + index"
                  >
                  </b-form-checkbox>
                </b-checkbox-group>
              </b-col>
            </b-row>
          </b-container>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="4">
          <b-form-group class="label-add" :label="'&nbsp;'">
            <b-button
              :disabled="!studentsToremove.length > 0"
              @click="removeStudents"
              class="blue"
              >Remover Selecionados</b-button
            >
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
  </b-container>
</template>

<script>
import store from "../../../store";
import httpRda from "../../../http-rda";

export default {
  name: "tutoria-alunos",
  components: {},

  data() {
    return {
      user: null,
      loading: false,
      teachers: [],
      selectedTeacher: null,
      studentsTutored: [],
      studentsToremove: [],
      breadcrumb: [
        {
          text: "Tutoria",
          href: "tutoria-alunos",
        },
      ],
      students: [],
      otherStudents: [],
      search: null,
      filter: {
        name: null,
      },
    };
  },

  computed: {},

  methods: {
    openModalAttClass() {
      this.$bvModal.show("modal-remove-tutoring");
    },

    clearModalData() {
      this.studentsTutored = [];
      this.studentsToremove = [];
      this.selectedTeacher = null;
    },

    clearSearch() {
      this.search = null;
      this.getStudent();
    },

    searched() {
      if (this.search) {
        this.filter.name = this.search;
        this.getStudent(this.filter);
      } else {
        this.getStudent();
      }
    },

    handleCheckStudent({ Student, checked }) {
      if (checked.length > 0) {
        this.studentsToremove.push(Student.id);
      } else {
        this.studentsToremove = this.studentsToremove.filter(
          (id) => id !== Student.id
        );
      }
    },

    async handleTeachersModal({ teacher_id }) {
      await httpRda.get(`tutoring/${teacher_id}`).then((res) => {
        this.studentsTutored = res.data.tutoring.students.map((student) => ({
          ...student,
          checked: false,
        }));
      });
    },

    async removeAllStudents() {
      this.$swal
        .fire({
          title: "Tem certeza que deseja excluir todos os alunos da tutoria?",
          text: "Você não poderá reverter isso!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#68c5b7",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim, excluir!",
          cancelButtonText: "Cancelar",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            await httpRda.delete(`tutoring/removeAllStudents`, {
              data: {
                deleteAll: true,
              },
            });

            this.clearModalData();
            this.getStudent();
          }
        })
        .catch(() => {
          this.$swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Não foi possivel excluir!",
          });
        });
    },

    async removeStudents() {
      if (!this.selectedTeacher) {
        return;
      }

      await httpRda.delete(
        `tutoring/${this.selectedTeacher.teacher_id}/removeStudents`,
        {
          data: {
            students: this.studentsToremove,
          },
        }
      );

      this.$bvModal.hide("modal-remove-tutoring");
      this.clearModalData();
      this.getStudent();
    },

    async getStudent(filter = null) {
      const user = store.getters["auth/getAuthData"];
      this.loading = true;

      await httpRda
        .get(`tutoring/${user.teacher_id || 0}`, { params: filter })
        .then((res) => {
          this.loading = false;
          this.students = res.data.tutoring.students;
          this.otherStudents = res.data.tutoring.otherStudents;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    async getAllTeachers() {
      await httpRda.get("/tutoring/teachers").then((res) => {
        this.teachers = res.data.teachers || [];
      });
    },
  },

  mounted() {
    this.getStudent();
    this.getAllTeachers();
    this.user = store.getters["auth/getAuthData"];
  },
};
</script>

<style lang="scss">
.divider {
  p {
    min-height: 70px;
  }
  &:after {
    background-color: $grey7;
    content: "";
    display: block;
    height: 1px;
    left: 0;
    top: 50%;
    position: absolute;
    width: 100%;
  }
}

#remove-all-students {
  background-color: #b23066 !important;
  border-color: #b23066;
  &:hover {
    background-color: #b23066;
    border-color: #b23066;
    color: #fff;
  }
}

.box-align-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.message-not-students {
  display: flex;
  width: 50%;
  justify-content: center;
  padding: 10px;
  text-align: center;
  box-sizing: border-box;
  p {
    margin: 0;
  }
}

.label-add {
  text-indent: -999rem;
  font-size: 0;
}
</style>
