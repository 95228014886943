<template>
  <b-container class="container-rda">
    <b-row>
      <b-col>
        <b-breadcrumb :items="breadcrumb"></b-breadcrumb>
      </b-col>
    </b-row>

    <b-row class="mb-5">
      <b-col>
        <h2 class="purple bold">Tutoria - Sobre o aluno</h2>
      </b-col>
    </b-row>

    <b-row align="center" v-if="loading">
      <b-col class="mt-5">
        <b-spinner variant="success" label="Carregando..."></b-spinner>
      </b-col>
    </b-row>

    <b-row v-if="this.student !== null">
      <b-col cols="3" v-if="this.student">
        <b-avatar class="mb-4" :src="this.student && this.student.User && this.student.User.photo
          ? this.student.User.photo
          : '../../../assets/images/user_placeholder.png'
          " size="17rem"></b-avatar>
        <div class="info-student">
          <h4>
            {{
              this.student.User.name && this.student.User
                ? this.student.User.name
                : "Não cadastrado"
            }}
          </h4>
          <ul>
            <li>
              <b>Turma</b>
              <p>
                {{
                  this.student &&
                    this.student.Classroom &&
                    this.student.Classroom.year_id
                    ? `${this.student.Classroom.year_id} º ano do`
                    : ""
                }}
                {{
                  this.student.Cycle && this.student.Cycle.name
                    ? this.student.Cycle.name
                    : ""
                }}
              </p>
              <p>
                {{
                  this.student.Cycle && this.student.Cycle.designatio
                    ? this.student.Cycle.designation
                    : ""
                }}
              </p>
            </li>
          </ul>
        </div>
      </b-col>
      <b-col>
        <b-tabs class="tab-content" content-class="mt-5">
          <b-tab title="Entrevista Inicial">
            <h4>Observações de tutoria</h4>
            <b-col v-if="initialInterview && initialInterview.initial_interview">
              <b-row class="mb-5" align-v="center">
                <b-col class=""> </b-col>
                <b-col cols="4" v-if="
                  initialInterview &&
                  initialInterview.teacherId &&
                  initialInterview.teacherId === teacher_id
                ">
                  <b-button class="btn large blue" @click="
                    openModal(
                      initialInterview.student_id,
                      currentUserId,
                      index
                    )
                    ">Atualizar Observação</b-button>
                </b-col>
              </b-row>
              <b-col class="obs-info">
                <i>Atualizado por
                  {{
                    initialInterview && initialInterview.teacherId == teacher_id
                      ? "mim"
                      : initialInterview.teacherName
                  }}
                  em
                  {{ initialInterview && initialInterview.updated_at }}
                </i>
                <p>
                  {{ initialInterview && initialInterview.initial_interview }}
                </p>

                <div class="file-group">
                  <h6>Arquivo para download</h6>
                  <div v-if="initialInterview.file_url !== null" :key="indexFile">
                    <a :href="`${initialInterview.file_url}`" target="_blank" download>
                      <b-icon icon="file-arrow-down" aria-hidden="true"></b-icon>
                      <span>Anexo</span>
                    </a>
                  </div>
                </div>
              </b-col>
            </b-col>
            <b-row v-if="!initialInterview.initial_interview" fluid class="notInitialView mb-5" align-v="center">
              <b-col class="message-not-found">
                <p style="margin: 0">
                  Não existe entrevista registrada para esse estudante
                </p>
              </b-col>
              <b-col cols="2" v-if="showButtons">
                <b-button class="btn large blue" @click="$bvModal.show('modal-create-initialView')">Criar</b-button>
              </b-col>
            </b-row>

            <b-row>
              <b-col class="parent-list">
                <h4 class="mb-4">Responsáveis</h4>
                <ul>
                  <li v-if="
                    student.Parent1 &&
                    student.Parent1.Responsible &&
                    student.Parent1.Responsible.User
                  ">
                    <div>
                      <b-avatar class="mb-4" :src="student.Parent1.Responsible.User.photo
                        ? student.Parent1.Responsible.User.photo
                        : '../../../assets/images/user_placeholder.png'
                        " size="10rem"></b-avatar>
                    </div>
                    <div>
                      <p>
                        <b>{{
                          student.Parent1.Responsible.User.name
                            ? student.Parent1.Responsible.User.name
                            : "Não cadastrado"
                        }}</b>
                      </p>
                      <p>
                        <b>Profissão: </b>{{
                          student.Parent1.Responsible.profession
                            ? student.Parent1.Responsible.profession
                            : "Não cadastrado"
                        }}
                      </p>
                      <p>
                        <b>Contato: </b>
                        <span>
                          {{
                            student.Parent1.Responsible.User.Phones.length > 0
                              ? student.Parent1.Responsible.User.Phones[0]
                                .number
                              : "Não cadastrado"
                          }}
                        </span>
                      </p>
                    </div>
                  </li>
                  <li v-if="
                    student.Parent2 &&
                    student.Parent2.Responsible &&
                    student.Parent2.Responsible.User
                  ">
                    <div>
                      <b-avatar class="mb-4" :src="student.Parent2.Responsible.User.photo
                        ? student.Parent2.Responsible.User.photo
                        : '../../../assets/images/user_placeholder.png'
                        " size="10rem"></b-avatar>
                    </div>
                    <div>
                      <p>
                        <b>{{
                          student.Parent2.Responsible.User.name
                            ? student.Parent2.Responsible.User.name
                            : "Não cadastrado"
                        }}</b>
                      </p>
                      <p>
                        <b>Profissão: </b>{{
                          student.Parent2.Responsible.profession
                            ? student.Parent2.Responsible.profession
                            : "Não cadastrado"
                        }}
                      </p>
                      <p>
                        <b>Contato: </b>{{
                          student.Parent2.Responsible.User.Phones.length > 0
                            ? student.Parent2.Responsible.User.Phones[0].number
                            : "Não cadastrado"
                        }}
                      </p>
                    </div>
                  </li>
                </ul>
              </b-col>
            </b-row>
          </b-tab>
          <b-tab title="Informe Periódico">
            <b-row class="mb-5" align-v="center">
              <b-col class="">
                <h4>Relatórios</h4>
              </b-col>
            </b-row>
            <b-row class="mb-5">
              <b-col>
                <b-row class="rda-tab-content" v-if="periodData">
                  <b-col cols="8" style="margin-left: 15px" v-for="(report, index) in periodData.reports" :key="index">
                    <b-row align-v="center" :class="[
                      'list-teacher',
                      activeEmpty(report) ? 'activePeriodEmpty' : '',
                    ]">
                      <b-col cols="9">
                        <p v-if="activeEmpty(report)">
                          <b>Registro do {{ report.name }} liberado</b>
                        </p>
                        <p v-else>
                          <b>{{ report.name }}</b>
                        </p>
                        <p v-if="activeEmpty(report)">
                          Faça o registro até o dia
                          {{ report.to_date }}
                        </p>
                        <p v-else>
                          <b style="color: #666666">Observação:</b>
                          {{ report.description ? report.description : "---" }}
                        </p>
                      </b-col>

                      <b-col cols="3" v-if="editable(report)">
                        <b-button class="btn large blue" @click="
                          openPeriodRDA(
                            periodData.periodType,
                            report,
                            'Editar'
                          )
                          ">
                          Editar
                        </b-button>
                      </b-col>

                      <b-col cols="3" v-else-if="activeEmpty(report) && isTeacher">
                        <b-button class="btn large blue" @click="
                          openPeriodRDA(periodData.periodType, null, 'Novo')
                          ">
                          Registrar
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row class="box-not-found" v-else>
                  <ul class="message-not-found">
                    <li>
                      <p>Ainda não há um bimestre ativo.</p>
                    </li>
                  </ul>
                </b-row>
              </b-col>
            </b-row>
          </b-tab>
          <b-tab title="Tutoria">
            <b-row class="mb-5" align-v="center">
              <b-col class="">
                <h4>Observações de tutoria</h4>
              </b-col>
              <b-col cols="4" v-if="showButtons">
                <b-button class="btn large blue" @click="openCreateModal()">
                  <b-icon icon="plus" aria-hidden="true"></b-icon>Registrar
                  observação
                </b-button>
              </b-col>
            </b-row>
            <b-row class="mb-5">
              <b-col>
                <ul class="tutoring-list" v-if="remarks.length > 0">
                  <li v-for="(item, index) in remarks" :key="index">
                    <i>
                      <time>Criado por
                        {{
                          item.teacher == teacher_id ? "mim" : item.teacherName
                        }}
                        em
                        {{ item.created_at }}
                      </time>
                    </i>
                    <p>
                      {{ item.remarks }}
                    </p>
                    <div class="file-group">
                      <h6>Arquivo para download</h6>
                      <div v-if="item.file_url !== null" :key="indexFile">
                        <a :href="`${item.file_url}`" target="_blank" download>
                          <b-icon icon="file-arrow-down" aria-hidden="true"></b-icon>
                          <span>{{ item.file_name }}</span>
                        </a>
                      </div>
                    </div>
                    <b-button-group size="sm" class="btn-group" v-if="item.teacher == teacher_id">
                      <button class="btn large blue" @click="getSingleRemark(item.id)">
                        Editar
                      </button>
                      <button @click="deleteRemark(item.id)">Excluir</button>
                    </b-button-group>
                  </li>
                </ul>
                <b-row class="box-not-found" v-else>
                  <ul class="message-not-found">
                    <li>
                      <p>Ainda não existe observação para esse aluno.</p>
                    </li>
                  </ul>
                </b-row>
              </b-col>
            </b-row>
          </b-tab>
          <b-tab title="RDA" active>
            <b-row class="rda-tabs">
              <b-col v-for="(cycle, index) in cycles" :key="index"
                :class="`tab ${cycle.active === true ? 'active' : ''}`" @click="selectRdaTab(index)">
                <b>{{ cycle.designation }}</b>
                <span>{{ cycle.name
                  }}{{ cycle.current === true ? " / Em curso" : "" }}</span>
              </b-col>
            </b-row>

            <b-row align="center" v-if="loadingRDA">
              <b-col class="mt-5">
                <b-spinner variant="success" label="Carregando..."></b-spinner>
              </b-col>
            </b-row>
            <div v-if="!loadingRDA && !viewRda">
              <div v-if="rdaTab">
                <b-row class="rda-tab-content">
                  <b-col cols="8">
                    <h4 class="mb-4">Disciplinas</h4>
                  </b-col>
                  <b-col cols="4">
                    <h4 class="mb-4">Evolução</h4>
                  </b-col>
                </b-row>
                <b-row class="rda-tab-content" v-for="(item, index) in subjects.Subjects" :key="`row-${index}`">
                  <b-col cols="8">
                    <b-row align-v="center" class="list-teacher">
                      <b-col cols="8">
                        <p>{{ item.designation }}</p>
                      </b-col>
                      <b-col>
                        <p v-if="item.status === 'EE'" class="tags warning">
                          EE
                        </p>
                      </b-col>
                      <b-col>
                        <p>
                          {{ item.progress }}
                        </p>
                      </b-col>
                      <b-col cols="1">
                        <button class="viewButton" @click="openViewRDA(item.id)">
                          <ViewIcon />
                        </button>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="4">
                    <div class="progress-rda">
                      <p>{{ item.percentage }}%</p>
                      <b :style="{ width: item.percentage + '%' }" :class="`bar ${createSubjectCssClass(
                        item.designation
                      )}`"></b>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </div>

            <div class="viewRDA" v-if="!loadingRDA && viewRda">
              <b-row>
                <b-col cols="12">
                  <span class="back-rda" @click="backTableRda(true)">
                    <BackIcon />
                    <span>Voltar</span>
                  </span>
                </b-col>
                <b-col cols="12">
                  <div class="title-viewRDA">
                    <b>{{ subjectSelected.designation }}</b>
                  </div>
                </b-col>
              </b-row>
              <b-row class="rda-viewlist" v-for="(item, index) in subjectSelected.Competences" :key="`item-${index}`">
                <b-col cols="12">
                  <h5 class="title">
                    {{ index + 1 }} - {{ item.description }}
                  </h5>
                </b-col>
                <b-col cols="12" v-for="(topic, indexS) in item.Topics" :key="`student-${indexS}`">
                  <div class="list-teacher item-rda-viewlist">
                    <div class="cols text-col">
                      <p>
                        {{ index + 1 }}.{{ indexS + 1 }} {{ topic.description }}
                      </p>
                    </div>
                    <div class="cols" v-if="topic.TopicStudents[0].status === 'AN'">
                      <b-button :id="topic.TopicStudents[0].topic_id" class="evidence-btn"
                        @click="showEvidence(topic.TopicStudents[0].pendency)">
                        Pendência
                      </b-button>
                    </div>
                    <div class="cols">
                      <p class="tags warning">
                        {{ topic.TopicStudents[0].status }}
                      </p>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </div>
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>

    <b-modal id="modal-bimontly" hide-footer>
      <template #modal-title>{{
        reportModalMode === "Editar" ? "Editar" : "Novo"
        }}
        Registro</template>
      <div class="d-block text-center">
        <b-form>
          <b-form-group id="modal-form-observation" label="Observações (Opcional)" label-for="form-observation">
            <b-form-textarea id="form-observation" v-model="reportDataToEdit.report" type="textarea" placeholder=""
              required rows="3" max-rows="6"></b-form-textarea>
          </b-form-group>
          <b-row class="mt-5">
            <b-col>
              <b-button class="btn large outline" @click="hidePeriodRDA()">Voltar
              </b-button>
            </b-col>
            <b-col class="text-right">
              <b-button class="btn large blue" @click="submitPeriodReport()">
                {{ reportModalMode === "Editar" ? "Salvar" : "Registrar" }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </div>
    </b-modal>

    <b-modal id="modal-evidence" hide-footer style="display: flex; justify-content: center; align-items: center">
      <h3>Pendência</h3>

      <div class="pendency-text">
        <p>{{ pendencyText }}</p>
      </div>
    </b-modal>

    <b-modal id="modal-update-initialView" v-if="this.initialInterview" hide-footer>
      <template #modal-title>Atualizar</template>
      <div class="d-block text-center">
        <b-form>
          <b-form-group id="modal-form-observation" label="Primeira entrevista" label-for="form-observation">
            <b-form-textarea id="form-observation" v-model="initialInterview.initial_interview" type="textarea"
              placeholder="" required rows="3" max-rows="6"></b-form-textarea>
          </b-form-group>
          <b-form-group>
            <UploadFiles v-model="initialInterview.file_url" :fileName="initialInterview.file_url ? 'Anexo' : null" />
          </b-form-group>
          <b-form-group class="wrap-btn-form">
            <b-button @click="updateInitialInterview" class="btn middle blue">
              Atualizar
            </b-button>
          </b-form-group>
        </b-form>
      </div>
    </b-modal>

    <b-modal id="modal-create-initialView" hide-footer>
      <template #modal-title>Registrar primeira entrevista</template>
      <div class="d-block text-center">
        <b-form>
          <b-form-group id="modal-form-observation" label="Primeira entrevista" label-for="form-observation">
            <b-form-textarea id="form-observation" v-model="initialInterview.initial_interview" type="textarea"
              placeholder="" required rows="3" max-rows="6"></b-form-textarea>
          </b-form-group>
          <b-form-group>
            <UploadFiles v-model="initialInterview.file_url" :fileName="files" />
          </b-form-group>
          <b-form-group class="wrap-btn-form">
            <b-button @click="sendInitialInterview" class="btn middle blue">
              Registrar
            </b-button>
          </b-form-group>
        </b-form>
      </div>
    </b-modal>

    <b-modal id="modal-register" hide-footer>
      <template #modal-title>Novo Registro</template>
      <div class="d-block text-center">
        <b-form>
          <b-form-group id="modal-form-observation" label="Observação" label-for="form-observation">
            <b-form-textarea id="form-observation" v-model="form.remarks" type="textarea" placeholder="" required
              rows="3" max-rows="6"></b-form-textarea>
          </b-form-group>
          <b-form-group>
            <label class="box-ckeckbox" for="form-checked">
              <span>Visível para os pais</span>
              <b-form-checkbox id="form-checked" v-model="form.parent_view" name="form-checked"
                switch></b-form-checkbox>
            </label>
            <label class="box-ckeckbox" for="form-checked-student">
              <span>Visível para o estudante</span>
              <b-form-checkbox id="form-checked-student" v-model="form.student_view" name="form-checked-student"
                switch></b-form-checkbox>
            </label>
          </b-form-group>
          <b-form-group>
            <UploadFiles v-model="form.files" />
          </b-form-group>
          <b-form-group class="wrap-btn-form">
            <b-button :disabled="submiting" class="btn middle blue" @click="createRemark">
              {{ submiting ? "Aguarde..." : "Registrar" }}
            </b-button>
          </b-form-group>
        </b-form>
      </div>
    </b-modal>

    <b-modal id="modal-update" hide-footer>
      <template #modal-title>Atualizar Registro</template>
      <div class="d-block text-center">
        <b-form>
          <b-form-group id="modal-form-observation" label="Observação" label-for="form-observation">
            <b-form-textarea id="form-observation" v-model="form.remarks" type="textarea" placeholder="" required
              rows="3" max-rows="6"></b-form-textarea>
          </b-form-group>
          <b-form-group>
            <label class="box-ckeckbox" for="form-checked">
              <span>Visível para os pais</span>
              <b-form-checkbox id="form-checked" v-model="form.parent_view" name="form-checked"
                switch></b-form-checkbox>
            </label>
            <label class="box-ckeckbox" for="form-checked-student">
              <span>Visível para o estudante</span>
              <b-form-checkbox id="form-checked-student" v-model="form.student_view" name="form-checked-student"
                switch></b-form-checkbox>
            </label>
          </b-form-group>
          <b-form-group>
            <UploadFiles v-model="form.files" :fileName="files" />
          </b-form-group>
          <b-form-group class="wrap-btn-form">
            <b-button :disabled="submiting" class="btn middle blue" @click="updateRemark(formId)">
              {{ submiting ? "Aguarde..." : "Atualizar" }}
            </b-button>
          </b-form-group>
        </b-form>
      </div>
    </b-modal>
  </b-container>
</template>

<script>
import UploadFiles from "@/components/inputs/UploadFiles.vue";
import httpRda from "../../../http-rda";
import store from "../../../store";
import moment from "moment";
import ViewIcon from "@/components/svg/ViewIcon.vue";
import BackIcon from "@/components/svg/LeftArrowIcon.vue";
export default {
  name: "tutotia-aluno",
  components: {
    UploadFiles,
    ViewIcon,
    BackIcon,
  },

  data() {
    return {
      isTeacher: false,
      pendencyText: null,
      breadcrumb: [
        {
          text: "Tutoria",
          href: "/comunidade/tutoria",
        },
        {
          text: "Tutoria - Sobre o aluno",
          href: null,
        },
      ],
      cycles: [],
      apenas: true,
      loading: false,
      loadingRDA: false,
      remarks: [],
      periodData: null,
      student: null,
      tutor: null,
      reportDataToEdit: {
        id: null,
        report: null,
      },
      initialInterview: {
        initial_interview: null,
        file_url: null,
        teacherId: null,
        teacherName: null,
        student_id: null,
      },
      currentIndex: null,
      teacher_id: null,
      allSubjects: [],
      subjects: null,
      subjectSelected: null,
      showButtons: true,
      submiting: false,
      form: {
        remarks: null,
        parent_view: false,
        student_view: false,
        files: [],
      },
      formId: Number,
      formReport: {
        period_type: null,
        period_id: null,
        student_id: null,
        report: null,
      },
      reportModalMode: null,
      periodDataDescription: null,
      periodReport: null,
      rdaTab: true,
      viewRda: false,
      currentUserId: null,
    };
  },

  methods: {
    removeSpecialChars(text) {
      text = text.toLowerCase();
      text = text.replace(new RegExp("[ÁÀÂÃ]", "gi"), "a");
      text = text.replace(new RegExp("[ÉÈÊ]", "gi"), "e");
      text = text.replace(new RegExp("[ÍÌÎ]", "gi"), "i");
      text = text.replace(new RegExp("[ÓÒÔÕ]", "gi"), "o");
      text = text.replace(new RegExp("[ÚÙÛ]", "gi"), "u");
      text = text.replace(new RegExp("[Ç]", "gi"), "c");

      return text;
    },

    showEvidence(pendency) {
      this.pendencyText = pendency;

      this.$bvModal.show("modal-evidence");
    },
    createSubjectCssClass(designation) {
      let aux = designation.toLowerCase();
      aux = aux.replace(/\s/g, "-");
      return this.removeSpecialChars(aux);
    },

    openCreateModal() {
      this.reportModalMode = "Novo";
      this.formId = null;
      this.form.remarks = null;
      this.form.parent_view = false;
      this.form.student_view = false;
      this.files = null;
      this.$bvModal.show("modal-register");
    },

    activeEmpty(report) {
      return this.periodData.name === report.name && !report.description_id;
    },

    editable(report) {
      return (
        this.periodData.name === report.name &&
        report.status === 'pending' &&
        report.description_id
      );
    },

    async getAllTutoringData(student_id) {
      this.loadingRDA = true;
      const response = await httpRda.get(
        `/tutoring/student/${student_id}/allTutoringData`
      );

      this.tutoringData = response.data.tutoringData;

      this.periodData = this.tutoringData.activePeriod;

      for (let index = 0; index < this.periodData.reports.length; index++) {
        this.periodData.reports[index].to_date = moment(
          this.periodData.reports[index].to_date
        ).format("DD/MM/YYYY");
      }

      if (this.periodData.to_date) {
        this.periodData.to_date = moment(this.periodData.to_date).format(
          "DD/MM/YYYY"
        );
      }

      this.remarks = this.tutoringData.remarkTutoring.map((remark) => {
        return {
          ...remark,
          created_at: moment(remark?.created_at).format(
            "DD/MM/YYYY [às] hh:mm"
          ),
          updated_at: moment(remark?.updated_at).format(
            "DD/MM/YYYY [às] hh:mm"
          ),
        };
      });

      this.initialInterview = this.tutoringData.initialInterview.map(
        (initialInterview) => {
          return {
            ...initialInterview,
            created_at: moment(initialInterview?.created_at).format(
              "DD/MM/YYYY [às] hh:mm"
            ),
            updated_at: moment(initialInterview?.updated_at).format(
              "DD/MM/YYYY [às] hh:mm"
            ),
          };
        }
      );

      this.loadingRDA = false;
    },

    async getDataUser() {
      this.loading = true;
      const { id } = this.$route.params;

      if (!id) {
        this.$router.push("/comunidade/tutoria");
      } else {
        const user = store.getters["auth/getAuthData"];
        const { teacher_id } = user;
        this.teacher_id = teacher_id;

        await httpRda
          .get(`students?include=parents,cycle,classroom&user_id=${id}`)
          .then(async (res) => {
            this.student = res.data.students[0];
            this.loading = false;

            if (!this.teacher_id) {
              this.showButtons = false;
            } else {
              await httpRda
                .get(`/tutoring/student/${this.student.id}/tutor`)
                .then((response) => {
                  response.data.tutor.teacher_id === this.teacher_id
                    ? (this.showButtons = true)
                    : (this.showButtons = false);
                });
            }

            await this.getAllTutoringData(this.student.id);
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },

    async createRemark() {
      this.submiting = true;
      if (this.form.remarks == null) {
        this.$swal.fire({
          title: "Alerta",
          text: "Preencher a observação",
          icon: "error",
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        try {
          const formData = new FormData();
          formData.append("remarks", this.form.remarks);
          formData.append("parent_view", this.form.parent_view);
          formData.append("student_view", this.form.student_view);
          formData.append("user_id", this.student.id);
          formData.append("teacher", this.teacher_id);
          formData.append("file", this.form.files);

          await httpRda.post(`/remarks`, formData);
          this.submiting = false;
          this.$swal.fire({
            title: "Sucesso",
            text: "Observação registrada com sucesso.",
            icon: "success",
            showConfirmButton: false,
            timer: 1500,
          });
          this.form.remarks = null;
          this.$bvModal.hide("modal-register");
          this.getDataUser();
        } catch (err) {
          this.submiting = false;
          this.$swal.fire({
            title: "Alerta",
            text: err.message,
            icon: "error",
            showConfirmButton: false,
            timer: 1500,
          });
        }
        this.form.files = null;
        this.submiting = false;
      }
    },

    openModal(studentId, currentUserId, index) {
      this.$bvModal.show("modal-update-initialView");
      this.student.id = studentId;
      this.currentUserId = currentUserId;
      this.currentIndex = index;
    },

    async sendInitialInterview() {
      try {
        const formData = new FormData();
        formData.append(
          "initial_interview",
          this.initialInterview.initial_interview
        );
        formData.append("who_edited_id", this.currentUserId);
        formData.append("student_id", this.student.id);
        formData.append("file", this.initialInterview.file_url);

        if (!this.initialInterview.initial_interview) {
          this.$swal.fire({
            title: "Alerta",
            text: "A entrevista não pode ser vazia",
            icon: "error",
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          await httpRda.post("initialinterview", formData);

          this.$swal.fire({
            title: "Sucesso",
            text: "Entrevista registrada com sucesso.",
            icon: "success",
            showConfirmButton: false,
            timer: 1500,
          });

          this.$bvModal.hide("modal-update-initialView");
          this.$bvModal.hide("modal-create-initialView");
          this.getDataUser();
        }
      } catch (err) {
        this.$swal.fire({
          title: "Alerta",
          text: err.message,
          icon: "error",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },

    async updateInitialInterview() {
      try {
        const formData = new FormData();

        formData.append(
          "initial_interview",
          this.initialInterview.initial_interview
        );
        formData.append("who_edited_id", this.currentUserId);
        formData.append("file", this.initialInterview.file_url);

        if (!this.initialInterview.initial_interview) {
          this.$swal.fire({
            title: "Alerta",
            text: "A entrevista não pode ser vazia",
            icon: "error",
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          await httpRda.patch(`/initialinterview/${this.student.id}`, formData);
          this.$swal.fire({
            title: "Sucesso",
            text: "Entrevista atualizada com sucesso.",
            icon: "success",
            showConfirmButton: false,
            timer: 1500,
          });
          this.$bvModal.hide("modal-update-initialView");
          this.$bvModal.hide("modal-create-initialView");
          this.getDataUser();
        }
      } catch (err) {
        this.$swal.fire({
          title: "Alerta",
          text: err.message,
          icon: "error",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },

    async deleteRemark(id) {
      this.$swal
        .fire({
          title: "Tem certeza?",
          text: "Você não poderá reverter isso!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#68c5b7",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim, excluir!",
          cancelButtonText: "Cancelar",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            try {
              await httpRda.delete(`/remarks/${id}`).then(() => {
                this.$swal.fire({
                  icon: "success",
                  title: "Excluído",
                  text: "Observação excluída com sucesso.",
                });
              });

              this.getDataUser();
            } catch (err) {
              this.$swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Não foi possivel excluir!",
                footer: "Por favor, tente novamente mais tarde.",
              });
              this.getDataUser();
              return err;
            }
          }
        })
        .catch((err) => {
          this.$swal.fire({
            icon: "error",
            title: err.message,
            text: "Não foi possivel excluir!",
          });
        });
    },

    async getSingleRemark(id) {
      const remarkData = await httpRda
        .get(`/remarks/${id}`)
        .then((e) => e.data.remark);
      this.formId = id;
      this.form.remarks = remarkData.remarks;
      this.form.parent_view = remarkData.parent_view;
      this.form.student_view = remarkData.student_view;
      this.files = remarkData.file_name;
      this.$bvModal.show("modal-update");
    },

    async updateRemark(id) {
      this.submiting = true;
      try {
        const formData = new FormData();
        formData.append("remarks", this.form.remarks);
        formData.append("parent_view", this.form.parent_view);
        formData.append("student_view", this.form.student_view);
        formData.append("user_id", this.student.id);
        formData.append("teacher", this.teacher_id);
        formData.append("file", this.form.files);

        await httpRda.patch(`/remarks/${id}`, formData);
        this.submiting = false;
        this.$swal.fire({
          title: "Sucesso",
          text: "Observação atualizada com sucesso.",
          icon: "success",
          showConfirmButton: false,
          timer: 1500,
        });
        this.form.remarks = null;
        this.$bvModal.hide("modal-update");
        this.getDataUser();
      } catch (err) {
        this.submiting = false;
        this.$swal.fire({
          title: "Alerta",
          text: err.message,
          icon: "error",
          showConfirmButton: false,
          timer: 1500,
        });
      }
      this.form.files = null;
      this.submiting = false;
    },

    selectRdaTab(clickIndex) {
      this.cycles.map((cycle, index) =>
        index === clickIndex ? (cycle.active = true) : (cycle.active = false)
      );

      this.loadingRDA = true;
      this.rdaTab = true;

      setTimeout(() => {
        this.defineCurrentSubjects();

        this.loadingRDA = false;
        this.viewRda = false;
      }, 100);
    },

    getCycleIndex() {
      return this.cycles.findIndex((cycle) => cycle.active == true);
    },

    defineCurrentSubjects() {
      this.subjects = this.allSubjects.find(
        (subject) => subject.id === this.cycles[this.getCycleIndex()].id
      );
    },

    async getSubjects(id) {
      this.loadingRDA = true;
      try {
        const { data } = await httpRda.get(
          `/tutoring/student/${this.student.id}/subjects/${id}`
        );
        this.subjectSelected = data.subjects[0];
        setTimeout(() => {
          this.loadingRDA = false;
          this.viewRda = true;
        }, 1000);
      } catch (err) {
        this.loadingRDA = false;
      }
      this.loadingRDA = false;
    },

    openViewRDA(id) {
      this.loadingRDA = true;
      this.getSubjects(id);
    },

    openPeriodRDA(period_type, report, mode) {
      this.reportModalMode = mode;
      this.formReport.period_type = period_type;

      this.reportDataToEdit.report = report && report.description ? report.description : null;
      this.reportDataToEdit.id = report && report.description_id ? report.description_id : null;

      this.$bvModal.show("modal-bimontly");
    },

    hidePeriodRDA() {
      this.$bvModal.hide("modal-bimontly");
    },

    submitPeriodReport() {
      this.loadingRDA = true;

      this.formReport.student_id = this.student.id;
      this.formReport.period_id = this.periodData.id;
      this.formReport.report = this.reportDataToEdit.report;

      if (this.reportModalMode === "Editar") {
        httpRda
          .patch(`/period_reports/${this.reportDataToEdit.id}`, this.formReport)
          .then(() => {
            this.$swal.fire({
              title: "Sucesso",
              text: "Registro atualizado com sucesso.",
              icon: "success",
              showConfirmButton: false,
              timer: 1500,
            });
            this.hidePeriodRDA();
            this.getDataUser();
          })
          .catch((err) => {
            this.$swal.fire({
              title: "Alerta",
              text: err.message,
              icon: "error",
              showConfirmButton: false,
              timer: 1500,
            });
          });
      } else {
        httpRda
          .post("/period_reports", this.formReport)
          .then(() => {
            this.$swal.fire({
              title: "Sucesso",
              text: "Registro criado com sucesso.",
              icon: "success",
              showConfirmButton: false,
              timer: 1500,
            });
            this.hidePeriodRDA();
            this.getDataUser();
          })
          .catch((err) => {
            this.$swal.fire({
              title: "Alerta",
              text: err.message,
              icon: "error",
              showConfirmButton: false,
              timer: 1500,
            });
          });
      }
    },

    backTableRda(status) {
      this.loadingRDA = true;
      this.rdaTab = status;

      setTimeout(() => {
        this.loadingRDA = false;
        this.viewRda = false;
      }, 100);
    },

    async getAllStudentCycles() {
      this.loadingRDA = true;
      await httpRda
        .get(`/tutoring/student/${this.student.id}/cycles`)
        .then((response) => {
          this.cycles = response.data.cycles;
          this.cycles = this.cycles.map((cycle) => {
            return {
              ...cycle,
              active: cycle.current === true ? true : false,
            };
          });
        });
      this.loadingRDA = false;
    },

    async getStudentSubjects() {
      this.loadingRDA = true;
      const subjectsAll = await httpRda.get(
        `/tutoring/student/${this.student.id}/allCyclesData`
      );

      let subjects = subjectsAll.data.data;

      subjects.map((data) => {
        data.Subjects.map((subject) => {
          subject.Competences.map((competence) => {
            competence.Topics.map((topic) => {
              topic.TopicStudents.map((item) => {
                if (item.status === "EE") {
                  subject.status = item.status;
                }
              });
            });
          });
        });
      });
      this.allSubjects = subjects;

      this.defineCurrentSubjects();
      this.loadingRDA = false;
    },

    getCurrentUser() {
      this.currentUserId = store.getters["auth/getAuthData"];
      this.isTeacher = this.currentUserId.role.includes("teacher");

      this.currentUserId = this.currentUserId.id;
    },
  },

  async created() {
    this.getCurrentUser();
    await this.getDataUser();
    await this.getAllStudentCycles();
    await this.getStudentSubjects();
  },

  mounted() { },
};
</script>

<style lang="scss">
.evidence-btn {
  border-radius: 5px;
  background-color: #68c5b7;
  color: white;
  border: none;
}

$artes-visuais: #b2ecf7;
$lingua-estrangeira: #ffc2c2;
$ciencias-da-natureza: #d1e7c0;
$ciencias-humanas: #d8c7ee;
$corpo-e-mente: #ffd97d;
$danca: #ff8b94;
$historia: #ffd9b6;
$lingua-portuguesa: #b2c9f7;
$matematica: #ffc9ed;
$espanhol: #a3e0ce;
$musica: #ffad76;
$cidadania-vida: #e7b3c8;
$teatro: #aedbad;
$ingles: #90a8e6;

.info-student {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  row-gap: 16px;

  ul {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    row-gap: 10px;
    margin: 0;
    padding: 0;

    li {
      list-style: none;

      b {
        font-weight: 400;
        font-size: 16px;
        color: #666666;
      }

      p {
        font-size: 18px;
        color: #000;
        margin-bottom: 0;
      }
    }
  }
}

.tab-content {
  .nav-tabs {
    border-bottom: 2px solid #e6e6e6;
    gap: 24px;

    li {
      &:first-child {
        a {
          text-align: left;
          padding: 0;
        }
      }
    }

    .nav-link {
      text-align: center;
      font-size: 18px;
      color: #1a1a1a;
      height: 52px;
      border: 0;
      padding: 0 18px;
      position: relative;

      &.active {
        font-weight: bold;
        background: transparent;

        &:after {
          width: 100%;
        }
      }

      &:after {
        background: $gradient1;
        content: "";
        width: 0;
        height: 4px;
        display: block;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 50px;
        transition: 0.2s all ease-in-out;
      }
    }
  }
}

.box-not-found {
  display: flex;
  flex-direction: row;
  justify-content: center;
  widows: 100%;
}

.message-not-found {
  width: 50%;
  background: $white;
  border: 1px solid #ededed;
  border-radius: 6px;
  padding: 10px;
  text-align: center;
  box-sizing: border-box;
  list-style: none;

  p {
    margin: 0;
  }
}

.btn {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  gap: 5px;

  svg {
    font-size: 175% !important;
  }
}

.obs-info {
  display: flex;
  flex-direction: column;
  text-align: start;
  row-gap: 24px;
  padding: 0;
  border-bottom: 2px solid #e6e6e6;
  padding-bottom: 70px;
  margin-bottom: 50px;

  i {
    color: #666666;
    font-size: 16px;
  }
}

.notInitialView {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  row-gap: 24px;
  border-bottom: 2px solid #e6e6e6;
  padding-bottom: 70px;
  margin-bottom: 70px;
}

.parent-list {
  ul {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    margin: 0;
    padding: 0;

    li {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 24px;
      list-style: none;

      p {
        margin-bottom: 0;
      }
    }
  }
}

.file-group {
  background: $white;
  border: 1px solid #ededed;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 16px;
  row-gap: 16px;
  border-radius: 6px;
  padding: 10px;
  box-sizing: border-box;

  h6 {
    text-align: center;
    color: #666;
    flex: 0 0 100%;
    font-size: 14px;
    border-bottom: 2px solid #fafafa;
    padding-bottom: 10px;
    margin-bottom: 0;
  }

  div {
    background: #fff;
    flex: calc(100% / 6);
    max-width: 200px;
    border: 1px solid #e9e9e9;
    border-radius: 6px;
    padding: 8px;
    box-sizing: border-box;
    transition: 0.2s all ease-in-out;

    &:hover {
      box-shadow: 3px 3px 0 rgba(0, 0, 0, 0.1);
      transform: translate(-3px, -3px);
    }

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 6px;

      span {
        text-align: center;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }
}

.tutoring-list {
  display: flex;
  flex-direction: column;
  row-gap: 32px;
  margin: 0;
  padding: 0;

  li {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    border-bottom: 2px solid #e6e6e6;
    padding-bottom: 32px;
    list-style: none;

    &:last-child {
      border-bottom: 0;
      padding-bottom: 0;
    }

    time {
      font-weight: 400;
      font-size: 14px;
      color: #666666;
    }

    p {
      margin-bottom: 0;
    }

    .btn-group {
      gap: 10px;
      padding-top: 10px;

      button {
        font-weight: 600;
        font-size: 16px;
        color: $purple;
        background-color: transparent;
        border: 0;
      }
    }
  }
}

#modal-register {
  .modal-dialog {
    max-width: 700px;
  }

  .modal-header {
    border: 0;
    position: relative;

    h5 {
      font-size: 24px;
    }

    .close {
      font-size: 16px;
      color: #fff;
      background: #b3b3b3;
      width: 24px;
      height: 24px;
      border-radius: 50px;
      padding: 0;
      position: absolute;
      top: 32px;
      right: 32px;
    }
  }
}

form {
  text-align: left;

  .box-ckeckbox {
    background: #ffffff;
    height: 58px;
    border: 1px solid #b3b3b3;
    border-radius: 0.25rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.375rem 0.75rem;
    box-sizing: border-box;
    cursor: pointer;

    .custom-control-input:checked~.custom-control-label {
      &::before {
        background: $purple;
      }

      &::after {
        background: $white;
        left: -35px !important;
        opacity: 1;
      }
    }

    .custom-switch {
      .custom-control-label {
        &::before {
          background: transparent;
          width: 59px;
          height: 26px;
          border-color: $purple;
          border-radius: 100px;
          left: -3.75rem;
        }

        &::after {
          background: $purple;
          width: 18px;
          height: 18px;
          top: 8px;
          left: -54px;
          opacity: 0.5;
        }
      }
    }
  }
}

.wrap-btn-form {
  width: 100%;
  border-bottom: 1px solid #b3b3b3;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 24px 0;
  margin-bottom: 24px;
  box-sizing: border-box;

  button {
    width: 146px;
  }
}

.informative {
  text-align: left;

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: #666666;
    margin-bottom: 10px;
  }
}

.rda-tabs {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  margin-bottom: 40px;

  .tab {
    background: linear-gradient(180deg, #fff -16%, #fff 113%);
    flex: 0 0 260px;
    height: 94px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 8;
    transition: 0.2s all;
    cursor: pointer;

    &:hover {
      box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
    }

    &.active {
      background: linear-gradient(180deg, #ef6549 -16%, #b23066 113%);

      b,
      span {
        color: #fff;
      }
    }

    b {
      font-size: 16px;
      color: #666666;
    }

    span {
      font-size: 14px;
      color: #666666;
    }
  }
}

.rda-tab-content {
  padding-left: 0;

  &:last-child {
    .progress-rda {
      .lines {
        height: 100%;
        top: 0;
      }
    }
  }
}

.progress-rda {
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  position: relative;
  overflow: visible !important;

  &:after {
    content: "";
    background: #f2f2f2;
    width: 100%;
    height: 80px;
    border-radius: 0 10px 10px 0;
    position: absolute;
    top: 0;
    left: 0px;
    z-index: 0;
  }

  p {
    font-size: 18px;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 16px;
    transform: translateY(-50%);
    z-index: 2;
  }

  .bar {
    background: #d8c7ee;
    height: 100%;
    border-radius: 0 10px 10px 0;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;

    &.artes-visuais {
      background: $artes-visuais;
    }

    &.lingua-estrangeira {
      background: $lingua-estrangeira;
    }

    &.ciencias-da-natureza {
      background: $ciencias-da-natureza;
    }

    &.ciencias-humanas {
      background: $ciencias-humanas;
    }

    &.corpo-e-mente {
      background: $corpo-e-mente;
    }

    &.danca {
      background: $danca;
    }

    &.historia {
      background: $historia;
    }

    &.lingua-portuguesa {
      background: $lingua-portuguesa;
    }

    &.matematica {
      background: $matematica;
    }

    &.espanhol {
      background: $espanhol;
    }

    &.musica {
      background: $musica;
    }

    &.cidadania-vida {
      background: $cidadania-vida;
    }

    &.teatro {
      background: $teatro;
    }

    &.ingles {
      background: $ingles;
    }
  }

  .lines {
    width: 1px;
    height: 116%;
    display: block;
    border-left: 1px dashed #b3b3b3;
    position: relative;
    top: 6px;
    z-index: 2;
  }
}

.viewButton {
  background: transparent;
  border: 0;

  svg {
    width: 20px;
  }
}

.tags {
  &.warning {
    background-color: var(--orange);
  }
}

.back-rda {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  margin-bottom: 40px;
  cursor: pointer;

  svg {
    transform: rotate(180deg);
    height: 18px;
  }

  span {
    font-size: 18px;
    font-weight: bold;
    color: #4f4f4f;
    position: relative;
    top: 2px;
  }
}

.title-viewRDA {
  background: linear-gradient(270deg, #6883c5 0%, #36b67d 100%);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  margin-bottom: 40px;

  b {
    color: #fff;
    font-weight: bold;
  }

  span {
    color: #fff;
  }
}

.rda-viewlist {
  margin-bottom: 30px;

  .title {
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: #36b67d;
    margin-bottom: 12px;
  }

  .item-rda-viewlist {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;

    .cols {
      .tags {
        min-width: 40px;
      }
    }

    .text-col {
      flex: 1;
    }
  }
}

.activePeriodEmpty {
  border: dashed #d1c8c8 0.5px !important;
  background-color: #fafafa !important;
}
</style>
