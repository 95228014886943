<template>
  <div class="home">
    <template>
      <b-container class="container-rda sidebar">
        <b-row class="height">
          <sidebar-coordination />
          <b-col class="content">
            <b-row>
              <b-col>
                <b-breadcrumb :items="breadcrumb"></b-breadcrumb>
              </b-col>
            </b-row>
            <b-row class="mb-5">
              <b-col>
                <h2 class="purple bold">Gerenciamento de turmas</h2>
              </b-col>
              <b-col
                class="text-right"
                v-if="
                  calendar &&
                  nextCalendar.designation !== selectedCalendar.designation
                "
              >
                <b-button
                  style="font-size: 19px"
                  class="btn large blue"
                  @click="duplicateClassrooms(nextCalendar.id)"
                  >Duplicar turmas de {{ selectedCalendar.designation }} para
                  {{ nextCalendar.designation }}</b-button
                >
              </b-col>
              <b-col class="text-right">
                <b-button
                  class="btn large blue"
                  :to="{
                    name: 'nova-turma',
                    params: {
                      cycle: this.cycle,
                      page: this.page,
                    },
                  }"
                  >Registrar nova turma</b-button
                >
              </b-col>
            </b-row>
            <b-row>
              <b-col md="4">
                <SelectCycles v-model="cycle" :callback="callbackCycles" />
                <span
                  class="clear-field"
                  v-if="this.cycle != null"
                  @click="clearCycle"
                  >Limpar filtro</span
                >
              </b-col>
              <b-col md="4">
                <SelectCalendar
                  label="Ano"
                  advice="Para duplicar turmas, selecione um ano."
                  v-model="calendar"
                  :callback="callbackCalendar"
                />
                <span
                  class="clear-field"
                  v-if="this.calendar != null"
                  @click="clearCalendar"
                  >Limpar filtro</span
                >
              </b-col>
              <b-col>
                <b-form-group label="Buscar">
                  <b-input-group-prepend>
                    <b-form-input
                      type="search"
                      placeholder="Digite aqui"
                      v-model="searchTitle"
                      v-on:keyup.enter="
                        page = 1;
                        getList();
                      "
                    >
                    </b-form-input>
                    <b-input-group-text
                      class="clear-fields"
                      v-if="searchTitle != '' || cycle != null"
                      v-b-tooltip.hover
                      title="Limpar Pesquisa"
                    >
                      <b-icon icon="x" @click="clearFields" />
                    </b-input-group-text>
                  </b-input-group-prepend>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <div class="order-grid-box">
                  <p
                    class="order-list"
                    :class="{ desc: descOrder }"
                    v-on:click="orderName"
                  >
                    Ordenar
                    <OrderNameIcon />
                  </p>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-container fluid>
                  <b-row
                    class="header-list-teacher"
                    v-if="classrooms.length > 0 && isRequestSend"
                  >
                    <b-col md="4">
                      <p class="bold">Designação</p>
                    </b-col>
                    <b-col md="2">
                      <p class="bold">Horário</p>
                    </b-col>
                    <b-col md="2">
                      <p class="bold">Contraturno</p>
                    </b-col>
                    <b-col>
                      <p class="bold">Ciclo</p>
                    </b-col>
                    <b-col>
                      <p class="bold">Ativo</p>
                    </b-col>
                    <b-col>
                      <p class="bold">Editar</p>
                    </b-col>
                    <b-col>
                      <p class="bold">Apagar</p>
                    </b-col>
                  </b-row>
                </b-container>
                <b-container fluid>
                  <b-row
                    align-v="center"
                    class="list-teacher"
                    v-for="classroom in orderbyName"
                    v-bind:key="classroom.id"
                  >
                    <b-col md="4">
                      <p>{{ classroom.designation }}</p>
                    </b-col>

                    <b-col md="2" v-if="classroom.start_at && classroom.end_at">
                      <p>{{ classroom.start_at }} - {{ classroom.end_at }}</p>
                    </b-col>
                    <b-col md="2" v-else>
                      <p>----</p>
                    </b-col>

                    <b-col
                      md="2"
                      v-if="
                        Array.isArray(classroom.ClassroomAfterHours) &&
                        classroom.ClassroomAfterHours.length > 0
                      "
                    >
                      <p>
                        Sim
                      </p>
                    </b-col>
                    <b-col md="2" v-else>
                      <p>Não</p>
                    </b-col>

                    <b-col>
                      <p class="tags">{{ classroom.Cycle.name }}</p>
                    </b-col>

                    <b-col class="status">
                      <b-form-checkbox
                        switch
                        v-if="classroom.is_editable"
                        v-model="classroom.is_editable"
                        @input="updateStatus(classroom.id, false)"
                      ></b-form-checkbox>
                      <b-form-checkbox
                        switch
                        v-else
                        v-model="classroom.is_editable"
                        @input="updateStatus(classroom.id, true)"
                      ></b-form-checkbox>
                    </b-col>
                    <b-col>
                      <router-link
                        :to="{
                          name: 'perfil-turma',
                          params: { id: classroom.id },
                        }"
                        ><EditIcon
                      /></router-link>
                    </b-col>
                    <b-col>
                      <a v-on:click.prevent="exclude(classroom.id)">
                        <DeleteIcon />
                      </a>
                    </b-col>
                  </b-row>
                  <b-row
                    align-v="center"
                    class="list-teacher"
                    v-if="classrooms.length <= 0 && isRequestSend"
                  >
                    <b-col> Nenhum resultado encontrado </b-col>
                  </b-row>
                  <b-row
                    align-v="center"
                    class="list-teacher"
                    v-if="!isRequestSend"
                  >
                    <b-col> Carregando </b-col>
                  </b-row>
                  <b-row
                    class="align-center"
                    v-else-if="count > perPage && isRequestSend"
                  >
                    <b-pagination
                      v-model="page"
                      :per-page="perPage"
                      :total-rows="count"
                      @change="changePage"
                      aria-controls="teachersList"
                      last-text=""
                      first-text=""
                    >
                      <template #prev-text
                        ><LeftArrowIcon class="left"
                      /></template>
                      <template #next-text><LeftArrowIcon /></template>
                    </b-pagination>
                  </b-row>
                </b-container>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import DataService from "@/services/classrooms.service";
import httpRda from "../../../http-rda";
import SelectCycles from "@/components/inputs/SelectCycles.vue";
import SelectCalendar from "@/components/inputs/SelectCalendar.vue";
import OrderNameIcon from "@/components/svg/OrderNameIcon.vue";
import LeftArrowIcon from "@/components/svg/LeftArrowIcon.vue";
import EditIcon from "@/components/svg/EditIcon.vue";
import DeleteIcon from "@/components/svg/DeleteIcon.vue";
import SidebarCoordination from "@/components/SidebarCoordination.vue";

export default {
  name: "turmas",
  components: {
    SelectCycles,
    SelectCalendar,
    OrderNameIcon,
    LeftArrowIcon,
    EditIcon,
    DeleteIcon,
    SidebarCoordination,
  },

  data() {
    return {
      classrooms: [],
      descOrder: false,
      isRequestSend: false,

      cycle: this.$route.params.cycle,
      calendar: null,
      nextCalendar: null,
      searchTitle: "",

      page: this.$route.params.page || 1,
      count: 0,
      perPage: 20,

      selectedCalendar: null,

      breadcrumb: [
        {
          text: "Secretaria",
          href: "/secretaria",
        },
        {
          text: "Alterações internas",
          active: true,
        },
        {
          text: "Turmas",
          href: "/turmas",
          active: true,
        },
      ],
    };
  },

  methods: {
    async duplicateClassrooms(nextCalendarId) {
      this.$swal
        .fire({
          title: `Deseja duplicar as turmas filtradas de ${this.selectedCalendar.designation} para ${this.nextCalendar.designation}?`,
          text: "Todas as turmas filtradas atualmente serão duplicadas!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#68c5b7",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim",
          cancelButtonText: "Cancelar",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            try {
              await httpRda.post("/classrooms/bulk", {
                classrooms: this.orderbyName,
                calendar_id: nextCalendarId,
              });
              this.$swal.fire({
                title: "Sucesso",
                text: "Turmas duplicadas com sucesso!",
                icon: "success",
                showConfirmButton: false,
                timer: 1500,
              });
              this.clearFields();
              this.getList();
            } catch (error) {
              this.$swal.fire({
                icon: "error",
                title: "Oops...",
                text: error.response.data.message,
                footer: "Por favor, tente novamente mais tarde",
              });

              return error.response;
            }
          }
        })
        .catch((error) => {
          this.$swal.fire({
            icon: "error",
            title: "Oops...",
            text: error.response.data.message,
            footer: "Por favor, tente novamente mais tarde.",
          });

          return error.response;
        });
    },
    async getCalendars() {
      this.nextCalendar = await httpRda
        .get("/calendars?order=desc")
        .then((response) => response.data.calendars[0]);
    },
    getRequestParams(searchTitle, page, perPage, cycle, calendar) {
      let params = {};

      if (searchTitle) {
        params["search"] = searchTitle;
      }

      if (page) {
        params["page"] = page;
      }

      if (perPage) {
        params["limit"] = perPage;
      }

      if (cycle) {
        params["cycle_id"] = cycle;
      }

      if (calendar) {
        params["calendar_id"] = calendar;
      }

      return params;
    },

    getList() {
      const params = this.getRequestParams(
        this.searchTitle,
        this.page,
        this.perPage,
        this.cycle,
        this.calendar
      );

      DataService.getAll(params)
        .then((response) => {
          this.classrooms = response.data.classrooms;
          this.count = response.data.count;
          this.isRequestSend = true;
        })
        .catch((error) => {
          return error;
        });
    },

    exclude(id) {
      this.$swal
        .fire({
          title: "Tem certeza?",
          text: "Você não poderá reverter isso!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#68c5b7",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim, excluir!",
          cancelButtonText: "Cancelar",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            try {
              await DataService.delete(id);
              this.$swal
                .fire("Excluído!", "Turma foi excluída.", "success")
                .then((response) => {
                  this.getList();
                  return response.data;
                });
            } catch (error) {
              this.$swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Não foi possivel excluir!",
                footer: "Por favor, tente novamente mais tarde.",
              });

              return error.response;
            }
          }
        })
        .catch((error) => {
          this.$swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Não foi possivel excluir!",
            footer: "Por favor, tente novamente mais tarde.",
          });

          return error.response;
        });
    },

    orderName() {
      this.descOrder = !this.descOrder;
    },

    callbackCycles({ id }) {
      this.cycle = id !== -1 ? id : null;
      this.page = 1;
      this.getList();
    },

    callbackCalendar(calendar) {
      this.selectedCalendar = {
        id: calendar.id,
        designation: calendar.designation,
      };
      this.calendar = calendar.id !== -1 ? calendar.id : null;
      this.page = 1;
      this.getList();
    },

    changePage(page) {
      this.page = page;
      this.getList();
      window.scrollTo({ top: 0, behavior: "smooth" });
    },

    clearCalendar() {
      this.calendar = null;
      this.getList({ calendar_id: this.calendar });
    },

    clearFields(searchTitle, cycle, page) {
      this.searchTitle = "";
      this.cycle = null;
      this.calendar = null;
      this.page = 1;
      this.getList({ search: searchTitle, cycle_id: cycle, page: page });
    },

    updateStatus(id, status) {
      var data = {
        id: id,
        is_editable: status,
      };
      DataService.update(id, data)
        .then((response) => {
          const Toast = this.$swal.mixin({
            toast: true,
            position: "bottom-end",
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer);
              toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "success",
            title: "Status atualizado com sucesso!",
          });

          return response.data;
        })
        .catch((error) => {
          this.$swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Não foi possivel atualizar!",
            footer: "Por favor, tente novamente mais tarde.",
          });

          return error.response;
        });
    },
  },

  created() {
    this.getCalendars();
    if (this.$route.params.page) {
      this.getList({
        page: this.$route.params.page,
        limit: this.perPage,
        cycle_id: this.cycle,
      });
    } else if (!this.$route.params.page) {
      this.getList();
    }
  },

  computed: {
    ...mapGetters({
      gettersAuthData: "auth/getAuthData",
    }),
    orderbyName() {
      return [...this.classrooms].sort((a, b) => {
        const order = this.descOrder ? [1, -1] : [-1, 1];

        if (a.designation < b.designation) return order[0];
        else if (a.designation > b.designation) return order[1];

        return 0;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/variables.scss";

.home {
  height: 100%;
}

.container-rda {
  padding-bottom: 0;
}

.tags {
  background-color: $blue;
  border-radius: 5px;
  color: $white;
  font-weight: $bold;
  line-height: 28px;
  margin: 3px 12px 3px 0;
  max-width: 58px;
  padding: 0 3px;
  text-align: center;
  vertical-align: middle;
  width: 100%;
}
</style>
