<template>
  <div class="home">
    <template>
      <b-container class="container-rda">
        <b-row>
          <b-col>
            <b-breadcrumb :items="breadcrumb"></b-breadcrumb>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <h2 class="purple bold">Painel de Avaliações</h2>
          </b-col>
          <b-col class="text-right"></b-col>
        </b-row>
        <div style="position: relative; z-index: 10">
          <b-row class="mt-50">
            <b-col>
              <b-form-group label="Buscar pelo nome do aluno">
                <b-input-group-prepend>
                  <b-form-input
                    type="search"
                    placeholder="Digite aqui"
                    v-model="studentName"
                    v-on:keyup.enter="getList()"
                  ></b-form-input>
                  <b-input-group-text
                    class="clear-fields"
                    v-if="studentName != null"
                    v-b-tooltip.hover
                    title="Limpar Pesquisa"
                  >
                    <b-icon icon="x" @click="clearFields" />
                  </b-input-group-text>
                </b-input-group-prepend>
              </b-form-group>
            </b-col>
            <b-col>
              <SelectCycles
                v-model="cycle"
                :callback="callbackCycles"
              ></SelectCycles>
              <span
                class="clear-field"
                v-if="this.cycle != null"
                @click="clearCycle"
                >Limpar filtro</span
              >
            </b-col>
            <b-col md="4">
              <SelectSubjectsWithCycle
                v-model="subject"
                :callback="callbackSubjects"
              />
              <span
                class="clear-field"
                v-if="this.subject != null"
                @click="clearSubject"
                >Limpar filtro</span
              >
            </b-col>
          </b-row>
        </div>

        <b-row style="position: relative; z-index: 0">
          <b-col>
            <b-row class="column-list">
              <b-col class="evaluation-flex">
                <p class="bold">Pedidos de avaliação</p>
                <span class="tags_count">{{ countEvaluation }}</span>
              </b-col>
            </b-row>
            <div
              class="accordion list cycles-discipline-tutors"
              ref=""
              role="tablist"
            >
              <b-card
                no-body
                class="list-cycles-discipline-tutors"
                v-for="(evaluation, index) in evaluations"
                :key="index"
              >
                <b-card-header
                  header-tag="header"
                  role="tab"
                  style="
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    margin-bottom: 10px;
                  "
                >
                  <router-link
                    :to="{
                      name: 'registrar-aprendizagens',
                      params: {
                        id: evaluation.student_id,
                        subject_id: evaluation.subject_id,
                      },
                    }"
                    target="_blank"
                    style="
                      display: flex;
                      padding: 0px;
                      margin: 0px;
                      line-height: 1;
                      text-decoration: none;
                      color: inherit;
                      align-items: center;
                      flex-direction: column;
                    "
                  >
                    <a v-b-toggle="'cycle-' + index">
                      <p>{{ evaluation.student_name }}</p>
                    </a>
                    <p class="tag">
                      {{ evaluation.subject_designation }}
                    </p>
                  </router-link>
                </b-card-header>
              </b-card>
              <b-card
                no-body
                class="list-cycles-discipline-tutors"
                v-if="!isRequestSend"
              >
                <b-card-header header-tag="header" class role="tab">
                  <b-row align="center">
                    <b-col>
                      <b-spinner
                        id="spinner"
                        variant="success"
                        label="Carregando..."
                      ></b-spinner>
                    </b-col>
                  </b-row>
                </b-card-header>
              </b-card>
              <b-card
                no-body
                class="list-cycles-discipline-tutors"
                v-if="evaluations.length <= 0 && isRequestSend"
              >
                <b-card-header header-tag="header" class role="tab">
                  <a v-b-toggle>
                    <p>Nenhum resultado encontrado</p>
                  </a>
                </b-card-header>
              </b-card>
              <b-row class="align-center" v-if="evaluations && isRequestSend">
                <b-pagination
                  v-model="currentPageEvaluation"
                  :per-page="perPageEvaluation"
                  :total-rows="countEvaluation"
                  aria-controls="teachersList"
                  last-text
                  first-text
                >
                  <template #prev-text>
                    <LeftArrowIcon class="left" />
                  </template>
                  <template #next-text>
                    <LeftArrowIcon />
                  </template>
                </b-pagination>
              </b-row>
            </div>
          </b-col>
          <b-col>
            <b-row class="column-list">
              <b-col class="evaluation-flex">
                <p class="bold">Alunos com AN</p>
                <span class="tags_count">{{ countStatusAn }}</span>
              </b-col>
            </b-row>
            <div class="accordion list cycles-discipline-tutors" role="tablist">
              <b-card
                no-body
                class="list-cycles-discipline-tutors"
                v-for="(student, index) in studentsWithAn"
                :key="index"
              >
                <b-card-header
                  header-tag="header"
                  style="
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    margin-bottom: 10px;
                  "
                  role="tab"
                >
                  <router-link
                    :to="{
                      name: 'registrar-aprendizagens',
                      params: {
                        id: student.student_id,
                        subject_id: student.subject_id,
                      },
                    }"
                    target="_blank"
                    style="
                      display: flex;
                      padding: 0px;
                      margin: 0px;
                      line-height: 1;
                      text-decoration: none;
                      color: inherit;
                      align-items: center;
                      flex-direction: column;
                    "
                  >
                    <a v-b-toggle="'cycle-' + index">
                      <p>{{ student.student_name }}</p>
                    </a>
                    <p class="tag">
                      {{ student.subject_designation }}
                    </p>
                  </router-link>
                </b-card-header>
              </b-card>
              <b-card
                no-body
                class="list-cycles-discipline-tutors"
                v-if="!isRequestSend"
              >
                <b-card-header header-tag="header" class role="tab">
                  <b-row align="center">
                    <b-col>
                      <b-spinner
                        id="spinner"
                        variant="success"
                        label="Carregando..."
                      ></b-spinner>
                    </b-col>
                  </b-row>
                </b-card-header>
              </b-card>
              <b-card
                no-body
                class="list-cycles-discipline-tutors"
                v-if="studentsWithAn.length <= 0 && isRequestSend"
              >
                <b-card-header header-tag="header" class role="tab">
                  <a v-b-toggle>
                    <p>Nenhum resultado encontrado</p>
                  </a>
                </b-card-header>
              </b-card>
              <b-row
                class="align-center"
                v-if="studentsWithAn && isRequestSend"
              >
                <b-pagination
                  v-model="currentPageStatusAn"
                  :per-page="perPageStatusAn"
                  :total-rows="countStatusAn"
                  aria-controls="teachersList"
                  last-text
                  first-text
                >
                  <template #prev-text>
                    <LeftArrowIcon class="left" />
                  </template>
                  <template #next-text>
                    <LeftArrowIcon />
                  </template>
                </b-pagination>
              </b-row>
            </div>
          </b-col>
          <b-col>
            <b-row class="column-list">
              <b-col class="evaluation-flex">
                <p class="bold">Alunos com EE > 25 dias</p>
                <span class="tags_count">{{ countStatusEe }}</span>
              </b-col>
            </b-row>
            <div class="accordion list cycles-discipline-tutors" role="tablist">
              <b-card
                no-body
                class="list-cycles-discipline-tutors"
                v-for="(student, index) in studentsWithEE"
                :key="index"
              >
                <b-card-header
                  header-tag="header"
                  style="
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    margin-bottom: 10px;
                  "
                  role="tab"
                >
                  <router-link
                    :to="{
                      name: 'registrar-aprendizagens',
                      params: {
                        id: student.student_id,
                        subject_id: student.subject_id,
                      },
                    }"
                    target="_blank"
                    style="
                      display: flex;
                      padding: 0px;
                      margin: 0px;
                      line-height: 1;
                      text-decoration: none;
                      color: inherit;
                      align-items: center;
                      flex-direction: column;
                    "
                  >
                    <a v-b-toggle="'cycle-' + index">
                      <p>{{ student.student_name }}</p>
                    </a>
                    <p class="tag">
                      {{ student.subject_designation }}
                    </p>
                  </router-link>
                </b-card-header>
              </b-card>
              <b-card
                no-body
                class="list-cycles-discipline-tutors"
                v-if="!isRequestSend"
              >
                <b-card-header header-tag="header" class role="tab">
                  <b-row align="center">
                    <b-col>
                      <b-spinner
                        id="spinner"
                        variant="success"
                        label="Carregando..."
                      ></b-spinner>
                    </b-col>
                  </b-row>
                </b-card-header>
              </b-card>
              <b-card
                no-body
                class="list-cycles-discipline-tutors"
                v-if="studentsWithEE.length <= 0 && isRequestSend"
              >
                <b-card-header header-tag="header" class role="tab">
                  <a v-b-toggle>
                    <p>Nenhum resultado encontrado</p>
                  </a>
                </b-card-header>
              </b-card>
              <b-row
                class="align-center"
                v-if="studentsWithEE && isRequestSend"
              >
                <b-pagination
                  v-model="currentPageStatusEe"
                  :per-page="perPageStatusEe"
                  :total-rows="countStatusEe"
                  aria-controls="teachersList"
                  last-text
                  first-text
                >
                  <template #prev-text>
                    <LeftArrowIcon class="left" />
                  </template>
                  <template #next-text>
                    <LeftArrowIcon />
                  </template>
                </b-pagination>
              </b-row>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </template>
  </div>
</template>

<script>
import httpRda from "../../http-rda";
import LeftArrowIcon from "@/components/svg/LeftArrowIcon.vue";
import SelectCycles from "@/components/inputs/SelectCycles.vue";
import SelectSubjectsWithCycle from "@/components/inputs/SelectSubjectsWithCycle.vue";

export default {
  name: "painel-de-avaliacoes",
  components: { LeftArrowIcon, SelectCycles, SelectSubjectsWithCycle },
  data() {
    return {
      id: "",
      evaluations: [],
      studentsWithAn: [],
      studentsWithEE: [],

      studentName: null,
      cycle: null,
      cycleQuery: null,
      cycle_select: null,

      subject: null,
      subjectQuery: null,

      currentPageEvaluation: 1,
      countEvaluation: 0,
      perPageEvaluation: 10,

      currentPageStatusAn: 1,
      countStatusAn: 0,
      perPageStatusAn: 10,

      currentPageStatusEe: 1,
      countStatusEe: 0,
      perPageStatusEe: 10,

      isRequestSend: false,
      searchTitle: "",

      breadcrumb: [
        {
          text: "Aprendizagem",
          href: "/aprendizagem",
        },
        {
          text: "Painel de Avaliações",
          href: "/painel-de-avaliacoes",
          active: true,
        },
      ],
    };
  },
  methods: {
    clearFields() {
      this.studentName = "";
      this.getList();
    },

    setCycle(id, name) {
      this.cycle = id !== -1 ? id : null;
      this.cycle_select = id;
      this.cycleQuery = name;
    },

    callbackSubjects({ id, Cycle, designation }) {
      this.subject = id;
      this.subjectQuery = designation;
      this.setCycle(Cycle.id, Cycle.name);
      this.getList();
      this.isRequestSend = true;
    },

    clearSubject() {
      this.isRequestSend = false;
      this.subject = null;
      this.subjectQuery = null;

      this.currentPageEvaluation = 1;
      this.currentPageStatusAn = 1;
      this.currentPageStatusEe = 1;

      this.getList();
    },

    callbackCycles({ id, name }) {
      this.subject = null;
      this.subjectQuery = null;
      this.setCycle(id, name);
      this.currentPageEvaluation = 1;
      this.currentPageStatusAn = 1;
      this.currentPageStatusEe = 1;
      this.getList();
    },

    clearCycle() {
      this.currentPageEvaluation = 1;
      this.currentPageStatusAn = 1;
      this.currentPageStatusEe = 1;
      this.cycle = null;
      this.cycle_select = null;
      this.cycleQuery = null;
      this.getList();
    },

    async getEvaluations(queryParams) {
      await httpRda
        .get("/evaluations", { params: queryParams })
        .then((resp) => {
          this.evaluations = resp.data.data.students;

          this.countEvaluation = resp.data.data.count;
        });
    },

    async getEvaluationsWithStatus(queryParams) {
      await httpRda
        .get("/evaluations-with-status", { params: queryParams })
        .then((resp) => {
          this.studentsWithAn = resp.data.data.students;

          this.countStatusAn = resp.data.data.count;
        });
    },

    async getEvaluationsWithStatusEE(queryParams) {
      await httpRda
        .get("/evaluations-with-status-ee", { params: queryParams })
        .then((resp) => {
          this.studentsWithEE = resp.data.data.students;

          this.countStatusEe = resp.data.data.count;
        });
    },

    async getList() {
      this.isRequestSend = false;

      let queryParams = {};

      if (this.studentName) {
        queryParams.name = this.studentName;
      }

      if (this.cycleQuery) {
        queryParams.cycle = this.cycleQuery;
      }

      if (this.subjectQuery) {
        queryParams.subject = this.subjectQuery;
      }

      if (
        this.currentPageEvaluation !== undefined &&
        this.currentPageEvaluation !== null
      ) {
        queryParams.page = this.currentPageEvaluation;
      }

      await this.getEvaluations(queryParams);

      queryParams.page = null;

      if (
        this.currentPageStatusAn !== undefined &&
        this.currentPageStatusAn !== null
      ) {
        queryParams.page = this.currentPageStatusAn;
      }

      await this.getEvaluationsWithStatus(queryParams);

      queryParams.page = null;

      if (
        this.currentPageStatusEe !== undefined &&
        this.currentPageStatusEe !== null
      ) {
        queryParams.page = this.currentPageStatusEe;
      }

      await this.getEvaluationsWithStatusEE(queryParams);

      this.isRequestSend = true;
    },
  },

  mounted() {
    this.getList();
  },

  watch: {
    currentPageEvaluation() {
      this.getEvaluations({
        name: this.studentName,
        page: this.currentPageEvaluation,
        cycle: this.cycleQuery,
      });
    },
    currentPageStatusAn() {
      this.getEvaluationsWithStatus({
        name: this.studentName,
        page: this.currentPageStatusAn,
        cycle: this.cycleQuery,
      });
    },
    currentPageStatusEe() {
      this.getEvaluationsWithStatusEE({
        name: this.studentName,
        page: this.currentPageStatusEe,
        cycle: this.cycleQuery,
      });
    },
  },
  computed: {},
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/variables.scss";
.tablist {
  overflow-x: hidden !important;
}

#spinner {
  margin-top: 25px;
}

.evaluation-flex {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  margin-bottom: 10px;
}

.tag {
  background-color: #68c5b7;
  border-radius: 5px;
  color: #ffffff;
  font-weight: 700;
  line-height: 28px;
  margin: 3px 12px 3px 0;
  padding: 0 3px;
  padding-left: 8px;
  padding-right: 8px;
  text-align: center;
  vertical-align: middle;
}

.list.cycles-discipline-tutors {
  height: 650px;
  overflow-x: hidden !important;
  overflow-y: auto !important;
}
.list-cycles-discipline-tutors {
  background-color: $white;
  border: 1px solid $grey5;
  border-radius: 10px;
  margin-bottom: 15px;
  margin-right: 12px;
  &.card:not(:first-of-type) {
    border-radius: 10px;
  }
  &.card:not(:last-of-type) {
    border: 1px solid $grey5;
    border-radius: 10px;
    &:hover {
      cursor: pointer;
      border-color: transparent;
      background-image: linear-gradient($white, $white),
        radial-gradient(circle at top left, $orange, $purple);
      background-origin: border-box;
      background-clip: content-box, border-box;
    }
  }
  &:last-child {
    margin-bottom: 0px;
  }
  &:hover {
    cursor: pointer;
    border-color: transparent;
    background-image: linear-gradient($white, $white),
      radial-gradient(circle at top left, $orange, $purple);
    background-origin: border-box;
    background-clip: content-box, border-box;
  }
  p {
    margin: 0;
  }
  .qty {
    background-color: $blue;
    border-radius: 5px;
    color: $white;
    font-weight: $bold;
    line-height: 26px;
    max-width: 52px;
    text-align: center;
    width: 100%;
  }
  .card-header {
    background-color: $white;
    border: 0;
    padding: 0;
    a {
      align-items: center;
      display: flex;
      justify-content: space-between;
      padding: 26px 20px;
    }
  }
  .card-body {
    padding: 0 20px;
    .names {
      border-bottom: 1px solid $grey5;
      display: flex;
      justify-content: space-between;
      padding: 20px 0;
      &:last-child {
        border-bottom: 0;
      }
      .two-icons {
        a {
          margin: 0 15px;
        }
      }
    }
  }
}

.list {
  &.cycles-discipline-tutors {
    height: 650px;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 6px;
      border-radius: 5px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
      border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background: $grey5;
      border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: $purple;
    }
  }
}

.column-list {
  color: $grey1;
  margin: 30px 0 0;
  p {
    margin: 0 0 5px 0;
  }
}
</style>
