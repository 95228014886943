import axios from "axios";

import { decode } from "jsonwebtoken";

import { jwtDecrypt, tokenAlive } from "../services/jwtHelper";

import AuthService from "../services/auth.service";

export const auth = {
  namespaced: true,
  state: {
    user: {},
    authData: {
      id: "",
      name: "",
      email: "",
      photo: "",
      role: "",
      parent_id: "",
      responsible_id: "",
      student_id: "",
      teacher_id: "",
    },
    loginStatus: "",
  },

  actions: {
    async login({ commit }, user) {
      const response = await axios
        .post(`${process.env.VUE_APP_URL}log_in`, user)
        .catch((error) => {
          return error;
        });
      if (response && response.data) {
        commit("saveTokenData", response.data);
        commit("setLoginStatus", "success");
        commit("loginSuccess", user);
      } else {
        commit("setLoginStatus", "failed");
        commit("RESET_USER");
      }
      return response;
    },

    logout({ commit }) {
      AuthService.logout();
      commit("RESET_USER");
    },

    register({ commit }, user) {
      return AuthService.register(user).then(
        (response) => {
          commit("RESET_USER");
          return Promise.resolve(response.data);
        },
        (error) => {
          commit("RESET_USER");
          return Promise.reject(error);
        }
      );
    },
  },

  mutations: {
    loginSuccess(state, user) {
      state.user = decode(user.access_token);
    },

    RESET_USER(state) {
      state.authData = {};
    },

    setUserByToken(state, accessToken) {
      state.user = decode(accessToken);
    },

    saveTokenData(state, data) {
      localStorage.setItem("access_token", data.access_token);
      localStorage.setItem("refresh_token", data.refresh_token);

      const jwtDecodedValue = jwtDecrypt(data.access_token);

      const newTokenData = {
        token: data.access_token,
        refreshToken: data.refresh_token,
        tokenExp: jwtDecodedValue.exp,
        tokenIat: jwtDecodedValue.iat,
        id: jwtDecodedValue.id,
        name: jwtDecodedValue.name,
        email: jwtDecodedValue.email,
        photo: jwtDecodedValue.photo,
        role: jwtDecodedValue.role,
        parent_id: jwtDecodedValue.parent_id,
        responsible_id: jwtDecodedValue.responsible_id,
        student_id: jwtDecodedValue.student_id,
        teacher_id: jwtDecodedValue.teacher_id,
      };
      state.authData = newTokenData;
    },

    setLoginStatus(state, value) {
      state.loginStatus = value;
    },
  },

  getters: {
    user(state) {
      return state.user;
    },
    getLoginStatus(state) {
      return state.loginStatus;
    },
    getAuthData(state) {
      return state.authData;
    },
    isTokenActive(state) {
      if (!state.authData.tokenExp) {
        return false;
      }
      return tokenAlive(state.authData.tokenExp);
    },
  },
};
