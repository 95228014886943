import http from "../http-rda";

class DataService {
  getAll(params) {
    return http.get("/subjects?include=cycles", { params });
  }

  get(id) {
    return http.get(`/subjects/${id}`);
  }

  create(data) {
    return http.post("/subjects", data);
  }

  update(id, data) {
    return http.patch(`/subjects/${id}`, data);
  }

  delete(id) {
    return http.delete(`/subjects/${id}`);
  }

  findByTitle(designation) {
    return http.get(`/subjects?search=${designation}`);
  }

}

export default new DataService();