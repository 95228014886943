import http from "../http-rda";

class DataService {
  getAll(params) {
    return http.get("/topics?include=competences", { params });
  }

  get(id, params) {
    return http.get(`/topics/${id}`, { params });
  }

  create(data) {
    return http.post("/topics", data);
  }

  update(id, data) {
    return http.patch(`/topics/${id}`, data);
  }

  delete(id) {
    return http.delete(`/topics/${id}`);
  }

  findByTitle(name) {
    return http.get(`/topics?search=${name}`);
  }

}

export default new DataService();