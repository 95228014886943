<template>
  <div class="container-rda">
    <h1>Política de Privacidade da Escola da Serra</h1>
    <p>
      A Escola da Serra, entidade registrada sob o CNPJ 39.985.753/0001-39,
      neste documento denominada "Instituição de Ensino", reconhece a
      importância da privacidade de informações pessoais de nossos alunos,
      responsáveis ou responsáveis legais, colaboradores, e demais partes
      interessadas. Esta Política de Privacidade descreve como coletamos, usamos
      e protegemos os dados pessoais fornecidos a nós durante o uso de nossos
      serviços educacionais.
    </p>

    <h2>1. Informações Coletadas</h2>
    <p>
      <strong>1.1. Informações de Alunos e Responsáveis:</strong> A Instituição
      de Ensino coleta informações pessoais, tais como nome, endereço, data de
      nascimento, informações acadêmicas, registros de saúde e outras
      informações relacionadas à prestação de serviços educacionais. Para os
      responsáveis legais de alunos menores de idade, também coletamos
      informações de contato.
    </p>
    <p>
      <strong>1.2. Informações de Colaboradores:</strong> Para funcionários,
      coletamos informações necessárias para fins de contratação, folha de
      pagamento e gerenciamento de recursos humanos.
    </p>
    <p>
      <strong>1.3. Informações de Visitantes do Site:</strong> Ao acessar nosso
      site, podemos coletar informações de uso, como endereço IP, tipo de
      navegador, páginas visitadas e outras informações relacionadas à
      navegação.
    </p>

    <h2>2. Uso das Informações</h2>
    <p>
      <strong>2.1. Prestação de Serviços Educacionais:</strong> As informações
      coletadas são usadas para fornecer serviços educacionais de qualidade,
      incluindo matrícula, acompanhamento do progresso do aluno, comunicações
      com responsáveis legais e suporte acadêmico.
    </p>
    <p>
      <strong>2.2. Comunicações:</strong> A Instituição de Ensino poderá
      utilizar as informações para enviar comunicações relacionadas a eventos
      escolares, atualizações, informações acadêmicas e administrativas, desde
      que haja consentimento prévio.
    </p>
    <p>
      <strong>2.3. Melhoria dos Serviços:</strong> Utilizamos informações
      coletadas para aprimorar nossos serviços e processos educacionais, com o
      objetivo de proporcionar uma experiência de ensino mais eficiente e
      eficaz.
    </p>
    <p>
      <strong>2.4. Segurança:</strong> Implementamos medidas de segurança para
      proteger as informações contra acesso não autorizado, uso indevido ou
      divulgação.
    </p>

    <h2>3. Compartilhamento de Informações</h2>
    <p>
      <strong>3.1. Parceiros Educacionais:</strong> Em determinados casos, a
      Instituição de Ensino pode compartilhar informações com parceiros
      educacionais para fins acadêmicos e administrativos, sempre respeitando a
      privacidade dos alunos.
    </p>
    <p>
      <strong>3.2. Cumprimento Legal:</strong> A Instituição de Ensino poderá
      divulgar informações pessoais quando exigido por lei ou para cumprir
      obrigações legais.
    </p>

    <h2>4. Seus Direitos</h2>
    <p>
      <strong>4.1. Acesso e Retificação:</strong> Os titulares de dados têm o
      direito de acessar suas informações pessoais e solicitar correções, se
      necessário.
    </p>
    <p>
      <strong>4.2. Exclusão de Dados:</strong> Você pode solicitar a exclusão de
      suas informações pessoais, sujeito a obrigações legais de retenção.
    </p>
    <p>
      <strong>4.3. Revogação do Consentimento:</strong> Você pode retirar seu
      consentimento para o uso de suas informações pessoais a qualquer momento.
    </p>

    <h2>5. Cookies e Tecnologias Semelhantes</h2>
    <p>
      Nosso site pode utilizar cookies e outras tecnologias para aprimorar sua
      experiência de navegação. Você pode gerenciar suas preferências de cookies
      através das configurações do seu navegador.
    </p>

    <h2>6. Menores de Idade</h2>
    <p>
      Nossos serviços educacionais são destinados a alunos menores de idade, e a
      Instituição de Ensino não coleta intencionalmente informações de menores
      sem o consentimento dos responsáveis ou responsáveis legais.
    </p>

    <h2>7. Alterações na Política de Privacidade</h2>
    <p>
      Esta Política de Privacidade pode ser atualizada periodicamente para
      refletir mudanças em nossas práticas de privacidade. A versão mais recente
      estará sempre disponível em nosso site.
    </p>

    <h2>8. Contato</h2>
    <p>
      Se você tiver alguma dúvida, preocupação ou solicitação relacionada à
      privacidade de seus dados pessoais, entre em contato conosco através do
      seguinte endereço de e-mail: comunica@escoladaserra.com.br .
    </p>

    <p>
      Ao utilizar os serviços da Escola da Serra, você concorda com os termos
      desta Política de Privacidade. É importante ler esta política atentamente
      e entrar em contato conosco caso tenha alguma dúvida ou preocupação.
    </p>
  </div>
</template>

<script>
export default {
  name: "data-deletion-request",

  created() {
    document.title = "Política de Privacidade da Escola da Serra";
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/variables.scss";

.container-rda {
  font-family: Arial, sans-serif;
  margin: 20px;
}

h1 {
  color: #333;
}
h2 {
  color: #555;
  margin-bottom: 30px;
}
p {
  color: #777;
  margin-bottom: 15px;
  line-height: 22px;
}
strong {
  color: #000;
}
</style>
