<template>
  <b-container class="container-rda">
    <b-row class="enrollment">
      <b-col>
        <h2 class="purple bold">Matrículas</h2>
      </b-col>
    </b-row>

    <b-container fluid v-if="studentsAnotherResponsible.length">
      <b-alert show variant="warning">
        <b>Atenção!</b>
        <ul class="mb-0">
          <li v-for="student in studentsAnotherResponsible" :key="student.name">
            A matrícula do(a) estudante
            <b>{{ student.name }}</b> foi feita pelo(a) usuário(a)
            <b>{{ student.responsibleName }}</b> com o login: "<b>{{
              student.email
            }}</b
            >".
          </li>
        </ul>
      </b-alert>
    </b-container>

    <b-container fluid>
      <b-row v-if="loading">
        <LoadingList v-model="loading" text="Carregando lista" :center="true" />
      </b-row>
      <b-row
        class="header-list-teacher"
        v-if="enrollments && enrollments.length >= 1 && !loading"
      >
        <b-col md="1">
          <p class="bold">Matrícula</p>
        </b-col>
        <b-col md="2">
          <p class="bold">Aluno</p>
        </b-col>
        <b-col md="2">
          <p class="bold">Contratante</p>
        </b-col>
        <b-col md="3">
          <p class="bold">Preenchido</p>
        </b-col>
        <b-col md="1">
          <p class="bold">Ano</p>
        </b-col>
      </b-row>

      <div v-if="!this.loading">
        <b-row
          v-for="enroll in enrollments"
          :key="enroll.id"
          align-v="center"
          class="list-teacher"
        >
          <b-col md="1">
            <p>#{{ enroll.id }}</p>
          </b-col>

          <b-col md="2">
            <p v-if="enroll.EnrollmentStudent">
              {{ enroll.EnrollmentStudent.name }}
            </p>
          </b-col>

          <b-col md="2">
            <p v-if="enroll.EnrollmentResponsibles.length">
              {{ getContractor(enroll.EnrollmentResponsibles).name }}
            </p>
          </b-col>

          <b-col
            md="3"
            class="progress-enrollment"
            :title="
              enroll.EnrollmentStudent.is_re_enroll === true
                ? 'Rematrícula'
                : ''
            "
            v-if="
              enroll.Calendar.designation === nextYear &&
              enroll.EnrollmentStudent != null &&
              enroll.EnrollmentStudent.is_re_enroll === true
            "
          >
            <p>{{ enroll.percentage }}%</p>
            <b-progress :value="enroll.percentage" max="100"></b-progress>
          </b-col>

          <b-col md="3" class="progress-enrollment" v-else>
            <p>{{ enroll.percentage }}%</p>
            <b-progress :value="enroll.percentage" max="100"></b-progress>
          </b-col>

          <b-col md="1">
            <p>{{ enroll.Calendar.designation }}</p>
          </b-col>

          <b-col
            md="2"
            v-if="
              ((enroll.EnrollmentStudent &&
                enroll.EnrollmentStudent.is_re_enroll == true) ||
                (enroll.EnrollmentStep &&
                  enroll.EnrollmentStep.enrollment_student === true)) &&
              enroll.percentage < 100 &&
              enroll.EnrollmentDefaulters === null
            "
          >
            <router-link
              v-if="enroll.EnrollmentStudent != null"
              :to="{
                name: 'rematricula',
                params: {
                  id: enroll.id,
                  step: defineStepByPercentage(enroll.percentage),
                },
              }"
              class="btn-link"
              >Continuar Rematrícula</router-link
            >
          </b-col>

          <b-col
            md="2"
            v-else-if="
              enroll.percentage == 100 &&
              enroll.EnrollmentStudent != null &&
              +enroll.Calendar.designation < +getEnrollmentYear().designation &&
              enroll.EnrollmentDefaulters === null
            "
          >
            <span
              @click="
                rematricularAluno(
                  enroll.EnrollmentStudent.id,
                  enroll.id,
                  currentCalendar
                )
              "
              class="btn-rematricula pointer"
              >Rematrícula {{ currentCalendar.designation }}</span
            >
          </b-col>

          <b-col
            md="2"
            v-else-if="enroll.EnrollmentDefaulters === null && !hasDefaulter"
          >
            <router-link
              v-if="enroll.percentage < 100"
              :to="{
                name: 'continuar-matricula',
                params: {
                  id: enroll.id,
                  edit: 'true',
                  step: defineStepByPercentage(enroll.percentage),
                },
              }"
              class="btn-link"
            >
              Continuar Matrícula
            </router-link>
          </b-col>

          <b-col md="2" v-else-if="enroll.EnrollmentDefaulters !== null">
            <p class="defautersText" style="font-size: 14px">
              Matricula Indisponível. Por favor, entrar em contato com
              financeiro.
            </p>
          </b-col>

          <b-col
            md="1"
            class="text-center"
            v-if="
              (!enroll.EnrollmentStudent ||
                !enroll.EnrollmentStudent.student_id) &&
              enroll.percentage < 80
            "
          >
            <span @click="deleteEnroll(enroll.id)" class="pointer">
              <DeleteIcon class="deleteIcone" />
            </span>
          </b-col>
        </b-row>
      </div>
    </b-container>

    <b-row class="enrollments-boxes">
      <b-col md="4" v-for="calendar in actualCalendars" :key="calendar.id">
        <div class="box">
          <h3 class="mt-4 purple">Matrícula {{ calendar.designation }}</h3>
          <p class="text" v-if="calendar.designation == currentYear">
            Clique para iniciar uma nova matrícula para o ano de
            {{ calendar.designation }}.
          </p>
          <p class="text" v-if="calendar.designation == currentYear + 1">
            Clique para iniciar a matrícula de um novo aluno para o ano de
            {{ calendar.designation }}.
          </p>
          <a
            href="#"
            v-if="!hasDefaulter"
            @click="createEnrollment(calendar.id)"
            class="btn large"
            :class="{
              outline: calendar.designation == currentYear,
              blue: calendar.designation != currentYear,
            }"
          >
            {{
              enrollments.filter((e) => e.calendar_id === calendar.id).length
                ? `Adicionar novo aluno`
                : `Criar matrícula ${calendar.designation}`
            }}
          </a>
          <p class="defautersText" v-else>
            Não é possível iniciar novas matrículas. Favor entrar em contato com
            o setor financeiro para maiores informações.
          </p>
        </div>
      </b-col>
    </b-row>

    <b-row class="documents">
      <b-col>
        <div class="box-info">
          <div class="title">
            <h2>Documentos necessários para a matrícula:</h2>
          </div>

          <div class="content">
            <b-row>
              <b-col>
                <p class="bold">Matrícula de NOVO aluno:</p>
                <ul>
                  <li>
                    Contrato de Prestação de Serviços Educacionais (gerado ao
                    final desse processo) assinado digitalmente pelos dois
                    Contratantes, com igual responsabilidade (Financeira e
                    Legal);
                  </li>
                  <li>
                    Carteira de Identidade ou Certidão de Nascimento do aluno;
                  </li>
                  <li>
                    Carteira de Identidade e do CPF dos dois Contratantes;
                  </li>
                  <li>CPF do aluno;</li>
                  <li>
                    Comprovante de endereço atualizado dos dois contratantes;
                  </li>
                  <li>Cartão de Vacinas;</li>
                  <li>Declaração de Transferência;</li>
                  <li>Declaração de Quitação emitida pela escola anterior;</li>
                  <li>
                    Histórico Escolar Original (a ser entregue em até 30 dias
                    após a data da matrícula)
                  </li>
                </ul>
              </b-col>
              <b-col>
                <p class="bold">Rematrícula de alunos:</p>
                <ul>
                  <li>
                    Contrato de Prestação de Serviços Educacionais (gerado ao
                    final desse processo) assinado digitalmente pelos dois
                    Contratantes, com igual responsabilidade (Financeira e
                    Legal);
                  </li>
                  <li>
                    Apenas será necessário o envio de documentos que tenham sido
                    alterados (ex.: comprovante de endereço, CPF e RG se houve
                    mudança de contratante).
                  </li>
                </ul>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-col>
    </b-row>

    <b-row class="payments">
      <b-col>
        <div class="box-payment">
          <div class="title">
            <h2>
              Valores referentes a 200 dias letivos para o ano de
              {{ currentCalendar.designation }}
            </h2>
          </div>
          <div class="content-payment" style="align-items: center">
            <b-row v-if="prices.length > 0" class="justify-content-md-center">
              <b-col
                style="max-width: 100%"
                md="2"
                :key="price.id"
                v-for="price in prices"
              >
                <h5 class="gradient1">{{ price.cycle_name }}</h5>
                <span class="gradient1">{{ price.cycle_description }}</span>
                <p class="year">Anuidade</p>
                <p>{{ price.annuity_price }}</p>
                <hr />
                <p class="installments">12 parcelas</p>
                <p>{{ price.instalment_price }}</p>
              </b-col>
            </b-row>
            <b-row v-else>
              <p class="bold" style="text-align: center">
                Valores não disponíveis no momento
              </p>
            </b-row>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import httpRda from "../../http-rda";

import DataService from "@/services/enrollments.service";
import LoadingList from "@/components/LoadingList.vue";
import DeleteIcon from "@/components/svg/DeleteIcon";
import "vue-toastification/dist/index.css";

export default {
  name: "Enrollment",
  components: {
    DeleteIcon,
    LoadingList,
  },
  data() {
    return {
      enrollments: null,
      prices: [],
      currentYear: new Date().getFullYear(),
      currentCalendar: null,
      studentsAnotherResponsible: [],
      hasDefaulter: false,
      actualCalendars: [],
      calendars: [],
    };
  },

  computed: {
    ...mapGetters({
      user: "auth/getAuthData",
    }),

    nextYear() {
      return (this.currentYear + 1).toString();
    },
  },

  methods: {
    ...mapActions({
      createEnroll: "enrollment/createEnrollment",
    }),

    async getCalendars() {
      this.calendars = await httpRda
        .get("/calendars")
        .then((response) => response.data.calendars);

      this.currentCalendar = await httpRda
        .get("/config/calendars/next")
        .then((response) => response.data.calendar);

      await this.defineActualCalendars();
    },

    async defineActualCalendars() {
      const currentYear = await httpRda
        .get("/config/calendars")
        .then((resp) => +resp.data.calendar.designation);

      this.actualCalendars = this.calendars
        .filter(
          ({ designation }) =>
            designation == currentYear || designation == currentYear + 1
        )
        .sort((a, b) => (a.designation > b.designation ? 1 : -1));
    },

    async loadList(responsible_id) {
      this.loading = true;
      await httpRda
        .get(`/responsibles/${responsible_id}/enrollments`)
        .then((resp) => {
          this.enrollments = resp.data.enrollments;

          this.hasDefaulter = this.enrollments.some(
            (enrollment) => enrollment.EnrollmentDefaulters !== null
          );
          this.loading = false;
        });
      this.loading = false;
    },

    async getEnrollmentPrices() {
      const params = {
        calendar_id: this.currentCalendar.id,
      };

      await httpRda
        .get(`/prices`, params.calendar_id ? { params } : null)
        .then((resp) => {
          this.prices = resp.data.prices.values;
          this.currentYear = resp.data.prices.year;
        });
    },

    async getAllStudents() {
      try {
        let students;
        await httpRda
          .get(
            `/responsibles/${this.user.responsible_id}/enrollments/allStudents`
          )
          .then((response) => {
            students = response.data;
          });

        if (students.enrollments && students.enrollments.length > 0) {
          const enrollments = students.data.enrollments.filter(
            (x) => x.responsible_id !== this.user.responsible_id
          );

          for (const enrollment of enrollments) {
            const responsibleData = enrollment.EnrollmentResponsibles.filter(
              (c) => c.responsible_id !== this.user.responsible_id
            )[0];

            this.studentsAnotherResponsible.push({
              name: enrollment.EnrollmentStudent.name,
              email: responsibleData.email,
              responsibleName: responsibleData.name,
            });
          }
        }
      } catch (e) {
        // ignore
      }
    },

    async createEnrollment(calendar_id) {
      try {
        const enroll = await this.createEnroll({
          responsible_id: this.user.responsible_id,
          calendar_id,
        });

        const enroll_id = enroll.data.enrollment.id;

        this.$router.push({
          name: "criar-matricula",
          params: { id: enroll_id, step: "dados-do-aluno" },
        });
      } catch (error) {
        this.loading = false;
        this.message =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();
        this.$swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Por favor, tente novamente mais tarde.",
          footer: `${this.message.error}`,
        });
      }
    },

    getContractor(responsibles) {
      return responsibles.find((res) =>
        res.ResponsibleTypes.some((r) => r.type === "contractor a")
      );
    },

    deleteEnroll(id) {
      this.$swal
        .fire({
          title: "Tem certeza?",
          text: "Você não poderá reverter isso!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#68c5b7",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim, excluir!",
          cancelButtonText: "Cancelar",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            try {
              await DataService.delete(id);

              this.$swal
                .fire("Excluído!", "Matricula deletada com sucesso.", "success")
                .then((response) => {
                  this.loadList(this.user.responsible_id);
                  return response.data;
                });
            } catch (error) {
              this.$swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Não foi possivel iniciar!",
                footer: "Por favor, tente novamente mais tarde.",
              });

              return error.response;
            }
          }
        })
        .catch((error) => {
          this.$swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Não foi possivel excluir!",
            footer: "Por favor, tente novamente mais tarde.",
          });

          return error.response;
        });
    },

    defineStepByPercentage(percentage = 0) {
      switch (percentage) {
        case 90:
        case 80:
          return "documento";

        case 70:
          return "revisao";

        case 60:
          return "autorizacao";

        case 50:
        case 40:
          return "complementares";

        case 30:
        case 20:
          return "filiacoes";

        case 10:
          return "contratantes";

        default:
          return "dados-do-aluno";
      }
    },

    getEnrollmentYear() {
      const nextYearToEnrollment = this.calendars.filter(
        (c) => c.designation.toString() === this.nextYear.toString()
      );

      const currentYearToEnrollment = this.calendars.filter(
        (c) => c.designation.toString() === this.currentYear.toString()
      );

      if (nextYearToEnrollment[0] !== undefined) {
        return nextYearToEnrollment[0];
      } else {
        return currentYearToEnrollment[0];
      }
    },

    async rematricularAluno(id, enroll_id, calendar) {
      if (!calendar) {
        this.$swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Não foi possível iniciar a rematrícula!",
          footer: "Por favor, tente novamente mais tarde.",
        });
        return;
      }
      const enrollmentsStudent = this.enrollments.find(
        (e) => e.EnrollmentStudent?.id === id
      ).EnrollmentStudent.name;
      this.$swal
        .fire({
          title: `Deseja iniciar a rematrícula ${calendar.designation}?`,
          text: `A rematrícula do aluno: ${enrollmentsStudent}, será iniciada.`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#68c5b7",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim",
          cancelButtonText: "Cancelar",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            await httpRda.patch(`/enrollments/${enroll_id}`, {
              enrollment_id: enroll_id,
              calendar_id: calendar.id,
              finished_at: null,
              signed_at: null,
              is_printed: false,
            });

            await httpRda.patch(`/enrollments/${enroll_id}/students`, {
              is_re_enroll: true,
            });

            await httpRda.patch(`/enrollments/${enroll_id}/setReenroll`);
            await httpRda.patch(`/enrollments/${enroll_id}/setReenroll`, {
              enrollment_student: true,
            });

            this.$router.push({
              name: "rematricula",
              params: {
                id: enroll_id,
                step: "dados-do-aluno",
              },
            });
          } else {
            this.$swal.fire({
              icon: "error",
              title: "Rematrícula cancelada!",
              text: "Você pode iniciar a rematrícula a qualquer momento.",
              footer: "",
            });
          }
        });
    },
  },

  beforeCreate() {
    if (localStorage.getItem("reloaded")) {
      localStorage.removeItem("reloaded");
      this.loading = false;
    } else {
      localStorage.setItem("reloaded", "1");
      this.loading = true;
      location.reload();
    }
  },

  mounted() {
    this.getCalendars();
  },

  created() {
    if (this.user.responsible_id) {
      this.loadList(this.user.responsible_id);
    }
    // this.defineActualCalendars();
    this.getAllStudents();
    this.getEnrollmentPrices();
  },

  watch: {
    currentCalendar: {
      handler() {
        this.getEnrollmentPrices();
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/css/variables.scss";

.defautersText {
  text-align: center;
  color: #b23066;
  font-weight: bold;
}

.header-list-teacher {
  color: $grey1;
  margin: 0 0 15px;
  p {
    margin: 0;
  }
}

.btn-rematricula {
  background-color: $blue;
  border: 1px solid $blue;
  border-radius: 5px;
  color: $white;
  display: block;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  max-width: 175px;
  padding: 4px 12px;
  transition: all 0.3s;
  text-align: center;
  &:hover {
    background-color: transparent;
    color: $grey4;
  }
}

.list-teacher {
  background-color: $white;
  border: 1px solid $grey5;
  border-radius: 10px;
  margin-bottom: 15px;
  min-height: 80px;
  &:hover {
    border-color: transparent;
    background-image: linear-gradient($white, $white),
      radial-gradient(circle at top left, $orange, $purple);
    background-origin: border-box;
    background-clip: content-box, border-box;
  }
  p {
    margin: 0;
  }
  .progress-enrollment {
    display: flex;
    align-items: center;
    p {
      font-size: 16px;
      min-width: 50px;
    }
    .progress {
      background-color: $grey5;
      height: 7px;
      max-width: 260px;
      width: 100%;
      padding: 0;
      .progress-bar {
        background-color: $orange;
      }
    }
  }
}

.documents {
  padding-bottom: 95px;
  .box-info {
    border-radius: 10px;
    background-image: linear-gradient($white, $white),
      radial-gradient(circle at top left, $orange, $purple);
    background-origin: border-box;
    background-clip: content-box, border-box;
    border: 1px solid transparent;
    .title {
      background: $gradient1;
      border-radius: 6px 6px 0 0;
      color: $white;
      padding: 21px 40px;
      text-align: center;
      h2 {
        margin: 0;
      }
    }
    .content {
      color: $grey4;
      margin: 0 auto;
      max-width: 1230px;
      padding: 60px 15px;
      ul {
        padding: 0px;
        max-width: 570px;
        li {
          font-size: 18px;
          list-style: none;
          margin-bottom: 5px;
          &:before {
            content: "";
            background: $gradient1;
            border-radius: 30px;
            display: inline-block;
            margin-right: 10px;
            height: 10px;
            width: 10px;
          }
        }
      }
      .obs {
        font-size: 20px;
        line-height: 32px;
      }
    }
  }
  .create-account {
    h2 {
      padding-bottom: 20px;
    }
    form {
      .form-group {
        margin-bottom: 35px;
        legend {
          position: relative;
          &:after {
            content: "*";
            color: $orange;
            font-size: 24px;
            position: absolute;
            top: 0;
            right: 0;
          }
        }
      }
      .required {
        position: relative;
        span {
          color: $orange;
          font-size: 24px;
        }
        color: $grey4;
      }
    }
  }
}

.enrollments-boxes {
  margin-bottom: 40px;
  padding-top: 65px;
  .box {
    background-color: $white;
    border: 1px solid $grey5;
    border-radius: 10px;
    margin-bottom: 40px;
    padding: 32px 40px 60px 40px;
    .tag {
      border-radius: 5px;
      color: $white;
      display: inline-block;
      line-height: 28px;
      margin-bottom: 24px;
      padding: 0 19px;
      &.green {
        background-color: $green1;
      }
      &.orange {
        background-color: $orange;
      }
    }
    h3 {
      margin-bottom: 24px;
    }
    .text {
      margin-bottom: 32px;
    }
  }
}

.payments {
  margin-bottom: 45px;
  .box-payment {
    border-radius: 10px;
    background-image: linear-gradient(to right, $green1, $blue),
      radial-gradient(circle at top left, $green1, $blue);
    background-origin: border-box;
    background-clip: content-box, border-box;
    border: 1px solid transparent;
    margin-bottom: 45px;
    .title {
      color: $white;
      padding: 21px 15px;
      text-align: center;
      h2 {
        margin: 0;
      }
    }
    .content-payment {
      background: $grey6;
      border-radius: 0 0 9px 9px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-height: 300px;
      h5 {
        margin-bottom: 0;
      }
      span {
        display: block;
        font-size: 14px;
        margin-bottom: 15px;
      }
      .year,
      .installments {
        color: $blue;
        font-size: 14px;
        font-weight: $bold;
        margin: 0;
      }
      p {
        color: $grey4;
      }
      hr {
        border-color: $grey5;
      }
    }
  }
  .box-methods {
    border-radius: 10px;
    background-image: linear-gradient($white, $white),
      radial-gradient(circle at top left, $green1, $blue);
    background-origin: border-box;
    background-clip: content-box, border-box;
    border: 1px solid transparent;
    margin-bottom: 45px;
    .title {
      padding: 21px 15px;
      h2 {
        margin: 0;
      }
    }
    .content-payment {
      background: $gradient2;
      border-radius: 0 0 7px 7px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-height: 220px;
      h3 {
        color: $white;
        margin: 0;
        padding: 11px 0;
      }
    }
  }
}

.contact-form {
  .btn {
    width: 100%;
  }
}
</style>
