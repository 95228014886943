<template>
  <b-container class="container-rda">
    <b-row>
      <b-col>
        <b-breadcrumb :items="breadcrumb"></b-breadcrumb>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-row>
          <h2 class="purple bold">Professores</h2>
          <h3 v-if="count" class="tags_count">{{ count }}</h3>
        </b-row>
      </b-col>

      <b-col class="text-right" v-if="canEdit">
        <b-button
          class="btn large blue"
          :to="{
            name: 'novo-professor',
            params: {
              cycle: this.cycle,
              subject: this.subject,
              page: this.page,
            },
          }"
          >Registrar novo professor</b-button
        >
      </b-col>
    </b-row>
    <b-row class="mt-50">
      <b-col>
        <SelectCycles v-model="cycle" :callback="callbackCycles" />
        <span class="clear-field" v-if="this.cycle != null" @click="clearCycles"
          >Limpar filtro</span
        >
      </b-col>
      <b-col>
        <SelectSubjects
          v-model="subject"
          :callback="callbackSubjects"
          :cycle="cycle_select"
        >
        </SelectSubjects>
        <span
          class="clear-field"
          v-if="this.subject != null"
          @click="clearSubject"
          >Limpar filtro</span
        >
      </b-col>
      <b-col>
        <b-form-group label="Buscar Professor">
          <b-input-group-prepend>
            <b-form-input
              type="search"
              placeholder="Digite aqui"
              v-model="searchTitle"
              v-on:keyup.enter="
                page = 1;
                getList();
              "
            ></b-form-input>
            <b-input-group-text
              class="clear-fields"
              v-if="searchTitle != '' || cycle != null || subject != null"
              v-b-tooltip.hover
              title="Limpar Pesquisa"
            >
              <b-icon icon="x" @click="clearFields" />
            </b-input-group-text>
          </b-input-group-prepend>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <!-- <b-col>
        <div class="order-grid-box">
          <p class="order-list" :class="{ desc: descOrder }" v-on:click="orderName">
            Ordenar
            <OrderNameIcon />
          </p>
        </div>
      </b-col> -->
    </b-row>
    <b-row>
      <b-col>
        <b-container class="list-top" fluid>
          <b-row
            class="header-list-teacher"
            v-if="teachers.length > 0 && isRequestSend"
          >
            <b-col md="2">
              <p class="bold">Nome</p>
            </b-col>
            <b-col :md="canEdit ? 3 : 8">
              <p class="bold">Matéria</p>
            </b-col>
            <b-col md="2" v-if="canEdit">
              <p class="bold">Ciclo</p>
            </b-col>
            <b-col v-if="canEdit">
              <p class="bold">Ativo</p>
            </b-col>
            <b-col v-if="canEdit">
              <p class="bold">Editar senha</p>
            </b-col>
            <b-col v-if="canEdit">
              <p class="bold">Editar</p>
            </b-col>
            <b-col v-if="canEdit">
              <p class="bold">Apagar</p>
            </b-col>

            <b-col v-if="!canEdit">
              <p class="bold">Visualizar</p>
            </b-col>
          </b-row>
        </b-container>
        <b-container fluid>
          <b-row
            align-v="center"
            class="list-teacher"
            v-for="teacher in orderbyName"
            v-bind:key="teacher.User.id"
          >
            <b-col md="2" class="name">
              <p class>{{ teacher.User.name }}</p>
            </b-col>
            <b-col :md="canEdit ? 3 : 8" class="disciplines">
              <p
                class="name"
                v-for="subject in listUniqueDesignation(teacher.Subjects)"
                v-bind:key="subject.id"
              >
                {{ subject.designation }}
                <span class="comma">,</span>
              </p>
            </b-col>

            <b-col md="2" class="cycles" v-if="canEdit">
              <p
                class="tags"
                v-for="cycle in listUniqueCycles(teacher.Subjects)"
                v-bind:key="cycle.id"
              >
                {{ cycle.Cycle.name }}
              </p>
            </b-col>

            <b-col class="status" v-if="canEdit">
              <b-form-checkbox
                switch
                v-if="teacher.is_active"
                v-model="teacher.is_active"
                @input="updateStatus(teacher.id, false)"
              ></b-form-checkbox>
              <b-form-checkbox
                switch
                v-else
                v-model="teacher.is_active"
                @input="updateStatus(teacher.id, true)"
              ></b-form-checkbox>
            </b-col>

            <b-col class="editar-senha" v-if="canEdit">
              <a
                @click="() => setTeacherPassword(teacher, teacher.id)"
                v-b-modal.edit-password
              >
                <LockIcon />
              </a>
            </b-col>

            <b-col v-if="canEdit">
              <router-link
                :to="{
                  name: 'perfil-professor',
                  params: {
                    id: teacher.id,
                    cycle: cycle,
                    subject: subject,
                    page: page,
                  },
                }"
              >
                <EditIcon />
              </router-link>
            </b-col>

            <b-col v-if="canEdit">
              <a href v-on:click.prevent="exclude(teacher.id)">
                <DeleteIcon />
              </a>
            </b-col>

            <b-col v-if="!canEdit">
              <router-link
                :to="{
                  name: 'perfil-professor-visualizar',
                  params: { id: teacher.id },
                }"
              >
                <ViewIcon />
              </router-link>
            </b-col>
          </b-row>

          <b-row
            align-v="center"
            class="list-teacher"
            v-if="teachers.length <= 0 && isRequestSend"
          >
            <b-col>Nenhum resultado encontrado</b-col>
          </b-row>
          <b-row align-v="center" class="list-teacher" v-if="!isRequestSend">
            <b-col>Carregando</b-col>
          </b-row>
          <b-row
            class="align-center"
            v-else-if="count > perPage && isRequestSend"
          >
            <b-pagination
              v-model="page"
              :per-page="perPage"
              :total-rows="count"
              @change="changePage"
              aria-controls="teachersList"
              last-text
              first-text
            >
              <template #prev-text>
                <LeftArrowIcon class="left" />
              </template>
              <template #next-text>
                <LeftArrowIcon />
              </template>
            </b-pagination>
          </b-row>

          <TeacherEditPassword :teacher="teacherToPassword" />
        </b-container>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapGetters } from "vuex";
import DataService from "@/services/teachers.service";

import EditIcon from "@/components/svg/EditIcon.vue";
import ViewIcon from "@/components/svg/ViewIcon.vue";
import DeleteIcon from "@/components/svg/DeleteIcon.vue";
import LeftArrowIcon from "@/components/svg/LeftArrowIcon.vue";
// import OrderNameIcon from "@/components/svg/OrderNameIcon.vue";
import LockIcon from "@/components/svg/LockIcon.vue";
import SelectSubjects from "@/components/inputs/SelectSubjects.vue";
import SelectCycles from "@/components/inputs/SelectCycles.vue";
import TeacherEditPassword from "@/components/modals/TeacherEditPassword.vue";

export default {
  name: "professores",

  components: {
    EditIcon,
    ViewIcon,
    DeleteIcon,
    LockIcon,
    LeftArrowIcon,
    SelectSubjects,
    SelectCycles,
    TeacherEditPassword,
  },

  data() {
    return {
      teacherToPassword: {},

      teachers: [],
      User: [],
      teacher: {
        id: "",
      },

      descOrder: false,
      isRequestSend: false,

      cycle: this.$route.params.cycle,
      subject: this.$route.params.subject,
      searchTitle: "",

      page: this.$route.params.page || 1,
      count: 0,
      perPage: 20,
      cycle_select: null,
      breadcrumb: [
        {
          text: "Comunidade",
          href: "/comunidade",
        },
        {
          text: "Professores",
          href: "/professores",
          active: true,
        },
      ],
    };
  },

  methods: {
    getRequestParams(searchTitle, page, perPage, subject, cycle) {
      let params = {};

      if (searchTitle) {
        params["search"] = searchTitle;
      }

      if (page) {
        params["page"] = page;
      }

      if (perPage) {
        params["limit"] = perPage;
      }

      if (subject) {
        params["subjects"] = subject;
      }

      if (cycle) {
        params["cycles"] = cycle;
      }

      return params;
    },

    getList() {
      const params = this.getRequestParams(
        this.searchTitle,
        this.page,
        this.perPage,
        this.subject,
        this.cycle
      );
      this.getCompleteList(params);
    },

    getCompleteList(params) {
      DataService.getAll(params)
        .then((response) => {
          this.teachers = response.data.teachers;
          this.count = response.data.count;
          this.isRequestSend = true;
        })
        .catch((error) => {
          return error;
        });
    },

    exclude(id) {
      this.$swal
        .fire({
          title: "Tem certeza?",
          text: "Você não poderá reverter isso!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#68c5b7",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim, excluir!",
          cancelButtonText: "Cancelar",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            try {
              await DataService.delete(id);
              this.$swal
                .fire("Excluído!", "Professor foi excluído.", "success")
                .then((response) => {
                  this.getList();
                  return response.data;
                });
            } catch (error) {
              this.$swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Não foi possivel excluir!",
                footer: "Por favor, tente novamente mais tarde.",
              });

              return error.response;
            }
          }
        })
        .catch((error) => {
          this.$swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Não foi possivel excluir!",
            footer: "Por favor, tente novamente mais tarde.",
          });

          return error.response;
        });
    },

    updateStatus(id, status) {
      var data = {
        id: id,
        is_active: status,
      };
      DataService.update(id, data)
        .then((response) => {
          const Toast = this.$swal.mixin({
            toast: true,
            position: "bottom-end",
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer);
              toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "success",
            title: `Status atualizado com sucesso!`,
          });

          return response.data;
        })
        .catch((error) => {
          this.$swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Não foi possivel atualizar!",
            footer: "Por favor, tente novamente mais tarde.",
          });

          return error.response;
        });
    },

    orderName() {
      this.descOrder = !this.descOrder;
    },

    callbackCycles({ id }) {
      this.cycle = id !== -1 ? id : null;
      this.cycle_select = id;
      this.page = 1;
      this.clearSubject();
      this.getList();
    },

    callbackSubjects({ id }) {
      this.subject = id !== -1 ? id : null;
      this.page = 1;
      this.getList();
    },

    changePage(page) {
      this.page = page;
      this.getList();
      window.scrollTo({ top: 0, behavior: "smooth" });
    },

    clearFields(searchTitle, cycle, subject, page) {
      this.searchTitle = "";
      this.cycle = null;
      this.subject = null;
      this.page = 1;
      this.getList({
        search: searchTitle,
        cycle_id: cycle,
        subject_id: subject,
        page: page,
      });
    },

    clearCycles(cycle, page) {
      this.cycle = null;
      this.page = 1;
      this.getList({ cycle_id: cycle, page: page });
    },

    setTeacherPassword(teacher) {
      this.teacherToPassword = teacher;
    },

    listUniqueCycles(teacherSubjects) {
      return teacherSubjects.filter(
        (t, i) =>
          teacherSubjects.findIndex((ts) => ts.Cycle.name == t.Cycle.name) == i
      );
    },

    listUniqueDesignation(teacherSubjects) {
      return teacherSubjects.filter(
        (t, i) =>
          teacherSubjects.findIndex((ts) => ts.designation == t.designation) ==
          i
      );
    },
  },

  created() {
    if (this.$route.params.page)
      this.getList({
        page: this.$route.params.page,
        limit: this.perPage,
        cycle_id: this.cycle,
      });
    else if (!this.$route.params.page) this.getList();
  },

  computed: {
    ...mapGetters({
      user: "auth/getAuthData",
    }),

    canEdit() {
      if (!this.user.role) return false;
      return ["coordinator", "admin"].some((role) =>
        this.user.role.includes(role)
      );
    },

    orderbyName() {
      return [...this.teachers].sort((a, b) => {
        const order = this.descOrder ? [1, -1] : [-1, 1];

        const nameA = a.User.name.toLowerCase();
        const nameB = b.User.name.toLowerCase();

        if (nameA < nameB) return order[0];
        else if (nameA > nameB) return order[1];

        return 0;
      });
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/css/variables.scss";
.tags_count {
  background-color: #68c5b7;
  border-radius: 3px;
  color: #fff;
  margin-left: 10px;
  padding: 3px 10px;
  height: max-content;
}
.list-top {
  margin-top: 30px;
  margin-bottom: 30px;
}
.text-right {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.list-teacher {
  background-color: $white;
  border: 1px solid $grey5;
  border-radius: 10px;
  margin-bottom: 15px;
  min-height: 80px;
  &:hover {
    border-color: transparent;
    background-image: linear-gradient($white, $white),
      radial-gradient(circle at top left, $orange, $purple);
    background-origin: border-box;
    background-clip: content-box, border-box;
  }
  p {
    margin: 0;
  }
  .disciplines {
    .name {
      display: inline-block;
      line-height: 22px;
      .comma {
        margin-right: 4px;
      }
      &:last-child {
        .comma {
          display: none;
        }
      }
    }
  }
  .cycles {
    display: flex;
    flex-wrap: wrap;
    .tags {
      background-color: $blue;
      border-radius: 5px;
      color: $white;
      font-weight: $bold;
      line-height: 28px;
      margin: 3px 12px 3px 0;
      max-width: 58px;
      padding: 0 3px;
      text-align: center;
      vertical-align: middle;
    }
  }
}

.order-grid-box {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 25px;
  .order-list {
    align-items: center;
    color: $grey4;
    cursor: pointer;
    display: flex;
    font-size: 16px;
    margin: 0 9px;
    svg {
      margin-left: 15px;
      transition: all 0.15s;
    }
    &.desc {
      svg {
        transform: rotate(180deg);
      }
    }
  }
  .order-style {
    border: 1px solid transparent;
    background-image: linear-gradient($white, $white),
      radial-gradient(circle at top left, $orange, $purple);
    background-origin: border-box;
    background-clip: content-box, border-box;
    border-radius: 5px;
    display: flex;
    margin: 0 0 0 9px;
    button {
      background: $gradient1;
      border: 0;
      line-height: 46px;
      width: 48px;
      svg {
        path {
          fill: $white;
        }
      }
      &.active {
        background-color: $white;
        background: $white;
        border-radius: 5px;
        svg {
          path {
            fill: url(#paint0_linear);
          }
        }
      }
    }
  }
}
</style>
