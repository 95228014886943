import moment from "moment";

export function formateDate(value) {
  var reg = /(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d/;

  if (value.match(reg)) {
    alert("Input matched");
  } else {
    alert("Please enter dd/mm/yyyy");
  }
}

export function FromIsoStringToBRDate(isoStringDate) {
  return moment(isoStringDate).format("DD/MM/YYYY");
}

export function FromBRDateToIsoString(BRDate) {
  let auxDate = null;

  auxDate = moment(BRDate, "DD/MM/YYYY");

  if (!auxDate.isValid()) {
    return (BRDate = moment(BRDate, "DD/MM/YYYY").toDate().toISOString());
  } else {
    return auxDate.toISOString();
  }
}
