<template>
  <div class="home">
    <template>
      <b-container class="container-rda sidebar">
        <b-row class="height">
          <sidebar-coordination />
          <b-col class="content">
            <b-row>
              <b-col>
                <b-breadcrumb :items="breadcrumb"></b-breadcrumb>
              </b-col>
            </b-row>
            <b-row class="mb-5">
              <b-col>
                <h2 class="purple bold">Gerenciamento de matérias</h2>
              </b-col>
              <b-col class="text-right">
                <b-button
                  class="btn large blue"
                  :to="{
                    name: 'nova-materia',
                    params: {
                      cycle: this.cycle,
                      page: this.page,
                    },
                  }"
                  >Registrar nova matéria</b-button
                >
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <SelectCycles v-model="cycle" :callback="callbackCycles" />
                <span
                  class="clear-field"
                  v-if="this.cycle != null"
                  @click="clearCycle"
                  >Limpar filtro</span
                >
              </b-col>
              <b-col md="9">
                <b-form-group label="Buscar">
                  <b-input-group-prepend>
                    <b-form-input
                      type="search"
                      placeholder="Digite aqui"
                      v-model="searchTitle"
                      v-on:keyup.enter="
                        page = 1;
                        getList();
                      "
                    >
                    </b-form-input>
                    <b-input-group-text
                      class="clear-fields"
                      v-if="searchTitle != '' || cycle != null"
                      v-b-tooltip.hover
                      title="Limpar Pesquisa"
                    >
                      <b-icon icon="x" @click="clearFields" />
                    </b-input-group-text>
                  </b-input-group-prepend>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <div class="order-grid-box">
                  <p
                    class="order-list"
                    :class="{ desc: descOrder }"
                    v-on:click="orderName"
                  >
                    Ordenar
                    <OrderNameIcon />
                  </p>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-container fluid>
                  <b-row
                    class="header-list-teacher"
                    v-if="subjects.length > 0 && isRequestSend"
                  >
                    <b-col md="7">
                      <p class="bold">Disciplina</p>
                    </b-col>
                    <b-col>
                      <p class="bold">Ciclos</p>
                    </b-col>
                    <b-col>
                      <p class="bold">Ativo</p>
                    </b-col>
                    <b-col>
                      <p class="bold">Editar</p>
                    </b-col>
                    <b-col>
                      <p class="bold">Apagar</p>
                    </b-col>
                  </b-row>
                </b-container>
                <b-container fluid>
                  <b-row
                    align-v="center"
                    class="list-teacher"
                    v-for="subject in orderbyName"
                    v-bind:key="subject.id"
                  >
                    <b-col md="7">
                      <p>{{ subject.designation }}</p>
                    </b-col>
                    <b-col>
                      <p class="tags">{{ subject.Cycle.name }}</p>
                    </b-col>
                    <b-col class="status">
                      <b-form-checkbox
                        switch
                        v-if="subject.is_active"
                        v-model="subject.is_active"
                        @input="updateStatus(subject.id, false)"
                      ></b-form-checkbox>
                      <b-form-checkbox
                        switch
                        v-else
                        v-model="subject.is_active"
                        @input="updateStatus(subject.id, true)"
                      ></b-form-checkbox>
                    </b-col>
                    <b-col>
                      <router-link
                        :to="{
                          name: 'perfil-materia',
                          params: { id: subject.id },
                        }"
                      >
                        <EditIcon />
                      </router-link>
                    </b-col>
                    <b-col>
                      <a v-on:click.prevent="exclude(subject.id)">
                        <DeleteIcon />
                      </a>
                    </b-col>
                  </b-row>
                  <b-row
                    align-v="center"
                    class="list-teacher"
                    v-if="subjects.length <= 0 && isRequestSend"
                  >
                    <b-col> Nenhum resultado encontrado </b-col>
                  </b-row>
                  <b-row
                    align-v="center"
                    class="list-teacher"
                    v-if="!isRequestSend"
                  >
                    <b-col> Carregando </b-col>
                  </b-row>
                  <b-row
                    class="align-center"
                    v-else-if="count > perPage && isRequestSend"
                  >
                    <b-pagination
                      v-model="page"
                      :per-page="perPage"
                      :total-rows="count"
                      @change="changePage"
                      aria-controls="teachersList"
                      last-text=""
                      first-text=""
                    >
                      <template #prev-text
                        ><LeftArrowIcon class="left"
                      /></template>
                      <template #next-text><LeftArrowIcon /></template>
                    </b-pagination>
                  </b-row>
                </b-container>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </template>
  </div>
</template>

<script>
import DataService from "@/services/subjects.service";

import SelectCycles from '@/components/inputs/SelectCycles.vue'
import OrderNameIcon from '@/components/svg/OrderNameIcon.vue'
import LeftArrowIcon from '@/components/svg/LeftArrowIcon.vue'
import EditIcon from '@/components/svg/EditIcon.vue'
import DeleteIcon from '@/components/svg/DeleteIcon.vue'
import SidebarCoordination from '@/components/SidebarCoordination.vue';

export default {
  name: 'turmas',
  components: {
    SelectCycles,
    OrderNameIcon,
    LeftArrowIcon,
    EditIcon,
    DeleteIcon,
    SidebarCoordination
  },
  data() {
    return {
      subjects: [],
      descOrder: false,
      isRequestSend: false,

      cycle: this.$route.params.cycle,
      searchTitle: '',

      page: this.$route.params.page || 1,
      count: 0,
      perPage: 20,

      breadcrumb : [
        {
          text: 'Secretaria',
          href: '/secretaria',
        },
        {
          text: 'Alterações internas',
          active: true
        },
        {
          text: 'Disciplinas',
          href: '/materias',
          active: true
        }
      ],
    }
  },
  methods: {
    getRequestParams(searchTitle, page, perPage, cycle) {
      let params = {}

      if (searchTitle) {
        params["search"] = searchTitle
      }

      if (page) {
        params["page"] = page
      }

      if (perPage) {
        params["limit"] = perPage
      }

      if (cycle) {
        params["cycle_id"] = cycle
      }

      return params
    },

    getList() {
      const params = this.getRequestParams(
        this.searchTitle,
        this.page,
        this.perPage,
        this.cycle
      );

      DataService.getAll(params)
        .then(response => {
          this.subjects = response.data.subjects;
          this.count = response.data.count;
          this.isRequestSend = true
        })
        .catch(error => {
          return error.response
        });
    },

    exclude(id) {
      this.$swal.fire({
        title: 'Tem certeza?',
        text: 'Você não poderá reverter isso!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#68c5b7',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim, excluir!',
        cancelButtonText: 'Cancelar'
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await DataService.delete(id);
            this.$swal
              .fire("Excluído!", "Disciplina foi excluída.", "success")
              .then((response) => {
                this.getList();
                return response.data
              });
          } catch (error) {
            this.$swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Não foi possivel excluir!",
              footer: "Por favor, tente novamente mais tarde.",
            });

            return error.response
          }
        }
      }).catch(error => {
        this.$swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Não foi possivel excluir!',
          footer: 'Por favor, tente novamente mais tarde.'
        })

        return error.response
      });
    },

    orderName() {
      this.descOrder = !this.descOrder
    },

    callbackCycles({ id }) {
      this.cycle = id !== -1 ? id : null
      this.page = 1
      this.getList()
    },

    changePage(page) {
      this.page = page
      this.getList()
      window.scrollTo({ top: 0, behavior: "smooth" })
    },

    clearFields(searchTitle, cycle, page) {
      this.searchTitle = ''
      this.cycle = null
      this.page = 1
      this.getList({ search: searchTitle, cycle_id: cycle, page: page })
    },

    updateStatus(id, status) {
      var data = {
        id: id,
        is_active: status
      };
      DataService.update(id, data)
        .then(response => {
          const Toast = this.$swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
            didOpen: (toast) => {
            toast.addEventListener('mouseenter', this.$swal.stopTimer)
            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
          }})

          Toast.fire({
            icon: 'success',
            title: `Status atualizado com sucesso!`
          })

          return response.data
        }).catch(error => {
          this.$swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Não foi possivel atualizar!',
            footer: 'Por favor, tente novamente mais tarde.'
          })

          return error.response
        })
    }
  },

  created() {
    if(this.$route.params.page)
      this.getList({
        page: this.$route.params.page,
        limit: this.perPage, cycle_id: this.cycle})
    else if(!this.$route.params.page)
      this.getList()
  },

  computed: {
    orderbyName() {
      return [...this.subjects].sort((a, b) => {
        const order = this.descOrder ? [1, -1] : [-1, 1]

        if (a.designation < b.designation) return order[0];
        else if (a.designation > b.designation) return order[1];

        return 0;
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/variables.scss";

.home {
  height: 100%;
}

.container-rda {
  padding-bottom: 0;
}

.tags {
  background-color: $blue;
  border-radius: 5px;
  color: $white;
  font-weight: $bold;
  line-height: 28px;
  margin: 3px 12px 3px 0;
  max-width: 58px;
  padding: 0 3px;
  text-align: center;
  vertical-align: middle;
  width: 100%;
}

</style>
