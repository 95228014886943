<template>
  <div class="loadingView" v-if="loadingForm">
    <LoadingList v-model="loadingForm" text="Carregando dados." />
  </div>
  <div v-else>
    <div class="wrap-informed">
      <div
        v-for="(value, index) in value"
        :key="index"
        :style="{ position: 'relative', zIndex: 10 - (index + 1) }"
      >
        <b-row
          class="information row mb-2"
          :style="{ position: 'relative', zIndex: 10 - (index + 1) }"
        >
          <b-col md="4">
            <b-form-group class="required">
              <label for="">Informações preenchidas anteriormente</label>
              <multiselect
                open-direction="bottom"
                v-model="value.previousInformed"
                :options="options.boolean"
                :multiple="false"
                class="required"
                placeholder="Selecione"
                selectLabel=""
                @input="setPreviousSelected($event, index)"
              />
            </b-form-group>
          </b-col>
          <b-col md="4" v-if="value.previousInformed === 'Sim'">
            <b-form-group class="required">
              <label for="">Repetir dados de:</label>
              <multiselect
                open-direction="bottom"
                v-model="value.formData"
                :options="contractorList"
                :multiple="false"
                class="required"
                required
                label="name"
                placeholder="Selecione"
                selectLabel=""
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row v-if="value.previousInformed === 'Não'" class="information row">
          <b-col md="12">
            <b-card-header
              header-tag="header"
              role="tab"
              class="title-collapse"
            >
              <h5 v-b-toggle="`accordion-${index + 1}`">
                Informações do {{ value.type }}
              </h5>
            </b-card-header>
            <b-collapse
              :id="`accordion-${index + 1}`"
              visible
              accordion="my-accordion"
              role="tabpanel"
            >
              <b-row>
                <b-col md="2" v-if="!value.deceased && !value.not_applicable">
                  <ProfilePhoto
                    :photo="
                      typeof value.formData.photo !== 'string'
                        ? photoContractor[index]
                        : value.formData.photo
                    "
                    :callback="(photo) => setNewPhoto(photo, index)"
                  />
                </b-col>
                <b-col>
                  <b-row class="mb-3" v-if="!value.not_applicable">
                    <b-col>
                      <b-check
                        v-model="value.deceased"
                        @change="setDeceased($event, index)"
                        >Filiado é Falecido</b-check
                      >
                    </b-col>
                  </b-row>
                  <b-row v-if="!value.deceased && index == 1" class="mb-3">
                    <b-col>
                      <b-check
                        v-model="value.not_applicable"
                        @change="setNotApplicable($event, index)"
                        >Não Há</b-check
                      >
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col v-if="!value.not_applicable">
                      <b-form-group class="required">
                        <label for="label">Relação com o aluno</label>
                        <multiselect
                          open-direction="bottom"
                          v-model="value.formData.relationship"
                          :options="options.relationships"
                          :multiple="false"
                          placeholder="Selecione"
                          class="required"
                          selectLabel=""
                        />
                      </b-form-group>

                      <b-form-group
                        class="required"
                        v-if="!value.deceased && !value.not_applicable"
                      >
                        <label for="label"
                          >Contato prioritário assuntos financeiros</label
                        >
                        <multiselect
                          open-direction="bottom"
                          v-model="value.formData.is_financial_contact"
                          :options="options.boolean"
                          :multiple="false"
                          placeholder="Selecione"
                          selectLabel=""
                        />
                      </b-form-group>

                      <StandardInput
                        v-if="!value.deceased && !value.not_applicable"
                        v-model="value.formData.workplace"
                        class="required"
                        type="text"
                        name="student-custody"
                        label="Local de trabalho"
                      />

                      <StandardInput
                        v-if="!value.deceased && !value.not_applicable"
                        v-model="value.formData.talents"
                        class="required"
                        type="text"
                        name="activities"
                        label="Habilidades/Talentos que possam contribuir com a Escola da Serra"
                        :required="false"
                      />
                    </b-col>

                    <b-col>
                      <b-form-group
                        class="required"
                        v-if="!value.deceased && !value.not_applicable"
                      >
                        <label for="label">Detentor da guarda do aluno</label>
                        <multiselect
                          open-direction="bottom"
                          v-model="value.formData.is_guardian"
                          :options="options.boolean"
                          :multiple="false"
                          placeholder="Selecione"
                          selectLabel=""
                        />
                      </b-form-group>

                      <StandardInput
                        v-if="!value.not_applicable"
                        v-model="value.formData.name"
                        class="required"
                        type="text"
                        name="student-custody"
                        label="Nome Completo"
                        :error="nameStudantValidate"
                        labelError="Digite nome e sobrenome"
                        validateFullName
                      />

                      <StandardInput
                        v-if="!value.deceased && !value.not_applicable"
                        v-model="value.formData.nationality"
                        class="required"
                        type="text"
                        name="country"
                        label="Nacionalidade"
                      />
                    </b-col>

                    <b-col v-if="!value.deceased && !value.not_applicable">
                      <b-form-group class="required">
                        <label for="label"
                          >Contato prioritário assuntos pedagógicos</label
                        >
                        <multiselect
                          open-direction="bottom"
                          v-model="value.formData.is_pedagogical_contact"
                          :options="options.boolean"
                          :multiple="false"
                          placeholder="Selecione"
                          selectLabel=""
                        />
                      </b-form-group>

                      <StandardInput
                        v-model="value.formData.occupation"
                        class="required"
                        type="text"
                        name="occupation"
                        label="Ocupação"
                      />

                      <StandardInput
                        v-if="!value.deceased && !value.not_applicable"
                        v-model="value.formData.country_origin"
                        class="required"
                        type="text"
                        name="country_origin"
                        label="Naturalidade"
                      />
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <b-row
                class="group"
                v-if="!value.deceased && !value.not_applicable"
              >
                <b-col>
                  <h5>Dados de endereço</h5>
                </b-col>
              </b-row>
              <b-row v-if="!value.deceased && !value.not_applicable">
                <b-col md="3">
                  <b-form-group class="required">
                    <label for="">Endereço igual ao do aluno</label>
                    <multiselect
                      open-direction="bottom"
                      v-model="sameAddress[index]"
                      :options="options.boolean"
                      :multiple="false"
                      placeholder="Selecione"
                      selectLabel=""
                      @input="resetAddressForm(index)"
                    />
                  </b-form-group>
                </b-col>
              </b-row>

              <template v-if="sameAddress[index] == 'Não'">
                <b-row class="" v-if="!value.deceased && !value.not_applicable">
                  <b-col>
                    <StandardInput
                      v-model="value.formData.Address.cep"
                      class="required required-cme"
                      type="text"
                      name="cep"
                      label="CEP"
                      required
                      v-mask="'#####-###'"
                      change="getAddress(value.formData.Address.cep, index)"
                    />
                  </b-col>
                  <b-col>
                    <b-form-group class="required required-cme">
                      <label for="label">Estado</label>
                      <multiselect
                        open-direction="bottom"
                        v-model="value.formData.Address.state"
                        :options="states"
                        :multiple="false"
                        placeholder="Selecione um estado"
                        selectLabel=""
                      />
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <StandardInput
                      v-model="value.formData.Address.city"
                      class="required required-cme"
                      type="text"
                      name="city"
                      label="Cidade"
                      required
                    />
                  </b-col>
                  <b-col>
                    <StandardInput
                      v-model="value.formData.Address.neighborhood"
                      class="required required-cme"
                      type="text"
                      name="neighborhood"
                      required
                      label="Bairro"
                    />
                  </b-col>
                </b-row>
                <b-row v-if="!value.deceased && !value.not_applicable">
                  <b-col>
                    <StandardInput
                      v-model="value.formData.Address.street"
                      class="required required-cme"
                      type="text"
                      name="address"
                      required
                      label="Endereço"
                    />
                  </b-col>
                  <b-col>
                    <StandardInput
                      v-model="value.formData.Address.number"
                      class="required-cme"
                      type="text"
                      required
                      name="number"
                      label="Número"
                    />
                  </b-col>
                  <b-col>
                    <StandardInput
                      v-model="value.formData.Address.complement"
                      type="text"
                      name="complement"
                      label="Complemento"
                      :required="false"
                    />
                  </b-col>
                  <b-col> </b-col>
                </b-row>
              </template>

              <b-row
                class="group"
                v-if="!value.deceased && !value.not_applicable"
              >
                <b-col>
                  <h5>Contatos</h5>
                </b-col>
              </b-row>
              <b-row v-if="!value.deceased && !value.not_applicable">
                <b-col>
                  <StandardInput
                    v-model="value.formData.phone1"
                    class="required"
                    type="text"
                    name="phone-1"
                    label="Telefone 1"
                    v-mask="'(##) #####-####'"
                  />
                </b-col>
                <b-col>
                  <StandardInput
                    v-model="value.formData.phone2"
                    :required="false"
                    type="text"
                    name="phone-2"
                    label="Telefone 2"
                    v-mask="'(##) #####-####'"
                  />
                </b-col>
                <b-col>
                  <StandardInput
                    v-model="value.formData.phone_commercial"
                    :required="false"
                    type="text"
                    name="commercial-phone"
                    label="Telefone Comercial"
                    v-mask="'(##) #####-####'"
                  />
                </b-col>
              </b-row>
              <b-row v-if="!value.deceased && !value.not_applicable">
                <b-col md="4">
                  <StandardInput
                    v-model="value.formData.email"
                    class="required"
                    type="email"
                    name="email"
                    label="Seu e-mail"
                  />
                </b-col>
              </b-row>
              <!-- Group -->

              <!-- Group -->
              <b-row
                class="group"
                v-if="!value.deceased && !value.not_applicable"
              >
                <b-col>
                  <h5>Documentos</h5>
                </b-col>
              </b-row>
              <b-row v-if="!value.deceased && !value.not_applicable">
                <b-col>
                  <fieldset class="form-group required-cme required">
                    <legend></legend>
                    <label for="hasSiblings">CPF</label>
                    <b-form-input
                      v-model="value.formData.cpf"
                      type="text"
                      name="cpf"
                      label="CPF"
                      v-mask="'###.###.###-##'"
                      placeholder=""
                    ></b-form-input>
                  </fieldset>
                </b-col>
                <b-col>
                  <StandardInput
                    v-model="value.formData.rg"
                    class="required"
                    type="text"
                    name="rg"
                    label="RG"
                    placeholder=""
                  />
                </b-col>
                <b-col></b-col>
              </b-row>
            </b-collapse>
          </b-col>
        </b-row>
      </div>
    </div>
    <b-row class="wrap-add" v-if="value.length < 2">
      <b-col>
        <b-button class="btn large blue mb-5 add" @click="addFiliate()"
          ><b-icon-plus-lg />Adicionar Filiação</b-button
        >
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ProfilePhoto from "@/components/ProfilePhoto.vue";
import StandardInput from "@/components/inputs/StandardInput.vue";
import httpRda from "../../../http-rda";
import getAddressByCep from "../../../services/getAddressByCep";
import getStateNameByAcronym from "../../../utils/getStateNameByAcronym";
import LoadingList from "@/components/LoadingList.vue";

export default {
  name: "Filiation",
  components: {
    ProfilePhoto,
    StandardInput,
    LoadingList,
  },

  props: ["value", "nameStudantValidate"],

  computed: {
    ...mapGetters({
      statesList: "shared/states",
    }),
    states() {
      return [...this.statesList.map((c) => c.name), "Não se aplica"];
    },
  },

  data() {
    return {
      loadingForm: true,
      sameAddress: [],
      studentAddressId: null,
      enrollment_id: this.$route.params.id,
      contractorList: [],
      photoContractor: [],
      alphabet: ["A", "B"],
      formInputs: {
        relationship: "",
        is_guardian: "",
        is_pedagogical_contact: "",
        is_financial_contact: "",
        is_address_same_student: false,
        photo: "",
        name: "",
        occupation: "",
        workplace: "",
        nationality: "",
        country_origin: "",
        talents: "",
        phone1: "",
        phone2: "",
        phone_commercial: "",
        email: "",
        allow_email: false,
        cpf: "",
        rg: "",
        Address: {
          id: null,
          cep: null,
          number: null,
          complement: null,
          street: null,
          neighborhood: null,
          city: null,
          state: null,
          country: null,
        },
      },

      formInputsDeceased: {
        relationship: "",
        is_guardian: "Não",
        is_pedagogical_contact: "Não",
        is_financial_contact: "Não",
        is_address_same_student: false,
        photo: "",
        name: "",
        occupation: "not applicable",
        workplace: "not applicable",
        nationality: "not applicable",
        country_origin: "not applicable",
        talents: "not applicable",
        phone1: "not applicable",
        phone2: "not applicable",
        phone_commercial: "not applicable",
        email: "not_applicable@notapplicable.com",
        allow_email: "Não",
        cpf: "00000000000",
        rg: "not applicable",
        Address: {
          id: undefined,
          cep: null,
          number: null,
          complement: null,
          street: null,
          neighborhood: null,
          city: null,
          state: null,
          country: null,
        },
      },

      formInputsNotApplicable: {
        relationship: "",
        is_guardian: "Não",
        is_pedagogical_contact: "Não",
        is_financial_contact: "Não",
        is_address_same_student: false,
        photo: "",
        name: "Não Há",
        occupation: "not applicable",
        workplace: "not applicable",
        nationality: "not applicable",
        country_origin: "not applicable",
        talents: "not applicable",
        phone1: "not applicable",
        phone2: "not applicable",
        phone_commercial: "not applicable",
        email: "not_applicable@notapplicable.com",
        allow_email: "Não",
        cpf: "00000000000",
        rg: "not applicable",
        Address: {
          id: undefined,
          cep: null,
          number: null,
          complement: null,
          street: null,
          neighborhood: null,
          city: null,
          state: null,
          country: null,
        },
      },

      options: {
        relationships: ["Pai", "Mãe", "Avô", "Avó", "Tio", "Tia", "Outra"],
        boolean: ["Sim", "Não"],
      },
    };
  },

  methods: {
    ...mapGetters({
      student: "enrollment/getStudent",
    }),

    async getAddress(cep, filiationIndex) {
      const resp = await getAddressByCep(cep);
      this.value[filiationIndex].Address.state = getStateNameByAcronym(resp.uf);
      this.value[filiationIndex].Address.city = resp.localidade;
      this.value[filiationIndex].Address.neighborhood = resp.bairro;
      this.value[filiationIndex].Address.street = resp.logradouro;
    },

    resetAddressForm(index) {
      this.value[index].formData.Address = {
        id: null,
        cep: null,
        number: null,
        complement: null,
        street: null,
        neighborhood: null,
        city: null,
        state: null,
        country: null,
      };
    },

    setDeceased(value, index) {
      if (value === true) {
        this.value[index].not_applicable = false;
        this.value[index].formData = {
          ...this.formInputsDeceased,
          id: this.value[index].formData.id,
        };
        this.value[index].deceased = true;
      } else {
        this.value[index].formData = {
          ...this.formInputs,
          id: this.value[index].formData.id,
        };
        this.value[index].deceased = false;
      }
    },

    setNotApplicable(value, index) {
      if (value === true) {
        this.value[index].deceased = false;
        this.value[index].formData = {
          ...this.formInputsNotApplicable,
          id: this.value[index].formData.id,
        };
        this.value[index].not_applicable = true;
      } else {
        this.value[index].formData = {
          ...this.formInputs,
          id: this.value[index].formData.id,
        };
        this.value[index].not_applicable = false;
      }
    },

    setPreviousSelected(value, formIndex) {
      this.value[formIndex].formData = this.formInputs;
    },

    positionAlphabet(index) {
      return this.alphabet[index];
    },

    async getStudentAddressId(enrollmentId) {
      const resp = await httpRda
        .get(`enrollments/${enrollmentId}`)
        .then((resp) => resp.data);
      this.studentAddressId = resp.enrollment.EnrollmentStudent.address_id;
    },

    defineTypeOfFiliate() {
      for (const filiation of this.value) {
        if (filiation.formData.ResponsibleTypes) {
          for (const key of filiation.formData.ResponsibleTypes) {
            switch (key.type) {
              case "filiation a":
                return "Filiado B";

              case "filiation b":
                return "Filiado A";
            }
          }
        }

        switch (filiation.type) {
          case "Filiado A":
            return "Filiado B";
        }
      }
      return "Filiado A";
    },

    addFiliate() {
      this.value.push({
        previousInformed: false,
        formData: this.formInputs,
        type: this.defineTypeOfFiliate(),
        deceased: false,
        not_applicable: false,
      });
    },

    // Form
    setNewPhoto(photo, index) {
      const bodyFormData = new FormData();
      bodyFormData.append("file", photo);
      this.value[index].formData.photo = bodyFormData;

      this.photoContractor[index] = photo;
    },

    defineIfIsSameAddressOfStudent(filiation) {
      if (filiation.formData.address_id !== this.studentAddressId) {
        return "Não";
      } else {
        return "Sim";
      }
    },
    defineIfIsDeceased(filiation) {
      if (
        filiation.formData.cpf.replace(/[^a-zA-Z0-9 ]/g, "") == "00000000000" &&
        filiation.formData.name !== "Não Há"
      ) {
        return true;
      } else {
        return false;
      }
    },

    defineIfIsNotApplicable(filiation) {
      if (
        (filiation.formData.cpf.replace(/[^a-zA-Z0-9 ]/g, "") ==
          "00000000000" &&
          filiation.formData.name == "Não Há") ||
        filiation.formData.relationship == "" ||
        !filiation.formData.relationship
      ) {
        return true;
      } else {
        return false;
      }
    },

    async loadFiliates() {
      if (this.value.length == 0) {
        this.addFiliate();
      } else {
        await this.getStudentAddressId(this.$route.params.id);

        for (const filiation of this.value) {
          if (filiation.formData !== undefined) {
            for (const key in filiation.formData) {
              if (filiation.formData[key] === true) {
                filiation.formData[key] = "Sim";
              } else if (filiation.formData[key] === false) {
                filiation.formData[key] = "Não";
              }
            }

            if (
              filiation.formData.cpf.replace(/[^a-zA-Z0-9 ]/g, "") ==
                "00000000000" &&
              filiation.formData.name !== "Não Há"
            ) {
              filiation.deceased = true;
              filiation.not_applicable = false;
            } else if (
              filiation.formData.name == "Não Há" &&
              filiation.formData.cpf.replace(/[^a-zA-Z0-9 ]/g, "") ==
                "00000000000"
            ) {
              filiation.not_applicable = true;
              filiation.deceased = false;
            } else {
              filiation.deceased = false;
              filiation.not_applicable = false;
            }
          }
        }

        this.value.map((filiation, index) => {
          if (
            filiation.formData !== undefined &&
            filiation.formData.ResponsibleTypes
          ) {
            filiation.formData.ResponsibleTypes.find((responsibleType) => {
              switch (responsibleType.type) {
                case "filiation a":
                  this.value[index].type = "Filiado A";
                  this.value[index].formData.is_address_same_student =
                    this.defineIfIsSameAddressOfStudent(filiation);
                  this.value[index].deceased =
                    this.defineIfIsDeceased(filiation);
                  this.value[index].not_applicable =
                    this.defineIfIsNotApplicable(filiation);
                  break;

                case "filiation b":
                  this.value[index].type = "Filiado B";
                  this.value[index].formData.is_address_same_student =
                    this.defineIfIsSameAddressOfStudent(filiation);
                  this.value[index].deceased =
                    this.defineIfIsDeceased(filiation);
                  this.value[index].not_applicable =
                    this.defineIfIsNotApplicable(filiation);
                  break;
              }
            });
          }
        });
      }
      this.loadingForm = false;
    },

    async getContractors() {
      await httpRda
        .get(`/enrollments/${this.enrollment_id}/responsibles`)
        .then((response) => {
          this.contractorList = response.data.responsibles
            .filter((responsible) =>
              responsible.ResponsibleTypes[0].type.includes("contractor")
            )
            .map((contractor) => {
              return {
                id: contractor.id,
                name: contractor.name,
              };
            });
        });
    },
  },

  watch: {
    sameAddress: function (newValue) {
      newValue.map((value, index) => {
        this.value[index].formData.is_address_same_student = value;
      });
    },
  },

  mounted() {},

  created() {
    this.getContractors();
    this.loadFiliates();

    this.value.map((filiation, index) => {
      if (filiation.formData !== undefined) {
        if (filiation.formData.address_id !== this.studentAddressId) {
          this.sameAddress[index] = "Não";
        } else {
          this.sameAddress[index] = "Sim";
        }
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.wrap-informed {
  position: relative;
  z-index: 10;
}
.information {
  position: relative !important;
}
.wrap-add {
  padding-top: 40px;
  position: relative;
  z-index: 0;

  .add {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;

    svg {
      font-size: 16px !important;
      position: relative;
      top: -1px;
    }
  }
}

.title-collapse {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;

  h5 {
    flex: 1;
  }
}
.btn-remove {
  background: transparent;
  height: 47px;
  border: 0;

  svg {
    font-size: 16px;
    fill: red;
  }
}

.group {
  border-top: 1px solid #b3b3b3;
  padding-top: 30px;
  margin-top: 30px;
}
</style>
