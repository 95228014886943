<template>
  <b-container class="container-rda">
    <b-row>
      <b-col>
        <b-breadcrumb :items="breadcrumb"></b-breadcrumb>
      </b-col>
    </b-row>
    <b-row class="mb-4">
      <b-col>
        <h2 class="purple bold">Autorizações para professores</h2>
      </b-col>
      <b-col class="text-right"> </b-col>
    </b-row>
    <b-row>
      <b-col md="4">
        <SelectSubjectsWithCycle
          v-model="subject"
          :callback="callbackSubjects"
        />
        <span
          class="clear-field"
          v-if="this.subject != null"
          @click="clearSubject"
          >Limpar filtro</span
        >
      </b-col>
      <b-col>
        <b-form-group label="Buscar">
          <b-input-group-prepend>
            <b-form-input
              type="search"
              placeholder="Digite aqui"
              v-model="searchTitle"
              v-on:keyup.enter="
                page = 1;
                getList();
              "
            >
            </b-form-input>
            <b-input-group-text
              class="clear-fields"
              v-if="searchTitle != '' || subject != null"
              v-b-tooltip.hover
              title="Limpar Pesquisa"
            >
              <b-icon icon="x" @click="clearFields" />
            </b-input-group-text>
          </b-input-group-prepend>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <div class="order-grid-box">
          <p
            class="order-list"
            :class="{ desc: descOrder }"
            v-on:click="orderName"
          >
            Ordenar
            <OrderNameIcon />
          </p>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-container fluid>
          <b-row
            class="header-list-teacher"
            v-if="teachers.length > 0 && isRequestSend"
          >
            <b-col md="4">
              <p class="bold">Usuário</p>
            </b-col>
            <b-col>
              <p class="bold">Disciplinas</p>
            </b-col>
            <b-col>
              <p class="bold">Atualizar RDA</p>
            </b-col>
            <b-col md="3">
              <p class="bold">Inscrever alunos em matéria opcional</p>
            </b-col>
            <b-col>
              <p class="bold">Perfil de Responsável</p>
            </b-col>
          </b-row>
        </b-container>
        <b-container fluid>
          <b-row
            align-v="center"
            class="list-teacher"
            v-for="teacher in orderbyName"
            v-bind:key="teacher.id"
          >
            <b-col md="4">
              <p>{{ teacher.User.name }}</p>
            </b-col>
            <b-col class="disciplines">
              <p
                class="name"
                v-for="subject in teacher.Subjects"
                v-bind:key="subject.id"
              >
                {{ subject.designation }}<span class="comma">, </span>
              </p>
            </b-col>
            <b-col>
              <b-form-checkbox
                v-if="teacher.User.Roles.some((c) => c.id === 5)"
                checked="true"
                @input="removeRoleTeacher(teacher.id, 5)"
              ></b-form-checkbox>
              <b-form-checkbox
                v-else
                checked="false"
                @input="addRoleTeacher(teacher.id, 5)"
              ></b-form-checkbox>
            </b-col>
            <b-col md="3">
              <b-form-checkbox
                v-if="teacher.User.Roles.some((c) => c.id === 6)"
                checked="true"
                @input="removeRoleTeacher(teacher.id, 6)"
              ></b-form-checkbox>
              <b-form-checkbox
                v-else
                checked="false"
                @input="addRoleTeacher(teacher.id, 6)"
              ></b-form-checkbox>
            </b-col>
            <b-col>
              <b-form-checkbox
                v-if="teacher.User.Roles.some((c) => c.id === 2)"
                checked="true"
                disabled
              ></b-form-checkbox>
              <b-form-checkbox
                v-else
                checked="false"
                @input="addRoleTeacher(teacher.id, 2)"
              ></b-form-checkbox>
            </b-col>
          </b-row>
          <b-row
            align-v="center"
            class="list-teacher"
            v-if="teachers.length <= 0 && isRequestSend"
          >
            <b-col> Nenhum resultado encontrado </b-col>
          </b-row>
          <b-row align-v="center" class="list-teacher" v-if="!isRequestSend">
            <b-col> Carregando </b-col>
          </b-row>
          <b-row
            class="align-center"
            v-else-if="count > perPage && isRequestSend"
          >
            <b-pagination
              v-model="page"
              :per-page="perPage"
              :total-rows="count"
              @change="changePage"
              aria-controls="teachersList"
              last-text=""
              first-text=""
            >
              <template #prev-text><LeftArrowIcon class="left" /></template>
              <template #next-text><LeftArrowIcon /></template>
            </b-pagination>
          </b-row>
        </b-container>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import DataService from "@/services/teachers.service";

import SelectSubjectsWithCycle from "@/components/inputs/SelectSubjectsWithCycle.vue";
import OrderNameIcon from "@/components/svg/OrderNameIcon.vue";
import LeftArrowIcon from "@/components/svg/LeftArrowIcon.vue";

export default {
  name: "coordenacao",
  components: {
    SelectSubjectsWithCycle,
    OrderNameIcon,
    LeftArrowIcon,
  },
  data() {
    return {
      teachers: [],
      descOrder: false,
      isRequestSend: false,

      subject: null,
      searchTitle: "",
      value: false,

      page: 1,
      count: 0,
      perPage: 20,

      breadcrumb: [
        {
          text: "Coordenação",
          href: "/coordenacao",
        },
        {
          text: "Permissões sistema",
          href: "/permissoes",
          active: true,
        },
      ],
    };
  },

  methods: {
    getRequestParams(searchTitle, page, perPage, subject) {
      let params = {};

      if (searchTitle) {
        params["search"] = searchTitle;
      }

      if (page) {
        params["page"] = page;
      }

      if (perPage) {
        params["limit"] = perPage;
      }

      if (subject) {
        params["subjects"] = subject;
      }

      return params;
    },

    getList() {
      const params = this.getRequestParams(
        this.searchTitle,
        this.page,
        this.perPage,
        this.subject
      );

      DataService.getAll(params)
        .then((response) => {
          this.teachers = response.data.teachers;
          this.totals = response.data.count;
          this.isRequestSend = true;
        })
        .catch((error) => {
          return error;
        });
    },

    orderName() {
      this.descOrder = !this.descOrder;
    },

    callbackSubjects({ id }) {
      this.subject = id !== -1 ? id : null;
      this.page = 1;
      this.getList();
    },

    changePage(page) {
      this.page = page;
      this.getList();
      window.scrollTo({ top: 0, behavior: "smooth" });
    },

    clearFields(searchTitle, subject) {
      this.searchTitle = "";
      this.subject = null;
      this.getList({ search: searchTitle, subject_id: subject });
    },

    addRoleTeacher(id, role_id) {
      var data = {
        Roles: [role_id],
      };
      DataService.addRole(id, data)
        .then((response) => {
          this.getList();

          const Toast = this.$swal.mixin({
            toast: true,
            position: "bottom-end",
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer);
              toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "success",
            title: "Autorização adicionada com sucesso!",
          });

          return response.data;
        })
        .catch((error) => {
          this.errorMessage();
          return error.response;
        });
    },

    removeRoleTeacher(id, role_id) {
      var data = {
        Roles: this.orderbyName
          .find((t) => t.id == id)
          .User.Roles.filter((c) => c.id != role_id)
          .map((r) => r.id),
      };
      DataService.removeRole(id, data)
        .then((response) => {
          this.getList();

          const Toast = this.$swal.mixin({
            toast: true,
            position: "bottom-end",
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer);
              toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "success",
            title: "Autorização removida com sucesso!",
          });

          return response.data;
        })
        .catch((error) => {
          this.errorMessage();
          return error.response;
        });
    },

    errorMessage() {
      this.$swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Não foi possivel atualizar!",
        footer: "Por favor, tente novamente mais tarde.",
      });
    },
  },

  created() {
    this.getList({ limit: this.perPage, page: 1 });
  },

  computed: {
    orderbyName() {
      return [...this.teachers].sort((a, b) => {
        const order = this.descOrder ? [1, -1] : [-1, 1];

        if (a.User.name < b.User.name) return order[0];
        else if (a.User.name > b.User.name) return order[1];

        return 0;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  height: 100%;
}
</style>
