<template>
  <b-modal
    ref="timeline_register"
    id="timeline_register"
    hide-footer
    centered
    no-close-on-backdrop
    :title="title"
  >
    <slot></slot>
  </b-modal>
</template>

<script>
export default {
  name: "TimelineRegister",
  components: {
    //
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    callback: {
      type: Function,
      required: false,
    },
  },
  data() {
    return {
      //
    };
  },
  methods: {
    hideModal() {
      this.$refs["timeline_register"].hide();
    },
  },
};
</script>

<style lang="scss">
//
</style>
