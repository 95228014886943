<template>
  <b-form-group :label="label">
    <multiselect
      :value="computedValue"
      :options="year"
      :multiple="false"
      :close-on-select="true"
      :clear-on-select="false"
      :preserve-search="true"
      placeholder="Selecione"
      label="id"
      track-by="id"
      selectLabel=""
      selectedLabel=""
      deselectLabel=""
      @select="callback"
      >
      <template slot="tag" slot-scope="{ option }">
        <span class="custom__tag">
          <span>{{ option.name }}</span>
        </span>
      </template>
      <template slot="clear" slot-scope="props">
        <div class="multiselect__clear" @mousedown.prevent.stop="clearAll(props.search)"></div>
      </template>
      <span slot="noResult">Nada encontrado</span>
    </multiselect>
  </b-form-group>
</template>

<script>

const options = [
    { id: 1 },
    { id: 2 },
    { id: 3 },
  ]

export default {
  props: [
    'value',
    'label',
    'callback'
  ],
  data() {
    return {
      options: '',
      year: options,
    }
  },
  computed: {
    computedValue() {
      if( typeof this.value === 'number' ) {
        return this.year.find( c => c.id == this.value )
      } else {
        return this.value
      }
    }
  }
}
</script>