<template>
  <div class="home">
    <template>
      <b-container class="container-rda sidebar">
        <b-row class="height">
          <sidebar-coordination />
          <b-col class="content">
            <b-row>
              <b-col>
                <b-breadcrumb :items="breadcrumb"></b-breadcrumb>
              </b-col>
            </b-row>
            <b-row class="mb-5">
              <b-col>
                <h2 class="purple bold">Registrar mensalidade escolar</h2>
              </b-col>
              <b-col class="text-right"> </b-col>
            </b-row>

            <b-row
              class="mb-5 pb-5"
              v-for="(price, index) in prices"
              :key="index"
            >
              <b-col md="3">
                <StandardInput
                  v-model="price.calendar_id"
                  type="text"
                  name="designation"
                  label="Calendário"
                  :disabled="true"
                ></StandardInput>
              </b-col>
              <b-col md="3">
                <StandardInput
                  v-model="price.cycle_name"
                  type="text"
                  name="designation"
                  label="Nome do ciclo"
                  placeholder="Fundamental 1° Ciclo"
                >
                </StandardInput>
              </b-col>
              <b-col md="3">
                <StandardInput
                  v-model="price.cycle_description"
                  type="text"
                  name="designation"
                  label="Descrição do ciclo"
                  placeholder="1º, 2º e 3º anos"
                >
                </StandardInput>
              </b-col>
              <b-col md="3">
                <StandardInput
                  v-model="price.instalment_price"
                  type="number"
                  name="designation"
                  label="Valor da mensalidade"
                  placeholder="1951,22"
                  :change="calculateAnnuity(price.instalment_price, index)"
                >
                </StandardInput>
              </b-col>
              <b-col md="3">
                <StandardInput
                  v-model="price.annuity_price"
                  type="number"
                  name="designation"
                  label="Valor da anuidade"
                  placeholder="23412,00"
                  disabled
                >
                </StandardInput>
              </b-col>
              <b-col
                id="col-purple-btn"
                md="3"
                v-if="prices.length > 1 && index > 0"
              >
                <b-button @click="removeRow(index)" id="purple-button"
                  >Deletar</b-button
                >
              </b-col>
            </b-row>
            <b-row>
              <div class="w-100 mt-5"></div>
              <b-col md="4">
                <b-button
                  class="large blue outline"
                  :to="{
                    name: 'mensalidade-escolar',
                  }"
                  >Voltar</b-button
                >
              </b-col>
              <b-col md="3">
                <b-button @click="addLine" class="large blue"
                  >Adicionar linha</b-button
                >
              </b-col>
              <b-col md="4">
                <b-button
                  @click="savePrices"
                  :class="`large blue ${isValid ? '' : 'disabled'}`"
                  >Salvar</b-button
                >
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </template>
  </div>
</template>

<script>
import StandardInput from "@/components/inputs/StandardInput.vue";
import httpRda from "../../../http-rda";

export default {
  name: "nova-turma",
  components: {
    StandardInput,
  },
  data() {
    return {
      price: {
        cycle_name: "",
        cycle_description: "",
        instalment_price: "",
        annuity_price: "",
        calendar_id: "",
      },
      prices: [],
      calendars: [],
      selectedCalendar: this.$route.query.calendar,
      isValid: false,
      breadcrumb: [
        {
          text: "Alterações internas",
        },
        {
          text: "Mensalidade escolar",
        },
        {
          text: "Registrar mensalidade escolar",
        },
      ],
    };
  },

  watch: {
    prices: {
      handler(values) {
        this.isValid = this.pricesValidation(values);
      },
      deep: true,
    },
  },

  methods: {
    removeRow(index) {
      this.prices.splice(index, 1);
    },

    calculateAnnuity(instalment_price, index) {
      const numericInstalment = +instalment_price;

      if (!isNaN(numericInstalment)) {
        const annuityValue = +(numericInstalment * 12).toFixed(2);

        this.prices[index].annuity_price = annuityValue;
        this.prices[index].instalment_price = numericInstalment;
      }
    },

    formatNumberToCurrency(price) {
      const result =
        "R$ " +
        price.toLocaleString("pt-BR", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });

      return result;
    },

    pricesValidation(prices) {
      const answers = [];
      prices.forEach((price) => {
        let logicResult = null;
        logicResult =
          price.cycle_name !== "" &&
          price.cycle_description !== "" &&
          price.instalment_price !== "" &&
          price.annuity_price !== "" &&
          price.calendar_id !== "";

        answers.push(logicResult);
      });

      return answers.every((answer) => answer === true);
    },

    async getCalendars() {
      this.calendars = await httpRda
        .get("/calendars")
        .then((resp) => resp.data.calendars);

      this.calendars = this.calendars.map((calendar) => {
        return {
          id: calendar.id,
          name: calendar.designation,
        };
      });

      this.setCalendar(this.calendars);
    },
    addLine() {
      const newLine = {
        cycle_name: "",
        cycle_description: "",
        instalment_price: "",
        annuity_price: "",
        calendar_id:
          this.selectedCalendar && this.selectedCalendar.name
            ? this.selectedCalendar.name
            : "",
      };

      this.prices.push(newLine);
    },

    setCalendar(calendars) {
      this.selectedCalendar = calendars.find(
        (calendar) => +calendar.id === +this.selectedCalendar
      );

      this.price.calendar_id = this.selectedCalendar.name;
    },

    async savePrices() {
      const isValid = this.prices.every((price) => {
        return (
          price.cycle_name &&
          price.cycle_description &&
          price.instalment_price &&
          price.annuity_price &&
          price.calendar_id
        );
      });

      if (!isValid) {
        alert("Preencha todos os campos");
        return;
      }

      const payload = this.prices.map((price) => {
        return {
          ...price,
          instalment_price: this.formatNumberToCurrency(price.instalment_price),
          annuity_price: this.formatNumberToCurrency(price.annuity_price),
          calendar_id: this.calendars.find(
            (calendar) => calendar.name === price.calendar_id
          ).id,
        };
      });

      await httpRda.post("/prices", payload);
      this.$router.go(-1);
    },
  },
  created() {
    this.prices.push(this.price);
    this.getCalendars();
  },
};
</script>

<style lang="scss">
@import "@/assets/css/variables.scss";

#col-purple-btn {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

#purple-button {
  width: 64px;
  height: 32px;
  background-color: #c23066;
  color: white;
  border: none;
  font-size: 16px;
}

#purple-button:hover {
  background-color: white;
  color: #c23066;
  border: 1px solid #c23066;
}

.home {
  height: 100%;
}

.container-rda {
  padding-bottom: 0;
}

.tags {
  background-color: $blue;
  border-radius: 5px;
  color: $white;
  font-weight: $bold;
  line-height: 28px;
  margin: 3px 12px 3px 0;
  max-width: 58px;
  padding: 0 3px;
  text-align: center;
  vertical-align: middle;
  width: 100%;
}
</style>
