<template>
  <b-form-group label="Disciplinas">
    <multiselect
      :value="computedValue"
      :options="myOptions"
      :multiple="false"
      :close-on-select="true"
      :clear-on-select="false"
      :preserve-search="true"
      placeholder="Todos"
      label="designation"
      track-by="id"
      selectLabel=""
      selectedLabel=""
      deselectLabel=""
      @select="callback"
    >
      <span slot="noResult">Nada encontrado</span>
    </multiselect>
  </b-form-group>
</template>

<script>
import httpRda from "../../http-rda";

export default {
  props: [
    'value',
    'callback',
    'cycle'
  ],
  data() {
    return {
      options: '',
      subjects: [],
    }
  },
  watch:{
    cycle(newValue){
      this.getCycles(newValue);
    }
  },
  created() {
    this.getCycles();
  },
  methods: {
   async getCycles() {

      const params = {
        cycle: this.cycle
      }

      if(params.cycle === -1 ){
        delete params.cycle;
      }

      await httpRda.get('/subjects', {params}).then(response => {
        this.subjects = response.data.subjects;
      }).catch();


    }
  },
  computed: {
    myOptions() {
      if(!this.subjects.length)
        return []

      return [
        ...this.subjects,
        { id: -1, designation: "Todos"  }
      ]
    },
    computedValue() {
      if( typeof this.value === 'number' ){
        return this.subjects.find( c => c.id == this.value )
      } else {
        return this.value
      }
    }
  }
}
</script>
