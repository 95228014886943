import http from "../http-rda";

class DataService {
  getAll(params) {
    return http.get("/cycles", { params });
  }

  getStudents(params) {
    return http.get("/cycles?include=students&scope=lightScope", { params });
  }

  get(id) {
    return http.get(`/cycles/${id}`);
  }

  create(data) {
    return http.post("/cycles", data);
  }

  update(id, data) {
    return http.patch(`/cycles/${id}`, data);
  }

  delete(id) {
    return http.delete(`/cycles/${id}`);
  }

  findByTitle(designation) {
    return http.get(`/cycles?search=${designation}`);
  }

}

export default new DataService();