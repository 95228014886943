<template>
  <div class="home">
    <template>
      <b-container class="container-rda">
        <b-row>
          <b-col>
            <b-breadcrumb :items="breadcrumb"></b-breadcrumb>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <h2 class="purple bold">Ata de reunião</h2>
          </b-col>
          <b-col class="text-right"> </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-row class="profile-row">
              <b-col>
                <img src="@/assets/images/foto-perfil-dados.png" alt="" />
                <div class="content-meeting">
                  <h5>Achilhes Najar Maia</h5>
                  <p>Ciclo: 2º EF</p>
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row class="meetings">
          <b-col>
            <b-container fluid>
              <b-row class="header-list-teacher">
                <b-col md="3">
                  <p class="bold">Disciplina</p>
                </b-col>
                <b-col>
                  <p class="bold">Duração</p>
                </b-col>
                <b-col>
                  <p class="bold">Data</p>
                </b-col>
                <b-col>
                  <p class="bold">Participante</p>
                </b-col>
                <b-col md="4">
                  <p class="bold">Resumo</p>
                </b-col>
                <b-col>
                  <p class="bold">Editar</p>
                </b-col>
              </b-row>
            </b-container>
            <b-container fluid>
              <b-row align-v="center" class="list-teacher">
                <b-col md="3">
                  <p>Português</p>
                </b-col>
                <b-col>
                  <p>1h</p>
                </b-col>
                <b-col>
                  <p>01/10/2019</p>
                </b-col>
                <b-col>
                  <p class="tags">Aluno</p>
                </b-col>
                <b-col md="4">
                  <p>Assunto tratado: Desempnho do aluno durante a classe.</p>
                </b-col>
                <b-col>
                  <a href="aluno/perfil/ata-de-reuniao">
                    <EditIcon />
                  </a>
                </b-col>
              </b-row>
              <b-row class="align-center">
                <b-pagination
                  aria-controls="teachersList"
                  last-text=""
                  first-text=""
                >
                  <template #prev-text><LeftArrowIcon class="left" /></template>
                  <template #next-text><LeftArrowIcon /></template>
                </b-pagination>
              </b-row>
              <StudentEditData />
              <StudentEditPassword />
            </b-container>
          </b-col>
        </b-row>
        <b-row align-v="center" class="mt-5">
          <b-col> </b-col>
          <b-col class="text-right"> </b-col>
          <b-col class="text-right">
            <b-button href="/alunos" class="large blue outline"
              >Voltar</b-button
            >
          </b-col>
          <b-col class="text-right">
            <b-button href="#" class="large blue">Adicionar Ata</b-button>
          </b-col>
        </b-row>
      </b-container>
    </template>
  </div>
</template>

<script>
import EditIcon from '@/components/svg/EditIcon.vue'


export default {
  name: 'reunioes',
  components: {
    EditIcon,
  },
  data() {
    return {
      breadcrumb : [
        {
          text: 'Comunidade',
          href: '/comunidade'
        },
        {
          text: 'Alunos',
          href: '/alunos'
        },
        {
          text: 'Perfil',
          href: '/aluno/perfil'
        },
        {
          text: 'Ata de reunião',
          href: '/reuniao',
          active: true
        },
      ],
    }
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>

@import "@/assets/css/variables.scss";

.profile-row {
  background: $gradient3;
  border-radius: 10px;
  color: $white;
  margin: 30px 0 60px;
  padding: 28px 10px;
  position: relative;
  &:after {
    content: url('~@/assets/images/watermark-profile.png');
    right: 0;
    pointer-events: none;
    position: absolute;
    top: -5px;
  }
  .col {
    display: flex;
    align-items: center;
    img {
      margin-right: 40px;
    }
    .content-meeting {
      h5 {
        margin-bottom: 14px;
      }
      p {
        margin: 0;
      }
    }
  }
}

.meetings {
  .tags {
    background-color: $blue;
    border-radius: 5px;
    color: $white;
    font-weight: $bold;
    line-height: 28px;
    max-width: 82px;
    text-align: center;
    width: 100%;

  }
}

</style>