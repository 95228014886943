import http from "../http-rda";

class DataService {
  getAll(params) {
    return http.get("/competences?include=subjects", { params });
  }

  get(id) {
    return http.get(`/competences/${id}?include=subjects`);
  }

  create(data) {
    return http.post("/competences", data);
  }

  update(id, data) {
    return http.patch(`/competences/${id}`, data);
  }

  delete(id) {
    return http.delete(`/competences/${id}`);
  }

  findByTitle(name) {
    return http.get(`/competences?search=${name}`);
  }

}

export default new DataService();