import httpRda from "../../http-rda";

const listStudents = async ({ commit }, params) => {
  let res = await httpRda.get(`/students?include=cycles,classrooms${params}`);
  commit("LIST_STUDENTS", res.data);
  return res;
};

const deleteStudent = async (_, id) => {
  let res = await httpRda.delete(`/students/${id}`);
  listStudents();
  return res;
};

const getStudent = async (_, id) => {
  let res = await httpRda.get(`/students/${id}`);
  listStudents();
  return res;
};

const listTeachers = async ({ commit }) => {
  let res = await httpRda.get("/teachers");
  commit("LIST_TEACHERS", res.data);
  return res;
};

const listClassroomsByCycle = async ({ commit }, cycle_id) => {
  let res = await httpRda("/classrooms", { cycle_id });
  commit("LIST_CLASSROOMS", res.data);
  return res;
};

const setSubjectsByCycles = async ({ commit }, cycle_id) => {
  let res = await httpRda.get(`cycles/${cycle_id}?include=subjects`);
  commit("SET_SUBJECTS_BY_CYCLES", res.data);
  return res;
};

const getTeacher = async ({ commit }, id) => {
  let res = await httpRda.get(`teachers/${id}?include=subjects,cycles`);
  let resTutors = await httpRda.get("tutors");

  const teacher = res.data.teacher;
  const tutor = resTutors.data.teachers.find((t) => t.id == id);

  if (teacher.Subjects.length) {
    const { subjects, cycles, articulate } = teacher.Subjects.reduce(
      (init, actual) => {
        if (!init.cycles.find((ic) => ic.id === actual.cycle_id))
          init.cycles.push(actual.Cycle);

        delete actual.Cycle;

        if (actual.articulator_id == id) init.articulate.push(actual);

        init.subjects.push(actual);
        return init;
      },
      { subjects: [], cycles: [], articulate: [] }
    );

    teacher.subjects = subjects;
    teacher.cycles = cycles;
    teacher.articulate = articulate;
  }

  delete teacher.Subjects;

  if (tutor) teacher.tutor = tutor.Students;

  commit("SET_TEACHER", res.data);
  return res;
};

const createTeacher = async (_, teacher) => {
  return httpRda.post("/teachers", teacher);
};

const updateTeacher = async (_, teacher) => {
  return httpRda.patch(`teachers/${teacher.id}`, teacher);
};

const updateTeachersSubjects = async (_, { teacher_id, subjects }) => {
  return httpRda.patch(`teachers/${teacher_id}/subjects`, { subjects });
};

const setStudentTutor = async (_, { students, teacher_id }) => {
  if (!students.length) return false;

  try {
    students.map(async ({ id }) => {
      return httpRda.patch(`students/${id}/`, { teacher_id });
    });
    return true;
  } catch (error) {
    return error;
  }
};

const setSubjectArticulator = async (_, { subjects, articulator_id }) => {
  if (!subjects.length) return false;

  try {
    subjects.map(async ({ id }) => {
      return httpRda.patch(`subjects/${id}/`, { articulator_id });
    });
    return true;
  } catch (error) {
    return error;
  }
};

const saveTeacherPhoto = async (_, { file, id }) => {
  return httpRda.patch(`teachers/${id}`, file, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

const saveUserPhoto = async (_, { file, id }) => {
  return httpRda.patch(`users/${id}`, file, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

const saveTeacherPhone = async (_, { id, number }) => {
  return httpRda.post(`teachers/${id}/phones`, { number, is_mobile: false });
};

const updateTeacherPhone = async (_, { id, number, phone_id }) => {
  return httpRda.patch(`teachers/${id}/phones/${phone_id}`, {
    number,
    is_mobile: false,
  });
};

export default {
  listStudents,
  deleteStudent,
  getStudent,
  listTeachers,
  listClassroomsByCycle,
  setSubjectsByCycles,
  setStudentTutor,
  setSubjectArticulator,
  getTeacher,
  createTeacher,
  updateTeacher,
  updateTeachersSubjects,
  saveTeacherPhoto,
  saveTeacherPhone,
  updateTeacherPhone,
  saveUserPhoto,
};
