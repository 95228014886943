import httpRda from "../../http-rda";

const listSyllabusesBySubject = async ({ commit }, { id, ...params }) => {
  let res = await httpRda.get(`subjects/${id}/syllabuses`, { params });
  commit("SYLLABUSES_UPDATE", res.data);
  return res;
};

const batchSaveStudentsSyllabuses = async (
  _,
  { studentsArray, syllabus_id }
) => {
  if (!studentsArray.length) return;

  return studentsArray.map(async ({ student, date }) => {
    return httpRda.post(`syllabuses/${syllabus_id}/students/`, {
      deadline: date,
      student_id: student,
    });
  });
};

const batchDeleteStudentsSyllabuses = async (
  _,
  { studentsArray, syllabus_id }
) => {
  if (!studentsArray.length) return;

  return studentsArray.map(async ({ student }) => {
    return httpRda.delete(`students/${student.id}/syllabuses/${syllabus_id}`);
  });
};

const batchUpdateStudentsSyllabuses = async (
  _,
  { studentsArray, syllabus_id }
) => {
  if (!studentsArray.length) return;

  return studentsArray.map(async ({ student, date }) => {
    return httpRda.patch(`students/${student}/syllabuses/${syllabus_id}`, {
      deadline: date,
    });
  });
};

const saveSyllabus = async (_, syllabus) => {
  return httpRda.post("/syllabuses", syllabus);
};

const updateSyllabus = async (_, { syllabus, Topics }) => {
  const res = await httpRda.patch(`/syllabuses/${syllabus.id}`, syllabus);
  const resTopics = await httpRda.patch(`/syllabuses/${syllabus.id}/topics`, {
    Topics,
  });

  return { syllabus: res.data.syllabus, topics: resTopics.data.topics };
};

const loadSyllabus = async ({ commit }, syllabus_id) => {
  const resSyllabus = await httpRda.get(`/syllabuses/${syllabus_id}`);
  const resTopics = await httpRda.get(
    `/syllabuses/${syllabus_id}/topics?include=competences`
  );
  const resStudents = await httpRda.get(
    `/syllabuses_students?syllabus_id=${syllabus_id}`
  );

  commit("SET_SYLLABUS", resSyllabus.data);
  commit("SET_SYLLABUS_TOPICS", resTopics.data);
  commit("SET_SYLLABUS_STUDENTS", resStudents.data);

  return {
    topics: resTopics.data.topics,
    syllabus: resSyllabus.data.syllabus,
    students: resStudents.data.syllabus_students,
  };
};

const loadSubjectContent = async (_, { student_id, subject_id }) => {
  return httpRda.get(
    `/students/${student_id}/subjects/${subject_id}?include=topics,cycle`
  );
};

const loadStudentsAndSyllabuses = async (
  { commit },
  { syllabus_id, search = "" }
) => {
  let syllabusesStudents = [];
  const syllabuses = await httpRda.get(`subjects/${syllabus_id}/syllabuses`);
  const syllabusesIdStringfied = syllabuses.data.syllabuses
    .map((s) => s.id)
    .join(",");

  if (syllabusesIdStringfied) {
    const getSyllabusesStudents = await httpRda.get(
      `syllabuses_students?syllabus=${syllabusesIdStringfied}&search=${search}`
    );
    syllabusesStudents = getSyllabusesStudents.data.syllabus_students;
  }

  commit("SET_STUDENTS_AND_SYLLABUSES_ARRAY", syllabusesStudents);

  return syllabusesStudents;
};

const saveFile = async (_, { file, id }) => {
  return httpRda.patch(`/syllabuses/${id}`, file, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

const updateFileStudentSyllabus = async (
  _,
  { student_id, syllabus_id, file }
) => {
  if (!file) return;

  const { type } = file.get("file");

  return httpRda.patch(
    `/students/${student_id}/syllabuses/${syllabus_id}`,
    file,
    {
      headers: { "Content-Type": type },
    }
  );
};

const updateStudentSyllabusDeadline = async (
  _,
  { student_id, syllabus_id, ...params }
) => {
  return httpRda.patch(
    `/students/${student_id}/syllabuses/${syllabus_id}`,
    params
  );
};

const getStudentSubjects = async ({ commit }, student_id) => {
  const res = await httpRda.get(`/students/${student_id}/subjects`);
  commit("SET_STUDENT_SUBJECTS", res.data);
  return res;
};

const getStudent = async ({ commit }, student_id) => {
  const res = await httpRda.get(`/students/${student_id}?include=cycles`);
  commit("SET_STUDENT", res.data);
  return res;
};

const searchStudentSyllabus = async ({ commit }, name) => {
  const res = await httpRda.get(`/syllabuses_students?search=${name}`);
  commit("SET_STUDENTS_SYLLABUSES", res.data);
  return res;
};

const getStudentSubjectPercentage = async (_, { student_id, subject_id }) => {
  const res = await httpRda.get(
    `/students/${student_id}/subjects/${subject_id}`
  );

  return res.data.subjects[0];
};

const getSubjectCycle = async ({ commit }, subject_id) => {
  const res = await httpRda.get(`subjects/${subject_id}`);

  if (res.data.subject.cycle_id) commit("SET_SYLLABUS_CYCLE", res.data);

  return res;
};

const updateStudentTopic = async (_, { student_id, topic_id, ...data }) => {
  return httpRda.patch(`students/${student_id}/topics/${topic_id}`, data);
};

const updateStudentTopicEvaluation = async (_, { student_id, topic_id, ...data }) => {
  return httpRda.patch(`students/${student_id}/topics/${topic_id}/evaluation`, data);
};

const updateStudentFileTopic = async (_, { student_id, topic_id, file }) => {
  return httpRda.patch(`students/${student_id}/topics/${topic_id}`, file, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

const getStudentSyllabuses = async ({ commit }, student_id) => {
  const res = await httpRda.get(
    `students/${student_id}/syllabuses?include=subjects`
  );
  const resSubjects = await httpRda.get(`students/${student_id}/subjects`);

  commit("SET_STUDENTS_SYLLABUSES", res.data);
  commit("SET_STUDENT_SUBJECTS", resSubjects.data);

  return res;
};

const getStudentSyllabusById = async (
  { commit },
  { student_id, syllabus_id }
) => {
  const res = await httpRda.get(
    `students/${student_id}/syllabuses/${syllabus_id}?include=teacher`
  );

  commit("SET_SYLLABUS", { syllabus: res.data.syllabuses });

  return res;
};

const updateSyllabusStudent = async (
  _,
  { student_id, syllabus_id, is_next }
) => {
  return httpRda.patch(`students/${student_id}/syllabuses/${syllabus_id}`, {
    is_next,
  });
};

const getStudentsBySyllabus = async ({ commit }, params) => {
  const res = await httpRda.get(`syllabuses_students/`, { params });

  commit("SET_STUDENTS_BY_SYLLABUS", res.data);

  return res;
};

const getStudentsBySubject = async (
  { commit },
  { subject_id, search = "", page = 1, limit = 20 }
) => {
  const res = await httpRda.get(
    `subjects/${subject_id}/students/?search=${search}&page=${page}&limit=${limit}`
  );

  const students = res.data.students;

  commit("SET_STUDENTS_BY_SUBJECT", { students });

  return res;
};

export default {
  listSyllabusesBySubject,
  saveSyllabus,
  loadSyllabus,
  updateSyllabus,
  loadSubjectContent,
  batchSaveStudentsSyllabuses,
  batchDeleteStudentsSyllabuses,
  batchUpdateStudentsSyllabuses,
  loadStudentsAndSyllabuses,
  saveFile,
  updateFileStudentSyllabus,
  updateStudentSyllabusDeadline,
  getStudentSubjects,
  getStudent,
  searchStudentSyllabus,
  getSubjectCycle,
  updateStudentTopic,
  updateStudentTopicEvaluation,
  updateStudentFileTopic,
  getStudentSyllabuses,
  getStudentSyllabusById,
  updateSyllabusStudent,
  getStudentsBySyllabus,
  getStudentsBySubject,

  getStudentSubjectPercentage,
};
