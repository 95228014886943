const students = state => state.students;
const teachers = state => state.teachers;
const classrooms = state => state.classrooms;
const subjectsByCycles = state => state.subjectsByCycles;
const teacher = state => state.teacher;

export default {
  students,
  teachers,
  classrooms,
  subjectsByCycles,
  teacher
}
