var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"edit-password",attrs:{"id":"edit-password","hide-header":"","hide-footer":"","centered":"","no-close-on-backdrop":""}},[(_vm.studentToPassword.id != null)?_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('h4',{staticClass:"purple mb-4"},[_vm._v("Editar senha")]),_c('p',[_vm._v("Digite a nova senha do usuário selecionado:")])]),_c('b-col',{staticClass:"mt-4",attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Usuário/Email"}},[_c('b-form-input',{attrs:{"type":"text","placeholder":_vm.studentToPassword.User.email,"disabled":""}})],1)],1),_c('b-col',{staticClass:"mt-4"},[_c('b-form-group',{attrs:{"label":"Senha"}},[_c('b-form-input',{attrs:{"state":_vm.statePassword,"type":"password","placeholder":""},model:{value:(_vm.newPassword),callback:function ($$v) {_vm.newPassword=$$v},expression:"newPassword"}})],1)],1),_c('b-col',{staticClass:"mt-4"},[_c('b-form-group',{attrs:{"label":"Confirmar senha"}},[_c('b-form-input',{attrs:{"state":_vm.statePassword,"type":"password","placeholder":""},model:{value:(_vm.newPasswordConfirm),callback:function ($$v) {_vm.newPasswordConfirm=$$v},expression:"newPasswordConfirm"}})],1)],1),_c('b-col',{attrs:{"md":"12"}},[(
          (this.newPassword.length < 5 &&
            this.newPasswordConfirm.length < 5) &
          (this.newPassword.length > 0 && this.newPasswordConfirm.length > 0)
        )?_c('p',[_vm._v(" A senha deve ter de 5 a 20 caracteres. ")]):(
          (this.newPassword.length > 0 &&
            this.newPasswordConfirm.length > 0) &
          (this.newPassword != this.newPasswordConfirm)
        )?_c('p',[_vm._v(" As senhas devem ser iguais ")]):_c('p')])],1):_vm._e(),(_vm.studentToPassword.id != null)?_c('b-row',{staticClass:"mt-5"},[_c('b-col',[_c('b-button',{staticClass:"btn large outline",on:{"click":function($event){return _vm.hideModal()}}},[_vm._v("Voltar")])],1),_c('b-col',{staticClass:"text-right"},[_c('b-button',{staticClass:"btn large blue",class:{
          disabled: !_vm.newPassword || !_vm.newPasswordConfirm,
        },on:{"click":_vm.createNewPassword}},[_vm._v("Salvar")])],1)],1):_c('b-row',[_c('b-col',{staticClass:"text-center"},[_c('p',[_vm._v("Ocorreu um problema ao carregar o Aluno")]),_c('p',[_vm._v("Por favor, atualize a pagina e tente novamente.")]),_c('b-button',{staticClass:"btn large blue",on:{"click":_vm.reloadPage}},[_vm._v("Recarregar pagina")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }