<template>
  <b-modal
    ref="edit-password"
    id="edit-password"
    hide-header
    hide-footer
    centered
    no-close-on-backdrop
  >
    <b-row v-if="user.id != null">
      <b-col md="12">
        <h4 class="purple mb-4">Editar senha</h4>
        <p>Digite a nova senha do usuário selecionado:</p>
      </b-col>
      <b-col md="12" class="mt-4">
        <b-form-group label="Usuário/Email">
          <b-form-input
            type="text"
            :placeholder="user.email"
            disabled
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col class="mt-4">
        <b-form-group label="Senha">
          <b-form-input
            v-model="newPassword"
            :state="statePassword"
            type="password"
            placeholder=""
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col class="mt-4">
        <b-form-group label="Confirmar senha">
          <b-form-input
            v-model="newPasswordConfirm"
            :state="statePassword"
            type="password"
            placeholder=""
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col md="12">
        <p
          v-if="
            (this.newPassword.length < 5 &&
              this.newPasswordConfirm.length < 5) &
            (this.newPassword.length > 0 && this.newPasswordConfirm.length > 0)
          "
        >
          A senha deve ter de 5 a 20 caracteres.
        </p>
        <p
          v-else-if="
            (this.newPassword.length > 0 &&
              this.newPasswordConfirm.length > 0) &
            (this.newPassword != this.newPasswordConfirm)
          "
        >
          As senhas devem ser iguais
        </p>
        <p v-else></p>
      </b-col>
    </b-row>
    <b-row class="mt-5" v-if="user.id != null">
      <b-col>
        <b-button class="btn large outline" @click="hideModal()"
          >Voltar</b-button
        >
      </b-col>
      <b-col class="text-right">
        <b-button
          class="btn large blue"
          @click="createNewPassword"
          :class="{
            disabled: !newPassword || !newPasswordConfirm,
          }"
          >Salvar</b-button
        >
      </b-col>
    </b-row>
    <b-row v-else>
      <b-col class="text-center">
        <p>Ocorreu um problema ao carregar</p>
        <p>Por favor, atualize a pagina e tente novamente.</p>
        <b-button class="btn large blue" @click="reloadPage"
          >Recarregar pagina</b-button
        >
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import DataService from "@/services/user.service";
import store from "../../store";

export default {
  name: "",
  components: {},
  user: {},

  data() {
    return {
      message: "",
      newPassword: "",
      newPasswordConfirm: "",
    };
  },
  props: {
    userToEdit: {},
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/getAuthData",
      // users: "shared/users",
    }),

    statePassword() {
      if (this.newPassword.length > 0 && this.newPasswordConfirm.length > 0) {
        if (
          this.newPassword.length >= 5 &&
          this.newPasswordConfirm.length >= 5
        ) {
          return this.newPassword === this.newPasswordConfirm ? true : false;
        } else {
          return false;
        }
      } else {
        return null;
      }
    },
  },

  methods: {
    ...mapActions({
      getUser: "shared/getUser",
    }),

    hideModal() {
      this.$refs["edit-password"].hide();
      this.newPassword = "";
      this.newPasswordConfirm = "";
    },

    reloadPage() {
      window.location.reload();
    },

    createNewPassword() {
      var data = {
        email: this.user.email,
        new_password: this.newPassword,
        confirm_password: this.newPasswordConfirm,
      };

      DataService.createNewPassword(this.user.id, data)
        .then((response) => {
          this.newPassword = "";
          this.newPasswordConfirm = "";
          this.hideModal();
          const Toast = this.$swal.mixin({
            toast: true,
            position: "bottom-end",
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer);
              toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "success",
            title: "Senha atualizada com sucesso!",
          });

          return response.data;
        })
        .catch((error) => {
          this.loading = false;
          this.message =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
          this.$swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Não foi possivel atualizar!",
            footer: `${this.message.error}`,
          });
        });
    },
  },
  created() {
    this.userToEdit === undefined
      ? (this.user = store.getters["auth/getAuthData"])
      : (this.user = this.userToEdit);
  },
};
</script>

<style lang="scss">
@import "@/assets/css/variables.scss";

div#edit-password {
  .modal-dialog {
    max-width: 820px;
    .modal-content {
      background-color: $grey2;
      border: 0;
      border-radius: 0;
      .modal-body {
        padding: 60px;
      }
    }
  }
  .students {
    list-style: none;
    padding: 0;
    margin: 0;
    .student {
      align-items: center;
      display: flex;
      justify-content: space-between;
      line-height: 60px;
      border-bottom: 1px solid $grey5;
      &:last-child {
        border-bottom: 0;
      }
      .name {
        margin: 0;
      }
      .actions {
        a {
          margin: 0 15px;
        }
      }
    }
  }
}
</style>
