
import http from "../http-rda";

class DataService {
  getAll(params) {
    return http.get("/classrooms?include=cycles", { params });
  }

  getStudents(params) {
    return http.get("/classrooms?include=students&scope=lightScope", { params });
  }

  get(id) {
    return http.get(`/classrooms/${id}`);
  }

  create(data) {
    return http.post("/classrooms", data);
  }

  update(id, data) {
    return http.patch(`/classrooms/${id}`, data);
  }

  delete(id) {
    return http.delete(`/classrooms/${id}`);
  }

  findByTitle(designation) {
    return http.get(`/classrooms?search=${designation}`);
  }

}

export default new DataService();