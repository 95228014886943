<template>
  <div class="home">
    <template>
      <b-container class="container-rda">
        <b-row>
          <b-col>
            <b-breadcrumb :items="breadcrumb"></b-breadcrumb>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <h2 class="purple bold">
              Solicitações de exclusão de dados de usuário
              <span class="qty"
                >({{ count }} {{ count > 1 ? "listados" : "listado" }})</span
              >
            </h2>
          </b-col>
        </b-row>
        <b-row class="mt-50">
          <b-col>
            <b-form-group label="Buscar Aluno">
              <b-input-group-prepend>
                <b-form-input
                  type="search"
                  placeholder="Digite aqui"
                  v-model="searchTitle"
                  v-on:keyup.enter="
                    page = 1;
                    getList();
                  "
                ></b-form-input>
                <b-input-group-text
                  class="clear-fields"
                  v-if="searchTitle != ''"
                  v-b-tooltip.hover
                  title="Limpar Pesquisa"
                >
                  <b-icon icon="x" @click="clearFields" />
                </b-input-group-text>
              </b-input-group-prepend>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row align="center" v-if="loading">
          <b-col class="mt-5">
            <b-spinner variant="success" label="Carregando..."></b-spinner>
          </b-col>
        </b-row>

        <b-row v-else>
          <b-col>
            <b-container fluid>
              <b-row
                style="padding-top: 15px"
                class="column-list"
                v-if="count > 0 && isRequestSend"
              >
                <b-col md="3">
                  <p class="bold">Nome</p>
                </b-col>
                <b-col>
                  <p class="bold">E-mail</p>
                </b-col>
                <b-col>
                  <p class="bold">CPF</p>
                </b-col>
                <b-col>
                  <p class="bold">Status</p>
                </b-col>
                <b-col>
                  <p class="bold">Solicitado em</p>
                </b-col>
                <b-col>
                  <p class="bold">Visualizar</p>
                </b-col>
              </b-row>
            </b-container>
            <b-container fluid>
              <b-row
                align-v="center"
                class="list-contracts"
                v-for="request in requests"
                :key="request.id"
              >
                <b-col md="3">
                  <p v-if="request.name">
                    {{ request.name }}
                  </p>
                </b-col>
                <b-col>
                  <p>{{ request.email }}</p>
                </b-col>
                <b-col>
                  <p>{{ request.cpf }}</p>
                </b-col>
                <b-col>
                  <p>
                    {{ request.status }}
                  </p>
                </b-col>

                <b-col>
                  <p>
                    {{ request.created_at }}
                  </p>
                </b-col>

                <b-col>
                  <b-button
                    @click="openModal(request.id)"
                    style="background-color: transparent; border: none"
                  >
                    <ViewIcon />
                  </b-button>
                </b-col>
              </b-row>
              <b-row
                align-v="center"
                class="list-teacher"
                v-if="requests.length <= 0 && isRequestSend"
              >
                <b-col>Nenhum resultado encontrado</b-col>
              </b-row>
              <b-row
                align-v="center"
                class="list-teacher"
                v-if="!isRequestSend"
              >
                <b-col>Carregando</b-col>
              </b-row>
              <b-row
                class="align-center"
                v-else-if="count > perPage && isRequestSend"
              >
                <b-pagination
                  v-model="page"
                  :per-page="perPage"
                  :total-rows="count"
                  @change="changePage"
                  aria-controls="teachersList"
                  last-text
                  first-text
                >
                  <template #prev-text>
                    <LeftArrowIcon class="left" />
                  </template>
                  <template #next-text>
                    <LeftArrowIcon />
                  </template>
                </b-pagination>
              </b-row>
            </b-container>
          </b-col>
        </b-row>

        <b-row class="align-center" v-if="!loading && requests.length > 0">
          <b-pagination
            v-model="currentPage"
            :per-page="20"
            :total-rows="count"
            last-text
            first-text
          >
            <template #prev-text>
              <LeftArrowIcon class="left" />
            </template>
            <template #next-text>
              <LeftArrowIcon />
            </template>
          </b-pagination>
        </b-row>
      </b-container>
      <b-modal
        id="modal-request-details"
        hide-footer
        style="display: flex; justify-content: center; align-items: center"
        title="Detalhes da solicitação"
        size="xl"
        ><div v-if="selectedRequest && selectedRequest.request">
          <b-form-group>
            <b-row>
              <b-col>
                <label style="padding-top: 10px" for="label">Nome</label>
                <b-form-input
                  v-model="selectedRequest.request.name"
                  disabled
                  type="text"
                  name="name"
                  class="form-control"
                ></b-form-input>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <label style="padding-top: 10px" for="label">Nome</label>
                <b-form-input
                  v-model="selectedRequest.request.cpf"
                  disabled
                  type="text"
                  name="cpf"
                  v-mask="'###.###.###-##'"
                  class="form-control"
                ></b-form-input>
              </b-col>

              <b-col>
                <label style="padding-top: 10px" for="label"
                  >Endereço de e-mail</label
                >
                <b-form-input
                  v-model="selectedRequest.request.email"
                  disabled
                  type="text"
                  name="email"
                  class="form-control"
                ></b-form-input>
              </b-col>
            </b-row>

            <label style="padding-top: 10px" for="label">Observação</label>
            <b-form-textarea
              v-model="selectedRequest.request.note"
              name="note"
              disabled
              class="form-control"
            ></b-form-textarea>

            <label style="padding-top: 10px" for="label">Status </label>
            <b-form-input
              v-model="currentStatus"
              :value="currentStatus"
              disabled
              type="text"
              name="status"
              class="form-control"
            ></b-form-input>
          </b-form-group>

          <label style="padding-top: 10px" for="label"
            >Estudantes vinculados a esse responsável</label
          >
          <div
            v-if="selectedRequest.students.length > 0"
            class="accordion"
            role="tablist"
          >
            <b-card
              no-body
              v-for="(target, index) in selectedRequest.students"
              :key="index"
            >
              <b-card-body>
                <div class="wrap-tags">
                  <span> {{ target.id }} - {{ target.name }} </span>
                </div>
              </b-card-body>
            </b-card>
          </div>

          <div v-if="!selectedRequest.students.length > 0">
            <p>Nenhum estudante vinculado a esse responsável</p>
          </div>
        </div>

        <b-form-group
          style="padding-top: 20px; display: flex; justify-content: flex-end"
        >
          <b-row>
            <b-col>
              <b-button @click="closeModal"> Voltar </b-button>
            </b-col>
            <b-col v-if="currentStatus == 'Pendente'">
              <b-button
                @click="updateRequest(selectedRequest.request.id, 'rejected')"
                >Rejeitar</b-button
              >
            </b-col>
            <b-col v-if="currentStatus == 'Pendente'">
              <b-button
                @click="updateRequest(selectedRequest.request.id, 'done')"
                >Confirmar</b-button
              >
            </b-col>
          </b-row>
        </b-form-group>
      </b-modal>
    </template>
  </div>
</template>

<script>
import LeftArrowIcon from "@/components/svg/LeftArrowIcon.vue";
import ViewIcon from "@/components/svg/ViewIcon.vue";

import httpRda from "../../http-rda";
import moment from "moment";

export default {
  name: "lista-contratos",
  components: {
    LeftArrowIcon,
    ViewIcon,
  },
  data() {
    return {
      requests: [],
      isRequestSend: false,

      selectedRequest: null,
      currentStatus: "",

      alreadyPresented: true,

      searchTitle: "",
      is_active: null,

      students: [],
      loadingList: false,

      page: this.$route.params.page || 1,
      count: 0,
      perPage: 20,

      contract: "actives",

      loading: false,

      currentPage: 1,

      breadcrumb: [
        {
          text: "Secretaria",
          href: "/secretaria",
        },
        {
          text: "Solicitações de exclusão de dados de usuário",
          href: "/secretaria/solicitacoes-de-exclusao-de-dados-de-usuario",
          active: true,
        },
      ],
    };
  },

  computed: {},

  methods: {
    async openModal(id) {
      await httpRda.get(`/data-deletion-request/${id}`).then((resp) => {
        this.selectedRequest = resp.data.request;
        this.currentStatus = this.setStatus(
          this.selectedRequest.request.status
        );
      });

      this.$bvModal.show("modal-request-details");
    },

    closeModal() {
      this.$bvModal.hide("modal-request-details");
    },

    async updateRequest(id, status) {
      this.$swal
        .fire({
          title: `Tem certeza que deseja ${
            status == "rejected" ? "rejeitar" : "aceitar"
          } a solicitação?`,
          text: "Essa ação não poderá ser desfeita!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#68c5b7",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim",
          cancelButtonText: "Cancelar",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            try {
              await httpRda.patch(`data-deletion-request/${id}`, {
                status: status,
              });

              const Toast = this.$swal.mixin({
                toast: true,
                position: "bottom-end",
                showConfirmButton: false,
                timer: 5000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", this.$swal.stopTimer);
                  toast.addEventListener("mouseleave", this.$swal.resumeTimer);
                },
              });

              Toast.fire({
                icon: "success",
                title: `Solicitação ${
                  status == "rejected" ? "rejeitada" : "aceita"
                } com sucesso!`,
              });
              this.$bvModal.hide("modal-request-details");
            } catch (error) {
              this.$swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Não foi possível realizar a operação!",
                footer: "Por favor, tente novamente mais tarde.",
              });
              this.$bvModal.hide("modal-request-details");
            }
          }
        })
        .catch((error) => {
          this.$swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Não foi possível realizar a operação!",
            footer: "Por favor, tente novamente mais tarde.",
          });
          this.$bvModal.hide("modal-request-details");
          return error;
        });

      this.getList();
    },

    getRequestParams(searchTitle, page, perPage = 20) {
      let params = {};

      if (searchTitle) {
        params["search"] = searchTitle;
      }

      if (page) {
        params["page"] = page;
      }

      if (perPage) {
        params["limit"] = perPage;
      }

      return params;
    },

    setStatus(status) {
      let result = "";
      switch (status) {
        case "pending":
          result = "Pendente";
          break;

        case "done":
          result = "Concluída";
          break;

        case "rejected":
          result = "Rejeitada";
          break;
        default:
          result = "Indefinido";
          break;
      }

      return result;
    },

    getList(page = 1) {
      this.page = page;
      this.loading = true;
      const params = this.getRequestParams(
        this.searchTitle,
        this.page,
        this.perPage
      );

      this.isRequestSend = false;
      httpRda.get("/data-deletion-request", { params }).then((resp) => {
        this.requests = resp.data.requests.data;
        this.page = resp.data.requests.pagination.page;
        this.count = resp.data.requests.pagination.total;

        this.requests = this.requests.map((request) => {
          request.created_at = moment(request.created_at).format("DD/MM/YYYY");
          request.status = this.setStatus(request.status);

          return request;
        });

        this.isRequestSend = true;
      });
      this.loading = false;
    },

    changePage(page) {
      this.page = page;
      this.getList();
      window.scrollTo({ top: 0, behavior: "smooth" });
    },

    clearFields() {
      this.searchTitle = "";
      this.page = 1;
      this.getList();
    },
  },

  watch: {
    currentPage() {
      this.getList(this.currentPage);
    },
  },

  created() {
    this.getList();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/variables.scss";

.list-contracts {
  background-color: $white;
  border: 1px solid $grey5;
  border-radius: 10px;
  margin-bottom: 15px;
  min-height: 80px;
  margin-right: -12px;
  &:last-child {
    margin-bottom: 0px;
  }
  &:hover {
    cursor: pointer;
    border-color: transparent;
    background-image: linear-gradient($white, $white),
      radial-gradient(circle at top left, $orange, $purple);
    background-origin: border-box;
    background-clip: content-box, border-box;
  }
  p {
    margin: 0;
  }
}

.header-list {
  color: $grey1;
  p {
    margin: 0;
  }
  .tags {
    background-color: $blue;
    border-radius: 5px;
    color: $white;
    line-height: 28px;
    max-width: 58px;
    text-align: center;
  }
}

.column-list {
  color: $grey1;
}

.status-box {
  margin: 0 0 60px;
}

.teacher-profile {
  .form-group {
    &.required {
      &:after {
        right: 0;
      }
    }
  }
}

.table-students {
  width: 100%;

  td,
  th {
    font-size: 18px;
  }

  th {
    padding-bottom: 20px;
  }

  td {
    padding: 20px 0;
  }

  tr td:last-of-type {
    text-align: right;

    .actions {
      span {
        cursor: pointer;
      }

      .deleteStudent {
        margin-left: 30px;
      }
    }
  }

  tr + tr {
    border-bottom: 1px solid $grey5;
  }
}

.title-collapse {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;

  h5 {
    flex: 1;
  }

  .btn {
    background: transparent;
    border: 0;

    svg {
      font-size: 16px;
      fill: red;
    }
  }
}

.wrap-tags {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 10px;

  span {
    background-color: #efefef;
    padding: 4px 8px;
    border-radius: 4px;
    box-sizing: border-box;
  }
}

.wrap-button {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 6px;
}

.tag {
  background-color: #68c5b7;
  border-radius: 5px;
  color: #ffffff;
  font-weight: 700;
  line-height: 28px;
  margin: 3px 12px 3px 0;
  padding: 0 3px;
  padding-left: 8px;
  padding-right: 8px;
  text-align: center;
  vertical-align: middle;
  max-width: 100%;
}

.container-rda .multiselect__tag-icon:after {
  color: white;
}

input.isInvalid {
  border-color: red;
}
</style>
