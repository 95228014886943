<template>
  <div class="list-page">
    <b-container class="container-rda">
      <b-row class="height">
        <b-col class="content">
          <b-row>
            <b-col>
              <b-breadcrumb :items="breadcrumb"></b-breadcrumb>
            </b-col>
          </b-row>
          <b-row class="mb-5">
            <b-col>
              <h2 class="purple bold">Roteiros</h2>
            </b-col>

            <b-col class="text-right" v-if="subject">
              <b-button
                class="btn large blue"
                :to="{
                  name: 'registrar-roteiro',
                  params: {
                    subject: this.subject,
                    syllabus_id: this.subject,
                  },
                }"
                >Adicionar roteiro</b-button
              >
            </b-col>
          </b-row>

          <b-row>
            <b-col md="12">
              <p class="purple">Filtre para obter resultados</p>
            </b-col>
            <b-col md="4">
              <SelectSubjectsWithCycle
                v-model="subject"
                :callback="selectSubject"
              />
              <span
                class="clear-field"
                v-if="this.subject != null"
                @click="clearSubject"
                >Limpar filtro</span
              >
            </b-col>

            <b-col>
              <form @submit.prevent="search" v-if="this.subject > 0">
                <b-form-group label="Buscar roteiros">
                  <b-input-group-prepend>
                    <b-form-input
                      v-model="searchTerm"
                      type="search"
                      placeholder="Digite aqui"
                    />
                  </b-input-group-prepend>
                  <b-input-group-text
                    class="clear-fields"
                    v-if="
                      searchTerm != '' || subject != null || progress != null
                    "
                    v-b-tooltip.hover
                    title="Limpar Pesquisa"
                  >
                    <b-icon icon="x" @click="clearFields" />
                  </b-input-group-text>
                </b-form-group>
              </form>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <div class="order-grid-box">
                <p
                  class="order-list"
                  :class="{ desc: descOrder }"
                  v-on:click="orderName"
                >
                  Ordenar
                  <OrderNameIcon />
                </p>
              </div>
            </b-col>
          </b-row>

          <b-row>
            <b-col class="list">
              <b-container
                fluid
                v-if="wasRequestMade && sortedSyllabuses.length"
              >
                <b-row class="header-list-teacher">
                  <b-col md="4">
                    <p class="bold">Conteúdo</p>
                  </b-col>
                  <b-col md="5">
                    <p class="bold">Descrição</p>
                  </b-col>
                  <b-col>
                    <p class="bold">Roteiro</p>
                  </b-col>
                  <b-col>
                    <p class="bold">Alunos</p>
                  </b-col>
                </b-row>
              </b-container>

              <b-container
                fluid
                v-if="wasRequestMade && sortedSyllabuses.length"
              >
                <b-row
                  class="list-teacher scripts align-items-center"
                  v-for="sy in sortedSyllabuses"
                  :key="sy.id"
                >
                  <b-col md="4">
                    <h5>{{ sy.name }}</h5>
                  </b-col>

                  <b-col md="5">
                    <p class="desc">{{ sy.description }}</p>
                  </b-col>
                  <b-col>
                    <router-link
                      :to="{
                        name: 'roteiro',
                        params: {
                          subject: subject,
                          id: sy.id,
                        },
                      }"
                    >
                      <ViewIcon />
                    </router-link>
                  </b-col>
                  <b-col>
                    <router-link
                      :to="{
                        name: 'registro-roteiros',
                        params: {
                          id: sy.id,
                        },
                      }"
                    >
                      <ListUsers />
                    </router-link>
                  </b-col>
                </b-row>
              </b-container>

              <b-container
                fluid
                v-if="
                  wasRequestMade && subject != null && !sortedSyllabuses.length
                "
              >
                <div class="row list-teacher scripts align-items-center">
                  <b-col>
                    <h5>Nenhum resultado encontrado</h5>
                  </b-col>
                </div>
              </b-container>
            </b-col>
          </b-row>

          <b-row class="align-center" v-if="count > perPage && wasRequestMade">
            <b-pagination
              v-model="currentPage"
              :per-page="perPage"
              :total-rows="count"
              aria-controls="teachersList"
              last-text
              first-text
            >
              <template #prev-text>
                <LeftArrowIcon class="left" />
              </template>
              <template #next-text>
                <LeftArrowIcon />
              </template>
            </b-pagination>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import SelectSubjectsWithCycle from "@/components/inputs/SelectSubjectsWithCycle.vue";
import OrderNameIcon from "@/components/svg/OrderNameIcon.vue";
import LeftArrowIcon from "@/components/svg/LeftArrowIcon.vue";
import ViewIcon from "@/components/svg/ViewIcon2.vue";
import ListUsers from "@/components/svg/ListUsers.vue";

export default {
  name: "SyllabusesList",
  components: {
    SelectSubjectsWithCycle,
    OrderNameIcon,
    LeftArrowIcon,
    ViewIcon,
    ListUsers,
  },

  data() {
    return {
      descOrder: false,
      cycle_id: 1,

      subject: null,
      searchTerm: "",
      wasRequestMade: false,

      currentPage: 1,
      count: 0,
      perPage: 20,
      breadcrumb: [
        {
          text: "Aprendizagem",
          href: "/aprendizagem",
        },
        {
          text: "Roteiros",
          href: "/aprendizagem/roteiros-historia",
          active: true,
        },
      ],
      selected: null,
    };
  },

  computed: {
    ...mapGetters({
      cycles: "shared/cycles",
      syllabuses: "learning/syllabuses",
    }),

    sortedSyllabuses() {
      return [...this.syllabuses].sort((a, b) => {
        const order = this.descOrder ? [1, -1] : [-1, 1];

        if (a.name < b.name) return order[0];
        else if (a.name > b.name) return order[1];

        return 0;
      });
    },
  },

  methods: {
    ...mapActions({
      getCycles: "shared/listCycles",
      getSyllabuses: "learning/listSyllabusesBySubject",
    }),

    orderName() {
      this.descOrder = !this.descOrder;
    },

    async getSubjects(page = 1) {
      const syllabuses = await this.getSyllabuses({
        id: this.subject,
        limit: this.perPage,
        page,
        search: this.searchTerm,
      });
      this.count = syllabuses.data.count;
      this.wasRequestMade = true;
    },

    async selectSubject({ id }) {
      this.subject = id;
      this.getSubjects();
      this.wasRequestMade = true;
    },

    clearFields() {
      this.searchTerm = "";
      this.currentPage = 1;
      this.getSubjects();
    },

    clearSubject() {
      this.subject = null;
      this.currentPage = 1;
      this.selectSubject(-1);
    },

    search() {
      this.currentPage = 1;
      this.getSubjects();
    },
  },

  watch: {
    currentPage() {
      this.getSubjects(this.currentPage);
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
  },

  created() {
    if (this.$route.params.subject)
      this.selectSubject({ id: this.$route.params.subject });
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/variables.scss";

.home {
  height: 100%;
}

.progress {
  border-radius: 30px;
  height: 7px;

  .progress-bar {
    background-color: $purple;
  }
  &.orange {
    .progress-bar {
      background-color: $orange2;
    }
  }
  &.yellow {
    .progress-bar {
      background-color: $yellow;
    }
  }
  &.red {
    .progress-bar {
      background-color: $red;
    }
  }
  &.green {
    .progress-bar {
      background-color: $green2;
    }
  }
  &.green1 {
    .progress-bar {
      background-color: $green1;
    }
  }
}

.container-rda {
  padding-bottom: 0;
}

.date {
  color: $grey4;
  span {
    background-color: $purple;
    border-radius: 6px;
    color: $white;
    display: inline-block;
    line-height: 28px;
    min-width: 82px;
    text-align: center;
  }
}

.scripts {
  h5 {
    color: $blue;
    margin: 0;
  }
}

.list-teacher {
  background-color: $white;
  border: 1px solid $grey5;
  border-radius: 10px;
  .box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 250px;
    padding: 40px 25px 25px;
    text-align: center;
    h5 {
      color: $blue;
      margin-bottom: 0;
    }
    p {
      margin-bottom: 0;
    }
    .row-progress {
      margin: 0 auto;
      max-width: 320px;
      width: 100%;
    }
  }
  &:hover {
    cursor: initial;
    border-color: transparent;
    background-image: linear-gradient($white, $white),
      radial-gradient(circle at top left, $orange, $purple);
    background-origin: border-box;
    background-clip: content-box, border-box;
  }
  p {
    margin: 0;
  }
  .desc {
    max-width: 420px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .disciplines {
    .name {
      display: inline-block;
      line-height: 22px;
      .comma {
        margin-right: 4px;
      }
      &:last-child {
        .comma {
          display: none;
        }
      }
    }
  }
  .cycles {
    display: flex;
    flex-wrap: wrap;
    .tags {
      background-color: $blue;
      border-radius: 5px;
      color: $white;
      font-weight: $bold;
      line-height: 28px;
      margin: 3px 12px 3px 0;
      max-width: 58px;
      padding: 0 3px;
      text-align: center;
      vertical-align: middle;
    }
  }
}
</style>
