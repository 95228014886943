<template>
  <b-modal
    id="articulador"
    hide-header
    hide-footer
    centered
    @hide="changeModalState"
  >
    <b-row>
      <b-col>
        <h4 class="purple">Nomear articulador</h4>
      </b-col>
    </b-row>

    <b-row class="mt-4">
      <b-col md="8" class="">
        <StandardSelect
          :callbackSelect="selectSubject"
          :options="subjectsNamed"
          label="Buscar matéira"
          placeholder="Digite aqui"
          class="mb-0"
        />
      </b-col>

      <b-col md="4" class="mt-auto ml-auto">
        <b-button @click="assignSubject" class="normal"
          >Adicionar matéria</b-button
        >
      </b-col>
    </b-row>

    <h4 class="purple mt-50">Ciclo/Disciplina</h4>

    <ul class="ciclos-materias">
      <li
        class="ciclo-materia"
        v-for="(subject, index) in subjectsAssigneds"
        :key="subject.id"
      >
        <div>
          <p class="title">{{ subject.designation }}</p>
        </div>
        <div class="actions">
          <span @click="() => deleteSubject(index)">
            <DeleteIcon />
          </span>
        </div>
      </li>
    </ul>

    <b-row>
      <b-col md="6" class="ml-auto mt-5">
        <b-button @click="closeModal" class="large blue outline"
          >Voltar</b-button
        >
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import DeleteIcon from "@/components/svg/DeleteIcon";
import StandardSelect from "@/components/inputs/StandardSelect.vue";

export default {
  name: "ArticuladorModal",
  components: {
    DeleteIcon,
    StandardSelect,
  },

  props: {
    callback: {
      type: Function,
      required: true,
    },

    subjectsToArticulate: {
      type: Array,
      default: () => [],
    },

    subjectsTeaching: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      subjectsAssigneds: [],
      subjectToAssign: null,
    };
  },

  computed: {
    ...mapGetters({
      subjects: "shared/subjects",
    }),

    subjectsNamed() {
      return this.subjects
        .filter(sub => this.subjectsTeaching.some(st => st.id === sub.id))
        .filter((sub) => !this.subjectsAssigneds.some((sa) => sa.id === sub.id))
        .map((sub) => ({ name: `${sub.designation} (${sub.Cycle.name})`, ...sub }));
    },
  },

  watch: {
    subjectsToArticulate() {
      this.subjectsAssigneds = this.subjectsToArticulate
    }
  },

  methods: {
    ...mapActions({
      getSubjects: "shared/listSubjects",
    }),

    selectSubject(subject) {
      this.subjectToAssign = subject;
    },

    assignSubject() {
      this.subjectsAssigneds.push(this.subjectToAssign);
    },

    changeModalState() {
      this.callback(this.subjectsAssigneds);
    },

    deleteSubject(index) {
      const newSubjectList = [...this.subjectsAssigneds];

      newSubjectList.splice(index, 1);

      this.subjectsAssigneds = newSubjectList;
    },

    closeModal() {
      this.$root.$emit('bv::hide::modal', 'articulador')
    },
  },

  created() {
    this.getSubjects({ subject_id: "", include: 'cycles' });
  },
};
</script>

<style lang="scss">
@import "@/assets/css/variables.scss";

div#articulador {
  .modal-dialog {
    max-width: 820px;
    .modal-content {
      background-color: $grey2;
      border: 0;
      border-radius: 0;
      .modal-body {
        padding: 60px;
      }
    }
  }
  .ciclos-materias {
    list-style: none;
    padding: 0;
    margin: 0;
    .ciclo-materia {
      align-items: center;
      color: $grey3;
      display: flex;
      justify-content: space-between;
      line-height: 60px;
      border-bottom: 1px solid $grey5;
      &:last-child {
        border-bottom: 0;
      }
      .title {
        margin: 0;
      }
      .actions {
        span {
          cursor: pointer;
        }
      }
    }
  }
}
</style>