var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home"},[[_c('b-container',{staticClass:"container-rda sidebar"},[_c('b-row',{staticClass:"height"},[_c('sidebar-coordination'),_c('b-col',{staticClass:"content"},[_c('b-row',[_c('b-col',[_c('b-breadcrumb',{attrs:{"items":_vm.breadcrumb}})],1)],1),_c('b-row',{staticClass:"mb-5"},[_c('b-col',[_c('h2',{staticClass:"purple bold"},[_vm._v("Gerenciamento de turmas")])]),(
                _vm.calendar &&
                _vm.nextCalendar.designation !== _vm.selectedCalendar.designation
              )?_c('b-col',{staticClass:"text-right"},[_c('b-button',{staticClass:"btn large blue",staticStyle:{"font-size":"19px"},on:{"click":function($event){return _vm.duplicateClassrooms(_vm.nextCalendar.id)}}},[_vm._v("Duplicar turmas de "+_vm._s(_vm.selectedCalendar.designation)+" para "+_vm._s(_vm.nextCalendar.designation))])],1):_vm._e(),_c('b-col',{staticClass:"text-right"},[_c('b-button',{staticClass:"btn large blue",attrs:{"to":{
                  name: 'nova-turma',
                  params: {
                    cycle: this.cycle,
                    page: this.page,
                  },
                }}},[_vm._v("Registrar nova turma")])],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('SelectCycles',{attrs:{"callback":_vm.callbackCycles},model:{value:(_vm.cycle),callback:function ($$v) {_vm.cycle=$$v},expression:"cycle"}}),(this.cycle != null)?_c('span',{staticClass:"clear-field",on:{"click":_vm.clearCycle}},[_vm._v("Limpar filtro")]):_vm._e()],1),_c('b-col',{attrs:{"md":"4"}},[_c('SelectCalendar',{attrs:{"label":"Ano","advice":"Para duplicar turmas, selecione um ano.","callback":_vm.callbackCalendar},model:{value:(_vm.calendar),callback:function ($$v) {_vm.calendar=$$v},expression:"calendar"}}),(this.calendar != null)?_c('span',{staticClass:"clear-field",on:{"click":_vm.clearCalendar}},[_vm._v("Limpar filtro")]):_vm._e()],1),_c('b-col',[_c('b-form-group',{attrs:{"label":"Buscar"}},[_c('b-input-group-prepend',[_c('b-form-input',{attrs:{"type":"search","placeholder":"Digite aqui"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.page = 1;
                      _vm.getList();}},model:{value:(_vm.searchTitle),callback:function ($$v) {_vm.searchTitle=$$v},expression:"searchTitle"}}),(_vm.searchTitle != '' || _vm.cycle != null)?_c('b-input-group-text',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"clear-fields",attrs:{"title":"Limpar Pesquisa"}},[_c('b-icon',{attrs:{"icon":"x"},on:{"click":_vm.clearFields}})],1):_vm._e()],1)],1)],1)],1),_c('b-row',[_c('b-col',[_c('div',{staticClass:"order-grid-box"},[_c('p',{staticClass:"order-list",class:{ desc: _vm.descOrder },on:{"click":_vm.orderName}},[_vm._v(" Ordenar "),_c('OrderNameIcon')],1)])])],1),_c('b-row',[_c('b-col',[_c('b-container',{attrs:{"fluid":""}},[(_vm.classrooms.length > 0 && _vm.isRequestSend)?_c('b-row',{staticClass:"header-list-teacher"},[_c('b-col',{attrs:{"md":"4"}},[_c('p',{staticClass:"bold"},[_vm._v("Designação")])]),_c('b-col',{attrs:{"md":"2"}},[_c('p',{staticClass:"bold"},[_vm._v("Horário")])]),_c('b-col',{attrs:{"md":"2"}},[_c('p',{staticClass:"bold"},[_vm._v("Contraturno")])]),_c('b-col',[_c('p',{staticClass:"bold"},[_vm._v("Ciclo")])]),_c('b-col',[_c('p',{staticClass:"bold"},[_vm._v("Ativo")])]),_c('b-col',[_c('p',{staticClass:"bold"},[_vm._v("Editar")])]),_c('b-col',[_c('p',{staticClass:"bold"},[_vm._v("Apagar")])])],1):_vm._e()],1),_c('b-container',{attrs:{"fluid":""}},[_vm._l((_vm.orderbyName),function(classroom){return _c('b-row',{key:classroom.id,staticClass:"list-teacher",attrs:{"align-v":"center"}},[_c('b-col',{attrs:{"md":"4"}},[_c('p',[_vm._v(_vm._s(classroom.designation))])]),(classroom.start_at && classroom.end_at)?_c('b-col',{attrs:{"md":"2"}},[_c('p',[_vm._v(_vm._s(classroom.start_at)+" - "+_vm._s(classroom.end_at))])]):_c('b-col',{attrs:{"md":"2"}},[_c('p',[_vm._v("----")])]),(
                      Array.isArray(classroom.ClassroomAfterHours) &&
                      classroom.ClassroomAfterHours.length > 0
                    )?_c('b-col',{attrs:{"md":"2"}},[_c('p',[_vm._v(" Sim ")])]):_c('b-col',{attrs:{"md":"2"}},[_c('p',[_vm._v("Não")])]),_c('b-col',[_c('p',{staticClass:"tags"},[_vm._v(_vm._s(classroom.Cycle.name))])]),_c('b-col',{staticClass:"status"},[(classroom.is_editable)?_c('b-form-checkbox',{attrs:{"switch":""},on:{"input":function($event){return _vm.updateStatus(classroom.id, false)}},model:{value:(classroom.is_editable),callback:function ($$v) {_vm.$set(classroom, "is_editable", $$v)},expression:"classroom.is_editable"}}):_c('b-form-checkbox',{attrs:{"switch":""},on:{"input":function($event){return _vm.updateStatus(classroom.id, true)}},model:{value:(classroom.is_editable),callback:function ($$v) {_vm.$set(classroom, "is_editable", $$v)},expression:"classroom.is_editable"}})],1),_c('b-col',[_c('router-link',{attrs:{"to":{
                        name: 'perfil-turma',
                        params: { id: classroom.id },
                      }}},[_c('EditIcon')],1)],1),_c('b-col',[_c('a',{on:{"click":function($event){$event.preventDefault();return _vm.exclude(classroom.id)}}},[_c('DeleteIcon')],1)])],1)}),(_vm.classrooms.length <= 0 && _vm.isRequestSend)?_c('b-row',{staticClass:"list-teacher",attrs:{"align-v":"center"}},[_c('b-col',[_vm._v(" Nenhum resultado encontrado ")])],1):_vm._e(),(!_vm.isRequestSend)?_c('b-row',{staticClass:"list-teacher",attrs:{"align-v":"center"}},[_c('b-col',[_vm._v(" Carregando ")])],1):(_vm.count > _vm.perPage && _vm.isRequestSend)?_c('b-row',{staticClass:"align-center"},[_c('b-pagination',{attrs:{"per-page":_vm.perPage,"total-rows":_vm.count,"aria-controls":"teachersList","last-text":"","first-text":""},on:{"change":_vm.changePage},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('LeftArrowIcon',{staticClass:"left"})]},proxy:true},{key:"next-text",fn:function(){return [_c('LeftArrowIcon')]},proxy:true}]),model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1):_vm._e()],2)],1)],1)],1)],1)],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }