<template>
  <b-container class="container-rda">
    <b-row>
      <b-col>
        <b-breadcrumb :items="breadcrumb"></b-breadcrumb>
      </b-col>
    </b-row>
    <b-row class="mb-5">
      <b-col md="7">
        <h2 class="purple bold">Currículo</h2>
      </b-col>
      <b-col class="text-right">
        <b-button class="large blue outline" href="#" v-if="false"
          >Copiar currículo</b-button
        >
      </b-col>
      <b-col class="text-right">
        <b-button
          class="large blue"
          :to="{
            name: 'novo-conteudo',
            params: {
              subject: this.subject,
              id: this.subject,
            },
          }"
          :class="{ disabled: !subject }"
          >Registrar</b-button
        >
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12">
        <p class="purple">Filtre para obter resultados</p>
      </b-col>
      <b-col md="4">
        <SelectSubjectsWithCycle v-model="subject" :callback="selectSubject" />
        <span
          class="clear-field"
          v-if="this.subject != null"
          @click="clearSubject"
          >Limpar filtro</span
        >
      </b-col>
      <b-col>
        <b-form-group label="Buscar" v-if="this.subject > 0">
          <b-input-group-prepend>
            <b-form-input
              type="search"
              placeholder="Digite aqui"
              v-model="searchTitle"
              v-on:keyup.enter="search"
            ></b-form-input>
            <b-input-group-text
              class="clear-fields"
              v-if="searchTitle != '' || subject != null"
              v-b-tooltip.hover
              title="Limpar Pesquisa"
            >
              <b-icon icon="x" @click="clearFields" />
            </b-input-group-text>
          </b-input-group-prepend>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="mb-4">
      <b-col>
        <div class="order-grid-box">
          <p
            class="order-list"
            :class="{ desc: descOrder }"
            v-on:click="orderName"
          >
            Ordenar
            <OrderNameIcon />
          </p>
        </div>
      </b-col>
    </b-row>
    <b-row
      v-for="(competence, competence_number) in orderbySort"
      :key="competence.id"
      class="line-divider"
      :class="{ disabled: competence.is_active == false }"
    >
      <b-col>
        <b-container fluid>
          <b-row class="header-list-teacher mb-4">
            <b-col md="9">
              <p class="bold">Competência</p>
            </b-col>
            <b-col>
              <p class="bold">Ativo</p>
            </b-col>
            <b-col>
              <p class="bold">Editar</p>
            </b-col>
            <b-col>
              <p class="bold">Apagar</p>
            </b-col>
          </b-row>
        </b-container>
        <b-container fluid>
          <b-row align-v="center" class="mb-5">
            <b-col md="9">
              <p class="text-blue bold desc">
                {{ competence_number + 1 }} - {{ competence.description }}
              </p>
            </b-col>
            <b-col class="status">
              <b-form-checkbox
                switch
                v-if="competence.is_active"
                v-model="competence.is_active"
                @input="updateStatus(competence.id, false)"
              ></b-form-checkbox>
              <b-form-checkbox
                switch
                v-else
                v-model="competence.is_active"
                @input="updateStatus(competence.id, true)"
              ></b-form-checkbox>
            </b-col>
            <b-col>
              <router-link
                :to="{
                  name: 'editar-competencia',
                  params: {
                    id: competence.id,
                    subject: competence.subject_id,
                  },
                }"
              >
                <EditIcon />
              </router-link>
            </b-col>
            <b-col>
              <a v-on:click.prevent="excludeCompetences(competence.id)">
                <DeleteIcon />
              </a>
            </b-col>
          </b-row>
        </b-container>
        <b-container fluid>
          <b-row class="header-list-teacher">
            <b-col md="9">
              <p class="bold">Conteúdos</p>
            </b-col>
            <b-col>
              <p class="bold">Ativo</p>
            </b-col>
            <b-col>
              <p class="bold">Editar</p>
            </b-col>
            <b-col>
              <p class="bold">Apagar</p>
            </b-col>
          </b-row>
        </b-container>
        <b-container fluid>
          <b-row
            align-v="center"
            class="list-teacher"
            v-for="(topic, topic_number) in competence.Topics.filter(
              (t) => t.competence_id === competence.id
            ).sort((a, b) => {
              return a.sort_order - b.sort_order;
            })"
            :key="topic.id"
          >
            <b-col md="9">
              <p
                class="desc"
                v-bind:style="{ color: topic.is_editable ? '' : '#a9a9a9' }"
              >
                {{ competence_number + 1 }}.{{ topic_number + 1 }} -
                {{ topic.description }}
                <span class="red-asterisk" v-if="topic.bncc_code != ''">*</span>
              </p>
            </b-col>
            <b-col class="status">
              <b-form-checkbox
                switch
                v-if="topic.is_editable"
                v-model="topic.is_editable"
                @input="updateTopicStatus(topic.id, false)"
              ></b-form-checkbox>
              <b-form-checkbox
                switch
                v-else
                v-model="topic.is_editable"
                @input="updateTopicStatus(topic.id, true)"
              ></b-form-checkbox>
            </b-col>
            <b-col>
              <router-link
                :to="{
                  name: 'editar-conteudo',
                  params: {
                    id: topic.id,
                    subject: subject,
                  },
                }"
              >
                <EditIcon />
              </router-link>
            </b-col>
            <b-col>
              <a v-on:click.prevent="excludeTopics(topic.id)">
                <DeleteIcon />
              </a>
            </b-col>
          </b-row>
        </b-container>
      </b-col>
    </b-row>
    <b-container v-if="competences.length <= 0 && isRequestSend" fluid>
      <b-row align-v="center" class="list-teacher">
        <b-col>Nenhum resultado encontrado</b-col>
      </b-row>
    </b-container>
  </b-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

import DataServiceCompetences from "@/services/competences.service";
import DataServiceTopics from "@/services/topics.service";

import SelectSubjectsWithCycle from "@/components/inputs/SelectSubjectsWithCycle.vue";
import OrderNameIcon from "@/components/svg/OrderNameIcon.vue";
import DeleteIcon from "@/components/svg/DeleteIcon.vue";
import EditIcon from "@/components/svg/EditIcon.vue";

export default {
  name: "listar-conteudos",
  components: {
    SelectSubjectsWithCycle,
    OrderNameIcon,
    DeleteIcon,
    EditIcon,
  },

  data() {
    return {
      descOrder: false,
      isRequestSend: false,
      subject: this.$route.params.subject,
      searchTitle: "",

      breadcrumb: [
        {
          text: "Coordenação",
          href: "/coordenacao/turmas",
        },
        {
          text: "Currículo",
          href: "/coordenacao/conteudos",
          active: true,
        },
      ],
    };
  },

  computed: {
    ...mapGetters({
      competences: "shared/competences",
    }),

    orderbySort() {
      return [...this.competences].sort((a, b) => {
        const order = this.descOrder ? [1, -1] : [-1, 1];

        if (a.sort_order < b.sort_order) return order[0];
        else if (a.sort_order > b.sort_order) return order[1];

        return 0;
      });
    },
  },

  methods: {
    excludeTopics(id) {
      this.$swal
        .fire({
          title: "Tem certeza?",
          text: "Você não poderá reverter isso!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#68c5b7",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim, excluir!",
          cancelButtonText: "Cancelar",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            try {
              await DataServiceTopics.delete(id);
              this.$swal
                .fire("Excluído!", "Conteudo foi excluída.", "success")
                .then((response) => {
                  this.resetStates();

                  return response.data;
                });
            } catch (error) {
              this.$swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Não foi possivel excluir!",
                footer: "Por favor, tente novamente mais tarde.",
              });

              return error.response;
            }
          }
        })
        .catch((error) => {
          this.$swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Não foi possivel excluir!",
            footer: "Por favor, tente novamente mais tarde.",
          });

          return error.response;
        });
    },

    excludeCompetences(id) {
      this.$swal
        .fire({
          title: "Tem certeza?",
          text: "Isso excluirá todos seus tópicos também!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#68c5b7",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim, excluir!",
          cancelButtonText: "Cancelar",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            try {
              await this.excludeTopicsAndCompetences(id);

              this.$swal.fire(
                "Excluído!",
                "Competência foi excluída.",
                "success"
              );
              this.getCompetencesWithTopics(this.subject);
            } catch (error) {
              this.alertError();
            }
          } else if (result.isDenied) {
            this.$swal.fire("As alteração não foram salvas", "", "info");
          }
        })
        .catch((error) => {
          this.alertError();
          return error.response;
        });
    },

    excludeTopicsAndCompetences(id) {
      if (this.competences.find((com) => com.id == id).Topics.length)
        this.competences
          .find((com) => com.id == id)
          .Topics.map(async (topic) => {
            await DataServiceTopics.delete(topic.id);
          });

      return DataServiceCompetences.delete(id);
    },

    alertError(
      { title, text, footer } = {
        title: "Oops...",
        text: "Não foi possivel excluir!",
        footer: "Por favor, tente novamente mais tarde",
      }
    ) {
      this.$swal.fire({
        icon: "error",
        title,
        text,
        footer,
      });
    },

    orderName() {
      this.descOrder = !this.descOrder;
    },

    selectSubject({ id }) {
      this.subject = id;
      this.getCompetencesWithTopics(this.subject);
      this.isRequestSend = true;
    },

    resetStates() {
      this.resetState();
    },

    clearFields() {
      this.searchTitle = "";
      this.resetStates();
      this.isRequestSend = false;
      this.subject = null;
    },

    clearSubject() {
      this.resetStates();
      this.isRequestSend = false;
      this.subject = null;
    },

    search() {
      this.searchTopics({ term: this.searchTitle, subject_id: this.subject });
    },

    updateStatus(id, status) {
      var data = {
        id: id,
        is_active: status,
      };
      DataServiceCompetences.update(id, data)
        .then((response) => {
          const Toast = this.$swal.mixin({
            toast: true,
            position: "bottom-end",
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer);
              toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "success",
            title: "Status atualizado com sucesso!",
          });

          return response.data;
        })
        .catch((error) => {
          this.$swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Não foi possivel atualizar!",
            footer: "Por favor, tente novamente mais tarde.",
          });

          return error.response;
        });
    },

    updateTopicStatus(id, status) {
      var data = {
        id: id,
        is_editable: status,
      };
      DataServiceTopics.update(id, data)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          this.$swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Não foi possivel atualizar!",
            footer: "Por favor, tente novamente mais tarde.",
          });

          return error.response;
        });
    },

    ...mapActions({
      getCompetencesWithTopics: "shared/getCompetencesWithTopics",
      searchTopics: "shared/searchTopics",
    }),

    ...mapMutations({
      resetState: "shared/RESET_STATE",
    }),
  },

  created() {
    if (this.$route.params.subject)
      this.getCompetencesWithTopics(this.$route.params.subject);
  },

  destroyed() {
    this.resetStates();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/variables.scss";

.home {
  height: 100%;
}

.container-rda {
  display: flex;
  flex-direction: column;
  .row.line-divider.disabled {
    order: 1;
  }
}

.desc {
  max-width: 900px;
}

.tags {
  background-color: $blue;
  border-radius: 5px;
  color: $white;
  font-weight: $bold;
  line-height: 28px;
  margin: 3px 12px 3px 0;
  max-width: 58px;
  padding: 0 3px;
  text-align: center;
  vertical-align: middle;
  width: 100%;
}

.nopadding {
  padding: 0;
}

.row.line-divider {
  & > .col {
    padding-bottom: 80px;
    margin-bottom: 80px;
    &:after {
      background-color: $grey5;
      bottom: 0;
      display: block;
      content: "";
      height: 1px;
      position: absolute;
      width: calc(100% - 30px);
    }
  }
}
</style>
