<template>
  <div class="upload-file">
    <ul class="list-files" v-if="currentFiles !== null || fileName !== null">
      <li v-if="currentFiles.name || fileName" :key="index">
        <div class="col-file">
          <b-icon icon="file-earmark" aria-hidden="true"></b-icon>
        </div>
        <div class="col-file upload-info">
          <span class="about-progress">
            <b>{{
              currentFiles.name != undefined ? currentFiles.name : fileName
            }}</b>
            <i v-if="progressUpload != null">{{
              progressUpload >= 100 ? "Pronto" : "Carregando..."
            }}</i>
          </span>
          <span v-if="progressUpload != null" class="progress-bar"></span>
        </div>
        <div class="col-file">
          <b-icon
            icon="x-circle-fill"
            aria-hidden="true"
            @click="removeFile"
          ></b-icon>
        </div>
      </li>
    </ul>
    <div class="wrap-input-file" @ondrop="onDrop">
      <input
        type="file"
        class="upload-files"
        :multiple="!noUploadPreview"
        :accept="!noUploadPreview ? 'image/png, image/jpg, .pdf' : '.xls, .csv'"
        @change="addFile($event)"
      />
      <div class="description-input-file" v-if="!noUploadPreview">
        <b-icon icon="upload" aria-hidden="true"></b-icon>
        <h5>Arraste um aquivo ou</h5>
        <button>Selecione</button>
        <span>Apenas arquivos PNG, JPG, PDF com tamanho máximo de 5MB.</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    fileName: String,
  },
  name: "UploadFiles",
  data: function () {
    return {
      currentFiles: [],
      progressUpload: null,
    };
  },
  methods: {
    onDragover() {
      const element = document.getElementsByClassName("wrap-input-file");

      element.classList.add("on-drag-over");
    },
    onDrop() {
      this.addFile();
    },
    progressTimer() {
      let timerProgress = setInterval(() => {
        this.progressUpload += 1;

        if (this.progressUpload >= 100) {
          clearInterval(timerProgress);
        }
      }, 10);
    },
    addFile(event) {
      this.progressUpload = 0;
      this.currentFiles = [];

      setTimeout(() => {
        this.currentFiles = event.target.files[0];
        this.$emit("input", this.currentFiles);
        this.progressTimer();
      }, 100);
    },
    removeFile() {
      this.fileName = null;
      this.progressUpload = null;
      this.currentFiles = null;
      this.$emit("input", this.currentFiles);
    },
  },
};
</script>

<style lang="scss">
.list-files {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  margin: 0 0 24px;
  padding: 0;

  li {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    row-gap: 20px;
    list-style: none;
    box-sizing: border-box;
  }

  .col-file {
    flex: 0 0 32px;
  }

  svg {
    font-size: 24px;

    &.bi-x-circle-fill {
      color: #b3b3b3;
      cursor: pointer;
    }
  }

  .upload-info {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;

    .about-progress {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 3px;

      b {
        text-align: left;
        flex: 1;
        max-width: 80%;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #000000;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
      i {
        flex: 0 0 42px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #666666;
      }
    }

    .progress-bar {
      background: #dbdbdb;
      width: 100%;
      height: 6px;
      position: relative;
      border-radius: 50px;
      overflow: hidden;

      &::before {
        content: "";
        background: $gradient1;
        width: 0%;
        height: 6px;
        animation-name: progressBar;
        animation-duration: 1.7s;
        animation-fill-mode: forwards;
      }

      @keyframes progressBar {
        0% {
          width: 0;
        }
        100% {
          width: 100%;
        }
      }
    }
  }
}
.wrap-input-file {
  width: 100%;
  border: 1px dashed #b3b3b3;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 20px;
  padding: 20px;
  box-sizing: border-box;
  position: relative;

  input {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    opacity: 0;
  }

  .description-input-file {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 20px;
    position: relative;
    z-index: 0;

    svg {
      font-size: 24px;
    }
    h5 {
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      color: #000000;
      margin-bottom: 0;
    }
    button {
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      color: #ffffff;
      background: $purple;
      width: 153px;
      height: 40px;
      border: 0;
      border-radius: 5px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
    span {
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #666666;
    }
  }
}
.loading-file {
  min-height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .spinner-border {
    width: 32px;
    height: 32px;
    margin-bottom: 1rem;
  }
  span {
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
    color: $purple;
  }
}
</style>
