<template>
  <div class="loadingView" v-if="loadingForm">
    <LoadingList v-model="loadingForm" text="Carregando dados." />
  </div>
  <div v-else>
    <div>
      <b-card-header header-tag="header" role="tab">
        <h5 v-b-toggle="'accordion-1-' + value.enrollment_id">Saúde</h5>
      </b-card-header>
      <b-collapse
        :id="'accordion-1-' + value.enrollment_id"
        visible
        :accordion="'my-accordion-' + value.enrollment_id"
        :role="'tabpanel-' + value.enrollment_id"
      >
        <b-row>
          <b-col md="8">
            <b-form-group
              class="group-radio required"
              v-slot="{ ariaDescribedby }"
            >
              <label for="">Vacinas em dia até data presente</label>
              <b-form-radio-group
                v-model="value.vaccines_up_to_date"
                :options="options.boolean"
                :aria-describedby="ariaDescribedby"
                :name="'vaccines_up_to_date' + value.enrollment_id"
                :id="'vaccines_up_to_date' + value.enrollment_id"
              ></b-form-radio-group>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="8">
            <b-form-group
              class="group-radio required"
              v-slot="{ ariaDescribedby }"
            >
              <label for="">Restrição alimentar</label>
              <b-form-radio-group
                v-model="value.food_restriction"
                :options="options.boolean"
                :aria-describedby="ariaDescribedby"
                :name="'food_restriction' + value.enrollment_id"
                :id="'food_restriction' + value.enrollment_id"
                @input="setDescriptionfieldNull()"
              ></b-form-radio-group>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <StandardInput
              v-if="value.food_restriction"
              v-model="value.food_restriction_description"
              class="required"
              type="text"
              :name="'food-restriction' + value.enrollment_id"
              :id="'food-restriction' + value.enrollment_id"
              label="Especifique:"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col md="8">
            <b-form-group
              class="group-radio required"
              v-slot="{ ariaDescribedby }"
            >
              <label for="">Problema crônico de saúde</label>
              <b-form-radio-group
                v-model="value.health_problem"
                :options="options.boolean"
                :aria-describedby="ariaDescribedby"
                :name="'health_problem' + value.enrollment_id"
                :id="'health_problem' + value.enrollment_id"
                @input="setDescriptionfieldNull()"
              ></b-form-radio-group>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <StandardInput
              v-if="value.health_problem"
              v-model="value.health_problem_description"
              class="required"
              type="text"
              :name="'chronic-health-problem' + value.enrollment_id"
              :id="'chronic-health-problem' + value.enrollment_id"
              label="Especifique:"
              placeholder=""
            >
            </StandardInput>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="8">
            <b-form-group
              class="group-radio required"
              v-slot="{ ariaDescribedby }"
            >
              <label for="">Limitações</label>
              <b-form-radio-group
                v-model="value.limitation"
                :options="options.boolean"
                :aria-describedby="ariaDescribedby"
                :name="'limitation' + value.enrollment_id"
                :id="'limitation' + value.enrollment_id"
                @input="setDescriptionfieldNull()"
              ></b-form-radio-group>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <StandardInput
              v-if="value.limitation"
              v-model="value.limitation_description"
              class="required"
              type="text"
              :name="'limitations' + value.enrollment_id"
              :id="'limitations' + value.enrollment_id"
              label="Especifique:"
              placeholder=""
            >
            </StandardInput>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="8">
            <b-form-group
              class="group-radio required"
              v-slot="{ ariaDescribedby }"
            >
              <label for="">Acompanhamento especializado</label>
              <b-form-radio-group
                v-model="value.special_accompaniment"
                :options="options.boolean"
                :aria-describedby="ariaDescribedby"
                :name="'special_accompaniment' + value.enrollment_id"
                :id="'special_accompaniment' + value.enrollment_id"
                @input="setDescriptionfieldNull()"
              ></b-form-radio-group>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <StandardInput
              v-if="value.special_accompaniment"
              v-model="value.special_accompaniment_description"
              class="required"
              type="text"
              :name="'specialized-monitoring' + value.enrollment_id"
              :id="'specialized-monitoring' + value.enrollment_id"
              label="Especifique:"
              placeholder=""
            >
            </StandardInput>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="8">
            <b-form-group
              class="group-radio required"
              v-slot="{ ariaDescribedby }"
            >
              <label for="">Acompanhamento individual na escola</label>
              <b-form-radio-group
                v-model="value.individual_accompaniment"
                :options="options.boolean"
                :aria-describedby="ariaDescribedby"
                :name="'individual_accompaniment' + value.enrollment_id"
                :id="'individual_accompaniment' + value.enrollment_id"
                @input="setDescriptionfieldNull()"
              ></b-form-radio-group>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <StandardInput
              v-if="value.individual_accompaniment"
              v-model="value.individual_accompaniment_description"
              class="required"
              type="text"
              :name="'individual-accompaniment-school' + value.enrollment_id"
              :id="'individual-accompaniment-school' + value.enrollment_id"
              label="Especifique:"
              placeholder=""
            >
            </StandardInput>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="8">
            <b-form-group
              class="group-radio required"
              v-slot="{ ariaDescribedby }"
            >
              <label for="">Cuidado especial por motivo de saúde</label>
              <b-form-radio-group
                v-model="value.special_care"
                :options="options.boolean"
                :aria-describedby="ariaDescribedby"
                :name="'special_care' + value.enrollment_id"
                :id="'special_care' + value.enrollment_id"
                @input="setDescriptionfieldNull()"
              ></b-form-radio-group>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <StandardInput
              v-if="value.special_care"
              v-model="value.special_care_description"
              class="required"
              type="text"
              :name="'special-care-health-reasons' + value.enrollment_id"
              :id="'special-care-health-reasons' + value.enrollment_id"
              label="Especifique:"
              placeholder=""
            >
            </StandardInput>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="8">
            <b-form-group
              class="group-radio required"
              v-slot="{ ariaDescribedby }"
            >
              <label for="">Uso de medicamento em horário escolar</label>
              <b-form-radio-group
                v-model="value.medication"
                :options="options.boolean"
                :aria-describedby="ariaDescribedby"
                :name="'medication' + value.enrollment_id"
                :id="'medication' + value.enrollment_id"
                @input="setDescriptionfieldNull()"
              ></b-form-radio-group>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <StandardInput
              v-if="value.medication"
              v-model="value.medication_description"
              class="required"
              type="text"
              :name="'medication-during-school' + value.enrollment_id"
              :id="'medication-during-school' + value.enrollment_id"
              label="Especifique:"
              placeholder=""
            >
            </StandardInput>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="8">
            <b-form-group
              class="group-radio required"
              v-slot="{ ariaDescribedby }"
            >
              <label for="">Possui convênio médico</label>
              <b-form-radio-group
                v-model="value.healthcare"
                :options="options.boolean"
                :aria-describedby="ariaDescribedby"
                :name="'healthcare' + value.enrollment_id"
                :id="'healthcare' + value.enrollment_id"
                @input="setDescriptionfieldNull()"
              ></b-form-radio-group>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <StandardInput
              v-if="value.healthcare"
              v-model="value.healthcare_description"
              class="required"
              type="text"
              :name="'medical-insurance' + value.enrollment_id"
              :id="'medical-insurance' + value.enrollment_id"
              label="Especifique:"
              placeholder=""
            >
            </StandardInput>
          </b-col>
        </b-row>
      </b-collapse>
      <!-- Item -->
    </div>
    <b-card-header header-tag="header" role="tab">
      <h5 block v-b-toggle="'accordion-2-' + value.enrollment_id">
        Em caso de emergência, não sendo localizados os responsáveis por
        assuntos pedagógicos e/ou financeiros, entrar em contato com:
      </h5>
    </b-card-header>
    <b-collapse
      :id="'accordion-2-' + value.enrollment_id"
      :accordion="'my-accordion-2-' + value.enrollment_id"
      :role="'tabpanel-' + value.enrollment_id"
    >
      <b-row>
        <b-col md="4">
          <StandardInput
            v-model="value.contact1"
            class="required"
            type="text"
            :name="'first-contact' + value.enrollment_id"
            :id="'first-contact' + value.enrollment_id"
            label="Primeiro contato"
          >
          </StandardInput>
        </b-col>
        <b-col md="2">
          <StandardInput
            v-model="value.contact1_phone"
            class="required"
            type="text"
            :name="'first-phone' + value.enrollment_id"
            :id="'first-phone' + value.enrollment_id"
            label="Telefone"
            v-mask="'(##) #####-####'"
          >
          </StandardInput>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="4">
          <StandardInput
            v-model="value.contact2"
            class="required"
            type="text"
            :name="'second-contact' + value.enrollment_id"
            :id="'second-contact' + value.enrollment_id"
            label="Segundo contato"
          >
          </StandardInput>
        </b-col>
        <b-col md="2">
          <StandardInput
            v-model="value.contact2_phone"
            class="required"
            type="text"
            :name="'second-phone' + value.enrollment_id"
            :id="'second-phone' + value.enrollment_id"
            label="Telefone"
            v-mask="'(##) #####-####'"
          >
          </StandardInput>
        </b-col>
      </b-row>
    </b-collapse>
    <!-- Item -->

    <b-card-header header-tag="header" role="tab">
      <h5 block v-b-toggle="'accordion-3-' + value.enrollment_id">
        Retorno para residência (além dos contratantes)
      </h5>
    </b-card-header>
    <b-collapse
      :id="'accordion-3-' + value.enrollment_id"
      :accordion="'my-accordion-3-' + value.enrollment_id"
      :role="'tabpanel-' + value.enrollment_id"
    >
      <b-row>
        <b-col md="4">
          <b-form-group class="required">
            <label for="">Forma de retorno</label>
            <multiselect
              v-model="value.return_to_home"
              :options="options.return_to_home"
              :multiple="false"
              placeholder="Selecione"
              class="required"
              selectLabel=""
              @input="setContact($event)"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <template v-if="value.return_to_home === 'Acompanhado por'">
        <b-row
          v-for="(contact, index) in value.EnrollmentComplementContacts"
          :key="index"
          class="profile-contact"
        >
          <b-col>
            <h5 class="mb-4">Contato {{ index + 1 }}</h5>
          </b-col>
          <b-button
            v-if="value.EnrollmentComplementContacts.length > 1"
            @click="removeContact(index)"
            type="button"
            class="btn blue mb-5 add"
          >
            Remover Contato
          </b-button>

          <div class="w-100"></div>
          <b-col md="3">
            <b-form-group class="required">
              <ProfilePhoto
                :modalId="`modal-complementary-${index + 1}`"
                :photo="contact.photo"
                :callback="(photo) => setNewPhoto(contact, photo)"
              />
            </b-form-group>
          </b-col>

          <b-col>
            <b-row>
              <b-col>
                <StandardInput
                  v-model="contact.name"
                  class="required"
                  type="text"
                  :name="'full-name' + value.enrollment_id"
                  :id="'full-name' + value.enrollment_id"
                  label="Nome completo"
                />
              </b-col>
              <b-col>
                <StandardInput
                  v-model="contact.relationship"
                  class="required"
                  type="text"
                  :name="'kinship' + value.enrollment_id"
                  :id="'kinship' + value.enrollment_id"
                  label="Relação com aluno"
                />
              </b-col>
              <b-col>
                <StandardInput
                  v-model="contact.number1"
                  class="required"
                  type="text"
                  :name="'phone-1' + value.enrollment_id"
                  :id="'phone-1' + value.enrollment_id"
                  label="Telefone 1"
                  v-mask="'(##) #####-####'"
                />
              </b-col>
              <div class="w-100"></div>
              <b-col md="4">
                <StandardInput
                  v-model="contact.number2"
                  type="text"
                  :name="'phone-2' + value.enrollment_id"
                  :id="'phone-2' + value.enrollment_id"
                  label="Telefone 2"
                  v-mask="'(##) #####-####'"
                  :required="false"
                />
              </b-col>
            </b-row>
          </b-col>
          <div class="w-100"></div>
          <b-col
            class="wrap-add"
            md="12"
            v-if="
              index === value.EnrollmentComplementContacts.length - 1 &&
              value.EnrollmentComplementContacts.length <= 3
            "
          >
            <b-button
              @click="addNewContact"
              type="button"
              class="btn large blue mb-5 add"
            >
              <b-icon-plus-lg />Adicionar contatos
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-collapse>
  </div>
</template>

<script>
import StandardInput from "@/components/inputs/StandardInput.vue";
import ProfilePhoto from "@/components/ProfilePhoto.vue";
import httpRda from "../../../http-rda";
import LoadingList from "@/components/LoadingList.vue";

export default {
  name: "Complementary",
  components: {
    StandardInput,
    LoadingList,
    ProfilePhoto,
  },

  props: ["value"],

  data() {
    return {
      enrollmentId: this.$route.params.id,
      loadingForm: true,
      options: {
        boolean: [
          { text: "Sim", value: true },
          { text: "Não", value: false },
        ],
        return_to_home: ["Sozinho", "Acompanhado por"],
      },
    };
  },

  methods: {
    loadComplements() {
      if (this.value !== undefined && this.value.id !== undefined) {
        if (
          this.value.EnrollmentComplementContacts === undefined ||
          this.value.EnrollmentComplementContacts.length === 0
        ) {
          this.value.EnrollmentComplementContacts = [];
        }
      }
      this.loadingForm = false;
    },

    setDescriptionfieldNull() {
      for (const key in this.value) {
        if (
          this.value[key] === false &&
          this.value[`${key}_description`] !== undefined
        ) {
          this.value[`${key}_description`] = "";
        }
      }
    },

    async removeContact(index) {
      const response = confirm("Deseja realmente deletar esse contato?");

      if (response) {
        const [contact] = this.value.EnrollmentComplementContacts.splice(
          index,
          1
        );

        if (contact.id) {
          await httpRda.delete(
            `/enrollments/${
              this.enrollmentId ? this.enrollmentId : this.value.enrollment_id
            }/contacts/${contact.id}`
          );
        }
      }
    },

    async setContact(value) {
      if (value === "Acompanhado por") {
        if (this.value.EnrollmentComplementContacts.length === 0) {
          this.addNewContact();
        }
      } else {
        if (
          this.value.EnrollmentComplementContacts.length > 0 &&
          this.value.EnrollmentComplementContacts[0].name != ""
        ) {
          const result = confirm(
            "Essa ação irá deletar todos os contatos complementares previamente existentes!\nDeseja continuar?"
          );
          if (result) {
            this.value.EnrollmentComplementContacts.map(async (contact) => {
              if (contact.id) {
                await httpRda.delete(
                  `/enrollments/${
                    this.enrollmentId
                      ? this.enrollmentId
                      : this.value.enrollment_id
                  }/contacts/${contact.id}`
                );
              } else {
                this.value.EnrollmentComplementContacts = [];
              }
            });
          } else {
            this.value.return_to_home = "Acompanhado por";
          }
        }
      }
    },

    addNewContact() {
      this.value.EnrollmentComplementContacts.push({
        photo: null,
        name: "",
        relationship: "",
        number1: "",
        number2: "",
      });
    },

    setNewPhoto(contact, photo) {
      contact.photo = photo;
    },
  },

  computed: {
    //
  },

  watch: {
    //
  },

  mounted() {
    if (
      this.value.return_to_home == "Acompanhado por" &&
      this.value.EnrollmentComplementContacts.length === 0
    ) {
      this.addNewContact();
    }
  },

  created() {
    this.loadComplements();
  },
};
</script>

<style lang="scss" scoped>
.wrap-add {
  padding-top: 40px;

  .add {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;

    svg {
      font-size: 16px !important;
      position: relative;
      top: -1px;
    }
  }
}
.profile-contact {
  border-bottom: 0;
  padding-bottom: 0;
}
</style>
