<template>
  <b-form-group>
    <multiselect
      v-model="value"
      :options="myOptions"
      :clear-on-select="true"
      :close-on-select="true"
      :preserve-search="true"
      placeholder="Selecione"
      label="name"
      track-by="id"
      selectLabel=""
      selectedLabel=""
      deselectLabel=""
      multiple
    >
      <span slot="noResult">Nada encontrado</span>
    </multiselect>
  </b-form-group>
</template>

<script>
export default {
  props: [
    'callback',
    'options',
    'hasSelectAll',
    'initial'
  ],

  data() {
    return {
      value: []
    }
  },

  watch: {
    value() {
      this.callback(this.value)
      if(this.value.some(v => v.id === -1))
        this.value = []
    },

    initial() {
      this.value = this.initial
      this.callback(this.value)
    }
  },

  computed: {
    myOptions() {
      if(!this.hasSelectAll)
        return this.options

      if(!this.options.length)
        return []

      return [
        ...this.options,
        { name: "Todos", id: -1 }
      ]
    },
  }
}
</script>
