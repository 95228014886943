<template>
  <b-container id="timeline-page" class="container-rda">
    <b-row class="mb-5">
      <b-col>
        <h2 class="purple bold">Linha do tempo</h2>
      </b-col>
    </b-row>

    <b-row v-if="this.student !== null">
      <b-col cols="3">
        <b-avatar class="mb-4" :src="this.student.User && this.student.User.photo
          ? this.student.User.photo
          : '../../assets/images/user_placeholder.png'
          " size="17rem"></b-avatar>
        <div class="info-student">
          <h4>{{ student.User.name }}</h4>
          <ul>
            <li>
              <b>Turma</b>
              <p>
                {{
                  classroom && classroom.Cycle
                    ? classroom.Cycle.designation
                    : ""
                }}
                - {{ classroom && classroom.year_id ? classroom.year_id : "" }}°
                ano
              </p>
            </li>
          </ul>
        </div>
      </b-col>
      <!-- Content Page -->
      <b-col>
        <!-- Filter -->
        <b-row class="filters">
          <b-col md="3" lg="3">
            <SelectSimple v-model="selectedCategory" label="Categoria" :options="allCategories"
              :callback="selectCategoryFilter" />
          </b-col>
          <b-col md="5" lg="5">
            <SelectSimple v-model="selectedExperienceField" label="Campo de Experiência" :options="allFieldsOptions"
              :disabled="selectedExperienceFieldDisabled" :class="{
                'disabled-input': selectedExperienceFieldDisabled
              }" :callback="selectExperienceField" />
          </b-col>
          <b-col md="2" lg="2">
            <SelectSimple v-model="selectedMonth" label="Mês" :options="allMonth" :callback="selectMonthFilter" />
          </b-col>
        </b-row>
        <!-- Content -->
        <b-row v-for="(item, index) in items" :key="index">
          <TimelineItem :resource="item" @onRemove="removeItemList(index)" @onEdit="openModalEditRegister(item)"
            :edit="false" />
        </b-row>
      </b-col>
    </b-row>
    <b-row v-else>
      <b-col cols="6">
        <Loading />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Loading from "@/components/Loading.vue";
import SelectSimple from "@/components/inputs/SelectSimple.vue";
import TimelineItem from "@/components/TimelineItem.vue";
import httpRda from "../../http-rda";
import DataService from "@/services/students.service";
import moment from "moment";

export default {
  name: "dashboard-tutoria-aluno-responsavel",
  components: {
    Loading,
    SelectSimple,
    TimelineItem,
  },

  data() {
    return {
      id: this.$route.params.id,
      student: null,
      classroom: null,
      allCategories: [],
      allFields: [],
      allFieldsOptions: [
        { id: null, label: "Todos" },
      ],
      categories: [],
      selectedCategory: null,
      allMonth: [
        { id: "-1", label: "Todos" },
        { id: "JAN", label: "Janeiro" },
        { id: "FEV", label: "Fevereiro" },
        { id: "MAR", label: "Março" },
        { id: "ABR", label: "Abril" },
        { id: "MAI", label: "Maio" },
        { id: "JUN", label: "Junho" },
        { id: "JUL", label: "Julho" },
        { id: "AGO", label: "Agosto" },
        { id: "SET", label: "Setembro" },
        { id: "OUT", label: "Outubro" },
        { id: "NOV", label: "Novembro" },
        { id: "DEZ", label: "Dezembro" },
      ],
      selectedMonth: null,
      selectedExperienceField: null,
      modalPreviewFile: false,
      modalEditRegister: false,
      submitLoading: false,
      registerData: {
        id: null,
        category: null,
        general_type: null,
        content: null,
        visible_for_parents: true,
        file: null,
      },
      items: [],
      modalTitle: "",
      filter: {
        category: null,
        month: null,
      },
    };
  },

  computed: {
    allFieldsFilled() {
      if (
        this.registerData.general_type &&
        this.registerData.general_type.id === "followup"
      ) {
        return (
          this.registerData.general_type !== null &&
          this.registerData.category !== null &&
          this.registerData.content !== null &&
          this.registerData.visible_for_parents !== null
        );
      } else {
        return (
          this.registerData.general_type !== null &&
          this.registerData.file !== null
        );
      }
    },

    selectedExperienceFieldDisabled() {
      return (
        this.selectedCategory &&
        this.selectedCategory.id &&
        this.selectedCategory.id !== 'followup' &&
        this.selectedCategory.id !== '-1'
      )
    }
  },

  methods: {
    successMessage(title) {
      this.$toast.open({
        message: title,
        type: "success",
        duration: 2000,
      });
    },

    selectCategoryFilter(data, clearExperienceField = true) {
      this.selectedCategory = data;
      this.filter.type = data;

      if (clearExperienceField !== false) {
        this.selectedExperienceField = { id: null, label: "Todos" };
        this.filter.followup_type_id = null;
      }

      this.getChildhoodFiles();
    },

    selectExperienceField(data) {
      this.selectedExperienceField = data;
      this.filter.followup_type_id = data.id;

      this.selectCategoryFilter(
        {
          id: "followup",
          label: "Acompanhamento individual"
        },
        false
      )
    },

    selectMonthFilter(data) {
      this.selectedMonth = data;

      if (data.id === "-1") {
        this.filter.month = null;
        this.getChildhoodFiles();
        return;
      }

      const year = moment().format("YYYY");
      const beginDate = moment(`${data.id}-${year}`, "MMM-YYYY")
        .startOf("month")
        .format("YYYY-MM-DD");
      const endDate = moment(`${data.id}-${year}`, "MMM-YYYY")
        .endOf("month")
        .format("YYYY-MM-DD");

      this.filter.month = `${beginDate},${endDate}`;

      this.getChildhoodFiles();
    },

    openModalPreviewFile() {
      this.modalPreviewFile = true;
    },

    async getStudentData() {
      if (this.id) {
        await httpRda
          .get(`/students/${this.id}?include=parents,cycle`)
          .then(async (res) => {
            this.student = res.data.student;
            if (this.student) {
              if (this.student.cycle_id != 6 && this.student.cycle_id != 8) {
                this.$router.push({
                  name: "dashboard-tutoria-aluno",
                  params: { id: this.id },
                });
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });

        DataService.getStudentClassroomCycle(this.id)
          .then((response) => {
            this.classroom = response.data.classroom;
          })
          .catch((error) => {
            return error;
          });
      }
    },

    async getChildhoodFiles() {
      this.items = await httpRda
        .get(`/childhood/index?student_id=${this.id}`, {
          params: {
            visible_for_parents: true,
            type: this.filter.type ? this.filter.type.id : null,
            month: this.filter.month ? this.filter.month : null,
            followup_type_id: this.filter.followup_type_id
              ? this.filter.followup_type_id
              : null,
          },
        })
        .then((res) => {
          return res.data.result;
        });
    },

    async getFollowupTypes() {
      this.allFields = await httpRda
        .get("/childhood/followup-types")
        .then((res) => {
          return res.data.result;
        });
      this.allFieldsOptions.push(...this.allFields);
    },

    async getCategories() {
      this.allCategories = await httpRda
        .get("/childhood/categories")
        .then((res) => {
          return res.data.result;
        });
    },
  },

  beforeMount() {
    this.getStudentData();
  },

  mounted() {
    this.getCategories();
    this.getFollowupTypes();
    this.getChildhoodFiles();
  },
};
</script>

<style lang="scss">
#timeline-page {
  .filters {
    margin-bottom: 40px;
  }

  .disabled-input {
    background-color: #fafafa;
    opacity: 0.3;
  }

  .wrap-button-new {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .btn {
      margin: 32px 0 0 0 !important;
      font-size: 16px;
    }
  }
}

.wrapper-switch {
  cursor: pointer;
}

.switch_parents {
  width: 100%;
  padding: 16px;
  border: 1px solid #b3b3b3;
  border-radius: 0.25rem;
  cursor: default;
  pointer-events: none;

  &>div {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .label {
      font-size: 18px;
      color: #1a1a1a;
    }
  }

  .custom-control-input:checked~.custom-control-label::before {
    background-color: $purple;
    border-color: $purple;
  }
}

.footer-modal {
  padding-top: 20px;
}
</style>
