<template>
  <svg
    width="24"
    height="16"
    viewBox="0 0 24 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.2857 8.28571C19.9955 11.8214 16.2991 14.2857 12 14.2857C7.70089 14.2857 4.00446 11.8214 1.71429 8.28571C2.98661 6.31696 4.72768 4.62946 6.81696 3.55803C6.28125 4.46875 6 5.51339 6 6.57143C6 9.87946 8.69196 12.5714 12 12.5714C15.308 12.5714 18 9.87946 18 6.57143C18 5.51339 17.7188 4.46875 17.183 3.55803C19.2723 4.62946 21.0134 6.31696 22.2857 8.28571ZM12.6429 3.14286C12.6429 3.49107 12.3482 3.78571 12 3.78571C10.4732 3.78571 9.21429 5.04464 9.21429 6.57143C9.21429 6.91964 8.91964 7.21429 8.57143 7.21429C8.22321 7.21429 7.92857 6.91964 7.92857 6.57143C7.92857 4.33482 9.76339 2.5 12 2.5C12.3482 2.5 12.6429 2.79464 12.6429 3.14286ZM24 8.28571C24 7.95089 23.8929 7.64286 23.7321 7.36161C21.2679 3.31696 16.7545 0.571427 12 0.571427C7.24554 0.571427 2.73214 3.31696 0.267857 7.36161C0.107143 7.64286 0 7.95089 0 8.28571C0 8.62054 0.107143 8.92857 0.267857 9.20982C2.73214 13.2545 7.24554 16 12 16C16.7545 16 21.2679 13.2679 23.7321 9.20982C23.8929 8.92857 24 8.62054 24 8.28571Z"
      fill="url(#paint0_linear)"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="-7.32"
        y1="6.88119"
        x2="37.2"
        y2="6.88119"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#EF6549" />
        <stop offset="1" stop-color="#B23066" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {

}
</script>

<style lang="sass">
</style>