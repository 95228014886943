s<template>
  <div class="home">
    <template>
      <b-container class="container-rda">
        <b-row>
          <b-col>
            <b-breadcrumb :items="breadcrumb"></b-breadcrumb>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <h2 class="purple bold">Roteiro</h2>
          </b-col>

          <b-col class="text-right">
            <router-link
              class="large blue"
              :to="{
                name: 'editar-roteiro',
                params: {
                  subject: this.$route.params.subject,
                  id: id,
                },
              }"
              tag="b-button"
              >Editar roteiro</router-link
            >
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <div class="meeting-box">
              <div class="content-meeting">
                <h5 class="purple">Competência:</h5>
                <p>{{ competences }}</p>

                <h5 class="purple mt-4">Conteúdo:</h5>
                <p>{{ topics }}</p>

                <h5 class="purple mt-4">Palavras-Chave</h5>
                <p>{{ tags }}</p>

                <h5 class="purple mt-4">Tema:</h5>
                <p>{{ syllabus.theme }}</p>

                <h5 class="purple mt-4">Atividade:</h5>
                <p>{{ syllabus.name }}</p>

                <h5 class="purple mt-4">Introdução:</h5>
                <p>{{ syllabus.description }}</p>
              </div>
            </div>
          </b-col>
        </b-row>

        <b-row align-v="center" class="mt-4" v-if="syllabus.file">
          <b-col md="3" class="text-right">
            <b-button
              :href="syllabus.file"
              download
              target="_blank"
              class="large blue"
              >Baixar roteiro</b-button
            >
          </b-col>
        </b-row>
      </b-container>
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  name: 'Script',
  data() {
    return {
      breadcrumb : [
        {
          text: 'Aprendizagem',
          href: '/aprendizagem'
        },
        {
          text: 'Roteiros',
          href: '/aprendizagem/roteiros-historia'
        },
        {
          text: 'Roteiros Artes',
          active: true
        },
      ],
    }
  },

  computed: {
    ...mapGetters({
      syllabus: 'learning/syllabus',
      syllabusTopics: 'learning/syllabusTopics'
    }),

    competences() {
      if(!this.syllabusTopics.length) return '-'
      return this.syllabusTopics.map(t => t.Competence.description).join(', ')
    },

    topics() {
      if(!this.syllabusTopics.length) return '-'
      return this.syllabusTopics.map(t => t.description).join(', ')
    },

    tags() {
      if(!this.syllabus.Tags || !this.syllabus.Tags.length) return '-'
      return this.syllabus.Tags.join(', ')
    },

    id() {
      return this.$route.params.id
    }
  },

  methods: {
    ...mapActions({
      loadSyllabusDetail: 'learning/loadSyllabus'
    }),

    ...mapMutations({
      clearState: 'learning/CLEAR_SYLLABUS_TOPICS'
    })
  },

  created() {
    this.loadSyllabusDetail(this.id)
  },

  destroyed() {
    this.clearState()
  }
}
</script>

<style lang="scss" scoped>

@import "@/assets/css/variables.scss";

.profile-row {
  background: $gradient1;
  border-radius: 10px;
  color: $white;
  margin: 30px 0 60px;
  padding: 28px 10px;
  position: relative;
  &:after {
    content: url('~@/assets/images/watermark-profile.png');
    right: 0;
    pointer-events: none;
    position: absolute;
    top: -5px;
  }
  .col {
    display: flex;
    align-items: center;
    img {
      margin-right: 40px;
    }
    .content-meeting {
      display: flex;
      width: 100%;
      .column {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 60px;
        min-width: 200px;
        margin-right: 40px;
      }
      h5 {
        margin: 0;
      }
      p {
        margin: 0;
      }
    }
  }
}

.meeting-box {
  background-color: rgba( $blue, 0.20);
  border-radius: 10px;
  margin-bottom: 40px;
  margin-top: 30px;
  padding: 60px 30px 40px;

  .content-meeting {
    max-width: 1180px;
    margin: 0 auto;
    h3 {
      margin: 0 0 10px;
    }
    .discipline {
      color: $grey4;
      margin: 0;
    }
    .date-time {
      color: $grey5;
      display: block;
      font-size: 16px;
      margin: 0 0 20px;
    }
    .text {
      max-width: 1025px;
      margin: 0 0 15px;
    }
    .reply {
      color: $purple;
      font-size: 16px;
    }
  }

}

</style>