<template>
  <b-form-group label="Disciplinas">
    <multiselect
      :value="computedValue"
      :options="subjects"
      :multiple="false"
      :close-on-select="true"
      :clear-on-select="false"
      :preserve-search="true"
      :custom-label="customLabel"
      placeholder="Selecione"
      label="designation"
      track-by="id"
      selectLabel=""
      selectedLabel=""
      deselectLabel=""
      @select="callback"
    >
      <template slot="option" slot-scope="props">
        <div>
          <span
            >{{ props.option.designation }} -
            {{ props.option.Cycle.name }}</span
          >
        </div>
      </template>
      <span slot="noResult">Nada encontrado</span>
    </multiselect>
  </b-form-group>
</template>

<script>
import DataService from "@/services/subjects.service";
import httpRda from "../../http-rda";
import store from "../../store";

export default {
  props: [
    'value',
    'callback',
  ],
  data() {
    return {
      options: '',
      subjects: [],
    }
  },
  created() {
    this.getCycles();
  },
  methods: {
    async getCycles() {
      const {teacher_id} = store.getters["auth/getAuthData"];
      
      if(teacher_id){
      await httpRda.get(`/teachers/${teacher_id}/subjects?include=cycles`).then(response => {
        this.subjects = response.data.subjects;
      }).catch(error => {
        return error;
      });

      }else{
        DataService.getAll()
        .then(response => {
          this.subjects = response.data.subjects
        })
        .catch(error => {
          return error
        });
      }
    },
    customLabel ({ designation, Cycle }) {
      return `${designation} – ${Cycle.name}`
    }
  },
  computed: {
    computedValue() {
      if( typeof this.value === 'number' ){
        return this.subjects.find( c => c.id == this.value )
      } else {
        return this.value
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .form-group {
    margin-bottom: 0 !important;
  }
</style>