var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"list-page"},[_c('b-container',{staticClass:"container-rda"},[_c('b-row',{staticClass:"height"},[_c('b-col',{staticClass:"content"},[_c('b-row',[_c('b-col',[_c('b-breadcrumb',{attrs:{"items":_vm.breadcrumb}})],1)],1),_c('b-row',{staticClass:"mb-5"},[_c('b-col',[_c('h2',{staticClass:"purple bold"},[_vm._v("Roteiros")])]),(_vm.subject)?_c('b-col',{staticClass:"text-right"},[_c('b-button',{staticClass:"btn large blue",attrs:{"to":{
                name: 'registrar-roteiro',
                params: {
                  subject: this.subject,
                  syllabus_id: this.subject,
                },
              }}},[_vm._v("Adicionar roteiro")])],1):_vm._e()],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('p',{staticClass:"purple"},[_vm._v("Filtre para obter resultados")])]),_c('b-col',{attrs:{"md":"4"}},[_c('SelectSubjectsWithCycle',{attrs:{"callback":_vm.selectSubject},model:{value:(_vm.subject),callback:function ($$v) {_vm.subject=$$v},expression:"subject"}}),(this.subject != null)?_c('span',{staticClass:"clear-field",on:{"click":_vm.clearSubject}},[_vm._v("Limpar filtro")]):_vm._e()],1),_c('b-col',[(this.subject > 0)?_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.search.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"label":"Buscar roteiros"}},[_c('b-input-group-prepend',[_c('b-form-input',{attrs:{"type":"search","placeholder":"Digite aqui"},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}})],1),(
                    _vm.searchTerm != '' || _vm.subject != null || _vm.progress != null
                  )?_c('b-input-group-text',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"clear-fields",attrs:{"title":"Limpar Pesquisa"}},[_c('b-icon',{attrs:{"icon":"x"},on:{"click":_vm.clearFields}})],1):_vm._e()],1)],1):_vm._e()])],1),_c('b-row',[_c('b-col',[_c('div',{staticClass:"order-grid-box"},[_c('p',{staticClass:"order-list",class:{ desc: _vm.descOrder },on:{"click":_vm.orderName}},[_vm._v(" Ordenar "),_c('OrderNameIcon')],1)])])],1),_c('b-row',[_c('b-col',{staticClass:"list"},[(_vm.wasRequestMade && _vm.sortedSyllabuses.length)?_c('b-container',{attrs:{"fluid":""}},[_c('b-row',{staticClass:"header-list-teacher"},[_c('b-col',{attrs:{"md":"4"}},[_c('p',{staticClass:"bold"},[_vm._v("Conteúdo")])]),_c('b-col',{attrs:{"md":"5"}},[_c('p',{staticClass:"bold"},[_vm._v("Descrição")])]),_c('b-col',[_c('p',{staticClass:"bold"},[_vm._v("Roteiro")])]),_c('b-col',[_c('p',{staticClass:"bold"},[_vm._v("Alunos")])])],1)],1):_vm._e(),(_vm.wasRequestMade && _vm.sortedSyllabuses.length)?_c('b-container',{attrs:{"fluid":""}},_vm._l((_vm.sortedSyllabuses),function(sy){return _c('b-row',{key:sy.id,staticClass:"list-teacher scripts align-items-center"},[_c('b-col',{attrs:{"md":"4"}},[_c('h5',[_vm._v(_vm._s(sy.name))])]),_c('b-col',{attrs:{"md":"5"}},[_c('p',{staticClass:"desc"},[_vm._v(_vm._s(sy.description))])]),_c('b-col',[_c('router-link',{attrs:{"to":{
                      name: 'roteiro',
                      params: {
                        subject: _vm.subject,
                        id: sy.id,
                      },
                    }}},[_c('ViewIcon')],1)],1),_c('b-col',[_c('router-link',{attrs:{"to":{
                      name: 'registro-roteiros',
                      params: {
                        id: sy.id,
                      },
                    }}},[_c('ListUsers')],1)],1)],1)}),1):_vm._e(),(
                _vm.wasRequestMade && _vm.subject != null && !_vm.sortedSyllabuses.length
              )?_c('b-container',{attrs:{"fluid":""}},[_c('div',{staticClass:"row list-teacher scripts align-items-center"},[_c('b-col',[_c('h5',[_vm._v("Nenhum resultado encontrado")])])],1)]):_vm._e()],1)],1),(_vm.count > _vm.perPage && _vm.wasRequestMade)?_c('b-row',{staticClass:"align-center"},[_c('b-pagination',{attrs:{"per-page":_vm.perPage,"total-rows":_vm.count,"aria-controls":"teachersList","last-text":"","first-text":""},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('LeftArrowIcon',{staticClass:"left"})]},proxy:true},{key:"next-text",fn:function(){return [_c('LeftArrowIcon')]},proxy:true}],null,false,3938296578),model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }