<template>
  <div class="home">
    <template>
      <b-container class="container-rda">
        <b-row>
          <b-col>
            <b-breadcrumb :items="breadcrumb"></b-breadcrumb>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <h2 class="purple bold">Comentários</h2>
          </b-col>
          <b-col class="text-right"> </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-row class="profile-row">
              <b-col>
                <img src="@/assets/images/foto-perfil-dados.png" alt="" />
                <div class="content-comments">
                  <h5>Achilhes Najar Maia</h5>
                  <p>Ciclo: 2º EF</p>
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <MateriaSelect> </MateriaSelect>
          </b-col>
          <b-col>
            <MateriaSelect> </MateriaSelect>
          </b-col>
          <b-col md="3">
            <div class="order-grid-box">
              <p
                class="order-list"
                :class="{ desc: isActive }"
                v-on:click="orderName"
              >
                Ordenar
                <OrderNameIcon />
              </p>
              <p class="order-style">
                <button
                  class="list"
                  v-on:click="layout = 'list'"
                  v-bind:class="{ active: layout == 'list' }"
                >
                  <ListIcon />
                </button>
                <button
                  class="grid"
                  v-on:click="layout = 'grid'"
                  v-bind:class="{ active: layout == 'grid' }"
                >
                  <GridIcon />
                </button>
              </p>
            </div>
          </b-col>
        </b-row>
        <b-row class="comments-box">
          <b-col>
            <b-container fluid v-if="layout === 'list'" class="list">
              <b-row align-v="center" class="list-comments">
                <div class="content-comments">
                  <img
                    src="@/assets/images/parents-photo-enrollment-2.jpg"
                    alt=""
                  />
                  <div class="colunm">
                    <h3 class="purple">Título</h3>
                    <p class="discipline">Ciências da natureza</p>
                    <span class="date-time">17/12/2020 há 22min</span>
                    <p class="text">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Aenean tristique velit ac tincidunt hendrerit. Maecenas
                      commodo lobortis tempor. Proin dignissim bibendum ornare.
                      Nullam nisi libero, aliquam eu orci at, pulvinar rhoncus
                      eros. In accumsan volutpat pellentesque. Praesent eu neque
                      id tellus eleifend mollis.
                    </p>
                    <a href="#" class="reply">responder</a>
                  </div>
                </div>
              </b-row>
              <b-row align-v="center" class="list-comments">
                <div class="content-comments">
                  <img
                    src="@/assets/images/parents-photo-enrollment-2.jpg"
                    alt=""
                  />
                  <div class="colunm">
                    <h3 class="purple">Título</h3>
                    <p class="discipline">Ciências da natureza</p>
                    <span class="date-time">17/12/2020 há 22min</span>
                    <p class="text">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Aenean tristique velit ac tincidunt hendrerit. Maecenas
                      commodo lobortis tempor. Proin dignissim bibendum ornare.
                      Nullam nisi libero, aliquam eu orci at, pulvinar rhoncus
                      eros. In accumsan volutpat pellentesque. Praesent eu neque
                      id tellus eleifend mollis.
                    </p>
                    <a href="#" class="reply">responder</a>
                  </div>
                </div>
              </b-row>
              <b-row align-v="center" class="list-comments">
                <div class="content-comments">
                  <img
                    src="@/assets/images/parents-photo-enrollment-2.jpg"
                    alt=""
                  />
                  <div class="colunm">
                    <h3 class="purple">Título</h3>
                    <p class="discipline">Ciências da natureza</p>
                    <span class="date-time">17/12/2020 há 22min</span>
                    <p class="text">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Aenean tristique velit ac tincidunt hendrerit. Maecenas
                      commodo lobortis tempor. Proin dignissim bibendum ornare.
                      Nullam nisi libero, aliquam eu orci at, pulvinar rhoncus
                      eros. In accumsan volutpat pellentesque. Praesent eu neque
                      id tellus eleifend mollis.
                    </p>
                    <a href="#" class="reply">responder</a>
                  </div>
                </div>
              </b-row>
            </b-container>
          </b-col>
        </b-row>
        <b-row align-v="center" class="mt-5">
          <b-col> </b-col>
          <b-col class="text-right"> </b-col>
          <b-col class="text-right">
            <b-button href="/aluno/perfil" class="large blue outline"
              >Voltar</b-button
            >
          </b-col>
          <b-col class="text-right">
            <b-button href="/alunos" class="large blue">Concluir</b-button>
          </b-col>
        </b-row>
      </b-container>
    </template>
  </div>
</template>

<script>
import OrderNameIcon from '@/components/svg/OrderNameIcon.vue'
import ListIcon from '@/components/svg/ListIcon.vue'
import GridIcon from '@/components/svg/GridIcon.vue'
import MateriaSelect from '@/components/inputs/MateriaSelect.vue'

export default {
  name: 'comentarios',
  components: {
    OrderNameIcon,
    ListIcon,
    GridIcon,
    MateriaSelect
  },
  data() {
    return {
      layout: 'list',
      isActive: false,
      status: true,
      breadcrumb : [
        {
          text: 'Comunidade',
          href: '/comunidade'
        },
        {
          text: 'Alunos',
          href: '/alunos'
        },
        {
          text: 'Perfil',
          href: '/aluno/perfil'
        },
        {
          text: 'Comentários',
          href: '/comentarios',
          active: true
        },
      ],
      selected: null,
    }
  },
  methods: {
    orderName() {
      this.isActive = !this.isActive;
    },
  }
}
</script>

<style lang="scss" scoped>

@import "@/assets/css/variables.scss";

.profile-row {
  background: $gradient3;
  border-radius: 10px;
  color: $white;
  margin: 30px 0 60px;
  padding: 28px 10px;
  position: relative;
  &:after {
    content: url('~@/assets/images/watermark-profile.png');
    right: 0;
    pointer-events: none;
    position: absolute;
    top: -5px;
  }
  .col {
    display: flex;
    align-items: center;
    img {
      margin-right: 40px;
    }
    .content-comments {
      h5 {
        margin-bottom: 14px;
      }
      p {
        margin: 0;
      }
    }
  }
}

.order-grid-box {
  margin-top: 31px;
}



</style>