<template>
  <div class="home">
    <template>
      <b-container class="container-rda sidebar">
        <b-row class="height">
          <b-col md="2" class="menu-sidebar">
            <div class="menu">
              <ul>
                <li class="side-item active">
                  <span>{{ this.subject.designation }}</span>
                </li>
              </ul>
            </div>
          </b-col>
          <b-col class="content">
            <b-row>
              <b-col>
                <b-breadcrumb :items="breadcrumb"></b-breadcrumb>
              </b-col>
            </b-row>
            <b-row class="mb-5">
              <b-col md="7">
                <h2 class="purple bold">Registrar</h2>
              </b-col>
            </b-row>
            <b-row class="register-type">
              <b-col md="4">
                <b-form-group class="group-radio">
                  <label for="label" class="mb-4"> Tipo de registro </label>
                  <div class="radio-inline">
                    <b-form-radio
                      v-model="competences.type"
                      name="options"
                      value="competence"
                    >
                      Competência
                    </b-form-radio>
                    <b-form-radio
                      v-model="competences.type"
                      name="options"
                      value="content"
                    >
                      Conteúdo
                    </b-form-radio>
                  </div>
                </b-form-group>
              </b-col>
              <b-col class="status">
                <b-form-group label="Ativo">
                  <b-form-checkbox
                    v-model="competences.is_active"
                    switch
                  ></b-form-checkbox>
                </b-form-group>
              </b-col>
              <div class="w-100"></div>
              <b-container fluid v-if="this.competences.type === 'competence'">
                <b-row>
                  <b-col>
                    <b-form-group class="top-border">
                      <label for="label">Descritivo</label>
                      <b-form-textarea
                        v-model="competences.description"
                        placeholder="Texto aqui"
                      ></b-form-textarea>
                    </b-form-group>
                  </b-col>
                  <div class="w-100"></div>
                  <b-col md="3">
                    <b-button
                      class="large blue outline"
                      :to="{
                        name: 'listar-conteudos',
                        params: {
                          subject: competences.subject_id,
                        },
                      }"
                      >Voltar</b-button
                    >
                  </b-col>
                  <b-col md="3">
                    <b-button
                      @click="createCompetences"
                      class="large blue"
                      :class="{ disabled: !competences.description }"
                      >Salvar</b-button
                    >
                  </b-col>
                </b-row>
              </b-container>
              <b-container fluid v-if="this.competences.type === 'content'">
                <b-row>
                  <b-col>
                    <b-form-group class="top-border">
                      <label for="label">Descritivo</label>
                      <b-form-textarea
                        v-model="topics.description"
                        placeholder="Texto aqui"
                      ></b-form-textarea>
                    </b-form-group>
                  </b-col>
                  <div class="w-100"></div>
                  <b-col md="4">
                    <StandardInput
                      v-model="topics.bncc_code"
                      type="text"
                      name="bncc_code"
                      label="Código BNCC"
                    >
                    </StandardInput>
                  </b-col>
                  <div class="w-100"></div>
                  <b-col>
                    <SelectCompetences
                      v-model="topics.competence_id"
                      :callback="callbackCompetences"
                    >
                    </SelectCompetences>
                  </b-col>
                  <b-col md="3">
                    <StandardInput
                      v-model="topics.weight"
                      type="text"
                      name="weight"
                      v-mask="['###']"
                      label="Peso na avaliação"
                    >
                    </StandardInput>
                  </b-col>
                  <div class="w-100"></div>
                  <b-col md="3">
                    <b-button
                      class="large blue outline"
                      :to="{
                        name: 'listar-conteudos',
                        params: {
                          subject: competences.subject_id,
                        },
                      }"
                      >Voltar</b-button
                    >
                  </b-col>
                  <b-col md="3">
                    <b-button
                      @click="createTopics"
                      class="large blue"
                      :class="{
                        disabled:
                          !topics.description ||
                          !topics.competence_id ||
                          !topics.weight,
                      }"
                      >Salvar</b-button
                    >
                  </b-col>
                </b-row>
              </b-container>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import DataService from "@/services/competences.service";
import DataServiceTopics from "@/services/topics.service";

import StandardInput from "@/components/inputs/StandardInput.vue";
import SelectCompetences from "@/components/inputs/SelectCompetences.vue";

export default {
  name: "novo-conteudo",
  components: {
    StandardInput,
    SelectCompetences,
  },
  data() {
    return {
      subject_id: this.$route.params.subject,
      competences: {
        subject_id: this.$route.params.subject,
        description: '',
        weight: '1',
        is_active: true,
        type: "competence",
      },
      topics: {
        competence_id: "",
        description: "",
        weight: "",
        is_active: true,
      },
      breadcrumb: [
        {
          text: "Coordenação",
          href: "/coordenacao",
        },
        {
          text: "Currículo",
          href: "/coordenacao/conteudos",
        },
        {
          text: "Registrar Roteiro",
          href: "/coordenacao/conteudos/registrar",
          active: true,
        },
      ],
    };
  },

  computed: {
    ...mapGetters({
      subjects: "shared/subjects",
    }),

    subject() {
      return this.subjects.find(s => s.id == this.subject_id)
    }
  },

  methods: {
    createCompetences() {
      var data = {
        subject_id: this.competences.subject_id,
        description: this.competences.description,
        weight: this.competences.weight,
        is_active: this.competences.is_active,
      };
      DataService.create(data)
        .then((response) => {
          this.competences.id = response.data.id
          this.$router.push({
            name: "listar-conteudos",
            params: {
              subject: this.competences.subject_id,
            },
          })
          const Toast = this.$swal.mixin({
            toast: true,
            position: "bottom-end",
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer);
              toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            },
          })

          Toast.fire({
            icon: "success",
            title: "Competência cadastrada com sucesso!",
          })

          return response.data
        }).catch((error) => {
          this.errorMessage()
          return error.response
        })
    },

    createTopics() {
      var data = {
        competence_id: this.topics.competence_id,
        description: this.topics.description,
        weight: this.topics.weight,
        is_active: this.topics.is_active,
      };
      DataServiceTopics.create(data)
        .then((response) => {
          this.topics.id = response.data.id
          this.$router.push({
            name: "listar-conteudos",
            params: {
              subject: this.competences.subject_id,
            },
          })
          const Toast = this.$swal.mixin({
            toast: true,
            position: "bottom-end",
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer);
              toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            },
          })

          Toast.fire({
            icon: "success",
            title: "Conteúdo cadastrado com sucesso!",
          })

          return response.data
        }).catch((error) => {
          this.errorMessage()
          return error.response
        })
    },

    callbackCompetences(args) {
      this.topics.competence_id = args.id;
    },

    selectSubject() {
      this.subject_id = this.$route.params.subject
      this.getSubjects(this.subject_id)
    },

    ...mapActions({
      getSubjects: "shared/listSubjects",
    }),

    errorMessage(){
      this.$swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Não foi possivel cadastrar!",
        footer:
          "Por favor, verifique se todos os campos estão preenchidos.",
      })
    }
  },

  created() {
    this.getSubjects();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/variables.scss";

.home {
  height: 100%;
}

.container-rda {
  padding-bottom: 0;
  .form-group {
    padding-bottom: 25px;
  }
}

.tags {
  background-color: $blue;
  border-radius: 5px;
  color: $white;
  font-weight: $bold;
  line-height: 28px;
  margin: 3px 12px 3px 0;
  max-width: 58px;
  padding: 0 3px;
  text-align: center;
  vertical-align: middle;
  width: 100%;
}

.register-type {
  padding-bottom: 30px;
  .top-border {
    border-top: 2px solid $grey7;
    padding-top: 35px;
    margin-top: 10px;
  }
}

ul,
li {
  padding: 0;
}

.side-item {
  &.hide {
    display: none !important;
  }
}
</style>