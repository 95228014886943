const cycles = state => state.cycles;
const subjects = state => state.subjects;
const competences = state => state.competences;
const topicsByCompetences = state => state.topicsByCompetences;
const topics = state => state.topics;
const countries = state => state.countries;
const states = state => state.states;
const cities = state => state.cities;
const users = state => state.users;
const studentsCycle = state => state.studentsCycle;
const studentsAttendances = state => state.studentsAttendances;
const studentsSubjects = state => state.studentsSubjects;
const calendars = state => state.calendars;

export default {
  cycles,
  subjects,
  competences,
  topicsByCompetences,
  topics,
  countries,
  states,
  cities,
  users,
  studentsCycle,
  studentsAttendances,
  studentsSubjects,
  calendars
}
