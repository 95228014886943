<template>
  <b-form-group label="Disciplina">
    <multiselect
      :value="computedValue"
      :options="subjects"
      :multiple="false"
      :close-on-select="true"
      :clear-on-select="false"
      :preserve-search="true"
      placeholder="Selecione"
      label="designation"
      track-by="id"
      selectLabel=""
      selectedLabel=""
      deselectLabel=""
      @select="callback"
    >
      <span slot="noResult">Nada encontrado</span>
    </multiselect>
  </b-form-group>
</template>

<script>
export default {
  props: [
    'value',
    'callback'
  ],
  data() {
    return {
      subject: null,
      subjects: [
        { id: '1', designation: 'Artes' },
        { id: '2', designation: 'Ciências da Natureza' },
        { id: '3', designation: 'Ciências Humanas e Sociais' },
        { id: '4', designation: 'Corpo e Mente' },
        { id: '5', designation: 'Língua Estrangeira' },
        { id: '6', designation: 'Língua Portuguesa' },
        { id: '7', designation: 'Matemática' },
      ],
    }
  },

  computed: {
    computedValue() {
      if( typeof this.designation === 'number' ){
        return this.subjects.find( c => c.id == this.designation )
      } else {
        return this.designation
      }
    }
  }
}
</script>