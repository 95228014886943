<template>
  <div class="list-page">
    <b-container class="container-rda">
      <b-row>
        <b-col class="content">
          <b-row>
            <b-col>
              <b-breadcrumb :items="breadcrumb" />
            </b-col>
          </b-row>

          <b-row class="mb-5">
            <b-col>
              <h2 class="purple bold">
                Registro de roteiro - {{ syllabus.name }}
              </h2>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <b-form-group label="Buscar alunos">
                <b-input-group-prepend>
                  <b-form-input
                    v-model="searchTerm"
                    type="search"
                    placeholder="Digite aqui"
                  />
                </b-input-group-prepend>
                <b-input-group-text
                  class="clear-fields"
                  v-if="searchTerm != ''"
                  v-b-tooltip.hover
                  title="Limpar Pesquisa"
                >
                  <b-icon icon="x" @click="clearFields" />
                </b-input-group-text>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <div class="order-grid-box">
                <p
                  class="order-list"
                  :class="{ desc: descOrder }"
                  v-on:click="orderName"
                >
                  Ordenar
                  <OrderNameIcon />
                </p>
              </div>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <b-container fluid>
                <b-row class="header-list-teacher" v-if="orderbyName.length">
                  <b-col md="5">
                    <p class="bold">Aluno</p>
                  </b-col>
                  <b-col>
                    <p class="bold">Data início</p>
                  </b-col>
                  <b-col>
                    <p class="bold">Data final</p>
                  </b-col>
                  <b-col md="2">
                    <p class="bold">Progresso</p>
                  </b-col>
                  <b-col>
                    <p class="bold">Editar data</p>
                  </b-col>
                  <b-col>
                    <p class="bold">Visualizar</p>
                  </b-col>
                </b-row>
              </b-container>

              <b-container fluid>
                <b-row
                  align-v="center"
                  class="list-teacher"
                  v-for="(student, index) in orderbyName"
                  :key="index"
                >
                  <b-col md="5">
                    <p>{{ student.Student.User.name }}</p>
                  </b-col>

                  <b-col>
                    <p class="learning-date">
                      {{ student.created_at | date }}
                    </p>
                  </b-col>

                  <b-col>
                    <p class="learning-date">
                      {{ student.deadline | date }}
                    </p>
                  </b-col>

                  <b-col md="2">
                    <b-row class="row-progress" align-v="center" no-gutters>
                      <b-col>
                        <span>{{ parseInt(student.percentage) }}%</span>
                      </b-col>
                      <b-col cols="9">
                        <b-progress
                          :value="student.percentage"
                          :class="getColor(student.percentage)"
                        ></b-progress>
                      </b-col>
                    </b-row>
                  </b-col>

                  <b-col v-b-modal.edit-dates>
                    <span @click="() => editStudentDeadline(student)">
                      <EditIcon />
                    </span>
                  </b-col>

                  <b-col>
                    <router-link
                      :to="{
                        name: 'roteiro-student-visualizar',
                        params: {
                          id: $route.params.id,
                          student_id: student.student_id,
                        },
                      }"
                    >
                      <ViewIcon />
                    </router-link>
                  </b-col>
                </b-row>

                <b-row
                  align-v="center"
                  class="list-teacher"
                  v-if="wasRequestMade && !orderbyName.length"
                >
                  <b-col> Nenhum resultado encontrado </b-col>
                </b-row>

                <b-row
                  align-v="center"
                  class="list-teacher"
                  v-if="wasRequestMade"
                >
                  <b-col> Carregando </b-col>
                </b-row>

                <b-row
                  class="align-center"
                  v-if="count > perPage && wasRequestMade"
                >
                  <b-pagination
                    v-model="currentPage"
                    :per-page="perPage"
                    :total-rows="count"
                    aria-controls="teachersList"
                    last-text=""
                    first-text=""
                  >
                    <template #prev-text
                      ><LeftArrowIcon class="left"
                    /></template>
                    <template #next-text><LeftArrowIcon /></template>
                  </b-pagination>
                </b-row>
              </b-container>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>

    <edit-syllabus-date-modal
      :student="studentToEditDate"
      :callback="onNewDateSave"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import EditSyllabusDateModal from "@/components/modals/EditSyllabusDateModal.vue";

import OrderNameIcon from "@/components/svg/OrderNameIcon.vue";
import EditIcon from "@/components/svg/EditIcon2.vue";
import LeftArrowIcon from "@/components/svg/LeftArrowIcon";
import ViewIcon from "@/components/svg/ViewIcon2.vue";

export default {
  name: "SyllabusesStudentsList",
  components: {
    EditSyllabusDateModal,

    OrderNameIcon,
    EditIcon,
    LeftArrowIcon,
    ViewIcon,
  },

  data() {
    return {
      studentToEditDate: null,

      descOrder: false,
      wasRequestMade: false,
      subject: null,
      progress: null,
      searchTerm: "",

      currentPage: 1,
      count: 0,
      perPage: 20,

      breadcrumb: [
        {
          text: "Aprendizagem",
          href: "/aprendizagem",
        },
        {
          text: "Roteiros",
          href: "/aprendizagem/roteiros",
        },
        {
          text: "Registro de Roteiros",
          href: "/aprendizagem/conteudos",
          active: true,
        },
      ],
    };
  },

  filters: {
    date(date) {
      try {
        var d = new Date(date.split());
        var year = d.getFullYear().toString().substr(-2);
        var month = (d.getMonth() + 1).toString().padStart(2, "0");
        var day = d.getUTCDate().toString().padStart(2, "0");

        return day + "/" + month + "/" + year;
      } catch (error) {
        return date;
      }
    },
  },

  computed: {
    ...mapGetters({
      students: "learning/studentSyllabusList",
    }),

    syllabus() {
      if (!this.students.length) return {};
      return this.students[0].Syllabus;
    },

    orderbyName() {
      return [...this.students]
        .filter((student) => {
          const normalizedName = this.normalizeString(
            student.Student.User.name
          );
          return normalizedName.includes(this.searchTerm);
        })
        .sort((a, b) => {
          const order = this.descOrder ? [1, -1] : [-1, 1];

          if (a.name < b.name) return order[0];
          else if (a.name > b.name) return order[1];

          return 0;
        });
    },
  },

  methods: {
    ...mapActions({
      getStudents: "learning/getStudentsBySyllabus",
      updateDeadline: "learning/updateStudentSyllabusDeadline",
    }),

    orderName() {
      this.descOrder = !this.descOrder;
    },

    clearFields() {
      this.searchTerm = "";
      this.subject = null;
      this.currentPage = 1;
    },

    editStudentDeadline(student_syllabus) {
      this.studentToEditDate = student_syllabus;
    },

    async onNewDateSave(params) {
      const { Syllabus, Student } = this.studentToEditDate;
      const formattedDate = this.formatDateToUs(params);
      try {
        await this.updateDeadline({
          student_id: Student.id,
          syllabus_id: Syllabus.id,
          deadline: formattedDate,
        });
        this.getStudents({ syllabus_id: this.$route.params.id });

        const Toast = this.$swal.mixin({
          toast: true,
          position: "bottom-end",
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", this.$swal.stopTimer);
            toast.addEventListener("mouseleave", this.$swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: "success",
          title: "Data atualizada com sucesso!",
        });
      } catch (error) {
        this.$swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Não foi possivel atualizar!",
          footer: "Por favor, tente novamente mais tarde.",
        });

        return error.response;
      }

      this.studentToEditDate = null;
    },

    formatDateToUs(date) {
      return new Intl.DateTimeFormat("ko-KR", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      })
        .format(new Date(date))
        .replaceAll(". ", "/")
        .replace(".", "");
    },

    normalizeString(str) {
      return str
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase();
    },

    getColor(percentage) {
      return (
        `${percentage <= 10 ? "red" : ""}` ||
        `${percentage < 60 ? "orange" : ""}` ||
        `${percentage < 90 ? "yellow" : ""}` ||
        `${percentage >= 90 ? "green" : ""}`
      );
    },
  },

  created() {
    this.getStudents({ syllabus_id: this.$route.params.id });
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/variables.scss";

.home {
  height: 100%;
}

.progress {
  border-radius: 30px;
  height: 7px;
  .progress-bar {
    background-color: $purple;
  }
  &.orange {
    .progress-bar {
      background-color: $orange2;
    }
  }
  &.yellow {
    .progress-bar {
      background-color: $yellow;
    }
  }
  &.red {
    .progress-bar {
      background-color: $red;
    }
  }
  &.green {
    .progress-bar {
      background-color: $green2;
    }
  }
}

.container-rda {
  padding-bottom: 0;
}

.learning-date {
  background-color: $purple;
  border-radius: 6px;
  color: $white;
  line-height: 28px;
  max-width: 82px;
  text-align: center;
  width: 100%;
}

.learning-tags {
  font-size: 0;
  span {
    background-color: $grey5;
    border-radius: 6px;
    color: $white;
    display: inline-block;
    font-size: 18px;
    line-height: 28px;
    margin-right: 12px;
    max-width: 34px;
    width: 100%;
    text-align: center;
    &.active {
      background-color: $blue;
    }
  }
}

.list-page {
  .list-teacher:hover {
    cursor: unset;
  }

  .list-actions {
    padding: 10px 0;
    cursor: pointer;

    .evaluation:not(.evaluated) path {
      fill: gray;
    }
  }
}
</style>
