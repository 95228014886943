<template>
  <b-container class="container-rda">
    <b-row>
      <b-col>
        <b-breadcrumb :items="breadcrumb"></b-breadcrumb>
      </b-col>
    </b-row>
    <b-row class="mb-4">
      <b-col>
        <h2 class="purple bold">Histórico de acesso ao sistema</h2>
      </b-col>
      <b-col class="text-right"></b-col>
    </b-row>
    <b-row class="mb-5">
      <b-col v-if="false">
        <a href="#" class="boxes-history">
          <p>Usuários online</p>
          <h1>20</h1>
        </a>
      </b-col>
      <b-col v-if="false">
        <a href="#" class="boxes-history">
          <p>Acessos/mês</p>
          <h1>4.500</h1>
        </a>
      </b-col>
      <b-col>
        <a href="#" class="boxes-history">
          <p>Alunos registrados</p>
          <h1>{{ this.countStudents }}</h1>
        </a>
      </b-col>
      <b-col>
        <a href="#" class="boxes-history active">
          <p>Acessos falhados</p>
          <h1>{{ this.counts }}</h1>
        </a>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group class="calendar">
          <label>Data</label>
          <Datepicker
            v-model="date"
            @selected="callbackDate"
            placeholder="Todos"
            format="dd/MM/yyyy"
            :use-utc="true"
            value="date"
            :language="languages[language]"
          ></Datepicker>
        </b-form-group>
        <span class="clear-field" v-if="this.date != null" @click="clearDate">Limpar filtro</span>
      </b-col>
      <b-col>
        <SelectBrowser
          v-model="browser"
          :callback="callbackBrowser"
          label="Agent"
          placeholder="Todos"
        ></SelectBrowser>
        <span class="clear-field" v-if="this.browser != null" @click="clearBrowser">Limpar filtro</span>
      </b-col>
      <b-col>
        <SelectPlataform
          v-model="platform"
          :callback="callbackPlataform"
          label="Plataforma"
          placeholder="Todos"
        ></SelectPlataform>
        <span class="clear-field" v-if="this.platform != null" @click="clearPlatform">Limpar filtro</span>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <div class="order-grid-box">
          <p class="order-list" :class="{ desc: descOrder }" v-on:click="orderName">
            Ordenar
            <OrderNameIcon />
          </p>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-container fluid>
          <b-row class="header-list-teacher" v-if="logs.length > 0 && isRequestSend">
            <b-col>
              <p class="bold">ID</p>
            </b-col>
            <b-col md="2">
              <p class="bold">IP</p>
            </b-col>
            <b-col md="5">
              <p class="bold">Agent</p>
            </b-col>
            <b-col>
              <p class="bold">USR</p>
            </b-col>
            <b-col md="2">
              <p class="bold">Data/Hora</p>
            </b-col>
            <b-col>
              <p class="bold">Plataforma</p>
            </b-col>
          </b-row>
        </b-container>
        <b-container fluid>
          <b-row
            align-v="center"
            class="list-teacher"
            v-for="log in orderbyName"
            v-bind:key="log.id"
          >
            <b-col>
              <p>{{ log.id }}</p>
            </b-col>
            <b-col md="2">
              <p>{{ log.ip }}</p>
            </b-col>
            <b-col md="5">
              <p>
                {{ log.agent.browser }} ({{ log.agent.os }} v.{{
                log.agent.version
                }})
              </p>
            </b-col>
            <b-col>
              <p>{{ log.user_id }}</p>
            </b-col>
            <b-col md="2">
              <p>{{ log.updated_at | formatDate }}</p>
            </b-col>
            <b-col>
              <p>{{ log.agent.platform }}</p>
            </b-col>
          </b-row>
          <b-row align-v="center" class="list-teacher" v-if="logs.length <= 0 && isRequestSend">
            <b-col>Nenhum resultado encontrado</b-col>
          </b-row>
          <b-row align-v="center" class="list-teacher" v-if="!isRequestSend">
            <b-col>Carregando</b-col>
          </b-row>
          <b-row class="align-center" v-else-if="count > perPage && isRequestSend">
            <b-pagination
              v-model="page"
              :per-page="perPage"
              :total-rows="count"
              @change="changePage"
              last-text
              first-text
            >
              <template #prev-text>
                <LeftArrowIcon class="left" />
              </template>
              <template #next-text>
                <LeftArrowIcon />
              </template>
            </b-pagination>
          </b-row>
        </b-container>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import DataService from "@/services/logs.service";
import DataServiceStudents from "@/services/students.service";

import Moment from "moment";
import Datepicker from "vuejs-datepicker";
import * as lang from "vuejs-datepicker/src/locale";
import SelectBrowser from "@/components/inputs/SelectBrowser.vue";
import SelectPlataform from "@/components/inputs/SelectPlataform.vue";
import OrderNameIcon from "@/components/svg/OrderNameIcon.vue";
import LeftArrowIcon from "@/components/svg/LeftArrowIcon.vue";

require("moment/locale/pt-br");
Moment.locale("pt-br");

export default {
  name: "acessos-falhados",
  components: {
    SelectBrowser,
    SelectPlataform,
    Datepicker,
    OrderNameIcon,
    LeftArrowIcon,
  },
  data() {
    return {
      logs: [],
      descOrder: false,
      isRequestSend: false,

      counts: null,
      countStudents: null,

      date: null,
      browser: null,
      platform: null,

      page: 1,
      count: 0,
      perPage: 20,

      breadcrumb: [
        {
          text: "Coordenação",
          href: "/coordenacao",
        },
        {
          text: "Histórico",
          href: "/historico",
          active: true,
        },
      ],
      language: "ptBR",
      languages: lang,
    };
  },
  methods: {
    getCount() {
      DataService.getFaileds("401")
        .then((response) => {
          this.counts = response.data.logs.length;
        })
        .catch((error) => {
          return error;
        });
    },

    getCountStudents() {
      DataServiceStudents.getAll()
        .then((response) => {
          this.countStudents = response.data.students.length;
        })
        .catch((error) => {
          return error;
        });
    },

    getRequestParams(page, perPage, formatDate, browser, platform) {
      let params = {};

      if (page) {
        params["page"] = page;
      }

      if (perPage) {
        params["limit"] = perPage;
      }

      if (formatDate) {
        params["created"] = formatDate;
      }

      if (browser) {
        params["browser"] = browser.value;
      }

      if (platform) {
        params["platform"] = platform.value;
      }

      return params;
    },

    getList() {
      const params = this.getRequestParams(
        this.page,
        this.perPage,
        this.formatDate,
        this.browser,
        this.platform
      );

      DataService.getAll(params)
        .then((response) => {
          this.logs = response.data.logs;
          this.count = response.data.count;
          this.isRequestSend = true;
        })
        .catch((error) => {
          return error;
        });
    },

    orderName() {
      this.descOrder = !this.descOrder;
    },

    callbackDate(args) {
      this.date = args;
      this.page = 1;
      this.getList();
    },

    callbackBrowser(args) {
      this.browser = args;
      this.page = 1;
      this.getList();
    },

    callbackPlataform(args) {
      this.platform = args;
      this.page = 1;
      this.getList();
    },

    changePage(page) {
      this.page = page;
      this.getList();
      window.scrollTo({ top: 0, behavior: "smooth" });
    },

    clearFields(date, browser, platform) {
      this.date = null;
      this.browser = null;
      this.platform = null;
      this.getList({ created: date, browser: browser, platform: platform });
    },

    clearDate(date) {
      this.date = null;
      this.getList({ created: date });
    },

    clearBrowser(browser) {
      this.browser = null;
      this.getList({ browser: browser });
    },

    clearPlatform(platform) {
      this.platform = null;
      this.getList({ platform: platform });
    },
  },

  created() {
    this.getList({ limit: this.perPage, page: 1 });
    this.getCount();
    this.getCountStudents();
  },

  computed: {
    orderbyName() {
      return [...this.logs].sort((a, b) => {
        const order = this.descOrder ? [1, -1] : [-1, 1];

        if (a.id < b.id) return order[0];
        else if (a.id > b.id) return order[1];

        return 0;
      });
    },
    formatDate() {
      if (!this.date) return this.date;
      else
        return new Intl.DateTimeFormat("ko-KR", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        })
          .format(new Date(this.date))
          .replaceAll(". ", "-")
          .replace(".", "");
    },
  },

  filters: {
    formatDate(value) {
      return Moment(value).format("DD/MM/YYYY");
    },
  },
};
</script>

<style lang="scss" scoped>

.home {
  height: 100%;
}

</style>