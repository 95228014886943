<template>
  <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.1429 19.1429C17.1429 19.6116 16.7545 20 16.2857 20C15.817 20 15.4286 19.6116 15.4286 19.1429C15.4286 18.6741 15.817 18.2857 16.2857 18.2857C16.7545 18.2857 17.1429 18.6741 17.1429 19.1429ZM20.5714 19.1429C20.5714 19.6116 20.183 20 19.7143 20C19.2455 20 18.8571 19.6116 18.8571 19.1429C18.8571 18.6741 19.2455 18.2857 19.7143 18.2857C20.183 18.2857 20.5714 18.6741 20.5714 19.1429ZM22.2857 16.1429C22.2857 15.433 21.7098 14.8571 21 14.8571H15.2812C14.9196 15.8482 13.9688 16.5714 12.8571 16.5714H9.42857C8.31696 16.5714 7.36607 15.8482 7.00446 14.8571H1.28571C0.575893 14.8571 0 15.433 0 16.1429V20.4286C0 21.1384 0.575893 21.7143 1.28571 21.7143H21C21.7098 21.7143 22.2857 21.1384 22.2857 20.4286V16.1429ZM17.933 7.46428C18.067 7.15625 18 6.78125 17.7455 6.54018L11.7455 0.540177C11.5848 0.366071 11.3571 0.285714 11.1429 0.285714C10.9286 0.285714 10.7009 0.366071 10.5402 0.540177L4.54018 6.54018C4.28571 6.78125 4.21875 7.15625 4.35268 7.46428C4.48661 7.78571 4.79464 8 5.14286 8H8.57143V14C8.57143 14.4687 8.95982 14.8571 9.42857 14.8571H12.8571C13.3259 14.8571 13.7143 14.4687 13.7143 14V8H17.1429C17.4911 8 17.7991 7.78571 17.933 7.46428Z" fill="url(#paint0_linear)"/>
    <defs>
    <linearGradient id="paint0_linear" x1="-7.015" y1="10.8812" x2="35.65" y2="10.8812" gradientUnits="userSpaceOnUse">
    <stop stop-color="#EF6549"/>
    <stop offset="1" stop-color="#B23066"/>
    </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  
}
</script>

<style lang="sass">

</style>