<template>
  <div class="home">
    <template>
      <b-container class="container-rda">
        <b-row>
          <b-col>
            <b-breadcrumb :items="breadcrumb"></b-breadcrumb>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <h2 class="purple bold">Matrículas canceladas</h2>
          </b-col>
        </b-row>
        <b-row class="mt-50">
          <b-col md="4">
            <SelectYear label="Ano" placeholder="Selecione"></SelectYear>
          </b-col>
          <b-col>
            <b-form-group label="Buscar">
              <b-form-input type="search" placeholder="Digite aqui"></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <div class="order-grid-box">
              <p class="order-list" :class="{ desc: isActive }" v-on:click="orderName">
                Ordenar
                <OrderNameIcon />
              </p>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-container fluid>
              <b-row class="column-list">
                <b-col md="3">
                  <p class="bold">Aluno</p>
                </b-col>
                <b-col md="4">
                  <p class="bold">Contratante responsável financeiro</p>
                </b-col>
                <b-col>
                  <p class="bold">Contato</p>
                </b-col>
                <b-col md="2">
                  <p class="bold">Data cancelamento</p>
                </b-col>
              </b-row>
            </b-container>
            <b-container fluid>
              <b-row align-v="center" class="list-orders">
                <b-col md="3">
                  <p>Adelson Pereira França</p>
                </b-col>
                <b-col md="4">
                  <p>Antônio Carlos Maia Figueiredo</p>
                </b-col>
                <b-col>
                  <p>(31)9646-4621</p>
                </b-col>
                <b-col md="2">
                  <p class="tags-purple">20/11/20</p>
                </b-col>
              </b-row>
            </b-container>
          </b-col>
        </b-row>
        <b-row class="align-center">
          <b-pagination aria-controls="teachersList" last-text first-text>
            <template #prev-text>
              <LeftArrowIcon class="left" />
            </template>
            <template #next-text>
              <LeftArrowIcon />
            </template>
          </b-pagination>
        </b-row>
      </b-container>
    </template>
  </div>
</template>

<script>
import LeftArrowIcon from "@/components/svg/LeftArrowIcon.vue";
import OrderNameIcon from "@/components/svg/OrderNameIcon.vue";
import SelectYear from "@/components/inputs/SelectYear.vue";

export default {
  name: "matriculas-canceladas",
  components: {
    LeftArrowIcon,
    OrderNameIcon,
    SelectYear,
  },
  data() {
    return {
      isActive: false,
      breadcrumb: [
        {
          text: "Secretaria",
          href: "/secretaria",
        },
        {
          text: "Matrículas canceladas",
          href: "/matriculas-canceladas",
          active: true,
        },
      ],
    };
  },
  methods: {
    orderName() {
      this.isActive = !this.isActive;
    },
  },
};
</script>

<style lang="scss" scoped>

@import "@/assets/css/variables.scss";

.list-orders {
  background-color: $white;
  border: 1px solid $grey5;
  border-radius: 10px;
  margin-bottom: 15px;
  min-height: 80px;
  margin-right: -12px;
  &:last-child {
    margin-bottom: 0px;
  }
  &:hover {
    cursor: pointer;
    border-color: transparent;
    background-image: linear-gradient($white, $white), radial-gradient(circle at top left, $orange, $purple);
    background-origin: border-box;
    background-clip: content-box, border-box;
  }
  p {
    margin: 0
  }
  .tags-purple {
    background-color: $purple;
    border-radius: 5px;
    color: $white;
    font-weight: $bold;
    line-height: 28px;
    max-width: 82px;
    text-align: center;
  }
}

.column-list {
  color: $grey1;
}

.flex-right {
  align-items: center;
  display: flex;
  justify-content: flex-end;
}

.qty-order {
  background-color: $purple;
  border-radius: 5px;
  color: $white;
  font-weight: $bold;
  line-height: 28px;
  max-width: 100px;
  margin: 0;
  text-align: center;
  width: 100%;
}

</style>