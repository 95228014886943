import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";

import axios from "axios";

import EmptyRoute from "../views/EmptyRoute.vue";

import Comments from "../views/community/student/Comments.vue";
import StudentsList from "../views/community/student/List.vue";
import MeetingResume from "../views/community/student/MeetingResume.vue";
import Meetings from "../views/community/student/Meetings.vue";
import StudentsProfile from "../views/community/student/Profile.vue";
import StudentTypeRegister from "../views/community/student/StudentTypeRegister.vue";
import EnrollForm from "../views/enroll/Form.vue";
import StudentCard from "../views/community/student/StudentCard.vue";
import StudentDevelopmentReport from "../views/community/student/StudentDevelopmentReport.vue";
import TeachersCreateUpdate from "../views/community/teacher/CreateUpdate.vue";
import TeacherDetail from "../views/community/teacher/Detail.vue";
import TeachersList from "../views/community/teacher/List.vue";
import TutoringList from "../views/community/tutoring/List.vue";
import TutoringDetails from "../views/community/tutoring/Detail.vue";
import CyclesDisciplinesTutors from "../views/community/CyclesDisciplinesTutors.vue";

import SchoolCalendar from "../views/coordination/calendar/SchoolCalendar.vue";
import ClassroomsCreate from "../views/coordination/classrooms/Create.vue";
import ClassroomsList from "../views/coordination/classrooms/List.vue";
import ClassroomsUpdate from "../views/coordination/classrooms/Update.vue";
import CompetenceCreate from "../views/coordination/competences/Create.vue";
import CompetenceList from "../views/coordination/competences/List.vue";
import CompetencesUpdate from "../views/coordination/competences/UpdateCompetences.vue";
import TopicsUpdate from "../views/coordination/competences/UpdateTopics.vue";
import CyclesCreate from "../views/coordination/cycles/Create.vue";
import CyclesList from "../views/coordination/cycles/List.vue";
import CyclesUpdate from "../views/coordination/cycles/Update.vue";
import SystemAccessHistory from "../views/coordination/logs/SystemAccessHistory.vue";
import SystemFailedAccessesHistory from "../views/coordination/logs/SystemFailedAccessesHistory.vue";
import SubjectsCreate from "../views/coordination/subjects/Create.vue";
import SubjectsList from "../views/coordination/subjects/List.vue";
import SubjectsUpdate from "../views/coordination/subjects/Update.vue";
import AuthorizationsTeachers from "../views/coordination/teachers/Authorizations.vue";
import PasswordReset from "../views/coordination/PasswordReset.vue";

import Attendances from "../views/dashboards/Attendances.vue";
import DashParents from "../views/dashboards/DashParents.vue";
// import DashPayment from "../views/dashboards/DashPayment.vue";
import DashStudents from "../views/dashboards/DashStudents.vue";
import DashTutoring from "../views/dashboards/DashTutoring.vue";
import DashTimeline from "../views/dashboards/DashTimeline.vue";
import DashTimelineParent from "../views/dashboards/DashTimelineParent.vue";
import Dashboard from "../views/dashboards/Dashboard.vue";

import CreateAccount from "../views/enrollment/CreateAccount.vue";
import EnrollmentExpiredToken from "../views/EnrollmentExpiredToken.vue";
import Enrollment from "../views/enroll/Enrollment.vue";

import LearningRecord from "../views/learning/LearningRecord.vue";
import Evaluation from "../views/learning/Evaluation.vue";
import LearningRecordStudent from "../views/learning/LearningRecordStudent.vue";
import Script from "../views/learning/Script.vue";
import StudentScript from "../views/learning/StudentScript.vue";
import SyllabusesStudentsList from "../views/learning/SyllabusesStudentsList.vue";
import SyllabusCreateEdit from "../views/learning/SyllabusCreateEdit.vue";
import SyllabusesList from "../views/learning/SyllabusesList.vue";
// import StudentSyllabusesList from "../views/learning/StudentSyllabusesList.vue";
import TopicsStudentsList from "../views/learning/TopicsStudentsList.vue";

import ForgotPassword from "../views/login/ForgotPassword.vue";
import Login from "../views/login/Login.vue";
import ResetPassword from "../views/login/ResetPassword.vue";
import FormDataDeletion from "../views/lgpd/FormDataDeletion.vue";
import Privacy from "../views/lgpd/Privacy.vue";

import ChildrenProfile from "../views/profile/ChildrenProfile.vue";
import UserProfile from "../views/profile/UserProfile.vue";

import EnrollmentData from "../views/community/student/enrollmentData/EnrollmentData.vue";

import Claimants from "../views/secretary/Claimants.vue";
import ContactsResponsible from "../views/secretary/ContactsResponsible.vue";
import EnrollmentsCanceled from "../views/secretary/EnrollmentsCanceled.vue";
import ListedContracts from "../views/secretary/ListedContracts.vue";
import ListedDefaultersEnrollments from "../views/secretary/DefaultersEnrollments";
import DataDeletionRequests from "../views/secretary/DataDeletionRequests.vue";
import Orders from "../views/secretary/Orders.vue";
import Permissions from "../views/secretary/Permissions.vue";
import SchoolYear from "../views/secretary/SchoolYear.vue";
import ValidateEnrollment from "../views/secretary/ValidateEnrollment.vue";
import ViewContracts from "../views/secretary/ViewContracts.vue";
import SchoolPrices from "../views/coordination/prices/List";
import SchoolPricesCreate from "../views/coordination/prices/Create";
import qrCode from "../views/attendance/qrCode.vue";

import NotificationsList from "../views/notifications/List.vue";
import AlertsList from '../views/alerts/List.vue'

import enrollmentAuthorizationCheck from "../utils/enrollmentAuthorizationCheck";

import utils from "@/utils";

Vue.use(VueRouter);

const redirectOnLogin = (next) => {
  const user = store.getters["auth/getAuthData"];
  if (user.role.includes("student")) next("/dashboard-aluno");
  else if (user.role.includes("teacher")) next({ name: "conteudos" });
  else if (user.role.includes("parent")) next("/dashboard");
  else if (user.role.includes("coordinator")) next("/coordenacao/turmas");
  else if (user.role.includes("concierge")) next("/portaria/");
  else if (user.role.includes("qrcode")) next("/qrcode");
  else if (user.role.includes("attendance_manager")) next("/registro/faltas-e-presencas");
  else next("/secretaria/listar-contratos");
};

const guard =
  (roles = [], check = false, allRoles = true) =>
    async (to, from, next) => {
      let rolesComplete;
      if (allRoles) {
        rolesComplete = [...roles, "admin", "coordinator"];
      } else {
        rolesComplete = [...roles];
      }

      if (!user || !user.role) await utils.delay(1000);

      const user = store.getters["auth/getAuthData"];

      const hasPermission = user.role.some((r) => rolesComplete.includes(r));
      const urlId = to.params.id;

      let authorized = true;

      if (check) {
        if (urlId) {
          authorized = await enrollmentAuthorizationCheck(urlId);
        }
      }
      if (hasPermission && authorized) {
        next();
      } else {
        redirectOnLogin(next);
      }
    };

const checkUnit = () => async (to, from, next) => {
  const rdaUnit = process.env.VUE_APP_RDA_UNIT;
  const allowedUnits = ["BELO_HORIZONTE"];
  if (allowedUnits.includes(rdaUnit)) {
    next();
  } else {
    redirectOnLogin(next);
  }
};

const routes = [
  {
    path: "/link-expirado",
    name: "link-expirado",
    component: EnrollmentExpiredToken,
    meta: { hideNavigation: true },
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: { hideNavigation: true },
  },
  {
    path: "/esqueceu-senha",
    name: "forgot-password",
    component: ForgotPassword,
    meta: { hideNavigation: true },
  },
  {
    path: "/lgpd/solicitar-exclusao",
    name: "data-deletion-request",
    component: FormDataDeletion,
    meta: { hideNavigation: true },
  },
  {
    path: "/lgpd/privacidade",
    name: "privacy",
    component: Privacy,
    meta: { hideNavigation: true },
  },
  {
    path: "/resetar-senha",
    name: "reset-password",
    component: ResetPassword,
    meta: { hideNavigation: true },
  },
  {
    path: "/criar-conta",
    name: "Matrículas - Criar Conta",
    component: CreateAccount,
  },
  {
    path: "/",
    name: "",
    redirect: { name: "indicadores" },
    component: EmptyRoute,
    children: [
      {
        path: "qrcode",
        name: "qrcode",
        beforeEnter: guard(["qrcode"], false, false),
        component: qrCode,
      },
      {
        path: "dashboard-aluno",
        name: "dashboard-aluno",
        component: DashStudents,
        beforeEnter: guard(["student"]),
      },
      {
        path: "me",
        name: "perfil",
        component: UserProfile,
      },
      {
        path: "perfil-aluno/:id",
        name: "aluno-perfil",
        component: ChildrenProfile,
      },
      {
        path: "dashboard",
        name: "dashboard",
        component: DashParents,
      },
      {
        path: "faltas-e-presencas/:student_id",
        name: "faltas-e-presencas",
        beforeEnter: guard(["teacher", "student", "parent"]),
        component: Attendances,
      },
      {
        path: "indicadores",
        name: "indicadores",
        component: Dashboard,
        beforeEnter: guard(["teacher", "secretary"]),
      },
      {
        path: "dashboard-tutoria",
        name: "",
        beforeEnter: guard(["parent", "teacher", "student"]),
        component: () => import("../views/EmptyRoute.vue"),
        children: [
          {
            path: "/dashboard-tutoria/aluno/:id",
            name: "dashboard-tutoria-aluno",
            component: DashTutoring,
          },
        ],
      },
      {
        path: "dashboard-timeline",
        name: "",
        beforeEnter: guard(["secretary", "coordinator", "teacher"]),
        component: () => import("../views/EmptyRoute.vue"),
        children: [
          {
            path: "/dashboard-timeline/aluno/:id",
            name: "dashboard-timeline-aluno",
            component: DashTimeline,
          },
        ],
      },
      {
        path: "dashboard-timeline-parent",
        name: "",
        beforeEnter: guard(["parent", "student"]),
        component: () => import("../views/EmptyRoute.vue"),
        children: [
          {
            path: "/dashboard-timeline/aluno/perfil/:id",
            name: "dashboard-timeline-aluno-parent",
            component: DashTimelineParent,
          },
        ],
      },
    ],
  },
  {
    path: "/comunidade",
    name: "comunidade",
    redirect: { name: "alunos" },
    beforeEnter: guard(["teacher", "secretary"]),
    component: () => import("../views/EmptyRoute.vue"),
    children: [
      {
        path: "alertas",
        name: "alertas",
        component: AlertsList,
      },

      {
        path: "professores",
        name: "",
        component: () => import("../views/EmptyRoute.vue"),
        beforeEnter: guard(["secretary"]),
        children: [
          {
            path: "",
            name: "professores",
            component: TeachersList,
          },
          {
            path: "novo-professor",
            name: "novo-professor",
            beforeEnter: guard(),
            component: TeachersCreateUpdate,
          },
        ],
      },
      {
        path: "professor/perfil/:id",
        name: "perfil-professor",
        component: TeachersCreateUpdate,
      },
      {
        path: "professor/perfil/:id/visualizar",
        name: "perfil-professor-visualizar",
        beforeEnter: guard(["teacher", "student", "parent", "secretary"]),
        component: TeacherDetail,
      },
      {
        path: "alunos",
        name: "",
        component: () => import("../views/EmptyRoute.vue"),
        children: [
          {
            path: "",
            name: "alunos",
            beforeEnter: guard(["teacher", "secretary"]),
            component: StudentsList,
          },
          {
            path: "emitir-carteirinha",
            name: "emitir-carteirinha",
            component: StudentCard,
          },
          {
            path: "tipo-de-cadastro",
            name: "tipo-de-cadastro",
            component: StudentTypeRegister,
          },
          {
            path: "dados-do-aluno/:id/:step",
            name: "dados-do-aluno",
            component: EnrollForm,
          },
        ],
      },
      {
        path: "aluno/perfil/:id",
        name: "perfil-aluno",
        beforeEnter: guard(["teacher"]),
        component: StudentsProfile,
      },
      {
        path: "ciclos-turmas-tutores",
        name: "ciclos-turmas-tutores",
        beforeEnter: guard(["coordinator"]),
        component: CyclesDisciplinesTutors,
      },
      {
        path: "informe-periodico",
        name: "informe-periodico",
        beforeEnter: guard(["coordinator"]),
        component: () => import("../views/community/periods/List.vue"),
      },
      {
        path: "informe-periodico/:id",
        name: "informe-periodico-detalhes",
        beforeEnter: guard(["coordinator"]),
        component: () => import("../views/community/periods/Show.vue"),
      },
      {
        path: "aluno/:id/exportar/relatorio-desenvolvimento/",
        name: "exportar-relatorio-desenvolvimento",
        beforeEnter: guard(["secretary", "coordinator"]),
        component: StudentDevelopmentReport,
      },
      {
        path: "tutoria",
        name: "",
        component: () => import("../views/EmptyRoute.vue"),
        children: [
          {
            path: "",
            name: "tutoria",
            // beforeEnter: guard(["teacher", "secretary"]),
            component: TutoringList,
          },
          {
            path: "/tutoria/aluno/",
            name: "tutoria-aluno",
            // beforeEnter: guard(["teacher", "secretary"]),
            component: TutoringDetails,
          },
        ],
      },
    ],
  },
  {
    path: "/registro",
    name: "registro",
    redirect: { name: "registro-faltas-e-presencas" },
    beforeEnter: guard(["attendance_manager",], false, false),
    component: () => import("../views/EmptyRoute.vue"),
    children: [
      {
        path: "faltas-e-presencas",
        name: "registro-faltas-presencas",
        component: () => import("../views/secretary/attendances/List.vue"),
      },
      {
        path: "faltas-e-presencas/registrar/",
        name: "registro-registrar-faltas-presencas",
        component: () => import("../views/secretary/attendances/Create.vue"),
      },
      {
        path: "faltas-e-presencas/editar/:id",
        name: "registro-editar-faltas-presencas",
        component: () => import("../views/secretary/attendances/Update.vue"),
      },
      {
        path: "faltas-e-presencas/visualizar/:id",
        name: "registro-visualizar-faltas-presencas",
        component: () => import("../views/secretary/attendances/View.vue"),
      }],
  },

  {
    path: "/secretaria",
    name: "",
    beforeEnter: guard(["secretary"]),
    component: () => import("../views/EmptyRoute.vue"),
    children: [
      {
        path: "links",
        name: "links",
        component: () => import("../views/admin/enrollmentToken/List.vue"),
      },
      {
        path: "turmas",
        name: "",
        component: () => import("../views/EmptyRoute.vue"),
        children: [
          {
            path: "",
            name: "turmas",
            component: ClassroomsList,
          },
          {
            path: "nova-turma",
            name: "nova-turma",
            component: ClassroomsCreate,
          },
          {
            path: "perfil/:id",
            name: "perfil-turma",
            component: ClassroomsUpdate,
          },
        ],
      },
      {
        path: "ciclos",
        name: "",
        component: () => import("../views/EmptyRoute.vue"),
        children: [
          {
            path: "",
            name: "ciclos",
            component: CyclesList,
          },
          {
            path: "novo-ciclo",
            name: "novo-ciclo",
            component: CyclesCreate,
          },
          {
            path: "perfil/:id",
            name: "perfil-ciclo",
            component: CyclesUpdate,
          },
        ],
      },
      {
        path: "materias",
        name: "",
        component: () => import("../views/EmptyRoute.vue"),
        children: [
          {
            path: "",
            name: "materias",
            component: SubjectsList,
          },
          {
            path: "nova-materia",
            name: "nova-materia",
            component: SubjectsCreate,
          },
          {
            path: "perfil/:id",
            name: "perfil-materia",
            component: SubjectsUpdate,
          },
        ],
      },
      {
        path: "calendario-escolar",
        name: "calendario-escolar",
        component: SchoolCalendar,
      },
      {
        path: "mensalidade-escolar",
        name: "mensalidade-escolar",
        component: SchoolPrices,
      },
      {
        path: "mensalidade-escolar-registrar",
        name: "mensalidade-escolar-registrar",
        component: SchoolPricesCreate,
      },
      {
        path: "",
        redirect: { name: "listar-contratos" },
        name: "secretaria",
      },
      {
        path: "pedidos",
        name: "pedidos",
        component: Orders,
      },
      {
        path: "matriculas-canceladas",
        name: "matriculas-canceladas",
        component: EnrollmentsCanceled,
      },
      {
        path: "ano-letivo",
        name: "ano-letivo",
        component: SchoolYear,
      },
      {
        path: "requerentes",
        name: "requerentes",
        component: Claimants,
      },
      {
        path: "autorizacoes",
        name: "autorizacoes",
        component: Permissions,
      },
      {
        path: "listar-contratos",
        name: "listar-contratos",
        component: ListedContracts,
      },
      {
        path: "listar-contratos-inadimplentes",
        name: "listar-contratos-inadimplentes",
        component: ListedDefaultersEnrollments,
      },
      {
        path: "solicitacoes-de-exclusao-de-dados",
        name: "solicitacoes-de-exclusao-de-dados",
        component: DataDeletionRequests,
      },
      {
        path: "visualizar-contrato/:id",
        name: "visualizar-contrato",
        component: ViewContracts,
      },
      {
        path: "contatos-responsaveis",
        name: "contatos-responsaveis",
        component: ContactsResponsible,
      },
      {
        path: "pedidos/:id/validar-matricula",
        name: "validar-matricula",
        component: ValidateEnrollment,
      },
      {
        path: "faltas-e-presencas",
        name: "faltas-presencas",
        component: () => import("../views/secretary/attendances/List.vue"),
      },
      {
        path: "faltas-e-presencas/registrar/",
        name: "registrar-faltas-presencas",
        component: () => import("../views/secretary/attendances/Create.vue"),
      },
      {
        path: "faltas-e-presencas/editar/:id",
        name: "editar-faltas-presencas",
        component: () => import("../views/secretary/attendances/Update.vue"),
      },
      {
        path: "faltas-e-presencas/visualizar/:id",
        name: "visualizar-faltas-presencas",
        component: () => import("../views/secretary/attendances/View.vue"),
      },
    ],
  },
  {
    path: "/administracao",
    name: "",
    beforeEnter: guard(["admin", "coordinator"], false, false),
    component: () => import("../views/EmptyRoute.vue"),
    children: [
      {
        path: "usuarios",
        beforeEnter: guard(["admin"], false, false),
        name: "usuarios",
        component: () => import("../views/admin/users/Users.vue"),
      },
      {
        path: "perfil/:id",
        name: "perfil-usuario",
        component: () => import("../views/admin/users/Detail.vue"),
      },
      {
        path: "novosusuario",
        name: "novosusuario",
        component: () => import("../views/admin/users/CreateUpdate.vue"),
      },
      {
        path: "eventos",
        name: "eventos",
        beforeEnter: checkUnit(),
        component: () => import("../views/admin/events/Events.vue"),
      },
      {
        path: "eventos/:id/editar",
        name: "updateEvent",
        beforeEnter: checkUnit(),
        component: () => import("../views/admin/events/UpdateEvent.vue"),
      },
      {
        path: "eventos/:id/visualizar",
        name: "showEvent",
        beforeEnter: checkUnit(),
        component: () => import("../views/admin/events/ShowEvent.vue"),
      },
      {
        path: "eventos/novo",
        name: "createEvent",
        beforeEnter: checkUnit(),
        component: () => import("../views/admin/events/CreateEvent.vue"),
      },
      {
        path: "banners",
        name: "banners",
        beforeEnter: checkUnit(),
        component: () => import("../views/admin/banners/Banners.vue"),
      },
      {
        path: "banners/:id/editar",
        name: "updateBanner",
        beforeEnter: checkUnit(),
        component: () => import("../views/admin/banners/UpdateBanner.vue"),
      },
      {
        path: "banners/:id/visualizar",
        name: "showBanner",
        beforeEnter: checkUnit(),
        component: () => import("../views/admin/banners/ShowBanner.vue"),
      },
      {
        path: "banners/novo",
        name: "createBanner",
        beforeEnter: checkUnit(),
        component: () => import("../views/admin/banners/CreateBanner.vue"),
      },
      {
        path: "notifications",
        name: "notifications",
        beforeEnter: checkUnit(),
        component: () =>
          import("../views/admin/notifications/Notifications.vue"),
      },
      {
        path: "notifications/:id/editar",
        name: "updateNotification",
        beforeEnter: checkUnit(),
        component: () =>
          import("../views/admin/notifications/UpdateNotification.vue"),
      },
      {
        path: "notifications/:id/visualizar",
        name: "showNotification",
        beforeEnter: checkUnit(),
        component: () =>
          import("../views/admin/notifications/ShowNotification.vue"),
      },
      {
        path: "notifications/novo",
        name: "createNotification",
        beforeEnter: checkUnit(),
        component: () =>
          import("../views/admin/notifications/CreateNotification.vue"),
      },
      {
        path: "blog",
        beforeEnter: guard(["admin"], false, false),
        name: "blog",
        component: () => import("../views/admin/blog/Blog.vue"),
      },
      {
        path: "videos",
        beforeEnter: guard(["admin"], false, false),
        name: "videos",
        component: () => import("../views/admin/videos/Videos.vue"),
      },
      {
        path: "contractors-attendances-notifications",
        name: "contractors-attendances-notifications",
        beforeEnter: checkUnit(),
        component: () => import("../views/admin/ContractorsAttendancesNofications/List.vue"),
      },
      {
        path: "periods",
        name: "periods",
        component: () => import("../views/admin/periods/List.vue"),
      },
    ],
  },
  {
    path: "/coordenacao",
    name: "coordenacao",
    redirect: { name: "turmas" },
    component: () => import("../views/EmptyRoute.vue"),
    beforeEnter: guard(["secretary", "admin", "coordinator"]),
    children: [
      {
        path: "permissoes",
        name: "permissoes",
        component: AuthorizationsTeachers,
      },

      {
        path: "dados-matricula/:id",
        name: "dadosMatricula",
        component: EnrollmentData,
      },

      {
        path: "notificacoes",
        name: "notificacoes",
        component: NotificationsList,
      },

      {
        path: "historico",
        name: "",
        component: () => import("../views/EmptyRoute.vue"),
        children: [
          {
            path: "",
            name: "historico",
            component: SystemAccessHistory,
          },
          {
            path: "acessos-falhados",
            name: "acessos-falhados",
            component: SystemFailedAccessesHistory,
          },
        ],
      },
      {
        path: "conteudos",
        name: "",
        component: () => import("../views/EmptyRoute.vue"),
        children: [
          {
            path: "",
            name: "",
            component: () => import("../views/EmptyRoute.vue"),
            children: [
              {
                path: "",
                name: "listar-conteudos",
                component: CompetenceList,
              },
              {
                path: ":id/registrar",
                name: "novo-conteudo",
                component: CompetenceCreate,
              },
              {
                path: "editar/:id",
                name: "editar-conteudo",
                component: TopicsUpdate,
              },
            ],
          },
        ],
      },
      {
        path: "competencia/editar/:id",
        name: "editar-competencia",
        component: CompetencesUpdate,
      },
      {
        path: "resetar-senhas/",
        name: "reset-password",
        beforeEnter: guard(["secretary"]),
        component: PasswordReset,
      },
    ],
  },
  {
    path: "/portaria",
    name: "portaria",
    beforeEnter: guard([
      "secretary",
      "admin",
      "coordinator",
      "teacher",
      "concierge",
    ]),
    component: () => import("../views/concierge/Concierge.vue"),
  },
  {
    path: "/matriculas",
    name: "",
    component: () => import("../views/EmptyRoute.vue"),
    beforeEnter: guard(["parent"], true),
    children: [
      {
        path: "/",
        name: "matriculas",
        component: Enrollment,
      },
      {
        path: "/criar-matricula/:id/:step",
        name: "criar-matricula",
        component: EnrollForm,
      },
      {
        path: "/continuar-matricula/:edit/:id/:step",
        name: "continuar-matricula",
        component: EnrollForm,
      },
      {
        path: "/rematricula/:id/:step",
        name: "rematricula",
        component: EnrollForm,
      },
    ],
  },

  {
    path: "/aprendizagem",
    name: "",
    component: () => import("../views/EmptyRoute.vue"),
    redirect: { name: "conteudos" },
    children: [
      {
        path: "",
        name: "aprendizagem",
        redirect: { name: "conteudos" },
      },
      {
        path: "registrar-aprendizagem/:id",
        name: "registrar-aprendizagem",
        beforeEnter: guard(["student", "parent"]),
        component: LearningRecordStudent,
      },

      {
        path: "informe-periodico/:id",
        name: "informe-periodico-responsavel",
        beforeEnter: guard(["student", "parent"]),
        component: () => import("../views/community/periods/Show.vue"),
      },
      
      {
        path: ":id/registrar-aprendizagens/:subject_id",
        name: "registrar-aprendizagens",
        beforeEnter: guard(["teacher"]),
        component: LearningRecord,
      },
      {
        path: "registro-roteiros/:id",
        name: "registro-roteiros",
        beforeEnter: guard(["teacher"]),
        component: SyllabusesStudentsList,
      },
      {
        path: "conteudos",
        name: "conteudos",
        beforeEnter: guard(["teacher"]),
        component: TopicsStudentsList,
      },
      {
        path: "painel-de-avaliacoes",
        name: "painel-de-avaliacoes",
        beforeEnter: guard(["teacher", "secretary"]),
        component: Evaluation,
      },
      {
        path: "roteiros",
        name: "roteiros",
        beforeEnter: guard(["teacher"]),
        component: SyllabusesList,
      },
      // {
      //   path: "roteiros-aluno/:id",
      //   name: "roteiros-aluno",
      //   beforeEnter: guard(["student", "parent"]),
      //   component: StudentSyllabusesList,
      // },
      {
        path: ":syllabus_id/registrar-roteiro",
        name: "registrar-roteiro",
        beforeEnter: guard(["teacher"]),
        component: SyllabusCreateEdit,
      },
      {
        path: "editar-roteiro/:id",
        name: "editar-roteiro",
        beforeEnter: guard(["teacher"]),
        component: SyllabusCreateEdit,
      },
      {
        path: "roteiro/:id",
        name: "roteiro",
        beforeEnter: guard(["teacher"]),
        component: Script,
      },
      {
        path: "roteiro/:id/visualizar",
        name: "roteiro-visualizar",
        beforeEnter: guard(["student", "parent"]),
        component: StudentScript,
      },
      {
        path: "roteiro/:id/aluno/:student_id",
        name: "roteiro-student-visualizar",
        beforeEnter: guard(["teacher", "parent"]),
        component: StudentScript,
      },
    ],
  },

  /* PAGINAS PARA FASE 2 */
  {
    path: "/comentarios",
    name: "comentarios",
    component: Comments,
  },
  {
    path: "/reunioes",
    name: "reunioes",
    component: Meetings,
  },
  {
    path: "/aluno/perfil/ata-de-reuniao",
    name: "ata-de-reuniao",
    component: MeetingResume,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  const publicPages = [
    "/login",
    "/criar-conta",
    "/esqueceu-senha",
    "/resetar-senha",
    "/lgpd/solicitar-exclusao",
    "/lgpd/privacidade",
    "/link-expirado",
  ];
  const authRequired = !publicPages.includes(to.path);

  const getToken = store.getters["auth/getAuthData"].token;
  if (!getToken) {
    const access_token = localStorage.getItem("access_token");
    const refresh_token = localStorage.getItem("refresh_token");
    if (access_token) {
      const data = {
        access_token: access_token,
        refresh_token: refresh_token,
      };
      store.commit("auth/saveTokenData", data);
    }
  }

  const auth = store.getters["auth/isTokenActive"];
  if (!auth) {
    const authData = store.getters["auth/getAuthData"];
    if (authData.token) {
      store.dispatch("auth/logout");
      store.commit("shared/RESET_STATE");
      document.body.classList.add("disabled");
      window.location.reload();

      const user = {
        access_token: authData.token,
        refresh_token: authData.refresh_token,
      };
      const refreshResponse = await axios.get(
        `${process.env.VUE_APP_URL}refresh`,
        user
      );
      store.commit("auth/saveTokenData", refreshResponse.data);
      return auth;
    }
  }

  if (to.fullPath == "/") {
    return next();
  } else if (auth && !authRequired) {
    return next();
  } else if (!auth && authRequired) {
    return next({ path: "/login" });
  }

  return next();
});

router.afterEach(() => {
  const getTokenDateExpiration = store.getters["auth/getAuthData"].tokenExp;
  const convertTokenExpirationToDate = new Date(getTokenDateExpiration * 1000);
  const getDateNow = new Date(Date.now());

  if (getDateNow >= convertTokenExpirationToDate) {
    store.dispatch("auth/logout");
    store.commit("shared/RESET_STATE");
    document.body.classList.add("disabled");
    window.location.reload();
    return false;
  }
});

export default router;
