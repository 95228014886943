<template>
  <b-modal
    :id="`edit-photo-${modalId}`"
    class="edit-photo"
    hide-header
    hide-footer
    centered
    @change="modalOpen"
  >
    <b-row>
      <b-col class="text-center">
        <h4 class="purple mb-5">Inserir foto de perfil</h4>

        <p>
          1 - Selecione uma foto do seu dispositivo <br />
          (formatos suportados: JPEG e PNG):
        </p>

        <label>
          <b-button tag="span" class="btn normal blue mb-5"
            >Selecionar</b-button
          >

          <input
            @change="uploadPhoto"
            type="file"
            accept=".png,.jpg,.jpeg"
            name="file"
            id="file"
            ref="file"
            hidden
          />
        </label>

        <p>2 - Edite a foto.</p>

        <div height="500" class="wrapper">
          <img
            v-show="file || photo"
            height="500"
            width="500"
            :src="src"
            alt=""
            ref="imgpreview"
          />

          <div v-show="!(file || photo)" class="img-placeholder" />
        </div>

        <p class="btn-rotate mb-5" v-if="cropper">
          <span @click="rotateRight">
            <RotateIcon />
          </span>

          <span @click="rotateLeft">
            <RotateIcon />
          </span>
        </p>

        <p>3 - Terminou de editar a foto?</p>

        <b-button class="btn normal blue" @click="hideModal">Salvar</b-button>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import RotateIcon from "@/components/svg/RotateIcon.vue";
import Cropper from "cropperjs";

export default {
  name: "EditPhotoProfileModal",

  components: {
    RotateIcon,
  },

  props: {
    callback: {
      type: Function,
      required: true,
    },
    photo: {
      type: undefined,
      default: null,
    },
    modalId: {
      type: String,
      default: "1",
    },
  },

  data() {
    return {
      file: null,
      cropper: null,
      src: "",
    };
  },

  computed: {
    hasImg() {
      return this.$refs && this.$refs.imgpreview;
    },
  },

  methods: {
    uploadPhoto() {
      const [file] = this.$refs.file.files;

      this.file = file;
      this.readURL(file);
    },

    readURL(file) {
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.$refs.imgpreview.src = e.target.result;

          this.cropperCreate();
        };

        reader.readAsDataURL(file);
      }
    },

    cropperDestroy() {
      if (this.cropper) this.cropper.destroy();
    },

    cropperCreate() {
      this.cropperDestroy();

      const cropper = new Cropper(this.$refs.imgpreview, {
        aspectRatio: 1 / 1,
        viewMode: 2,
        dragMode: "move",
        zoomable: false,
        ready() {
          cropper.crop();
        },
      });

      this.cropper = cropper;
    },

    rotateLeft() {
      this.cropper.rotate(-90);
    },

    rotateRight() {
      this.cropper.rotate(90);
    },

    modalOpen(state) {
      if (state) this.cropperCreate();
      this.setSrc();
    },

    hideModal() {
      if (this.file) this.cropper.getCroppedCanvas().toBlob(this.callback);

      this.$root.$emit("bv::hide::modal", "edit-photo-" + this.modalId);
    },

    setSrc() {
      const photo = this.photo;

      if (photo && typeof photo === "string") {
        this.src = photo;
      }
      if (photo && typeof photo === "object") {
        const urlCreator = window.URL || window.webkitURL;
        const imageUrl = urlCreator.createObjectURL(photo);

        this.src = imageUrl;
      }
    },
  },

  watch: {
    photo() {
      this.setSrc();
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/css/variables.scss";
@import "@/assets/css/vendors/cropper.scss";

.btn-rotate {
  margin-bottom: 26px;

  svg {
    margin: 20px 10px 0;
  }

  span + span {
    display: inline-block;
    transform: rotateY(180deg);
  }
}

[id^="edit-photo"] {
  .modal-dialog {
    max-width: 820px;

    .modal-content {
      background-color: $grey2;
      border: 0;
      border-radius: 0;

      .modal-body {
        padding: 60px;
      }
    }
  }

  .ciclos-materias {
    list-style: none;
    padding: 0;
    margin: 0;

    .ciclo-materia {
      align-items: center;
      display: flex;
      justify-content: space-between;
      line-height: 60px;
      border-bottom: 1px solid $grey5;

      &:last-child {
        border-bottom: 0;
      }

      .title {
        margin: 0;
      }

      .actions {
        a {
          margin: 0 15px;
        }
      }
    }
  }
}
.img-placeholder {
  width: 500px;
  height: 500px;
  border: 2px gray dashed;
  margin: auto;
}
</style>
