export default {
  student: {},
  contractorA: {},  
  contractorB: {},
  filiationA: {},
  filiationB: {},

  reEnrollContractorA: {},
  reEnrollContractorB: {},
  reEnrollFiliationA: {},
  reEnrollFiliationB: {},
  
  cycle: [],

  enrollments: [],
  enrollment: {},

  complement: {},
  authorization: {},
  finance: {},
  files: {}
}