<template>
  <img
    :style="{
      height: height + 'px',
    }"
    src="@/assets/images/marca-escola-da-serra.png"
    alt=""
  />
</template>

<script>
export default {
  props: {
    height: {
      type: Number,
      default: 47,
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
