<template>
  <div class="home">
    <template>
      <b-container class="container-rda sidebar">
        <b-row class="height">
          <sidebar-coordination />
          <b-col class="content" v-if="selected">
            <b-row>
              <b-col>
                <b-breadcrumb :items="breadcrumb"></b-breadcrumb>
              </b-col>
            </b-row>
            <b-row class="mb-4">
              <b-col>
                <h2 class="purple bold">Atualizar Turma</h2>
              </b-col>
              <b-col class="text-right"> </b-col>
            </b-row>
            <b-row class="">
              <b-col md="1" class="status">
                <b-form-group label="Ativa">
                  <b-form-checkbox
                    v-model="selected.classroom.is_editable"
                    switch
                  ></b-form-checkbox>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="">
              <b-col md="3">
                <StandardInput
                  v-model="selected.classroom.designation"
                  type="text"
                  name="designation"
                  label="Nome da Turma"
                  placeholder="EM-B-2021"
                >
                </StandardInput>
              </b-col>
              <b-col md="3">
                <SelectCycles
                  :value="selected.classroom.cycle_id"
                  :callback="callbackCycles"
                >
                </SelectCycles>
              </b-col>
              <b-col>
                <SelectYear
                  :value="selected.classroom.year_id"
                  :callback="callbackYears"
                  label="Ano de Escolaridade"
                >
                </SelectYear>
              </b-col>
              <b-col>
                <SelectCalendar
                  :value="selected.classroom.calendar_id"
                  :callback="callbackCalendars"
                  label="Ano Letivo"
                >
                </SelectCalendar>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="6">
                <StandardInput
                  v-model="selected.classroom.start_at"
                  type="time"
                  name="designation"
                  label="Início do horário das aulas"
                >
                </StandardInput>
              </b-col>
              <b-col md="6">
                <StandardInput
                  v-model="selected.classroom.end_at"
                  type="time"
                  name="designation"
                  label="Fim do horário das aulas"
                >
                </StandardInput>
              </b-col>
            </b-row>
            <br />
            <b-row style="padding-bottom: 20px">
              <b-col>
                <h3 class="purple bold add-contraturno">
                  Contraturnos
                  <b-button @click="addAfterHours" class="blue btn-contraturno"
                    >Adicionar</b-button
                  >
                </h3>
              </b-col>
            </b-row>
            <b-row
              v-for="(after_hours, index) in selected.classroom
                .ClassroomAfterHours"
              :key="index"
            >
              <b-col md="2">
                <b-form-group label="Dia da semana">
                  <multiselect
                    v-model="after_hours.day"
                    :options="daysOfweek"
                    :multiple="false"
                    placeholder="Selecione"
                    selectLabel=""
                    label="name"
                    track-by="id"
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <StandardInput
                  v-model="after_hours.start_at"
                  type="time"
                  name="designation"
                  label="Início do horário das aulas"
                >
                </StandardInput>
              </b-col>
              <b-col md="4">
                <StandardInput
                  v-model="after_hours.end_at"
                  type="time"
                  name="designation"
                  label="Fim do horário das aulas"
                >
                </StandardInput>
              </b-col>
              <b-col md="2" class="remove-after-hours">
                <b-button
                  class="large blue outline"
                  @click="
                    selected.classroom.ClassroomAfterHours.splice(index, 1)
                  "
                  >Remover</b-button
                >
              </b-col>
            </b-row>
            <b-row>
              <div class="w-100 mt-5"></div>
              <b-col md="3">
                <b-button class="large blue outline" :to="{ name: 'turmas' }"
                  >Voltar</b-button
                >
              </b-col>
              <b-col md="3">
                <b-button @click="update" class="large blue">Salvar</b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </template>
  </div>
</template>

<script>
import DataService from "@/services/classrooms.service";

import SelectCycles from "@/components/inputs/SelectCycles.vue";
import SelectCalendar from "@/components/inputs/SelectCalendar.vue";
import StandardInput from "@/components/inputs/StandardInput.vue";
import SelectYear from "@/components/inputs/SelectYear.vue";
import SidebarCoordination from "@/components/SidebarCoordination.vue";

export default {
  name: "perfil-turma",
  components: {
    SelectCycles,
    SelectCalendar,
    StandardInput,
    SelectYear,
    SidebarCoordination,
  },
  data() {
    return {
      selected: null,
      daysOfweek: [
        {
          id: 1,
          name: "Segunda-feira",
        },
        {
          id: 2,
          name: "Terça-feira",
        },
        {
          id: 3,
          name: "Quarta-feira",
        },
        {
          id: 4,
          name: "Quinta-feira",
        },
        {
          id: 5,
          name: "Sexta-feira",
        },
        {
          id: 6,
          name: "Sábado",
        },
        {
          id: 7,
          name: "Domingo",
        },
      ],

      breadcrumb: [
        {
          text: "Coordenação",
          href: "/coordenacao",
        },
        {
          text: "Alterações internas",
          active: true,
        },
        {
          text: "Turmas",
          href: "/turmas",
          active: true,
        },
      ],
    };
  },
  methods: {
    addAfterHours() {
      this.selected.classroom.ClassroomAfterHours.push({
        day: null,
        start_at: "",
        end_at: "",
      });
    },

    update() {
      const payload = this.selected.classroom;
      if (payload.ClassroomAfterHours.length > 0) {
        for (let i = 0; i < payload.ClassroomAfterHours.length; i++) {
          payload.ClassroomAfterHours[i].day =
            payload.ClassroomAfterHours[i].day.id;
        }
      }

      DataService.update(this.selected.classroom.id, this.selected.classroom)
        .then((response) => {
          this.$router.push({ name: "turmas" });
          const Toast = this.$swal.mixin({
            toast: true,
            position: "bottom-end",
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer);
              toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "success",
            title: "Disciplina atualizada com sucesso!",
          });

          return response.data;
        })
        .catch((error) => {
          this.$swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Não foi possivel atualizar!",
            footer: "Por favor, verifique se os campos estão preenchidos.",
          });

          return error.response;
        });
    },
    getSelected(id) {
      DataService.get(id)
        .then((response) => {
          this.selected = response.data;
          if (this.selected.classroom.ClassroomAfterHours.length > 0) {
            for (
              let i = 0;
              i < this.selected.classroom.ClassroomAfterHours.length;
              i++
            ) {
              this.selected.classroom.ClassroomAfterHours[i].day =
                this.daysOfweek.find(
                  (d) =>
                    d.id == this.selected.classroom.ClassroomAfterHours[i].day
                );
            }
          }
        })
        .catch((error) => {
          return error.response;
        });
    },
    callbackCycles(args) {
      this.selected.classroom.cycle_id = args.id;
    },
    callbackCalendars(args) {
      this.selected.classroom.calendar_id = args.id;
    },
    callbackYears(args) {
      this.selected.classroom.year_id = args.id;
    },
  },
  created() {
    this.getSelected(this.$route.params.id);
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/variables.scss";

.home {
  height: 100%;
}

.container-rda {
  padding-bottom: 0;
}

.tags {
  background-color: $blue;
  border-radius: 5px;
  color: $white;
  font-weight: $bold;
  line-height: 28px;
  margin: 3px 12px 3px 0;
  max-width: 58px;
  padding: 0 3px;
  text-align: center;
  vertical-align: middle;
  width: 100%;
}

.add-contraturno {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}

.btn-contraturno {
  height: 28;
}

.remove-after-hours {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 20px;
}
</style>
