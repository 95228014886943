export function createResponsibleBody(fields) {
  const phones = [
    { number: fields.phone1, is_mobile: false },
    { number: fields.phone2, is_mobile: false },
  ].filter((c) => c.number);

  return {
    relationship: "",
    profession: "",
    activities: "",
    company: "",
    is_billing_contact: false,
    is_school_contact: false,
    has_child_custody: false,
    is_legal_representative: false,
    User: {
      name: fields.name,
      email: fields.email,
      password: fields.password,
      cpf: fields.cpf,
      rg: "000",
      photo: "",
      gender: "",
      state_origin: "",
      country_origin: "",
      Address: {
        cep: "00000000",
        number: 0,
        complement: "",
        street: "",
        neighborhood: "",
        city: "",
        state: "",
        country: "",
      },
      Phones: phones,
    },
  };
}

export function createParentBody(fields) {
  const phones = [
    { number: fields.phone1, is_mobile: false },
    { number: fields.phone2, is_mobile: false },
  ].filter((c) => c.number);

  return {
    is_divorced: false,
    is_deceased: false,
    divorce_annotation: "",
    Responsible: {
      relationship: "",
      profession: "",
      activities: "",
      company: "",
      is_billing_contact: false,
      is_school_contact: false,
      has_child_custody: false,
      is_legal_representative: false,
      User: {
        name: fields.name,
        email: fields.email,
        password: fields.password,
        cpf: fields.cpf,
        rg: "000",
        photo: "",
        gender: "",
        state_origin: "",
        country_origin: "",
        Address: {
          cep: "00000000",
          number: 0,
          complement: "",
          street: "",
          neighborhood: "",
          city: "",
          state: "",
          country: "",
        },
        Phones: phones,
      },
    },
  };
}
