<template>
  <b-container class="container-rda" v-if="selected">
    <b-row>
      <b-col>
        <b-breadcrumb :items="breadcrumb"></b-breadcrumb>
        <h2 class="purple mb-5">Relatório de desenvolvimento do aluno</h2>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <div class="profile-row">
          <b-col>
            <img
              v-if="selected.student.User.photo == null || ''"
              src="@/assets/images/default-photo.png"
              height="64"
              width="64"
              alt
            />
            <img
              v-else
              width="64"
              height="64"
              :src="`${selected.student.User.photo}`"
              alt
            />
            <div class="content-profile">
              <h5>{{ selected.student.User.name }}</h5>
              <p>Ciclo: {{ cycle.name }}</p>
            </div>
          </b-col>
        </div>
      </b-col>

      <b-col md="12" class="mt-5">
        <b-form-group class="accordion-materias">
          <label for="Turmas" class="grey1">
            <strong>Turma:</strong>
          </label>
          <div class="accordion" role="tablist">
            <b-card no-body>
              <b-card-header header-tag="header" role="tab">
                <p block v-b-toggle="'accordion-' + classroom.id">
                  {{ classroom.designation }}
                </p>
              </b-card-header>

              <b-collapse
                :id="'accordion-' + classroom.id"
                visible
                accordion="my-accordion"
                role="tabpanel"
              >
                <b-card-body>
                  <b-form-checkbox-group
                    :id="'checkbox-group-' + classroom.id"
                    v-model="subjectsSelected"
                    name="flavour-2"
                  >
                    <b-form-checkbox
                      v-for="subject in subjects"
                      :key="subject.id"
                      :value="subject"
                      >{{ subject.designation }}</b-form-checkbox
                    >
                  </b-form-checkbox-group>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
        </b-form-group>
      </b-col>

      <b-col md="12">
        <b-form-group class="group-radio" v-slot="{ ariaDescribedby }">
          <label for="Incluir conteúdos marcados:" class="mt-4 mb-4 grey1">
            <strong>Incluir conteúdos marcados:</strong>
          </label>

          <div class="container-fluid">
            <div class="row">
              <b-form-radio
                v-model="selectedStatus"
                :aria-describedby="ariaDescribedby"
                name="matricula"
                value="All"
                class="col-md-12 mb-4"
                md="12"
                >Todos os valores</b-form-radio
              >
              <b-form-radio
                v-model="selectedStatus"
                :aria-describedby="ariaDescribedby"
                name="matricula"
                value="A"
                class="col-md-2 mb-4"
              >
                Apenas com
                <strong>A</strong>
              </b-form-radio>
              <b-form-radio
                v-model="selectedStatus"
                :aria-describedby="ariaDescribedby"
                name="matricula"
                value="EE"
                class="col-md-2 mb-4"
              >
                Apenas com
                <strong>EE</strong>
              </b-form-radio>
              <b-form-radio
                v-model="selectedStatus"
                :aria-describedby="ariaDescribedby"
                name="matricula"
                value="AN"
                class="col-md-2 mb-4"
              >
                Apenas com
                <strong>AN</strong>
              </b-form-radio>

              <div class="w-100"></div>

              <b-form-radio
                v-model="selectedStatus"
                :aria-describedby="ariaDescribedby"
                name="matricula"
                value="*"
                class="col-md-2 mb-4"
              >
                Apenas com
                <strong>*</strong>
              </b-form-radio>

              <b-form-radio
                v-model="selectedStatus"
                :aria-describedby="ariaDescribedby"
                name="matricula"
                value="#"
                class="col-md-2 mb-4"
              >
                Apenas com
                <strong>#</strong>
              </b-form-radio>

              <b-form-radio
                v-model="selectedStatus"
                :aria-describedby="ariaDescribedby"
                name="matricula"
                value="blank"
                class="col-md-2 mb-4"
              >
                Conteúdos
                <strong>em branco</strong>
              </b-form-radio>
            </div>
          </div>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row align-h="end">
      <b-col md="3" class="mt-5">
        <b-button
          :to="{
            name: 'alunos',
          }"
          class="large blue outline"
          >Voltar</b-button
        >
      </b-col>

      <b-col md="3" class="mt-5">
        <b-button
          @click="exportReport"
          class="large blue"
          :class="{ disabled: subjectsSelected.length === 0 }"
          >{{ loadText }}</b-button
        >
      </b-col>
    </b-row>
  </b-container>
  <b-container class="mt-5 container-rda" v-else-if="this.$route.params.id">
    <p>Carregando</p>
  </b-container>
  <b-container class="mt-5 container-rda" v-else>
    <p>Nenhum aluno selecionado</p>
  </b-container>
</template>

<script>
import DataService from "@/services/students.service";

export default {
  name: "StudentDevelopmentReport",
  components: {},
  data() {
    return {
      breadcrumb: [
        {
          text: "Comunidade",
          href: "/comunidade",
        },
        {
          text: "Alunos",
          href: "/alunos",
        },
        {
          text: "Exportar",
          active: true,
        },
      ],
      selected: null,
      student: {
        User: {},
      },
      cycle: "",
      classroom: "",
      subjects: null,
      loadText: "Exportar",

      subjectsSelected: [],
      selectedStatus: "All",
    };
  },
  methods: {
    getSelected(id) {
      DataService.getStudent(id)
        .then((response) => {
          this.selected = response.data;
        })
        .catch((error) => {
          return error;
        });

      DataService.getStudentClassroomCycle(id)
        .then((response) => {
          this.cycle = response.data.classroom.Cycle;
        })
        .catch((error) => {
          return error;
        });

      DataService.getStudentClassroom(id)
        .then((response) => {
          this.classroom = response.data.classroom;
        })
        .catch((error) => {
          return error;
        });

      DataService.getStudentSubjects(id)
        .then((response) => {
          this.subjects = response.data.subjects;
        })
        .catch((error) => {
          return error;
        });
    },

    exportReport() {
      var data = {
        subject_ids: this.subjectsSelected.map((s) => s.id),
        status: this.selectedStatus,
      };

      this.loadText = "Por favor, aguarde...";

      DataService.exportStudentReport(this.$route.params.id, data)
        .then((response) => {
          this.subjectsSelected = [];
          this.loadText = "Exportar";

          var date = new Date();

          var newBlob = new Blob([response.data], { type: "application/pdf" });

          // IE doesn't allow using a blob object directly as link href
          // instead it is necessary to use msSaveOrOpenBlob
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(newBlob);
            return;
          }

          // For other browsers:
          // Create a link pointing to the ObjectURL containing the blob.
          data = window.URL.createObjectURL(newBlob);
          var link = document.createElement("a");
          link.href = data;
          link.download = `Relatorio_${date.getDate()}-${date.getMonth()}-${date.getFullYear()}_${date.getTime()}`;
          link.click();

          setTimeout(function () {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(data);
          }, 100);

          const Toast = this.$swal.mixin({
            toast: true,
            position: "bottom-end",
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer);
              toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "success",
            title: `Relatório exportado com sucesso!`,
          });

          return response;
        })
        .catch((error) => {
          this.loadText = "Exportar";
          this.subjectsSelected = [];

          this.$swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Não foi possivel exportar o relatório!",
            footer: "Por favor, tente novamente mais tarde.",
          });

          return error;
        });
    },
  },

  created() {
    this.getSelected(this.$route.params.id);
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/variables.scss";
</style>
