import httpRda from "../../http-rda";

const listCycles = async ({ commit }) => {
  let res = await httpRda.get("/cycles");
  commit("CYCLES_UPDATE", res.data);
  return res;
};

const listSubjects = async ({ commit }, subject_id) => {
  let res = await httpRda.get("/subjects", { subject_id });
  commit("SUBJECTS_UPDATE", res.data);
  return res;
};

const getCompetencesBySubject = async ({ commit }, subject_id) => {
  let res = await httpRda.get("/competences", { subject_id });
  commit("LIST_COMPETENCES", res.data);
  return res;
};

const getSubject = async ({ commit }, subject_id) => {
  let res = await httpRda.get(`/subjects/${subject_id}`);
  commit("GET_SUBJECT", res.data);
  return res;
};

const getCycle = async ({ commit }) => {
  let res = await httpRda.get("/cycles", { commit });
  commit("GET_CYCLE", res.data);
  return res;
};

const getCompetencesWithTopics = async ({ commit }, subject_id) => {
  let res = await httpRda.get(
    `competences?include=topics&subject=${subject_id}`
  );
  commit("LIST_COMPETENCES_WITH_TOPICS", res.data);
  return res;
};

const getTopicsByCompetence = async ({ commit }, competence_id) => {
  let res = await httpRda.get("/topics", { competence_id });
  commit("LIST_TOPICS_BY_COMPETENCE", res.data);
  return res;
};

const listTopics = async ({ commit }) => {
  let res = await httpRda.get("/topics");
  commit("LIST_TOPICS", res.data);
  return res;
};

const searchTopics = async ({ commit }, { term, subject_id }) => {
  let res = await httpRda.get(`/topics?search=${term}&include=competences`);
  const resultFiltered = res.data.topics.filter(
    (topic) => topic.Competence.subject_id == subject_id
  );

  const competences = resultFiltered.reduce((init, actual) => {
    if (init.some((i) => i.id === actual.competence_id)) {
      delete actual.Competence;
      init.find((i) => i.id === actual.competence_id).Topics.push(actual);
    } else {
      const { Competence, ...topic } = actual;
      init.push({ ...Competence, Topics: [topic] });
    }
    return init;
  }, []);

  commit("LIST_COMPETENCES_WITH_TOPICS", { competences });

  return res;
};

const getCountries = async ({ commit, state }) => {
  if (state.countries.length) return;

  let res = await httpRda.get("/countries");
  commit("SET_COUNTRIES", res.data);
  return res;
};

const getStates = async ({ commit, state }) => {
  if (state.states.length) return;

  let res = await httpRda.get("/states");
  commit("SET_STATES", res.data);
  return res;
};

const getCities = async ({ commit }) => {
  let res = await httpRda.get("/cities");
  commit("SET_CITIES", res.data);
  return res;
};

const getUser = async ({ commit }, user_id) => {
  let res = await httpRda.get(`users/${user_id}`);
  commit("GET_USER", res.data);
  return res;
};

const getStudentAttendances = async ({ commit }, user_id) => {
  let res = await httpRda.get(`students/${user_id}/attendances`);
  commit("GET_STUDENT_ATTENDANCES", res.data);
  return res;
};

const getStudentCycle = async ({ commit }, user_id) => {
  let res = await httpRda.get(`students/${user_id}?include=cycles,topics`);
  commit("GET_STUDENT_CYCLE", res.data);
  return res;
};

const getStudentSubject = async ({ commit }, user_id) => {
  let res = await httpRda.get(`students/${user_id}/subjects`);
  commit("GET_STUDENT_SUBJECTS", res.data);
  return res;
};

const sabeStudentPhoto = async (_, { roles, file, id }) => {
  return httpRda.patch(`${roles}/${id}`, file, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

const getCalendars = async ({ commit }) => {
  let res = await httpRda.get("/calendars");
  commit("SET_CALENDARS", res.data);
  return res;
};

const resetPassword = async (_, ...params) => {
  return httpRda.post("/forgot_password/", ...params);
};

const newPassword = async (_, ...params) => {
  return httpRda.post("/new_password/", ...params);
};

export default {
  listCycles,
  listSubjects,
  getSubject,
  getCycle,
  getCompetencesBySubject,
  getCompetencesWithTopics,
  getTopicsByCompetence,
  listTopics,
  searchTopics,
  getCountries,
  getStates,
  getCities,
  getUser,
  getStudentAttendances,
  getStudentCycle,
  getStudentSubject,
  sabeStudentPhoto,
  getCalendars,
  resetPassword,
  newPassword,
};
