import http from "../http-rda";

class DataService {
  getAll(params) {
    return http.get("/tutors?include=subjects,cycles", { params });
  }

  getStudents(params) {
    return http.get("/tutors?include=students&scope=lightScope", { params });
  }

  getTeachersTutors(params) {
    return http.get("/tutoring", { params });
  }

  get(id) {
    return http.get(`/tutors/${id}`);
  }

  create(data) {
    return http.post("/tutors", data);
  }

  update(id, data) {
    return http.patch(`/tutors/${id}`, data);
  }

  addRole(id, data) {
    return http.post(`/tutors/${id}/roles`, data);
  }

  removeRole(id, data) {
    return http.patch(`/tutors/${id}/roles`, data);
  }

  delete(id) {
    return http.delete(`/tutors/${id}`);
  }

  findByTitle(name) {
    return http.get(`/tutors?search=${name}`);
  }

}

export default new DataService();
