import http from "../http-rda";

class DataService {
  getAll(params) {
    return http.get("/students", { params });
  }

  get(id) {
    return http.get(`/students/${id}`);
  }

  getStudent(id) {
    return http.get(`/students/${id}`);
  }

  getStudentAttendances(id) {
    return http.get(`/students/${id}/attendances`);
  }

  getStudentWithAttendances(id, attendance_id) {
    return http.get(`/students/${id}/attendances?id=${attendance_id}`);
  }

  getStudentClassroomCycle(id) {
    return http.get(`/students/${id}/classrooms?include=cycles`);
  }

  getStudentClassroom(id) {
    return http.get(`/students/${id}/classrooms`);
  }

  getStudentTutor(id) {
    return http.get(`/students/${id}/tutors`);
  }

  getStudentSyllabus(id) {
    return http.get(`/students/${id}/syllabuses`);
  }

  getStudentSubjects(id) {
    return http.get(`/students/${id}/subjects`);
  }

  getStudentCompetences(id) {
    return http.get(`/students/${id}/competences`);
  }

  getStudentTopics(id) {
    return http.get(`/students/${id}/topics`);
  }

  getStudentsCards(data) {
    return http.post(`/students/cards`, data, { responseType: "blob" });
  }

  exportStudentReport(id, data) {
    return http.post(`/students/${id}/report/download`, data, {
      responseType: "blob",
    });
  }

  getStudentParents(id) {
    return http.get(`/students/${id}/parents`);
  }

  createNewPassword(id, data) {
    return http.post(`/students/${id}/password`, data);
  }

  create(data) {
    return http.post("/students", data);
  }

  createStudentAttendance(data) {
    return http.post(`/attendances`, data);
  }

  update(id, data) {
    return http.patch(`/students/${id}`, data);
  }

  updateStudentAttendance(id, data) {
    return http.patch(`/attendances/${id}`, data);
  }

  deleteStudentFromClassroom(id, data) {
    return http.patch(`/students/${id}`, data);
  }

  delete(id) {
    return http.delete(`/students/${id}`);
  }

  findByTitle(name) {
    return http.get(`/students?search=${name}`);
  }
}

export default new DataService();
