import http from "../http-rda";

class DataService {
  getAll(params) {
    return http.get("/teachers?include=subjects,cycles,roles", { params });
  }

  getStudentTeachers({student_id, ...params}) {
    return http.get(`students/${student_id}/subjects/?include=teachers`, { params })
  }

  get(id) {
    return http.get(`/teachers/${id}`);
  }

  createNewPassword(id, data) {
    return http.post(`/teachers/${id}/password`, data);
  }

  create(data) {
    return http.post("/teachers", data);
  }

  update(id, data) {
    return http.patch(`/teachers/${id}`, data);
  }

  addRole(id, data) {
    return http.post(`/teachers/${id}/roles`, data);
  }

  removeRole(id, data) {
    return http.patch(`/teachers/${id}/roles`, data);
  }

  delete(id) {
    return http.delete(`/teachers/${id}`);
  }

  findByTitle(name) {
    return http.get(`/teachers?search=${name}`);
  }

}

export default new DataService();