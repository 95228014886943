<template>
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M24.1094 2.67188L22.3281 0.890625C21.7656 0.328125 20.9688 0 20.2188 0C19.4688 0 18.6719 0.328125 18.1094 0.890625L14.5938 4.40625L13 6L1.5625 17.4375L1 22.7812C0.90625 23.4375 1.42188 24 2.07812 24C2.125 24 2.17188 24 2.21875 24L7.5625 23.4375L19 12L20.5938 10.4062L24.1094 6.89062C25.2812 5.71875 25.2812 3.84375 24.1094 2.67188ZM6.53125 21.2812L3.39062 21.6094L3.71875 18.4688L14.5469 7.59375L16 6.14062L18.8594 9L17.4062 10.4531L6.53125 21.2812ZM22.5156 5.29688L20.4531 7.40625L17.5938 4.54688L19.7031 2.48438C19.8906 2.29688 20.125 2.25 20.2188 2.25C20.3125 2.25 20.5469 2.29688 20.7344 2.48438L22.5156 4.26562C22.7969 4.54688 22.7969 5.01562 22.5156 5.29688Z" fill="url(#paint0_linear)"/>
    <defs>
    <linearGradient id="paint0_linear" x1="-6.32" y1="11.8812" x2="38.2" y2="11.8812" gradientUnits="userSpaceOnUse">
    <stop stop-color="#EF6549"/>
    <stop offset="1" stop-color="#B23066"/>
    </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  
}
</script>

<style lang="sass">

</style>