<template>
  <b-modal
    ref="update-calendar"
    id="update-calendar"
    hide-header
    hide-footer
    centered
    no-close-on-backdrop
  >
    <h4 class="purple mb-4">
      Atualizar Ano Letivo {{ selectedDate.designation }}
    </h4>
    <b-row>
      <b-col class="mt-4">
        <b-form-group class="calendar">
          <label>Data de início</label>
          <Datepicker
            v-model="selectedDate.start_date"
            placeholder="Selecione"
            format="dd/MM/yyyy"
            :use-utc="true"
            :language="languages[language]"
          ></Datepicker>
        </b-form-group>
      </b-col>
      <b-col class="mt-4">
        <b-form-group class="calendar">
          <label>Data de término</label>
          <Datepicker
            v-model="selectedDate.end_date"
            placeholder="Selecione"
            format="dd/MM/yyyy"
            :use-utc="true"
            :language="languages[language]"
          ></Datepicker>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="mt-4">
        <b-form-group class="group-radio">
          <label for="label">Calendário ativo da escola?</label>
          <div class="radio-inline">
            <b-form-radio
              name="name"
              value="true"
              v-model="selectedDate.current"
              >Sim</b-form-radio
            >
            <b-form-radio
              name="name"
              value="false"
              v-model="selectedDate.current"
              >Não</b-form-radio
            >
          </div>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="mt-5">
      <b-col>
        <b-button class="btn large outline" @click="hideModal()"
          >Voltar</b-button
        >
      </b-col>
      <b-col class="text-right">
        <b-button class="btn large blue" @click="update(selectedDate.id)"
          >Salvar</b-button
        >
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import DataService from "@/services/calendars.service";
import httpRda from "../../http-rda";

import Datepicker from "vuejs-datepicker";
import * as lang from "vuejs-datepicker/src/locale";

export default {
  name: "",
  components: {
    Datepicker,
  },
  props: {
    calendar: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectedDate: {},
      calendars: [],
      language: "ptBR",
      languages: lang,
    };
  },

  watch: {
    calendar() {
      if (this.calendar)
        this.selectedDate = JSON.parse(JSON.stringify(this.calendar));
    },
  },

  methods: {
    async update(id) {
      var data = {
        id: this.selectedDate.id,
        start_date: this.selectedDate.start_date,
        end_date: this.selectedDate.end_date,
      };

      if (this.selectedDate.current === "true") {
        await httpRda.patch("/config/calendars", {
          calendar_id: id,
        });
      }

      DataService.update(id, data)
        .then((response) => {
          this.calendars.id = response.data.calendar.id;
          window.location.reload();
          const Toast = this.$swal.mixin({
            toast: true,
            position: "bottom-end",
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer);
              toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            },
          });
          Toast.fire({
            icon: "success",
            title: "Ano Letivo atualizado com sucesso!",
          });
          this.hideModal();
        })
        .catch((error) => {
          this.message =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
          this.$swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Não foi possivel atualizar!",
            footer: `${this.message.details}`,
          });
        });
    },
    hideModal() {
      this.$refs["update-calendar"].hide();
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/css/variables.scss";

div#update-calendar {
  .modal-dialog {
    max-width: 820px;
    .modal-content {
      background-color: $grey2;
      border: 0;
      border-radius: 0;
      .modal-body {
        padding: 60px;
      }
    }
  }
  .students {
    list-style: none;
    padding: 0;
    margin: 0;
    .student {
      align-items: center;
      display: flex;
      justify-content: space-between;
      line-height: 60px;
      border-bottom: 1px solid $grey5;
      &:last-child {
        border-bottom: 0;
      }
      .name {
        margin: 0;
      }
      .actions {
        a {
          margin: 0 15px;
        }
      }
    }
  }
  .vdp-datepicker__calendar:nth-child(3) {
    header {
      .month__year_btn.up {
        pointer-events: none;
        width: 100%;
      }
      .prev,
      .next {
        display: none;
      }
    }
  }
}
</style>
