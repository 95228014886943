<template>
  <b-form-group>
    <label for="label">{{ label }}</label>
    <multiselect
      v-bind="$attrs"
      v-model="option"
      @select="callbackSelect"
      @search-change="searchChange"
      :close-on-select="true"
      :clear-on-select="false"
      :preserve-search="true"
      label="name"
      track-by="id"
      selectLabel=""
      selectedLabel=""
      deselectLabel=""
    >
      <span slot="noResult">{{
        isLoading ? "Carregando lista..." : "Nada encontrado."
      }}</span>
      <span slot="noOptions">Lista vazia.</span>
    </multiselect>
  </b-form-group>
</template>

<script>
export default {
  inheritAttrs: false,

  data() {
    return {
      option: this.init
    }
  },

  props: {
    init: {
      type: Object,
      default: () => null
    },
    name: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    callbackSelect: {
      type: Function,
      default: () => {''}
    },
    searchChange: {
      type: Function,
      default: () => {''}
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },

  watch: {
    '$attrs.options'(newV, oldV) {
      if(!this.option) return
      else if(newV.length === oldV.length) return

      else if(!this.$attrs.options.find(opt => opt.id === this.option.id || opt.name === this.option.name)) {
        this.option = null
        this.callbackSelect({})
      }
    },

    'init'() {
      this.option = this.init
    }
  },

}
</script>
