<template>
  <b-form-group label="Validado">
    <multiselect
      :value="computedValue"
      :options="myOptions"
      :multiple="false"
      :close-on-select="true"
      :clear-on-select="false"
      :preserve-search="true"
      :placeholder="placeholder"
      label="designation"
      track-by="id"
      selectLabel=""
      selectedLabel=""
      deselectLabel=""
      @select="callback"
    >
      <span slot="noResult">Nada encontrado</span>
    </multiselect>
  </b-form-group>
</template>

<script>
export default {
  props: {
    placeholder: {
      default: "Todos",
      type: String,
    },
    value: {},
    callback: {},
    status: null,
  },
  data() {
    return {
      options: "",
      validationStatus: [],
    };
  },

  methods: {
    clearAll() {
      this.validationStatus = [];
    },
  },
  computed: {
    myOptions() {
      return [
        { designation: "Sim", id: "true" },
        { designation: "Não", id: "false" },
        { designation: "Todos", id: null },
      ];
    },
    computedValue() {
      return this.myOptions.find((c) => c.id == this.value);
    },
  },
};
</script>
