<template>
  <div class="home">
    <template>
      <b-container class="container-rda">
        <b-row>
          <b-col>
            <b-breadcrumb :items="breadcrumb"></b-breadcrumb>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <h2 class="purple bold">Ata de reunião</h2>
          </b-col>
          <b-col class="text-right"> </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-row class="profile-row">
              <b-col>
                <img src="@/assets/images/foto-perfil-dados.png" alt="" />
                <div class="content-meeting">
                  <div class="column">
                    <h5>Achilhes Najar Maia</h5>
                    <p><strong>Disciplina:</strong> 2º EF</p>
                  </div>
                  <div class="column">
                    <p><strong>Duração:</strong> 1h</p>
                    <p><strong>Data:</strong> 01/10/2019</p>
                  </div>
                  <div class="column">
                    <p><strong>Participante:</strong> Pai</p>
                    <p><strong>Última edição:</strong> 20/10/2019</p>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <div class="meeting-box">
              <div class="content-meeting">
                <h3 class="purple mb-4">Resumo</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                <h3 class="purple mt-5 mb-4">Ata completa:</h3>
                <p>
                  Aenean tristique velit ac tincidunt hendrerit. Maecenas
                  commodo lobortis tempor. Proin dignissim bibendum ornare.
                  Nullam nisi libero, aliquam eu orci at, pulvinar rhoncus eros.
                  In accumsan volutpat pellentesque. Praesent eu neque id tellus
                  eleifend mollis. Lorem ipsum dolor sit amet, consectetur
                  adipiscing elit. Aenean tristique velit ac tincidunt
                  hendrerit. Maecenas commodo lobortis tempor. Proin dignissim
                  bibendum ornare. Nullam nisi libero, aliquam eu orci at,
                  pulvinar rhoncus eros. In accumsan volutpat pellentesque.
                  Praesent eu neque id tellus eleifend mollis.
                </p>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Aenean tristique velit ac tincidunt hendrerit. Maecenas
                  commodo lobortis tempor. Proin dignissim bibendum ornare.
                  Nullam nisi libero, aliquam eu orci at, pulvinar rhoncus eros.
                  In accumsan volutpat pellentesque. Praesent eu neque id tellus
                  eleifend mollis. Lorem ipsum dolor sit amet, consectetur
                  adipiscing elit. Aenean tristique velit ac tincidunt
                  hendrerit. Maecenas commodo lobortis tempor. Proin dignissim
                  bibendum ornare. Nullam nisi libero, aliquam eu orci at,
                  pulvinar rhoncus eros. In accumsan volutpat pellentesque.
                  Praesent eu neque id tellus eleifend mollis.
                </p>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Aenean tristique velit ac tincidunt hendrerit. Maecenas
                  commodo lobortis tempor. Proin dignissim bibendum ornare.
                  Nullam nisi libero, aliquam eu orci at, pulvinar rhoncus eros.
                  In accumsan volutpat pellentesque. Praesent eu neque id tellus
                  eleifend mollis. Lorem ipsum dolor sit amet, consectetur
                  adipiscing elit. Aenean tristique velit ac tincidunt
                  hendrerit. Maecenas commodo lobortis tempor. Proin dignissim
                  bibendum ornare. Nullam nisi libero, aliquam eu orci at,
                  pulvinar rhoncus eros. In accumsan volutpat pellentesque.
                  Praesent eu neque id tellus eleifend mollis.
                </p>
                <p>
                  * Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Aenean tristique velit ac tincidunt hendrerit. Maecenas
                  commodo lobortis tempor. Proin dignissim bibendum ornare.
                  Nullam nisi libero, aliquam eu orci at, pulvinar rhoncus eros.
                  In accumsan volutpat pellentesque. Praesent eu neque id tellus
                  eleifend mollis.
                </p>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row align-v="center" class="mt-5">
          <b-col> </b-col>
          <b-col class="text-right"> </b-col>
          <b-col class="text-right">
            <b-button href="/reunioes" class="large blue outline"
              >Voltar</b-button
            >
          </b-col>
          <b-col class="text-right">
            <b-button href="/alunos" class="large blue">Concluir</b-button>
          </b-col>
        </b-row>
      </b-container>
    </template>
  </div>
</template>

<script>

export default {
  name: 'ata-de-reuniao',
  components: {

  },
  data() {
    return {
      breadcrumb : [
        {
          text: 'Comunidade',
          href: '/comunidade'
        },
        {
          text: 'Alunos',
          href: '/alunos'
        },
        {
          text: 'Perfil',
          href: '/aluno/perfil'
        },
        {
          text: 'Ata de reunião',
          href: '/ata-de-reuniao',
          active: true
        },
      ],
    }
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>

@import "@/assets/css/variables.scss";

.profile-row {
  background: $gradient3;
  border-radius: 10px;
  color: $white;
  margin: 30px 0 60px;
  padding: 28px 10px;
  position: relative;
  &:after {
    content: url('~@/assets/images/watermark-profile.png');
    right: 0;
    pointer-events: none;
    position: absolute;
    top: -5px;
  }
  .col {
    display: flex;
    align-items: center;
    img {
      margin-right: 40px;
    }
    .content-meeting {
      display: flex;
      width: 100%;
      .column {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 60px;
        min-width: 225px;
      }
      h5 {
        margin: 0;
      }
      p {
        margin: 0;
      }
    }
  }
}

.meeting-box {
  background-color: rgba( $blue, 0.20);
  border-radius: 10px;
  margin-bottom: 40px;
  padding: 60px 30px 40px;
  .content-meeting {
    max-width: 1180px;
    margin: 0 auto;
    h3 {
      margin: 0 0 10px;
    }
    .discipline {
      color: $grey4;
      margin: 0;
    }
    .date-time {
      color: $grey5;
      display: block;
      font-size: 16px;
      margin: 0 0 20px;
    }
    .text {
      max-width: 1025px;
      margin: 0 0 15px;
    }
    .reply {
      color: $purple;
      font-size: 16px;
    }
  }

}

</style>