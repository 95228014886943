<template>
  <b-row>
    <b-col class="mt-5">
      <h3 class="mb-4">Evolução do aluno em relação ao currículo</h3>
    </b-col>
    <div class="w-100"></div>
    <b-col>
      <div class="graphs" v-if="subjects.length > 0">
        <ul class="lines-percentage">
          <li
            class="desired-percentage"
            :style="{ left: desiredPercentage - 9 + '%' }"
          >
            <span v-b-tooltip.hover title="Progresso esperado" />
            <b class="line-number desired-number">{{ desiredPercentage }}%</b>
          </li>
          <li class="line-number-zero">
            <span v-b-tooltip.hover title="Progresso esperado" />
            <b class="line-number">0%</b>
          </li>
          <li
            v-for="(percentage, index) in percentages"
            :key="index"
            :style="{ left: index * 10 + '%' }"
          >
            <span v-b-tooltip.hover title="Progresso esperado" />
            <b class="line-number">{{ percentage.number }}%</b>
          </li>
        </ul>
        <div class="line" v-for="subject in subjects" :key="subject.id">
          <p class="bold">{{ subject.designation }}</p>
          <b-progress
            max="100"
            :class="
              `${
                subject.designation === 'Artes Visuais' ? 'artes-visuais' : ''
              }` ||
              `${
                subject.designation === 'Ciências da Natureza'
                  ? 'ciencias-natureza'
                  : ''
              }` ||
              `${
                subject.designation === 'Ciências Humanas e Sociais'
                  ? 'ciencias-humanas'
                  : ''
              }` ||
              `${
                subject.designation === 'Corpo e Mente' ? 'corpo-mente' : ''
              }` ||
              `${subject.designation === 'Dança' ? 'danca' : ''}` ||
              `${subject.designation === 'História' ? 'historia' : ''}` ||
              `${
                subject.designation === 'Língua Portuguesa'
                  ? 'lingua-portuguesa'
                  : ''
              }` ||
              `${subject.designation === 'Matemática' ? 'matematica' : ''}` ||
              `${subject.designation === 'Espanhol' ? 'espanhol' : ''}` ||
              `${subject.designation === 'Música' ? 'musica' : ''}` ||
              `${
                subject.designation === 'Cidadania e Vida'
                  ? 'cidadania-vida'
                  : ''
              }` ||
              `${
                subject.designation === 'Cidadania e Projeto de Vida'
                  ? 'cidadania-vida'
                  : ''
              }` ||
              `${subject.designation === 'Teatro' ? 'teatro' : ''}` ||
              `${subject.designation === 'Inglês' ? 'ingles' : ''}` ||
              `${
                subject.designation === 'Língua Estrangeira'
                  ? 'lingua-estrangeira'
                  : ''
              }`
            "
          >
            <b-progress-bar
              :value="subject.percentage"
              :label="`${((subject.percentage / 100) * 100).toFixed(0)}%`"
            ></b-progress-bar>
          </b-progress>
        </div>
      </div>
      <div v-else>
        <p>Aluno não possui Disciplinas associadas</p>
      </div>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "SubjectsProgressBars",
  data() {
    return {};
  },

  props: {
    percentages: {
      default: [],
    },
    subjects: {
      default: [],
    },
    desiredPercentage: null,
  },

  methods: {},

  created() {},
};
</script>

<style lang="scss">
@import "@/assets/css/variables.scss";

.lines-percentage {
  display: flex;
  justify-content: space-between;
  margin: 0 0 0 245px;
  left: 0;
  padding: 0;
  width: calc(100% - 245px);
  height: 100%;
  position: absolute;
  right: 0;
  li {
    height: 100%;
    width: 2px;
    position: absolute;
    align-items: flex-end;
    display: flex;
    font-size: 16px;
    font-weight: $bold;
    justify-content: flex-end;
    list-style: none;
    width: 10%;
    &:after {
      content: "";
      border-right: 1px dashed $grey5;
      height: calc(100% - 50px);
      margin-bottom: 50px;
      z-index: 1;
    }
    &.line-number-zero {
      position: absolute;
      left: -10%;
    }
  }
}

.line-number {
  position: absolute;
  right: -18%;
  &.desired-number {
    bottom: 20px;
    right: -8%;
  }
}

.desired-percentage {
  color: purple;
  font-weight: bold;
  height: 100%;
  width: 14%;
  position: absolute;
  span {
    content: "";
    width: 14%;
    height: calc(100% - 50px);
    display: block;
    position: absolute;
    bottom: 50px;
    right: 0;
    z-index: 10;
    background-color: rgba(181, 50, 105, 0.3);
    border: 1px solid rgba(181, 50, 105, 1);
    border-radius: 0;
    cursor: help;
    font-size: 12px !important;
  }
}

.profile-row {
  background: $gradient3;
  border-radius: 10px;
  color: $white;
  padding: 28px 10px;
  position: relative;
  &:after {
    content: url("~@/assets/images/watermark-profile.png");
    right: 0;
    pointer-events: none;
    position: absolute;
    top: -5px;
  }
  .col {
    display: flex;
    align-items: center;
    img {
      margin-right: 40px;
      border-radius: 50%;
    }
    .content-profile {
      h5 {
        margin-bottom: 14px;
      }
      p {
        margin: 0;
      }
    }
  }
}

.graphs {
  .line {
    .progress {
      .progress-bar[aria-valuenow="100"]:after {
        display: none;
      }
    }
  }
}

.dash-box {
  border-radius: 19px;
  color: $white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 120px;
  padding: 15px 10px;
  text-align: center;
  p {
    margin-bottom: 10px;
  }
  h1 {
    margin: 0;
  }
  &.contents {
    background-color: $purple;
  }
  &.scripts {
    background-color: $green1;
  }
  &.comments {
    background-color: $orange;
  }
  &:hover {
    color: $white;
  }
}

.dash-tasks {
  background-color: $white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 120px;
  padding: 15px 10px;
  text-align: center;
  p {
    margin-bottom: 10px;
  }
  h1 {
    margin: 0;
    color: $grey4;
  }
  &:hover {
    background: $gradient1;
    color: $white;
    cursor: pointer;
    h1 {
      color: $white;
    }
  }
  &.active {
    background: $gradient1;
    color: $white;
    h1 {
      color: $white;
    }
  }
}

.task-list {
  background-color: $white;
  border-radius: 10px;
  margin: 0 15px;
  min-height: 130px;
  padding: 20px;
  position: relative;
  h5 {
    color: $grey4;
    margin: 0 0 4px 0;
  }
  .disciplines {
    color: $grey4;
    font-size: 16px;
    margin: 0;
  }
  &.date {
    h5 {
      background: -webkit-linear-gradient(left, $orange, $purple);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    &:after {
      content: "";
      background: $gradient1;
      border-radius: 60px;
      display: block;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      width: 4px;
    }
  }
}

.owl-dots {
  .owl-dot {
    span {
      background-color: $grey5 !important;
      min-height: 22px;
      min-width: 22px;
      margin: 12px !important;
    }
    &.active {
      span {
        background: $gradient1 !important;
      }
    }
  }
}

.box-scripts {
  background-color: $white;
  border-radius: 19px;
  min-height: 169px;
  padding: 20px 25px 15px;
  text-align: center;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  svg {
    margin-bottom: 10px;
    height: 23px;
  }
  h5 {
    color: $grey4;
  }
  span {
    color: $grey3;
    display: block;
    font-size: 16px;
    text-align: left;
  }
  .progress {
    border-radius: 30px;
    height: 7px;
    .progress-bar {
      background-color: $purple;
    }
    &.orange {
      .progress-bar {
        background-color: $orange2;
      }
    }
    &.yellow {
      .progress-bar {
        background-color: $yellow;
      }
    }
    &.red {
      .progress-bar {
        background-color: $red;
      }
    }
    &.green {
      .progress-bar {
        background-color: $green2;
      }
    }
  }
  p {
    color: $grey4;
    font-size: 12px;
    margin: 0;
    text-align: left;
  }
}

.legend {
  color: $grey4;
  margin-bottom: 90px;
  svg {
    margin-right: 20px;
  }
}

.meetings {
  .tags {
    background-color: $blue;
    border-radius: 5px;
    color: $white;
    font-weight: $bold;
    line-height: 28px;
    max-width: 82px;
    text-align: center;
    width: 100%;
  }
}

.captalize {
  text-transform: capitalize;
}
</style>
