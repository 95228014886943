<template>
  <b-form-group label="Competência">
    <multiselect
      :value="computedValue"
      :options="competences"
      :multiple="false"
      :close-on-select="true"
      :clear-on-select="false"
      :preserve-search="true"
      placeholder="Selecione"
      label="description"
      track-by="id"
      selectLabel=""
      selectedLabel=""
      deselectLabel=""
      @select="callback"
    >
      <template slot="tag" slot-scope="{ option }">
        <span class="custom__tag">
          <span>{{ option.description }}</span>
        </span>
      </template>
      <template slot="clear" slot-scope="props">
        <div
          class="multiselect__clear"
          @mousedown.prevent.stop="clearAll(props.search)"
        ></div>
      </template>
      <span slot="noResult">Nada encontrado</span>
    </multiselect>
  </b-form-group>
</template>

<script>
import DataService from "@/services/competences.service";

export default {
  props: [
    'value',
    'callback'
  ],
  data() {
    return {
      options: '',
      competences: [],
    }
  },
  created() {
    this.getCompetences();
  },
  methods: {
    getCompetences() {
      DataService.getAll()
        .then(response => {
          this.competences = response.data.competences
        })
        .catch(error => {
          return error
        });
    },
  },
  computed: {
    computedValue() {
      if( typeof this.value === 'number' ){
        return this.competences.find( c => c.id == this.value )
      } else {
        return this.value
      }
    }
  }
}
</script>