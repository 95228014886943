<template>
  <div class="loading" v-if="value">
    <div>
      <b-spinner label="Loading..."></b-spinner>
      <span v-if="text" class="text">{{ text }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoadingList",
  props: ["value", "text"],

  components: {
    //
  },

  methods: {
    //
  },

  computed: {
    //
  },

  mounted() {
    //
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/variables.scss";

.loading {
  background: rgba(250, 250, 250, 0.5);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  border-radius: 10px;

  div {
    background: #fff;
    max-width: 150px;
    border: 1px solid #dedede;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    padding: 10px 10px 8px;
    position: -webkit-sticky;
    position: sticky;
    top: 100px;
    left: 50%;
    transform: translateX(-50%);

    span {
      color: $purple;
    }
    .text {
      font-size: 14px;
    }
  }
}
</style>
