import options from './options.json'
import { createResponsibleBody, createParentBody } from './postResponsibles'
import { delay } from './delay'
import { getPercentage, getPercentageReEnrollment } from './getPercentage'
import { validateCPF } from './validateCPF'

export default {
	delay,
	options,
	getPercentage,
	getPercentageReEnrollment,
	createParentBody,
	createResponsibleBody,
	validateCPF,
}