<template>
  <b-modal
    ref="student-data"
    id="student-data"
    hide-header
    hide-footer
    centered
    no-close-on-backdrop
  >
    <h4 class="purple mb-4">Editar dados</h4>
    <p>Altere os dados do usuário selecionado:</p>
    <b-row>
      <b-col class="mt-4">
        <b-form-group label="Nome">
          <b-form-input
            v-model="studentToEdit.User.name"
            type="text"
            placeholder=""
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col class="mt-4 hide-last">
        <SelectClassrooms
          :value="studentToEdit.classroom_id"
          :callback="callbackClassrooms"
        >
        </SelectClassrooms>
      </b-col>
      <div class="w-100"></div>
      <b-col class="mt-4">
        <SelectCycles
          :value="studentToEdit.cycle_id"
          :callback="callbackCycles"
        >
        </SelectCycles>
      </b-col>
      <b-col class="mt-4 status">
        <b-form-group label="Ativo">
          <b-form-checkbox
            v-model="studentToEdit.is_active"
            switch
          ></b-form-checkbox>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="mt-5">
      <b-col>
        <b-button class="btn large outline" @click="hideModal()"
          >Voltar</b-button
        >
      </b-col>
      <b-col class="text-right">
        <b-button class="btn large blue" @click="update">Salvar</b-button>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import { mapActions } from 'vuex'

import DataService from "@/services/students.service";

import SelectClassrooms from "@/components/inputs/SelectClassrooms";
import SelectCycles from "@/components/inputs/SelectCycles";

export default {
  name: "",
  components: {
    SelectClassrooms,
    SelectCycles,
  },
  props: {
    student: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      studentToEdit: {
        User: {
          name: ''
        }
      },
    };
  },

  watch: {
    student() {
      if (this.student.User)
        this.studentToEdit = JSON.parse(JSON.stringify(this.student));
    },
  },

  methods: {
    ...mapActions({
      getStudents: 'community/listStudents',
    }),

    hideModal() {
      this.$refs["student-data"].hide();
    },

    callbackCycles(args) {
      this.studentToEdit.cycle_id = args.id;
    },

    callbackClassrooms(args) {
      this.studentToEdit.classroom_id = args.id;
    },

    update() {
      var data = {
        name: this.studentToEdit.User.name,
        classroom_id: this.studentToEdit.classroom_id,
        cycle_id: this.studentToEdit.cycle_id,
        is_active: this.studentToEdit.is_active,
      };
      DataService.update(this.studentToEdit.id, data)
        .then((response) => {
          this.hideModal()
          this.getStudents()
          const Toast = this.$swal.mixin({
            toast: true,
            position: "bottom-end",
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer);
              toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            },
          })
          Toast.fire({
            icon: "success",
            title: "Disciplina atualizada com sucesso!",
          })

          return response
        })
        .catch((error) => {
          this.$swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Não foi possivel atualizar!",
            footer: "Por favor, verifique se os campos estão preenchidos.",
          })

          return error
        })
    },
  }
}
</script>

<style lang="scss">
@import "@/assets/css/variables.scss";

div#student-data {
  .modal-dialog {
    max-width: 820px;
    .modal-content {
      background-color: $grey2;
      border: 0;
      border-radius: 0;
      .modal-body {
        padding: 60px;
      }
    }
  }
  .students {
    list-style: none;
    padding: 0;
    margin: 0;
    .student {
      align-items: center;
      display: flex;
      justify-content: space-between;
      line-height: 60px;
      border-bottom: 1px solid $grey5;
      &:last-child {
        border-bottom: 0;
      }
      .name {
        margin: 0;
      }
      .actions {
        a {
          margin: 0 15px;
        }
      }
    }
  }
}
</style>