<template>
  <b-container class="container-rda">
    <b-row>
      <b-col>
        <b-breadcrumb :items="breadcrumb"></b-breadcrumb>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <h2 class="purple bold">Dados do Aluno</h2>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-row class="profile-row">
          <b-col>
            <img v-if="!photo" width="64" src="@/assets/images/default-photo.png" alt />
            <img v-else width="64" height="64" :src="photo" alt />

            <div class="content-profile">
              <h5>{{ selected.user.name }}</h5>
              <p v-if="students.Cycle">Ciclo: {{ students.Cycle.name }}</p>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row v-if="!canEdit">
      <b-col class="title">
        <h5>Dados do aluno</h5>
      </b-col>
      <div class="w-100"></div>
      <b-col>
        <StandardInput type="text" name="name" label="Nome" v-model="selected.user.name"></StandardInput>
      </b-col>
      <b-col>
        <StandardInput type="email" name="email" label="E-mail" v-model="selected.user.email"></StandardInput>
      </b-col>
      <b-col v-if="selected.user.Phones[1]">
        <StandardInput
          type="text"
          name="phone"
          label="Celular"
          v-mask="['(##)#####-####']"
          v-model="selected.user.Phones[0].number"
        ></StandardInput>
      </b-col>
      <b-col>
        <b-form-group class="calendar">
          <label>Data de Nascimento</label>
          <Datepicker
            v-model="selected.user.birth_date"
            placeholder="Selecione"
            format="dd/MM/yyyy"
            :use-utc="true"
            :language="languages[language]"
          ></Datepicker>
        </b-form-group>
      </b-col>
      <div class="w-100"></div>
      <b-col class="title">
        <h5>Documentos</h5>
      </b-col>
      <div class="w-100"></div>
      <b-col md="3">
        <StandardInput
          type="text"
          name="cpf"
          label="CPF"
          v-mask="['###.###.###-##']"
          v-model="selected.user.cpf"
        ></StandardInput>
      </b-col>
      <b-col md="3">
        <StandardInput type="text" name="rg" label="RG" v-model="selected.user.rg"></StandardInput>
      </b-col>
      <div class="w-100"></div>
      <b-col v-if="selected.user.Address" class="title">
        <h5>Dados de endereço</h5>
      </b-col>
      <div class="w-100"></div>
      <b-col v-if="selected.user.Address">
        <StandardInput type="text" name="cep" label="CEP" v-model="selected.user.Address.cep"></StandardInput>
      </b-col>
      <b-col v-if="selected.user.Address">
        <StandardInput type="text" name="city" label="Cidade" v-model="selected.user.Address.city"></StandardInput>
      </b-col>
      <b-col v-if="selected.user.Address">
        <StandardInput
          type="text"
          name="neighborhood"
          label="Bairro"
          v-model="selected.user.Address.neighborhood"
        ></StandardInput>
      </b-col>
      <b-col v-if="selected.user.Address">
        <StandardInput
          type="text"
          name="address"
          label="Rua"
          v-model="selected.user.Address.street"
        ></StandardInput>
      </b-col>
      <div class="w-100"></div>
      <b-col md="2" v-if="selected.user.Address">
        <StandardInput
          type="text"
          name="number"
          label="Número"
          v-model="selected.user.Address.number"
        ></StandardInput>
      </b-col>
      <b-col md="2" v-if="selected.user.Address">
        <StandardInput
          type="text"
          name="complement"
          label="Complemento"
          v-model="selected.user.Address.complement"
        ></StandardInput>
      </b-col>
    </b-row>
    <b-row class="row-buttons">
      <b-col>
        <b-button @click="update" class="btn large outline" v-if="!canEdit">Atualizar dados</b-button>
      </b-col>
      <b-col></b-col>
      <b-col class="text-right">
        <b-button class="btn large outline" v-b-modal.edit-photo-1>Editar foto de perfil</b-button>
      </b-col>
      <b-col class="text-right">
        <b-button class="btn large blue" v-b-modal.edit-password-children>Alterar senha</b-button>
      </b-col>
    </b-row>

    <ChildrenEditPassword />

    <EditPhotoProfile :callback="selectPhoto" :photo="photo" />
  </b-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import DataService from "@/services/user.service";

import Datepicker from "vuejs-datepicker";
import * as lang from "vuejs-datepicker/src/locale";

import StandardInput from "@/components/inputs/StandardInput.vue";
import ChildrenEditPassword from "@/components/modals/ChildrenEditPassword.vue";
import EditPhotoProfile from "../../components/modals/EditPhotoProfile.vue";

export default {
  name: "Perfil",
  components: {
    Datepicker,
    StandardInput,
    ChildrenEditPassword,
    EditPhotoProfile,
  },

  data() {
    return {
      selected: null,

      newPhoto: null,
      blob: null,

      language: "ptBR",
      languages: lang,

      breadcrumb: [
        {
          text: "Meus dados",
        },
      ],
    };
  },

  filters: {
    date(date) {
      try {
        var d = new Date(date.split());
        var year = d.getFullYear();
        var month = (d.getMonth() + 1).toString().padStart(2, "0");
        var day = d.getUTCDate().toString().padStart(2, "0");

        return day + "/" + month + "/" + year;
      } catch (error) {
        return date;
      }
    },
  },

  computed: {
    ...mapGetters({
      currentUser: "auth/getAuthData",
      users: "shared/users",
      students: "shared/studentsCycle",
    }),

    canEdit() {
      if (!this.currentUser.role) return false;
      return !["coordinator", "teacher", "parent", "secretary"].includes(
        this.currentUser.role[0]
      );
    },

    photo() {
      if (this.newPhoto) return this.newPhoto;
      else if (this.users.photo) return this.users.photo;
      else return null;
    },

    phone() {
      if (this.users.Phones[0]) return this.users.Phones[0].number;
      else return "Sem registro";
    },

    birthday() {
      if (this.users.birth_date) return this.users.birth_date;
      else return "Sem registro";
    },

    addressCep() {
      if (this.users.Address) return this.users.Address.cep;
      else return "Sem registro";
    },

    addressCity() {
      if (this.users.Address) return this.users.Address.city;
      else return "Sem registro";
    },

    addressNeighborhood() {
      if (this.users.Address) return this.users.Address.neighborhood;
      else return "Sem registro";
    },

    addressStreet() {
      if (this.users.Address) return this.users.Address.street;
      else return "Sem registro";
    },

    addressNumber() {
      if (this.users.Address) return this.users.Address.number;
      else return "Sem registro";
    },

    addressComplement() {
      if (this.users.Address) return this.users.Address.complement;
      else return "Sem registro";
    },
  },

  methods: {
    ...mapActions({
      getUser: "shared/getUser",
      getStudentCycle: "shared/getStudentCycle",
      updatePhoto: "shared/sabeStudentPhoto",
    }),

    setUser() {
      this.user_id = this.$route.params.id;
      this.getUser(this.user_id);
    },

    selectPhoto(photo) {
      const urlCreator = window.URL || window.webkitURL;
      const imageUrl = urlCreator.createObjectURL(photo);
      const bodyFormData = new FormData();

      bodyFormData.append("file", photo);

      this.updatePhoto({
        roles: "users",
        id: this.$route.params.id,
        file: bodyFormData,
      });

      this.newPhoto = imageUrl;
      this.blob = photo;

      const Toast = this.$swal.mixin({
        toast: true,
        position: "bottom-end",
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "success",
        title: "Foto atualizado com sucesso!",
      });
    },

    update() {
      try {
        var address = {
          cep: this.selected.user.Address ? this.selected.user.Address.cep : "",
          number: this.selected.user.Address
            ? this.selected.user.Address.number
            : "",
          complement: this.selected.user.Address
            ? this.selected.user.Address.complement
            : "",
          street: this.selected.user.Address
            ? this.selected.user.Address.street
            : "",
          neighborhood: this.selected.user.Address
            ? this.selected.user.Address.neighborhood
            : "",
          city: this.selected.user.Address
            ? this.selected.user.Address.city
            : "",
          state: this.selected.user.Address
            ? this.selected.user.Address.state
            : "",
          country: this.selected.user.Address
            ? this.selected.user.Address.country
            : "",
        };

        DataService.updateAddress(this.selected.user.id, address);

        DataService.update(this.selected.user.id, this.selected.user)
          .then((response) => {
            this.setUser();
            const Toast = this.$swal.mixin({
              toast: true,
              position: "bottom-end",
              showConfirmButton: false,
              timer: 5000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", this.$swal.stopTimer);
                toast.addEventListener("mouseleave", this.$swal.resumeTimer);
              },
            });

            Toast.fire({
              icon: "success",
              title: "Perfil atualizado com sucesso!",
            });

            this.setUser();

            return response.data;
          })
          .catch((error) => {
            this.$swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Não foi possivel atualizar!",
              footer: "Por favor, tente novamente mais tarde.",
            });

            return error.response;
          });
      } catch (error) {
        this.$swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Não foi possivel atualizar!",
          footer: "Por favor, tente novamente mais tarde.",
        });

        return error.response;
      }
    },

    getSelected(id) {
      DataService.get(id)
        .then((response) => {
          this.selected = response.data;
          return response.data;
        })
        .catch((error) => {
          return error.response;
        });
    },
  },

  created() {
    if (this.$route.params.id) this.setUser();
  },

  mounted() {
    this.getSelected(this.$route.params.id);
  },

  watch: {
    currentUser() {
      this.setUser();
    },
  },
};
</script>

<style lang="scss" scoped>

@import "@/assets/css/variables.scss";

.profile-row {
  background: $gradient3;
  border-radius: 10px;
  color: $white;
  margin: 30px 0 40px;
  padding: 28px 10px;
  position: relative;
  &:after {
    content: url('~@/assets/images/watermark-profile.png');
    right: 0;
    pointer-events: none;
    position: absolute;
    top: -5px;
  }
  .col {
    display: flex;
    align-items: center;
    img {
      margin-right: 40px;
    }
    .content-profile {
      h5 {
        margin-bottom: 14px;
      }
      p {
        margin: 0;
      }
    }
  }
}

.form-group {
  margin-bottom: 40px;
}
.title {
  padding: 20px 15px 40px;
  h5 {
    margin: 0;
  }
}
.row-buttons {
  padding-top: 100px;
}
</style>