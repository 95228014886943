<template>
  <b-container class="container-rda login">
    <b-row class="form-login">
      <b-col>
        <div v-if="!successRequest" class="box-login">
          <h2>Solicitação de exclusão de dados</h2>
          <p class="mb-4">
            Preenchendo o formulário abaixo, você poderá solicitar a exclusão de
            seus dados da plataforma. Lembre-se de que essa ação é irreversível,
            então tenha certeza de que é o que você deseja.
          </p>
          <form name="form" @submit.prevent="submitDeleteData">
            <b-form-group>
              <label for="label">Nome</label>
              <b-form-input
                v-model="name"
                v-validate="'required'"
                type="text"
                name="name"
                placeholder="Digite o seu nome de cadastro"
                class="form-control"
              ></b-form-input>
              <div
                v-if="errors.has('name')"
                class="alert alert-danger"
                role="alert"
              >
                Campo obrigatório
              </div>

              <b-row>
                <b-col>
                  <label style="padding-top: 10px" for="label">CPF</label>
                  <b-form-input
                    v-model="cpf"
                    v-validate="'required'"
                    type="text"
                    name="cpf"
                    v-mask="'###.###.###-##'"
                    placeholder="Digite aqui o seu CPF de cadastro"
                    class="form-control"
                  ></b-form-input>
                  <div
                    v-if="errors.has('cpf')"
                    class="alert alert-danger"
                    role="alert"
                  >
                    Campo obrigatório
                  </div>
                </b-col>

                <b-col>
                  <label style="padding-top: 10px" for="label"
                    >Endereço de e-mail</label
                  >
                  <b-form-input
                    v-model="email"
                    v-validate="'required'"
                    type="text"
                    name="email"
                    placeholder="Digite o seu e-mail de cadastro"
                    class="form-control"
                  ></b-form-input>
                  <div
                    v-if="errors.has('email')"
                    class="alert alert-danger"
                    role="alert"
                  >
                    Campo obrigatório
                  </div>
                </b-col>
              </b-row>

              <label style="padding-top: 10px" for="label">Observação</label>
              <b-form-textarea
                v-model="note"
                name="note"
                placeholder="Digite aqui uma observação"
                class="form-control"
              ></b-form-textarea>

              <b-checkbox-group
                style="padding-top: 10px"
                name="confirm-checkbox"
                id="confirm-checkbox"
              >
                <b-form-checkbox
                  v-model="confirm"
                  :value="true"
                  :unchecked-value="false"
                  id="confirm-true"
                >
                  <div style="color: #fff; font-size: 18px">
                    Estou ciente de que essa ação é irreversível e desejo
                    continuar
                  </div>
                </b-form-checkbox>
              </b-checkbox-group>
            </b-form-group>

            <button
              :disabled="loading || !isConfirmed()"
              class="mt-4 btn full btn-login"
            >
              <span
                v-show="loading"
                class="spinner-border spinner-border-sm"
              ></span>
              <span>Solicitar exclusão dos meus dados</span>
            </button>
            <div class="mt-3 d-block text-center">
              <router-link :to="{ name: 'login' }" class="white"
                >Voltar para tela de acesso</router-link
              >
            </div>
          </form>
        </div>
        <div v-if="!successRequest && message" role="alert">
          <p class="mt-4 text-center message-error">
            <InfoIcon /> {{ message }}
          </p>
        </div>
        <div v-if="successRequest" class="box-login">
          <h2 class="mb-3">E-mail enviado!</h2>
          <p class="mb-4">
            Solicitação de exclusão de dados enviada com sucesso. Aguarde o
            retorno da escola!
          </p>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import InfoIcon from "@/components/svg/InfoIcon.vue";
import httpRda from "../../http-rda";

export default {
  name: "data-deletion-request",
  components: {
    InfoIcon,
  },
  data() {
    return {
      loading: false,
      message: "",
      successRequest: false,

      email: "",
      name: "",
      cpf: "",
      note: "",
      confirm: false,
    };
  },

  methods: {
    isConfirmed() {
      return (
        this.confirm === true ||
        (Array.isArray(this.confirm) &&
          this.confirm.length === 1 &&
          this.confirm[0] === true)
      );
    },
    async submitDeleteData() {
      const data = {
        name: this.name,
        email: this.email,
        cpf: this.cpf,
        note: this.note,
      };

      this.$validator.validateAll().then(async (isValid) => {
        if (!isValid) {
          this.loading = false;
          return;
        }

        this.loading = true;

        await httpRda
          .post("/data-deletion-request", data)
          .then((response) => {
            this.loading = false;
            this.successRequest = true;

            return response;
          })
          .catch((error) => {
            this.loading = false;

            if (
              error.response &&
              error.response.data &&
              error.response.data.message
            ) {
              this.message = error.response.data.message;
            } else {
              this.message = error.message || error.toString();
            }
          });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/variables.scss";

.container-rda {
  display: flex;
  justify-content: center;
}

.login {
  background: url("~@/assets/images/bg-login.jpg");
  background-position: center;
  background-size: cover;
  height: 100%;
  max-width: 100%;
  padding: 0;
  .form-login {
    align-items: center;
    height: 100%;
    width: 100%;
    .box-login {
      max-width: 575px;
      margin: 0 auto;
      h2,
      p,
      label {
        color: $white;
      }
      .btn-login {
        text-transform: uppercase;
      }
      a {
        color: $white;
        font-size: 18px;
        line-height: 32px;
      }
    }
  }

  .spinner-border-sm {
    vertical-align: text-top;
    margin-right: 10px;
  }

  .message-error {
    background-color: $orange;
    border-radius: 5px;
    border: 1px solid $white;
    color: $white;
    font-size: 16px;
    font-weight: 700;
    line-height: 16px;
    margin: 0 auto;
    max-width: 710px;
    padding: 12px 20px;
    svg {
      vertical-align: text-top;
      margin-right: 8px;
    }
  }
}
</style>
