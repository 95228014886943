export function getPercentage(enrollment, pousoAlegre = false) {
  let percentage = 0;

  if (
    enrollment.signed_at != null &&
    enrollment.EnrollmentFile != null &&
    enrollment.EnrollmentFile.cpf != null &&
    enrollment.EnrollmentFile.cpf_contractor_a != null &&
    enrollment.EnrollmentFile.cpf_contractor_b != null &&
    enrollment.EnrollmentFile.discharge != null &&
    enrollment.EnrollmentFile.identity != null &&
    enrollment.EnrollmentFile.identity_back != null &&
    enrollment.EnrollmentFile.identity_contractor_a != null &&
    enrollment.EnrollmentFile.identity_contractor_a_back != null &&
    enrollment.EnrollmentFile.identity_contractor_b != null &&
    enrollment.EnrollmentFile.identity_contractor_b_back != null &&
    // enrollment.EnrollmentFile.transference_declaration != null &&
    enrollment.EnrollmentFile.vaccination_record != null &&
    pousoAlegre == false
  ) {
    percentage = 100;
  } else if (
    enrollment.EnrollmentFile != null &&
    enrollment.EnrollmentFile.cpf != null &&
    enrollment.EnrollmentFile.cpf_contractor_a != null &&
    enrollment.EnrollmentFile.cpf_contractor_b != null &&
    enrollment.EnrollmentFile.discharge != null &&
    enrollment.EnrollmentFile.identity != null &&
    enrollment.EnrollmentFile.identity_back != null &&
    enrollment.EnrollmentFile.identity_contractor_a != null &&
    enrollment.EnrollmentFile.identity_contractor_a_back != null &&
    enrollment.EnrollmentFile.identity_contractor_b != null &&
    enrollment.EnrollmentFile.identity_contractor_b_back != null &&
    // enrollment.EnrollmentFile.transference_declaration != null &&
    enrollment.EnrollmentFile.vaccination_record != null &&
    pousoAlegre == true
  ) {
    percentage = 100;
  } else if (enrollment.printed_at || enrollment.is_printed) {
    pousoAlegre &&
    enrollment.Calendar &&
    enrollment.Calendar.designation == 2022
      ? (percentage = 100)
      : (percentage = 90);
  } else if (enrollment.EnrollmentAuthorization) {
    percentage = 70;
  } else if (enrollment.EnrollmentComplement) {
    percentage = 60;
  } else if (
    getEnrollmentType(enrollment.EnrollmentResponsibles, "filiation a")
  ) {
    percentage = 40;
  } else if (
    getEnrollmentType(enrollment.EnrollmentResponsibles, "contractor b")
  ) {
    percentage = 30;
  } else if (
    getEnrollmentType(enrollment.EnrollmentResponsibles, "contractor a")
  ) {
    percentage = 20;
  } else if (enrollment.EnrollmentStudent) {
    percentage = 10;
  }

  return percentage;
}

export function getPercentageReEnrollment(enrollment, pousoAlegre = false) {
  let percentage = 0;

  if (
    enrollment.signed_at != null &&
    enrollment.EnrollmentFile != null &&
    enrollment.EnrollmentFile.cpf != null &&
    enrollment.EnrollmentFile.cpf_contractor_a != null &&
    enrollment.EnrollmentFile.cpf_contractor_b != null &&
    enrollment.EnrollmentFile.identity != null &&
    enrollment.EnrollmentFile.identity_back != null &&
    enrollment.EnrollmentFile.identity_contractor_a != null &&
    enrollment.EnrollmentFile.identity_contractor_a_back != null &&
    enrollment.EnrollmentFile.identity_contractor_b != null &&
    enrollment.EnrollmentFile.identity_contractor_b_back != null &&
    enrollment.EnrollmentFile.vaccination_record != null &&
    enrollment.EnrollmentStep?.enrollment_file === true &&
    pousoAlegre == false
  ) {
    percentage = 100;
  } else if (
    enrollment.EnrollmentFile != null &&
    enrollment.EnrollmentFile.cpf != null &&
    enrollment.EnrollmentFile.cpf_contractor_a != null &&
    enrollment.EnrollmentFile.cpf_contractor_b != null &&
    enrollment.EnrollmentFile.identity != null &&
    enrollment.EnrollmentFile.identity_back != null &&
    enrollment.EnrollmentFile.identity_contractor_a != null &&
    enrollment.EnrollmentFile.identity_contractor_a_back != null &&
    enrollment.EnrollmentFile.identity_contractor_b != null &&
    enrollment.EnrollmentFile.identity_contractor_b_back != null &&
    enrollment.EnrollmentFile.vaccination_record != null &&
    enrollment.EnrollmentStep?.enrollment_file === true &&
    pousoAlegre == true
  ) {
    percentage = 100;
  } else if (
    enrollment.printed_at === true ||
    enrollment.is_printed ||
    enrollment.EnrollmentStep?.printed_at === true
  ) {
    percentage = 90;
  } else if (
    enrollment.EnrollmentAuthorization &&
    enrollment.EnrollmentAuthorization.is_re_enroll === true &&
    enrollment.EnrollmentStep?.enrollment_authorization === true
  ) {
    percentage = 70;
  } else if (
    enrollment.EnrollmentComplement &&
    enrollment.EnrollmentComplement.is_re_enroll === true &&
    enrollment.EnrollmentStep?.enrollment_complement === true
  ) {
    percentage = 60;
  } else if (
    getEnrollmentType(enrollment.EnrollmentResponsibles, "filiation a") &&
    getIsReenrollmentFromType(
      enrollment.EnrollmentResponsibles,
      "filiation a"
    ) &&
    enrollment.EnrollmentStep?.enrollment_responsibles_filiations === true
  ) {
    percentage = 40;
  } else if (
    getEnrollmentType(enrollment.EnrollmentResponsibles, "contractor b") &&
    getIsReenrollmentFromType(
      enrollment.EnrollmentResponsibles,
      "contractor b"
    ) &&
    enrollment.EnrollmentStep?.enrollment_responsibles_contractors === true
  ) {
    percentage = 30;
  } else if (
    getEnrollmentType(enrollment.EnrollmentResponsibles, "contractor a") &&
    getIsReenrollmentFromType(
      enrollment.EnrollmentResponsibles,
      "contractor a"
    ) &&
    enrollment.EnrollmentStep?.enrollment_responsibles_contractors === true
  ) {
    percentage = 20;
  } else if (
    enrollment.EnrollmentStudent &&
    enrollment.EnrollmentStudent.is_re_enroll === true &&
    enrollment.EnrollmentStep?.enrollment_student === true
  ) {
    percentage = 10;
  }
  return percentage;
}

function getEnrollmentType(responsible, type) {
  return responsible.find((res) =>
    res.ResponsibleTypes.some((r) => r.type === type)
  );
}

function getIsReenrollmentFromType(responsible, type) {
  const responsibleIsReEnroll = getEnrollmentType(responsible, type);
  return responsibleIsReEnroll.ResponsibleTypes.find((r) => r.type === type)
    .EnrollmentResponsibleType.is_re_enroll;
}
