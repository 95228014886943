<template>
  <b-form-group>
    <label for="label">{{ label }}</label>
    <multiselect
      :value="computedValue"
      :options="myOptions"
      :multiple="false"
      :close-on-select="true"
      :clear-on-select="false"
      :preserve-search="true"
      :placeholder="placeholder"
      label="name"
      track-by="id"
      selectLabel=""
      selectedLabel=""
      deselectLabel=""
      @select="callback"
    >
      <span slot="noResult">Nada encontrado</span>
    </multiselect>
  </b-form-group>
</template>

<script>
export default {
  props: [
    'name',
    'label',
    'placeholder',
    'value',
    'callback'
  ],
  data() {
    return {
      options: '',
      plataforms: [
        { id: 1, value: 'Apple Mac', name: 'Apple Mac' },
        { id: 2, value: 'Linux', name: 'Linux' },
        { id: 3, value: 'Microsoft Windows', name: 'Microsoft Windows' },
      ],
    }
  },
  computed: {
    myOptions() {
      if(!this.plataforms.length)
        return []

      return [
        ...this.plataforms,
        { name: "Todos", id: -1 }
      ]
    },
    computedValue() {
      if( typeof this.value === 'number' ){
        return this.plataforms.find( c => c.id == this.value )
      } else {
        return this.value
      }
    }
  }
}
</script>

<style lang="scss">
  .form-group {
    margin-bottom: 0;
  }
</style>