<template>
  <b-container class="container-rda">
    <b-row class="pt-5 mt-4 mb-5">
      <b-col>
        <h2 class="purple bold">Bem-vindo(a)!</h2>
      </b-col>
      <b-col class="text-right">
        <b-button
          class="normal"
          :to="{
            name: 'perfil',
          }"
          >Meus dados</b-button
        >
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <div class="profile-row">
          <b-col>
            <img
              v-if="currentUser.photo == null || currentUser.photo == ''"
              src="@/assets/images/default-photo.png"
              height="64"
              width="64"
              alt
            />
            <img
              v-else
              width="64"
              height="64"
              :src="`${currentUser.photo}`"
              alt
            />
            <div class="content-profile">
              <h5>{{ currentUser.name }}</h5>
              <p
                v-if="
                  students && students.Classroom && students.Classroom.year_id
                "
              >
                Ciclo: {{ students.Cycle.name }} -
                {{ students.Classroom.year_id }}º ano
              </p>
              <p v-else>Ciclo: {{ students.Cycle.name }}</p>
            </div>
          </b-col>
        </div>
      </b-col>
      <b-col>
        <b-row>
          <b-col>
            <router-link
              class="dash-box contents"
              :to="{
                name: 'registrar-aprendizagem',
                params: { id: currentUser.student_id },
              }"
            >
              <p>Conteúdos</p>
              <h1>{{ students.Topics.length }}</h1>
            </router-link>
          </b-col>
          <b-col v-if="false">
            <router-link
              class="dash-box scripts"
              :to="{ name: 'roteiros-aluno' }"
            >
              <p>Roteiros</p>
              <h1>{{ syllabuses.length }}</h1>
            </router-link>
          </b-col>
          <b-col>
            <router-link
              class="dash-box comments"
              :to="{
                name: 'faltas-e-presencas',
                params: { student_id: currentUser.student_id },
              }"
            >
              <p>Frequência</p>
              <h1>{{ attendances.frequency }}%</h1>
            </router-link>
          </b-col>
          <b-col v-if="students.Cycle.id !== 8 && students.Cycle.id !== 6">
            <router-link
              class="dash-box comments"
              style="background-color: #4589b2"
              :to="{
                name: 'dashboard-tutoria-aluno',
                params: {
                  id: currentUser.student_id,
                },
              }"
            >
              <h2>Tutoria</h2>
            </router-link>
          </b-col>
          <b-col v-if="students.Cycle.id === 8 || students.Cycle.id === 6">
            <router-link
              class="dash-box comments"
              style="background-color: #4589b2"
              :to="{
                name: 'dashboard-timeline-aluno-parent',
                params: {
                  id: currentUser.student_id,
                },
              }"
            >
              <h2>Ed. Infantil</h2>
            </router-link>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row class="pt-5 mt-5 mb-4">
      <b-col md="12">
        <h3>Conteúdos em estudo</h3>
      </b-col>
    </b-row>

    <b-row>
      <b-col v-if="!topicsFormated.length > 0">
        <p>Não há conteúdos em estudo</p>
      </b-col>

      <b-col v-else>
        <carousel
          :perPageCustom="[
            [360, 3],
            [1366, 6],
          ]"
          paginationColor="$grey5"
        >
          <slide v-for="subject in topicsFormated" v-bind:key="subject.id">
            <div class="box-scripts">
              <b-row>
                <b-col
                  ><a @click="showModal(subject)" style="cursor: pointer">
                    <SubjectsIcon :value="subject.designation" />
                    <h5>{{ subject.designation }}</h5>
                  </a>
                </b-col>
              </b-row>
              <b-row align-v="center" no-gutters>
                <b-col>
                  <span v-if="subject.topics && subject.topics.length > 1"
                    >Há
                    <strong>{{ subject.topics.length }}</strong>
                    tópicos com status "EE"</span
                  >
                  <span v-if="subject.topics && subject.topics.length == 1"
                    >Há
                    <strong>{{ subject.topics.length }}</strong>
                    tópicos com status "EE"</span
                  >
                  <span v-if="subject.topics && subject.topics.length == 0"
                    >Não há tópicos</span
                  >
                </b-col>

                <div class="w-100"></div>
              </b-row>
            </div>
          </slide>
        </carousel>
      </b-col>
    </b-row>
    <SubjectsProgressBars
      :percentages="percentages"
      :subjects="subjects"
      :desiredPercentage="desiredPercentage"
    />
    <b-modal
      id="modal-script"
      hide-footer
      style="display: flex; justify-content: center; align-items: center"
      v-if="subjectSelected"
      :title="subjectSelected.designation"
    >
      <b-row
        class="rda-viewlist"
        v-for="(competence, index) in subjectSelected.competences"
        :key="`competence-${index}`"
      >
        <b-col cols="12">
          <h5 class="title">{{ competence.description }}</h5>
        </b-col>
        <b-col
          cols="12"
          v-for="(topic, indexS) in competence.topics"
          :key="`student-${indexS}`"
        >
          <div class="list-teacher item-rda-viewlist">
            <div class="cols text-col">
              <p>{{ topic }}</p>
            </div>

            <div class="cols">
              <p class="tags warning">EE</p>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-modal>
  </b-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { Carousel, Slide } from "vue-carousel";
import SubjectsIcon from "@/components/svg/SubjectsIcon.vue";
import SubjectsProgressBars from "../../components/SubjectsProgressBars.vue";
import httpRda from "../../http-rda";

export default {
  name: "",
  components: {
    Carousel,
    Slide,
    SubjectsIcon,
    SubjectsProgressBars,
  },

  data() {
    return {
      subjectScript: null,
      topicsFormated: null,
      subjectSelected: null,
      desiredPercentage: 10,
      percentagesDefault: [
        { number: 10, class: false },
        { number: 20, class: false },
        { number: 30, class: false },
        { number: 40, class: false },
        { number: 50, class: false },
        { number: 60, class: false },
        { number: 70, class: false },
        { number: 80, class: false },
        { number: 90, class: false },
        { number: 100, class: false },
      ],
      percentages: [
        { number: 10, class: false },
        { number: 20, class: false },
        { number: 30, class: false },
        { number: 40, class: false },
        { number: 50, class: false },
        { number: 60, class: false },
        { number: 70, class: false },
        { number: 80, class: false },
        { number: 90, class: false },
        { number: 100, class: false },
      ],
      subjects: null,
      comments: "actives",
      selectedDate: new Date(),
      breadcrumb: [
        {
          text: "Início",
          href: "/",
        },
      ],
    };
  },

  computed: {
    ...mapGetters({
      currentUser: "auth/getAuthData",
      students: "shared/studentsCycle",
      attendances: "shared/studentsAttendances",
      syllabuses: "learning/studentSyllabuses",
    }),

    month() {
      return this.formatDay(new Date());
    },

    getDay() {
      return new Date().getDay();
    },

    week() {
      return [
        {
          label: "dom",
          fullDate: this.getCorrectDate(0),
          day: this.formatDay(this.getCorrectDate(0), { day: "2-digit" }),
        },
        {
          label: "seg",
          fullDate: this.getCorrectDate(1),
          day: this.formatDay(this.getCorrectDate(1), { day: "2-digit" }),
        },
        {
          label: "ter",
          fullDate: this.getCorrectDate(2),
          day: this.formatDay(this.getCorrectDate(2), { day: "2-digit" }),
        },
        {
          label: "qua",
          fullDate: this.getCorrectDate(3),
          day: this.formatDay(this.getCorrectDate(3), { day: "2-digit" }),
        },
        {
          label: "qui",
          fullDate: this.getCorrectDate(4),
          day: this.formatDay(this.getCorrectDate(4), { day: "2-digit" }),
        },
        {
          label: "sex",
          fullDate: this.getCorrectDate(5),
          day: this.formatDay(this.getCorrectDate(5), { day: "2-digit" }),
        },
        {
          label: "sab",
          fullDate: this.getCorrectDate(6),
          day: this.formatDay(this.getCorrectDate(6), { day: "2-digit" }),
        },
      ];
    },

    syllabusesThisWeek() {
      return this.syllabuses
        .filter((sy) => this.checkDateOnRange(sy.SyllabusStudent.deadline))
        .map((sy) => {
          const deadlineDate = new Date(
            sy.SyllabusStudent.deadline.replaceAll("-", "/")
          ).getDate();
          const todayDate = this.selectedDate.getDate();

          return { ...sy, isToday: deadlineDate === todayDate };
        })
        .sort((a, b) => {
          const dateA = new Date(
            a.SyllabusStudent.deadline.replaceAll("-", "/")
          ).getDate();
          const dateB = new Date(
            b.SyllabusStudent.deadline.replaceAll("-", "/")
          ).getDate();

          return dateA < dateB ? -1 : 1;
        });
    },
  },

  methods: {
    ...mapActions({
      getSyllabuses: "learning/getStudentSyllabuses",
      getStudentAttendances: "shared/getStudentAttendances",
      getStudentCycle: "shared/getStudentCycle",
    }),

    setUser() {
      this.getStudentAttendances(this.currentUser.student_id);
      this.getStudentCycle(this.currentUser.student_id);
      this.getStudentSubject(this.currentUser.student_id);
      this.getSubjectScript(this.currentUser.student_id);
    },

    async getStudentSubject(studentId) {
      await httpRda.get(`students/${studentId}/subjects`).then((response) => {
        const data = response.data;
        this.subjects = data.subjects;
        if (data && data.desiredPercentage) {
          this.percentages = [];
          this.percentages = [...this.percentagesDefault];

          this.desiredPercentage = Number(response.data.desiredPercentage);

          this.percentages.sort((a, b) => a.number - b.number);
        }
      });
    },

    async getSubjectScript(student_id) {
      const subjects = await httpRda
        .get(`students/${student_id}/study_script`)
        .then((res) => res.data.subjectScript)
        .catch((err) => err);

      this.subjectScript = subjects;

      const topics = await subjects.reduce((acc, cur) => {
        const designation = cur.designation;
        const competences = cur.competences;

        let topics = cur.competences.reduce((acc, cur) => {
          const topics = cur.topics;
          acc.push(topics);
          return acc;
        }, []);

        topics = topics.flat();

        acc.push({ designation, competences, topics });

        return acc;
      }, []);

      this.topicsFormated = topics;
    },

    showModal(subject) {
      this.$bvModal.show("modal-script");
      this.subjectSelected = subject;
    },

    getCorrectDate(weekDay) {
      const days = weekDay - this.getDay;

      const result = new Date();
      result.setDate(result.getDate() + days);
      return result;
    },

    formatDay(day, options = { month: "long" }) {
      return new Intl.DateTimeFormat("pt-BR", options).format(new Date(day));
    },

    checkDateOnRange(deadline) {
      const dateCheck = this.formatDay(deadline.replaceAll("-", "/"), {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });

      const dateFrom = this.formatDay(this.week[0].fullDate, {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
      const dateTo = this.formatDay(this.week[6].fullDate, {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });

      const d1 = dateFrom.split("/");
      const d2 = dateTo.split("/");
      const c = dateCheck.split("/");

      const from = new Date(d1[2], parseInt(d1[1]) - 1, d1[0]);
      const to = new Date(d2[2], parseInt(d2[1]) - 1, d2[0]);
      const check = new Date(c[2], parseInt(c[1]) - 1, c[0]);

      return check >= from && check <= to;
    },

    formatSyllabusDeadline(syllabus) {
      return this.formatDay(
        syllabus.SyllabusStudent.deadline.replaceAll("-", "/"),
        { year: "numeric", month: "2-digit", day: "2-digit" }
      );
    },

    selectDay(day) {
      this.selectedDate = day;
    },
  },

  created() {
    if (this.currentUser.id) {
      this.getSyllabuses(this.currentUser.student_id);
      this.setUser();
    }
  },

  watch: {
    currentUser() {
      this.getSyllabuses(this.currentUser.student_id);
      this.setUser();
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/css/variables.scss";

.rda-viewlist {
  margin-bottom: 30px;
  .title {
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: #36b67d;
    margin-bottom: 12px;
  }

  .item-rda-viewlist {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;

    .cols {
      .tags {
        min-width: 40px;
      }
    }

    .text-col {
      flex: 1;
    }
  }
}

.profile-row {
  background: $gradient3;
  border-radius: 10px;
  color: $white;
  padding: 28px 10px;
  position: relative;
  &:after {
    content: url("~@/assets/images/watermark-profile.png");
    right: 0;
    pointer-events: none;
    position: absolute;
    top: -5px;
  }
  .col {
    display: flex;
    align-items: center;
    img {
      margin-right: 40px;
      border-radius: 50%;
    }
    .content-profile {
      h5 {
        margin-bottom: 14px;
      }
      p {
        margin: 0;
      }
    }
  }
}

.graphs {
  .line {
    .progress {
      .progress-bar[aria-valuenow="100"]:after {
        display: none;
      }
    }
  }
}

.dash-box {
  border-radius: 19px;
  color: $white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 120px;
  padding: 15px 10px;
  text-align: center;
  p {
    margin-bottom: 10px;
  }
  h1 {
    margin: 0;
  }
  &.contents {
    background-color: $purple;
  }
  &.scripts {
    background-color: $green1;
  }
  &.comments {
    background-color: $orange;
  }
  &:hover {
    color: $white;
  }
}

.dash-tasks {
  background-color: $white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 120px;
  padding: 15px 10px;
  text-align: center;
  p {
    margin-bottom: 10px;
  }
  h1 {
    margin: 0;
    color: $grey4;
  }
  &:hover {
    background: $gradient1;
    color: $white;
    cursor: pointer;
    h1 {
      color: $white;
    }
  }
  &.active {
    background: $gradient1;
    color: $white;
    h1 {
      color: $white;
    }
  }
}

.task-list {
  background-color: $white;
  border-radius: 10px;
  margin: 0 15px;
  min-height: 130px;
  padding: 20px;
  position: relative;
  h5 {
    color: $grey4;
    margin: 0 0 4px 0;
  }
  .disciplines {
    color: $grey4;
    font-size: 16px;
    margin: 0;
  }
  &.date {
    h5 {
      background: -webkit-linear-gradient(left, $orange, $purple);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    &:after {
      content: "";
      background: $gradient1;
      border-radius: 60px;
      display: block;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      width: 4px;
    }
  }
}

.owl-dots {
  .owl-dot {
    span {
      background-color: $grey5 !important;
      min-height: 22px;
      min-width: 22px;
      margin: 12px !important;
    }
    &.active {
      span {
        background: $gradient1 !important;
      }
    }
  }
}

.box-scripts {
  background-color: $white;
  border-radius: 19px;
  min-height: 169px;
  padding: 20px 25px 15px;
  text-align: center;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  svg {
    margin-bottom: 10px;
    height: 23px;
  }
  h5 {
    color: $grey4;
  }
  span {
    color: $grey3;
    display: block;
    font-size: 16px;
    text-align: left;
  }
  .progress {
    border-radius: 30px;
    height: 7px;
    .progress-bar {
      background-color: $purple;
    }
    &.orange {
      .progress-bar {
        background-color: $orange2;
      }
    }
    &.yellow {
      .progress-bar {
        background-color: $yellow;
      }
    }
    &.red {
      .progress-bar {
        background-color: $red;
      }
    }
    &.green {
      .progress-bar {
        background-color: $green2;
      }
    }
  }
  p {
    color: $grey4;
    font-size: 12px;
    margin: 0;
    text-align: left;
  }
}

.legend {
  color: $grey4;
  margin-bottom: 90px;
  svg {
    margin-right: 20px;
  }
}

.meetings {
  .tags {
    background-color: $blue;
    border-radius: 5px;
    color: $white;
    font-weight: $bold;
    line-height: 28px;
    max-width: 82px;
    text-align: center;
    width: 100%;
  }
}

.captalize {
  text-transform: capitalize;
}
</style>
