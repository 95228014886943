<template>
  <div>
    <b-row>
      <b-col>
        <h4>Estudante</h4>
      </b-col>
    </b-row>
    <div class="info-students box" v-if="formEnroll.EnrollmentStudent">
      <div class="user-highlight">
        <img
          :src="formEnroll.EnrollmentStudent.photo"
          class="image-rounded"
          v-if="
            formEnroll.EnrollmentStudent && formEnroll.EnrollmentStudent.photo
          "
        />
        <img
          src="../../assets/images/default-user.png"
          class="image-rounded"
          v-else
        />
        <p>
          Nome:<br />
          <strong>{{ formEnroll.EnrollmentStudent.name }}</strong>
        </p>
      </div>
      <b-row>
        <b-col v-if="formEnroll.EnrollmentStudent !== undefined" col="3">
          <p>
            Data de Nascimento:
            <strong>{{
              formEnroll.EnrollmentStudent.birth_date | formatDate
            }}</strong>
            <br />Sexo:
            <strong>{{ formEnroll.EnrollmentStudent.gender }}</strong>
            <br />Nacionalidade:
            <strong>{{ formEnroll.EnrollmentStudent.nationality }}</strong>
            <br />Naturalidade:
            <strong>{{ formEnroll.EnrollmentStudent.country_origin }}</strong>
            <br />Cor:
            <strong>{{ formEnroll.EnrollmentStudent.skin_color }}</strong>
            <br />Ciclo:
            <strong v-if="formEnroll.EnrollmentStudent">{{ cycle }}</strong>
            <br />Ano:
            <strong>{{ formEnroll.EnrollmentStudent.year_id }}</strong
            ><br />
            Guarda:
            <strong>{{
              String(formEnroll.EnrollmentStudent.guardian).toUpperCase()
            }}</strong>
            <br />
            <span v-if="formEnroll.EnrollmentStudent.previous_school">
              Escola anterior:
              <strong>{{
                formEnroll.EnrollmentStudent.previous_school
              }}</strong>
              <br />
            </span>
          </p>
        </b-col>
        <b-col col="3">
          <p
            v-if="
              formEnroll.EnrollmentStudent !== undefined &&
              formEnroll.EnrollmentStudent.Address
            "
          >
            <strong>Endereço:</strong>
            <br />
            Rua:
            <strong>{{ formEnroll.EnrollmentStudent.Address.street }}</strong>
            <br />Número:
            <strong>{{ formEnroll.EnrollmentStudent.Address.number }}</strong>
            <br />Bairro:
            <strong>{{
              formEnroll.EnrollmentStudent.Address.neighborhood
            }}</strong>
            <br />Cidade:
            <strong>{{ formEnroll.EnrollmentStudent.Address.city }}</strong>
            <br />Complemento:
            <strong>{{
              formEnroll.EnrollmentStudent.Address.complement
            }}</strong>
            <br />CEP:
            <strong>{{
              formEnroll.EnrollmentStudent.Address.cep
                ? formEnroll.EnrollmentStudent.Address.cep
                : "Não disponível"
            }}</strong>
            <br />Telefone 1:
            <strong>{{ formEnroll.EnrollmentStudent.phone1 }}</strong>
            <br />
            <span v-if="formEnroll.EnrollmentStudent.phone2">
              Telefone 2:
              <strong>{{ formEnroll.EnrollmentStudent.phone2 }}</strong>
              <br />
            </span>
            Email:
            <strong>{{ formEnroll.EnrollmentStudent.email }}</strong>
          </p>
        </b-col>
        <b-col v-if="formEnroll.EnrollmentStudent != undefined" col="3">
          <p>
            Certidão:
            <strong>{{
              formEnroll.EnrollmentStudent.birth_certificate
            }}</strong>
            <br />RG:
            <strong>{{ formEnroll.EnrollmentStudent.rg }}</strong>
            <br />CPF:
            <strong>{{ formEnroll.EnrollmentStudent.cpf }}</strong>
            <br />Usuário:
            <strong>{{ formEnroll.EnrollmentStudent.email }}</strong>
          </p>
        </b-col>
      </b-row>
    </div>

    <b-row>
      <b-col>
        <h4>Contratante(s)</h4>
      </b-col>
    </b-row>
    <div class="info-students box">
      <div
        v-for="(contractor, index) in formEnroll.Contractor"
        :key="`${contractor.id}-${index}-contractor`"
        class="box-item"
      >
        <b-row>
          <b-col class="mb-4">
            <h6>{{ contractor.type }} - {{ contractor.relationship }}</h6>
          </b-col>
        </b-row>
        <b-row>
          <b-col v-if="contractor.photo" cols="3">
            <img :src="contractor.photo" class="image-rounded" />
          </b-col>
          <b-col v-else cols="2">
            <img
              src="../../assets/images/default-user.png"
              class="image-rounded"
            />
          </b-col>
          <b-col cols="3">
            <p>
              Nome:
              <strong>{{ contractor.name }}</strong>
              <br />
              Data de Nascimento:
              <strong>{{ contractor.birth_date | formatDate }}</strong>
              <br />Ocupação:
              <strong>{{ contractor.occupation }}</strong>
              <br />Local:
              <strong>{{ contractor.workplace }}</strong>
              <br />Nacionalidade:
              <strong>{{ contractor.nationality }}</strong>
              <br />Naturalidade:
              <strong>{{ contractor.country_origin }}</strong>
              <br />
              <span v-if="contractor.talents">
                Atividade:
                <strong>{{ contractor.talents }}</strong>
                <br />
              </span>
              Guarda:
              <strong>{{ contractor.is_guardian ? "Sim" : "Não" }}</strong>
              <br />
              Autoriza fornecimento de email a APAES:
              <strong>{{ contractor.allow_email | booleanString }}</strong>
            </p>
          </b-col>
          <b-col v-if="contractor.Address" cols="3">
            <p>
              <template>
                <strong>Endereço:</strong>
                <br />
                Rua:
                <strong>{{ contractor.Address.street }}</strong>
                <br />Número:
                <strong>{{ contractor.Address.number }}</strong>
                <br />Bairro:
                <strong>{{ contractor.Address.neighborhood }}</strong>
                <br />Cidade: <strong>{{ contractor.Address.city }}</strong>
                <br />Complemento:
                <strong>{{ contractor.Address.complement }}</strong>
                <br />CEP:
                <strong>{{
                  contractor.Address.cep
                    ? contractor.Address.cep
                    : "Não disponível"
                }}</strong>
                <br />
              </template>
              Telefone 1:
              <strong>{{ contractor.phone1 }}</strong>
              <br />
              <span v-if="contractor.phone2">
                Telefone 2:
                <strong>{{ contractor.phone2 }}</strong>
                <br />
              </span>
              Email:
              <strong>{{ contractor.email }}</strong>
              <br />
            </p>
          </b-col>

          <b-col cols="3">
            <p>
              RG:
              <strong>{{ contractor.rg }}</strong>
              <br />CPF:
              <strong>{{ contractor.cpf }}</strong>
              <br />Usuário:
              <strong>{{ contractor.email }}</strong>
              <br />É contato assuntos de cobrança?
              <strong>
                {{ contractor.is_financial_contact | booleanString }}
              </strong>
              <br />É contato assuntos escolares?
              <strong>
                {{ contractor.is_pedagogical_contact | booleanString }}
              </strong>
            </p>
          </b-col>
        </b-row>
      </div>
    </div>
    <b-row>
      <b-col>
        <h4>Filiação</h4>
      </b-col>
    </b-row>
    <div class="info-students box">
      <div
        v-for="(filiation, index) in formEnroll.Filiation"
        :key="`${filiation.id}-${index}-filiation`"
        class="box-item"
      >
        <div
          v-if="
            (filiation.email == 'not_applicable@notapplicable.com' &&
              filiation.name != 'Not Applicable') ||
            (filiation.email != 'not_applicable@notapplicable.com' &&
              filiation.name != 'Not Applicable')
          "
        >
          <b-row>
            <b-col class="mb-4">
              <h4
                v-if="
                  filiation.email == 'not_applicable@notapplicable.com' &&
                  filiation.name == 'Não Há'
                "
              >
                Não há
              </h4>
              <h4 v-else>
                {{ filiation.relationship }}
                {{
                  filiation.email == "not_applicable@notapplicable.com" &&
                  filiation.name != "Not Applicable"
                    ? "- Falecido(a)"
                    : ""
                }}
              </h4>
              <div
                v-if="
                  (filiation.email == 'not_applicable@notapplicable.com' &&
                    filiation.name != 'Not Applicable') ||
                  (filiation.email != 'not_applicable@notapplicable.com' &&
                    filiation.name != 'Not Applicable')
                "
              >
                <p v-if="filiation.name != 'Não Há'">
                  Nome:
                  <strong>{{ filiation.name }}</strong>
                </p>
              </div>
            </b-col>
            <div class="w-100"></div>
            <div v-if="filiation.email != 'not_applicable@notapplicable.com'">
              <b-col v-if="filiation.photo">
                <img :src="filiation.photo" class="image-rounded" />
              </b-col>
              <b-col v-else>
                <img
                  src="../../assets/images/default-user.png"
                  class="image-rounded"
                />
              </b-col>
            </div>
            <b-col>
              <div v-if="filiation.email != 'not_applicable@notapplicable.com'">
                <p>
                  Data de Nascimento:
                  <strong>{{ filiation.birth_date | formatDate }}</strong>
                  <br />
                  Ocupação:
                  <strong>{{ filiation.occupation }}</strong>
                  <br />Local:
                  <strong>{{ filiation.workplace }}</strong>
                  <br />Nacionalidade:
                  <strong>{{ filiation.nationality }}</strong>
                  <br />Naturalidade:
                  <strong>{{ filiation.country_origin }}</strong>
                  <br />
                  <span v-if="filiation.talents">
                    Atividade:
                    <strong>{{ filiation.talents }}</strong>
                    <br />
                  </span>
                  Autoriza fornecimento de email a APAES?
                  <strong>{{ filiation.allow_email | booleanString }}</strong>
                </p>
              </div>
            </b-col>

            <b-col v-if="filiation.email != 'not_applicable@notapplicable.com'">
              <p v-if="filiation.Address">
                <template>
                  <strong>Endereço:</strong>
                  <br />
                  Rua:
                  <strong>{{ filiation.Address.street }}</strong>
                  <br />Número:
                  <strong>{{ filiation.Address.number }}</strong>
                  <br />Bairro:
                  <strong>{{ filiation.Address.neighborhood }}</strong>
                  <br />Cidade: <strong>{{ filiation.Address.city }}</strong>
                  <br />Complemento:
                  <strong>{{ filiation.Address.complement }}</strong>
                  <br />CEP:
                  <strong>{{
                    filiation.Address.cep
                      ? filiation.Address.cep
                      : "Não disponível"
                  }}</strong>
                  <br />
                </template>
                Telefone 1:
                <strong>{{ filiation.phone1 }}</strong>
                <br />
                <span v-if="filiation.phone2">
                  Telefone 2:
                  <strong>{{ filiation.phone2 }}</strong>
                  <br />
                </span>
                Email:
                <strong>{{ filiation.email }}</strong>
                <br />
              </p>
            </b-col>

            <b-col v-if="filiation.email != 'not_applicable@notapplicable.com'">
              <p>
                RG:
                <strong>{{ filiation.rg }}</strong>
                <br />CPF:
                <strong>{{ filiation.cpf }}</strong>
                <br />Usuário:
                <strong>{{ filiation.email }}</strong>
                <br />É contato assuntos de cobrança?
                <strong>
                  {{ filiation.is_financial_contact | booleanString }}
                </strong>
                <br />É contato assuntos escolares?
                <strong>
                  {{ filiation.is_pedagogical_contact | booleanString }}
                </strong>
              </p>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
    <div class="info-students box" v-if="formEnroll.EnrollmentComplement">
      <b-row>
        <b-col v-if="formEnroll.EnrollmentComplement !== undefined">
          <h4 class="mb-4">Informações complementares</h4>
          <p>
            As vacinas estão em dia a data presente?
            <strong>{{
              formEnroll.EnrollmentComplement.vaccines_up_to_date
                | booleanString
            }}</strong>
            <br />O aluno possui alguma restrição alimentar?
            <strong>
              {{
                formEnroll.EnrollmentComplement.food_restriction | booleanString
              }}
              <span v-if="formEnroll.EnrollmentComplement.food_restriction"
                >,
                {{
                  formEnroll.EnrollmentComplement.food_restriction_description
                }}</span
              >
            </strong>
            <br />O aluno possui algum problema crônico?
            <strong>
              {{
                formEnroll.EnrollmentComplement.health_problem | booleanString
              }}
              <span v-if="formEnroll.EnrollmentComplement.health_problem"
                >,
                {{
                  formEnroll.EnrollmentComplement.health_problem_description
                }}</span
              >
            </strong>
            <br />Tem alguma limitação?
            <strong>
              {{ formEnroll.EnrollmentComplement.limitation | booleanString }}
              <span v-if="formEnroll.EnrollmentComplement.limitation"
                >,
                {{
                  formEnroll.EnrollmentComplement.limitation_description
                }}</span
              >
            </strong>
            <br />O aluno tem algum acompanhamento especializado?
            <strong>
              {{
                formEnroll.EnrollmentComplement.special_accompaniment
                  | booleanString
              }}
              <span v-if="formEnroll.EnrollmentComplement.special_accompaniment"
                >,
                {{
                  formEnroll.EnrollmentComplement
                    .special_accompaniment_description
                }}</span
              >
            </strong>
            <br />O aluno necessita de acompanhamento individual na escola?
            <strong>
              {{
                formEnroll.EnrollmentComplement.individual_accompaniment
                  | booleanString
              }}
              <span
                v-if="formEnroll.EnrollmentComplement.individual_accompaniment"
                >,
                {{
                  formEnroll.EnrollmentComplement
                    .individual_accompaniment_description
                }}</span
              >
            </strong>
            <br />O aluno necessita de algum cuidado especial por motivos de
            saúde?
            <strong>
              {{ formEnroll.EnrollmentComplement.special_care | booleanString }}
              <span v-if="formEnroll.EnrollmentComplement.special_care"
                >,
                {{
                  formEnroll.EnrollmentComplement.special_care_description
                }}</span
              >
            </strong>
            <br />O aluno manifesta alergia ou incompatibilidade a algum
            medicamento?
            <strong>
              {{ formEnroll.EnrollmentComplement.medication | booleanString }}
              <span v-if="formEnroll.EnrollmentComplement.medication"
                >,
                {{
                  formEnroll.EnrollmentComplement.medication_description
                }}</span
              >
            </strong>
            <br />O aluno possui algum convênio médico?
            <strong>
              {{ formEnroll.EnrollmentComplement.healthcare | booleanString }}
              <span v-if="formEnroll.EnrollmentComplement.healthcare"
                >,
                {{
                  formEnroll.EnrollmentComplement.healthcare_description
                }}</span
              >
            </strong>
            <br />
          </p>
        </b-col>

        <b-col v-if="formEnroll.EnrollmentComplement !== undefined">
          <h4 class="mb-4">
            Em caso de emergência, não sendo localizados os responsáveis,
            entraremos em contato com:
          </h4>
          <p>
            Nome:
            <strong>{{ formEnroll.EnrollmentComplement.contact1 }}</strong>
            <br />Telefone:
            <strong>{{
              formEnroll.EnrollmentComplement.contact1_phone
            }}</strong>
            <br />Nome:
            <strong>{{ formEnroll.EnrollmentComplement.contact2 }}</strong>
            <br />Telefone:
            <strong>{{
              formEnroll.EnrollmentComplement.contact2_phone
            }}</strong>
            <br />Forma como o aluno retornará a sua residência:
            <strong>{{
              formEnroll.EnrollmentComplement.return_to_home
            }}</strong>
          </p>

          <template
            v-if="formEnroll.EnrollmentComplement.return_to_home != 'Sozinho'"
          >
            <h4 class="mb-4">
              Pessoas autorizadas a acompanhar o(a) aluno(a) na saída:
            </h4>

            <ul
              v-for="(contact, index) of formEnroll.EnrollmentComplement
                .EnrollmentComplementContacts"
              :key="`${contact.id}-${index}` - contact"
            >
              <li>
                <h4>{{ index + 1 }}° pessoa:</h4>

                <b-col v-if="contact.photo" cols="3">
                  <img :src="contact.photo" class="image-rounded" />
                </b-col>
                <b-col v-else cols="2">
                  <img
                    src="../../assets/images/default-user.png"
                    class="image-rounded"
                  />
                </b-col>
                Nome: <strong>{{ contact.name }}</strong> <br />Relação com
                aluno:
                <strong>{{ contact.relationship }}</strong>
                <br />Telefone 1:
                <strong>{{ contact.number1 }}</strong>
                <br />
                <span v-if="contact.number2">
                  Telefone 2:
                  <strong>{{ contact.number2 }}</strong>
                  <br />
                </span>
              </li>
            </ul>
          </template>
        </b-col>
      </b-row>
    </div>

    <div class="info-students box" v-if="formEnroll.EnrollmentAuthorization">
      <b-row>
        <b-col v-if="formEnroll.EnrollmentAuthorization !== undefined">
          <h4>Autorizações</h4>
          <p>
            <strong>1</strong> - Fica o aluno antecipadamente autorizado,
            independentemente de permissão específica de excursões - parte
            integrante do projeto pedagógico desta escola - desde que realizadas
            dentro do horário normal de aulas e dentro dos limites do município.
            <br />
            <strong class="strong_padding">
              •
              {{ formEnroll.EnrollmentAuthorization.excursion | booleanString }}
            </strong>
          </p>
          <p v-if="rdaUnit != 'POUSO_ALEGRE'">
            <strong>2</strong> - Fica o aluno, matriculado no Ensino Médio,
            autorizado a sair desacompanhado das dependências da Escola da Serra
            durante o horário de recreio.
            <br />
            <strong class="strong_padding">
              •
              {{
                formEnroll.EnrollmentAuthorization.leave_school_break
                  | booleanString
              }}
            </strong>
          </p>
          <p v-if="rdaUnit != 'POUSO_ALEGRE'">
            <strong>3</strong> - Fica o aluno, matriculado no Ensino Médio,
            autorizado a sair desacompanhado da Escola da Serra antes do término
            do horário normal das aulas caso o encerramento das atividades
            escolares seja, eventualmente, antecipado.
            <br />
            <strong class="strong_padding">
              •
              {{
                formEnroll.EnrollmentAuthorization.leave_school_antecipated
                  | booleanString
              }}
            </strong>
          </p>
          <p>
            <strong>4</strong> - Fica o aluno autorizado a tomar banho de
            ducha/mangueira independentemente de autorização específica.
            <br />
            <strong class="strong_padding">
              •
              {{ formEnroll.EnrollmentAuthorization.shower | booleanString }}
            </strong>
          </p>
          <p v-if="rdaUnit != 'POUSO_ALEGRE'">
            <strong>5</strong> - Fica o aluno, matriculado no 3° Ciclo ou Ensino
            Médio, autorizado a sair desacompanhado das dependências da Escola
            da Serra durante o horário de almoço.
            <br />
            <strong class="strong_padding">
              •
              {{
                formEnroll.EnrollmentAuthorization.leave_school_lunch
                  | booleanString
              }}
            </strong>
          </p>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import httpRda from "../../http-rda";
import moment from "moment";

export default {
  props: {
    showMessage: {
      type: Boolean,
      default: true,
    },
    formEnroll: {
      type: Object,
    },
  },
  data() {
    return {
      rdaUnit: process.env.VUE_APP_RDA_UNIT,
      cycle: null,
    };
  },
  computed: {
    enrollment_id() {
      return this.$route.params.id;
    },
  },

  methods: {
    async getCycleDesignation() {
      if (
        this.formEnroll.EnrollmentStudent &&
        this.formEnroll.EnrollmentStudent.cycle_id
      ) {
        this.cycle = await httpRda
          .get(`/cycles/${this.formEnroll.EnrollmentStudent.cycle_id}`)
          .then((response) => response.data.cycle.designation);
      }
    },
  },

  mounted() {
    this.getCycleDesignation();
  },

  created() {},

  filters: {
    booleanString(str) {
      if (str === true || str === "Sim" || str === "yes") return "Sim";
      else if (str === false || str === "Não" || str === "no") return "Não";
      else if (str === "na") return "Não se aplica";
      else return str;
    },
    formatDate(dateToBeFormatted) {
      return moment(dateToBeFormatted).format("DD/MM/YYYY");
    },
  },
};
</script>

<style lang="scss">
h4 {
  text-transform: uppercase;
}
.user-highlight {
  width: 100%;
  border-bottom: 2px solid #fafafa;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  padding-bottom: 20px;
  margin-bottom: 20px;

  p {
    margin-bottom: 0;
  }
}
.box {
  background: #fff;
  padding: 30px;
  margin-bottom: 60px;
  border-radius: 10px;
  box-shadow: 0 5px 7px rgba(0, 0, 0, 0.1);

  .box-item {
    &:last-of-type {
      border: 0;
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }
}
.box-item {
  border-bottom: 4px solid #fafafa;
  margin-bottom: 20px;
  padding-bottom: 20px;
}
.image-rounded {
  width: 150px;
  border-radius: 50%;
}

.strong_padding {
  padding-left: 16px;
}
</style>
