<template>
  <b-form-group :label="label">
    <multiselect
      :value="computedValue"
      :options="calendars"
      :multiple="false"
      :close-on-select="true"
      :clear-on-select="false"
      :preserve-search="true"
      placeholder="Selecione"
      label="designation"
      track-by="id"
      selectLabel=""
      selectedLabel=""
      deselectLabel=""
      @select="callback"
    >
      <template slot="tag" slot-scope="{ option }">
        <span class="custom__tag">
          <span>{{ option.name }}</span>
        </span>
      </template>
      <template slot="clear" slot-scope="props">
        <div
          class="multiselect__clear"
          @mousedown.prevent.stop="clearAll(props.search)"
        ></div>
      </template>
      <span slot="noResult">Nada encontrado</span>
    </multiselect>
    <div v-if="advice && advice.length > 0">
      <span class="text-muted">{{ advice }}</span>
    </div>
  </b-form-group>
</template>

<script>
import DataService from "@/services/calendars.service";

export default {
  props: ["value", "label", "callback", "advice"],
  data() {
    return {
      options: "",
      calendars: [],
    };
  },
  created() {
    this.getCalendars();
  },
  methods: {
    getCalendars() {
      DataService.getAll()
        .then((response) => {
          this.calendars = response.data.calendars;
        })
        .catch((error) => {
          return error;
        });
    },
  },
  computed: {
    computedValue() {
      if (typeof this.value === "number") {
        return this.calendars.find((c) => c.id == this.value);
      } else {
        return this.value;
      }
    },
  },
};
</script>
