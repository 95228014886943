<template>
  <svg
    width="27"
    height="18"
    viewBox="0 0 27 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.5 3.75C13.0781 3.79688 12.4219 3.89062 12 3.98438C12.1875 4.3125 12.3281 4.92188 12.375 5.25C12.375 6.70312 11.1562 7.875 9.75 7.875C9.375 7.875 8.76562 7.73438 8.48438 7.54688C8.34375 7.96875 8.25 8.57812 8.25 9C8.25 11.9062 10.5938 14.25 13.5 14.25C16.4062 14.25 18.75 11.9062 18.75 9C18.75 6.14062 16.4062 3.75 13.5 3.75ZM26.8125 8.34375C24.2812 3.375 19.2188 0 13.5 0C7.73438 0 2.67188 3.375 0.140625 8.34375C0.046875 8.53125 0 8.8125 0 9.04688C0 9.23438 0.046875 9.51562 0.140625 9.70312C2.67188 14.6719 7.73438 18 13.5 18C19.2188 18 24.2812 14.6719 26.8125 9.70312C26.9062 9.51562 26.9531 9.23438 26.9531 9C26.9531 8.8125 26.9062 8.53125 26.8125 8.34375ZM13.5 15.75C8.85938 15.75 4.59375 13.1719 2.34375 9C4.59375 4.82812 8.85938 2.25 13.5 2.25C18.0938 2.25 22.3594 4.82812 24.6094 9C22.3594 13.1719 18.0938 15.75 13.5 15.75Z"
      fill="url(#paint0_linear)"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="-8.235"
        y1="8.88119"
        x2="41.85"
        y2="8.88119"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#EF6549" />
        <stop offset="1" stop-color="#B23066" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {

}
</script>

<style lang="sass">
</style>