<template>
  <b-container class="container-rda">
    <b-row>
      <b-col>
        <b-breadcrumb :items="breadcrumb"></b-breadcrumb>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <h2 class="purple bold">Tipo de cadastro</h2>
      </b-col>
    </b-row>

    <b-row class="mt-50">
      <b-col>
        <h3 class="bold">Como deseja fazer o cadastro?</h3>
      </b-col>
    </b-row>

    <b-row class="mt-50">
      <b-col xs="12" sm="6" md="6" lg="3" xl="3">
        <router-link
          :to="`/comunidade/alunos/dados-do-aluno/1/dados-do-aluno`"
          class="btn-type-register"
        >
          <b-icon-pencil-square />
          <span>Cadastrar manualmente</span>
        </router-link>
      </b-col>
      <b-col xs="12" sm="6" md="6" lg="3" xl="3">
        <button class="btn-type-register" @click="modal = true">
          <b-icon-upload />
          <span>Importar dados</span>
        </button>
      </b-col>
    </b-row>
    <b-modal v-model="modal" hide-header hide-footer no-close-on-backdrop>
      <div class="title-modal" v-if="!uploading">
        <h4>Novo cadastro</h4>
        <b-icon-x-circle-fill @click="closeModal" v-if="!uploading" />
      </div>
      <div v-if="!uploading">
        <UploadFiles :noUploadPreview="true" v-model="importData" />
        <div class="btn-group">
          <b-button :disabled="importData.length <= 0 ? true : false" @click="submitFile">Importar</b-button>
        </div>
      </div>
      <div class="loading-file" v-if="uploading">
        <b-spinner label="Loading..."></b-spinner>
        <span>Aguarde enquanto o arquivo é importado.</span>
      </div>
    </b-modal>
  </b-container>
</template>
<script>
import UploadFiles from "@/components/inputs/UploadFiles.vue";

export default {
  name: "tipo-de-cadastro",
  components: {
    UploadFiles
  },
  data() {
    return {
      modal: false,
      importData: [],
      uploading: false,
      breadcrumb: [
        {
          text: "Comunidade",
          href: "/comunidade",
        },
        {
          text: "Alunos",
          href: "/comunidade/alunos",
        },
        {
          text: "Tipo de cadastro",
          href: "/comunidade/tipo-de-cadastro",
          active: true,
        },
      ],
    };
  },

  computed: {
    //
  },

  methods: {
    openModal() {
      this.modal = true;
    },
    closeModal() {
      this.modal = false;
      this.importData = [];
    },
    submitFile() {
      this.uploading = true;

      setTimeout(() => (
        this.$router.push({path: '/comunidade/alunos/dados-do-aluno/1?step=1'})
      ), 1000)
    }
  },

  watch: {
    //
  },
};
</script>

<style lang="scss">
@import "@/assets/css/variables.scss";

.btn-type-register {
  text-align: center;
  background: $green;
  width: 100%;
  height: 198px;
  border: 6px solid transparent;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  row-gap: 32px;
  box-align: border-box;
  box-shadow: none;
  transition: 0.2s all ease-in-out;

  &:hover {
    border-color: #66aea3;
    transition: 0.2s all ease-in-out;
  }

  svg {
    width: 32px;
    height: 32px;

    path {
      fill: #fff;
    }
  }

  span {
    font-size: 18px;
    font-weight: $bold;
    color: #ffffff;
  }
}

.title-modal {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;

  h4 {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #000000;
  }

  svg {
    width: 24px;
    height: 24px;
    fill: #b3b3b3;
    cursor: pointer;

    &:hover {
      fill: #eb4d4d;
      transition: 0.2s all ease-in-out;
    }
  }
}

.btn-group {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 40px;

  .btn {
    font-size: 20px;
    flex: 0 0 auto!important;
    width: 142px;

    &:disabled {
      color: #b3b3b3;
      background: #e6e6e6 !important;
      pointer-events: none;
      border: 0;
    }
  }
}

.loading-file{
  min-height:250px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction:column;

  .spinner-border{
    color:#000;
    width:32px;
    height:32px;
    margin-bottom:1rem;
  }

  span{
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
    color: $purple;
  }
}
</style>
