<template>
  <div class="home">
    <template>
      <b-container class="container-rda">
        <b-row>
          <b-col>
            <b-breadcrumb :items="breadcrumb"></b-breadcrumb>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <h2 class="purple bold">
              Ano letivo
              <span class="qty">({{ count }} listadas)</span>
            </h2>
          </b-col>
        </b-row>
        <b-row class="mt-50">
          <b-col md="4">
            <SelectCalendar
              label="Ano"
              placeholder="Selecione"
              v-model="calendar"
              :callback="callbackCalendars"
            ></SelectCalendar>
            <span
              class="clear-field"
              v-if="this.calendar != null"
              @click="clearCalendar"
            >Limpar filtro</span>
          </b-col>
          <b-col>
            <b-form-group label="Buscar Aluno">
              <b-input-group-prepend>
                <b-form-input
                  type="search"
                  placeholder="Digite aqui"
                  v-model="searchTitle"
                  v-on:keyup.enter="
                    page = 1;
                    getList();
                  "
                ></b-form-input>
                <b-input-group-text
                  class="clear-fields"
                  v-if="searchTitle != ''"
                  v-b-tooltip.hover
                  title="Limpar Pesquisa"
                >
                  <b-icon icon="x" @click="clearFields" />
                </b-input-group-text>
              </b-input-group-prepend>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <div class="order-grid-box">
              <p class="order-list" :class="{ desc: descOrder }" v-on:click="orderName">
                Ordenar
                <OrderNameIcon />
              </p>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-container fluid>
              <b-row class="column-list" v-if="enrollments.length > 0 && isRequestSend">
                <b-col md="4">
                  <p class="bold">Aluno</p>
                </b-col>
                <b-col>
                  <p class="bold">Matrícula</p>
                </b-col>
                <b-col>
                  <p class="bold">Data Pedido</p>
                </b-col>
                <b-col md="3">
                  <p class="bold">Início frequência escolar</p>
                </b-col>
                <b-col>
                  <p class="bold">Ano letivo</p>
                </b-col>
              </b-row>
            </b-container>
            <b-container fluid>
              <b-row
                align-v="center"
                class="list-orders"
                v-for="enrollment in orderbyName"
                :key="enrollment.id"
              >
                <b-col md="4">
                  <p>{{ enrollment.EnrollmentStudent.name }}</p>
                </b-col>
                <b-col>
                  <p>{{ enrollment.id }}</p>
                </b-col>
                <b-col>
                  <p>{{ enrollment.created_at | date }}</p>
                </b-col>
                <b-col md="3">
                  <p>{{ enrollment.printed_at | date }}</p>
                </b-col>
                <b-col>
                  <p>{{ getCalendarName(enrollment.calendar_id) }}</p>
                </b-col>
              </b-row>
              <b-row
                align-v="center"
                class="list-teacher"
                v-if="enrollments.length <= 0 && isRequestSend"
              >
                <b-col>Nenhum resultado encontrado</b-col>
              </b-row>
              <b-row align-v="center" class="list-teacher" v-if="!isRequestSend">
                <b-col>Carregando</b-col>
              </b-row>
              <b-row class="align-center" v-else-if="count > perPage && isRequestSend">
                <b-pagination
                  v-model="page"
                  :per-page="perPage"
                  :total-rows="count"
                  @change="changePage"
                  aria-controls="teachersList"
                  last-text
                  first-text
                >
                  <template #prev-text>
                    <LeftArrowIcon class="left" />
                  </template>
                  <template #next-text>
                    <LeftArrowIcon />
                  </template>
                </b-pagination>
              </b-row>
            </b-container>
          </b-col>
        </b-row>
      </b-container>
    </template>
  </div>
</template>

<script>
import DataService from "@/services/enrollments.service";
import DataServiceCalendars from "@/services/calendars.service";

import LeftArrowIcon from "@/components/svg/LeftArrowIcon.vue";
import OrderNameIcon from "@/components/svg/OrderNameIcon.vue";
import SelectCalendar from "@/components/inputs/SelectCalendar.vue";

export default {
  name: "ano-letivo",
  components: {
    LeftArrowIcon,
    OrderNameIcon,
    SelectCalendar,
  },
  data() {
    return {
      enrollments: [],
      descOrder: false,
      isRequestSend: false,

      calendar: null,
      searchTitle: "",

      page: this.$route.params.page || 1,
      count: 0,
      perPage: 20,

      breadcrumb: [
        {
          text: "Secretaria",
          href: "/secretaria",
        },
        {
          text: "Ano letivo",
          href: "/ano-letivo",
          active: true,
        },
      ],
    };
  },

  filters: {
    date(date) {
      try {
        var d = new Date(date.split());
        var year = d.getFullYear().toString();
        var month = (d.getMonth() + 1).toString().padStart(2, "0");
        var day = d.getUTCDate().toString().padStart(2, "0");

        return day + "/" + month + "/" + year;
      } catch (error) {
        return date;
      }
    },
  },

  computed: {
    orderbyName() {
      const newList = [...this.enrollments];
      for (var i = 0; i < newList.length; i++) {
        if (newList[i].EnrollmentStudent === null) {
          newList.splice(i, 1);
          i--;
        }
      }

      newList.sort((a, b) => {
        const order = this.descOrder ? [1, -1] : [-1, 1];

        if (a.EnrollmentStudent.name < b.EnrollmentStudent.name)
          return order[0];
        else if (a.EnrollmentStudent.name > b.EnrollmentStudent.name)
          return order[1];

        return 0;
      });

      return newList;
    },
  },

  methods: {
    getRequestParams(searchTitle, page, perPage, calendar) {
      let params = {};

      if (searchTitle) {
        params["search"] = searchTitle;
      }

      if (page) {
        params["page"] = page;
      }

      if (perPage) {
        params["limit"] = perPage;
      }

      if (calendar) {
        params["calendar_id"] = calendar;
      }

      return params;
    },

    getList() {
      const params = this.getRequestParams(
        this.searchTitle,
        this.page,
        this.perPage,
        this.calendar
      );

      DataService.getAll(params)
        .then((response) => {
          this.enrollments = response.data.enrollments;
          this.count = response.data.count;
          this.isRequestSend = true;
        })
        .catch((error) => {
          return error.data;
        });
    },

    getParams(params) {
      DataServiceCalendars.getAll(params)
        .then((response) => {
          this.calendars = response.data.calendars;
        })
        .catch((error) => {
          return error.data;
        });
    },

    orderName() {
      this.descOrder = !this.descOrder;
    },

    changePage(page) {
      this.page = page;
      this.getList();
      window.scrollTo({ top: 0, behavior: "smooth" });
    },

    callbackCalendars({ id }) {
      this.calendar = id;
      this.currentPage = 1;
      this.getList();
    },

    clearFields() {
      this.searchTitle = "";
      this.currentPage = 1;
      this.getList();
    },

    clearCalendar() {
      this.calendar = null;
      this.currentPage = 1;
      this.getList();
    },

    getCalendarName(id) {
      if (this.calendars) {
        const calendars = this.calendars.find((c) => c.id === id);
        return calendars ? calendars.designation : "-";
      }
    },
  },

  created() {
    this.getList();
    this.getParams();
  },
};
</script>

<style lang="scss" scoped>

@import "@/assets/css/variables.scss";

.list-orders {
  background-color: $white;
  border: 1px solid $grey5;
  border-radius: 10px;
  margin-bottom: 15px;
  min-height: 80px;
  margin-right: -12px;
  &:last-child {
    margin-bottom: 0px;
  }
  &:hover {
    border-color: transparent;
    background-image: linear-gradient($white, $white), radial-gradient(circle at top left, $orange, $purple);
    background-origin: border-box;
    background-clip: content-box, border-box;
  }
  p {
    margin: 0
  }
  .tags-purple {
    background-color: $purple;
    border-radius: 5px;
    color: $white;
    font-weight: $bold;
    line-height: 28px;
    max-width: 82px;
    text-align: center;
  }
}

.column-list {
  color: $grey1;
}

.flex-right {
  align-items: center;
  display: flex;
  justify-content: flex-end;
}

.qty-order {
  background-color: $purple;
  border-radius: 5px;
  color: $white;
  font-weight: $bold;
  line-height: 28px;
  max-width: 100px;
  margin: 0;
  text-align: center;
  width: 100%;
}

</style>