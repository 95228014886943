<template>
  <b-form-group :description="description">
    <div>
      <label for="label">{{ label }}</label>
      <input
        :v-model="vmodel"
        :v-validate="vvalidate"
        :type="type"
        :name="name"
        :v-mask="vmask"
        :placeholder="placeholder"
        :value="value"
        :required="required"
        @input="updateValue($event.target.value)"
        class="form-control"
        v-bind="$attrs"
        @change="onChange"
      />
    </div>
  </b-form-group>
</template>

<script>
export default {
  props: {
    value: undefined,
    vmodel: undefined,
    vvalidate: undefined,
    type: undefined,
    name: undefined,
    label: undefined,
    vmask: undefined,
    description: undefined,
    placeholder: undefined,
    required: {
      type: Boolean,
      default: true,
    },
    onChange: undefined,
  },
  data() {
    return {
      mask: "",
    };
  },
  methods: {
    updateValue(value) {
      this.$emit("input", value);
    },
  },
};
</script>
