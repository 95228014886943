var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{staticClass:"container-rda"},[_c('b-row',[_c('b-col',[_c('b-breadcrumb',{attrs:{"items":_vm.breadcrumb}})],1)],1),_c('b-row',[_c('b-col',[_c('h2',{staticClass:"purple bold"},[_vm._v("Perfil do professor")])])],1),_c('b-row',{staticClass:"teacher-profile mt-50"},[_c('b-col',{attrs:{"md":"2"}},[_c('img',{staticClass:"w-100",attrs:{"src":_vm.teacher.User.photo,"alt":""}})]),_c('b-col',[_c('b-row',[_c('b-col',[_c('b-form-group',{staticClass:"required",attrs:{"description":""}},[_c('label',{attrs:{"for":"label"}},[_vm._v("Nome")]),_c('b-form-input',{attrs:{"readonly":"","type":"text","placeholder":"","required":""},model:{value:(_vm.teacher.User.name),callback:function ($$v) {_vm.$set(_vm.teacher.User, "name", $$v)},expression:"teacher.User.name"}})],1)],1),_c('b-col',[_c('b-form-group',{staticClass:"required",attrs:{"description":""}},[_c('label',{attrs:{"for":"label"}},[_vm._v("E-mail")]),_c('b-form-input',{attrs:{"readonly":"","type":"text","placeholder":"","required":""},model:{value:(_vm.teacher.User.email),callback:function ($$v) {_vm.$set(_vm.teacher.User, "email", $$v)},expression:"teacher.User.email"}})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"required",attrs:{"description":""}},[_c('label',{attrs:{"for":"label"}},[_vm._v("Telefone")]),_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:(['(##) #####-####']),expression:"['(##) #####-####']"}],attrs:{"readonly":"","type":"text","placeholder":"(__) _____-____","required":""},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}})],1)],1)],1)],1)],1),_c('b-row',{staticClass:"mt-5",attrs:{"align-v":"center"}},[_c('b-col'),_c('b-col',{staticClass:"text-right"},[_c('b-button',{staticClass:"large blue outline",attrs:{"to":{
          name: 'professores',
          params: {
            cycle: this.$route.params.cycle,
            subject: this.$route.params.subject,
            page: this.$route.params.page,
          },
        }}},[_vm._v("Voltar")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }