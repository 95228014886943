<template>
  <div class="home">
    <b-container class="container-rda">
      <b-row>
        <b-col>
          <b-breadcrumb :items="breadcrumb"></b-breadcrumb>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <h2 class="purple bold">{{ this.value.EnrollmentStudent.name }}</h2>
        </b-col>
        <b-col class="text-right"></b-col>
      </b-row>

      <b-row class="row-buttons" style="padding-bottom: 20px">
        <b-col md="4">
          <router-link
            :to="{ name: 'listar-contratos' }"
            class="btn xlarge outline"
            >Voltar</router-link
          >
        </b-col>
      </b-row>

      <TemplateViewContract :formEnroll="value" />

      <b-row class="row-buttons">
        <b-col md="4">
          <router-link
            :to="{ name: 'listar-contratos' }"
            class="btn xlarge outline"
            >Voltar</router-link
          >
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import TemplateViewContract from "@/components/templates/TemplateViewContract";
import httpRda from "../../http-rda";

export default {
  name: "visualizar-contratos",
  components: {
    TemplateViewContract,
  },

  data() {
    return {
      value: {},
      breadcrumb: [
        {
          text: "Secretaria",
          href: "/secretaria",
        },
        {
          text: "Contratos",
          href: "/secretaria/listar-contratos",
        },
        {
          text: "Nome do Aluno",
          active: true,
        },
      ],
    };
  },

  computed: {
    ...mapGetters({
      student: "enrollment/getStudent",
      contractorA: "enrollment/getContractorA",
      contractorB: "enrollment/getContractorB",
      filiationA: "enrollment/getFiliationA",
      filiationB: "enrollment/getFiliationB",
      complement: "enrollment/getComplement",
      authorization: "enrollment/getAuthorization",
      finance: "enrollment/getFinance",
      enrollment: "enrollment/enrollment",
      cycle: "enrollment/getCycle",
    }),

    enrollment_id() {
      return this.$route.params.id;
    },
  },

  filters: {
    booleanString(str) {
      if (str === true || str === "Sim" || str === "yes") return "Sim";
      else if (str === false || str === "Não" || str === "no") return "Não";
      else if (str === "na") return "Não se aplica";
      else return str;
    },
  },

  methods: {
    ...mapActions({
      getEnrollment: "enrollment/getEnrollment",
    }),

    async getEnrollmentData() {
      await httpRda
        .get(`/enrollments/${this.enrollment_id}`)
        .then((response) => {
          let contractors = [];
          let filiations = [];
          for (const responsible of response.data.enrollment
            .EnrollmentResponsibles) {
            for (const types of responsible.ResponsibleTypes) {
              if (types.type.includes("contractor")) {
                contractors.push({
                  ...responsible,
                  type: types.type
                    .replace("contractor", "Contratante")
                    .replace(
                      /\w\S*/g,
                      (m) =>
                        m.charAt(0).toUpperCase() + m.substr(1).toLowerCase()
                    ),
                });
                contractors.sort((a, b) => {
                  if (a.type < b.type) {
                    return -1;
                  }
                  if (a.type > b.type) {
                    return 1;
                  }
                  return 0;
                });
              } else if (types.type.includes("filiation")) {
                filiations.push(responsible);
              }
            }
          }

          this.value = {
            ...response.data.enrollment,
            Contractor: contractors,
            Filiation: filiations,
          };
        });
    },
  },

  created() {
    this.getEnrollmentData();
  },
};
</script>

<style lang="scss">
@import "@/assets/css/variables.scss";

.list-contracts {
  background-color: $white;
  border: 1px solid $grey5;
  border-radius: 10px;
  margin-bottom: 15px;
  min-height: 80px;
  margin-right: -12px;
  &:last-child {
    margin-bottom: 0px;
  }
  &:hover {
    cursor: pointer;
    border-color: transparent;
    background-image: linear-gradient($white, $white),
      radial-gradient(circle at top left, $orange, $purple);
    background-origin: border-box;
    background-clip: content-box, border-box;
  }
  p {
    margin: 0;
  }
}

.header-list {
  color: $grey1;
  p {
    margin: 0;
  }
  .tags {
    background-color: $blue;
    border-radius: 5px;
    color: $white;
    line-height: 28px;
    max-width: 58px;
    text-align: center;
  }
}

.column-list {
  color: $grey1;
}

.status-box {
  margin: 0 0 60px;
}

.secretary-view {
  .info-students:nth-child(1) {
    border: 0 !important;
  }
}
</style>
