export default {
  syllabuses: [],
  syllabus: {},
  syllabusTopics: [],
  syllabusStudents: [],
  syllabusesAndStudentsArray: [],
  syllabusCycle: 0,

  subjectContent: {},
  
  student: {},
  studentCompetences: {},
  studentSyllabuses: [],
  studentSubjects: [],
  studentSyllabusList: [],
  studentsBySubject: []
}