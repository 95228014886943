export default {
  cycles: [],
  subjects: [],
  competences: [],
  topicsByCompetences: {},
  topics: [],
  countries: [],
  states: [],
  cities: [],
  users: {},
  studentsCycle: [],
  studentsAttendances: [],
  studentsSubjects: [],
  calendars: []
}