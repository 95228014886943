<template>
  <b-col class="flex-right">
    <div class="sk-circle">
      <div class="sk-circle1 sk-child"></div>
      <div class="sk-circle2 sk-child"></div>
      <div class="sk-circle3 sk-child"></div>
      <div class="sk-circle4 sk-child"></div>
      <div class="sk-circle5 sk-child"></div>
      <div class="sk-circle6 sk-child"></div>
      <div class="sk-circle7 sk-child"></div>
      <div class="sk-circle8 sk-child"></div>
      <div class="sk-circle9 sk-child"></div>
      <div class="sk-circle10 sk-child"></div>
    </div>
    <b-progress
      v-if="progress === 0 || progress"
      :value="progress"
      class="mb-3"
    />
  </b-col>
</template>

<script>
export default {
  name: "Loading",

  props: {
    progress: {
      type: Number,
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/variables.scss";
.flex-right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.progress {
  background-color: $white;
  margin: 0 !important;
  max-width: 290px;
  width: 100%;
  height: 26px;
  padding: 3px;
  border: 1px solid $grey5;
  border-radius: 0;

  .progress-bar {
    background-color: $grey5;
  }
}
</style>