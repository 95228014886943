<template>
  <b-modal
    id="tutoria"
    hide-header
    hide-footer
    centered
    @hide="changeModalState"
  >
    <h4 class="purple">Tutoria</h4>
    <b-row class="mt-4">
      <b-col>
        <StandardSelect
          :callbackSelect="selectStudent"
          :searchChange="setSearch"
          :options="students"
          :isLoading="loadingList"
          label="Buscar Aluno"
          placeholder="Digite aqui"
        />
      </b-col>
    </b-row>

    <b-row>
      <b-col md-6 class="mt-4 ml-auto">
        <b-button
          @click="assignStudent"
          :disabled="!studentToAssign"
          class="normal mb-5"
          >Atribuir</b-button
        >
      </b-col>
    </b-row>

    <h4 class="purple mt-50">Novos Alunos Atribuídos</h4>

    <ul class="students">
      <li
        class="student"
        v-for="(student, index) in studentsAssigneds"
        :key="student.id"
      >
        <div>
          <p class="name">{{ student.name }}</p>
        </div>

        <div class="actions">
          <span @click="() => deleteStudent(index)">
            <DeleteIcon />
          </span>
        </div>
      </li>
    </ul>

    <h4 class="purple mt-50">Alunos Tutorandos Atualmente</h4>

    <ul class="students">
      <li
        class="student"
        v-for="student in currentStudentsAssigneds"
        :key="student.id"
      >
        <div>
          <p class="name">{{ student.name }}</p>
        </div>

        <div class="actions">
          <span v-on:click.prevent="deleteStudentFromTutoring(student.id)">
            <DeleteIcon />
          </span>
        </div>
      </li>
    </ul>

    <b-row>
      <b-col md="6" class="ml-auto mt-5">
        <b-button @click="closeModal" class="large blue outline"
          >Voltar</b-button
        >
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import { mapActions } from "vuex";

import DeleteIcon from "@/components/svg/DeleteIcon";
import StandardSelect from "@/components/inputs/StandardSelect.vue";
import httpRda from "../../http-rda";

export default {
  name: "TutoriaModal",
  components: {
    DeleteIcon,
    StandardSelect,
  },

  props: {
    callback: {
      type: Function,
      required: true,
    },

    studentsToEdit: {
      type: Array,
      default: () => [],
    },
    teacherId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      loadingList: false,
      searchStudent: "",
      studentToAssign: null,
      students: [],
      studentsAssigneds: [],
      currentStudentsAssigneds: [],
      userStopedTyping: () => {
        "";
      },
    };
  },

  computed: {
    studentsNamed() {
      return this.students
        .filter((s) => !this.studentsAssigneds.some((sa) => sa.id === s.id))
        .map((s) => ({ ...s, name: s.User.name }));
    },
  },

  methods: {
    ...mapActions({
      getStudents: "community/listStudents",
    }),

    async getStudents() {
      await httpRda
        .get(`studentsforconcierge`)
        .then((res) => {
          let students = res.data.students;
          students = students.map((item) => {
            item.User.student_id = item.id;
            return item.User;
          });

          this.students = students;
        })
        .catch((err) => {
          console.log(err);
        });

      const tutoredStudents = await httpRda
        .get(`/tutoringAllStudents`)
        .then((res) => res.data.response);

      this.students = this.students
        .map((e) => {
          if (tutoredStudents.includes(e.student_id)) {
            e.name = `${e.name} => Possui tutor!`;
            return e;
          }
          return e;
        })
        .filter((e) => e !== undefined);
    },

    async getAssignedStudents() {
      let response = await httpRda
        .get(`/tutoring/${this.teacherId}`)
        .then((res) => res.data);

      this.currentStudentsAssigneds = response.tutoring.students
        .map((e) => {
          return { id: e.Student.id, name: e.Student.User.name };
        })
        .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
    },

    selectStudent(student) {
      this.studentToAssign = student;
    },

    setSearch(string) {
      this.searchStudent = string;
    },

    assignStudent() {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "bottom-end",
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });

      if (this.studentToAssign.name.includes("Possui tutor!")) {
        Toast.fire({
          icon: "error",
          title:
            "Não é possível atribuir esse aluno!\nJá é tutorando de outro professor!",
        });

        return;
      } else {
        if (!this.studentToAssign || !this.studentToAssign.id) return;
        this.searchStudent = "";

        if (
          this.studentsAssigneds.some((sa) => sa.id === this.studentToAssign.id)
        ) {
          Toast.fire({
            icon: "error",
            title: "Aluno já atribuído!",
          });

          return;
        }

        this.studentsAssigneds.push(this.studentToAssign);
      }
    },

    deleteStudent(index) {
      const newStudentList = [...this.studentsAssigneds];

      newStudentList.splice(index, 1);

      this.studentsAssigneds = newStudentList;
    },

    async deleteStudentFromTutoring(student_id) {
      this.$swal
        .fire({
          title: "Tem certeza que deseja remover o estudante dessa tutoria?",
          text: "Você não poderá reverter isso!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#68c5b7",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim, excluir!",
          cancelButtonText: "Cancelar",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            try {
              await httpRda.delete(`/tutoring/${student_id}`);
              this.$swal
                .fire("Excluído!", "Aluno excluído da tutoria.", "success")
                .then(() => {
                  this.getAssignedStudents();
                });
            } catch (error) {
              this.$swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Não foi possivel excluir!",
                footer: "Por favor, tente novamente mais tarde.",
              });

              return error.response;
            }
          }
        })
        .catch(() => {
          this.$swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Não foi possivel excluir!",
            footer: "Por favor, tente novamente mais tarde.",
          });
        });
    },

    changeModalState() {
      this.callback(this.studentsAssigneds);
    },

    closeModal() {
      this.$root.$emit("bv::hide::modal", "tutoria");
    },
  },

  watch: {
    searchStudent() {
      const search = this.searchStudent;
      this.loadingList = true;

      clearTimeout(this.userStopedTyping);

      this.userStopedTyping = setTimeout(() => {
        this.loadingList = false;
        if (search.length >= 3) this.getStudents({ search });
      }, 2000);
    },

    studentsToEdit() {
      this.studentsAssigneds = this.studentsToEdit.map((ste) => ({
        name: ste.User.name,
        ...ste,
      }));
    },
  },

  created() {
    this.getStudents();
    this.getAssignedStudents();
  },
};
</script>

<style lang="scss">
@import "@/assets/css/variables.scss";

div#tutoria {
  .modal-dialog {
    max-width: 820px;
    .modal-content {
      background-color: $grey2;
      border: 0;
      border-radius: 0;
      .modal-body {
        padding: 60px;
      }
    }
  }
  .students {
    list-style: none;
    padding: 0;
    margin: 0;
    .student {
      align-items: center;
      color: $grey3;
      display: flex;
      justify-content: space-between;
      line-height: 60px;
      border-bottom: 1px solid $grey5;
      &:last-child {
        border-bottom: 0;
      }
      .name {
        margin: 0;
      }
      .actions {
        a {
          margin: 0 15px;
        }
      }
    }
  }
}
</style>
