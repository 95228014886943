<template>
  <b-form-group label="Ciclos">
    <multiselect
      :value="computedValue"
      :options="myOptions"
      :multiple="false"
      :close-on-select="true"
      :clear-on-select="false"
      :preserve-search="true"
      :placeholder="placeholder"
      label="name"
      track-by="id"
      selectLabel=""
      selectedLabel=""
      deselectLabel=""
      @select="callback"
    >
      <span slot="noResult">Nada encontrado</span>
    </multiselect>
  </b-form-group>
</template>

<script>
import DataService from "@/services/cycles.service";

export default {
  props: {
    placeholder: {
      default: "Todos",
      type: String,
    },
    value: {},
    callback: {},
  },
  data() {
    return {
      options: "",
      cycles: [],
    };
  },
  created() {
    this.getCycles();
  },
  methods: {
    getCycles() {
      DataService.getAll()
        .then((response) => {
          this.cycles = response.data.cycles;
        })
        .catch((error) => {
          return error;
        });
    },
  },
  computed: {
    myOptions() {
      if (!this.cycles.length) return [];

      return [...this.cycles, { name: "Todos", id: -1 }];
    },
    computedValue() {
      if (typeof this.value === "number") {
        return this.cycles.find((c) => c.id == this.value);
      } else {
        return this.value;
      }
    },
  },
};
</script>

<style lang="scss">
.form-group {
  margin-bottom: 0;
}
</style>
