<template>
  <b-container class="container-rda login">
    <b-row class="form-login">
      <b-col>
        <div class="box-login">
          <h1>Link de matrícula inválido!</h1>
          <h4 class="mb-4">
            O link de matrícula inválido. Por favor, entre em contato com a
            escola para mais informações.
          </h4>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "enrollment-expired-token",
  components: {},
  data() {
    return {};
  },

  methods: {},
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/variables.scss";

.container-rda {
  display: flex;
  justify-content: center;
}

.login {
  background: url("~@/assets/images/bg-login.jpg");
  background-position: center;
  background-size: cover;
  height: 100%;
  max-width: 100%;
  padding: 0;
  .form-login {
    align-items: center;
    height: 100%;
    width: 100%;
    .box-login {
      max-width: 575px;
      margin: 0 auto;
      h1,
      h2,
      h4,
      p,
      label {
        color: $white;
      }
      .btn-login {
        text-transform: uppercase;
      }
      a {
        color: $white;
        font-size: 18px;
        line-height: 32px;
      }
    }
  }

  .spinner-border-sm {
    vertical-align: text-top;
    margin-right: 10px;
  }

  .message-error {
    background-color: $orange;
    border-radius: 5px;
    border: 1px solid $white;
    color: $white;
    font-size: 16px;
    font-weight: 700;
    line-height: 16px;
    margin: 0 auto;
    max-width: 710px;
    padding: 12px 20px;
    svg {
      vertical-align: text-top;
      margin-right: 8px;
    }
  }
}
</style>
