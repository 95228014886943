import axios from "axios";

const API_URL = process.env.VUE_APP_URL;

class AuthService {
  login(users) {
    return axios
      .post(API_URL + "log_in", {
        email: users.email,
        password: users.password,
      })
      .then((response) => {
        return response
      });
  }

  refreshToken() {
    return axios.post(API_URL + "refresh");
  }

  logout() {
    localStorage.removeItem("access_token")
    localStorage.removeItem("refresh_token")
  }

  register(users) {
    return axios.post(API_URL + "register", {
      email: users.email,
      password: users.password,
    });
  }
}

export default new AuthService();
