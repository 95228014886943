const syllabuses = state => state.syllabuses
const syllabus = state => state.syllabus
const syllabusTopics = state => state.syllabusTopics
const syllabusStudents = state => state.syllabusStudents
const studentSyllabuses = state => state.studentSyllabuses
const studentCompetences = state => state.studentCompetences
const syllabusesAndStudentsArray = state => state.syllabusesAndStudentsArray
const studentSubjects = state => state.studentSubjects
const student = state => state.student
const subjectContent = state => state.subjectContent
const syllabusCycle = state => state.syllabusCycle
const studentSyllabusList = state => state.studentSyllabusList
const studentsBySubject = state => state.studentsBySubject

export default {
  syllabuses,
  syllabus,
  syllabusTopics,
  syllabusStudents,
  syllabusesAndStudentsArray,
  syllabusCycle,

  studentSyllabuses,
  studentCompetences,
  studentSubjects,
  student,
  subjectContent,
  studentSyllabusList,
  studentsBySubject
}