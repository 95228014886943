<template>
  <svg width="23" height="14" viewBox="0 0 23 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M19.885 0.511709L11.5 8.67742L3.11502 0.511709C2.58025 -0.00924197 1.85779 -0.143327 1.21979 0.159963C0.58179 0.463254 0.125171 1.15784 0.0219385 1.98208C-0.0812942 2.80632 0.184543 3.635 0.719311 4.15595L10.3021 13.4882C11.0023 14.1706 11.9977 14.1706 12.6978 13.4882L22.2807 4.15595C23.1073 3.35063 23.2412 1.882 22.5796 0.875666C21.9181 -0.130662 20.7116 -0.293612 19.885 0.511709Z" fill="url(#paint0_linear)"/>
    <defs>
    <linearGradient id="paint0_linear" x1="-7.015" y1="6.93069" x2="35.65" y2="6.9307" gradientUnits="userSpaceOnUse">
    <stop stop-color="#666666"/>
    <stop offset="1" stop-color="#666666"/>
    </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  
}
</script>

<style lang="sass">

</style>