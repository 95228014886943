var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',[_c('b-container',{attrs:{"fluid":""}},[_c('b-row',{staticClass:"content-header",attrs:{"align-v":"center"}},[_c('b-col',{attrs:{"md":"2","lg":"2"}},[(
          !_vm.hasPermission([
            'admin',
            'parent',
            'teacher',
            'secretary',
            'concierge' ]) &&
          !this.currentUser.role.includes('qrcode') &&
          !this.currentUser.role.includes('attendance_manager')
        )?_c('router-link',{attrs:{"to":{
          name: 'dashboard-aluno',
        }}},[_c('Logo')],1):(this.currentUser.role.includes('qrcode'))?_c('router-link',{attrs:{"to":{
          name: 'qrcode',
        }}},[_c('Logo')],1):(!_vm.hasPermission(['admin']) && _vm.hasPermission(['parent']))?_c('router-link',{attrs:{"to":{
          name: ("" + _vm.linkTeacherRole),
        }}},[_c('Logo')],1):(
          _vm.hasPermission(['teacher']) && !_vm.hasPermission(['coordinator'])
        )?_c('router-link',{attrs:{"to":{
          name: 'indicadores',
        }}},[_c('Logo')],1):(
          _vm.hasPermission(['secretary']) && !_vm.hasPermission(['coordinator'])
        )?_c('router-link',{attrs:{"to":{
          name: 'indicadores',
        }}},[_c('Logo')],1):(this.currentUser.role.includes('attendance_manager'))?_c('router-link',{attrs:{"to":"registro/faltas-e-presencas"}},[_c('Logo')],1):_c('router-link',{attrs:{"to":{
          name: 'indicadores',
        }}},[_c('Logo')],1)],1),_c('b-col',{attrs:{"cols":"2","sm":"7","md":"8","lg":"8"}},[_c('b-navbar',{staticClass:"menu align-center show-large-only"},[_c('b-navbar-nav',[(
              !_vm.hasPermission(['admin']) ? _vm.hasPermission(['student']) : ''
            )?_c('router-link',{attrs:{"to":{ name: 'dashboard-aluno' }}},[_vm._v("Início")]):_vm._e(),(
              !_vm.hasPermission(['admin']) &&
              _vm.hasPermission(['parent']) &&
              _vm.hideToTeacher
            )?_c('router-link',{attrs:{"to":{ name: 'dashboard' }}},[_vm._v("Início")]):_vm._e(),(_vm.hasPermission(['teacher', 'secretary']) && _vm.hideToParent)?_c('router-link',{attrs:{"to":{ name: 'indicadores' }}},[_vm._v("Indicadores")]):_vm._e(),(
              (_vm.hasPermission(['teacher']) && _vm.hideToParent) ||
              _vm.hasPermission(['secretary'])
            )?_c('router-link',{attrs:{"to":{
              name: _vm.hasPermission(['teacher', 'student'])
                ? 'comunidade'
                : 'professores',
            },"active-class":"router-link-exact-active"}},[_vm._v(" Comunidade "),(_vm.hasPermission(['teacher', 'secretary']))?_c('ul',{staticClass:"submenu"},[_c('li',[(_vm.hasPermission(['secretary']))?_c('router-link',{attrs:{"to":{ name: 'professores' }}},[_vm._v("Professores")]):_vm._e(),_c('router-link',{attrs:{"to":{ name: 'alunos' }}},[_vm._v("Alunos")]),_c('router-link',{attrs:{"to":{ name: 'tutoria' }}},[_vm._v("Tutoria")]),(_vm.hasPermission(['coordinator']))?_c('router-link',{attrs:{"to":{ name: 'ciclos-turmas-tutores' }}},[_vm._v("Ciclos, Turmas e Tutores")]):_vm._e(),(_vm.hasPermission(['coordinator']))?_c('router-link',{attrs:{"to":{ name: 'informe-periodico' }}},[_vm._v(" Informe Periódico ")]):_vm._e()],1)]):_vm._e()]):_vm._e(),(
              !_vm.hasPermission(['admin']) &&
              _vm.hasPermission(['parent']) &&
              _vm.hideToTeacher
            )?_c('router-link',{attrs:{"to":{ name: 'matriculas' }}},[_vm._v("Matrícula")]):_vm._e(),(
              this.currentUser.role.includes('attendance_manager') &&
              _vm.hideToTeacher
            )?_c('router-link',{attrs:{"to":{ name: 'registro' }}},[_vm._v("Faltas e Presenças ")]):_vm._e(),(_vm.hasPermission(['secretary']))?_c('router-link',{attrs:{"to":{ name: 'secretaria' },"active-class":"router-link-exact-active"}},[_vm._v(" Secretaria "),_c('ul',{staticClass:"submenu"},[_c('li',[_c('router-link',{attrs:{"to":{ name: 'pedidos' }}},[_vm._v("Pedidos")]),_c('router-link',{attrs:{"to":{ name: 'ano-letivo' }}},[_vm._v("Ano letivo")]),_c('router-link',{attrs:{"to":{ name: 'listar-contratos' }}},[_vm._v("Contratos")]),_c('router-link',{attrs:{"to":{ name: 'listar-contratos-inadimplentes' }}},[_vm._v("Contratos inadimplentes")]),_c('router-link',{attrs:{"to":{ name: 'faltas-presencas' }}},[_vm._v("Faltas e presenças")]),_c('router-link',{attrs:{"to":{ name: 'turmas' }}},[_vm._v("Alterações Internas")]),_c('router-link',{attrs:{"to":{ name: 'solicitacoes-de-exclusao-de-dados' }}},[_vm._v("Solicitações de exclusão")]),_c('router-link',{attrs:{"to":{ name: 'links' }}},[_vm._v("Link de Matrícula")])],1)])]):_vm._e(),(_vm.hasPermission())?_c('router-link',{attrs:{"to":{ name: 'coordenacao' },"active-class":"router-link-exact-active"}},[_vm._v(" Coordenação "),_c('ul',{staticClass:"submenu"},[_c('li',[_c('router-link',{attrs:{"to":{ name: 'permissoes' }}},[_vm._v("Autorizações")]),_c('router-link',{attrs:{"to":{ name: 'listar-conteudos' }}},[_vm._v("Currículo")])],1)])]):_vm._e(),(
              (_vm.hasPermission(['teacher']) && _vm.hideToParent) ||
              _vm.hasPermission(['student'])
            )?_c('router-link',{attrs:{"to":{
              name: _vm.hasPermission(['teacher'])
                ? 'aprendizagem'
                : 'registrar-aprendizagem',
              params: { id: _vm.currentUser.student_id },
            },"active-class":"router-link-exact-active"}},[_vm._v(" Aprendizagem "),_c('ul',{staticClass:"submenu"},[(_vm.hasPermission(['teacher']))?_c('li',[(false)?_c('router-link',{attrs:{"to":{ name: 'roteiros' }}},[_vm._v("Roteiros")]):_vm._e(),_c('router-link',{attrs:{"to":{ name: 'conteudos' }}},[_vm._v("Registro de Aprendizagem")])],1):_vm._e(),(
                  !_vm.hasPermission(['admin']) && _vm.hasPermission(['student'])
                )?_c('li',[_c('router-link',{attrs:{"to":{
                    name: 'registrar-aprendizagem',
                    params: { id: _vm.currentUser.student_id },
                  }}},[_vm._v("Conteudos")]),(false)?_c('router-link',{attrs:{"to":{ name: 'roteiros-aluno' }}},[_vm._v("Roteiros")]):_vm._e()],1):_vm._e(),(_vm.hasPermission(['teacher']))?_c('li',[(false)?_c('router-link',{attrs:{"to":{ name: 'roteiros' }}},[_vm._v("Roteiros")]):_vm._e()],1):_vm._e(),(_vm.hasPermission(['teacher', 'secretary']))?_c('li',[_c('router-link',{attrs:{"to":{ name: 'painel-de-avaliacoes' }}},[_vm._v("Painel de Avaliações")])],1):_vm._e()])]):_vm._e(),(_vm.hasPermission(['concierge']))?_c('router-link',{attrs:{"to":{ name: 'portaria' },"active-class":"router-link-exact-active"}},[_vm._v(" Portaria ")]):_vm._e(),(_vm.canShowAppModules())?_c('router-link',{attrs:{"to":{
              name: _vm.isAdmin() ? 'usuarios' : 'eventos',
            },"active-class":"router-link-exact-active"}},[_vm._v(" Administração "),_c('ul',{staticClass:"submenu"},[(_vm.isAdmin())?_c('li',[_c('router-link',{attrs:{"to":{ name: 'usuarios' }}},[_vm._v("Usuários")])],1):_vm._e(),(_vm.rdaUnit == 'BELO_HORIZONTE')?_c('li',[_c('router-link',{attrs:{"to":{ name: 'eventos' }}},[_vm._v("Eventos")])],1):_vm._e(),(_vm.rdaUnit == 'BELO_HORIZONTE')?_c('li',[_c('router-link',{attrs:{"to":{ name: 'banners' }}},[_vm._v("Banners")])],1):_vm._e(),(_vm.rdaUnit == 'BELO_HORIZONTE')?_c('li',[_c('router-link',{attrs:{"to":{ name: 'notifications' }}},[_vm._v("Avisos")])],1):_vm._e(),(_vm.rdaUnit == 'BELO_HORIZONTE')?_c('li',[_c('router-link',{attrs:{"to":{ name: 'contractors-attendances-notifications' }}},[_vm._v("Notificações de Faltas")])],1):_vm._e(),_c('li',[_c('router-link',{attrs:{"to":{ name: 'periods' }}},[_vm._v("Informe Periódico")])],1),(_vm.isAdmin() && _vm.rdaUnit == 'BELO_HORIZONTE')?_c('li',[_c('router-link',{attrs:{"to":{ name: 'blog' }}},[_vm._v("Blog")])],1):_vm._e(),(_vm.isAdmin() && _vm.rdaUnit == 'BELO_HORIZONTE')?_c('li',[_c('router-link',{attrs:{"to":{ name: 'videos' }}},[_vm._v("Vídeos")])],1):_vm._e()])]):_vm._e()],1)],1)],1),_c('b-col',{staticClass:"group-user-infos",attrs:{"md":"2","lg":"2"}},[_c('div',{staticClass:"profile align-right"},[_c('b-popover',{attrs:{"target":"popover-target-1","triggers":"focus","placement":"bottom"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Popover Title")]},proxy:true}])},[_vm._v(" I am popover "),_c('b',[_vm._v("component")]),_vm._v(" content! ")])],1),(_vm.currentUser.name)?_c('b-navbar',{staticClass:"profile align-right"},[_c('b-navbar-nav',[(
              _vm.hasPermission(['teacher'], true) &&
              _vm.bimontlyReportNotifications.length !== 0
            )?_c('b-nav-item-dropdown',{attrs:{"id":"my-nav-dropdown","text":"Dropdown","toggle-class":"nav-link-custom","right":""}},[_c('template',{slot:"button-content"},[_c('div',{staticClass:"button-content"},[_c('div',{staticClass:"bell-circle"},[_vm._v(" "+_vm._s(_vm.unreadBimontlyReportNotifications)+" ")]),_c('font-awesome-icon',{attrs:{"icon":"fa-regular fa-bell","size":"lg"}})],1)]),_c('div',{staticClass:"wrap-notification-data"},_vm._l((_vm.bimontlyReportNotifications),function(notification,index){return _c('b-dropdown-item',{key:index,staticClass:"notification-item",attrs:{"to":_vm.getNotificationRoute(notification)},on:{"click":function($event){$event.preventDefault();return _vm.markBimontlyReportAsRead(notification.id)}}},[_c('div',{staticClass:"notification-item-wrapper"},[_c('p',{staticClass:"notification-date"},[_vm._v(" "+_vm._s(notification.created_at)+" ")]),_c('p',[_c('strong',{attrs:{"id":"notificatoin-body-text"}},[_vm._v("A "+_vm._s(notification.context.user_coordinator_name)+" ")]),_vm._v(" solicitou o "),_c('br'),_vm._v(" preenchimento "+_vm._s(notification.type === 'period_tutor' ? ' do informe periódico' : ' das avaliações')+" do(a) aluno(a) "),_c('br'),_c('strong',[_vm._v(" "+_vm._s(notification.context.student_name)+" ")])])]),(!notification.readed_at)?_c('div',{staticClass:"notification-circle"}):_vm._e()])}),1),_c('b-dropdown-divider'),_c('b-dropdown-item',{attrs:{"to":{ name: 'alertas' }}},[_c('p',{staticStyle:{"color":"#b23066"}},[_vm._v("Ver todas")])])],2):_vm._e(),(
              _vm.hasPermission(['secretary', 'teacher']) &&
              _vm.notifications.length !== 0
            )?_c('b-nav-item-dropdown',{attrs:{"id":"my-nav-dropdown","text":"Dropdown","toggle-class":"nav-link-custom","right":""}},[_c('template',{slot:"button-content"},[_c('div',{staticClass:"button-content"},[(_vm.Unreadnotifications)?_c('div',{staticClass:"bell-circle"},[_vm._v(" "+_vm._s(_vm.Unreadnotifications)+" ")]):_vm._e(),_c('font-awesome-icon',{attrs:{"icon":"fa-regular fa-bell","size":"lg"}})],1)]),_vm._l((_vm.notifications.slice(0, 5)),function(notification,index){return _c('b-dropdown-item',{key:index,attrs:{"to":{ name: 'notificacoes' }}},[_c('div',[_c('p',{staticClass:"notification-date"},[_vm._v(" "+_vm._s(notification.created_at)+" ")]),_c('strong',{attrs:{"id":"notificatoin-body-text"}},[_vm._v(_vm._s(notification.EditorUser.name))]),_vm._v(" editou dados "),(
                    notification.user_editor_id ===
                    notification.user_edited_id
                  )?_c('strong',{attrs:{"id":"notificatoin-body-text"}},[_c('span',{staticClass:"not-bold"},[_vm._v(" do ")]),_vm._v("próprio usuário ")]):_vm._e(),(!notification.readed_at)?_c('div',{staticClass:"notification-circle"}):_c('div',[_vm._v(" "+_vm._s(notification.type)+" ")])])])}),_c('b-dropdown-divider'),_c('b-dropdown-item',{attrs:{"to":{ name: 'notificacoes' }}},[_c('p',{staticStyle:{"color":"#b23066"}},[_vm._v("Ver todas")])])],2):_vm._e(),(
              _vm.hasPermission(['secretary', 'teacher']) &&
              _vm.evaluationNotifications.length !== 0 &&
              _vm.switchViews != 'parent'
            )?_c('b-nav-item-dropdown',{attrs:{"id":"my-nav-dropdown","text":"Dropdown","toggle-class":"nav-link-custom","right":""}},[_c('template',{slot:"button-content"},[_c('div',{staticClass:"button-content"},[_c('div',{staticClass:"bell-circle"}),_c('font-awesome-icon',{attrs:{"icon":"fa-regular fa-note-sticky","size":"lg"}})],1)]),_vm._l((_vm.evaluationNotifications.slice(
                0,
                5
              )),function(notification,index){return _c('b-dropdown-item',{key:index,attrs:{"to":{
                name: 'registrar-aprendizagens',
                params: {
                  id: notification.student_id,
                  subject_id: notification.subject_id,
                },
              }}},[_c('div',[_c('p',{staticClass:"notification-date"},[_vm._v(" "+_vm._s(notification.updated_at)+" ")]),_c('strong',{attrs:{"id":"notificatoin-body-text"}},[_vm._v(_vm._s(notification.student_name))]),_vm._v(" solicitou uma avaliação em "),_c('strong',{attrs:{"id":"notificatoin-body-text"}},[_c('span',{staticClass:"bold"},[_vm._v(" "+_vm._s(notification.subject_designation)+" ")])])])])}),_c('b-dropdown-divider'),_c('b-dropdown-item',{attrs:{"to":{ name: 'painel-de-avaliacoes' }}},[_c('p',{staticStyle:{"color":"#b23066"}},[_vm._v("Ver todas")])])],2):_vm._e(),_c('b-navbar-brand',[(_vm.photo)?_c('img',{attrs:{"width":"32","height":"32","src":_vm.photo,"alt":""}}):_c('div',{staticClass:"profile_name"},[_c('span',[_vm._v(_vm._s(_vm.getFirstLetterSpan(_vm.currentUser.name)))])])]),_c('b-nav-item-dropdown',{staticClass:"name-user",attrs:{"right":""}},[(
                !_vm.hasPermission(['admin']) &&
                _vm.hasPermission(['teacher']) &&
                _vm.hasPermission(['parent'])
              )?_c('b-form-group',{staticClass:"group-radio switch-roles"},[_c('div',{staticClass:"radio"},[_c('b-form-radio',{attrs:{"name":"options-view","value":"parent"},on:{"change":function($event){return _vm.switchRoles('changeToParent')}},model:{value:(_vm.switchViews),callback:function ($$v) {_vm.switchViews=$$v},expression:"switchViews"}},[_vm._v("Mãe/Pai")]),_c('b-form-radio',{attrs:{"name":"options-view","value":"teacher"},on:{"change":function($event){return _vm.switchRoles('changeToTeacher')}},model:{value:(_vm.switchViews),callback:function ($$v) {_vm.switchViews=$$v},expression:"switchViews"}},[_vm._v("Professor")])],1)]):_vm._e(),(!this.currentUser.role.includes('qrcode'))?_c('b-dropdown-item',{attrs:{"to":{ name: 'perfil' }}},[_vm._v("Perfil")]):_vm._e(),_c('b-dropdown-item',{attrs:{"href":""},on:{"click":function($event){$event.preventDefault();return _vm.logOut.apply(null, arguments)}}},[_vm._v("Sair")])],1)],1)],1):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }