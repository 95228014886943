<template>
  <b-container class="container-rda login">
    <b-row class="form-login">
      <b-col>
        <div class="box-login">
          <h2>Bem-vindo(a)!</h2>
          <p class="mb-4">Preencha os dados abaixo para realizar seu login!</p>
          <form name="form" @submit.prevent="handleLogin">
            <b-form-group>
              <label for="label">E-mail</label>
              <b-form-input
                v-model="users.email"
                v-validate="'required'"
                type="text"
                name="email"
                placeholder="Digite aqui seu email"
                class="form-control"
              ></b-form-input>
              <div
                v-if="errors.has('email')"
                class="alert alert-danger"
                role="alert"
              >
                Campo obrigatório
              </div>
            </b-form-group>
            <b-form-group>
              <label for="label">Senha</label>
              <b-form-input
                v-model="users.password"
                v-validate="'required'"
                type="password"
                name="password"
                placeholder="Digite aqui sua senha"
                class="form-control"
              ></b-form-input>
              <div
                v-if="errors.has('password')"
                class="alert alert-danger"
                role="alert"
              >
                Campo obrigatório
              </div>
            </b-form-group>
            <b-form-checkbox-group id="connected" name="">
              <b-form-checkbox value="connected"
                >Manter-me conectado</b-form-checkbox
              >
            </b-form-checkbox-group>
            <button :disabled="loading" class="mt-4 btn full btn-login">
              <span
                v-show="loading"
                class="spinner-border spinner-border-sm"
              ></span>
              <span>ENTRAR</span>
            </button>
            <div class="mt-3 d-block text-center">
              <router-link
                :to="{ name: 'forgot-password' }"
                class="btn-link white"
                >Esqueceu sua senha?</router-link
              >
            </div>
            <div v-if="message" role="alert">
              <p class="mt-4 text-center message-error">
                <InfoIcon /> Email ou senha estão incorretos. Tente novamente
              </p>
            </div>
          </form>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import User from "@/models/User";
import { mapActions, mapGetters } from "vuex";

import InfoIcon from "@/components/svg/InfoIcon.vue";

export default {
  name: "login",
  components: {
    InfoIcon,
  },
  data() {
    return {
      users: new User("", ""),
      loading: false,
      message: "",
    };
  },

  computed: {
    ...mapGetters({
      user: "auth/getAuthData",
    }),
  },

  methods: {
    ...mapActions({
      actionLogin: "auth/login",
    }),
    async handleLogin() {
      this.loading = true;

      this.$validator.validateAll().then(async (isValid) => {
        if (!isValid) {
          this.loading = false;
          return;
        }

        if (this.users.email && this.users.password) {
          await this.$store
            .dispatch("auth/login", this.users)
            .then(async (response) => {
              if (
                response.message != undefined &&
                response.message.includes("401")
              ) {
                this.message = true;
                this.loading = false;
              } else {
                await this.$router.push("/");
              }
            });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/variables.scss";

.container-rda {
  display: flex;
  justify-content: center;
}

.login {
  background: url("~@/assets/images/bg-login.jpg");
  background-position: center;
  background-size: cover;
  height: 100%;
  max-width: 100%;
  padding: 0;
  margin: 0;

  .form-login {
    align-items: center;
    height: 100%;
    width: 100%;
    .box-login {
      max-width: 575px;
      margin: 0 auto;
      h2,
      p,
      label {
        color: $white;
      }
      a {
        color: $white;
        font-size: 18px;
        line-height: 32px;
        text-transform: uppercase;
      }
    }
  }

  .spinner-border-sm {
    vertical-align: text-top;
    margin-right: 10px;
  }

  .message-error {
    background-color: $orange;
    border-radius: 5px;
    border: 1px solid $white;
    font-size: 16px;
    font-weight: 700;
    line-height: 16px;
    padding: 12px 20px;
    svg {
      vertical-align: text-top;
      margin-right: 8px;
    }
  }
}
</style>
