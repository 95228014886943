import { render, staticRenderFns } from "./Claimants.vue?vue&type=template&id=72076e31&scoped=true&"
import script from "./Claimants.vue?vue&type=script&lang=js&"
export * from "./Claimants.vue?vue&type=script&lang=js&"
import style0 from "./Claimants.vue?vue&type=style&index=0&id=72076e31&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72076e31",
  null
  
)

export default component.exports