<template>
  <b-form-group :description="description">
    <div>
      <label for="label">{{ label }}</label>
      <input
        autocomplete="off"
        :v-model="vmodel"
        :v-validate="vvalidate"
        :type="type"
        :name="name"
        :v-mask="vmask"
        :placeholder="placeholder"
        :value="value"
        :required="required"
        @input="updateValue($event.target.value)"
        :class="`form-control ${noFullName ? 'error' : ''}`"
        v-bind="$attrs"
        @change="onChangeValue($event.target.value)"
        :disabled="disabled"
      />
    </div>
    <span v-if="noFullName" class="label-error">Digite nome e sobrenome</span>
  </b-form-group>
</template>

<script>
export default {
  props: {
    value: undefined,
    vmodel: undefined,
    disabled: {
      type: Boolean,
      default: false,
    },
    vvalidate: undefined,
    type: undefined,
    name: undefined,
    label: undefined,
    vmask: undefined,
    description: undefined,
    placeholder: undefined,
    onchange: undefined,
    error: {
      type: Boolean,
      default: false,
    },
    labelError: undefined,
    required: {
      type: Boolean,
      default: true,
    },
    validateFullName: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      mask: "",
      noFullName: false,
    };
  },
  methods: {
    updateValue(value) {
      this.$emit("input", value);
    },
    onChangeValue(value) {
      this.$emit("onchange", value);

      if (this.validateFullName) {
        this.checkFullName(value);
      }
    },
    checkFullName(name) {
      name = name.trim();
      const nameArr = name.split(" ");

      if (nameArr.length >= 2) {
        this.noFullName = false;
        return true;
      } else {
        this.noFullName = true;
        return false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.form-control {
  &.error {
    border-color: red;
  }
}
.label-error {
  font-size: 10px;
  color: red;
}
</style>
