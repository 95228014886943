const CYCLES_UPDATE = (state, { cycles }) => {
  state.cycles = cycles;
};

const SUBJECTS_UPDATE = (state, { subjects }) => {
  state.subjects = subjects;
};

const GET_SUBJECT = (state, { subjects }) => {
  state.subjects = subjects;
};

const GET_CYCLE = (state, { cycles }) => {
  state.cycles = cycles;
};

const LIST_COMPETENCES = (state, { competences }) => {
  state.competences = competences;
};

const LIST_COMPETENCES_WITH_TOPICS = (state, { competences }) => {
  state.competences = competences;
};

const LIST_TOPICS_BY_COMPETENCE = (state, { topics }) => {
  if(!topics.length) return []

  const competence_id = topics[0].competence_id
  state.topicsByCompetences[competence_id] = topics;
};

const LIST_TOPICS = (state, { topics }) => {
  state.topics = topics;
};

const RESET_STATE = (state) => {
  state.cycles = []
  state.subjects = []
  state.competences = []
  state.topicsByCompetences = {}
  state.topics = []
  state.countries = []
  state.states = []
  state.cities = []
  state.users = []
};

const SET_COUNTRIES = (state, countries) => {
  state.countries = countries
}

const SET_STATES = (state, states) => {
  state.states = states
}

const SET_CITIES = (state, cities) => {
  state.cities = cities
}

const GET_USER = (state, { user }) => {
  state.users = user
}

const GET_STUDENT_ATTENDANCES = (state, students) => {
  state.studentsAttendances = students
}

const GET_STUDENT_CYCLE = (state, { student }) => {
  state.studentsCycle = student
}

const GET_STUDENT_SUBJECTS = (state, { subjects }) => {
  state.studentsSubjects = subjects
}

const SET_CALENDARS = (state, { calendars }) => {
  state.calendars = calendars
}

export default {
  CYCLES_UPDATE,
  SUBJECTS_UPDATE,
  GET_SUBJECT,
  GET_CYCLE,
  LIST_COMPETENCES,
  LIST_COMPETENCES_WITH_TOPICS,
  LIST_TOPICS_BY_COMPETENCE,
  LIST_TOPICS,
  RESET_STATE,
  SET_COUNTRIES,
  SET_STATES,
  SET_CITIES,
  GET_USER,
  GET_STUDENT_ATTENDANCES,
  GET_STUDENT_CYCLE,
  GET_STUDENT_SUBJECTS,
  SET_CALENDARS
}