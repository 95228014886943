<template>
  <div class="home">
    <template>
      <b-container class="container-rda">
        <b-row>
          <b-col>
            <b-breadcrumb :items="breadcrumb"></b-breadcrumb>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <h2 class="purple bold">Ciclos, turmas e tutores</h2>
          </b-col>
          <b-col class="text-right"></b-col>
        </b-row>
        <b-row class="mt-50">
          <b-col>
            <b-form-group label="Buscar Ciclo, turmas e tutores">
              <b-input-group-prepend>
                <b-form-input
                  type="search"
                  placeholder="Digite aqui"
                  v-model="searchTitle"
                  v-on:keyup.enter="getList()"
                ></b-form-input>
                <b-input-group-text
                  class="clear-fields"
                  v-if="searchTitle != ''"
                  v-b-tooltip.hover
                  title="Limpar Pesquisa"
                >
                  <b-icon icon="x" @click="clearFields" />
                </b-input-group-text>
              </b-input-group-prepend>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <div class="order-grid-box">
              <p
                class="order-list"
                :class="{ desc: descOrder }"
                v-on:click="orderName"
              >
                Ordenar
                <OrderNameIcon />
              </p>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-row class="column-list">
              <b-col>
                <p class="bold">Ciclo</p>
              </b-col>
            </b-row>
            <div class="accordion list cycles-discipline-tutors" role="tablist">
              <b-card
                no-body
                class="list-cycles-discipline-tutors"
                v-for="cycle in orderByNameCycles"
                v-bind:key="cycle.id"
              >
                <b-card-header header-tag="header" class role="tab">
                  <a v-b-toggle="'cycle-' + cycle.id">
                    <p>{{ cycle.designation }}</p>
                    <p class="qty">{{ cycle.Students.length }}</p>
                  </a>
                </b-card-header>
                <b-collapse
                  :id="'cycle-' + `${cycle.id}`"
                  accordion="cicles-accordion"
                  role="tabpanel"
                >
                  <b-card-body>
                    <div
                      class="names"
                      v-for="student in cycles
                        .find((s) => s.id == cycle.id)
                        .Students.filter(
                          (s) => s.User != null && s.User !== undefined
                        )"
                      v-bind:key="student.id"
                    >
                      <p>{{ student.User.name }}</p>
                      <router-link
                        :to="{ name: 'perfil-aluno', params: { id: student.id } }"
                      >
                        <ViewIcon />
                      </router-link>
                    </div>
                    <div
                      class="names"
                      v-if="cycle.Students.length <= 0 && isRequestSend"
                    >
                      <p>Nenhum aluno encontrado</p>
                    </div>
                  </b-card-body>
                </b-collapse>
              </b-card>
              <b-card
                no-body
                class="list-cycles-discipline-tutors"
                v-if="!isRequestSend"
              >
                <b-card-header header-tag="header" class role="tab">
                  <a v-b-toggle>
                    <p>Carregando</p>
                  </a>
                </b-card-header>
              </b-card>
              <b-card
                no-body
                class="list-cycles-discipline-tutors"
                v-if="cycles.length <= 0 && isRequestSend"
              >
                <b-card-header header-tag="header" class role="tab">
                  <a v-b-toggle>
                    <p>Nenhum resultado encontrado</p>
                  </a>
                </b-card-header>
              </b-card>
            </div>
          </b-col>
          <b-col>
            <b-row class="column-list">
              <b-col>
                <p class="bold">Turma</p>
              </b-col>
            </b-row>
            <div class="accordion list cycles-discipline-tutors" role="tablist">
              <b-card
                no-body
                class="list-cycles-discipline-tutors"
                v-for="classroom in orderByNameClassrooms"
                v-bind:key="classroom.id"
              >
                <b-card-header header-tag="header" class role="tab">
                  <a v-b-toggle="'classroom-' + classroom.id">
                    <p>{{ classroom.designation }}</p>
                    <p class="qty">{{ classroom.Students.length }}</p>
                  </a>
                </b-card-header>
                <b-collapse
                  :id="'classroom-' + `${classroom.id}`"
                  accordion="disciplines-accordion"
                  role="tabpanel"
                >
                  <b-card-body>
                    <div
                      class="names"
                      v-for="student in classrooms
                        .find((s) => s.id == classroom.id)
                        .Students.filter(
                          (s) => s.User != null && s.User !== undefined
                        )"
                      v-bind:key="student.id"
                    >
                      <p>{{ student.User.name }}</p>
                      <div class="two-icons">
                        <router-link
                          :to="{
                            name: 'perfil-aluno',
                            params: { id: student.id },
                          }"
                        >
                          <ViewIcon />
                        </router-link>
                        <a
                          href
                          v-on:click.prevent="
                            deleteStudentClassroom(student.id)
                          "
                        >
                          <DeleteIcon />
                        </a>
                      </div>
                    </div>
                    <div
                      class="names"
                      v-if="classroom.Students.length <= 0 && isRequestSend"
                    >
                      <p>Nenhum aluno encontrado</p>
                    </div>
                  </b-card-body>
                </b-collapse>
              </b-card>
              <b-card
                no-body
                class="list-cycles-discipline-tutors"
                v-if="!isRequestSend"
              >
                <b-card-header header-tag="header" class role="tab">
                  <a v-b-toggle>
                    <p>Carregando</p>
                  </a>
                </b-card-header>
              </b-card>
              <b-card
                no-body
                class="list-cycles-discipline-tutors"
                v-if="classrooms.length <= 0 && isRequestSend"
              >
                <b-card-header header-tag="header" class role="tab">
                  <a v-b-toggle>
                    <p>Nenhum resultado encontrado</p>
                  </a>
                </b-card-header>
              </b-card>
            </div>
          </b-col>
          <b-col>
            <b-row class="column-list">
              <b-col>
                <p class="bold">Tutores</p>
              </b-col>
            </b-row>
            <div class="accordion list cycles-discipline-tutors" role="tablist">
              <b-card
                no-body
                class="list-cycles-discipline-tutors"
                v-for="tutor in orderByNameTutors"
                v-bind:key="tutor.teacher_id"
              >
                <b-card-header header-tag="header" class role="tab">
                  <a v-b-toggle="'tutor-' + tutor.teacher_id">
                    <p>{{ tutor.name }}</p>
                    <p class="qty">{{ tutor.students.length }}</p>
                  </a>
                </b-card-header>
                <b-collapse
                  :id="'tutor-' + `${tutor.teacher_id}`"
                  accordion="tutors-accordion"
                  role="tabpanel"
                >
                  <b-card-body>
                    <div
                      class="names"
                      v-for="student in tutor.students"
                      v-bind:key="student.id"
                    >
                      <p>{{ student.name }}</p>
                      <div class="two-icons">
                        <router-link
                          :to="{
                            name: 'perfil-aluno',
                            params: { id: student.id },
                          }"
                        >
                          <ViewIcon />
                        </router-link>
                      </div>
                    </div>
                    <div
                      class="names"
                      v-if="tutor.students.length <= 0 && isRequestSend"
                    >
                      <p>Nenhum aluno encontrado</p>
                    </div>
                  </b-card-body>
                </b-collapse>
              </b-card>
              <b-card
                no-body
                class="list-cycles-discipline-tutors"
                v-if="!isRequestSend"
              >
                <b-card-header header-tag="header" class role="tab">
                  <a v-b-toggle>
                    <p>Carregando</p>
                  </a>
                </b-card-header>
              </b-card>
              <b-card
                no-body
                class="list-cycles-discipline-tutors"
                v-if="tutors.length <= 0 && isRequestSend"
              >
                <b-card-header header-tag="header" class role="tab">
                  <a v-b-toggle>
                    <p>Nenhum resultado encontrado</p>
                  </a>
                </b-card-header>
              </b-card>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </template>
  </div>
</template>

<script>
import DataServiceClassrooms from "@/services/classrooms.service";
import DataServiceCycles from "@/services/cycles.service";
import DataServiceTutors from "@/services/tutors.service";
import DataServiceStudents from "@/services/students.service";

import OrderNameIcon from "@/components/svg/OrderNameIcon.vue";
import ViewIcon from "@/components/svg/ViewIcon.vue";
import DeleteIcon from "@/components/svg/DeleteIcon.vue";

export default {
  name: "ciclos-turmas-tutores",
  components: {
    OrderNameIcon,
    ViewIcon,
    DeleteIcon,
  },
  data() {
    return {
      id: "",
      cycles: [],
      classrooms: [],
      tutors: [],

      isRequestSend: false,
      descOrder: false,
      searchTitle: "",

      breadcrumb: [
        {
          text: "Comunidade",
          href: "/comunidade",
        },
        {
          text: "Ciclos, turmas e tutores",
          href: "/ciclos-turmas-tutores",
          active: true,
        },
      ],
    };
  },
  methods: {
    getRequestParams(searchTitle) {
      let params = {};

      if (searchTitle) {
        params["search"] = searchTitle;
      }

      return params;
    },

    getList() {
      const params = this.getRequestParams(this.searchTitle);

      DataServiceCycles.getStudents(params)
        .then((response) => {
          this.cycles = response.data.cycles;
          this.isRequestSend = true;
        })
        .catch((error) => {
          return error;
        });

      DataServiceClassrooms.getStudents(params)
        .then((response) => {
          this.classrooms = response.data.classrooms;
          this.isRequestSend = true;
        })
        .catch((error) => {
          return error;
        });

      DataServiceTutors.getTeachersTutors(params)
        .then((response) => {
          this.tutors = response.data.response;
          this.isRequestSend = true;
        })
        .catch((error) => {
          return error;
        });
    },

    deleteStudentClassroom(id) {
      var data = {
        classroom_id: null,
      };
      this.$swal
        .fire({
          title: "Tem certeza?",
          text: "Você não poderá reverter isso!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#68c5b7",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim, excluir!",
          cancelButtonText: "Cancelar",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            try {
              await DataServiceStudents.deleteStudentFromClassroom(id, data);
              this.$swal
                .fire("Excluído!", "Aluno foi removido.", "success")
                .then((response) => {
                  this.getList();

                  return response;
                });
            } catch (error) {
              this.$swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Não foi possivel remover!",
                footer: "Por favor, tente novamente mais tarde.",
              });

              return error;
            }
          }
        })
        .catch((error) => {
          this.$swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Não foi possivel excluir!",
            footer: "Por favor, tente novamente mais tarde.",
          });

          return error;
        });
    },

    orderName() {
      this.descOrder = !this.descOrder;
    },

    clearFields(searchTitle) {
      this.searchTitle = "";
      this.getList({ search: searchTitle });
    },

    orderLists(array, orderby) {
      return [...array].sort((a, b) => {
        const order = this.descOrder ? [1, -1] : [-1, 1];

        if (a[orderby] < b[orderby]) return order[0];
        else if (a[orderby] > b[orderby]) return order[1];

        return 0;
      });
    },
  },

  mounted() {
    this.getList();
  },

  computed: {
    orderByNameCycles() {
      return this.orderLists(this.cycles, "designation");
    },

    orderByNameClassrooms() {
      return this.orderLists(this.classrooms, "designation");
    },

    orderByNameTutors() {
      return [...this.tutors].sort((a, b) => {
        const order = this.descOrder ? [1, -1] : [-1, 1];

        if (a.name < b.name) return order[0];
        else if (a.name > b.name) return order[1];

        return 0;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/variables.scss";

.list-cycles-discipline-tutors {
  background-color: $white;
  border: 1px solid $grey5;
  border-radius: 10px;
  margin-bottom: 15px;
  margin-right: 12px;
  &.card:not(:first-of-type) {
    border-radius: 10px;
  }
  &.card:not(:last-of-type) {
    border: 1px solid $grey5;
    border-radius: 10px;
    &:hover {
      cursor: pointer;
      border-color: transparent;
      background-image: linear-gradient($white, $white),
        radial-gradient(circle at top left, $orange, $purple);
      background-origin: border-box;
      background-clip: content-box, border-box;
    }
  }
  &:last-child {
    margin-bottom: 0px;
  }
  &:hover {
    cursor: pointer;
    border-color: transparent;
    background-image: linear-gradient($white, $white),
      radial-gradient(circle at top left, $orange, $purple);
    background-origin: border-box;
    background-clip: content-box, border-box;
  }
  p {
    margin: 0;
  }
  .qty {
    background-color: $blue;
    border-radius: 5px;
    color: $white;
    font-weight: $bold;
    line-height: 26px;
    max-width: 52px;
    text-align: center;
    width: 100%;
  }
  .card-header {
    background-color: $white;
    border: 0;
    padding: 0;
    a {
      align-items: center;
      display: flex;
      justify-content: space-between;
      padding: 26px 20px;
    }
  }
  .card-body {
    padding: 0 20px;
    .names {
      border-bottom: 1px solid $grey5;
      display: flex;
      justify-content: space-between;
      padding: 20px 0;
      &:last-child {
        border-bottom: 0;
      }
      .two-icons {
        a {
          margin: 0 15px;
        }
      }
    }
  }
}

.list {
  &.cycles-discipline-tutors {
    height: 650px;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 6px;
      border-radius: 5px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
      border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background: $grey5;
      border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: $purple;
    }
  }
}

.column-list {
  color: $grey1;
  margin: 30px 0 0;
  p {
    margin: 0 0 5px 0;
  }
}
</style>
