import http from "../http-rda";

class DataService {
  getAll(params) {
    return http.get("/calendars", { params });
  }

  get(id) {
    return http.get(`/calendars/${id}`);
  }

  create(data) {
    return http.post("/calendars", data);
  }

  createHoliday(id, data) {
    return http.post(`/calendars/${id}/holidays`, data);
  }

  countBusinessDays(data) {
    return http.post(`/calculate_days`, data);
  }

  update(id, data) {
    return http.patch(`/calendars/${id}`, { data });
  }

  delete(id) {
    return http.delete(`/calendars/${id}`);
  }

  findByTitle(designation) {
    return http.get(`/calendars?search=${designation}`);
  }
}

export default new DataService();
