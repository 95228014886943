<template>
  <b-container class="container-rda login">
    <b-row class="form-login">
      <b-col>
        <div v-if="!successRequest" class="box-login">
          <h2>Recuperar senha</h2>
          <p class="mb-4">
            Digite o seu endereço de e-mail cadastrado. Você receberá um e-mail
            com instruções sobre como redefinir a sua senha.
          </p>
          <form name="form" @submit.prevent="submitResetPassword">
            <b-form-group>
              <label for="label">Endereço de e-mail</label>
              <b-form-input
                v-model="email"
                v-validate="'required'"
                type="text"
                name="email"
                placeholder="Digite aqui o seu e-mail de cadastro"
                class="form-control"
              ></b-form-input>
              <div
                v-if="errors.has('email')"
                class="alert alert-danger"
                role="alert"
              >
                Campo obrigatório
              </div>
            </b-form-group>
            <button :disabled="loading" class="mt-4 btn full btn-login">
              <span
                v-show="loading"
                class="spinner-border spinner-border-sm"
              ></span>
              <span>Obter nova senha</span>
            </button>
            <div class="mt-3 d-block text-center">
              <router-link :to="{ name: 'login' }" class="white"
                >Voltar para tela de acesso</router-link
              >
            </div>
          </form>
        </div>
        <div v-if="!successRequest && message" role="alert">
          <p class="mt-4 text-center message-error">
            <InfoIcon /> E-mail não cadastrado no sistema. Insira outro possível
            endereço ou contate a secretaria
          </p>
        </div>
        <div v-if="successRequest" class="box-login">
          <h2 class="mb-3">E-mail enviado!</h2>
          <p class="mb-4">
            Verifique a sua caixa de entrada para obter as instruções.
          </p>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapActions } from "vuex";

import InfoIcon from "@/components/svg/InfoIcon.vue";

export default {
  name: "login",
  components: {
    InfoIcon,
  },
  data() {
    return {
      loading: false,
      message: "",
      successRequest: false,

      email: "",
    };
  },

  methods: {
    ...mapActions({
      resetPassword: "shared/resetPassword",
    }),
    async submitResetPassword() {
      const data = {
        email: this.email,
      };

      this.$validator.validateAll().then((isValid) => {
        if (!isValid) {
          this.loading = false;
          return;
        }

        this.loading = true;

        this.resetPassword(data)
          .then((response) => {
            this.loading = false;
            this.successRequest = true;

            return response;
          })
          .catch((error) => {
            this.loading = false;

            this.message =
              (error.response && error.response.data) ||
              error.message ||
              error.toString();
          });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/variables.scss";

.container-rda {
  display: flex;
  justify-content: center;
}

.login {
  background: url("~@/assets/images/bg-login.jpg");
  background-position: center;
  background-size: cover;
  height: 100%;
  max-width: 100%;
  padding: 0;
  .form-login {
    align-items: center;
    height: 100%;
    width: 100%;
    .box-login {
      max-width: 575px;
      margin: 0 auto;
      h2,
      p,
      label {
        color: $white;
      }
      .btn-login {
        text-transform: uppercase;
      }
      a {
        color: $white;
        font-size: 18px;
        line-height: 32px;
      }
    }
  }

  .spinner-border-sm {
    vertical-align: text-top;
    margin-right: 10px;
  }

  .message-error {
    background-color: $orange;
    border-radius: 5px;
    border: 1px solid $white;
    color: $white;
    font-size: 16px;
    font-weight: 700;
    line-height: 16px;
    margin: 0 auto;
    max-width: 710px;
    padding: 12px 20px;
    svg {
      vertical-align: text-top;
      margin-right: 8px;
    }
  }
}
</style>
