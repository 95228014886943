var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',[_c('b-col',{staticClass:"mt-5"},[_c('h3',{staticClass:"mb-4"},[_vm._v("Evolução do aluno em relação ao currículo")])]),_c('div',{staticClass:"w-100"}),_c('b-col',[(_vm.subjects.length > 0)?_c('div',{staticClass:"graphs"},[_c('ul',{staticClass:"lines-percentage"},[_c('li',{staticClass:"desired-percentage",style:({ left: _vm.desiredPercentage - 9 + '%' })},[_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"title":"Progresso esperado"}}),_c('b',{staticClass:"line-number desired-number"},[_vm._v(_vm._s(_vm.desiredPercentage)+"%")])]),_c('li',{staticClass:"line-number-zero"},[_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"title":"Progresso esperado"}}),_c('b',{staticClass:"line-number"},[_vm._v("0%")])]),_vm._l((_vm.percentages),function(percentage,index){return _c('li',{key:index,style:({ left: index * 10 + '%' })},[_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"title":"Progresso esperado"}}),_c('b',{staticClass:"line-number"},[_vm._v(_vm._s(percentage.number)+"%")])])})],2),_vm._l((_vm.subjects),function(subject){return _c('div',{key:subject.id,staticClass:"line"},[_c('p',{staticClass:"bold"},[_vm._v(_vm._s(subject.designation))]),_c('b-progress',{class:("" + (subject.designation === 'Artes Visuais' ? 'artes-visuais' : '')) ||
            ("" + (subject.designation === 'Ciências da Natureza'
                ? 'ciencias-natureza'
                : '')) ||
            ("" + (subject.designation === 'Ciências Humanas e Sociais'
                ? 'ciencias-humanas'
                : '')) ||
            ("" + (subject.designation === 'Corpo e Mente' ? 'corpo-mente' : '')) ||
            ("" + (subject.designation === 'Dança' ? 'danca' : '')) ||
            ("" + (subject.designation === 'História' ? 'historia' : '')) ||
            ("" + (subject.designation === 'Língua Portuguesa'
                ? 'lingua-portuguesa'
                : '')) ||
            ("" + (subject.designation === 'Matemática' ? 'matematica' : '')) ||
            ("" + (subject.designation === 'Espanhol' ? 'espanhol' : '')) ||
            ("" + (subject.designation === 'Música' ? 'musica' : '')) ||
            ("" + (subject.designation === 'Cidadania e Vida'
                ? 'cidadania-vida'
                : '')) ||
            ("" + (subject.designation === 'Cidadania e Projeto de Vida'
                ? 'cidadania-vida'
                : '')) ||
            ("" + (subject.designation === 'Teatro' ? 'teatro' : '')) ||
            ("" + (subject.designation === 'Inglês' ? 'ingles' : '')) ||
            ("" + (subject.designation === 'Língua Estrangeira'
                ? 'lingua-estrangeira'
                : '')),attrs:{"max":"100"}},[_c('b-progress-bar',{attrs:{"value":subject.percentage,"label":((((subject.percentage / 100) * 100).toFixed(0)) + "%")}})],1)],1)})],2):_c('div',[_c('p',[_vm._v("Aluno não possui Disciplinas associadas")])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }