<template>
  <div class="home">
    <template>
      <b-container class="container-rda">
        <b-row>
          <b-col>
            <b-breadcrumb :items="breadcrumb"></b-breadcrumb>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <h2 class="purple bold">
              Contatos e responsáveis
              <span class="qty" v-if="count <= 1"
                >({{ countAll }} de {{ count }} aluno ativo)</span
              >
              <span class="qty" v-else
                >({{ countAll }} de {{ count }} alunos ativos)</span
              >
            </h2>
          </b-col>
          <b-col class="text-right">
            <b-button v-if="false" class="btn large blue" href="#"
              >Exportar CSV</b-button
            >
          </b-col>
        </b-row>
        <b-row class="mt-50">
          <b-col md="3">
            <SelectCycles
              v-model="cycle"
              :callback="callbackCycles"
            ></SelectCycles>
            <span
              class="clear-field"
              v-if="this.cycle != null"
              @click="clearCycle"
              >Limpar filtro</span
            >
          </b-col>
          <b-col>
            <b-form-group label="Buscar">
              <b-input-group-prepend>
                <b-form-input
                  type="search"
                  placeholder="Digite aqui"
                  v-model="searchTitle"
                  v-on:keyup.enter="
                    page = 1;
                    getList();
                  "
                ></b-form-input>
                <b-input-group-text
                  class="clear-fields"
                  v-if="searchTitle != ''"
                  v-b-tooltip.hover
                  title="Limpar Pesquisa"
                >
                  <b-icon icon="x" @click="clearFields" />
                </b-input-group-text>
              </b-input-group-prepend>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <div class="order-grid-box">
              <p
                class="order-list"
                :class="{ desc: descOrder }"
                v-on:click="orderName"
              >
                Ordenar
                <OrderNameIcon />
              </p>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-container fluid>
              <b-row
                class="column-list"
                v-if="enrollments.length > 0 && isRequestSend"
              >
                <b-col md="2">
                  <p class="bold">Aluno</p>
                </b-col>
                <b-col md="1">
                  <p class="bold">Ciclo</p>
                </b-col>
                <b-col>
                  <p class="bold">Responsável</p>
                </b-col>
                <b-col>
                  <p class="bold">Documento</p>
                </b-col>
                <b-col>
                  <p class="bold">Contato</p>
                </b-col>
                <b-col>
                  <p class="bold">Endereço</p>
                </b-col>
              </b-row>
            </b-container>
            <b-container fluid>
              <b-row
                class="list-contracts"
                v-for="enrollment in orderbyName"
                :key="enrollment.id"
              >
                <b-col md="2">
                  <p>{{ enrollment.EnrollmentStudent.name }}</p>
                  <img
                    v-if="!enrollment.EnrollmentStudent.photo"
                    width="136"
                    src="@/assets/images/default-photo.png"
                    alt
                  />
                  <img
                    v-else
                    width="136"
                    height="136"
                    :src="enrollment.EnrollmentStudent.photo"
                    alt
                  />
                </b-col>
                <b-col md="1">
                  <p>
                    {{ getCycleName(enrollment.EnrollmentStudent.cycle_id) }}
                  </p>
                </b-col>
                <b-col class="divider">
                  <p v-if="enrollment.Responsible">
                    {{ enrollment.Responsible.User.name }}
                    <br />
                    <strong>{{ enrollment.Responsible.relationship }}</strong>
                  </p>
                  <p class="grey4" v-else>Cadastro incompleto</p>
                </b-col>
                <b-col class="divider">
                  <p v-if="enrollment.Responsible">
                    CPF:
                    <strong>{{ enrollment.Responsible.User.cpf }}</strong>
                    <br />RG:
                    <strong>{{ enrollment.Responsible.User.rg }}</strong>
                  </p>
                  <p class="grey4" v-else>Cadastro incompleto</p>
                </b-col>
                <b-col class="divider">
                  <p v-if="enrollment.Responsible">
                    Email:
                    <strong>{{ enrollment.Responsible.User.email }}</strong>
                    <br />Telefone:
                    <strong
                      v-if="
                        enrollment.Responsible.User.Phones.find(
                          (p) => p.is_mobile == false
                        )
                      "
                    >
                      {{
                        enrollment.Responsible.User.Phones.find(
                          (p) => p.is_mobile == false
                        ).number
                      }}
                    </strong>
                    <br />
                    <span
                      v-if="
                        enrollment.Responsible.User.Phones.find(
                          (p) => p.is_mobile == true
                        )
                      "
                    >
                      Celular:
                      <strong
                        v-if="
                          enrollment.Responsible.User.Phones.find(
                            (p) => p.is_mobile == true
                          ).number
                        "
                      >
                        {{
                          enrollment.Responsible.User.Phones.find(
                            (p) => p.is_mobile == true
                          ).number
                        }}
                      </strong>
                    </span>
                  </p>
                  <p class="grey4" v-else>Cadastro incompleto</p>
                </b-col>
                <b-col class="divider">
                  <p v-if="enrollment.Responsible">
                    {{ enrollment.Responsible.User.Address.street }},
                    {{ enrollment.Responsible.User.Address.number }}
                    <br />
                    {{ enrollment.Responsible.User.Address.neighborhood }},
                    {{ enrollment.Responsible.User.Address.city }}
                    <br />
                    {{ enrollment.Responsible.User.Address.cep }},
                    {{ enrollment.Responsible.User.Address.state }},
                    {{ enrollment.Responsible.User.Address.country }}
                  </p>
                  <p class="grey4" v-else>Cadastro incompleto</p>
                </b-col>
              </b-row>
              <b-row
                align-v="center"
                class="list-teacher"
                v-if="enrollments.length <= 0 && isRequestSend"
              >
                <b-col>Nenhum resultado encontrado</b-col>
              </b-row>
              <b-row
                align-v="center"
                class="list-teacher"
                v-if="!isRequestSend"
              >
                <b-col>Carregando</b-col>
              </b-row>
              <b-row
                class="align-center"
                v-else-if="count > perPage && isRequestSend"
              >
                <b-pagination
                  v-model="page"
                  :per-page="perPage"
                  :total-rows="count"
                  @change="changePage"
                  aria-controls="teachersList"
                  last-text
                  first-text
                >
                  <template #prev-text>
                    <LeftArrowIcon class="left" />
                  </template>
                  <template #next-text>
                    <LeftArrowIcon />
                  </template>
                </b-pagination>
              </b-row>
            </b-container>
          </b-col>
        </b-row>
      </b-container>
    </template>
  </div>
</template>

<script>
import DataService from "@/services/enrollments.service";
import DataServiceCycles from "@/services/cycles.service";

import OrderNameIcon from "@/components/svg/OrderNameIcon.vue";
import LeftArrowIcon from "@/components/svg/LeftArrowIcon.vue";
import SelectCycles from "@/components/inputs/SelectCycles.vue";

export default {
  name: "contatos-responsaveis",
  components: {
    OrderNameIcon,
    SelectCycles,
    LeftArrowIcon,
  },
  data() {
    return {
      enrollments: [],
      descOrder: false,
      isRequestSend: false,
      countAll: null,

      cycle: null,
      searchTitle: "",

      page: this.$route.params.page || 1,
      count: 0,
      perPage: 20,

      breadcrumb: [
        {
          text: "Secretaria",
          href: "/secretaria",
        },
        {
          text: "Contatos e responsáveis",
          href: "/contatos-responsaveis",
          active: true,
        },
      ],
    };
  },

  filters: {
    date(date) {
      try {
        var d = new Date(date.split());
        var year = d.getFullYear().toString();
        var month = (d.getMonth() + 1).toString().padStart(2, "0");
        var day = d.getUTCDate().toString().padStart(2, "0");

        return day + "/" + month + "/" + year;
      } catch (error) {
        return date;
      }
    },
  },

  computed: {
    orderbyName() {
      return [...this.enrollments].sort((a, b) => {
        const order = this.descOrder ? [1, -1] : [-1, 1];

        if (a.EnrollmentStudent.name < b.EnrollmentStudent.name)
          return order[0];
        else if (a.EnrollmentStudent.name > b.EnrollmentStudent.name)
          return order[1];

        return 0;
      });
    },
  },

  methods: {
    getRequestParams(searchTitle, page, perPage, cycle) {
      let params = {};

      if (searchTitle) {
        params["search"] = searchTitle;
      }

      if (page) {
        params["page"] = page;
      }

      if (perPage) {
        params["limit"] = perPage;
      }

      if (cycle) {
        params["cycle_id"] = cycle;
      }

      return params;
    },

    getList() {
      const params = this.getRequestParams(
        this.searchTitle,
        this.page,
        this.perPage,
        this.cycle
      );

      DataService.getAll(params)
        .then((response) => {
          this.enrollments = response.data.enrollments;
          this.countAll = response.data.count;
          this.isRequestSend = true;
        })
        .catch((error) => {
          return error.data;
        });
    },

    getParams(params) {
      DataServiceCycles.getAll(params)
        .then((response) => {
          this.cycles = response.data.cycles;
        })
        .catch((error) => {
          return error.data;
        });

      DataService.getEnrollmentsActives()
        .then((response) => {
          this.count = response.data.count_active;
        })
        .catch((error) => {
          return error.data;
        });
    },

    orderName() {
      this.descOrder = !this.descOrder;
    },

    changePage(page) {
      this.page = page;
      this.getList();
      window.scrollTo({ top: 0, behavior: "smooth" });
    },

    callbackCycles({ id }) {
      this.cycle = id !== -1 ? id : null;
      this.currentPage = 1;
      this.getList();
    },

    clearFields() {
      this.searchTitle = "";
      this.currentPage = 1;
      this.getList();
    },

    getCycleName(id) {
      if (this.cycles) {
        const cycles = this.cycles.find((c) => c.id === id);
        return cycles ? cycles.name : "-";
      }
    },

    getCalendarName(id) {
      if (this.calendars) {
        const calendars = this.calendars.find((c) => c.id === id);
        return calendars ? calendars.designation : "-";
      }
    },
  },

  created() {
    this.getList();
    this.getParams();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/variables.scss";

.list-contracts {
  background-color: $white;
  border: 1px solid $grey5;
  border-radius: 10px;
  margin-bottom: 15px;
  min-height: 80px;
  .col,
  .col-md-1,
  .col-md-2 {
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .col-md-2 {
    &.divider {
      p {
        min-height: 70px;
      }
      &:after {
        background-color: $grey7;
        content: "";
        display: block;
        height: 1px;
        left: 0;
        top: 50%;
        position: absolute;
        width: 100%;
      }
    }
    &.divider:last-child {
      &:after {
        width: calc(100% - 15px);
      }
    }
  }
  img {
    border-radius: 50%;
    margin-top: 15px;
  }
  &:last-child {
    margin-bottom: 0px;
  }
  &:hover {
    cursor: pointer;
    border-color: transparent;
    background-image: linear-gradient($white, $white),
      radial-gradient(circle at top left, $orange, $purple);
    background-origin: border-box;
    background-clip: content-box, border-box;
  }
  p {
    margin: 0;
  }
}

.header-list {
  color: $grey1;
  p {
    margin: 0;
  }
  .tags {
    background-color: $blue;
    border-radius: 5px;
    color: $white;
    line-height: 28px;
    max-width: 58px;
    text-align: center;
  }
}

.column-list {
  color: $grey1;
}
</style>
