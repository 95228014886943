<template>
  <div
    :class="`modal-files ${extenssion == 'pdf' && 'pdf'}`"
    v-if="opening"
    @keydown.esc="handleOnClose"
  >
    <div class="back" @click="handleOnClose"></div>
    <div :class="`modal-content ${extenssion == 'pdf' && 'pdf'}`">
      <div class="title">
        <h4>Visualização</h4>
        <span @click="handleOnClose" class="close">
          <Close />
        </span>
      </div>
      <iframe
        v-if="extenssion == 'pdf'"
        :src="`${path}#toolbar=0&navpanes=0`"
        frameborder="0"
      ></iframe>
      <img v-else :src="path" alt="" />
    </div>
  </div>
</template>

<script>
import Close from "@/components/svg/Close.vue";
export default {
  name: "FilesViewModal",
  components: {
    Close,
  },

  props: {
    type: "pdf" || "image",
    opening: {
      type: Boolean,
    },
    onClose: {
      type: Function,
    },
    path: {
      type: String,
    },
  },

  data() {
    return {
      //
    };
  },

  computed: {
    extenssion() {
      return this.type.split(".")[1];
    },
  },

  watch: {
    //
  },

  methods: {
    handleOnClose() {
      this.$emit("onClose");
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/css/variables.scss";
.modal-files {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  box-sizing: border-box;
  overflow-y: auto;
  overflow-x: hidden;

  .back {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 0;
  }

  .modal-content {
    width: 100%;
    max-width: 1024px;
    padding: 16px;
    position: relative;
    z-index: 100;
    box-sizing: border-box;

    iframe {
      width: 100%;
      height: 100%;
    }

    img {
      width: auto !important;
      max-height: 90vh;
      margin: 0;
      object-fit: contain;
    }

    &.pdf {
      height: 100vh;
    }

    .title {
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: flex-end;
      margin-bottom: 16px;

      h4 {
        text-transform: none;
        font-size: 24px;
        flex: 1;
        margin: 0;
      }

      .close {
        opacity: 1;
        cursor: pointer;
      }
    }
  }
}
</style>
