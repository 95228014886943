<template>
  <b-container class="container-rda">
    <b-row>
      <b-col>
        <b-breadcrumb :items="_"></b-breadcrumb>
      </b-col>
    </b-row>
    <b-col>
      <b-tabs class="tab-content" content-class="mt-5">
        <b-tab v-if="!canEdit" title="Dados dos contratantes">
          <b-col v-if="enrollmentsResponsibles">
            <b-col
              v-for="(
                responsible, index
              ) in enrollmentsResponsibles.EnrollmentResponsibles"
              :key="index"
            >
              <b-row>
                <h2 class="purple bold">
                  {{ responsible.name }}
                </h2>
              </b-row>

              <b-row>
                <b-row>
                  <b-col>
                    <ProfilePhoto
                      :modalId="`contractor + ${index}`"
                      :photo="
                        typeof responsible.photo !== 'string'
                          ? photoContractor[index]
                          : responsible.photo
                      "
                      :callback="(photo) => setNewPhotoContractor(photo, index)"
                    />
                  </b-col>
                  <b-col>
                    <StandardInput
                      type="text"
                      name="name"
                      label="Nome"
                      v-model="responsible.name"
                    ></StandardInput>

                    <StandardInput
                      type="email"
                      name="email"
                      label="E-mail"
                      v-model="responsible.email"
                    ></StandardInput>

                    <h5>Documentos</h5>

                    <StandardInput
                      type="text"
                      name="cpf"
                      label="CPF"
                      v-mask="['###.###.###-##']"
                      v-model="responsible.cpf"
                    ></StandardInput>
                  </b-col>
                  <b-col>
                    <StandardInput
                      v-if="
                        responsible.Responsible &&
                        responsible.Responsible.User &&
                        responsible.Responsible.User.Phones.length > 0
                      "
                      type="text"
                      name="phone"
                      label="Telefone"
                      v-mask="['(##) #####-####']"
                      v-model="responsible.Responsible.User.Phones[0].number"
                    ></StandardInput>

                    <StandardInput
                      v-model="responsible.birth_date"
                      type="text"
                      name="birth_date"
                      label="Data de nascimento"
                      v-mask="'##/##/####'"
                      placeholder
                    />
                    <h5>&nbsp;</h5>
                    <StandardInput
                      type="text"
                      name="rg"
                      label="RG"
                      v-model="responsible.rg"
                    ></StandardInput>
                  </b-col>
                  <div class="w-100"></div>

                  <div class="w-100"></div>
                  <b-col v-if="responsible.Address" class="title">
                    <h5>Dados de endereço</h5>
                  </b-col>
                  <div class="w-100"></div>
                  <b-col v-if="responsible.Address">
                    <StandardInput
                      type="text"
                      name="cep"
                      label="CEP"
                      v-mask="['#####-###']"
                      v-model="responsible.Address.cep"
                    ></StandardInput>
                  </b-col>
                  <b-col v-if="responsible.Address">
                    <StandardInput
                      type="text"
                      name="city"
                      label="Cidade"
                      v-model="responsible.Address.city"
                    ></StandardInput>
                  </b-col>
                  <b-col v-if="responsible.Address">
                    <StandardInput
                      type="text"
                      name="neighborhood"
                      label="Bairro"
                      v-model="responsible.Address.neighborhood"
                    ></StandardInput>
                  </b-col>
                  <b-col v-if="responsible.Address">
                    <StandardInput
                      type="text"
                      name="address"
                      label="Rua"
                      v-model="responsible.Address.street"
                    ></StandardInput>
                  </b-col>
                  <div class="w-100"></div>
                  <b-col md="2" v-if="responsible.Address">
                    <StandardInput
                      type="text"
                      name="number"
                      label="Número"
                      v-model="responsible.Address.number"
                    ></StandardInput>
                  </b-col>
                  <b-col md="2" v-if="responsible.Address">
                    <StandardInput
                      type="text"
                      name="complement"
                      label="Complemento"
                      v-model="responsible.Address.complement"
                    ></StandardInput>
                  </b-col>
                </b-row>
              </b-row>
              <b-col class="divider" style="padding-top: 40px"></b-col>
            </b-col>

            <b-row class="row-buttons">
              <b-col>
                <b-button
                  @click="updateContractors"
                  class="btn large outline"
                  :disabled="!isValid('Contractor')"
                  v-if="!canEdit"
                  >Atualizar dados</b-button
                >
              </b-col>
            </b-row>
          </b-col>
        </b-tab>

        <b-tab v-if="!canEdit" title="Dados dos alunos">
          <b-col v-if="enrollmentStudent">
            <b-col>
              <b-row>
                <h2 class="purple bold">
                  {{ enrollmentStudent.name }}
                </h2>
              </b-row>
              <b-row>
                <p class="purple bold">
                  Referente à matrícula {{ enrollment.id }}
                </p>
              </b-row>
              <b-row>
                <b-row>
                  <b-col>
                    <ProfilePhoto
                      modalId="studentData"
                      :photo="
                        typeof enrollmentStudent.photo !== 'string'
                          ? photoStudent
                          : enrollmentStudent.photo
                      "
                      :callback="setNewStudentPhoto"
                    />
                  </b-col>
                  <b-col>
                    <StandardInput
                      type="text"
                      name="name"
                      label="Nome"
                      v-model="enrollmentStudent.name"
                    ></StandardInput>

                    <StandardInput
                      type="email"
                      name="email"
                      label="E-mail"
                      v-model="enrollmentStudent.email"
                    ></StandardInput>
                    <h5>Documentos</h5>
                    <StandardInput
                      type="text"
                      name="cpf"
                      label="CPF"
                      v-mask="['###.###.###-##']"
                      v-model="enrollmentStudent.cpf"
                    ></StandardInput>
                  </b-col>
                  <b-col>
                    <StandardInput
                      v-if="enrollmentStudent.Student.User.Phones.length > 0"
                      type="text"
                      name="phone"
                      label="Telefone"
                      v-mask="['(##) #####-####']"
                      v-model="enrollmentStudent.Student.User.Phones[0].number"
                    ></StandardInput>

                    <StandardInput
                      :v-model="enrollmentStudent.birth_date"
                      type="text"
                      name="birth_date"
                      label="Data de nascimento"
                      v-mask="'##/##/####'"
                      placeholder
                    />
                    <h5>&nbsp;</h5>
                    <StandardInput
                      type="text"
                      name="rg"
                      label="RG"
                      v-model="enrollmentStudent.rg"
                    ></StandardInput>
                  </b-col>
                  <div class="w-100"></div>
                  <b-col class="title"> </b-col>
                  <div class="w-100"></div>
                  <b-col md="3"> </b-col>
                  <b-col md="3"> </b-col>
                  <div class="w-100"></div>
                  <b-col v-if="enrollmentStudent.Address" class="title">
                    <h5>Dados de endereço</h5>
                  </b-col>
                  <div class="w-100"></div>
                  <b-col v-if="enrollmentStudent.Address">
                    <StandardInput
                      type="text"
                      name="cep"
                      label="CEP"
                      v-mask="['#####-###']"
                      v-model="enrollmentStudent.Address.cep"
                    ></StandardInput>
                  </b-col>
                  <b-col v-if="enrollmentStudent.Address">
                    <StandardInput
                      type="text"
                      name="city"
                      label="Cidade"
                      v-model="enrollmentStudent.Address.city"
                    ></StandardInput>
                  </b-col>
                  <b-col v-if="enrollmentStudent.Address">
                    <StandardInput
                      type="text"
                      name="neighborhood"
                      label="Bairro"
                      v-model="enrollmentStudent.Address.neighborhood"
                    ></StandardInput>
                  </b-col>
                  <b-col v-if="enrollmentStudent.Address">
                    <StandardInput
                      type="text"
                      name="address"
                      label="Rua"
                      v-model="enrollmentStudent.Address.street"
                    ></StandardInput>
                  </b-col>
                  <div class="w-100"></div>
                  <b-col md="2" v-if="enrollmentStudent.Address">
                    <StandardInput
                      type="text"
                      name="number"
                      label="Número"
                      v-model="enrollmentStudent.Address.number"
                    ></StandardInput>
                  </b-col>
                  <b-col md="2" v-if="enrollmentStudent.Address">
                    <StandardInput
                      type="text"
                      name="complement"
                      label="Complemento"
                      v-model="enrollmentStudent.Address.complement"
                    ></StandardInput>
                  </b-col>
                </b-row>
              </b-row>
              <b-col class="divider" style="padding-top: 40px"></b-col>
            </b-col>

            <b-row class="row-buttons">
              <b-col>
                <b-button
                  @click="updateStudents"
                  class="btn large outline"
                  :disabled="!isValid('Student')"
                  v-if="!canEdit"
                  >Atualizar dados</b-button
                >
              </b-col>
            </b-row>
          </b-col>
        </b-tab>

        <b-tab v-if="!canEdit" title="Autorizações da matrícula">
          <div
            v-for="(authorization, index) in authorizations"
            :key="index"
            style="padding-bottom: 20px"
          >
            <h3 class="purple bold">
              Referente à matrícula {{ authorization.enrollment_id }} do(a)
              aluno(a)
              {{ studentNameByEnrollmentId(authorization.enrollment_id) }}
            </h3>

            <Authorizations v-model="authorizations[index]" />
            <b-col class="divider" style="padding-top: 40px"></b-col>
          </div>
          <b-row class="row-buttons">
            <b-col>
              <b-button
                @click="createUpdateAuthorizations('update')"
                class="btn large outline"
                v-if="!canEdit"
                >Atualizar dados</b-button
              >
            </b-col>
          </b-row>
        </b-tab>

        <b-tab v-if="!canEdit" title="Complementos da matrícula">
          <div
            v-for="(complement, index) in complements"
            :key="index"
            style="padding-bottom: 20px"
          >
            <p class="purple bold">
              Referente à matrícula {{ complement.enrollment_id }} do(a)
              aluno(a)
              {{ studentNameByEnrollmentId(complement.enrollment_id) }}
            </p>
            <div class="form-content">
              <Complementary v-model="complements[index]" />
            </div>
            <b-col class="divider" style="padding-top: 40px"></b-col>
          </div>
          <b-row class="row-buttons">
            <b-col>
              <b-button
                @click="createUpdateComplement('update')"
                class="btn large outline"
                v-if="!canEdit"
                :disabled="!isValid('Complementary')"
                >Atualizar dados</b-button
              >
            </b-col>
          </b-row>
        </b-tab>

        <b-tab v-if="!canEdit" title="Anexos da matrícula">
          <div
            v-for="(submit, index) in submits"
            :key="index"
            style="padding-bottom: 20px"
          >
            <p class="purple bold">
              Referente à matrícula {{ submit.enrollmentId }} do(a) aluno(a)
              {{ studentNameByEnrollmentId(submit.enrollmentId) }}
            </p>
            <div class="form-content">
              <Submit
                v-model="submits[index].structure"
                :enrollmentId="submits[index].enrollmentId"
                :editedUserId="submits[index].editedUserId"
                :id="submit.enrollmentId"
              />
            </div>
            <b-col class="divider" style="padding-top: 40px"></b-col>
          </div>
        </b-tab>
      </b-tabs>
    </b-col>
  </b-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import DataService from "@/services/user.service";
import * as lang from "vuejs-datepicker/src/locale";
import store from "../../../../store";
import StandardInput from "@/components/inputs/StandardInput.vue";
import httpRda from "../../../../http-rda";
import Complementary from "../../../enroll/form/Complementary.vue";
import Authorizations from "../../../enroll/form/Authorizations.vue";
import Submit from "../../../enroll/form/Submit.vue";
import ProfilePhoto from "@/components/ProfilePhoto.vue";

import {
  FromBRDateToIsoString,
  FromIsoStringToBRDate,
} from "../../../../utils/fomatDate";

export default {
  name: "EnrollmentData",
  components: {
    StandardInput,
    Complementary,
    Authorizations,
    Submit,
    ProfilePhoto,
  },

  data() {
    return {
      selected: {
        user: {
          name: "",
          Phones: {},
        },
      },

      photoContractor: [],

      photoStudent: null,

      enrollment: null,

      enrollmentsResponsibles: [],

      enrollmentStudent: null,

      enrollmentsIds: [],

      newPhoto: null,
      blob: null,

      language: "ptBR",
      languages: lang,

      authorizations: [],
      complements: [],
      SubmitStructure: [
        {
          title: "Carteira de identidade do aluno (Frente e Verso)",
          isRequired: true,

          files: [
            {
              file: null,
              progress: null,
              status: false,
              fileName: "identity",
              url: "",
            },
            {
              file: null,
              progress: null,
              status: false,
              fileName: "identity_back",
              url: "",
            },
          ],
        },
        {
          title: "CPF do aluno",
          isRequired: true,

          files: [
            {
              file: null,
              progress: null,
              status: false,
              fileName: "cpf",
              url: "",
            },
          ],
        },
        {
          title: "Cartão de vacinas do aluno",
          isRequired: true,

          files: [
            {
              file: null,
              progress: null,
              status: false,
              fileName: "vaccination_record",
              url: "",
            },
          ],
        },
        {
          title: "Declaração de quitação emitida pela escola anterior",
          isRequired: true,

          files: [
            {
              file: null,
              progress: null,
              status: false,
              fileName: "discharge",
              url: "",
            },
          ],
        },
        {
          title: "Histórico escolar",
          isRequired: false,

          files: [
            {
              file: null,
              progress: null,
              status: false,
              fileName: "school_record",
              url: "",
            },
          ],
        },
        {
          title: "Carteira de identidade do contratante A (Frente e Verso)",
          isRequired: true,

          files: [
            {
              file: null,
              progress: null,
              status: false,
              fileName: "identity_contractor_a",
              url: "",
            },
            {
              file: null,
              progress: null,
              status: false,
              fileName: "identity_contractor_a_back",
              url: "",
            },
          ],
        },
        {
          title: "Carteira de identidade do contratante B (Frente e Verso)",
          isRequired: true,

          files: [
            {
              file: null,
              progress: null,
              status: false,
              fileName: "identity_contractor_b",
              url: "",
            },
            {
              file: null,
              progress: null,
              status: false,
              fileName: "identity_contractor_b_back",
              url: "",
            },
          ],
        },
        {
          title: "CPF dos contratantes",
          isRequired: true,

          files: [
            {
              file: null,
              progress: null,
              status: false,
              fileName: "cpf_contractor_a",
              url: "",
            },
            {
              file: null,
              progress: null,
              status: false,
              fileName: "cpf_contractor_b",
              url: "",
            },
          ],
        },
      ],

      submits: [],
    };
  },

  filters: {
    date(date) {
      try {
        var d = new Date(date.split());
        var year = d.getFullYear();
        var month = (d.getMonth() + 1).toString().padStart(2, "0");
        var day = d.getUTCDate().toString().padStart(2, "0");

        return day + "/" + month + "/" + year;
      } catch (error) {
        return date;
      }
    },
  },

  computed: {
    ...mapGetters({
      currentUser: "auth/getAuthData",
      users: "shared/users",
      students: "shared/studentsCycle",
    }),

    canEdit() {
      if (!this.currentUser.role) return false;
      return !["coordinator", "teacher", "parent", "secretary"].includes(
        this.currentUser.role[0]
      );
    },
  },

  methods: {
    ...mapActions({
      getUser: "shared/getUser",
      getStudentCycle: "shared/getStudentCycle",
    }),

    isCompoundName(name) {
      name = name.trim();
      const nameArr = name.split(" ");

      if (nameArr.length >= 2) {
        return true;
      } else {
        return false;
      }
    },

    isValid(type) {
      function checkAddressFormFields(item) {
        return (item.Address.cep &&
          item.Address.cep !== null &&
          item.Address.number &&
          item.Address.number !== null &&
          item.Address.street &&
          item.Address.street !== null &&
          item.Address.neighborhood &&
          item.Address.neighborhood !== null &&
          item.Address.city &&
          item.Address.city !== null) === true
          ? true
          : false;
      }

      const formStudentData = this.enrollmentStudent;
      const formContractor =
        this.enrollmentsResponsibles.EnrollmentResponsibles;
      const formComplementary = this.complements[0];

      if (!formStudentData) {
        return false;
      }

      const dataValidateStudentData =
        !formStudentData.photo ||
        !(formStudentData.name && this.isCompoundName(formStudentData.name)) ||
        !formStudentData.birth_date ||
        !formStudentData.Address.cep ||
        !formStudentData.Address.city ||
        !formStudentData.Address.neighborhood ||
        !formStudentData.Address.street ||
        !formStudentData.Address.number ||
        !(
          formStudentData.Student.User.Phones &&
          formStudentData.Student.User.Phones.length > 0 &&
          formStudentData.Student.User.Phones[0].number
        ) ||
        !formStudentData.email ||
        !formStudentData.rg ||
        !formStudentData.cpf;

      if (!formContractor) {
        return false;
      }

      const dataValidateContractor = formContractor.map((contractor) => {
        const allData =
          !contractor.photo ||
          !(contractor.name && this.isCompoundName(contractor.name)) ||
          !contractor.birth_date ||
          !(
            contractor.Responsible.User.Phones &&
            contractor.Responsible.User.Phones.length > 0 &&
            contractor.Responsible.User.Phones[0].number
          ) ||
          !contractor.email ||
          !contractor.cpf ||
          !contractor.rg ||
          !checkAddressFormFields(contractor);

        return allData;
      });

      if (!formComplementary) {
        return false;
      }

      let validateContacts =
        formComplementary.return_to_home === "Acompanhado por"
          ? formComplementary.EnrollmentComplementContacts?.map((item) => {
              const validate =
                !item.name ||
                !item.number1 ||
                !item.relationship ||
                !item.photo;
              return validate ? validate : null;
            })
          : [null];
      validateContacts = validateContacts?.every((e) => e === null);

      const dataValidateComplementaryAux =
        !formComplementary.contact1 ||
        !formComplementary.contact1_phone ||
        !formComplementary.contact2 ||
        !formComplementary.contact2_phone ||
        formComplementary.food_restriction
          ? !formComplementary.food_restriction_description
          : null || formComplementary.health_problem
          ? !formComplementary.health_problem_description
          : null || formComplementary.healthcare
          ? !formComplementary.healthcare_description
          : null || formComplementary.individual_accompaniment
          ? !formComplementary.individual_accompaniment_description
          : null || formComplementary.limitation
          ? !formComplementary.limitation_description
          : null || formComplementary.medication
          ? !formComplementary.medication_description
          : null || formComplementary.special_accompaniment
          ? !formComplementary.special_accompaniment_description
          : null || formComplementary.special_care
          ? !formComplementary.special_care_description
          : null;

      let dataValidateComplementary;

      if (validateContacts) {
        dataValidateComplementary = !dataValidateComplementaryAux;
      } else {
        dataValidateComplementary = false;
      }

      switch (type) {
        case "Student":
          return dataValidateStudentData ? false : true;

        case "Contractor":
          return dataValidateContractor.every((cur) => cur !== true);

        case "Complementary":
          return !dataValidateComplementary ? false : true;

        default:
          return false;
      }
    },

    setNewPhotoContractor(photo, index) {
      const bodyFormData = new FormData();
      bodyFormData.append("file", photo);
      bodyFormData.append("notification", true);
      this.enrollmentsResponsibles.EnrollmentResponsibles[index].photo =
        bodyFormData;

      this.photoContractor[index] = photo;
    },

    setNewStudentPhoto(photo) {
      const bodyFormData = new FormData();
      bodyFormData.append("file", photo);
      bodyFormData.append("notification", true);
      this.enrollment.EnrollmentStudent.photo = bodyFormData;

      this.photoStudent = photo;
    },

    async getAllUserEnrollments() {
      this.enrollment = await httpRda
        .get(`/students/${this.$route.params.id}/enrollment-data`)
        .then((response) => response.data.enrollment);

      this.enrollmentStudent = this.enrollment.EnrollmentStudent;

      for (const responsible of this.enrollment.EnrollmentResponsibles) {
        responsible.birth_date = FromIsoStringToBRDate(responsible.birth_date);
      }

      this.enrollment.EnrollmentStudent.birth_date = FromIsoStringToBRDate(
        this.enrollment.EnrollmentStudent.birth_date
      );

      this.submits.push({
        structure: JSON.parse(JSON.stringify(this.SubmitStructure)),
        enrollmentId: this.enrollment.id,
        editedUserId: this.enrollment.EnrollmentStudent.Student.User.id,
      });

      this.enrollmentsResponsibles = this.enrollment;

      this.enrollmentsIds.push(this.enrollment.id);

      await this.createUpdateAuthorizations("get");
      await this.createUpdateComplement("get");
    },

    studentNameByEnrollmentId() {
      return this.enrollment.EnrollmentStudent.name;
    },

    setUser() {
      const user = store.getters["auth/getAuthData"];
      this.user_id = user.id;
      this.getUser(user.id);
    },

    async createNotification(userEditorId, userEditedId, details) {
      await httpRda.post("/notifications", {
        user_editor_id: userEditorId,
        user_edited_id: userEditedId,
        details,
      });
    },

    async updateContractors() {
      this.enrollmentsResponsibles.EnrollmentResponsibles.map(
        (responsible, index) => {
          try {
            var address = {
              cep: responsible.Address
                ? responsible.Address.cep.replace("-", "")
                : "",
              number: responsible.Address ? responsible.Address.number : "",
              complement: responsible.Address
                ? responsible.Address.complement
                : "",
              street: responsible.Address ? responsible.Address.street : "",
              neighborhood: responsible.Address
                ? responsible.Address.neighborhood
                : "",
              city: responsible.Address ? responsible.Address.city : "",
              state: responsible.Address ? responsible.Address.state : "",
              country: responsible.Address ? responsible.Address.country : "",
            };

            DataService.updateAddress(responsible.Responsible.User.id, address);

            const payload = {
              birth_date: FromBRDateToIsoString(responsible.birth_date),
              Phones: responsible.Responsible.User.Phones,
              email: responsible.email,
              name: responsible.name,
              cpf: responsible.cpf,
              rg: responsible.rg,
              Address: responsible.Responsible.User.Address,
            };

            DataService.update(responsible.Responsible.User.id, {
              ...payload,
              notification: true,
            })
              .then(async () => {
                if (
                  typeof responsible.photo === "object" &&
                  this.photoContractor[index]
                ) {
                  httpRda.patch(
                    `/users/${responsible.Responsible.User.id}`,
                    responsible.photo,
                    {
                      headers: { "Content-Type": "multipart/form-data" },
                    }
                  );
                }
              })
              .catch((error) => {
                this.$swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: error.message,
                  footer: "Por favor, tente novamente mais tarde.",
                });

                return error.response;
              });
          } catch (error) {
            this.$swal.fire({
              icon: "error",
              title: "Oops...",
              text: error.message,
              footer: "Por favor, tente novamente mais tarde.",
            });

            return error.response;
          }

          const Toast = this.$swal.mixin({
            toast: true,
            position: "bottom-end",
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer);
              toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            },
          });
          Toast.fire({
            icon: "success",
            title: "Perfil atualizado com sucesso!",
          });
        }
      );
    },

    async updateStudents() {
      try {
        var address = {
          cep: this.enrollment.EnrollmentStudent.Address
            ? this.enrollment.EnrollmentStudent.Address.cep.replace("-", "")
            : "",
          number: this.enrollment.EnrollmentStudent.Address
            ? this.enrollment.EnrollmentStudent.Address.number
            : "",
          complement: this.enrollment.EnrollmentStudent.Address
            ? this.enrollment.EnrollmentStudent.Address.complement
            : "",
          street: this.enrollment.EnrollmentStudent.Address
            ? this.enrollment.EnrollmentStudent.Address.street
            : "",
          neighborhood: this.enrollment.EnrollmentStudent.Address
            ? this.enrollment.EnrollmentStudent.Address.neighborhood
            : "",
          city: this.enrollment.EnrollmentStudent.Address
            ? this.enrollment.EnrollmentStudent.Address.city
            : "",
          state: this.enrollment.EnrollmentStudent.Address
            ? this.enrollment.EnrollmentStudent.Address.state
            : "",
          country: this.enrollment.EnrollmentStudent.Address
            ? this.enrollment.EnrollmentStudent.Address.country
            : "",
        };

        DataService.updateAddress(
          this.enrollment.EnrollmentStudent.Student.User.id,
          address
        );

        const payload = {
          birth_date: FromBRDateToIsoString(
            this.enrollment.EnrollmentStudent.birth_date
          ),
          Phones: this.enrollment.EnrollmentStudent.Student.User.Phones,
          email: this.enrollment.EnrollmentStudent.email,
          name: this.enrollment.EnrollmentStudent.name,
          cpf: this.enrollment.EnrollmentStudent.cpf,
          rg: this.enrollment.EnrollmentStudent.rg,
          Address: this.enrollment.EnrollmentStudent.Address,
        };

        await httpRda
          .patch(
            `/users/${this.enrollment.EnrollmentStudent.Student.User.id}/student`,
            { ...payload, notification: true }
          )
          .then(async () => {
            if (typeof this.enrollment.EnrollmentStudent.photo === "object") {
              await httpRda.patch(
                `/users/${this.enrollment.EnrollmentStudent.Student.User.id}/student`,
                this.enrollment.EnrollmentStudent.photo,
                {
                  headers: { "Content-Type": "multipart/form-data" },
                }
              );
            }
          })
          .catch((error) => {
            this.$swal.fire({
              icon: "error",
              title: "Oops...",
              text: error.message,
              footer: "Por favor, tente novamente mais tarde.",
            });

            return error.response;
          });
      } catch (error) {
        this.$swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.message,
          footer: "Por favor, tente novamente mais tarde.",
        });

        return error.response;
      }

      const Toast = this.$swal.mixin({
        toast: true,
        position: "bottom-end",
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "success",
        title: "Perfil atualizado com sucesso!",
      });
    },

    getSelected(id) {
      DataService.get(id)
        .then((response) => {
          this.selected = response.data;
          this.selected.user.birth_date = FromIsoStringToBRDate(
            this.selected.user.birth_date
          );
          return response.data;
        })
        .catch((error) => {
          return error.response;
        });
    },

    getCurrentEnrollmentById() {
      return this.enrollment;
    },

    async createUpdateAuthorizations(type) {
      switch (type) {
        case "update":
        case "post":
          for (const authorization of this.authorizations) {
            const currentEnrollment = this.getCurrentEnrollmentById(
              authorization.enrollment_id
            );

            await httpRda
              .post(
                `/enrollments/${authorization.enrollment_id}/authorization`,
                {
                  ...authorization,
                  userId: currentEnrollment.EnrollmentStudent.Student.User.id,
                  notification: true,
                }
              )
              .then(() => {
                const Toast = this.$swal.mixin({
                  toast: true,
                  position: "bottom-end",
                  showConfirmButton: false,
                  timer: 5000,
                  timerProgressBar: true,
                  didOpen: (toast) => {
                    toast.addEventListener("mouseenter", this.$swal.stopTimer);
                    toast.addEventListener(
                      "mouseleave",
                      this.$swal.resumeTimer
                    );
                  },
                });
                Toast.fire({
                  icon: "success",
                  title: "Autorizações atualizadas com sucesso!",
                });
              })
              .catch((error) => {
                this.$swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: error.message,
                  footer: "Por favor, tente novamente mais tarde.",
                });

                return error.response;
              });
          }
          break;
        default:
          for (const enrollmentId of this.enrollmentsIds) {
            await httpRda
              .get(`/enrollments/${enrollmentId}/authorization`)
              .then((response) => {
                this.authorizations.push(response.data.authorization);
              });
          }
          break;
      }
    },

    async createUpdateComplement(type) {
      switch (type) {
        case "update":
        case "post":
          for (const complement of this.complements) {
            const currentEnrollment = this.getCurrentEnrollmentById(
              complement.enrollment_id
            );

            await httpRda
              .patch(`/enrollments/${complement.enrollment_id}/complement`, {
                complement: {
                  ...complement,
                  userId: currentEnrollment.EnrollmentStudent.Student.User.id,
                  notification: true,
                },
                id: complement.enrollment_id,
              })
              .then(async () => {
                await this.createUpdateContacts(complement.enrollment_id, {
                  ...complement,
                  userId: currentEnrollment.EnrollmentStudent.Student.User.id,
                  notification: true,
                }).then(() => {
                  const Toast = this.$swal.mixin({
                    toast: true,
                    position: "bottom-end",
                    showConfirmButton: false,
                    timer: 5000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                      toast.addEventListener(
                        "mouseenter",
                        this.$swal.stopTimer
                      );
                      toast.addEventListener(
                        "mouseleave",
                        this.$swal.resumeTimer
                      );
                    },
                  });
                  Toast.fire({
                    icon: "success",
                    title: "Autorizações atualizadas com sucesso!",
                  });
                });
              })
              .catch((error) => {
                this.$swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: error.message,
                  footer: "Por favor, tente novamente mais tarde.",
                });

                return error.response;
              });
          }
          break;

        default:
          for (const enrollmentId of this.enrollmentsIds) {
            await httpRda
              .get(`/enrollments/${enrollmentId}/complement`)
              .then((response) => {
                this.complements.push(response.data.enrollment_complement);
              });
          }

          break;
      }
    },

    async createUpdateContacts(id, complementary) {
      if (complementary.return_to_home !== "Sozinho") {
        const toUpdate = complementary.EnrollmentComplementContacts.filter(
          (contact) => contact.id !== undefined
        );

        const toCreate = complementary.EnrollmentComplementContacts.filter(
          (contact) => contact.id === undefined
        );

        if (toUpdate.length) {
          toUpdate.map(async ({ photo, ...contact }) => {
            const bodyFormData = new FormData();
            bodyFormData.append("file", photo);

            await httpRda.patch(`/enrollments/${id}/contacts/${contact.id}`, {
              ...contact,
              notification: complementary.notification,
              userId: complementary.userId,
            });

            await httpRda.patch(
              `enrollments/${id}/contacts/${contact.id}`,
              bodyFormData,
              {
                headers: { "Content-Type": "multipart/form-data" },
              }
            );
          });
        }

        if (toCreate.length) {
          let newId;
          toCreate.map(async ({ photo, ...contact }) => {
            const bodyFormData = new FormData();
            bodyFormData.append("file", photo);
            await httpRda
              .post(`/enrollments/${id}/contacts`, {
                ...contact,
                notification: complementary.notification,
                userId: complementary.userId,
              })
              .then((response) => {
                newId = response.data.complement[0].id;
              });
            await httpRda.patch(
              `enrollments/${id}/contacts/${newId}`,
              bodyFormData,
              {
                headers: { "Content-Type": "multipart/form-data" },
              }
            );
          });
        }
      }
    },
  },

  created() {
    this.setUser();
    this.getAllUserEnrollments();
  },

  mounted() {
    this.getSelected(this.user_id);
  },

  watch: {
    currentUser() {
      this.setUser();
    },

    enrollmentsResponsibles: {
      handler() {
        this.isValid("Contractor");
      },
      deep: true,
    },

    enrollmentStudent: {
      handler() {
        this.isValid("Student");
      },
      deep: true,
    },

    complements: {
      handler() {
        this.isValid("Complementary");
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/variables.scss";

[id*="accordion-"] {
  border-bottom: 1px solid #b3b3b3;
  padding-bottom: 30px;
}
.tab-content {
  .nav-tabs {
    border-bottom: 2px solid #e6e6e6;
    gap: 24px;

    li {
      &:first-child {
        a {
          text-align: left;
          padding: 0;
        }
      }
    }

    .nav-link {
      text-align: center;
      font-size: 18px;
      color: #1a1a1a;
      height: 52px;
      border: 0;
      padding: 0 18px;
      position: relative;

      &.active {
        font-weight: bold;
        background: transparent;

        &:after {
          width: 100%;
        }
      }

      &:after {
        background: $gradient1;
        content: "";
        width: 0;
        height: 4px;
        display: block;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 50px;
        transition: 0.2s all ease-in-out;
      }
    }
  }
}

.divider {
  p {
    min-height: 70px;
  }
  &:after {
    background-color: $grey7;
    content: "";
    display: block;
    height: 1px;
    left: 0;
    top: 50%;
    position: absolute;
    width: 100%;
  }
}

.profile-row {
  background: $gradient3;
  border-radius: 10px;
  color: $white;
  margin: 30px 0 40px;
  padding: 28px 10px;
  position: relative;
  &:after {
    content: url("~@/assets/images/watermark-profile.png");
    right: 0;
    pointer-events: none;
    position: absolute;
    top: -5px;
  }
  .col {
    display: flex;
    align-items: center;
    img {
      margin-right: 40px;
    }
    .content-profile {
      h5 {
        margin-bottom: 14px;
      }
      p {
        margin: 0;
      }
    }
  }
}

.form-group {
  margin-bottom: 40px;
}
.title {
  padding: 20px 15px 40px;
  h5 {
    margin: 0;
  }
}
.row-buttons {
  padding-top: 10px;
}

#StandardInput_birth_date {
  height: 48.67px;
}
</style>
