<template>
  <div class="home">
    <template>
      <b-container class="container-rda" v-if="selected">
        <b-row>
          <b-col>
            <b-breadcrumb :items="breadcrumb"></b-breadcrumb>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <h2 class="purple bold">Validar matrícula</h2>
          </b-col>
        </b-row>
        <b-row class="mt-50">
          <b-col v-if="selected.EnrollmentStudent" md="4">
            <p class="bold">Aluno</p>
            <p v-if="selected.EnrollmentStudent">
              {{ selected.EnrollmentStudent.name }}
            </p>
            <p v-else>Matrícula incompleta</p>
          </b-col>
          <b-col v-if="selected.EnrollmentResponsibles" md="4">
            <p class="bold">Contratante responsável</p>
            <p v-if="selected.EnrollmentResponsibles">
              {{ selected.EnrollmentResponsibles[0].name }}
            </p>
            <p v-else>Matrícula incompleta</p>
          </b-col>
          <b-col v-if="selected.EnrollmentStudent.time_period" md="4">
            <p class="bold">Turno</p>
            <p>
              {{
                selected.EnrollmentStudent.time_period === "Matutino"
                  ? "Manhã"
                  : "Tarde"
              }}
            </p>
          </b-col>
        </b-row>
        <b-row class="mt-5">
          <b-col md="12">
            <h5 class="purple mb-4">Ciclo e turma:</h5>
          </b-col>
          <b-col md="4" class="hide-last">
            <SelectCycles
              placeholder="Selecione"
              v-model="selected.EnrollmentStudent.cycle_id"
              :callback="callbackCycles"
            ></SelectCycles>
          </b-col>
          <b-col md="4" class="hide-last">
            <SelectClassrooms
              placeholder="Selecione"
              v-model="classroom.classroom_id"
              :callback="callbackClassrooms"
            ></SelectClassrooms>
          </b-col>
          <FrequencyInput
            v-model="selected.EnrollmentStudent.starting_frequency"
            type="text"
            name="name"
            v-mask="'##/##/####'"
            label="Data de início da frequência"
            :onChange="callbackStartingFrequency"
          ></FrequencyInput>
          <b-col md="4" class="hide-last">
            <p class="">Ano da matrícula</p>
            <multiselect
              :value="selectedCalendar"
              :options="calendars"
              :multiple="false"
              :close-on-select="true"
              :clear-on-select="false"
              :preserve-search="true"
              label="name"
              track-by="id"
              selectLabel=""
              @select="handleCalendar"
            >
              <span slot="noResult">Nada encontrado</span>
            </multiselect>
          </b-col>
        </b-row>

        <b-row
          class="mt-5"
          v-if="selected.EnrollmentFile || this.rdaUnit == 'POUSO_ALEGRE'"
        >
          <b-col md="6">
            <h5 class="purple bold">Documentos do aluno:</h5>
          </b-col>
          <b-col>
            <h5 class="purple bold">Download do documento:</h5>
          </b-col>
          <b-col>
            <h5 class="purple bold">Validade do documento:</h5>
          </b-col>
        </b-row>
        <b-row v-else class="mt-5">
          <b-col>
            <h5>Matrícula incompleta ou em andamento</h5>
          </b-col>
        </b-row>
        <b-row
          class="mt-5 pb-5 list-downloads"
          v-if="
            (selected.EnrollmentFile &&
              rdaUnit != 'POUSO_ALEGRE' &&
              currentCycle != 8) ||
            rdaUnit == 'POUSO_ALEGRE'
          "
        >
          <b-col md="6">
            <h5 class="bold">Contrato</h5>

            <a
              v-if="selected.EnrollmentFile.is_valid_contract == false"
              class="btn-link pointer"
              @click="generateNewContract(selected.id)"
              >Gerar um novo contrato?</a
            >
            <b-button
              v-if="!selected.signed_at && !updatingStatus"
              class="pointer"
              style="margin-top: 15px; margin-bottom: -20px"
              @click="updateContractStatus(selected.id)"
            >
              Atualizar status do contrato</b-button
            >

            <b-row align="center" v-if="updatingStatus">
              <b-col class="mt-5">
                <b-spinner variant="success" label="Carregando..."></b-spinner>
              </b-col>
            </b-row>
          </b-col>

          <b-col>
            <b-button
              v-if="selected.EnrollmentFile.contract == null"
              class="large pending"
              >Pendente</b-button
            >
            <b-button
              v-else
              :href="selected.EnrollmentFile.contract"
              download
              target="_blank"
              class="large blue"
              >Download</b-button
            >
          </b-col>
          <b-col>
            <b-form-group class="group-radio">
              <div class="radio-inline">
                <b-form-radio
                  v-model="selected.EnrollmentFile.is_valid_contract"
                  id="is_valid_contract-1"
                  name="options-is_valid_contract"
                  value="true"
                  @change="validContract(selected.id, true)"
                  >Válido</b-form-radio
                >
                <b-form-radio
                  v-model="selected.EnrollmentFile.is_valid_contract"
                  id="is_valid_contract-2"
                  name="options-is_valid_contract"
                  value="false"
                  @change="validContract(selected.id, false)"
                  >Inválido</b-form-radio
                >
              </div>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="pt-5 pb-5 list-downloads" v-if="selected.EnrollmentFile">
          <b-col md="12" class="mb-5">
            <h5 class="bold">Carteira de Identidade</h5>
          </b-col>
          <b-col md="6" class="mb-5">
            <h5 class="bold">Frente</h5>
          </b-col>
          <b-col md="3" class="mb-5">
            <b-button
              v-if="selected.EnrollmentFile.identity == null"
              class="large pending"
              >Pendente</b-button
            >
            <b-button
              v-else
              :href="selected.EnrollmentFile.identity"
              download
              target="_blank"
              class="large blue"
              >Download</b-button
            >
          </b-col>
          <b-col md="3" class="mb-5">
            <b-form-group class="group-radio">
              <div class="radio-inline">
                <b-form-radio
                  v-model="selected.EnrollmentFile.is_valid_identity"
                  name="options-is_valid_identity"
                  id="is_valid_identity-1"
                  value="true"
                  @change="validIndentity(selected.id, true)"
                  >Válido</b-form-radio
                >
                <b-form-radio
                  v-model="selected.EnrollmentFile.is_valid_identity"
                  name="options-is_valid_identity"
                  id="is_valid_identity-2"
                  value="false"
                  @change="validIndentity(selected.id, false)"
                  >Inválido</b-form-radio
                >
              </div>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <h5 class="bold">Verso</h5>
          </b-col>
          <b-col md="3">
            <b-button
              v-if="selected.EnrollmentFile.identity_back == null"
              class="large pending"
              >Pendente</b-button
            >
            <b-button
              v-else
              :href="selected.EnrollmentFile.identity_back"
              download
              target="_blank"
              class="large blue"
              >Download</b-button
            >
          </b-col>
          <b-col md="3">
            <b-form-group class="group-radio">
              <div class="radio-inline">
                <b-form-radio
                  v-model="selected.EnrollmentFile.is_valid_identity_back"
                  name="options-is_valid_identity_back"
                  id="is_valid_identity_back-1"
                  value="true"
                  @change="validIndentityBack(selected.id, true)"
                  >Válido</b-form-radio
                >
                <b-form-radio
                  v-model="selected.EnrollmentFile.is_valid_identity_back"
                  name="options-is_valid_identity_back"
                  id="is_valid_identity_back-2"
                  value="false"
                  @change="validIndentityBack(selected.id, false)"
                  >Inválido</b-form-radio
                >
              </div>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row class="pt-5 pb-5 list-downloads" v-if="selected.EnrollmentFile">
          <b-col md="6">
            <h5 class="bold">CPF</h5>
          </b-col>
          <b-col>
            <b-button
              v-if="selected.EnrollmentFile.cpf == null"
              class="large pending"
              >Pendente</b-button
            >
            <b-button
              v-else
              :href="selected.EnrollmentFile.cpf"
              download
              target="_blank"
              class="large blue"
              >Download</b-button
            >
          </b-col>
          <b-col>
            <b-form-group class="group-radio">
              <div class="radio-inline">
                <b-form-radio
                  v-model="selected.EnrollmentFile.is_valid_cpf"
                  name="options-is_valid_cpf"
                  id="is_valid_cpf-1"
                  value="true"
                  @change="validCPF(selected.id, true)"
                  >Válido</b-form-radio
                >
                <b-form-radio
                  v-model="selected.EnrollmentFile.is_valid_cpf"
                  name="options-is_valid_cpf"
                  id="is_valid_cpf-2"
                  value="false"
                  @change="validCPF(selected.id, false)"
                  >Inválido</b-form-radio
                >
              </div>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="pt-5 pb-5 list-downloads" v-if="selected.EnrollmentFile">
          <b-col md="6">
            <h5 class="bold">Cartão de vacinas</h5>
          </b-col>
          <b-col>
            <b-button
              v-if="selected.EnrollmentFile.vaccination_record == null"
              class="large pending"
              >Pendente</b-button
            >
            <b-button
              v-else
              :href="selected.EnrollmentFile.vaccination_record"
              download
              target="_blank"
              class="large blue"
              >Download</b-button
            >
          </b-col>
          <b-col>
            <b-form-group class="group-radio">
              <div class="radio-inline">
                <b-form-radio
                  v-model="selected.EnrollmentFile.is_valid_vaccination_record"
                  name="options-is_valid_vaccination_record"
                  id="is_valid_vaccination_record-1"
                  value="true"
                  @change="validVaccination(selected.id, true)"
                  >Válido</b-form-radio
                >
                <b-form-radio
                  v-model="selected.EnrollmentFile.is_valid_vaccination_record"
                  name="options-is_valid_vaccination_record"
                  id="is_valid_vaccination_record-2"
                  value="false"
                  @change="validVaccination(selected.id, false)"
                  >Inválido</b-form-radio
                >
              </div>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="pt-5 pb-5 list-downloads" v-if="selected.EnrollmentFile">
          <b-col md="6">
            <h5 class="bold">Declaração de transferência</h5>
          </b-col>
          <b-col>
            <b-button
              v-if="selected.EnrollmentFile.transference_declaration == null"
              class="large pending"
              >Pendente</b-button
            >
            <b-button
              v-else
              :href="selected.EnrollmentFile.transference_declaration"
              download
              target="_blank"
              class="large blue"
              >Download</b-button
            >
          </b-col>
          <b-col>
            <b-form-group class="group-radio">
              <div class="radio-inline">
                <b-form-radio
                  v-model="
                    selected.EnrollmentFile.is_valid_transference_declaration
                  "
                  name="options-is_valid_transference_declaration"
                  id="is_valid_transference_declaration-1"
                  value="true"
                  @change="validTransference(selected.id, true)"
                  >Válido</b-form-radio
                >
                <b-form-radio
                  v-model="
                    selected.EnrollmentFile.is_valid_transference_declaration
                  "
                  name="options-is_valid_transference_declaration"
                  id="is_valid_transference_declaration-2"
                  value="false"
                  @change="validTransference(selected.id, false)"
                  >Inválido</b-form-radio
                >
              </div>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="pt-5 pb-5 list-downloads" v-if="selected.EnrollmentFile">
          <b-col md="6">
            <h5 class="bold">
              Declaração de quitação emitida pela escola anterior
            </h5>
          </b-col>
          <b-col>
            <b-button
              v-if="selected.EnrollmentFile.discharge == null"
              class="large pending"
              >Pendente</b-button
            >
            <b-button
              v-else
              :href="selected.EnrollmentFile.discharge"
              download
              target="_blank"
              class="large blue"
              >Download</b-button
            >
          </b-col>
          <b-col>
            <b-form-group class="group-radio">
              <div class="radio-inline">
                <b-form-radio
                  v-model="selected.EnrollmentFile.is_valid_discharge"
                  name="options-is_valid_discharge"
                  id="is_valid_discharge-1"
                  value="true"
                  @change="validDischarge(selected.id, true)"
                  >Válido</b-form-radio
                >
                <b-form-radio
                  v-model="selected.EnrollmentFile.is_valid_discharge"
                  name="options-is_valid_discharge"
                  id="is_valid_discharge-2"
                  value="false"
                  @change="validDischarge(selected.id, false)"
                  >Inválido</b-form-radio
                >
              </div>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="pt-5 pb-5 list-downloads" v-if="selected.EnrollmentFile">
          <b-col md="6">
            <h5 class="bold">Histórico escolar</h5>
          </b-col>
          <b-col>
            <b-button
              v-if="selected.EnrollmentFile.school_record == null"
              class="large pending"
              >Pendente</b-button
            >
            <b-button
              v-else
              :href="selected.EnrollmentFile.school_record"
              download
              target="_blank"
              class="large blue"
              >Download</b-button
            >
          </b-col>
          <b-col>
            <b-form-group class="group-radio">
              <div class="radio-inline">
                <b-form-radio
                  v-model="selected.EnrollmentFile.is_valid_school_record"
                  name="options-is_valid_school_record"
                  id="is_valid_school_record-1"
                  value="true"
                  @change="validSchoolRecord(selected.id, true)"
                  >Válido</b-form-radio
                >
                <b-form-radio
                  v-model="selected.EnrollmentFile.is_valid_school_record"
                  name="options-is_valid_school_record"
                  id="is_valid_school_record-2"
                  value="false"
                  @change="validSchoolRecord(selected.id, false)"
                  >Inválido</b-form-radio
                >
              </div>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="pt-5 mt-5" v-if="selected.EnrollmentFile">
          <b-col md="6">
            <h5 class="purple bold">Documentos dos contratantes:</h5>
          </b-col>
          <b-col>
            <h5 class="purple bold">Download do documento:</h5>
          </b-col>
          <b-col>
            <h5 class="purple bold">Validade do documento:</h5>
          </b-col>
        </b-row>
        <b-row class="pt-5 pb-5 list-downloads" v-if="selected.EnrollmentFile">
          <b-col md="12" class="mb-5">
            <h5>Carteira de Identidade dos contratantes</h5>
          </b-col>
          <b-col md="6" class="mb-5">
            <h5 class="bold">Contratante A</h5>
          </b-col>
          <b-col md="3" class="mb-5">
            <b-button
              v-if="selected.EnrollmentFile.identity_contractor_a == null"
              class="large pending"
              >Pendente</b-button
            >
            <b-button
              v-else
              :href="selected.EnrollmentFile.identity_contractor_a"
              download
              target="_blank"
              class="large blue"
              >Download</b-button
            >
          </b-col>
          <b-col md="3" class="mb-5">
            <b-form-group class="group-radio">
              <div class="radio-inline">
                <b-form-radio
                  v-model="
                    selected.EnrollmentFile.is_valid_identity_contractor_a
                  "
                  name="options-is_valid_identity_contractor_a"
                  id="is_valid_identity_contractor_a-1"
                  value="true"
                  @change="validIdentityContractorA(selected.id, true)"
                  >Válido</b-form-radio
                >
                <b-form-radio
                  v-model="
                    selected.EnrollmentFile.is_valid_identity_contractor_a
                  "
                  name="options-is_valid_identity_contractor_a"
                  id="is_valid_identity_contractor_a-2"
                  value="false"
                  @change="validIdentityContractorA(selected.id, false)"
                  >Inválido</b-form-radio
                >
              </div>
            </b-form-group>
          </b-col>
          <b-col md="6" class="mb-5">
            <h5 class="bold">Contratante A Verso</h5>
          </b-col>
          <b-col md="3" class="mb-5">
            <b-button
              v-if="selected.EnrollmentFile.identity_contractor_a_back == null"
              class="large pending"
              >Pendente</b-button
            >
            <b-button
              v-else
              :href="selected.EnrollmentFile.identity_contractor_a_back"
              download
              target="_blank"
              class="large blue"
              >Download</b-button
            >
          </b-col>
          <b-col md="3" class="mb-5">
            <b-form-group class="group-radio">
              <div class="radio-inline">
                <b-form-radio
                  v-model="
                    selected.EnrollmentFile.is_valid_identity_contractor_a_back
                  "
                  name="options-is_valid_identity_contractor_a_back"
                  id="is_valid_identity_contractor_a_back-1"
                  value="true"
                  @change="validIdentityContractorABack(selected.id, true)"
                  >Válido</b-form-radio
                >
                <b-form-radio
                  v-model="
                    selected.EnrollmentFile.is_valid_identity_contractor_a_back
                  "
                  name="options-is_valid_identity_contractor_a_back"
                  id="is_valid_identity_contractor_a_back-2"
                  value="false"
                  @change="validIdentityContractorABack(selected.id, false)"
                  >Inválido</b-form-radio
                >
              </div>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <h5 class="bold">Contratante B</h5>
          </b-col>
          <b-col md="3">
            <b-button
              v-if="selected.EnrollmentFile.identity_contractor_b == null"
              class="large pending"
              >Pendente</b-button
            >
            <b-button
              v-else
              :href="selected.EnrollmentFile.identity_contractor_b"
              download
              target="_blank"
              class="large blue"
              >Download</b-button
            >
          </b-col>
          <b-col md="3" class="mb-5">
            <b-form-group class="group-radio">
              <div class="radio-inline">
                <b-form-radio
                  v-model="
                    selected.EnrollmentFile.is_valid_identity_contractor_b
                  "
                  name="options-is_valid_identity_contractor_b"
                  id="is_valid_identity_contractor_b-1"
                  value="true"
                  @change="validIdentityContractorB(selected.id, true)"
                  >Válido</b-form-radio
                >
                <b-form-radio
                  v-model="
                    selected.EnrollmentFile.is_valid_identity_contractor_b
                  "
                  name="options-is_valid_identity_contractor_b"
                  id="is_valid_identity_contractor_b-2"
                  value="false"
                  @change="validIdentityContractorB(selected.id, false)"
                  >Inválido</b-form-radio
                >
              </div>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <h5 class="bold">Contratante B Verso</h5>
          </b-col>
          <b-col md="3">
            <b-button
              v-if="selected.EnrollmentFile.identity_contractor_b_back == null"
              class="large pending"
              >Pendente</b-button
            >
            <b-button
              v-else
              :href="selected.EnrollmentFile.identity_contractor_b_back"
              download
              target="_blank"
              class="large blue"
              >Download</b-button
            >
          </b-col>
          <b-col md="3">
            <b-form-group class="group-radio">
              <div class="radio-inline">
                <b-form-radio
                  v-model="
                    selected.EnrollmentFile.is_valid_identity_contractor_b_back
                  "
                  name="options-is_valid_identity_contractor_b_back"
                  id="is_valid_identity_contractor_b_back-1"
                  value="true"
                  @change="validIdentityContractorBBack(selected.id, true)"
                  >Válido</b-form-radio
                >
                <b-form-radio
                  v-model="
                    selected.EnrollmentFile.is_valid_identity_contractor_b_back
                  "
                  name="options-is_valid_identity_contractor_b_back"
                  id="is_valid_identity_contractor_b_back-2"
                  value="false"
                  @change="validIdentityContractorBBack(selected.id, false)"
                  >Inválido</b-form-radio
                >
              </div>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="pt-5 pb-5 list-downloads" v-if="selected.EnrollmentFile">
          <b-col md="12" class="mb-5">
            <h5>CPF dos contratantes</h5>
          </b-col>
          <b-col md="6" class="mb-5">
            <h5 class="bold">Contratante A</h5>
          </b-col>
          <b-col md="3" class="mb-5">
            <b-button
              v-if="selected.EnrollmentFile.cpf_contractor_a == null"
              class="large pending"
              >Pendente</b-button
            >
            <b-button
              v-else
              :href="selected.EnrollmentFile.cpf_contractor_a"
              download
              target="_blank"
              class="large blue"
              >Download</b-button
            >
          </b-col>
          <b-col md="3" class="mb-5">
            <b-form-group class="group-radio">
              <div class="radio-inline">
                <b-form-radio
                  v-model="selected.EnrollmentFile.is_valid_cpf_contractor_a"
                  name="options-is_valid_cpf_contractor_a"
                  id="is_valid_cpf_contractor_a-1"
                  value="true"
                  @change="validCPFContractorA(selected.id, true)"
                  >Válido</b-form-radio
                >
                <b-form-radio
                  v-model="selected.EnrollmentFile.is_valid_cpf_contractor_a"
                  name="options-is_valid_cpf_contractor_a"
                  id="is_valid_cpf_contractor_a-2"
                  value="false"
                  @change="validCPFContractorA(selected.id, false)"
                  >Inválido</b-form-radio
                >
              </div>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <h5 class="bold">Contratante B</h5>
          </b-col>
          <b-col md="3">
            <b-button
              v-if="selected.EnrollmentFile.cpf_contractor_b == null"
              class="large pending"
              >Pendente</b-button
            >
            <b-button
              v-else
              :href="selected.EnrollmentFile.cpf_contractor_b"
              download
              target="_blank"
              class="large blue"
              >Download</b-button
            >
          </b-col>
          <b-col md="3">
            <b-form-group class="group-radio">
              <div class="radio-inline">
                <b-form-radio
                  v-model="selected.EnrollmentFile.is_valid_cpf_contractor_b"
                  name="options-is_valid_cpf_contractor_b"
                  id="is_valid_cpf_contractor_b-1"
                  value="true"
                  @change="validCPFContractorB(selected.id, true)"
                  >Válido</b-form-radio
                >
                <b-form-radio
                  v-model="selected.EnrollmentFile.is_valid_cpf_contractor_b"
                  name="options-is_valid_cpf_contractor_b"
                  id="is_valid_cpf_contractor_b-2"
                  value="false"
                  @change="validCPFContractorB(selected.id, false)"
                  >Inválido</b-form-radio
                >
              </div>
            </b-form-group>
          </b-col>
        </b-row>
      </b-container>
    </template>
  </div>
</template>

<script>
import { mapActions } from "vuex";

import DataService from "@/services/enrollments.service";
import DataServiceClassrooms from "@/services/students.service";

import SelectCycles from "@/components/inputs/SelectCycles.vue";
import FrequencyInput from "@/components/inputs/FrequencyInput.vue";
import SelectClassrooms from "@/components/inputs/SelectClassrooms.vue";
import httpRda from "../../http-rda";

export default {
  name: "validar-matricula",
  components: {
    SelectCycles,
    SelectClassrooms,
    FrequencyInput,
  },
  data() {
    return {
      currentCycle: null,
      updatingStatus: false,
      calendars: [],
      selectedCalendar: null,
      classroom: {
        classroom_id: "",
      },
      rdaUnit: process.env.VUE_APP_RDA_UNIT,
      selected: {
        EnrollmentStudent: {
          cycle_id: "",
        },
        EnrollmentFile: {
          is_valid_contract: null,
        },
      },

      cicle: null,

      breadcrumb: [
        {
          text: "Secretaria",
          href: "/secretaria/contratos",
        },
        {
          text: "Pedidos",
          href: "/secretaria/pedidos",
        },
        {
          text: "Validar",
          href: "/validar-matricula",
          active: true,
        },
      ],
    };
  },

  filters: {
    date(date) {
      try {
        var d = new Date(date.split());
        var year = d.getFullYear().toString();
        var month = (d.getMonth() + 1).toString().padStart(2, "0");
        var day = d.getUTCDate().toString().padStart(2, "0");

        return day + "/" + month + "/" + year;
      } catch (error) {
        return date;
      }
    },
  },

  methods: {
    ...mapActions({
      requestContract: "enrollment/requestContractOnClickSign",
    }),

    async handleCalendar(calendar) {
      if (calendar.id && calendar.name) {
        this.$swal
          .fire({
            title: `Deseja alterar o ano da matrícula do aluno(a) para ${calendar.name}?`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#68c5b7",
            cancelButtonColor: "#d33",
            confirmButtonText: "Sim",
            cancelButtonText: "Cancelar",
          })
          .then(async (result) => {
            if (result.isConfirmed) {
              await httpRda
                .patch(`/enrollments/${this.selected.id}`, {
                  calendar_id: calendar.id,
                })
                .then(() => {
                  this.selectedCalendar = calendar;
                  const Toast = this.$swal.mixin({
                    toast: true,
                    position: "bottom-end",
                    showConfirmButton: false,
                    timer: 5000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                      toast.addEventListener(
                        "mouseenter",
                        this.$swal.stopTimer
                      );
                      toast.addEventListener(
                        "mouseleave",
                        this.$swal.resumeTimer
                      );
                    },
                  });

                  Toast.fire({
                    icon: "success",
                    title: `Ano atualizado com sucesso!`,
                  });
                })
                .catch(() => {
                  this.$swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Não foi possivel atualizar!",
                    footer: "Por favor, tente novamente mais tarde.",
                  });
                });
            }
          })
          .catch(() => {
            this.$swal.fire({
              icon: "error",
              title: "ok",
              text: "Ação não realizada!",
              footer: "Por favor, tente novamente mais tarde.",
            });
          });
      }
    },

    async getCalendars() {
      this.calendars = await httpRda
        .get("/calendars")
        .then((resp) => resp.data.calendars);

      this.calendars = this.calendars.map((calendar) => {
        return {
          id: calendar.id,
          name: calendar.designation,
        };
      });

      this.calendars = [...this.calendars, { id: null, name: null }];
    },

    getSelected(id) {
      DataService.get(id)
        .then((response) => {
          this.selected = response.data.enrollment;
          this.currentCycle =
            this.selected &&
            this.selected.EnrollmentStudent &&
            this.selected.EnrollmentStudent.cycle_id;
          this.calendars.find((calendar) => {
            if (calendar.id == this.selected.calendar_id) {
              this.selectedCalendar = calendar;
            }
          });
        })
        .catch((error) => {
          return error;
        });
      DataServiceClassrooms.get(id)
        .then((response) => {
          this.classroom = response.data.student;
        })
        .catch((error) => {
          return error;
        });
    },

    validContract(id, status) {
      var data = {
        is_valid_contract: status,
      };
      this.updateStatusFile(id, data);
    },

    validIndentity(id, status) {
      var data = {
        is_valid_identity: status,
      };
      this.updateStatusFile(id, data);
    },

    validIndentityBack(id, status) {
      var data = {
        is_valid_identity_back: status,
      };
      this.updateStatusFile(id, data);
    },

    validCPF(id, status) {
      var data = {
        is_valid_cpf: status,
      };
      this.updateStatusFile(id, data);
    },

    validVaccination(id, status) {
      var data = {
        is_valid_vaccination_record: status,
      };
      this.updateStatusFile(id, data);
    },

    validTransference(id, status) {
      var data = {
        is_valid_transference_declaration: status,
      };
      this.updateStatusFile(id, data);
    },

    validDischarge(id, status) {
      var data = {
        is_valid_discharge: status,
      };
      this.updateStatusFile(id, data);
    },

    validSchoolRecord(id, status) {
      var data = {
        is_valid_school_record: status,
      };
      this.updateStatusFile(id, data);
    },

    validIdentityContractorA(id, status) {
      var data = {
        is_valid_identity_contractor_a: status,
      };
      this.updateStatusFile(id, data);
    },

    validIdentityContractorABack(id, status) {
      var data = {
        is_valid_identity_contractor_a_back: status,
      };
      this.updateStatusFile(id, data);
    },

    validIdentityContractorB(id, status) {
      var data = {
        is_valid_identity_contractor_b: status,
      };
      this.updateStatusFile(id, data);
    },

    validIdentityContractorBBack(id, status) {
      var data = {
        is_valid_identity_contractor_b_back: status,
      };
      this.updateStatusFile(id, data);
    },

    validCPFContractorA(id, status) {
      var data = {
        is_valid_cpf_contractor_a: status,
      };
      this.updateStatusFile(id, data);
    },

    validCPFContractorB(id, status) {
      var data = {
        is_valid_cpf_contractor_b: status,
      };
      this.updateStatusFile(id, data);
    },

    updateStatusFile(id, data) {
      DataService.postFile(id, data)
        .then((response) => {
          this.getSelected(id);
          this.toastSucess();

          return response.data;
        })
        .catch((error) => {
          this.toastError();
          return error.response;
        });
    },

    toastSucess() {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "bottom-end",
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "success",
        title: "Status atualizado!",
      });
    },

    toastError() {
      this.$swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Não foi possivel atualizar!",
        footer: "Por favor, tente novamente mais tarde.",
      });
    },

    callbackStartingFrequency(args) {
      this.$swal
        .fire({
          title: "Deseja alterar a data de frequência inicial atual?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#68c5b7",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim",
          cancelButtonText: "Cancelar",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            try {
              var data = {
                starting_frequency: args.target.value,
              };
              await DataService.updateEnrollmentStudent(
                this.$route.params.id,
                data
              );
              this.$swal
                .fire("Sucesso!", "O Estudante foi atualizado.", "success")
                .then((response) => {
                  this.getSelected(this.$route.params.id);
                  return response.data;
                });
            } catch (error) {
              this.getSelected(this.$route.params.id);
              this.$swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Não foi possivel atualizar!",
                footer: "Por favor, tente novamente mais tarde.",
              });

              return error.response;
            }
          }
        })
        .catch((error) => {
          this.getSelected(this.$route.params.id);
          this.$swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Não foi possivel atualizar!",
            footer: "Por favor, tente novamente mais tarde.",
          });
          return error.response;
        });
    },

    callbackCycles(args) {
      this.selected.EnrollmentStudent.cycle_id = args.id;

      this.$swal
        .fire({
          title: "Deseja alterar o Ciclo?",
          text: "A Matricula terá seu Ciclo atualizado.",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#68c5b7",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim",
          cancelButtonText: "Cancelar",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            try {
              var data = {
                cycle_id: args.id,
              };
              await DataService.updateCycle(this.$route.params.id, data);
              this.$swal
                .fire("Sucesso!", "O Ciclo foi alterado.", "success")
                .then((response) => {
                  this.getSelected(this.$route.params.id);
                  return response.data;
                });
            } catch (error) {
              this.getSelected(this.$route.params.id);
              this.$swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Não foi possivel alterar!",
                footer: "Por favor, tente novamente mais tarde.",
              });

              return error.response;
            }
          }
        })
        .catch((error) => {
          this.getSelected(this.$route.params.id);
          this.$swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Não foi possivel alterar!",
            footer: "Por favor, tente novamente mais tarde.",
          });
          return error.response;
        });
    },

    callbackClassrooms(args) {
      this.classroom.classroom_id = args.id;

      this.$swal
        .fire({
          title: "Deseja enturmar?",
          text: "O Aluno será adicionado na Turma selecionada.",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#68c5b7",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim",
          cancelButtonText: "Cancelar",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            try {
              var data = {
                classroom_id: this.classroom.classroom_id,
              };
              await DataServiceClassrooms.update(this.$route.params.id, data);
              this.$swal
                .fire("Sucesso!", "O Aluno foi enturmado.", "success")
                .then((response) => {
                  this.getSelected(this.$route.params.id);
                  return response.data;
                });
            } catch (error) {
              this.getSelected(this.$route.params.id);
              this.$swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Não foi possivel alterar!",
                footer: "Por favor, tente novamente mais tarde.",
              });

              return error.response;
            }
          }
        })
        .catch((error) => {
          this.getSelected(this.$route.params.id);
          this.$swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Não foi possivel alterar!",
            footer: "Por favor, tente novamente mais tarde.",
          });

          return error.response;
        });
    },

    generateNewContract(id) {
      this.$swal
        .fire({
          title: "Deseja gerar um novo Contrato?",
          text: "Os Contratantes serão receberam por email um novo contrato.",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#68c5b7",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim",
          cancelButtonText: "Cancelar",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            try {
              this.requestContract({ id: id });
              this.$swal
                .fire(
                  "Sucesso!",
                  "Contrato enviado por e-mail para ser assinado.",
                  "success"
                )
                .then((response) => {
                  this.getSelected(this.$route.params.id);
                  return response.data;
                });
            } catch (error) {
              this.getSelected(this.$route.params.id);
              this.$swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Não foi possivel gerar um novo contrato!",
                footer: "Por favor, tente novamente mais tarde.",
              });

              return error.response;
            }
          }
        })
        .catch((error) => {
          this.getSelected(this.$route.params.id);
          this.$swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Não foi possivel gerar um novo contrato!",
            footer: "Por favor, tente novamente mais tarde.",
          });

          return error.response;
        });
    },

    updateContractStatus(id) {
      this.$swal
        .fire({
          title: "Deseja atualizar o status do Contrato?",
          text: "Se o contrato já tiver sido assinado, o link do PDF será atualizado e o status do documento será alterado para 'VÁLIDO'.",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#68c5b7",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim",
          cancelButtonText: "Cancelar",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            this.updatingStatus = true;
            try {
              await httpRda
                .get(`/enrollments/${id}/update-contract-status?type=Webhook`)
                .then((resp) => {
                  if (resp.data.code === 202) {
                    this.$swal
                      .fire("o contrato ainda não assinado")
                      .then(async (response) => {
                        this.getSelected(this.$route.params.id);
                        this.updatingStatus = false;
                        return response.data;
                      });
                  } else if (resp.data.code === 204) {
                    this.$swal
                      .fire("O contrato foi cancelado ou finalizado")
                      .then(async (response) => {
                        this.getSelected(this.$route.params.id);
                        this.updatingStatus = false;
                        return response.data;
                      });
                  } else {
                    this.$swal
                      .fire("Contrato atualizado")
                      .then(async (response) => {
                        this.validContract(id, true);
                        this.getSelected(this.$route.params.id);
                        this.updatingStatus = false;
                        return response.data;
                      });
                  }
                });
            } catch (error) {
              this.getSelected(this.$route.params.id);
              this.updatingStatus = false;
              this.$swal.fire({
                icon: "error",
                title: "Oops...",
                text: error.response.data.message,
                footer: "Por favor, tente novamente mais tarde",
              });

              return error.response;
            }
          }
        })
        .catch((error) => {
          this.getSelected(this.$route.params.id);
          this.updatingStatus = false;
          this.$swal.fire({
            icon: "error",
            title: "Oops...",
            text: error.response.data.message,
            footer: "Por favor, tente novamente mais tarde.",
          });

          return error.response;
        });
    },
  },

  created() {
    this.getCalendars();
    this.getSelected(this.$route.params.id);
  },
};
</script>

<style lang="scss">
@import "@/assets/css/variables.scss";

.list-downloads {
  position: relative;
  &::after {
    content: "";
    display: block;
    position: absolute;
    border-bottom: 1px solid $grey5;
    width: calc(100% - 30px);
    bottom: 0;
    left: 15px;
  }
}
</style>
