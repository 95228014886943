<template>
  <b-modal ref="holidays" id="holidays" hide-header hide-footer centered no-close-on-backdrop>
    <h4 class="purple mb-4">Adicionar novo Feriado</h4>
    <b-row>
      <b-col class="mt-4">
        <SelectCalendar v-model="calendars.id" :callback="callbackCalendars" label="Ano Letivo"></SelectCalendar>
      </b-col>
      <div class="w-100"></div>
      <b-col class="mt-4">
        <StandardInput v-model="calendars.name" type="text" name="name" label="Nome do Feriado"></StandardInput>
      </b-col>
      <b-col class="mt-4">
        <b-form-group class="calendar">
          <label>Data do Feriado</label>
          <Datepicker
            v-model="calendars.date"
            placeholder="Selecione"
            format="dd/MM/yyyy"
            :use-utc="true"
            :language="languages[language]"
          ></Datepicker>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="mt-5">
      <b-col>
        <b-button class="btn large outline" @click="hideModal()">Voltar</b-button>
      </b-col>
      <b-col class="text-right">
        <b-button class="btn large blue" @click="create(calendars.id)">Salvar</b-button>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import DataService from "@/services/calendars.service";

import Datepicker from "vuejs-datepicker";
import * as lang from "vuejs-datepicker/src/locale";

import StandardInput from "@/components/inputs/StandardInput.vue";
import SelectCalendar from "@/components/inputs/SelectCalendar.vue";

export default {
  name: "",
  components: {
    Datepicker,
    StandardInput,
    SelectCalendar,
  },
  data() {
    return {
      calendars: {
        id: "",
        name: "",
        date: "",
      },
      language: "ptBR",
      languages: lang,
    };
  },
  methods: {
    create(id) {
      var data = {
        name: this.calendars.name,
        date: this.calendars.date,
      };
      DataService.createHoliday(id, data)
        .then((response) => {
          this.calendars.id = response.data.id;
          this.hideModal();
          window.location.reload();
          const Toast = this.$swal.mixin({
            toast: true,
            position: "bottom-end",
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer);
              toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            },
          });
          Toast.fire({
            icon: "success",
            title: "Feriado cadastrado com sucesso!",
          });
        })
        .catch((error) => {
          this.message =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
          this.$swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Não foi possivel cadastrar!",
            footer: `${this.message.details}`,
          });
        });
    },
    hideModal() {
      this.$refs["holidays"].hide();
    },
    callbackCalendars(args) {
      this.calendars.id = args.id;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/css/variables.scss";

div#holidays {
  .modal-dialog {
    max-width: 820px;
    .modal-content {
      background-color: $grey2;
      border: 0;
      border-radius: 0;
      .modal-body {
        padding: 60px;
      }
    }
  }
  .students {
    list-style: none;
    padding: 0;
    margin: 0;
    .student {
      align-items: center;
      display: flex;
      justify-content: space-between;
      line-height: 60px;
      border-bottom: 1px solid $grey5;
      &:last-child {
        border-bottom: 0;
      }
      .name {
        margin: 0;
      }
      .actions {
        a {
          margin: 0 15px;
        }
      }
    }
  }
  .vdp-datepicker__calendar:nth-child(3){
    header {
      .month__year_btn.up {
        pointer-events: none;
        width: 100%;
      }
      .prev, .next {
        display: none;
      }
    }
  }
}
</style>