<template>
  <b-container class="container-rda">
    <b-row>
      <b-col>
        <b-breadcrumb :items="breadcrumb"></b-breadcrumb>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <h2 class="purple bold">Perfil do professor</h2>
      </b-col>
    </b-row>

    <b-row class="teacher-profile mt-50">
      <b-col md="2">
        <img class="w-100" :src="teacher.User.photo" alt="" />
      </b-col>

      <b-col>
        <b-row>
          <b-col>
            <b-form-group class="required" description="">
              <label for="label">Nome</label>
              <b-form-input
                readonly
                v-model="teacher.User.name"
                type="text"
                placeholder=""
                required
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col>
            <b-form-group class="required" description="">
              <label for="label">E-mail</label>
              <b-form-input
                readonly
                v-model="teacher.User.email"
                type="text"
                placeholder=""
                required
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col md="6">
            <b-form-group class="required" description="">
              <label for="label">Telefone</label>
              <b-form-input
                readonly
                v-model="phone"
                type="text"
                v-mask="['(##) #####-####']"
                placeholder="(__) _____-____"
                required
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-row align-v="center" class="mt-5">
      <b-col></b-col>

      <b-col class="text-right">
        <b-button
          class="large blue outline"
          :to="{
            name: 'professores',
            params: {
              cycle: this.$route.params.cycle,
              subject: this.$route.params.subject,
              page: this.$route.params.page,
            },
          }"
          >Voltar</b-button
        >
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "perfil-professor-visualizar",

  data() {
    return {
      breadcrumb: [
        {
          text: "Comunidade",
          href: "/comunidade",
        },
        {
          text: "Professores",
          href: "/professores",
        },
        {
          text: "Registro de professor",
          href: "/perfil",
          active: true,
        },
      ],
    };
  },

  computed: {
    ...mapGetters({
      teacher: "community/teacher",
    }),

    id() {
      return this.$route.params.id;
    },

    phone() {
      if (this.teacher.User.Phones.length)
        return this.teacher.User.Phones[0].number;
      else return "";
    },
  },

  methods: {
    ...mapActions({
      getTeacher: "community/getTeacher",
    }),
  },

  created() {
    if (this.id) this.getTeacher(this.id);
  },
};
</script>

<style lang="scss">
.teacher-profile {
  .form-group {
    // margin-bottom: 40px;
    &.required {
      &:after {
        right: 0;
      }
    }
  }
}

.container-rda .multiselect__tag-icon:after {
  color: white;
}

input.isInvalid {
  border-color: red;
}
</style>
