import http from "../http-rda";

class DataService {
  getAll(params) {
    return http.get("/admin/logs", { params });
  }

  getPasswordHistory(params) {
    return http.get("/admin/logs?search=password", { params });
  }

  getFaileds(name) {
    return http.get(`/admin/logs?status_code=${ name }`);
  }

  findByTitle(name) {
    return http.get(`/admin/logs?search=${ name }`);
  }

}

export default new DataService();