<template>
  <div>
    <b-row class="print">
      <b-col>
        <p>
          Os <strong>CONTRATANTES</strong> declaram, para fins de direito, e sob
          as penas da lei, que as informações prestadas no ato de preenchimento
          do Requerimento de Matrícula e constantes no
          <strong>CONTRATO</strong>, são verdadeiras e eximem a
          <strong>CONTRATADA</strong> de quaisquer responsabilidade que possam
          adivir de omissões e/ou inverdades prestadas no referido ato.
        </p>
      </b-col>
    </b-row>
    <TemplateViewContract :formEnroll="value" />
  </div>
</template>

<script>
import TemplateViewContract from "@/components/templates/TemplateViewContract";
export default {
  name: "Print",
  components: {
    TemplateViewContract,
  },

  props: {
    value: {
      type: Object,
    },
  },

  data() {
    return {
      showDocs: null,
    };
  },

  methods: {
    //
  },

  computed: {
    //
  },

  watch: {
    //
  },

  mounted() {},

  created() {
    //
  },
};
</script>

<style lang="scss" scoped>
//
</style>
